//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _              from 'lodash';
import { call }       from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { takeEvery }  from 'redux-saga/effects';
import { push }       from 'connected-react-router';
import I18n           from 'i18next';

import { CompanyActions }    from '@/store/actions/company';
import { CompanyTypes }      from '@/store/actions/company';
import { MachineActions }    from '@/store/actions/machine';
import { OverlayManager }    from '@/components/connected/OverlayManager';
import Tag                   from '@/helper/Tag';
import Overlays              from '@/constants/Overlays';
import HydraHelper           from '@/helper/Hydra';
import Hydra                 from '@/helper/Hydra';
import * as Api              from '@/api';
import { TagActions }        from '@/store/actions/tag';
import { AlertBoxActions }   from '@/store/actions/alertBox';
import Routes                from '@/constants/Routes';
import Route                 from '@/helper/Route';
import { NavigationActions } from '@/store/actions/navigation';
import { UserActions }       from '@/store/actions/user';
import Status                from '@/constants/Status';
import TagEditorContext      from '@/constants/TagEditorContext';
import TagEditor             from '@/helper/TagEditor';

function* addMachineToCompany() {
    yield put(CompanyActions.updateCompany());
}

function* fetchCompany(action) {
    const response = yield call(
        Api.fetchCompany,
        action.id,
    );

    if (response.ok) {
        const company = HydraHelper.cleanupObject(response.data);

        if (company) {
            yield put(CompanyActions.fetchCompanySucceeded({
                company,
            }));
        } else {
            yield put(CompanyActions.fetchCompanyFailed());
        }
    } else {
        yield put(CompanyActions.fetchCompanyFailed());
    }
}

function* deleteCompanyMachine() {
    const currentCompanyId = yield select((state) => state.company.currentCompanyId);
    const machine          = yield select((state) => state.company.machineToDelete);
    const response         = yield call(
        Api.deleteMachine,
        machine.iri,
    );

    if (response.ok) {
        yield put(CompanyActions.deleteCompanyMachineSucceeded({
            machine,
            companyId: currentCompanyId,
        }));
        yield put(AlertBoxActions.showSuccessAlert({
            text: I18n.t('deleteCompanyMachineSucceeded'),
        }));
    } else {
        yield put(CompanyActions.deleteCompanyMachineFailed());
    }

    yield call(fetchCompany, {
        id: currentCompanyId,
    });
}

function* setCompanyAsActive(action) {
    const response = yield call(
        Api.setCompanyAsActive,
        action.id,
    );

    if (response.ok) {
        yield put(AlertBoxActions.showSuccessAlert({
            text: I18n.t('companySetAsActiveSuccess'),
        }));

        yield put(CompanyActions.fetchCompany({
            id: action.id,
        }));
    } else {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('companySetAsActiveFailed'),
        }));
    }
}

function* removeCompanyMachine() {
    const currentCompanyId = yield select((state) => state.company.currentCompanyId);
    const machine          = yield select((state) => state.company.machineToRemove);
    const companyPayload   = yield select((state) => state.company.ownCompanyWithUpdatedMachineList);
    const response         = yield call(
        Api.updateCompany,
        companyPayload.iri,
        companyPayload,
    );

    if (response.ok) {
        const updatedCompany = response.data;

        yield put(CompanyActions.updateCompanySucceeded({
            company: updatedCompany,
        }));
        yield put(MachineActions.fetchMachinesByCompany({
            companyId: updatedCompany.id,
        }));
        yield put(CompanyActions.removeCompanyMachineSucceeded({
            machine,
            companyId: currentCompanyId,
        }));
    } else {
        yield put(CompanyActions.updateCompanyFailed());
    }
}

function* openDeleteCompanyMachineDialog() {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.deleteMachine)));
}

function* openRemoveCompanyMachineDialog() {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.removeMachine)));
}

function* editOwnCompanyAndOpenOverlay() {
    const company     = yield select((state) => state.company.ownCompanyEdit);
    const interests   = _.get(company, 'interests', []);
    const competences = _.get(company, 'competences', []);

    yield put(TagActions.reset());
    yield put(TagActions.buildHierarchy({
        context: TagEditorContext.editCompanyInterests,
        tags:    interests,
    }));
    yield put(TagActions.buildHierarchy({
        context: TagEditorContext.editCompanyCompetences,
        tags:    competences,
    }));
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editCompany)));
}

function* getChildTagsCompetences(action) {
    const tag       = _.get(action, 'tag', null);
    const hierarchy = _.get(tag, 'hierarchy', null);

    for (const tagElement of hierarchy) {
        const tagId = _.get(tagElement, 'id', null);

        if (tagId) {
            const response = yield call(
                Api.fetchTag,
                tagId,
            );

            if (response.ok) {
                const result   = HydraHelper.cleanupObject(response.data);
                const children = _.get(result, 'childTags', []);

                yield put(CompanyActions.addOwnCompanyChildrenToTagQueryCompetences({
                    tag: result,
                    children,
                }));
            }
        }
    }
}

function* getChildTagsInterests(action) {
    const tag       = _.get(action, 'tag', null);
    const hierarchy = _.get(tag, 'hierarchy', null);

    for (const tagElement of hierarchy) {
        const tagId = _.get(tagElement, 'id', null);

        if (tagId) {
            const response = yield call(
                Api.fetchTag,
                tagId,
            );

            if (response.ok) {
                const result   = HydraHelper.cleanupObject(response.data);
                const children = _.get(result, 'childTags', []);

                yield put(CompanyActions.addOwnCompanyChildrenToTagQueryInterests({
                    tag: result,
                    children,
                }));
            }
        }
    }
}

function* getOwnCompanyChildTags() {
    const ownCompanyEdit       = yield select((state) => state.company.ownCompanyEdit);
    const interestsHierarchy   = ownCompanyEdit.interestsHierarchy;
    const interests            = Tag.gatherTagsFromHierarchy(interestsHierarchy);
    const competencesHierarchy = ownCompanyEdit.competencesHierarchy;
    const competences          = Tag.gatherTagsFromHierarchy(competencesHierarchy);

    for (const tagInterest of interests) {
        const tagId = _.get(tagInterest, 'id', null);

        if (tagId) {
            const response = yield call(
                Api.fetchTag,
                tagId,
            );

            if (response.ok) {
                const result   = HydraHelper.cleanupObject(response.data);
                const children = _.get(result, 'childTags', []);

                yield put(CompanyActions.addOwnCompanyChildrenToTagQueryInterests({
                    tag: result,
                    children,
                }));
            }
        }
    }

    for (const tagCompetence of competences) {
        const tagId = _.get(tagCompetence, 'id', null);

        if (tagId) {
            const response = yield call(
                Api.fetchTag,
                tagId,
            );

            if (response.ok) {
                const result   = HydraHelper.cleanupObject(response.data);
                const children = _.get(result, 'childTags', []);

                yield put(CompanyActions.addOwnCompanyChildrenToTagQueryCompetences({
                    tag: result,
                    children,
                }));
            }
        }
    }
}

/* TODO: https://lulububu.atlassian.net/browse/SUPPLYDU-762
function* setCurrentCompany(action) {
    yield put(CompanyActions.fetchCompany({
        id: action.id,
    }));
}
*/

function* setMyCompanyQuery(action) {
    const query = _.get(action, 'query');

    if (_.isEmpty(query)) {
        yield put(CompanyActions.setMyCompanySearchResults({
            companies: [],
        }));

        return;
    }

    const response = yield call(
        Api.search,
        query,
        'companies',
    );

    if (response.ok) {
        const companies = _.get(
            HydraHelper.cleanupObject(response.data),
            'companies',
            [],
        );

        yield put(CompanyActions.setMyCompanySearchResults({
            companies,
        }));
    }
}

function mapAttachment(company, fieldName) {
    const attachment = _.get(company, fieldName, null);

    if (!attachment) {
        return null;
    }

    return {
        ...attachment,
        id: _.get(attachment, 'iri', null),
    };
}

function* updateCompany() {
    const ownCompanyEdit      = yield select((state) => state.company.ownCompanyEdit);
    const companyClone        = _.cloneDeep(ownCompanyEdit);
    companyClone.factSheet    = _.get(companyClone, 'factSheet.iri', null);
    companyClone.logo         = companyClone.logoUploaded;
    companyClone.industries   = _.map(companyClone.industries, (industry) => industry.iri);
    companyClone.video        = mapAttachment(companyClone, 'video');
    companyClone.presentation = mapAttachment(companyClone, 'presentation');

    delete companyClone.logoUploaded;
    delete companyClone.favoriteCompanies;

    companyClone.interests   = yield TagEditor.getTagsFromEditor(TagEditorContext.editCompanyInterests);
    companyClone.competences = yield TagEditor.getTagsFromEditor(TagEditorContext.editCompanyCompetences);

    const response = yield call(
        Api.updateCompany,
        companyClone.iri,
        companyClone,
    );

    if (response.ok) {
        const updatedCompany = response.data;

        yield put(CompanyActions.updateCompanySucceeded({
            company: updatedCompany,
        }));

        if (ownCompanyEdit.machineIdToDelete) {
            yield put(MachineActions.fetchMachine({
                machineId: ownCompanyEdit.machineIdToDelete,
            }));
        }

        yield put(MachineActions.fetchMachinesByCompany({
            companyId: updatedCompany.id,
        }));
    } else {
        yield put(CompanyActions.updateCompanyFailed());
    }
}

function* updateCompanyFailed() {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('updateCompanyFailed'),
    }));
}

function* updateCompanySucceeded() {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('updateCompanySuccess'),
    }));
}

function* setCurrentJoinRequestsByResponse(response) {
    const usersRequestedToJoin = _.get(response, 'data.usersRequestedToJoin', []);

    yield put(CompanyActions.setCurrentCompanyJoinRequests({
        usersRequestedToJoin,
    }));
}

function* acceptJoinRequest(action) {
    const { companyIri, userId } = action;
    const response               = yield call(
        Api.acceptJoinRequest,
        companyIri,
        userId,
    );

    if (response.ok) {
        yield setCurrentJoinRequestsByResponse(response);
        yield put(AlertBoxActions.showSuccessAlert({
            text: I18n.t('acceptedJoinRequest'),
        }));
    } else {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('acceptingJoinRequestFailed'),
        }));
    }
}

function* declineJoinRequest(action) {
    const { companyIri, userId } = action;
    const response               = yield call(
        Api.declineJoinRequest,
        companyIri,
        userId,
    );

    if (response.ok) {
        yield setCurrentJoinRequestsByResponse(response);
        yield put(AlertBoxActions.showSuccessAlert({
            text: I18n.t('declinedJoinRequest'),
        }));
    } else {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('decliningJoinRequestFailed'),
        }));
    }
}

function* createCompany() {
    const createCompanyState = yield select((state) => state.company.createCompany);
    const name               = _.get(createCompanyState, 'companyName');

    if (!name) {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('companyNameRequired'),
        }));
        yield put(CompanyActions.createCompanyFailed({
            reason: 'noName',
        }));

        return;
    }

    const company  = {
        name,
    };
    const response = yield call(
        Api.createCompany,
        company,
    );

    if (response.ok) {
        const createdCompany = HydraHelper.cleanupObject(response.data);

        yield put(CompanyActions.createCompanySucceeded({
            company: createdCompany,
        }));
    } else if (response.status === Status.unprocessableEntity) {
        const serverError = response.data['hydra:description'].split(': ')[1];

        yield put(CompanyActions.createCompanyFailed({
            reason: `errors.${serverError}`,
        }));
    }
}

function* createCompanyFailed(action) {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(action.reason),
    }));
}

function* createCompanySucceeded(action) {
    const { company } = action;
    const id          = _.get(company, 'id');
    const slug        = _.get(company, 'slug');
    const companyId   = Route.getCompanyUrl(id, slug);
    const route       = Route.buildRoute(
        Routes.myCompany,
        {
            companyId,
        },
    );
    const path        = Route.buildPathByRoute(route);

    yield put(NavigationActions.openUrl({
        url: path,
    }));
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('createCompanySucceeded'),
    }));
}

function* actPrivate() {
    const userState   = yield select((state) => state.user);
    const actsPrivate = _.get(userState, 'actsPrivate');

    if (actsPrivate) {
        yield put(CompanyActions.actPrivateFailed());
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('alreadyActsPrivate'),
        }));

        return;
    }

    const createCompanyState = yield select((state) => state.company.createCompany);
    const userIri            = _.get(userState, 'userId');
    const position           = _.get(createCompanyState, 'position');
    const companyName        = _.get(createCompanyState, 'companyName');
    const user               = {
        actsPrivate:      true,
        position,
        workingAtCompany: companyName,
    };
    const response           = yield call(
        Api.patchUser,
        userIri,
        user,
    );

    if (response.ok) {
        yield put(CompanyActions.actPrivateSucceeded({
            position,
            companyName,
        }));
        yield put(NavigationActions.openUrl({
            url: Routes.myProfile,
        }));
        yield put(AlertBoxActions.showSuccessAlert({
            text: I18n.t('actingPrivateNow'),
        }));
    } else {
        yield put(CompanyActions.actPrivateFailed());
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('actingPrivateFailed'),
        }));
    }
}

function* openProjects(action) {
    const route = Route.buildRoute(Routes.projects, action.companyId);

    yield put(push(Route.buildPathByRoute(route)));
}

function* deleteCompany(action) {
    const { companyId } = action;

    const iri = Hydra.getIriFromId(
        'companies',
        companyId,
    );

    const response = yield call(
        Api.context.company.deleteCompany,
        iri,
    );

    if (response.ok) {
        yield put(CompanyActions.deleteCompanySucceeded());
    } else {
        yield put(CompanyActions.deleteCompanyFailed());
    }
}

function* deleteCompanySucceeded() {
    yield put(UserActions.fetch());
    yield put(NavigationActions.openUrl({
        url: Routes.home,
    }));
}

function* deleteCompanyFailed() {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('deleteCompanyFailed'),
    }));
}

export const callCompanySagas = () => {
    return [
        // @formatter:off
        takeLatest([CompanyTypes.DELETE_COMPANY],                         deleteCompany),
        takeLatest([CompanyTypes.DELETE_COMPANY_SUCCEEDED],               deleteCompanySucceeded),
        takeLatest([CompanyTypes.DELETE_COMPANY_FAILED],                  deleteCompanyFailed),
        takeLatest([CompanyTypes.ACT_PRIVATE],                            actPrivate),
        takeLatest([CompanyTypes.JOIN_REQUEST_ACCEPT],                    acceptJoinRequest),
        takeLatest([CompanyTypes.JOIN_REQUEST_DECLINE],                   declineJoinRequest),
        takeLatest([CompanyTypes.ADD_OWN_COMPANY_MACHINE],                addMachineToCompany),
        takeLatest([CompanyTypes.ADD_OWN_COMPANY_COMPETENCE_TAG],         getChildTagsCompetences),
        takeLatest([CompanyTypes.ADD_OWN_COMPANY_INTEREST_TAG],           getChildTagsInterests),
        takeLatest([CompanyTypes.OPEN_DELETE_OWN_COMPANY_MACHINE_DIALOG], openDeleteCompanyMachineDialog),
        takeLatest([CompanyTypes.OPEN_REMOVE_OWN_COMPANY_MACHINE_DIALOG], openRemoveCompanyMachineDialog),
        takeLatest([CompanyTypes.DELETE_OWN_COMPANY_MACHINE],             deleteCompanyMachine),
        takeLatest([CompanyTypes.REMOVE_OWN_COMPANY_MACHINE],             removeCompanyMachine),
        takeLatest([CompanyTypes.EDIT_OWN_COMPANY_AND_OPEN_OVERLAY],      editOwnCompanyAndOpenOverlay),
        takeEvery([CompanyTypes.FETCH_COMPANY],                           fetchCompany),
        takeLatest([CompanyTypes.GET_OWN_COMPANY_CHILD_TAGS],             getOwnCompanyChildTags),
        takeLatest([CompanyTypes.SET_COMPANY_AS_ACTIVE],                  setCompanyAsActive),
        takeLatest([CompanyTypes.SET_CURRENT_COMPANY],                    fetchCompany),
        takeLatest([CompanyTypes.SET_MY_COMPANY_QUERY],                   setMyCompanyQuery),
        takeLatest([CompanyTypes.UPDATE_COMPANY],                         updateCompany),
        takeLatest([CompanyTypes.UPDATE_COMPANY_FAILED],                  updateCompanyFailed),
        takeLatest([CompanyTypes.UPDATE_COMPANY_SUCCEEDED],               updateCompanySucceeded),
        takeLatest([CompanyTypes.CREATE_COMPANY],                         createCompany),
        takeLatest([CompanyTypes.CREATE_COMPANY_FAILED],                  createCompanyFailed),
        takeLatest([CompanyTypes.CREATE_COMPANY_SUCCEEDED],               createCompanySucceeded),
        takeLatest([CompanyTypes.OPEN_PROJECTS],                          openProjects),
        // @formatter:on
    ];
};
