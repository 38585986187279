//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const OverlayTypes = {
    OPEN_DIALOG:            'Overlay/OPEN_DIALOG',
    OPEN_CONFIRM_DIALOG:    'Overlay/OPEN_CONFIRM_DIALOG',
    TRIGGER_CONFIRM_ACTION: 'Overlay/TRIGGER_CONFIRM_ACTION',
    TRIGGER_CANCEL_ACTION:  'Overlay/TRIGGER_CANCEL_ACTION',
};

const openDialog = makeActionCreator(
    OverlayTypes.OPEN_DIALOG,
    {
        dialogKey:         null,
        confirmAction:     null,
        cancelAction:      null,
        title:             null,
        message:           null,
        confirmButtonText: null,
        cancelButtonText:  null,
    },
);

const openConfirmDialog = makeActionCreator(
    OverlayTypes.OPEN_CONFIRM_DIALOG,
    {
        confirmAction:     null,
        cancelAction:      null,
        title:             null,
        message:           null,
        confirmButtonText: null,
        cancelButtonText:  null,
    },
);

const triggerConfirmAction = makeActionCreator(
    OverlayTypes.TRIGGER_CONFIRM_ACTION,
    {},
);

const triggerCancelAction = makeActionCreator(
    OverlayTypes.TRIGGER_CANCEL_ACTION,
    {},
);

export const OverlayActions = {
    openDialog,
    openConfirmDialog,
    triggerConfirmAction,
    triggerCancelAction,
};
