//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default class Window {
    static getWindowOverflow = (element) => {
        const bounding = element.getBoundingClientRect();

        return bounding.top + bounding.height - window.innerHeight;
    };

    static getMaxHeightNoOverflow = (element) => {
        const bounding = element.getBoundingClientRect();

        return window.innerHeight - bounding.top;
    };

    static getCoordinates = (elem) => {
        const box             = elem.getBoundingClientRect();
        const body            = document.body;
        const documentElement = document.documentElement;
        const scrollTop       = window.pageYOffset || documentElement.scrollTop || body.scrollTop;
        const scrollLeft      = window.pageXOffset || documentElement.scrollLeft || body.scrollLeft;
        const clientTop       = documentElement.clientTop || body.clientTop || 0;
        const clientLeft      = documentElement.clientLeft || body.clientLeft || 0;
        const top             = box.top + scrollTop - clientTop;
        const left            = box.left + scrollLeft - clientLeft;

        return {
            top:    Math.round(top),
            left:   Math.round(left),
            height: box.height,
            width:  box.width,
        };
    };

    static scrollTo = (top, element = window, callback = () => null) => {
        // Check if the container is scrollable, because "scrollend" would not be triggered in this case.
        if (element.scrollHeight > element.clientHeight) {
            element.addEventListener(
                'scrollend',
                callback,
                {
                    once: true,
                },
            );
            element.scrollTo({
                top,
                behavior: 'smooth',
            });
        } else {
            callback(true);
        }
    };

    static scrollAdd = (add, element, callback) => {
        Window.scrollTo(
            element.scrollTop + add,
            element,
            callback,
        );
    };
}
