//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import _                         from 'lodash';
import { connect }               from 'react-redux';
import { withRouter }            from 'react-router';
import { bindActionCreators }    from 'redux';
import ComponentHelper           from '@/helper/ComponentHelper';
import PropTypes                 from '@/components/PropTypes';
import StatelessCreateCompanyBox from '@/components/stateless/composed/CreateCompanyBox';
import { CompanyActions }        from '@/store/actions/company';

export class Component extends React.Component {
    onCompanyNameChange = (companyName) => {
        this.props.setCreateCompany({
            companyName,
        });
    };

    onPositionChange = (position) => {
        this.props.setCreateCompany({
            position,
        });
    };

    onCreateCompany = () => {
        this.props.createCompany();
    };

    onActPrivate = () => {
        this.props.actPrivate();
    };

    render() {
        return (
            <StatelessCreateCompanyBox
                onCompanyNameChange={this.onCompanyNameChange}
                onPositionChange={this.onPositionChange}
                onCreateCompany={this.onCreateCompany}
                onActPrivate={this.onActPrivate}
                {...this.props}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    actPrivate:       PropTypes.func,
    companyName:      PropTypes.string,
    createCompany:    PropTypes.func,
    position:         PropTypes.string,
    setCreateCompany: PropTypes.func,
};

Component.defaultProps = {
    actPrivate:       _.noop,
    companyName:      '',
    createCompany:    _.noop,
    position:         '',
    setCreateCompany: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => {
    const createCompany = _.get(state, 'company.createCompany');
    const companyName   = _.get(createCompany, 'companyName');
    const position      = _.get(createCompany, 'position');

    return {
        companyName,
        position,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyActions,
    },
    dispatch,
);

export const CreateCompanyBox = Component;

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component));
