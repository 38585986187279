//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                          from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import classNames                     from 'classnames';
import ComponentHelper                from '@/helper/ComponentHelper';
import PropTypes                      from '@/components/PropTypes';
import colors                         from '@/styles/colors.module.scss';
import LogoAsset                      from '../../../../assets/images/logo.svg';
import LogoPoweredByAsset             from '../../../../assets/images/logo-powered-by.svg';
import styles                         from './styles.module.scss';

export class Component extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }

    renderLogo = (color) => {
        if (this.props.poweredBy) {
            return (
                <LogoPoweredByAsset color={color} />
            );
        }

        return (
            <LogoAsset color={color} />
        );
    };

    render() {
        const color     = !this.props.inverted ? colors.colorWhite : colors.colorBlack;
        const className = classNames(
            styles.logo,
            this.props.className,
        );

        if (this.props.to) {
            return (
                <ReactRouterDomLink
                    className={className}
                    to={this.props.to}
                >
                    {this.renderLogo(color)}
                </ReactRouterDomLink>
            );
        }

        return (
            <div className={className}>
                {this.renderLogo(color)}
            </div>
        );
    }
}

export const Logo = Component;

Component.propTypes = {
    className: PropTypes.string,
    inverted:  PropTypes.bool,
    poweredBy: PropTypes.bool,
    to:        PropTypes.to,
};

Component.defaultProps = {
    className: null,
    inverted:  false,
    poweredBy: false,
    to:        null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
