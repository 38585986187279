//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React          from 'react';
import HeadlineMedium from '@/components/stateless/atomic/HeadlineMedium';
import PropTypes      from '@/components/PropTypes';
import styles         from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.callToActionBox}>
                <HeadlineMedium text={this.props.title} />
                <p>
                    {this.props.text}
                </p>
                {this.props.children}
            </div>
        );
    }
}

export const CallToActionBox = Component;

Component.propTypes = {
    children: PropTypes.children,
    text:     PropTypes.string,
    title:    PropTypes.string,
};

Component.defaultProps = {
    children: null,
    text:     null,
    title:    null,
};

export default Component;
