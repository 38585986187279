//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import SelectionHelper from '@/helper/SelectionHelper';
import IconType        from './IconType';
import styles          from './styles.module.scss';

import IconAddCircle              from '../../../../assets/icons/add-circle.svg';
import IconArrowRight             from '../../../../assets/icons/arrow-right.svg';
import IconBurger                 from '../../../../assets/icons/burger.svg';
import IconCalendar               from '../../../../assets/icons/calendar.svg';
import IconCaretDown              from '../../../../assets/icons/caret-down.svg';
import IconCaretLeft              from '../../../../assets/icons/caret-left.svg';
import IconCaretRight             from '../../../../assets/icons/caret-right.svg';
import IconCheck                  from '../../../../assets/icons/check.svg';
import IconChat                   from '../../../../assets/icons/chat.svg';
import IconCross                  from '../../../../assets/icons/cross.svg';
import IconDeleteCircle           from '../../../../assets/icons/delete-circle.svg';
import IconDownload               from '../../../../assets/icons/download.svg';
import IconEnvelope               from '../../../../assets/icons/envelope.svg';
import IconEye                    from '../../../../assets/icons/eye.svg';
import IconCompleteProfileCheck   from '../../../../assets/icons/complete-profile-check.svg';
import IconCompleteProfileUncheck from '../../../../assets/icons/complete-profile-uncheck.svg';
import IconFacebook               from '../../../../assets/icons/facebook.svg';
import IconFile                   from '../../../../assets/icons/file.svg';
import IconFilter                 from '../../../../assets/icons/filter.svg';
import IconInfo                   from '../../../../assets/icons/info.svg';
import IconInstagram              from '../../../../assets/icons/instagram.svg';
import IconKey                    from '../../../../assets/icons/key.svg';
import IconLinkedin               from '../../../../assets/icons/linkedin.svg';
import IconOwner                  from '../../../../assets/icons/owner.svg';
import IconPencil                 from '../../../../assets/icons/pencil.svg';
import IconPlus                   from '../../../../assets/icons/plus.svg';
import IconPrint                  from '../../../../assets/icons/print.svg';
import IconRemove                 from '../../../../assets/icons/remove.svg';
import IconSearch                 from '../../../../assets/icons/search.svg';
import IconShare                  from '../../../../assets/icons/share.svg';
import IconSpinner                from '../../../../assets/icons/spinner.svg';
import IconStar                   from '../../../../assets/icons/star-filled.svg';
import IconTwitter                from '../../../../assets/icons/twitter.svg';
import IconUnfollow               from '../../../../assets/icons/unfollow.svg';
import IconUser                   from '../../../../assets/icons/user.svg';
import IconVerified               from '../../../../assets/icons/verified.svg';
import IconX                      from '../../../../assets/icons/x.svg';
import IconYouTube                from '../../../../assets/icons/youtube.svg';
import IconUpload                 from '../../../../assets/icons/upload.svg';
import IconPdf                    from '../../../../assets/icons/pdf.svg';
import IconCamera                 from '../../../../assets/icons/camera.svg';

export class Component extends React.Component {
    render() {
        return (
            <span className={styles.icon}>
                {this.renderSvg()}
            </span>
        );
    }

    renderSvg = () => {
        return SelectionHelper.get(
            this.props.iconType,
            {
                [IconType.addCircle]:              <IconAddCircle />,
                [IconType.arrowRight]:             <IconArrowRight />,
                [IconType.burger]:                 <IconBurger />,
                [IconType.calendar]:               <IconCalendar />,
                [IconType.caretDown]:              <IconCaretDown />,
                [IconType.caretLeft]:              <IconCaretLeft />,
                [IconType.caretRight]:             <IconCaretRight />,
                [IconType.chat]:                   <IconChat />,
                [IconType.camera]:                 <IconCamera />,
                [IconType.check]:                  <IconCheck />,
                [IconType.cross]:                  <IconCross />,
                [IconType.deleteCircle]:           <IconDeleteCircle />,
                [IconType.download]:               <IconDownload />,
                [IconType.envelope]:               <IconEnvelope />,
                [IconType.eye]:                    <IconEye />,
                [IconType.completeProfileCheck]:   <IconCompleteProfileCheck />,
                [IconType.completeProfileUncheck]: <IconCompleteProfileUncheck />,
                [IconType.facebook]:               <IconFacebook />,
                [IconType.file]:                   <IconFile />,
                [IconType.file]:                   <IconFile />,
                [IconType.filter]:                 <IconFilter />,
                [IconType.info]:                   <IconInfo />,
                [IconType.instagram]:              <IconInstagram />,
                [IconType.key]:                    <IconKey />,
                [IconType.linkedin]:               <IconLinkedin />,
                [IconType.owner]:                  <IconOwner />,
                [IconType.pencil]:                 <IconPencil />,
                [IconType.plus]:                   <IconPlus />,
                [IconType.print]:                  <IconPrint />,
                [IconType.remove]:                 <IconRemove />,
                [IconType.search]:                 <IconSearch />,
                [IconType.share]:                  <IconShare />,
                [IconType.spinner]:                <IconSpinner />,
                [IconType.star]:                   <IconStar />,
                [IconType.twitter]:                <IconTwitter />,
                [IconType.unfollow]:               <IconUnfollow />,
                [IconType.user]:                   <IconUser />,
                [IconType.verified]:               <IconVerified />,
                [IconType.x]:                      <IconX />,
                [IconType.youTube]:                <IconYouTube />,
                [IconType.upload]:                 <IconUpload />,
                [IconType.pdf]:                    <IconPdf />,
            },
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Icon = Component;

Component.propTypes = {
    iconType: PropTypes.icon,
};

Component.defaultProps = {
    iconType: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
