//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import { Chart as ChartJS } from 'chart.js';
import { CategoryScale }    from 'chart.js';
import { LinearScale }      from 'chart.js';
import { BarElement }       from 'chart.js';
import { Title }            from 'chart.js';
import { Legend }           from 'chart.js';
import { Bar }              from 'react-chartjs-2';
import _                    from 'lodash';
import colors               from '@/styles/colors.module.scss';
import PropTypes            from '@/components/PropTypes';
import ComponentHelper      from '@/helper/ComponentHelper';
import styles               from './styles.module.scss';

export class Component extends React.Component {
    static chartJsPlugins = [
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Legend,
    ];

    static chartOptions = {
        plugins:    {
            legend: {
                display:  true,
                position: 'bottom',
                align:    'start',
                labels:   {
                    pointStyle:    'circle',
                    usePointStyle: true,
                },
            },
        },
        responsive: true,
        scales:     {
            x: {
                stacked: true,
                grid:    {
                    display: false,
                },
            },
            y: {
                stacked: false,
                ticks:   {
                    callback: (value) => {
                        const formattedValue = new Intl.NumberFormat(
                            'en-US',
                            {
                                notation: 'compact',
                            },
                        ).format(value);

                        return `$ ${formattedValue}`;
                    },
                },
            },
        },
    };

    static colorPool = [
        colors.colorOrangeDark,
        colors.colorOrange,
        colors.colorOrangeLight,
        colors.colorOrangeLighter1,
        colors.colorBlueCalypso,
        colors.colorBlackLight,
        colors.colorGrayHeather,
    ];

    getDataWithAssignedColor = (data) => {
        return _.map(data, (dataRow, index) => {
            const backgroundColor = Component.colorPool[index];

            return {
                ...dataRow,
                backgroundColor,
            };
        });
    };

    componentDidMount() {
        ChartJS.register(...Component.chartJsPlugins);
    }

    componentWillUnmount() {
        ChartJS.unregister(...Component.chartJsPlugins);
    }

    render() {
        const {
            labels,
            data,
            animate,
            onChartLoaded,
        }                       = this.props;
        const chartData               = {
            labels,
            datasets: this.getDataWithAssignedColor(data),
        };
        const defaultChartOptions     = Component.chartOptions;
        defaultChartOptions.animation = {
            duration:   animate ? 1000 : 0,
            onComplete: onChartLoaded,
        };

        return (
            <div className={styles.factSheetBarChartWrapper}>
                <Bar
                    options={defaultChartOptions}
                    data={chartData}
                />
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const FactSheetBarChart = Component;

Component.propTypes = {
    animate:       PropTypes.bool,
    data:          PropTypes.array,
    labels:        PropTypes.array,
    onChartLoaded: PropTypes.func,
};

Component.defaultProps = {
    animate:       true,
    data:          [],
    labels:        [],
    onChartLoaded: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
