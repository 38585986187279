//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import classNames      from 'classnames';
import React           from 'react';
import ComponentHelper from '@/helper/ComponentHelper';
import Icon            from '@/components/stateless/atomic/Icon';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={classNames([styles.checkBoxWrapper, this.props.className])}>
                <div
                    className={styles.checkBoxWrapperClickArea}
                    onClick={this.props.valueChanged}
                >
                    {this.renderCheckBox()}
                    <div className={styles.checkBoxWrapperLabel}>
                        {this.props.text}
                    </div>
                </div>
            </div>
        );
    }

    renderCheckBox = () => {
        return (
            <div
                className={
                    classNames(
                        styles.checkBox,
                        {
                            [styles.checkBoxChecked]: this.props.checked,
                        },
                    )
                }
            >
                {this.renderIcon()}
            </div>
        );
    };

    renderIcon = () => {
        if (this.props.checked) {
            return (
                <Icon iconType={IconType.check} />
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    checked:      PropTypes.bool,
    text:         PropTypes.string,
    valueChanged: PropTypes.func,
    className:    PropTypes.string,
};

Component.defaultProps = {
    checked:      false,
    text:         '',
    valueChanged: _.noop,
    className:    '',
};

Component.renderAffectingProps = [
    'checked',
    'text',
];

Component.renderAffectingStates = [];

export const CheckBox = Component;

export default Component;
