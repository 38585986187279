//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                from 'immutability-helper';
import { CookieBannerTypes } from '@/store/actions/cookieBanner';

const initialState = {
    accepted: null,
};

const acceptCookies = (action, state) => {
    return update(state, {
        accepted: {
            $set: true,
        },
    });
};

const rejectedCookies = (action, state) => {
    return update(state, {
        accepted: {
            $set: false,
        },
    });
};

const resetCookies = (action, state) => {
    return update(state, {
        accepted: {
            $set: null,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case CookieBannerTypes.COOKIES_ACCEPTED: return acceptCookies(action, state);
        case CookieBannerTypes.COOKIES_REJECTED: return rejectedCookies(action, state);
        case CookieBannerTypes.COOKIES_RESET:    return resetCookies(action, state);
        default:                                 return state;
        // @formatter:on
    }
}
