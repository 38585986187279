//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import I18n       from 'i18next';
import classNames from 'classnames';
import PropTypes  from '@/components/PropTypes';
import appStyles  from '@/styles.module.scss';
import { Link }   from '@/components/stateless/atomic/Link';
import Align      from '@/constants/Align';
import styles     from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const props = this.props;
        const align = props.align;

        return (
            <div
                className={classNames(
                    styles.startPageBoxFooter,
                    {
                        [styles.alignCenter]: align === Align.center,
                        [styles.alignLeft]:   align === Align.left,
                        [styles.alignRight]:  align === Align.right,
                    },
                )}
            >
                <span className={appStyles.textSmall}>
                    {props.text}
                    &nbsp;
                    <Link
                        className={appStyles.link}
                        to={props.to}
                    >
                        {I18n.t('clickHere')}
                    </Link>
                </span>
            </div>
        );
    }
}

export const StartPageBoxFooter = Component;

Component.propTypes = {
    align: PropTypes.align,
    text:  PropTypes.string,
    to:    PropTypes.to,
};

Component.defaultProps = {
    align: null,
    text:  null,
    to:    null,
};

export default Component;
