//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import PropTypes from '@/components/PropTypes';
import styles    from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { show, children } = this.props;

        if (show) {
            return (
                <tr className={styles.tableHeaderFilterRow}>
                    {children}
                </tr>
            );
        }

        return null;
    }
}

export const TableHeaderFilterRow = Component;

Component.propTypes = {
    children: PropTypes.children,
    show:     PropTypes.bool,
};

Component.defaultProps = {
    children: null,
    show:     false,
};

export default Component;
