//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const SignupTypes = {
    RESET_SIGNUP:        'Signup/RESET_SIGNUP',
    SET_PASSWORD_SCORE:  'Signup/UPDATE_PASSWORD_SCORE',
    SIGNUP:              'Signup/SIGNUP',
    SIGNUP_FAILED:       'Signup/SIGNUP_FAILED',
    SIGNUP_SUCCEEDED:    'Signup/SIGNUP_SUCCEEDED',
    UPDATE_SIGNUP_FIELD: 'Signup/UPDATE_SIGNUP_FIELD',
};

const resetSignup = makeActionCreator(
    SignupTypes.RESET_SIGNUP,
    {},
);

const signup = makeActionCreator(
    SignupTypes.SIGNUP,
    {},
);

const signupFailed = makeActionCreator(
    SignupTypes.SIGNUP_FAILED,
    {},
);

const signupSucceeded = makeActionCreator(
    SignupTypes.SIGNUP_SUCCEEDED,
    {},
);

const updateSignupField = makeActionCreator(
    SignupTypes.UPDATE_SIGNUP_FIELD,
    {},
);

export const SignupActions = {
    resetSignup,
    signup,
    signupFailed,
    signupSucceeded,
    updateSignupField,
};
