//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import I18n            from 'i18next';
import ComponentHelper from '@/helper/ComponentHelper';
import Icon            from '@/components/stateless/atomic/Icon';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render = () => {
        return (
            <ul className={styles.socialMediaList}>
                <li>
                    <a
                        href={I18n.t('facebookUrl')}
                        rel={'noreferrer'}
                        target={'_blank'}
                        title={I18n.t('supplyDuOnFacebook')}
                    >
                        <Icon iconType={IconType.facebook} />
                    </a>
                </li>
                <li>
                    <a
                        href={I18n.t('linkedinUrl')}
                        rel={'noreferrer'}
                        target={'_blank'}
                        title={I18n.t('supplyDuOnLinkedin')}
                    >
                        <Icon iconType={IconType.linkedin} />
                    </a>
                </li>
            </ul>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const SocialMediaList = Component;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;
