//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _              from 'lodash';
import React          from 'react';
import I18n           from 'i18next';
import CompanyType    from '@/constants/CompanyTypes';
import SideMenuButton from '@/components/stateless/atomic/SideMenuButton';
import Route          from '@/helper/Route';
import Routes         from '@/constants/Routes';

class CompanyMenu {
    static renderMessagesButton = (props, activeRoute, isOwnCompany) => {
        if (props.isOnOwnCompany !== isOwnCompany) {
            return null;
        }

        return (
            <SideMenuButton
                title={I18n.t('companyMessage')}
                to={Route.buildRoute(Routes.companyMessage, props.currentCompanyUrl)}
                active={activeRoute === Routes.companyMessage}
            />
        );
    };

    static renderProjectsButton = (props, activeRoute, isOwnCompany, onClick) => {
        if (props.isOnOwnCompany !== isOwnCompany) {
            return null;
        }

        return (
            <SideMenuButton
                title={I18n.t('projects')}
                onClick={onClick}
                active={activeRoute === Routes.companyProjects}
            />
        );
    };

    static renderUserManagementButton = (props, activeRoute) => {
        return (
            <SideMenuButton
                active={activeRoute === Routes.userManagement}
                to={Route.buildRoute(Routes.userManagement, props.currentCompanyUrl)}
                title={I18n.t('userManagement')}
            />
        );
    };

    static renderSideMenuButtons = (props, activeRoute) => {
        const buttons             = [];
        let renderCompanyOverview = true;
        const { companyTypes }    = props;

        if (_.includes(companyTypes, CompanyType.serviceProvider)) {
            buttons.push(this.renderServiceProviderButtons(props, activeRoute, renderCompanyOverview));

            renderCompanyOverview = false;
        }

        if (_.includes(companyTypes, CompanyType.supplier)) {
            buttons.push(this.renderSupplierButtons(props, activeRoute, renderCompanyOverview));

            renderCompanyOverview = false;
        }

        if (_.includes(companyTypes, CompanyType.machineManufacturer)) {
            buttons.push(this.renderMachineManufacturerButtons(props, activeRoute, renderCompanyOverview));

            renderCompanyOverview = false;
        }

        return buttons;
    };

    static renderCompanyOverview = (props, activeRoute, renderCompanyOverview) => {
        if (!renderCompanyOverview) {
            return null;
        }

        return (
            <>
                <SideMenuButton
                    title={I18n.t('companyOverview')}
                    to={Route.buildRoute(Routes.company, props.currentCompanyUrl)}
                    active={activeRoute === Routes.company}
                />
                {this.renderMessagesButton(props, activeRoute, false)}
            </>
        );
    };

    static renderServiceProviderButtons = (props, activeRoute, renderCompanyOverview) => {
        return (
            <React.Fragment
                key={CompanyType.serviceProvider}
            >
                {this.renderCompanyOverview(props, activeRoute, renderCompanyOverview)}
            </React.Fragment>
        );
    };

    static renderSupplierButtons = (props, activeRoute, renderCompanyOverview) => {
        return (
            <React.Fragment
                key={CompanyType.supplier}
            >
                {this.renderCompanyOverview(props, activeRoute, renderCompanyOverview)}
                <SideMenuButton
                    title={I18n.t('factSheet')}
                    to={Route.buildRoute(Routes.companyFactSheet, props.currentCompanyUrl)}
                    active={activeRoute === Routes.companyFactSheet}
                />
                <SideMenuButton
                    title={I18n.t('productionMachine')}
                    to={Route.buildRoute(Routes.companyMachine, props.currentCompanyUrl)}
                    active={activeRoute === Routes.companyMachine}
                />
                <SideMenuButton
                    title={I18n.t('productionPortfolio')}
                    to={Route.buildRoute(Routes.companyProduction, props.currentCompanyUrl)}
                    active={activeRoute === Routes.companyProduction}
                />
            </React.Fragment>
        );
    };

    static renderJoinCompanyButton = (activeRoute) => {
        return (
            <>
                <SideMenuButton
                    title={I18n.t('joinCompany')}
                    to={Routes.myCompany}
                    active={activeRoute === Routes.myCompany}
                />
            </>
        );
    };

    static renderMachineManufacturerButtons = (props, activeRoute, renderCompanyOverview) => {
        return (
            <React.Fragment
                key={CompanyType.machineManufacturer}
            >
                {this.renderCompanyOverview(props, activeRoute, renderCompanyOverview)}
                {this.renderMachineManufacturerButtonProductButton(props, activeRoute)}
            </React.Fragment>
        );
    };

    static renderMachineManufacturerButtonProductButton = (props, activeRoute) => {
        const { companyTypes } = props;

        if (!_.includes(companyTypes, CompanyType.supplier)) {
            const { currentCompanyUrl } = props;

            return (
                <SideMenuButton
                    title={I18n.t('companyMachine')}
                    to={Route.buildRoute(Routes.companyMachine, currentCompanyUrl)}
                    active={activeRoute === Routes.companyMachine}
                />
            );
        }

        return null;
    };
}

export default CompanyMenu;
