//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import classNames           from 'classnames';
import _                    from 'lodash';
import { Chart as ChartJS } from 'chart.js';
import { ArcElement }       from 'chart.js';
import { Doughnut }         from 'react-chartjs-2';
import { PercentageLabel }  from '@/components/stateless/composed/PercentageLabel';
import PropTypes            from '@/components/PropTypes';
import colors               from '@/styles/colors.module.scss';
import ComponentHelper      from '@/helper/ComponentHelper';
import chroma               from 'chroma-js';

import I18n          from 'i18next';
import { LegendRow } from '@/components/stateless/composed/LegendRow';
import styles        from './styles.module.scss';

export class Component extends React.Component {
    static chartJsPlugins = [
        ArcElement,
    ];

    static colorRange = [
        colors.colorBlueCalypso,
        colors.colorBlackLight,
        colors.colorOrange,
    ];

    getTotalValue = () => {
        return _.sumBy(this.props.data, 'value');
    };

    getPalette = (hasOther) => {
        const palette = _.clone(Component.colorRange);

        if (hasOther) {
            palette.splice(this.props.data.length, 1, colors.colorGrayDark);
        }

        return chroma.scale(palette).colors(this.props.data.length || 1);
    };

    getLegendRowColorStyle = (backgroundColor) => {
        return {
            backgroundColor,
        };
    };

    getDataValues = (data) => {
        return _.map(data, (dataRow) => {
            return _.get(dataRow, 'value', 0);
        });
    };

    prepareData = (data, percent) => {
        if (
            percent > 0 &&
            percent < 100
        ) {
            data.push({
                value: 100 - percent,
                name:  I18n.t('other'),
            });
        }

        return data;
    };

    componentDidMount() {
        ChartJS.register(...Component.chartJsPlugins);
    }

    componentWillUnmount() {
        ChartJS.unregister(...Component.chartJsPlugins);
    }

    render() {
        const props        = this.props;
        const {
            data,
            animate,
            onChartLoaded,
        }            = props;
        const percent      = this.getTotalValue();
        const preparedData = this.prepareData(data, percent);
        const hasOther     = preparedData.some((item) => item.name === I18n.t('other'));
        const palette      = this.getPalette(hasOther);
        const chartData    = {
            datasets: [
                {
                    data:            this.getDataValues(data),
                    backgroundColor: this.getPalette(hasOther),
                    borderWidth:     0,
                },
            ],
        };

        return (
            <div className={styles.pieChartWrapper}>
                <div className={styles.pieChartContentWrapper}>
                    <div className={styles.pieChartContent}>
                        <Doughnut
                            data={chartData}
                            height={null}
                            width={null}
                            options={{
                                cutout:      '70%',
                                plugins:     {
                                    legend: {
                                        display: false,
                                    },
                                },
                                aspectRatio: 1,
                                animation:   {
                                    duration:   animate ? 1000 : 0,
                                    onComplete: onChartLoaded,
                                },
                            }}
                        />
                        <div
                            className={classNames(
                                styles.pieChartContent,
                                styles.pieChartLabelWrapper,
                            )}
                        >
                            <div className={styles.pieChartLabelContent}>
                                <span className={styles.pieChartLabelTotal}>
                                    <PercentageLabel percent={percent} />
                                </span>
                                <span>
                                    {props.labelText}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.pieChartLegend}>
                    {preparedData.map((row, index) => {
                        return (
                            <LegendRow
                                key={row.name}
                                value={row.value}
                                backgroundColor={palette[index]}
                                name={row.name}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const FactSheetPieChart = Component;

Component.propTypes = {
    animate:       PropTypes.bool,
    data:          PropTypes.array,
    labelText:     PropTypes.string,
    onChartLoaded: PropTypes.func,
};

Component.defaultProps = {
    animate:       true,
    data:          [],
    labelText:     null,
    onChartLoaded: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
