//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import classNames from 'classnames';
import PropTypes  from '@/components/PropTypes';
import styles     from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { children, className } = this.props;

        return (
            <td
                className={classNames(
                    styles.tableBodyColumn,
                    className,
                )}
            >
                {children}
            </td>
        );
    }
}

export const TableBodyColumn = Component;

Component.propTypes = {
    children:  PropTypes.children,
    className: PropTypes.string,
};

Component.defaultProps = {
    children:  null,
    className: null,
};

export default Component;
