//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import _                from 'lodash';
import I18n             from 'i18next';
import ColorButton      from '@/components/stateless/atomic/ColorButton';
import PropTypes        from '@/components/PropTypes';
import { TextInput }    from '@/components/stateless/atomic/TextInput';
import ColorButtonTheme from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import styles           from './styles.module.scss';

export class Component extends React.Component {
    authenticate = () => {
        this.props.authenticate();
    };

    passwordChanged = (event) => {
        this.props.passwordChanged({
            password: event.target.value,
        });
    };

    render() {
        return (
            <div className={styles.stagingLoginOverlay}>
                <TextInput
                    type={'password'}
                    onChange={this.passwordChanged}
                    placeholder={I18n.t('stagingPasswordPlaceholder')}
                />
                <ColorButton
                    onClick={this.authenticate}
                    theme={ColorButtonTheme.orange}
                    text={I18n.t('login')}
                />
            </div>
        );
    }
}

export const StagingLoginOverlay = Component;

Component.propTypes = {
    authenticate:    PropTypes.func,
    passwordChanged: PropTypes.func,
};

Component.defaultProps = {
    authenticate:    _.noop,
    passwordChanged: _.noop,
};

export default Component;
