//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { call }       from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import I18n           from 'i18next';

import { SignupActions }   from '@/store/actions/signup';
import { SignupTypes }     from '@/store/actions/signup';
import { AlertBoxActions } from '@/store/actions/alertBox';
import * as Api            from '@/api';
import PasswordStrength    from '@/constants/PasswordStrength';

function* signup() {
    yield put(AlertBoxActions.clearAlerts());

    const signupData             = yield select((state) => state.signup);
    // @formatter:off
    const {
        name,
        identifier,
        password,
        repeatedPassword,
        passwordScore,
        company,
    }                            = signupData;
    // @formatter:on
    const isPasswordSecureEnough = yield PasswordStrength.isPasswordSecureEnough(
        passwordScore,
        password,
        repeatedPassword,
    );

    if (!isPasswordSecureEnough) {
        return;
    }

    const signupObject = {
        name,
        email:         identifier,
        plainPassword: password,
        company,
    };

    const signupResponse = yield call(
        Api.signup,
        signupObject,
    );

    if (signupResponse.ok) {
        yield put(SignupActions.signupSucceeded());
    } else {
        yield put(SignupActions.signupFailed());
    }
}

function* signupSucceeded() {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('signupSucceeded'),
    }));
    yield put(SignupActions.resetSignup());
}

function* signupFailed() {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('signupFailed'),
    }));
}

export const callSignupSagas = () => {
    return [
        // @formatter:off
        takeLatest([SignupTypes.SIGNUP],           signup),
        takeLatest([SignupTypes.SIGNUP_FAILED],    signupFailed),
        takeLatest([SignupTypes.SIGNUP_SUCCEEDED], signupSucceeded),
        // @formatter:on
    ];
};
