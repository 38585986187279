//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@/components/PropTypes';
import Link      from '@/components/stateless/atomic/Link';
import Icon      from '@/components/stateless/atomic/Icon';

import styles from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { to, onClick } = this.props;

        if (to || onClick) {
            return (
                <Link
                    className={styles.sideMenuButtonWrapper}
                    to={to}
                    onClick={onClick}
                >
                    {this.renderContent()}
                </Link>
            );
        }

        console.warn('Please pass "to" or "onClick" to render SideMenuButton');

        return null;
    }

    renderContent = () => {
        return (
            <>
                <strong className={styles.sideMenuButtonTitle}>
                    {this.props.title}
                </strong>
                {this.renderIcon()}
            </>
        );
    };

    renderIcon = () => {
        const { icon } = this.props;

        if (icon) {
            return (
                <div className={styles.iconContainer}>
                    <Icon iconType={icon} />
                </div>
            );
        }

        return null;
    };
}

export const SideMenuButtonSmall = Component;

Component.propTypes = {
    icon:    PropTypes.string,
    onClick: PropTypes.func,
    title:   PropTypes.string,
    to:      PropTypes.to,
};

Component.defaultProps = {
    icon:    null,
    onClick: null,
    title:   null,
    to:      null,
};

export default Component;
