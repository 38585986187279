//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                                     from 'react';
import _                                         from 'lodash';
import { bindActionCreators }                    from 'redux';
import { compose }                               from 'redux';
import { connect }                               from 'react-redux';
import ComponentHelper                           from '@/helper/ComponentHelper';
import PropTypes                                 from '@/components/PropTypes';
import { MachineActions }                        from '@/store/actions/machine';
import AddNewMachineManufacturerOverlayStateless from '@/components/stateless/composed/AddNewMachineManufacturerOverlay';
import { NavigationActions }                     from '@/store/actions/navigation';

export class Component extends React.Component {
    okButtonPressed = () => {
        this.props.updateOrCreateMachine();
    };

    closeButtonPressed = () => {
        this.props.goBack();
    };

    isManufacturerChanged = () => {
        this.props.isManufacturerChanged();
    };

    addMachineParameterClicked = () => {
        this.props.addMachineParameter();
    };

    deleteMachineParameterClicked = (index) => () => {
        this.props.removeMachineParameter({
            index,
        });
    };

    onMachineParameterNameChanged = (index) => (event) => {
        this.props.machineParameterNameChanged({
            name: event.target.value,
            index,
        });
    };

    onMachineParameterValueChanged = (index) => (event) => {
        this.props.machineParameterValueChanged({
            value: event.target.value,
            index,
        });
    };

    onMachineParameterUnitChanged = (index) => (event) => {
        this.props.machineParameterUnitChanged({
            unit: event.target.value,
            index,
        });
    };

    render() {
        const {
            isManufacturer,
            machineParameters,
        } = this.props;

        return (
            <AddNewMachineManufacturerOverlayStateless
                okButtonPressed={this.okButtonPressed}
                closeButtonPressed={this.closeButtonPressed}
                isManufacturer={isManufacturer}
                isManufacturerChanged={this.isManufacturerChanged}
                machineParameters={machineParameters}
                addMachineParameterClicked={this.addMachineParameterClicked}
                deleteMachineParameterClicked={this.deleteMachineParameterClicked}
                onMachineParameterNameChanged={this.onMachineParameterNameChanged}
                onMachineParameterValueChanged={this.onMachineParameterValueChanged}
                onMachineParameterUnitChanged={this.onMachineParameterUnitChanged}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AddNewMachineManufacturerOverlay = Component;

Component.propTypes = {
    abortMachineEdit:             PropTypes.func,
    addMachineParameter:          PropTypes.func,
    goBack:                       PropTypes.func,
    isManufacturer:               PropTypes.bool,
    isManufacturerChanged:        PropTypes.func,
    machineParameterNameChanged:  PropTypes.func,
    machineParameters:            PropTypes.array,
    machineParameterUnitChanged:  PropTypes.func,
    machineParameterValueChanged: PropTypes.func,
    removeMachineParameter:       PropTypes.func,
    updateOrCreateMachine:        PropTypes.func,
};

Component.defaultProps = {
    abortMachineEdit:             _.noop,
    addMachineParameter:          _.noop,
    goBack:                       _.noop,
    isManufacturer:               false,
    isManufacturerChanged:        _.noop,
    machineParameterNameChanged:  _.noop,
    machineParameters:            [],
    machineParameterUnitChanged:  _.noop,
    machineParameterValueChanged: _.noop,
    removeMachineParameter:       _.noop,
    updateOrCreateMachine:        _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...MachineActions,
        ...NavigationActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    const machine      = _.get(state, 'machine.ownMachineEdit');
    let isManufacturer = _.get(machine, 'isManufacturer');
    const manufacturer = _.get(machine, 'manufacturer');

    if (manufacturer) {
        isManufacturer = true;
    }

    return {
        isManufacturer,
        machineParameters: _.get(machine, 'parameters'),
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
