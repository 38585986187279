//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class Faker {
    static getWord = (options) => {
        const settings   = options || {};
        const consonants = 'bcdfghjklmnprstvwz';
        const vowels     = 'aeiou';
        let length       = settings.length === undefined ? 3 : settings.length;
        let chars        = '';
        let chr          = '';

        if (length < 0) {
            length = 1;
        }

        for (let i = 0; i < length; i++) {
            if (i === 0) {
                chr = (consonants + vowels).charAt(Math.floor(Math.random() * (consonants + vowels).length));
            } else if (!consonants.includes(chr)) {
                chr = consonants.charAt(Math.floor(Math.random() * consonants.length));
            } else {
                chr = vowels.charAt(Math.floor(Math.random() * vowels.length));
            }

            chars += chr;
        }

        return chars;
    };

    static getSentence = (options) => {
        const settings = options || {};
        let words      = settings.words === undefined ? 5 : settings.words;
        let sentence   = '';

        if (words < 0) {
            words = 5;
        }

        for (let i = 0; i < words; i++) {
            sentence += Faker.getWord({
                length: Math.floor(2 + (Math.random() * 9)),
            });
            if (i !== words - 1) {
                sentence += ' ';
            }
        }

        return `${sentence.charAt(0).toUpperCase()}${sentence.slice(1)}`;
    };
}

export default Faker;
