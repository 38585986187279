//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes       from '@/components/PropTypes';
import ComponentHelper from '@/helper/ComponentHelper';
import AlignItems      from '@/components/stateless/atomic/Flex/AlignItems';
import JustifyContent  from '@/components/stateless/atomic/Flex/JustifyContent';
import FlexDirection   from '@/components/stateless/atomic/Flex/FlexDirection';

import styles from './styles.module.scss';

export class Component extends React.Component {
    getStyle = () => {
        const style = {
            flexDirection:  this.props.flexDirection,
            gap:            this.props.gap,
            alignItems:     this.props.alignItems,
            justifyContent: this.props.justifyContent,
        };

        if (this.props.flex > 0) {
            style.flex = this.props.flex;
        }

        return style;
    };

    render() {
        const style = this.getStyle();

        return (
            <div
                className={styles.flex}
                style={style}
            >
                {this.props.children}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Flex = Component;

Component.propTypes = {
    flex:           PropTypes.number,
    flexDirection:  PropTypes.oneOfObjectValues(FlexDirection),
    children:       PropTypes.children,
    gap:            PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alignItems:     PropTypes.oneOfObjectValues(AlignItems),
    justifyContent: PropTypes.oneOfObjectValues(JustifyContent),
};

Component.defaultProps = {
    flex:           1,
    flexDirection:  FlexDirection.row,
    children:       null,
    gap:            '0px',
    alignItems:     null,
    justifyContent: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
