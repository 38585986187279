//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';

import ComponentHelper               from '@/helper/ComponentHelper';
import PropTypes                     from '@/components/PropTypes';
import StatelessCreateProjectOverlay from '@/components/stateless/composed/CreateProjectOverlay';
import { ProjectsActions }           from '@/store/actions/projects';
import { CreateProjectSchema }       from '@/validations/createProjects';
import { TagActions }                from '@/store/actions/tag';

export class Component extends React.Component {
    onSaveButtonPressed = () => {
        this.props.createProject();
    };

    onSaveButtonValidator = () => {
        const validation = CreateProjectSchema.safeParse(this.props.data);

        if (!validation.success) {
            this.props.createProjectOverlayValidationChanged({
                validation,
            });

            return false;
        }

        return true;
    };

    render() {
        return (
            <StatelessCreateProjectOverlay
                data={this.props.data}
                validation={this.props.validation}
                onFormDataChanged={this.props.createProjectOverlayFormDataChanged}
                onSaveButtonPressed={this.onSaveButtonPressed}
                onSaveButtonValidator={this.onSaveButtonValidator}
                onTagSearch={this.props.fetchTags}
                tags={this.props.tags}
                addIndustryTag={this.props.addIndustryTag}
                addTechnologyTag={this.props.addTechnologyTag}
                addMaterialTag={this.props.addMaterialTag}
                addPartFamilyTag={this.props.addPartFamilyTag}
                addMandatoryTag={this.props.addMandatoryTag}
                addInspectionTag={this.props.addInspectionTag}
                removeIndustryTag={this.props.removeIndustryTag}
                removeTechnologyTag={this.props.removeTechnologyTag}
                removeMaterialTag={this.props.removeMaterialTag}
                removePartFamilyTag={this.props.removePartFamilyTag}
                removeMandatoryTag={this.props.removeMandatoryTag}
                removeInspectionTag={this.props.removeInspectionTag}
                resetTagQuery={this.props.resetTagQuery}
                isLoading={this.props.tags.isLoading}
                onFilesChanged={this.props.filesChanged}
                onFileDeleted={this.props.fileDeleted}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const CreateProjectOverlay = Component;

Component.propTypes = {
    addIndustryTag:                        PropTypes.func,
    addInspectionTag:                      PropTypes.func,
    addMandatoryTag:                       PropTypes.func,
    addMaterialTag:                        PropTypes.func,
    addPartFamilyTag:                      PropTypes.func,
    addTechnologyTag:                      PropTypes.func,
    createProject:                         PropTypes.func,
    createProjectOverlayFormDataChanged:   PropTypes.func,
    createProjectOverlayValidationChanged: PropTypes.func,
    data:                                  PropTypes.object,
    fetchTags:                             PropTypes.func,
    fileDeleted:                           PropTypes.func,
    filesChanged:                          PropTypes.func,
    removeIndustryTag:                     PropTypes.func,
    removeInspectionTag:                   PropTypes.func,
    removeMandatoryTag:                    PropTypes.func,
    removeMaterialTag:                     PropTypes.func,
    removePartFamilyTag:                   PropTypes.func,
    removeTechnologyTag:                   PropTypes.func,
    resetTagQuery:                         PropTypes.func,
    tags:                                  PropTypes.object,
    validation:                            PropTypes.object,
};

Component.defaultProps = {
    addIndustryTag:                        _.noop,
    addInspectionTag:                      _.noop,
    addMandatoryTag:                       _.noop,
    addMaterialTag:                        _.noop,
    addPartFamilyTag:                      _.noop,
    addTechnologyTag:                      _.noop,
    createProject:                         _.noop,
    createProjectOverlayFormDataChanged:   _.noop,
    createProjectOverlayValidationChanged: _.noop,
    data:                                  {},
    fetchTags:                             _.noop,
    fileDeleted:                           _.noop,
    filesChanged:                          _.noop,
    removeIndustryTag:                     _.noop,
    removeInspectionTag:                   _.noop,
    removeMandatoryTag:                    _.noop,
    removeMaterialTag:                     _.noop,
    removePartFamilyTag:                   _.noop,
    removeTechnologyTag:                   _.noop,
    resetTagQuery:                         _.noop,
    tags:                                  {},
    validation:                            {},
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...ProjectsActions,
        ...TagActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    const allTagResults = _.get(state, 'tag.tags', []);

    return {
        data:       _.get(state, 'projects.createProjectOverlayForm.data'),
        validation: _.get(state, 'projects.createProjectOverlayForm.validation'),
        tags:       {
            isLoading: _.get(state, 'tag.isLoading'),
            query:     _.get(state, 'tag.tagQuery'),
            results:   allTagResults,
        },
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
