//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import _                         from 'lodash';
import { bindActionCreators }    from 'redux';
import { connect }               from 'react-redux';
import ComponentHelper           from '@/helper/ComponentHelper';
import PropTypes                 from '@/components/PropTypes';
import StatelessHeaderUserButton from '@/components/stateless/atomic/HeaderUserButton';
import { UserActions }           from '@/store/actions/user';
import { NavigationActions }     from '@/store/actions/navigation';
import Routes                    from '@/constants/Routes';

export class Component extends React.Component {
    logoutButtonClicked = () => {
        this.props.logout();
    };

    myProfileClicked = () => {
        this.props.openUrl({
            url: Routes.myProfile,
        });
    };

    render() {
        return (
            <StatelessHeaderUserButton
                badgeCount={this.props.badgeCount}
                isLoggedIn={this.props.isLoggedIn}
                company={this.props.userCompany}
                onLogoutClicked={this.logoutButtonClicked}
                onMyProfileClicked={this.myProfileClicked}
                user={this.props.user}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const HeaderUserButton = Component;

Component.propTypes = {
    badgeCount:  PropTypes.number,
    isLoggedIn:  PropTypes.bool,
    logout:      PropTypes.func,
    openUrl:     PropTypes.func,
    user:        PropTypes.object,
    userCompany: PropTypes.object,
};

Component.defaultProps = {
    badgeCount:  null,
    isLoggedIn:  false,
    logout:      _.noop,
    openUrl:     _.noop,
    user:        null,
    userCompany: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => {
    const user          = _.get(state, 'user');
    const userCompanyId = _.get(state, ['user', 'company']);
    const userToken     = _.get(state, ['user', 'token'], false);
    let userCompany     = null;

    if (userCompanyId) {
        userCompany = _.get(state, ['company', 'companies', userCompanyId]);
    }

    return {
        user,
        userCompany,
        isLoggedIn: !!userToken,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...UserActions,
        ...NavigationActions,
    },
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
