//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import classNames      from 'classnames';
import Align           from '@/constants/Align';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    renderContent() {
        const { text, children } = this.props;

        if (!_.isEmpty(text)) {
            return text;
        }

        return children;
    }

    render() {
        return (
            <h2
                className={classNames(
                    styles.headlineMedium,
                    {
                        [styles.alignCenter]: this.props.align === Align.center,
                        [styles.alignLeft]:   this.props.align === Align.left,
                        [styles.alignRight]:  this.props.align === Align.right,
                    },
                    this.props.className,
                )}
            >
                {this.renderContent()}
            </h2>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const HeadlineMedium = Component;

Component.propTypes = {
    align:     PropTypes.align,
    children:  PropTypes.children,
    className: PropTypes.string,
    text:      PropTypes.string,
};

Component.defaultProps = {
    align:     Align.left,
    children:  null,
    className: '',
    text:      '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
