//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import Icon            from '@/components/stateless/atomic/Icon';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { onClick } = this.props;

        return (
            <h3 className={styles.headline}>
                <span
                    className={classNames(
                        styles.headlineSmall,
                        {
                            [styles.headlineClickable]:         onClick,
                            [styles.headlineClickableDisabled]: onClick && this.props.disabled,
                        },
                    )}
                    onClick={onClick}
                >
                    {this.props.text}
                    {this.renderIcon()}
                </span>
                {this.renderChildren()}
            </h3>
        );
    }

    renderChildren = () => {
        const children = this.props.children;

        if (children) {
            return (
                <div className={styles.headlineSmallChildren}>
                    {children}
                </div>
            );
        }

        return null;
    };

    renderIcon = () => {
        if (this.props.iconType) {
            return (
                <Icon iconType={this.props.iconType} />
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const HeadlineSmall = Component;

Component.propTypes = {
    children: PropTypes.children,
    disabled: PropTypes.bool,
    iconType: PropTypes.icon,
    onClick:  PropTypes.func,
    text:     PropTypes.string,
};

Component.defaultProps = {
    children: null,
    disabled: false,
    iconType: null,
    onClick:  null,
    text:     '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
