//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import update          from 'immutability-helper';
import { FilterTypes } from '@/store/actions/filter';

const initialState = {
    filters: {},
};

const applyFilter = (action, state) => {
    return update(state, {
        filters: {
            [action.filter]: {
                [action.field]: {
                    $set: {
                        value: action.value,
                        query: action.fieldQuery,
                    },
                },
            },
        },
    });
};

const resetFilter = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const toggleFilter = (action, state) => {
    return update(state, {
        filters: {
            $set: {
                [action.filter]: {
                    isOpen: !_.get(state.filters, [action.filter, 'isOpen'], false),
                },
            },
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case FilterTypes.APPLY_FILTER:  return applyFilter(action, state);
        case FilterTypes.RESET_FILTER:  return resetFilter(action, state);
        case FilterTypes.TOGGLE_FILTER: return toggleFilter(action, state);
        default:                        return state;
        // @formatter:on
    }
}
