//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import _                          from 'lodash';
import ComponentHelper            from '@/helper/ComponentHelper';
import PropTypes                  from '@/components/PropTypes';
import IconType                   from '@/components/stateless/atomic/Icon/IconType';
import IconTextButton             from '@/components/stateless/atomic/IconTextButton';
import ShowIfUserIsAdminOfCompany from '@/components/connected/ShowIfUserIsAdminOfCompany';
import styles                     from './styles.module.scss';

export class Component extends React.Component {
    onEditButtonClicked = (event) => {
        this.props.editButtonPressed(event);
    };

    renderEditButton = () => {
        if (_.eq(this.props.editButtonPressed, _.noop)) {
            return null;
        }

        return (
            <div className={styles.factSheetWrapperActions}>
                <ShowIfUserIsAdminOfCompany
                    currentCompanyId={this.props.currentCompanyId}
                >
                    <IconTextButton
                        onClick={this.onEditButtonClicked}
                        iconType={IconType.pencil}
                    />
                </ShowIfUserIsAdminOfCompany>
            </div>
        );
    };

    renderHeadline = () => {
        const { headline } = this.props;

        if (!headline) {
            return null;
        }

        return (
            <div className={styles.factSheetWrapperHeader}>
                <div className={styles.factSheetWrapperHeadline}>
                    {headline}
                </div>
                {this.renderEditButton()}
            </div>
        );
    };

    render() {
        return (
            <div className={styles.factSheetWrapper}>
                {this.renderHeadline()}
                <div className={styles.factSheetWrapperContent}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const FactSheetBox = Component;

Component.propTypes = {
    actions:           PropTypes.children,
    children:          PropTypes.children,
    currentCompanyId:  PropTypes.string,
    editButtonPressed: PropTypes.func,
    headline:          PropTypes.string,
};

Component.defaultProps = {
    actions:           null,
    children:          null,
    currentCompanyId:  null,
    editButtonPressed: _.noop,
    headline:          null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
