//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import ComponentHelper from '@/helper/ComponentHelper';
import HighlightedText from '@/components/stateless/atomic/HighlightedText';
import Icon            from '@/components/stateless/atomic/Icon';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div
                className={styles.tagSearchResultWrapper}
                onClick={this.props.onClick}
            >
                <div className={styles.searchIconWrapper}>
                    <Icon iconType={IconType.search} />
                </div>
                <HighlightedText
                    highlightText={this.props.highlightText}
                    text={this.getText()}
                />
            </div>
        );
    }

    getText() {
        const tagChain = _.get(this, 'props.tagChain', []);

        return tagChain.join(' > ');
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const TagSearchResult = Component;

Component.propTypes = {
    highlightText: PropTypes.string,
    onClick:       PropTypes.func,
    tagChain:      PropTypes.array,
};

Component.defaultProps = {
    highlightText: '',
    onClick:       _.noop,
    tagChain:      [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
