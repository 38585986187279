//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React             from 'react';
import _                 from 'lodash';
import classNames        from 'classnames';
import PropTypes         from '@/components/PropTypes';
import { HeadlineSmall } from '@/components/stateless/atomic/HeadlineSmall';
import styles            from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const title = _.get(this.props, 'tab.title', '');

        return (
            <div
                ref={this.props.forwardedRef}
                className={classNames(
                    styles.tabWrapper,
                    {
                        [styles.tabActive]: this.props.active,
                    },
                )}
                onClick={this.props.onClick}
            >
                <HeadlineSmall text={title} />
                {this.renderCount()}
            </div>
        );
    }

    renderCount = () => {
        const badgeCount = _.get(this.props, 'tab.badgeCount', 0);

        return (
            <span className={styles.count}>
                {badgeCount}
            </span>
        );
    };
}

const Tab = React.forwardRef((props, ref) => {
    return <Component {...props} forwardedRef={ref} />;
});

Component.propTypes = {
    active:       PropTypes.bool,
    onClick:      PropTypes.func,
    tab:          PropTypes.tab,
    forwardedRef: PropTypes.func,
};

Component.defaultProps = {
    active:       false,
    onClick:      _.noop,
    tab:          null,
    forwardedRef: null,
};

export default Tab;
