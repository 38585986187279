//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                    from 'react';
import _                        from 'lodash';
import { connect }              from 'react-redux';
import { withRouter }           from 'react-router';
import ComponentHelper          from '@/helper/ComponentHelper';
import PropTypes                from '@/components/PropTypes';
import StatelessProfileSideMenu from '@/components/stateless/composed/ProfileSideMenu';
import Navigation               from '@/helper/Navigation';
import { bindActionCreators }   from 'redux';
import { ProfileActions }       from '@/store/actions/profile';

export class Component extends React.Component {
    render() {
        return (
            <StatelessProfileSideMenu
                {...this.props}
                verifiedCertificateURL={this.props.verifiedCertificateURL}
                isOnOwnProfile={this.props.isOnOwnProfile}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    isOnOwnProfile:         PropTypes.bool,
    route:                  PropTypes.string,
    verifiedCertificateURL: PropTypes.string,
};

Component.defaultProps = {
    isOnOwnProfile:         false,
    route:                  null,
    verifiedCertificateURL: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state, ownProps) => {
    const ownProfileId   = _.get(state, 'user.profile');
    const isOnOwnProfile = Navigation.isOwnProfileVisible(ownProps, ownProfileId);

    return {
        isOnOwnProfile,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...ProfileActions,
    },
    dispatch,
);

export const ProfileSideMenu = Component;

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component));
