//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import { connect }     from 'react-redux';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';

export class Component extends React.Component {
    render() {
        if (this.props.userCompanyId && this.props.userCompanyId === this.props.currentCompanyId) {
            return this.props.children;
        }

        if (this.props.fallbackContent) {
            return this.props.fallbackContent;
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ShowIfUserIsAdminOfCompany = Component;

Component.propTypes = {
    children:         PropTypes.children,
    currentCompanyId: PropTypes.string,
    fallbackContent:  PropTypes.children,
    userCompanyId:    PropTypes.string,
};

Component.defaultProps = {
    children:         null,
    currentCompanyId: null,
    fallbackContent:  null,
    userCompanyId:    null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        userCompanyId: _.get(state, 'user.company'),
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
