//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Tooltip }     from '@/components/stateless/atomic/Tooltip';
import _               from 'lodash';
import React           from 'react';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.tag}>
                <Tooltip content={this.renderTooltip()}>
                    <span className={styles.tagContent}>
                        {this.props.tag}
                    </span>
                </Tooltip>
            </div>
        );
    }

    renderTooltip = () => {
        const id      = _.uniqueId('tag-path');
        const toolTip = this.props.tree;

        if (toolTip.length === 0) {
            return (
                <strong>
                    {this.props.tag}
                </strong>
            );
        }

        const tooltipElements = toolTip.map((item, index) => (
            <span key={`${id}-${item}`}>
                {item}
                {index < toolTip.length - 1 && ' > '}
            </span>
        ));

        tooltipElements.push(<>
            {' > '}
            <strong>
                {this.props.tag}
            </strong>
        </>);

        return tooltipElements;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Tag = Component;

Component.propTypes = {
    index: PropTypes.number,
    tag:   PropTypes.string,
    tree:  PropTypes.array,
};

Component.defaultProps = {
    index: -1,
    tag:   '',
    tree:  [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
