//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Routes from '@/constants/Routes';

/**
 * All routes that are defined in this array are reachable without the
 * requirement of a active/valid session. If no session is present on
 * a route that is not in this array, the user is automatically redirected
 * to Routes.login (in the restoreToken-saga).
 *
 * @type {(string)[]}
 */
const routesWithoutSession = [
    Routes.home,
    Routes.imprint,
    Routes.login,
    Routes.signUp,
    Routes.searchResults,
    Routes.lostPassword,
    Routes.screenDesign,
    Routes.membership,
];

const routePartsWithoutSession = [
    Routes.companyPart,
];

export default class RedirectCheck {
    static checkSessionRedirect(user, pathname) {
        const browserPathname = window.location.pathname;

        return (
            !user.token &&
            !routePartsWithoutSession.filter((option) => browserPathname.startsWith(option)).length &&
            (
                routesWithoutSession.indexOf(pathname) === -1 ||
                routesWithoutSession.indexOf(browserPathname) === -1
            )
        );
    }

    static getSessionRedirect(pathname) {
        let route       = Routes.login;
        const urlParams = new URLSearchParams();

        if (pathname === Routes.membershipRequest) {
            urlParams.set('redirect', Routes.membershipRequest);

            route += `?${urlParams.toString()}`;
        }

        return route;
    }
}
