//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div
                className={classNames(
                    styles.startPageBackground,
                    {
                        [styles.fullHeight]: this.props.fullHeight,
                    },
                )}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const StartPageBackground = Component;

Component.propTypes = {
    fullHeight: PropTypes.bool,
};

Component.defaultProps = {
    fullHeight: false,
};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;
