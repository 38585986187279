//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import classNames      from 'classnames';
import React           from 'react';
import Icon            from '@/components/stateless/atomic/Icon';
import PropTypes       from '@/components/PropTypes';
import SelectionHelper from '@/helper/SelectionHelper';
import Link            from '@/components/stateless/atomic/Link';
import ComponentHelper from '@/helper/ComponentHelper';
import styles          from './styles.module.scss';
import IconButtonTheme from './IconButtonTheme';

export class Component extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }

    getClassName = () => {
        const className = classNames(
            styles.iconButton,
            SelectionHelper.get(
                this.props.theme,
                {
                    [IconButtonTheme.grayBorder]:       styles.iconButtonGrayBorder,
                    [IconButtonTheme.grayRound]:        styles.iconButtonGrayRound,
                    [IconButtonTheme.plain]:            styles.iconButtonPlain,
                    [IconButtonTheme.blackRectangular]: styles.iconButtonBlackRectangular,
                },
            ),
        );

        return className;
    };

    render() {
        const finalClassName = this.getClassName();
        const icon           = this.renderIcon();

        if (this.props.onClick) {
            return (
                <div
                    className={finalClassName}
                    onClick={this.props.onClick}
                >
                    {icon}
                </div>
            );
        }

        if (this.props.to) {
            return (
                <Link
                    className={finalClassName}
                    to={this.props.to}
                >
                    {icon}
                </Link>
            );
        }

        return null;
    }

    renderIcon = () => {
        return (
            <Icon
                iconType={this.props.iconType}
            />
        );
    };
}

export const IconButton = Component;

Component.propTypes = {
    iconType: PropTypes.icon,
    onClick:  PropTypes.func,
    theme:    PropTypes.oneOf(Object.values(IconButtonTheme)),
    to:       PropTypes.to,
};

Component.defaultProps = {
    iconType: null,
    onClick:  null,
    theme:    null,
    to:       null,
};

Component.renderAffectingProps = [
    'iconType',
    'theme',
    'to',
];

Component.renderAffectingStates = [];

export default Component;
