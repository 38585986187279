//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    // Large overlay, which is used to edit the page content
    full: 'full',
    // Small and cancelable dialog which size is defined by its content
    prompt: 'prompt',
    // Same as prompt but with a bigger max-width for wider content
    widePrompt: 'widePrompt',
};
