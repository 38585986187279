//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import _                      from 'lodash';

import ComponentHelper    from '@/helper/ComponentHelper';
import PropTypes          from '@/components/PropTypes';
import Overlays           from '@/constants/Overlays';
import OverlayType        from '@/components/connected/Overlay/OverlayType';
import Overlay            from '@/components/connected/Overlay';
import { OverlayActions } from '@/store/actions/ovleray';
import HeadlineMedium     from '@/components/stateless/atomic/HeadlineMedium';
import Align              from '@/constants/Align';
import Spacer             from '@/components/stateless/atomic/Spacer';
import { BoldTranslation } from '@/components/stateless/composed/BoldTranslation'; import styles             from './styles.module.scss';

export class Component extends React.Component {
    okButtonPressed = () => {
        const { triggerConfirmAction } = this.props;

        triggerConfirmAction();
    };

    cancelButtonPressed = () => {
        const { triggerCancelAction, confirmDialog } = this.props;

        if (_.isNil(confirmDialog.cancelAction)) {
            return;
        }

        triggerCancelAction();
    };

    getOverlayType() {
        const { confirmDialog } = this.props;
        const { overlayType }   = confirmDialog;

        return _.either(overlayType, OverlayType.prompt);
    }

    render() {
        const confirmDialog = this.props.confirmDialog;

        if (!confirmDialog) {
            return null;
        }

        const { cancelButtonText, confirmButtonText, message, title } = confirmDialog;
        let messageElement                                            = message;

        if (_.isObject(message)) {
            messageElement = <BoldTranslation {...message.props} />;
        }

        return (
            <Overlay
                className={styles.confirmOverlay}
                id={Overlays.confirmOverlay}
                okButtonPressed={this.okButtonPressed}
                okButtonText={confirmButtonText}
                overlayType={this.getOverlayType()}
                cancelButtonText={cancelButtonText}
                cancelButtonPressed={this.cancelButtonPressed}
            >
                <HeadlineMedium
                    text={title}
                    align={Align.center}
                />
                <Spacer height={20} />
                <div className={styles.confirmOverlayWrapper}>
                    {messageElement}
                </div>
            </Overlay>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ConfirmOverlay = Component;

Component.propTypes = {
    confirmDialog:        PropTypes.object,
    triggerConfirmAction: PropTypes.func,
    triggerCancelAction:  PropTypes.func,
};

Component.defaultProps = {
    confirmDialog:        null,
    triggerConfirmAction: _.noop,
    triggerCancelAction:  _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...OverlayActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    return {
        confirmDialog: _.get(state, 'overlay.confirmDialog'),
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
