//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import VerifiedProps              from '@/constants/VerifiedProps';
import React                      from 'react';
import _                          from 'lodash';
import { connect }                from 'react-redux';
import I18n                       from 'i18next';
import moment                     from 'moment';
import ComponentHelper            from '@/helper/ComponentHelper';
import PropTypes                  from '@/components/PropTypes';
import { EntityLabel }            from '@/components/stateless/composed/EntityLabel';
import EntityLabelSize            from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import Route                      from '@/helper/Route';
import Routes                     from '@/constants/Routes';
import * as Api                   from '@/api';
import { ContentHeaderStateless } from '@/components/stateless/composed/ContentHeaderStateless';
import State                      from '@/helper/State';
import Company                    from '@/helper/Company';

export class Component extends React.Component {
    render() {
        const props = this.props;

        return (
            <ContentHeaderStateless
                leftContent={
                    <EntityLabel
                        title={props.companyName}
                        subtitle={Company.getCompanyTypesString(props.companyTypes)}
                        verified={props.companyVerified}
                        avatar={Api.getImagePath(props.companyLogo)}
                        size={EntityLabelSize.default}
                        iso31661Alpha2CountryCode={_.get(props, 'companyAddress.countryCode', null)}
                        to={Route.buildRoute(Routes.company, props.currentCompanyUrl)}
                    />
                }
                rightContent={this.props.rightContent}
                infoText={I18n.t(
                    'lastUpdate',
                    {
                        updateString: moment(this.props.companyLastUpdate).fromNow(),
                    },
                )}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ContentHeader = Component;

Component.propTypes = {
    companyAddress:    PropTypes.object,
    companyLastUpdate: PropTypes.string,
    companyLogo:       PropTypes.string,
    companyName:       PropTypes.string,
    companyTypes:      PropTypes.array,
    companyVerified:   PropTypes.object,
    currentCompanyId:  PropTypes.string,
    currentCompanyUrl: PropTypes.string,
    rightContent:      PropTypes.children,
};

Component.defaultProps = {
    companyAddress:    {},
    companyLastUpdate: null,
    companyLogo:       null,
    companyName:       null,
    companyTypes:      [],
    companyVerified:   VerifiedProps,
    currentCompanyId:  null,
    currentCompanyUrl: null,
    rightContent:      null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state, ownProps) => {
    const companyId = ownProps.currentCompanyId;

    return {
        companyAddress:    State.getCompanyValue(state, companyId, 'address'),
        companyLastUpdate: State.getCompanyValue(state, companyId, 'updatedAt'),
        companyLogo:       State.getCompanyValue(state, companyId, 'logo.path', null),
        companyName:       State.getCompanyValue(state, companyId, 'name'),
        companyTypes:      State.getCompanyValue(state, companyId, 'types', []),
        companyVerified:   State.getCompanyVerified(state, companyId),
    };
};

export default connect(
    mapStateToProps,
    null,
)(Component);
