//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                         from 'immutability-helper';
import { NavigationTypes }            from '@/store/actions/navigation';
import { StagingAuthenticationTypes } from '@/store/actions/stagingAuthentication';

const stagingPassword = 'kama2022';

const initialState = {
    isAuthenticated: false,
    password:        null,
};

const reset = () => {
    return initialState;
};

const authenticate = (action, state) => {
    return update(state, {
        isAuthenticated: {
            $set: state.password === stagingPassword,
        },
    });
};

const authenticationPasswordChanged = (action, state) => {
    return update(state, {
        password: {
            $set: action.password,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case NavigationTypes.RESET_STATE:                                return reset(action, state);
        case StagingAuthenticationTypes.AUTHENTICATE:                    return authenticate(action, state);
        case StagingAuthenticationTypes.AUTHENTICATION_PASSWORD_CHANGED: return authenticationPasswordChanged(action, state);
        default:                                                         return state;
        // @formatter:on
    }
}
