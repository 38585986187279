//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { combineReducers }          from 'redux';
import { connectRouter }            from 'connected-react-router';
import alertBoxReducer              from './alertBox';
import companyFactSheetReducer      from './companyFactSheet';
import companyReducer               from './company';
import companyTypeReducer           from './companyType';
import cookieBannerReducer          from './cookieBanner';
import filterReducer                from './filter';
import loadingReducer               from './loading';
import lostPasswordReducer          from './lostPassword';
import machineReducer               from './machine';
import membershipReducer            from './membership';
import messageRequestReducer        from './messageRequest';
import overlayReducer               from './overlay';
import partnerReducer               from './partner';
import productReducer               from './product';
import profileReducer               from './profile';
import projectsReducer              from './projects';
import searchReducer                from './search';
import signupReducer                from './signup';
import smallScreenReducer           from './smallScreen';
import stagingAuthenticationReducer from './stagingAuthentication';
import tagReducer                   from './tag';
import userReducer                  from './user';

export default (history) => combineReducers({
    alertBox:              alertBoxReducer,
    company:               companyReducer,
    companyFactSheet:      companyFactSheetReducer,
    companyType:           companyTypeReducer,
    cookieBanner:          cookieBannerReducer,
    filter:                filterReducer,
    loading:               loadingReducer,
    lostPassword:          lostPasswordReducer,
    machine:               machineReducer,
    membership:            membershipReducer,
    messageRequest:        messageRequestReducer,
    overlay:               overlayReducer,
    partner:               partnerReducer,
    product:               productReducer,
    profile:               profileReducer,
    projects:              projectsReducer,
    search:                searchReducer,
    signup:                signupReducer,
    smallScreen:           smallScreenReducer,
    stagingAuthentication: stagingAuthenticationReducer,
    tag:                   tagReducer,
    user:                  userReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
});
