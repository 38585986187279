//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                       from 'react';
import { bindActionCreators }      from 'redux';
import { compose }                 from 'redux';
import { connect }                 from 'react-redux';
import I18n                        from 'i18next';
import _                           from 'lodash';
import ComponentHelper             from '@/helper/ComponentHelper';
import PropTypes                   from '@/components/PropTypes';
import FactSheetEditType           from '@/constants/FactSheetEditType';
import Overlays                    from '@/constants/Overlays';
import OverlayType                 from '@/components/connected/Overlay/OverlayType';
import Overlay                     from '@/components/connected/Overlay';
import { CompanyFactSheetActions } from '@/store/actions/companyFactSheet';
import { AlertBoxActions }         from '@/store/actions/alertBox';
import IndustryRatio               from '@/components/stateless/composed/IndustryRatio';
import { FormHeadline }            from '@/components/stateless/atomic/FormHeadline';
import EditFactSheetFacts          from '@/components/stateless/composed/EditFactSheetFacts';
import { EditFactSheetProduct }    from '@/components/stateless/composed/EditFactSheetProduct';
import DevelopmentRevenue          from '@/components/stateless/composed/DevelopmentRevenue';
import TagTypeDropDown             from '@/constants/TagTypeDropDown';
import StateHelper                 from '@/helper/State';

export class Component extends React.Component {
    okButtonPressed = () => {
        const { editType, updateFactSheet } = this.props;

        updateFactSheet({
            editType,
        });
    };

    validateIndustryRatio = () => {
        const { editContext } = this.props;
        const isValid         = _.uniqBy(editContext, 'industry.iri').length === editContext.length;

        if (!isValid) {
            this.props.showErrorAlert({
                text: I18n.t('industryRatioDuplicateTag'),
            });
        }

        return isValid;
    };

    validateProduct = () => {
        const { editContext } = this.props;
        const title           = _.get(editContext, 'title');
        const image           = _.get(editContext, 'image');
        const isValid         = (
            !_.isNil(title) &&
            !_.isNil(image)
        );

        if (!isValid) {
            this.props.showErrorAlert({
                text: I18n.t('factSheetProductMissingTitleOrImage'),
            });
        }

        return isValid;
    };

    okButtonValidator = () => {
        const { editType } = this.props;

        // @formatter:off
        switch (editType) {
            case FactSheetEditType.developmentRevenue:  return true;
            case FactSheetEditType.exportRatio:         return this.validateIndustryRatio();
            case FactSheetEditType.facts:               return true;
            case FactSheetEditType.products:            return this.validateProduct();
            case FactSheetEditType.revenueDistribution: return this.validateIndustryRatio();
            default:                                    return null;
        }
        // @formatter:on
    };

    updateEntry = (index, key, value, arrayIsInKey = false) => {
        const { editType, updateEntry } = this.props;

        updateEntry({
            index,
            key,
            value,
            arrayIsInKey,
            editType,
        });
    };

    updateValue = (key, value) => {
        const { editType, updateValue } = this.props;

        updateValue({
            key,
            value,
            editType,
        });
    };

    addNewEntry = (key = null, arrayIsInKey = false) => {
        const { editType, addNewEntry } = this.props;

        addNewEntry({
            editType,
            key,
            arrayIsInKey,
        });
    };

    updateOrCreateCertificateFile = (certificate, data) => {
        const { editContext, createCertificateFile, updateCertificateFile } = this.props;
        const certificateFiles                                              = _.get(editContext, 'certificateFiles');
        const certificateFileIndex                                          = _.findIndex(certificateFiles, {
            tag: certificate,
        });

        if (certificateFileIndex !== -1) {
            updateCertificateFile({
                certificateFileIndex,
                data,
            });

            return;
        }

        createCertificateFile({
            data: {
                ...data,
                tag: certificate,
            },
        });
    };

    removeEntry = (index, key = null, arrayIsInKey = false) => {
        const { editType, removeEntry } = this.props;

        removeEntry({
            editType,
            index,
            key,
            arrayIsInKey,
        });
    };

    renderIndustryRatio = (title, tagType) => {
        return (
            <IndustryRatio
                title={I18n.t(title)}
                industryRatios={this.props.editContext}
                industryRatioChanged={this.updateEntry}
                addButtonClicked={this.addNewEntry}
                removeButtonClicked={this.removeEntry}
                tagType={tagType}
            />
        );
    };

    renderDevelopmentRevenue = () => {
        return (
            <DevelopmentRevenue
                title={I18n.t('developmentRevenue')}
                developmentRevenues={this.props.editContext}
                developmentRevenueChanged={this.updateEntry}
                addButtonClicked={this.addNewEntry}
                removeButtonClicked={this.removeEntry}
            />
        );
    };

    renderFacts = () => {
        const { editContext } = this.props;

        return (
            <>
                <FormHeadline
                    text={I18n.t('facts')}
                />
                <EditFactSheetFacts
                    updateFact={this.updateValue}
                    updateEntry={this.updateEntry}
                    removeEntry={this.removeEntry}
                    addEntry={this.addNewEntry}
                    facts={editContext}
                    updateOrCreateCertificateFile={this.updateOrCreateCertificateFile}
                    isMember={this.props.isMember}
                />
            </>
        );
    };

    renderProduct = () => {
        return (
            <>
                <FormHeadline
                    text={I18n.t('products')}
                />
                <EditFactSheetProduct
                    updateEntry={this.updateEntry}
                    index={this.props.productIndex}
                    product={this.props.editContext}
                />
            </>
        );
    };

    renderEditTypeContent = () => {
        const { editType } = this.props;

        // @formatter:off
        switch (editType) {
            case FactSheetEditType.developmentRevenue:  return this.renderDevelopmentRevenue();
            case FactSheetEditType.exportRatio:         return this.renderIndustryRatio('exportRatio', TagTypeDropDown.regionExportRatio);
            case FactSheetEditType.facts:               return this.renderFacts();
            case FactSheetEditType.products:            return this.renderProduct();
            case FactSheetEditType.revenueDistribution: return this.renderIndustryRatio('revenueDistributionRatio', TagTypeDropDown.industry);
            default:                                    return null;
        }
        // @formatter:on
    };

    render() {
        return (
            <Overlay
                id={Overlays.editFactSheetOverlay}
                okButtonPressed={this.okButtonPressed}
                okButtonText={I18n.t('save')}
                overlayType={OverlayType.full}
                closeButtonPressed={this.closeButtonPressed}
                okButtonValidator={this.okButtonValidator}
                disableCloseOnOutsideClick={true}
            >
                {this.renderEditTypeContent()}
            </Overlay>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const EditFactSheetOverlay = Component;

Component.propTypes = {
    addNewEntry:           PropTypes.func,
    editContext:           PropTypes.factSheetEditContext,
    editType:              PropTypes.factSheetEditType,
    productIndex:          PropTypes.number,
    removeEntry:           PropTypes.func,
    showErrorAlert:        PropTypes.func,
    updateEntry:           PropTypes.func,
    updateFactSheet:       PropTypes.func,
    updateValue:           PropTypes.func,
    createCertificateFile: PropTypes.func,
    updateCertificateFile: PropTypes.func,
    isMember:              PropTypes.bool,
};

Component.defaultProps = {
    addNewEntry:           _.noop,
    editContext:           null,
    editType:              null,
    productIndex:          -1,
    removeEntry:           _.noop,
    showErrorAlert:        _.noop,
    updateEntry:           _.noop,
    updateFactSheet:       _.noop,
    updateValue:           _.noop,
    createCertificateFile: _.noop,
    updateCertificateFile: _.noop,
    isMember:              false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyFactSheetActions,
        ...AlertBoxActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    const editType     = _.get(state, 'companyFactSheet.editType');
    let editContext    = _.get(state, ['companyFactSheet', editType]);
    const productIndex = _.get(state, ['companyFactSheet', 'productIndex'], -1);
    const isMember     = StateHelper.isUserCompanyMember(state);

    if (editType === FactSheetEditType.products) {
        editContext = _.get(state, ['companyFactSheet', 'products', productIndex], {});
    }

    return {
        editContext,
        editType,
        productIndex,
        isMember,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
