//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator }                from '@/helper/Store';
import { makeActionCreatorIncreaseLoading } from '@/helper/Store';
import { makeActionCreatorDecreaseLoading } from '@/helper/Store';

export const ProfileTypes = {
    FETCH_PROJECTS_OWN_PROJECTS: 'PROFILE/FETCH_PROJECTS_OWN_PROJECTS',
    FETCH_PROJECTS_SUGGESTIONS:  'PROFILE/FETCH_PROJECTS_SUGGESTIONS',
    LOAD_INITIAL_PROFILE_DATA:   'PROFILE/LOAD_INITIAL_PROFILE_DATA',
    OPEN_MY_PROFILE_MESSAGES:    'PROFILE/OPEN_MY_PROFILE_MESSAGES',
    OPEN_MY_PROFILE_PROJECTS:    'PROFILE/OPEN_MY_PROFILE_PROJECTS',
    OPEN_FAVORITES:              'PROFILE/OPEN_FAVORITES',
    PROFILE_FORM_DATA_CHANGED:   'PROFILE/PROFILE_FORM_DATA_CHANGED',
    SAVE_PROFILE:                'PROFILE/SAVE_PROFILE',
    SAVE_PROFILE_FAILED:         'PROFILE/SAVE_PROFILE_FAILURE',
    SAVE_PROFILE_SUCCEEDED:      'PROFILE/SAVE_PROFILE_SUCCEEDED',
    SET_INITIAL_PROFILE_DATA:    'PROFILE/SET_INITIAL_PROFILE_DATA',
    VALIDATION_CHANGED:          'PROFILE/VALIDATION_CHANGED',
};

const profileFormDataChanged = makeActionCreator(
    ProfileTypes.PROFILE_FORM_DATA_CHANGED,
    {
        field: '',
        value: null,
    },
);

const saveProfile = makeActionCreatorIncreaseLoading(
    ProfileTypes.SAVE_PROFILE,
    {},
);

const saveProfileSucceeded = makeActionCreatorDecreaseLoading(
    ProfileTypes.SAVE_PROFILE_SUCCEEDED,
    {},
);

const saveProfileFailed = makeActionCreatorDecreaseLoading(
    ProfileTypes.SAVE_PROFILE_FAILED,
    {},
);

const setInitialProfileData = makeActionCreator(
    ProfileTypes.SET_INITIAL_PROFILE_DATA,
    {},
);

const loadInitialProfileData = makeActionCreator(
    ProfileTypes.LOAD_INITIAL_PROFILE_DATA,
    {},
);

const validationChanged = makeActionCreator(
    ProfileTypes.VALIDATION_CHANGED,
    {
        validation: {},
    },
);

const fetchProjectsSuggestions = makeActionCreator(
    ProfileTypes.FETCH_PROJECTS_SUGGESTIONS,
    {},
);

const fetchProjectsOwnProjects = makeActionCreator(
    ProfileTypes.FETCH_PROJECTS_OWN_PROJECTS,
    {},
);

const openMyProfileProjects = makeActionCreator(
    ProfileTypes.OPEN_MY_PROFILE_PROJECTS,
    {},
);

const openMyProfileMessages = makeActionCreator(
    ProfileTypes.OPEN_MY_PROFILE_MESSAGES,
    {},
);

const openFavorites = makeActionCreator(
    ProfileTypes.OPEN_FAVORITES,
    {},
);

export const ProfileActions = {
    fetchProjectsOwnProjects,
    fetchProjectsSuggestions,
    loadInitialProfileData,
    openMyProfileMessages,
    openMyProfileProjects,
    openFavorites,
    profileFormDataChanged,
    saveProfile,
    saveProfileFailed,
    saveProfileSucceeded,
    setInitialProfileData,
    validationChanged,
};
