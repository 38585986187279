//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React               from 'react';
import _                   from 'lodash';
import I18n                from 'i18next';
import { connect }         from 'react-redux';
import { Helmet }          from 'react-helmet';
import BreadCrumbBar       from '@/components/connected/BreadCrumbBar';
import ComponentHelper     from '@/helper/ComponentHelper';
import Header              from '@/components/connected/Header';
import Helmets             from '@/constants/Helmets';
import Navigation          from '@/helper/Navigation';
import PageTitleHelper     from '@/helper/PageTitle';
import PropTypes           from '@/components/PropTypes';
import Route               from '@/helper/Route';
import RoutesWithoutHeader from '@/constants/RoutesWithoutHeader';
import State               from '@/helper/State';

export class Component extends React.Component {
    renderHelmet = () => {
        const { matchingHelmet } = this.props;

        if (matchingHelmet) {
            const titleKey = matchingHelmet.key;

            if (titleKey) {
                let title           = '';
                const propParameter = matchingHelmet.prop;

                if (propParameter) {
                    title = I18n.t(
                        titleKey,
                        {
                            [propParameter]: _.get(this, ['props', propParameter]),
                        },
                    );
                } else {
                    title = I18n.t(titleKey);
                }

                return (
                    <Helmet>
                        <title>
                            {PageTitleHelper.getPageTitle(title)}
                        </title>
                    </Helmet>
                );
            }
        }

        console.warn('No title entry found for this route, check the HelmetsHelper to see if its missing.');

        return null;
    };

    render = () => {
        if (!this.props.hasHeader) {
            return (
                <>
                    {this.renderHelmet()}
                    <Header />
                    <BreadCrumbBar />
                </>
            );
        }

        return this.renderHelmet();
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const HeaderWrapper = Component;

Component.propTypes = {
    companyName:    PropTypes.string,
    hasHeader:      PropTypes.bool,
    location:       PropTypes.object,
    machineName:    PropTypes.string,
    matchingHelmet: PropTypes.object,
};

Component.defaultProps = {
    companyName:    null,
    hasHeader:      true,
    location:       {},
    machineName:    null,
    matchingHelmet: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => {
    const location       = _.get(state, 'router.location');
    const pathname       = _.get(location, 'pathname', '');
    const currentUrl     = pathname.replace(new RegExp('/[a-zA-Z]+/'), '');
    const urlId          = Navigation.getUuidFromString(currentUrl);
    const hasHeader      = RoutesWithoutHeader.includes(pathname);
    const companyName    = State.getCompanyValue(state, urlId, 'name');
    const machineName    = State.getMachineById(state, urlId, 'name');
    const matchingHelmet = _.find(Helmets, (helmet, route) => Route.matchesPartRoute(route, pathname));

    return {
        companyName,
        hasHeader,
        location,
        machineName,
        matchingHelmet,
    };
};

export default connect(
    mapStateToProps,
    null,
)(Component);
