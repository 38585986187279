//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React               from 'react';
import _                   from 'lodash';
import I18n                from 'i18next';
import * as Api            from '@/api';
import EntityLabelSize     from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import Hydra               from '@/helper/Hydra';
import IconType            from '@/components/stateless/atomic/Icon/IconType';
import PropTypes           from '@/components/PropTypes';
import StartPageSearch     from '@/components/stateless/composed/StartPageSearch';
import StartPageSearchMode from '@/components/stateless/composed/StartPageSearch/StartPageSearchMode';
import { AddressLabel }    from '@/components/stateless/composed/AddressLabel';
import { EntityLabel }     from '@/components/stateless/composed/EntityLabel';
import { IconTextButton }  from '@/components/stateless/atomic/IconTextButton';
import Company             from '@/helper/Company';
import styles              from './styles.module.scss';

export class Component extends React.Component {
    renderCompanySearchResults = () => {
        const { companies } = this.props;
        const companyCount  = _.size(companies);

        if (companyCount) {
            return _.map(
                companies,
                this.renderCompanySearchResult,
            );
        }

        return (
            <span className={styles.noResults}>
                {I18n.t('noResults')}
            </span>
        );
    };

    renderJoinButton = (company) => {
        let active   = false;
        let onClick  = this.props.onCompanyJoinClick(company);
        let text     = I18n.t('join');
        const joined = _.includes(
            _.get(this.props, 'joinRequests', []),
            Hydra.getIriFromId(
                'companies',
                _.get(company, 'id'),
            ),
        );

        if (joined) {
            active  = true;
            onClick = _.noop;
            text    = I18n.t('joinRequested');
        }

        return (
            <IconTextButton
                active={active}
                onClick={onClick}
                iconType={IconType.check}
                text={text}
            />
        );
    };

    renderCompanySearchResult = (company, index) => {
        const companyAddress = _.get(company, 'address');

        return (
            <div
                className={styles.companySearchResult}
                key={index}
            >
                <EntityLabel
                    title={_.get(company, 'name')}
                    subtitle={Company.getCompanyTypesString(_.get(company, 'types'))}
                    avatar={Api.getImagePath(_.get(company, 'logo.path'))}
                    size={EntityLabelSize.small}
                />
                <AddressLabel
                    city={_.get(companyAddress, 'city')}
                    country={_.get(companyAddress, 'country')}
                    iso31661Alpha2CountryCode={_.get(companyAddress, 'countryCode')}
                />
                <div className={styles.joinButtonContainer}>
                    {this.renderJoinButton(company)}
                </div>
            </div>
        );
    };

    renderSearchInput = () => {
        const { query, onTextChange, joinRequests } = this.props;

        if (!_.isEmpty(joinRequests)) {
            return null;
        }

        return (
            <StartPageSearch
                mode={StartPageSearchMode.company}
                query={query}
                onTextChange={onTextChange}
            />
        );
    };

    render() {
        return (
            <div>
                {this.renderSearchInput()}
                <div className={styles.companySearchResults}>
                    {this.renderCompanySearchResults()}
                </div>
            </div>
        );
    }
}

export const CompanySearchBox = Component;

Component.propTypes = {
    companies:          PropTypes.array,
    joinRequests:       PropTypes.array,
    onCompanyJoinClick: PropTypes.func,
    onTextChange:       PropTypes.func,
    query:              PropTypes.string,
};

Component.defaultProps = {
    companies:          [],
    joinRequests:       [],
    onCompanyJoinClick: _.noop,
    onTextChange:       _.noop,
    query:              null,
};

export default Component;
