//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { Trans }       from 'react-i18next';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';

export class Component extends React.Component {
    render() {
        const { components, translationKey, values } = this.props;
        const bold                                   = (
            <strong />
        );
        const defaultComponents                      = {
            bold,
        };

        return (
            <Trans
                i18nKey={translationKey}
                values={values}
                components={{
                    ...defaultComponents,
                    ...components,
                }}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const BoldTranslation = Component;

Component.propTypes = {
    components:     PropTypes.object,
    translationKey: PropTypes.string,
    values:         PropTypes.object,
};

Component.defaultProps = {
    components:     {},
    translationKey: null,
    values:         {},
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
