//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import I18n      from 'i18next';
import PropTypes from '@/components/PropTypes';

import { TagCertificate } from '@/components/stateless/atomic/TagCertificate';
import _                  from 'lodash';
import styles             from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.tagCertificateListWrapper}>
                {this.renderTags()}
            </div>
        );
    }

    renderTags = () => {
        if (this.props.tags.length) {
            return this.props.tags.map(this.renderTag);
        }

        return (
            <p className={styles.tagListWrapperNoTags}>
                {I18n.t('noCertificatesSelected')}
            </p>
        );
    };

    renderTag = (tag) => {
        const tagId          = tag.id;
        const tagCertificate = _.find(this.props.tagCertificates, (currentTagCertificate) => {
            return currentTagCertificate.tag.id === tagId;
        });

        return (
            <TagCertificate
                tag={tag}
                tagCertificate={tagCertificate}
            />
        );
    };
}

export const TagCertificateList = Component;

Component.propTypes = {
    tags:            PropTypes.array,
    tagCertificates: PropTypes.array,
};

Component.defaultProps = {
    tags:            [],
    tagCertificates: [],
};

export default Component;
