//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Hydra          from '@/helper/Hydra';
import _              from 'lodash';
import { call }       from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { take }       from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { push }       from 'connected-react-router';
import I18n           from 'i18next';

import * as Api                  from '@/api';
import ApiMode                   from '@/constants/ApiMode';
import HydraHelper               from '@/helper/Hydra';
import Overlays                  from '@/constants/Overlays';
import Route                     from '@/helper/Route';
import Routes                    from '@/constants/Routes';
import { CompanyActions }        from '@/store/actions/company';
import { MachineActions }        from '@/store/actions/machine';
import { MachineTypes }          from '@/store/actions/machine';
import { OverlayManager }        from '@/components/connected/OverlayManager';
import { AlertBoxActions }       from '@/store/actions/alertBox';
import { MessageRequestActions } from '@/store/actions/messageRequest';
import MessageReqeustField       from '@/constants/MessageReqeustField';
import { TagActions }            from '@/store/actions/tag';
import TagEditorContext          from '@/constants/TagEditorContext';
import TagEditor                 from '@/helper/TagEditor';

function* createMachineFailed() {
    yield put(AlertBoxActions.showSuccessAlert({
        text:        I18n.t('createMachineFailed'),
        lifeCounter: 2,
    }));
}

function* updateOrCreateMachineSucceeded(key) {
    const currentCompanyId = yield select((state) => state.company.currentCompanyId);

    yield put(MachineActions.fetchMachinesByCompany({
        companyId: currentCompanyId,
    }));
    yield put(AlertBoxActions.showSuccessAlert({
        text:        I18n.t(key),
        lifeCounter: 2,
    }));

    yield put(push(Route.buildRoute(Routes.companyMachine, currentCompanyId)));
}

function* createMachineSucceeded() {
    yield updateOrCreateMachineSucceeded('createMachineSuccess');
}

function* updateMachineSucceeded() {
    yield updateOrCreateMachineSucceeded('updateMachineSuccess');
}

const postMachineParametersBatch = function* batchPost(parameters, machineIri, tagErrors) {
    const parameter = parameters.shift();
    const response  = yield call(
        Api.postMachineParameter,
        machineIri,
        parameter.name,
        parameter.value,
    );

    if (!response.ok) {
        tagErrors.push(parameter);
    }

    if (parameters.length > 0) {
        yield postMachineParametersBatch(parameters, machineIri, tagErrors);
    } else if (!tagErrors.length) {
        yield put(MachineActions.postMachineParametersSucceeded());
    } else {
        yield put(MachineActions.postMachineParametersFailed());
    }
};

function* fetchMachine(action) {
    const response = yield call(
        Api.fetchMachine,
        action.machineId,
    );

    if (response.ok) {
        const machine = HydraHelper.cleanupObject(response.data);

        if (machine) {
            yield put(MachineActions.fetchMachineSucceeded({
                machine,
            }));
        } else {
            yield put(MachineActions.fetchMachineFailed());
        }
    } else {
        yield put(MachineActions.fetchMachineFailed());
    }
}

function* fetchMachineSucceeded(action) {
    const companies        = yield select((state) => state.company.companies);
    const machineCompanies = _.get(action, ['machine', 'companies'], []);

    for (const machineCompany of machineCompanies) {
        const id = _.get(machineCompany, 'id');

        if (id) {
            const company = _.get(companies, id);

            if (!company) {
                yield put(CompanyActions.fetchCompany({
                    id,
                }));
            }
        }
    }
}

function* getChildTags(action) {
    const tag       = _.get(action, 'tag', null);
    const hierarchy = _.get(tag, 'hierarchy', null);

    for (const tagElement of hierarchy) {
        const tagId = _.get(tagElement, 'id', null);

        if (tagId) {
            const response = yield call(
                Api.fetchTag,
                tagId,
            );

            if (response.ok) {
                const result   = HydraHelper.cleanupObject(response.data);
                const children = _.get(result, 'childTags', []);

                yield put(MachineActions.addChildrenToTagQuery({
                    tag: result,
                    children,
                }));
            }
        }
    }
}

function* fetchMachinesByCompany(action) {
    const response = yield call(
        Api.fetchMachineByCompany,
        action.companyId,
    );

    if (response.ok) {
        const machines = HydraHelper.getMembersFromResponse(response.data);

        if (machines) {
            yield put(MachineActions.fetchMachinesByCompanySucceeded({
                machines,
                companyId: action.companyId,
            }));
        } else {
            yield put(MachineActions.fetchMachinesByCompanyFailed());
        }
    } else {
        yield put(MachineActions.fetchMachinesByCompanyFailed());
    }
}

function* openAddNewMachineManufacturerOverlay() {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.addNewMachineManufacturer)));
}

function* openAddNewMachineOverlay() {
    yield put(TagActions.buildHierarchy({
        context: TagEditorContext.newEditMachine,
        tags:    [],
    }));
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.addNewMachine)));
}

function* openEditMachineOverlay(action) {
    const machine = _.get(action, 'machine');

    yield put(TagActions.buildHierarchy({
        context: TagEditorContext.newEditMachine,
        tags:    _.get(machine, 'tags', []),
    }));
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.addNewMachine)));
}

function* postMachineParameters(action) {
    const parameters = _.clone(action.parameters);

    if (!parameters.length) {
        yield put(MachineActions.postMachineParametersSucceeded());
    } else {
        yield postMachineParametersBatch(parameters, action.machineIri, []);
    }
}

function* requestMachine(action) {
    const { machine, companyUrl } = action;
    const companyRoute            = Route.buildRoute(Routes.companyMessage, companyUrl);
    const companyPath             = Route.buildPathByRoute(companyRoute);

    yield put(MessageRequestActions.editMessageRequestField({
        field: MessageReqeustField.machine,
        value: machine,
    }));
    yield put(MessageRequestActions.editMessageRequestField({
        field: MessageReqeustField.portfolioProduct,
        value: null,
    }));
    yield put(push(`${companyPath.pathname}?machine`));
}

function* resetMachine() {
    yield put(MessageRequestActions.editMessageRequestField({
        field: MessageReqeustField.machine,
        value: null,
    }));
    yield put(MessageRequestActions.editMessageRequestField({
        field: MessageReqeustField.portfolioProduct,
        value: null,
    }));
}

function* updateOrCreateMachine() {
    const apiMode            = yield select((state) => state.machine.apiMode);
    const ownMachineEdit     = yield select((state) => state.machine.ownMachineEdit);
    const parameters         = _.get(ownMachineEdit, 'parameters', []);
    const machineClone       = _.cloneDeep(ownMachineEdit);
    const createdByCompanyId = ownMachineEdit?.createdByCompany?.id;
    machineClone.image       = machineClone.imageUploaded;
    machineClone.tags        = yield TagEditor.getTagsFromEditor(TagEditorContext.newEditMachine);
    const currentCompanyId   = yield select((state) => state.company.currentCompanyId);
    const machineCapacities  = _.cloneDeep(_.get(ownMachineEdit, 'capacities', []));
    const machineCategories  = _.cloneDeep(_.get(ownMachineEdit, 'categories', []));
    const machineCategory    = _.find(machineCategories, ['company.id', createdByCompanyId]);

    if (machineClone.isManufacturer) {
        const company             = yield select((state) => state.company.companies[currentCompanyId]);
        machineClone.manufacturer = company.iri;
    }

    delete machineClone.isManufacturer;
    delete machineClone.imageUploaded;
    delete machineClone.tagHierarchy;
    delete machineClone.capacities;
    delete machineClone.categories;

    if (apiMode === ApiMode.create) {
        const response = yield call(
            Api.createMachine,
            machineClone,
        );

        if (response.ok) {
            const createdMachine = HydraHelper.cleanupObject(response.data);

            yield put(MachineActions.updateMachineCapacity({
                machineCapacities,
                machine: createdMachine,
            }));
            yield take([
                MachineTypes.UPDATE_MACHINE_CAPACITY_SUCCEEDED,
                MachineTypes.UPDATE_MACHINE_CAPACITY_FAILED,
            ]);
            yield put(MachineActions.updateMachineCategory({
                machine:  response.data.iri,
                category: machineClone.category,
                machineCategory,
            }));
            yield take([
                MachineTypes.UPDATE_MACHINE_CATEGORY_SUCCEEDED,
                MachineTypes.UPDATE_MACHINE_CATEGORY_FAILED,
            ]);
            yield put(MachineActions.createMachineSucceeded());
        } else {
            yield put(MachineActions.createMachineFailed());
        }
    } else {
        delete machineClone.companies;

        const machineIri  = _.get(ownMachineEdit, 'iri', null);
        const machineData = {
            name:        machineClone.name,
            image:       machineClone.image,
            description: machineClone.description,
            tags:        machineClone.tags,
            category:    null,
            parameters:  _.map(parameters, (parameter) => {
                return {
                    ...parameter,
                    id: _.get(parameter, 'iri', null),
                };
            }),
        };
        const response    = yield call(
            Api.updateMachine,
            machineIri,
            machineData,
        );

        if (response.ok) {
            const updatedMachine = HydraHelper.cleanupObject(response.data);

            yield put(MachineActions.updateMachineCapacity({
                machineCapacities,
                machine: updatedMachine,
            }));
            yield take([
                MachineTypes.UPDATE_MACHINE_CAPACITY_SUCCEEDED,
                MachineTypes.UPDATE_MACHINE_CAPACITY_FAILED,
            ]);
            yield put(MachineActions.updateMachineCategory({
                machine:  response.data.iri,
                category: machineClone.category,
                machineCategory,
            }));
            yield take([
                MachineTypes.UPDATE_MACHINE_CATEGORY_SUCCEEDED,
                MachineTypes.UPDATE_MACHINE_CATEGORY_FAILED,
            ]);
            yield put(MachineActions.updateMachineSucceeded());
        } else {
            yield put(MachineActions.updateMachineFailed());
        }
    }
}

function* updateMachineCapacity(action) {
    const machineCapacities = _.get(action, 'machineCapacities', null);
    const machine           = _.get(action, 'machine', null);

    if (!machineCapacities.length) {
        yield put(MachineActions.updateMachineCapacitySucceeded({
            silent: true,
        }));
    }

    for (const machineCapacity of machineCapacities) {
        if (_.get(machineCapacity, 'company', null) === null) {
            // eslint-disable-next-line no-continue
            continue;
        }

        const silent            = _.get(action, 'silent', false);
        const machineIri        = _.get(machine, 'iri', null);
        const companyIri        = _.get(machineCapacity, 'company.iri', null);
        const year              = _.get(machineCapacity, 'year', null);
        const capacity          = _.get(machineCapacity, 'capacity', null);
        const machineIdentifier = _.isString(machine) ? machine : machineIri;

        if (
            !machineIdentifier ||
            !companyIri ||
            !year
        ) {
            // eslint-disable-next-line no-continue
            continue;
        }

        const machineCapacityToUpdate = {
            ...machineCapacity,
            year,
            capacity,
            machine: machineIdentifier,
            company: companyIri,
        };
        const response                = yield call(
            Api.updateMachineCapacity,
            machineCapacityToUpdate,
        );

        if (response.ok) {
            yield put(MachineActions.updateMachineCapacitySucceeded({
                silent,
            }));
        } else {
            yield put(MachineActions.updateMachineCapacityFailed({
                silent,
            }));
        }
    }
}

function* updateMachineCapacityFailed(action) {
    const silent = _.get(action, 'silent', false);

    if (silent) {
        return;
    }

    yield put(AlertBoxActions.showErrorAlert({
        text:        I18n.t('updateMachineCapacityFailed'),
        lifeCounter: 1,
    }));
}

function* updateMachineCapacitySucceeded(action) {
    const silent = _.get(action, 'silent', false);

    if (silent) {
        return;
    }

    yield updateOrCreateMachineSucceeded('updateMachineCapacitySucceeded');
}

function* updateMachineCategory(action) {
    let api                  = null;
    const {
        category,
        machineCategory,
    }                  = action;
    const newMachineCategory = machineCategory ? {
        ...machineCategory,
    } : {};
    const companyId          = yield select((state) => state.company.currentCompanyId);
    const companyIri         = Hydra.getIriFromId('companies', companyId);
    const machineIri         = action.machine;

    if (machineCategory) {
        // Only update if changed
        if (machineCategory.name !== category) {
            api = Api.context.machine.updateMachineCategory;
        }
    } else {
        api = Api.context.machine.createMachineCategory;
    }

    // Only update or create if necessary
    if (api) {
        newMachineCategory.name    = category;
        newMachineCategory.company = companyIri;
        newMachineCategory.machine = machineIri;
        const response             = yield call(
            api,
            newMachineCategory,
        );

        if (response.ok) {
            yield put(MachineActions.updateMachineCategorySucceeded());
        } else {
            yield put(MachineActions.updateMachineCategoryFailed());
        }
    }
}

export const callMachineSagas = () => {
    return [
        // @formatter:off
        takeLatest([MachineTypes.ADD_MACHINE_TAG],                           getChildTags),
        takeLatest([MachineTypes.CREATE_MACHINE_FAILED],                     createMachineFailed),
        takeLatest([MachineTypes.CREATE_MACHINE_SUCCEEDED],                  createMachineSucceeded),
        takeLatest([MachineTypes.UPDATE_MACHINE_SUCCEEDED],                  updateMachineSucceeded),
        takeLatest([MachineTypes.FETCH_MACHINES],                            fetchMachine),
        takeLatest([MachineTypes.FETCH_MACHINES_BY_COMPANY],                 fetchMachinesByCompany),
        takeLatest([MachineTypes.FETCH_MACHINES_SUCCEEDED],                  fetchMachineSucceeded),
        takeLatest([MachineTypes.OPEN_ADD_NEW_MACHINE_MANUFACTURER_OVERLAY], openAddNewMachineManufacturerOverlay),
        takeLatest([MachineTypes.OPEN_ADD_NEW_MACHINE_OVERLAY],              openAddNewMachineOverlay),
        takeLatest([MachineTypes.OPEN_EDIT_MACHINE_OVERLAY],                 openEditMachineOverlay),
        takeLatest([MachineTypes.POST_MACHINE_PARAMETERS],                   postMachineParameters),
        takeLatest([MachineTypes.REQUEST_MACHINE],                           requestMachine),
        takeLatest([MachineTypes.RESET_MACHINE],                             resetMachine),
        takeLatest([MachineTypes.UPDATE_OR_CREATE_MACHINE],                  updateOrCreateMachine),
        takeLatest([MachineTypes.UPDATE_MACHINE_CAPACITY],                   updateMachineCapacity),
        takeLatest([MachineTypes.UPDATE_MACHINE_CAPACITY_FAILED],            updateMachineCapacityFailed),
        takeLatest([MachineTypes.UPDATE_MACHINE_CAPACITY_SUCCEEDED],         updateMachineCapacitySucceeded),
        takeLatest([MachineTypes.UPDATE_MACHINE_CATEGORY],                   updateMachineCategory),
        // @formatter:on
    ];
};

export default {
    fetchMachinesByCompany,
};
