//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React              from 'react';
import PropTypes          from '@/components/PropTypes';
import { HeadlineMedium } from '@/components/stateless/atomic/HeadlineMedium';
import styles             from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.companyDescriptionWrapper}>
                {this.renderTitle()}
                {this.renderText()}
            </div>
        );
    }

    renderText = () => {
        return (
            <p>
                {this.props.text}
            </p>
        );
    };

    renderTitle = () => {
        if (this.props.title) {
            return (
                <HeadlineMedium text={this.props.title} />
            );
        }

        return null;
    };
}

export const CompanyDescription = Component;

Component.propTypes = {
    text:  PropTypes.string,
    title: PropTypes.string,
};

Component.defaultProps = {
    text:  PropTypes.string,
    title: PropTypes.string,
};

export default Component;
