//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                    from 'immutability-helper';
import _                         from 'lodash';
import FactSheetEditType         from '@/constants/FactSheetEditType';
import { CompanyFactSheetTypes } from '@/store/actions/companyFactSheet';
import FactSheetFactTagList      from '@/constants/FactSheetFactTagList';

const industryRatioInitialObject = {
    industry:   null,
    percentage: 0,
};

const certificateFileInitialObject = {
    file:           null,
    title:          null,
    expirationDate: new Date(),
    tag:            null,
};

const factInitialObject = {
    employees:                             null,
    mainCustomers:                         null,
    certificateFiles:                      [],
    [FactSheetFactTagList.commodities]:    [],
    [FactSheetFactTagList.processes]:      [],
    [FactSheetFactTagList.productionType]: [],
    [FactSheetFactTagList.certificates]:   [],
    [FactSheetFactTagList.language]:       [],
};

const initialObjectMapping = {
    [FactSheetEditType.revenueDistribution]: industryRatioInitialObject,
    [FactSheetEditType.exportRatio]:         industryRatioInitialObject,
    [FactSheetEditType.developmentRevenue]:  {
        label: null,
        value: null,
    },
    [FactSheetEditType.products]:            {
        title: null,
        image: null,
    },
    [FactSheetEditType.facts]:               {
        title: null,
        iri:   null,
    },
};

const initialState = {
    [FactSheetEditType.facts]:               factInitialObject,
    [FactSheetEditType.products]:            [],
    [FactSheetEditType.revenueDistribution]: [
        industryRatioInitialObject,
    ],
    [FactSheetEditType.exportRatio]:         [
        industryRatioInitialObject,
    ],
    [FactSheetEditType.developmentRevenue]:  [
        initialObjectMapping[FactSheetEditType.developmentRevenue],
    ],
    editType:                                null,
};

const isArrayInKey = (action) => {
    return _.get(action, 'arrayIsInKey', false);
};

const updateEntry = (action, state) => {
    if (isArrayInKey(action)) {
        const { key, editType, index, value } = action;
        let updatedState                      = update(state, {
            [editType]: {
                [key]: {
                    [index]: {
                        $set: value,
                    },
                },
            },
        });

        if (key === 'commodities') {
            const { facts }                  = updatedState;
            const { commodities, processes } = facts;
            const processesToRemove          = [];
            const commodityIris              = _.map(commodities, 'iri');

            for (const process of processes) {
                if (
                    !process.parentTag ||
                    !commodityIris.includes(process?.parentTag?.iri)
                ) {
                    processesToRemove.push(process);
                }
            }

            for (const processToRemove of processesToRemove) {
                updatedState = update(updatedState, {
                    facts: {
                        processes: {
                            $removeArrayItemByFieldValue: {
                                field: 'iri',
                                value: processToRemove.iri,
                            },
                        },
                    },
                });
            }
        }

        return updatedState;
    }

    const initialObject       = _.cloneDeep(initialObjectMapping[action.editType]);
    initialObject[action.key] = action.value;
    const addNewEntry         = action.index === -1;
    const currentIndex        = addNewEntry ? state[action.editType].length : action.index;
    const updateStatement     = (
        addNewEntry ?
            {
                $push: [
                    initialObject,
                ],
            } :
            {
                [action.index]: {
                    [action.key]: {
                        $set: action.value,
                    },
                },
            }
    );

    const indexSetter = (
        addNewEntry ?
            {
                productIndex: {
                    $set: currentIndex,
                },
            } :
            null
    );

    return update(state, {
        [action.editType]: {
            ...updateStatement,
        },
        ...indexSetter,
    });
};

const updateValue = (action, state) => {
    return update(state, {
        [action.editType]: {
            [action.key]: {
                $set: action.value,
            },
        },
    });
};

const removeEntry = (action, state) => {
    if (isArrayInKey(action)) {
        return update(state, {
            [action.editType]: {
                [action.key]: {
                    $removeArrayItemAtIndex: action.index,
                },
            },
        });
    }

    return update(state, {
        [action.editType]: {
            $removeArrayItemAtIndex: action.index,
        },
    });
};

const addNewEntry = (action, state) => {
    if (isArrayInKey(action)) {
        return update(state, {
            [action.editType]: {
                [action.key]: {
                    $push: [
                        initialObjectMapping[action.editType],
                    ],
                },
            },
        });
    }

    return update(state, {
        [action.editType]: {
            $push: [
                initialObjectMapping[action.editType],
            ],
        },
    });
};

const loadEditContext = (action, state) => {
    const { factSheet, editType, productIndex } = action;
    const clonedFactSheet                       = {
        ...initialState,
        ...factSheet,
    };
    clonedFactSheet.editType                    = editType;
    clonedFactSheet.productIndex                = productIndex;

    return update(state, {
        $set: clonedFactSheet,
    });
};

const openEditOverlay = (action, state) => {
    return update(state, {
        editType: {
            $set: action.editType,
        },
    });
};

const createCertificateFile = (action, state) => {
    return update(state, {
        [FactSheetEditType.facts]: {
            certificateFiles: {
                $push: [
                    {
                        ...certificateFileInitialObject,
                        ...action.data,
                    },
                ],
            },
        },
    });
};

const updateCertificateFile = (action, state) => {
    const { certificateFileIndex, data } = action;
    const existingCertificateFile        = state[FactSheetEditType.facts].certificateFiles[certificateFileIndex];

    return update(state, {
        [FactSheetEditType.facts]: {
            certificateFiles: {
                [certificateFileIndex]: {
                    $set: {
                        ...existingCertificateFile,
                        ...data,
                    },
                },
            },
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case CompanyFactSheetTypes.UPDATE_ENTRY:            return updateEntry(action, state);
        case CompanyFactSheetTypes.UPDATE_VALUE:            return updateValue(action, state);
        case CompanyFactSheetTypes.REMOVE_ENTRY:            return removeEntry(action, state);
        case CompanyFactSheetTypes.ADD_NEW_ENTRY:           return addNewEntry(action, state);
        case CompanyFactSheetTypes.LOAD_EDIT_CONTEXT:       return loadEditContext(action, state);
        case CompanyFactSheetTypes.OPEN_EDIT_OVERLAY:       return openEditOverlay(action, state);
        case CompanyFactSheetTypes.CREATE_CERTIFICATE_FILE: return createCertificateFile(action, state);
        case CompanyFactSheetTypes.UPDATE_CERTIFICATE_FILE: return updateCertificateFile(action, state);
        default:                                            return state;
        // @formatter:on
    }
}
