//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import VerifiedProps          from '@/constants/VerifiedProps';
import React                  from 'react';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import * as Api               from '@/api';
import _                      from 'lodash';
import AddressLabel           from '@/components/stateless/composed/AddressLabel';
import ComponentHelper        from '@/helper/ComponentHelper';
import EntityLabelSize        from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import I18n                   from 'i18next';
import Navigation             from '@/helper/Navigation';
import PropTypes              from '@/components/PropTypes';
import Revenue                from '@/helper/Revenue';
import Route                  from '@/helper/Route';
import Routes                 from '@/constants/Routes';
import State                  from '@/helper/State';
import withFetchCompany       from '@/components/hoc/FetchCompany';
import { bindActionCreators } from 'redux';
import { EntityLabel }        from '@/components/stateless/composed/EntityLabel';
import { FactSheetBarChart }  from '@/components/stateless/composed/FactSheetBarChart';
import { FactSheetBox }       from '@/components/stateless/composed/FactSheetBox';
import { FactSheetFacts }     from '@/components/stateless/composed/FactSheetFacts';
import { FactSheetPieChart }  from '@/components/stateless/composed/FactSheetPieChart';
import { FactSheetProducts }  from '@/components/stateless/composed/FactSheetProducts';
import { goBack }             from 'connected-react-router';
import { Logo }               from '@/components/stateless/atomic/Logo';
import { NavigationActions }  from '@/store/actions/navigation';

import classNames from 'classnames';
import Company    from '@/helper/Company';
import styles     from './styles.module.scss';

class Screen extends React.Component {
    waitForLoaded = {
        avatar:                  false,
        developmentRevenueData:  false,
        exportRatio:             false,
        revenueDistributionData: false,
        top10ProductImages:      false,
    };

    afterPrint = () => {
        this.props.goBack();
    };

    openPrint = () => {
        window.print();
    };

    elementLoaded = (element) => () => {
        this.waitForLoaded[element] = true;

        if (Object.values(this.waitForLoaded).every((item) => item)) {
            this.openPrint();
        }
    };

    componentDidMount() {
        window.addEventListener('afterprint', this.afterPrint);
    }

    componentWillUnmount() {
        window.removeEventListener('afterprint', this.afterPrint);
    }

    render() {
        const {
            companyAddress,
            companyName,
            companyTypes,
            companyVerified,
            companyLogo,
            companyId,
            products,
            developmentRevenue,
            revenueDistribution,
            exportRatio,
            facts,
        }                         = this.props;
        const top10Products             = _.take(products, 10);
        const {
            label: developmentRevenueLabel,
            data:  developmentRevenueData,
        }                         = Revenue.getLabelAndData(developmentRevenue);
        const mappedRevenueDistribution = Revenue.mapIndustryRatios(revenueDistribution);
        const mappedExportRatio         = Revenue.mapIndustryRatios(exportRatio);

        return (
            <div className={styles.printContainer}>
                <div className={styles.header}>
                    <div className={styles.companyOverview}>
                        <EntityLabel
                            title={companyName}
                            subtitle={Company.getCompanyTypesString(companyTypes)}
                            verified={companyVerified}
                            avatar={Api.getImagePath(companyLogo)}
                            size={EntityLabelSize.default}
                            to={Route.buildRoute(Routes.company, {
                                companyId,
                            })}
                            onImageLoaded={this.elementLoaded('avatar')}
                        />
                        <AddressLabel
                            city={companyAddress?.city}
                            country={companyAddress?.country}
                            houseNumber={companyAddress?.houseNumber}
                            postalCode={companyAddress?.postalCode}
                            street={companyAddress?.street}
                            iso31661Alpha2CountryCode={companyAddress?.countryCode}
                        />
                    </div>
                    <Logo poweredBy={true} />
                </div>
                <div className={styles.body}>
                    <div className={classNames(styles.row, styles.barChartRow)}>
                        <FactSheetProducts
                            products={top10Products}
                            currentCompanyId={companyId}
                            onImagesLoaded={this.elementLoaded('top10ProductImages')}
                        />
                        <FactSheetBox
                            headline={I18n.t('developmentRevenue')}
                            currentCompanyId={companyId}
                        >
                            <FactSheetBarChart
                                data={developmentRevenueData}
                                labels={developmentRevenueLabel}
                                animate={false}
                                onChartLoaded={this.elementLoaded('developmentRevenueData')}
                            />
                        </FactSheetBox>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.pies}>
                            <FactSheetBox
                                headline={I18n.t('revenueDistribution')}
                                currentCompanyId={companyId}
                            >
                                <FactSheetPieChart
                                    data={mappedRevenueDistribution}
                                    animate={false}
                                    onChartLoaded={this.elementLoaded('revenueDistributionData')}
                                />
                            </FactSheetBox>
                            <FactSheetBox
                                headline={I18n.t('exportRatio')}
                                currentCompanyId={companyId}
                            >
                                <FactSheetPieChart
                                    data={mappedExportRatio}
                                    animate={false}
                                    onChartLoaded={this.elementLoaded('exportRatio')}
                                />
                            </FactSheetBox>
                        </div>
                        <FactSheetBox
                            headline={I18n.t('facts')}
                            currentCompanyId={companyId}
                        >
                            <FactSheetFacts
                                facts={facts}
                            />
                        </FactSheetBox>
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    companyAddress:      PropTypes.object,
    companyId:           PropTypes.string,
    companyLogo:         PropTypes.string,
    companyName:         PropTypes.string,
    companyTypes:        PropTypes.array,
    companyVerified:     PropTypes.object,
    developmentRevenue:  PropTypes.array,
    exportRatio:         PropTypes.array,
    facts:               PropTypes.object,
    goBack:              PropTypes.func,
    products:            PropTypes.array,
    revenueDistribution: PropTypes.array,
};

Screen.defaultProps = {
    companyAddress:      null,
    companyId:           null,
    companyLogo:         null,
    companyName:         null,
    companyTypes:        [],
    companyVerified:     VerifiedProps,
    developmentRevenue:  [],
    exportRatio:         [],
    facts:               null,
    goBack:              _.noop,
    products:            [],
    revenueDistribution: [],
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...NavigationActions,
    },
    dispatch,
);

const mapStateToProps = (state, ownProps) => {
    const companyId = Navigation.getCompanyIdFromUrl(ownProps);

    return {
        companyAddress:      State.getCompanyValue(state, companyId, 'address'),
        companyName:         State.getCompanyValue(state, companyId, 'name'),
        companyTypes:        State.getCompanyValue(state, companyId, 'types', []),
        companyVerified:     State.getCompanyVerified(state, companyId),
        companyLogo:         State.getCompanyValue(state, companyId, 'logo.path', null),
        companyId:           Navigation.getParameterFromUrl(ownProps, 'companyId'),
        products:            State.getCompanyValue(state, companyId, 'factSheet.products'),
        developmentRevenue:  State.getCompanyValue(state, companyId, 'factSheet.developmentRevenue', []),
        revenueDistribution: State.getCompanyValue(state, companyId, 'factSheet.revenueDistribution', []),
        exportRatio:         State.getCompanyValue(state, companyId, 'factSheet.exportRatio', []),
        facts:               State.getCompanyValue(state, companyId, 'factSheet.facts'),
    };
};

const Composed = compose(connect(
    mapStateToProps,
    {
        ...mapDispatchToProps,
        goBack,
    },
))(Screen);

export default withFetchCompany(Composed);
