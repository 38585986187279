//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React             from 'react';
import PropTypes         from '@/components/PropTypes';
import { HeadlineSmall } from '@/components/stateless/atomic/HeadlineSmall';
import styles            from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { required, label } = this.props;
        const headerText          = (
            required
                ? `${label} *`
                : label
        );

        return (
            <div className={styles.formRow}>
                <HeadlineSmall text={headerText} />
                {this.props.children}
            </div>
        );
    }
}

export const FormRow = Component;

Component.propTypes = {
    children: PropTypes.children.isRequired,
    label:    PropTypes.string,
    required: PropTypes.bool,
};

Component.defaultProps = {
    children: null,
    label:    null,
    required: false,
};

export default Component;
