//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiUrls       from '@/constants/ApiUrls';
import ProjectStatus from '@/constants/ProjectStatus';

export default (api) => {
    return {
        acceptProject:      (companyIri, projectIir) => {
            return api.post(
                ApiUrls.API_PROJECT_MATCHES,
                {
                    matchingCompany: companyIri,
                    project:         projectIir,
                    status:          ProjectStatus.INTERESTED,
                },
            );
        },
        declineProject:     (companyId, projectId) => {
            return api.post(
                ApiUrls.API_PROJECT_MATCHES,
                {
                    matchingCompany: companyId,
                    project:         projectId,
                    status:          ProjectStatus.DECLINED,
                },
            );
        },
        rejectProjectMatch: (matchIri) => {
            return api.put(
                matchIri,
                {
                    status: ProjectStatus.REJECTED,
                },
            );
        },
        updateProjectMatch: (matchIri, data) => {
            return api.put(
                matchIri,
                data,
            );
        },
        fetchSuggestions:   () => {
            return api.get(ApiUrls.API_PROJECTS_SUGGESTIONS);
        },
        fetchOwnProjects:   () => {
            return api.get(ApiUrls.API_PROJECTS_OWN_PROJECTS);
        },
        fetchProject:       (projectId) => {
            return api.get(`${ApiUrls.API_PROJECT}/${projectId}`);
        },
        create:             (project) => {
            return api.post(ApiUrls.API_PROJECTS_CREATE, project);
        },
        fetchMatchStatus:   (projectIri, companyIri) => {
            return api.get(ApiUrls.API_PROJECT_MATCHES, {
                projectIri,
                companyIri,
            });
        },
        closeProject:       (projectIri) => {
            return api.put(projectIri, {
                status: ProjectStatus.CLOSED,
            });
        },
    };
};
