//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React          from 'react';
import I18n           from 'i18next';
import SideMenuButton from '@/components/stateless/atomic/SideMenuButton';
import Route          from '@/helper/Route';
import Routes         from '@/constants/Routes';

class ProfileMenu {
    static renderMyProfileButton = (props, activeRoute, isOwnProfile) => {
        if (props.isOnOwnProfile !== isOwnProfile) {
            return null;
        }

        return (
            <SideMenuButton
                title={I18n.t('settings')}
                to={Routes.myProfile}
                active={activeRoute === Routes.myProfile}
            />
        );
    };

    static renderMessagesButton = (props, activeRoute, isOwnProfile, onClick) => {
        if (props.isOnOwnProfile !== isOwnProfile) {
            return null;
        }

        return (
            <SideMenuButton
                title={I18n.t('profileMessages')}
                onClick={onClick}
                active={activeRoute === Routes.myProfileMessages}
            />
        );
    };

    static renderProjectsButton = (props, activeRoute, isOwnProfile, onClick) => {
        if (props.isOnOwnProfile !== isOwnProfile) {
            return null;
        }

        return (
            <SideMenuButton
                title={I18n.t('projects')}
                onClick={onClick}
                active={activeRoute === Routes.myProfileProjects}
            />
        );
    };

    static renderFavoritesButton = (props, activeRoute, isOwnProfile, onClick) => {
        if (props.isOnOwnProfile !== isOwnProfile) {
            return null;
        }

        return (
            <SideMenuButton
                title={I18n.t('favorites')}
                onClick={onClick}
            />
        );
    };

    static renderMyCompanyButton = (props, activeRoute, isOwnProfile) => {
        if (
            props.isOnOwnProfile !== isOwnProfile ||
            !props.isUserWithoutCompany
        ) {
            return null;
        }

        return (
            <SideMenuButton
                title={I18n.t('joinCompany')}
                to={Routes.myProfileJoinCompany}
                active={activeRoute === Routes.myProfileJoinCompany}
            />
        );
    };

    static renderUserManagementButton = (props, activeRoute) => {
        return (
            <SideMenuButton
                active={activeRoute === Routes.userManagement}
                to={Route.buildRoute(Routes.userManagement, props.currentProfileUrl)}
                title={I18n.t('userManagement')}
            />
        );
    };

    static renderProfileOverview = (props, activeRoute, renderProfileOverview) => {
        if (!renderProfileOverview) {
            return null;
        }

        return (
            <>
                <SideMenuButton
                    title={I18n.t('profileOverview')}
                    to={Route.buildRoute(Routes.profile, props.currentProfileUrl)}
                    active={activeRoute === Routes.profile}
                />
                {this.renderMessagesButton(props, activeRoute, false)}
            </>
        );
    };

    static renderCurrentRoleButton = (props, activeRoute, isOwnProfile) => {
        if (props.isOnOwnProfile !== isOwnProfile) {
            return null;
        }

        return (
            <SideMenuButton
                title={I18n.t('currentRole')}
                to={Routes.myProfileCurrentRole}
                active={activeRoute === Routes.myProfileCurrentRole}
            />
        );
    };

    static renderUserVerification = (props, activeRoute, isOwnProfile) => {
        if (props.isOnOwnProfile !== isOwnProfile) {
            return null;
        }

        return (
            <SideMenuButton
                title={I18n.t('userVerification')}
                to={Routes.myProfileVerification}
                active={activeRoute === Routes.myProfileVerification}
            />
        );
    };
}

export default ProfileMenu;
