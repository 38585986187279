//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import PropTypes       from '@/components/PropTypes';
import Tippy           from '@tippyjs/react';
import ComponentHelper from '@/helper/ComponentHelper';

export class Component extends React.Component {
    render() {
        const { content, children } = this.props;

        return (
            <Tippy
                content={content}
                interactive={true}
                arrow={true}
            >
                {children}
            </Tippy>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Tooltip = Component;

Component.propTypes = {
    children: PropTypes.children,
    content:  PropTypes.jsx,
};

Component.defaultProps = {
    children: null,
    content:  null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
