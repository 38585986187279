//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const ToastTypes = {
    CLEAR:        'AlertBox/CLEAR',
    SHOW_ERROR:   'AlertBox/SHOW_ERROR',
    SHOW_SUCCESS: 'AlertBox/SHOW_SUCCESS',
};

const clearAlerts = makeActionCreator(
    ToastTypes.CLEAR,
    {},
);

const showErrorAlert = makeActionCreator(
    ToastTypes.SHOW_ERROR,
    {
        text:      null,
        lifeCount: null,
    },
);

const showSuccessAlert = makeActionCreator(
    ToastTypes.SHOW_SUCCESS,
    {
        text:      null,
        lifeCount: null,
    },
);

export const AlertBoxActions = {
    clearAlerts,
    showErrorAlert,
    showSuccessAlert,
};
