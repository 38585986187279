//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import _                      from 'lodash';
import PropTypes              from '@/components/PropTypes';
import ComponentHelper        from '@/helper/ComponentHelper';
import StarButton             from '@/components/stateless/composed/StarButton';
import Hydra                  from '@/helper/Hydra';
import State                  from '@/helper/State';
import { FavoriteActions }    from '@/store/actions/favorite';

export class Component extends React.Component {
    onRemoveFavoriteCompany = (company) => {
        this.props.removeFavoriteCompany({
            company,
        });
    };

    onAddFavoriteCompany = (company) => {
        this.props.addFavoriteCompany({
            company,
        });
    };

    onStarButtonClick = (event) => {
        const { companyIri, isFavorite } = this.props;
        const company                    = companyIri;

        if (isFavorite) {
            this.onRemoveFavoriteCompany(company);
        } else {
            this.onAddFavoriteCompany(company);
        }

        event.stopPropagation();
    };

    render() {
        const { ownCompanyIri, companyIri, isLoggedIn } = this.props;

        if (
            !isLoggedIn ||
            (
                ownCompanyIri &&
                ownCompanyIri === companyIri
            )
        ) {
            return null;
        }

        const { isFavorite, noHover } = this.props;

        return (
            <StarButton
                onClick={this.onStarButtonClick}
                gray={!isFavorite}
                glow={isFavorite}
                noHover={noHover}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const FavoriseCompanyButton = Component;

Component.propTypes = {
    addFavoriteCompany:    PropTypes.func,
    company:               PropTypes.string,
    companyIri:            PropTypes.string,
    glow:                  PropTypes.bool,
    isFavorite:            PropTypes.bool,
    isLoggedIn:            PropTypes.bool,
    noHover:               PropTypes.bool,
    ownCompanyIri:         PropTypes.string,
    removeFavoriteCompany: PropTypes.func,
};

Component.defaultProps = {
    addFavoriteCompany:    _.noop,
    company:               null,
    companyIri:            null,
    glow:                  false,
    isFavorite:            false,
    isLoggedIn:            false,
    noHover:               false,
    ownCompanyIri:         null,
    removeFavoriteCompany: _.noop,
};

Component.renderAffectingProps = _.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state, ownProps) => {
    const companyIri = Hydra.getIriFromId('companies', _.get(ownProps, 'company'));
    const isFavorite = State.isCompanyFavorite(state, companyIri);

    return {
        companyIri,
        isFavorite,
        isLoggedIn:    !_.isEmpty(_.get(state, 'user.token', null)),
        ownCompanyIri: Hydra.getIriFromId('companies', _.get(state, 'user.company')),
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...FavoriteActions,
    },
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
