//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { TabBar }                 from '@/components/stateless/composed/TabBar';
import React                      from 'react';
import _                          from 'lodash';
import { bindActionCreators }     from 'redux';
import { compose }                from 'redux';
import { connect }                from 'react-redux';
import appStyles                  from '@/styles.module.scss';
import ComponentHelper            from '@/helper/ComponentHelper';
import Ids                        from '@/constants/Ids';
import PropTypes                  from '@/components/PropTypes';
import Routes                     from '@/constants/Routes';
import State                      from '@/helper/State';
import { CompanyActions }         from '@/store/actions/company';
import ContentHeader              from '@/components/connected/ContentHeader';
import { MachineActions }         from '@/store/actions/machine';
import { OverlayActions }         from '@/store/actions/ovleray';
import AddNewProductBox           from '@/components/connected/AddNewProductBox';
import FavoriseCompanyButton      from '@/components/connected/FavoriseCompanyButton';
import ProductionPortfolio        from '@/components/stateless/composed/ProductionPortfolio';
import { ProductActions }         from '@/store/actions/product';
import CompanySideMenu            from '@/components/connected/CompanySideMenu';
import ShowIfUserIsAdminOfCompany from '@/components/connected/ShowIfUserIsAdminOfCompany';
import { Spacer }                 from '@/components/stateless/atomic/Spacer';
import withFetchCompany           from '@/components/hoc/FetchCompany';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 0,
        };
    }

    componentDidMount() {
        this.props.fetchProducts();
    }

    onTabIndexChanged = (index) => () => {
        this.setState({
            tabIndex: index,
        });
    };

    editProduct = (product) => () => {
        this.props.editProduct({
            product,
        });
    };

    requestProduct = (product) => () => {
        this.props.requestProduct({
            product,
        });
    };

    deleteProduct = (product) => () => {
        this.props.deleteProduct({
            product,
        });
    };

    getTabs = () => {
        const categories = Object.entries(this.props.categorizedProducts);

        return categories.map(([key, value]) => ({
            title:      key,
            badgeCount: value.length,
        }));
    };

    render() {
        const { props }           = this;
        const categorizedProducts = Object.values(this.props.categorizedProducts);
        const visibleProducts     = categorizedProducts[this.state.tabIndex];

        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <CompanySideMenu
                        route={Routes.companyProduction}
                        {...this.props}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        <ContentHeader
                            rightContent={this.renderStarButton()}
                            currentCompanyUrl={props.currentCompanyUrl}
                            currentCompanyId={props.currentCompanyId}
                        />
                        <TabBar
                            tabs={this.getTabs()}
                            changeActiveTabIndex={this.onTabIndexChanged}
                            activeTabIndex={this.state.tabIndex}
                        />
                        <ShowIfUserIsAdminOfCompany
                            currentCompanyId={props.currentCompanyId}
                        >
                            <AddNewProductBox />
                        </ShowIfUserIsAdminOfCompany>
                        <Spacer height={20} />
                        <ProductionPortfolio
                            productionPortfolios={visibleProducts}
                            currentCompanyId={props.currentCompanyId}
                            onDeleteClicked={this.deleteProduct}
                            onEditClicked={this.editProduct}
                            onRequestClicked={this.requestProduct}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderStarButton = () => {
        return (
            <FavoriseCompanyButton
                company={this.props.currentCompanyId}
                glow={true}
            />
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    categorizedProducts: PropTypes.object,
    companyAddress:      PropTypes.object,
    companyName:         PropTypes.string,
    companyTypes:        PropTypes.array,
    competences:         PropTypes.array,
    currentCompanyId:    PropTypes.string,
    currentCompanyUrl:   PropTypes.string,
    deleteProduct:       PropTypes.func,
    editProduct:         PropTypes.func,
    fetchCompany:        PropTypes.func,
    fetchProducts:       PropTypes.func,
    openConfirmDialog:   PropTypes.func,
    openEditOverlay:     PropTypes.func,
    requestProduct:      PropTypes.func,
    setCurrentCompany:   PropTypes.func,
};

Screen.defaultProps = {
    categorizedProducts: {},
    companyAddress:      {},
    companyName:         null,
    companyTypes:        [],
    competences:         [],
    currentCompanyId:    null,
    currentCompanyUrl:   null,
    deleteProduct:       _.noop,
    editProduct:         _.noop,
    fetchCompany:        _.noop,
    fetchProducts:       _.noop,
    openConfirmDialog:   _.noop,
    openEditOverlay:     _.noop,
    requestProduct:      _.noop,
    setCurrentCompany:   _.noop,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [
    'tabIndex',
];

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...CompanyActions,
    ...MachineActions,
    ...ProductActions,
    ...OverlayActions,
}, dispatch);

const mapStateToProps = (state, ownProps) => {
    const { currentCompanyId } = ownProps;
    const products             = _.get(state, 'product.products', []);
    const currentCompanyMember = State.isUserCompanyMember(state);
    const categorizedProducts  = {};

    if (currentCompanyMember) {
        products.forEach((product) => {
            const category = product?.category?.toLowerCase() ?? 'all';

            if (!categorizedProducts[category]) {
                categorizedProducts[category] = [];
            }

            categorizedProducts[category].push(product);
        });
    } else {
        categorizedProducts.all = products;
    }

    return {
        companyAddress: State.getCompanyValue(state, currentCompanyId, 'address'),
        companyName:    State.getCompanyValue(state, currentCompanyId, 'name'),
        companyTypes:   State.getCompanyValue(state, currentCompanyId, 'types', []),
        competences:    State.getCompanyValue(state, currentCompanyId, 'competencesHierarchy', []),
        categorizedProducts,
    };
};

const Composed = compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);

export default withFetchCompany(Composed);
