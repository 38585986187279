//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import DropDownInput          from '@/components/stateless/atomic/DropDownInput';
import { TagActions }         from '@/store/actions/tag';

export class Component extends React.Component {
    valueChanged = (value) => {
        this.props.valueChanged(value);
    };

    getOptionValue = (tag) => tag.iri;

    getOptionLabel = (tag) => tag.title;

    render() {
        const uniqueTags = this.props.tags.filter((tag) => !this.props.selectedValueIds.includes(tag.id));

        return (
            <DropDownInput
                options={uniqueTags}
                multiple={this.props.multiple}
                renderBelow={this.props.renderBelow}
                defaultValue={this.props.value}
                onChange={this.valueChanged}
                getOptionValue={this.getOptionValue}
                getOptionLabel={this.getOptionLabel}
                loadOptions={this.loadOptions}
                placeholder={this.props.placeholder}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const TagDropDown = Component;

Component.propTypes = {
    multiple:         PropTypes.bool,
    placeholder:      PropTypes.string,
    renderBelow:      PropTypes.bool,
    tags:             PropTypes.array,
    selectedValueIds: PropTypes.array,
    tagType:          PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    value:            PropTypes.stringOrArrayOrObject,
    valueChanged:     PropTypes.func,
    multipleTagTypes: PropTypes.bool,
    filter:           PropTypes.func,
};

Component.defaultProps = {
    multiple:         false,
    placeholder:      null,
    renderBelow:      false,
    tags:             [],
    selectedValueIds: [],
    tagType:          null,
    value:            null,
    valueChanged:     null,
    multipleTagTypes: false,
    filter:           null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...TagActions,
    },
    dispatch,
);

const mapStateToProps = (state, ownProps) => {
    const tagsOfProperty                = _.get(ownProps, 'tags', []);
    const { tagType, multipleTagTypes } = ownProps;
    let tags                            = [];
    const { filter }                    = ownProps;

    if (multipleTagTypes) {
        _.forEach(tagType, (type) => {
            const newTags = _.get(state, ['tag', 'dropDown', type], tagsOfProperty);
            tags          = _.concat(tags, newTags);
        });
    } else {
        tags = _.get(state, ['tag', 'dropDown', tagType], tagsOfProperty);
    }

    if (filter) {
        tags = _.filter(tags, filter);
    }

    return {
        tags,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
