//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Routes from '@/constants/Routes';

export default {
    [Routes.companyMachine]:     {
        key:  'companyMachineCompanyName',
        prop: 'companyName',
    },
    [Routes.company]:              {
        key:  'companyProfileCompanyName',
        prop: 'companyName',
    },
    [Routes.companyFactSheet]:     {
        key:  'companyProfileCompanyName',
        prop: 'companyName',
    },
    [Routes.companyMessage]:       {
        key:  'companyProfileCompanyName',
        prop: 'companyName',
    },
    [Routes.companyProduction]:    {
        key:  'companyProfileCompanyName',
        prop: 'companyName',
    },
    [Routes.companyProjects]:      {
        key:  'companyProfileCompanyName',
        prop: 'companyName',
    },
    [Routes.companyMessageDetail]: {
        key:  'companyProfileMessage',
        prop: 'companyName',
    },
    [Routes.machine]:              {
        key:  'machineDetailName',
        prop: 'machineName',
    },
    [Routes.about]:                {
        key: 'aboutUs',
    },
    [Routes.membership]:            {
        key: 'membership',
    },
    [Routes.favorites]:            {
        key: 'favorites',
    },
    [Routes.home]:                 {
        key: 'home',
    },
    [Routes.imprint]:              {
        key: 'imprint',
    },
    [Routes.login]:                {
        key: 'login',
    },
    [Routes.lostPassword]:         {
        key: 'lostPassword',
    },
    [Routes.myCompany]:            {
        key: 'myCompany',
    },
    [Routes.notFound]:             {
        key: 'notFound',
    },
    [Routes.screenDesign]:         {
        key: 'screenDesign',
    },
    [Routes.searchResults]:        {
        key: 'searchResults',
    },
    [Routes.singUp]:               {
        key: 'singUp',
    },
    [Routes.singUp]:               {
        key: 'singUp',
    },
    [Routes.userManagement]:       {
        key: 'userManagement',
    },
};
