//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update           from 'immutability-helper';
import { OverlayTypes } from '@/store/actions/ovleray';

const initialState = {
    confirmDialog: {
        cancelButtonText:  null,
        confirmAction:     null,
        confirmButtonText: null,
        message:           null,
        title:             null,
    },
};

const openConfirmDialog = (action, state) => {
    return update(state, {
        confirmDialog: {
            cancelButtonText:  {
                $set: action.cancelButtonText,
            },
            confirmAction:     {
                $set: action.confirmAction,
            },
            cancelAction:      {
                $set: action.cancelAction,
            },
            confirmButtonText: {
                $set: action.confirmButtonText,
            },
            message:           {
                $set: action.message,
            },
            title:             {
                $set: action.title,
            },
            overlayType:       {
                $set: action.overlayType,
            },
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case OverlayTypes.OPEN_CONFIRM_DIALOG: return openConfirmDialog(action, state);
        default:                               return state;
        // @formatter:on
    }
}
