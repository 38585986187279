//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import PropTypes from '@/components/PropTypes';
import styles    from './styles.module.scss';

export class Component extends React.Component {
    getStyle = () => {
        const marginLeft = `${this.props.offset}px`;

        return {
            marginLeft,
        };
    };

    render() {
        return (
            <th className={styles.tableHeaderColumn}>
                {this.renderInnerContainer()}
            </th>
        );
    }

    renderInnerContainer = () => {
        const style = this.getStyle();

        if (style) {
            return (
                <span
                    className={styles.tableHeaderColumnInnerContainer}
                    style={style}
                >
                    {this.props.children}
                </span>
            );
        }

        return (
            <>
                {this.props.children}
            </>
        );
    };
}

export const TableHeaderColumn = Component;

Component.propTypes = {
    children: PropTypes.children,
    offset:   PropTypes.number,
};

Component.defaultProps = {
    children: null,
    offset:   0,
};

export default Component;
