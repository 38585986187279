//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    commodities:       'COMMODITIES',
    certificates:      'CERTIFICATES',
    exportRegion:      'EXPORT_REGION',
    regionExportRatio: 'REGION_EXPORT_RATIO',
    industry:          'INDUSTRY',
    language:          'LANGUAGE',
    process:           'PROCESS',
    processRoot:       'PROCESS_ROOT',
    productionType:    'PRODUCTION_TYPE',
    partFamily:        'PART_FAMILY',
    material:          'MATERIAL',
    processMachining:  'PROCESS_MACHINING',
    processCasting:    'PROCESS_CASTING',
};
