//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update from 'immutability-helper';

import countryList      from 'country-list';
import { ProfileTypes } from '@/store/actions/profile';
import I18n             from 'i18next';

const initialState = {
    data:       {},
    validation: {},
};

const createFormDataChanged = (action, state) => {
    const { field, value } = action;
    const updated          = update(state, {
        data: {
            [field]: {
                $set: value,
            },
        },
    });

    return updated;
};

const setInitialProfileData = (action, state) => {
    const { data }              = action;
    const { country, position } = data;

    if (country) {
        data.country = {
            value: country,
            label: countryList.getName(country),
        };
    } else {
        data.country = {
            value: '',
            label: '',
        };
    }

    if (position) {
        data.position = {
            value: position,
            label: I18n.t(`companyPositions.${position}`),
        };
    }

    return update(state, {
        data:       {
            $set: data,
        },
        validation: {
            $set: {},
        },
    });
};

const validationChanged = (action, state) => {
    const { validation } = action;

    return update(state, {
        validation: {
            $set: {
                ...validation,
            },
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case ProfileTypes.PROFILE_FORM_DATA_CHANGED:           return createFormDataChanged(action, state);
        case ProfileTypes.SET_INITIAL_PROFILE_DATA:            return setInitialProfileData(action, state);
        case ProfileTypes.VALIDATION_CHANGED:                  return validationChanged(action, state);
        default:                                               return state;
        // @formatter:on
    }
}
