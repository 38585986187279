//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import I18n             from 'i18next';
import PropTypes        from 'prop-types';
import _                from 'lodash';
import TextInput        from '@/components/stateless/atomic/TextInput';
import ColorButtonTheme from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import ColorButton      from '@/components/stateless/atomic/ColorButton';
import Spacer           from '@/components/stateless/atomic/Spacer';
import styles           from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCreateCompany: false,
        };
    }

    onCompanyNameChange = (event) => {
        this.props.onCompanyNameChange(event.target.value);
    };

    onPositionChange = (event) => {
        this.props.onPositionChange(event.target.value);
    };

    onCreateCompany = () => {
        if (!this.state.showCreateCompany) {
            this.setState({
                showCreateCompany: true,
            });

            return;
        }

        this.props.onCreateCompany();
    };

    renderInputs = () => {
        if (!this.state.showCreateCompany) {
            return null;
        }

        const { companyName, position } = this.props;

        return (
            <>
                <p>
                    {I18n.t('createCompanyText')}
                </p>
                <Spacer height={15} />
                <div className={styles.companyCreateBox}>
                    <TextInput
                        value={companyName}
                        placeholder={I18n.t('companyName')}
                        onChange={this.onCompanyNameChange}
                    />
                    <TextInput
                        value={position}
                        placeholder={I18n.t('position')}
                        onChange={this.onPositionChange}
                    />
                </div>
            </>
        );
    };

    render() {
        return (
            <>
                {this.renderInputs()}
                <Spacer height={20} />
                <div className={styles.footerButtonContainer}>
                    <ColorButton
                        onClick={this.onCreateCompany}
                        theme={ColorButtonTheme.orange}
                        text={I18n.t('createCompany')}
                    />
                </div>
            </>
        );
    }
}

export const CreateCompanyBox = Component;

Component.propTypes = {
    companyName:         PropTypes.string,
    onActPrivate:        PropTypes.func,
    onCompanyNameChange: PropTypes.func,
    onCreateCompany:     PropTypes.func,
    onPositionChange:    PropTypes.func,
    position:            PropTypes.string,
};

Component.defaultProps = {
    companyName:         '',
    onActPrivate:        _.noop,
    onCompanyNameChange: _.noop,
    onCreateCompany:     _.noop,
    onPositionChange:    _.noop,
    position:            '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'showCreateCompany',
];

export default Component;
