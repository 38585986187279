//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const SmallScreenTypes = {
    SMALL_SCREEN_ACCEPTED: 'SmallScreen/SMALL_SCREEN_ACCEPTED',
};

const acceptSmallScreen = makeActionCreator(
    SmallScreenTypes.SMALL_SCREEN_ACCEPTED,
    {},
);

export const SmallScreenActions = {
    acceptSmallScreen,
};
