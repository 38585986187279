//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import ComponentHelper        from '@/helper/ComponentHelper';
import { ReactNotifications } from 'react-notifications-component';
import styles                 from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.toastWrapper}>
                <ReactNotifications />
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ToastWrapper = Component;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
