//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const TagTypes = {
    DELETE_COMPETENCE_TAGS:            'Tag/DELETE_COMPETENCE_TAGS',
    DELETE_COMPETENCE_TAGS_FAILED:     'Tag/DELETE_COMPETENCE_TAGS_FAILED',
    DELETE_COMPETENCE_TAGS_SUCCEEDED:  'Tag/DELETE_COMPETENCE_TAGS_SUCCEEDED',
    DELETE_INTEREST_TAGS:              'Tag/DELETE_INTEREST_TAGS',
    DELETE_INTEREST_TAGS_FAILED:       'Tag/DELETE_INTEREST_TAGS_FAILED',
    DELETE_INTEREST_TAGS_SUCCEEDED:    'Tag/DELETE_INTEREST_TAGS_SUCCEEDED',
    FETCH_ROOT_TAGS:                   'Tag/FETCH_ROOT_TAGS',
    FETCH_ROOT_TAGS_FAILED:            'Tag/FETCH_ROOT_TAGS_FAILED',
    FETCH_ROOT_TAGS_SUCCEEDED:         'Tag/FETCH_ROOT_TAGS_SUCCEEDED',
    FETCH_TAGS_FOR_DROPDOWN:           'Tag/FETCH_TAGS_FOR_DROPDOWN',
    FETCH_TAGS_FOR_DROPDOWN_FAILED:    'Tag/FETCH_TAGS_FOR_DROPDOWN_FAILED',
    FETCH_TAGS_FOR_DROPDOWN_SUCCEEDED: 'Tag/FETCH_TAGS_FOR_DROPDOWN_SUCCEEDED',
    FETCH_TAGS:                        'Tag/FETCH_TAGS',
    FETCH_TAGS_FAILED:                 'Tag/FETCH_TAGS_FAILED',
    FETCH_TAGS_SUCCEEDED:              'Tag/FETCH_TAGS_SUCCEEDED',
    POST_COMPETENCE_TAGS:              'Tag/POST_COMPETENCE_TAGS',
    POST_COMPETENCE_TAGS_FAILED:       'Tag/POST_COMPETENCE_TAGS_FAILED',
    POST_COMPETENCE_TAGS_SUCCEEDED:    'Tag/POST_COMPETENCE_TAGS_SUCCEEDED',
    POST_INTEREST_TAGS:                'Tag/POST_INTEREST_TAGS',
    POST_INTEREST_TAGS_FAILED:         'Tag/POST_INTEREST_TAGS_FAILED',
    POST_INTEREST_TAGS_SUCCEEDED:      'Tag/POST_INTEREST_TAGS_SUCCEEDED',
    RESET:                             'Tag/RESET',
    RESET_TAG_QUERY:                   'Tag/RESET_TAG_QUERY',
    BUILD_HIERARCHY:                   'Tag/BUILD_HIERARCHY',
    SET_TAG_HIERARCHY:                 'Tag/SET_TAG_HIERARCHY',
    ADD_TAG:                           'Tag/ADD_TAG',
    DELETE_TAG:                        'Tag/DELETE_TAG',
    ADD_CHILDREN_TO_TAG_QUERY:         'Tag/ADD_CHILDREN_TO_TAG_QUERY',
};

const addChildrenToTagQuery = makeActionCreator(
    TagTypes.ADD_CHILDREN_TO_TAG_QUERY,
    {
        tag:      null,
        children: null,
    },
);

const addTag = makeActionCreator(
    TagTypes.ADD_TAG,
    {
        tag: null,
    },
);

const deleteTag = makeActionCreator(
    TagTypes.DELETE_TAG,
    {
        tag: null,
    },
);

const buildHierarchy = makeActionCreator(
    TagTypes.BUILD_HIERARCHY,
    {},
);

const setTagHierarchy = makeActionCreator(
    TagTypes.SET_TAG_HIERARCHY,
    {},
);

const deleteCompetenceTags = makeActionCreator(
    TagTypes.DELETE_COMPETENCE_TAGS,
    {
        competenceTagIris: null,
    },
);

const deleteCompetenceTagsFailed    = makeActionCreator(TagTypes.DELETE_COMPETENCE_TAGS_FAILED);
const deleteCompetenceTagsSucceeded = makeActionCreator(TagTypes.DELETE_COMPETENCE_TAGS_SUCCEEDED);

const deleteInterestTags = makeActionCreator(
    TagTypes.DELETE_INTEREST_TAGS,
    {
        interestTagIris: null,
    },
);

const deleteInterestTagsFailed    = makeActionCreator(TagTypes.DELETE_INTEREST_TAGS_FAILED);
const deleteInterestTagsSucceeded = makeActionCreator(TagTypes.DELETE_INTEREST_TAGS_SUCCEEDED);

const fetchTags = makeActionCreator(
    TagTypes.FETCH_TAGS,
    {
        title:   null,
        tagType: null,
    },
);

const fetchTagsFailed = makeActionCreator(TagTypes.FETCH_TAGS_FAILED);

const fetchTagsSucceeded = makeActionCreator(
    TagTypes.FETCH_TAGS_SUCCEEDED,
    {
        tags: null,
    },
);

const fetchRootTags = makeActionCreator(
    TagTypes.FETCH_ROOT_TAGS,
    {},
);

const fetchRootTagsFailed = makeActionCreator(TagTypes.FETCH_ROOT_TAGS_FAILED);

const fetchRootTagsSucceeded = makeActionCreator(
    TagTypes.FETCH_ROOT_TAGS_SUCCEEDED,
    {
        tags: null,
    },
);

const fetchTagsForDropdown = makeActionCreator(
    TagTypes.FETCH_TAGS_FOR_DROPDOWN,
    {
        tagType:    null,
        parameters: null,
    },
);

const fetchTagsForDropdownFailed = makeActionCreator(
    TagTypes.FETCH_TAGS_FOR_DROPDOWN_FAILED,
    {},
);

const fetchTagsForDropdownSucceeded = makeActionCreator(
    TagTypes.FETCH_TAGS_FOR_DROPDOWN_SUCCEEDED,
    {},
);

const postCompetenceTags = makeActionCreator(
    TagTypes.POST_COMPETENCE_TAGS,
    {
        tagIris: null,
    },
);

const postCompetenceTagsFailed    = makeActionCreator(TagTypes.POST_COMPETENCE_TAGS_FAILED);
const postCompetenceTagsSucceeded = makeActionCreator(TagTypes.POST_COMPETENCE_TAGS_SUCCEEDED);

const postInterestTags = makeActionCreator(
    TagTypes.POST_INTEREST_TAGS,
    {
        tagIris: null,
    },
);

const postInterestTagsFailed    = makeActionCreator(TagTypes.POST_INTEREST_TAGS_FAILED);
const postInterestTagsSucceeded = makeActionCreator(TagTypes.POST_INTEREST_TAGS_SUCCEEDED);
const reset                     = makeActionCreator(TagTypes.RESET);
const resetTagQuery             = makeActionCreator(TagTypes.RESET_TAG_QUERY);

export const TagActions = {
    deleteCompetenceTags,
    deleteCompetenceTagsFailed,
    deleteCompetenceTagsSucceeded,
    deleteInterestTags,
    deleteInterestTagsFailed,
    deleteInterestTagsSucceeded,
    fetchTags,
    fetchTagsFailed,
    fetchTagsSucceeded,
    fetchRootTags,
    fetchRootTagsFailed,
    fetchRootTagsSucceeded,
    fetchTagsForDropdown,
    fetchTagsForDropdownFailed,
    fetchTagsForDropdownSucceeded,
    postCompetenceTags,
    postCompetenceTagsFailed,
    postCompetenceTagsSucceeded,
    postInterestTags,
    postInterestTagsFailed,
    postInterestTagsSucceeded,
    reset,
    resetTagQuery,
    buildHierarchy,
    setTagHierarchy,
    addTag,
    deleteTag,
    addChildrenToTagQuery,
};
