//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const MembershipTypes = {
    FETCH_MEMBERSHIP:           'Membership/FETCH_MEMBERSHIP',
    FETCH_MEMBERSHIP_FAILED:    'Membership/FETCH_MEMBERSHIP_FAILED',
    FETCH_MEMBERSHIP_SUCCEEDED: 'Membership/FETCH_MEMBERSHIP_SUCCEEDED',
};

const fetchMemberships = makeActionCreator(
    MembershipTypes.FETCH_MEMBERSHIP,
    {},
);

const fetchMembershipsFailed = makeActionCreator(
    MembershipTypes.FETCH_MEMBERSHIP_FAILED,
    {},
);

const fetchMembershipsSucceeded = makeActionCreator(
    MembershipTypes.FETCH_MEMBERSHIP_SUCCEEDED,
    {
        options: null,
    },
);

export const MembershipActions = {
    fetchMemberships,
    fetchMembershipsFailed,
    fetchMembershipsSucceeded,
};
