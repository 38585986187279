//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                from 'lodash';
import PropTypes        from '@/components/PropTypes';
import ColorButton      from '@/components/stateless/atomic/ColorButton';
import I18n             from 'i18next';
import ColorButtonTheme from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';

import styles from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.magicBannerRequired}>
                <p>
                    {I18n.t('cookieBannerRequired')}
                </p>
                <ColorButton
                    text={I18n.t('cookieBanner.buttonAccept')}
                    theme={ColorButtonTheme.orange}
                    onClick={this.props.acceptCookies}
                />
            </div>
        );
    }
}

export const CookieBannerRequired = Component;

Component.propTypes = {
    acceptCookies: PropTypes.func,
};

Component.defaultProps = {
    acceptCookies: _.noop,
};

export default Component;
