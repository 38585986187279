//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import classNames       from 'classnames';
import ColorButtonSize  from '@/components/stateless/atomic/ColorButton/ColorButtonSize';
import ComponentHelper  from '@/helper/ComponentHelper';
import Link             from '@/components/stateless/atomic/Link';
import PropTypes        from '@/components/PropTypes';
import SelectionHelper  from '@/helper/SelectionHelper';
import { Icon }         from '@/components/stateless/atomic/Icon';
import styles           from './styles.module.scss';
import ColorButtonTheme from './ColorButtonTheme';

export class Component extends React.Component {
    getClassName = () => {
        const className = classNames(
            styles.colorButton,
            {
                [styles.colorButtonGrow]:      this.props.grow,
                [styles.colorButtonHover]:     this.props.hover,
                [styles.colorButtonDisabled]:  this.props.disabled,
                [styles.colorButtonUppercase]: this.props.uppercase,
            },
            SelectionHelper.get(
                this.props.theme,
                {
                    [ColorButtonTheme.white]:             styles.colorButtonWhite,
                    [ColorButtonTheme.orange]:            styles.colorButtonOrange,
                    [ColorButtonTheme.red]:               styles.colorButtonRed,
                    [ColorButtonTheme.whiteGhost]:        styles.colorButtonWhiteGhost,
                    [ColorButtonTheme.transparent]:       styles.colorButtonTransparent,
                    [ColorButtonTheme.transparentBorder]: styles.colorButtonTransparentBorder,
                },
            ),
            SelectionHelper.get(
                this.props.size,
                {
                    [ColorButtonSize.default]: styles.colorButtonSizeDefault,
                    [ColorButtonSize.large]:   styles.colorButtonSizeLarge,
                    [ColorButtonSize.medium]:  styles.colorButtonSizeMedium,
                },
            ),
        );

        return className;
    };

    render() {
        const wrapperClassName = this.getClassName();
        const disabled         = this.props.disabled;
        const to               = this.props.to;
        const target           = this.props.target;
        const onClick          = this.props.onClick;

        if (onClick) {
            return (
                <button
                    className={wrapperClassName}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {this.renderContent()}
                </button>
            );
        }

        if (to) {
            return (
                <Link
                    className={wrapperClassName}
                    to={to}
                    target={target}
                    disabled={disabled}
                >
                    {this.renderContent()}
                </Link>
            );
        }

        console.warn(`The "${this.props.text}" button is missing a "to", or "onClick" parameter.`);

        return (
            <button
                className={wrapperClassName}
                disabled={disabled}
            >
                {this.renderContent()}
            </button>
        );
    }

    renderContent = () => {
        return (
            <>
                {this.props.text}
                {this.renderIcon()}
            </>
        );
    };

    renderIcon = () => {
        const { icon } = this.props;

        if (icon) {
            return (
                <div className={styles.iconWrapper}>
                    <Icon iconType={icon} />
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ColorButton = Component;

Component.propTypes = {
    disabled:  PropTypes.bool,
    grow:      PropTypes.bool,
    hover:     PropTypes.bool,
    icon:      PropTypes.icon,
    onClick:   PropTypes.func,
    size:      PropTypes.oneOfObjectValues(ColorButtonSize),
    target:    PropTypes.string,
    text:      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    theme:     PropTypes.oneOfObjectValues(ColorButtonTheme),
    to:        PropTypes.to,
    uppercase: PropTypes.bool,
};

Component.defaultProps = {
    disabled:  false,
    grow:      false,
    hover:     false,
    icon:      null,
    onClick:   null,
    size:      ColorButtonSize.default,
    target:    null,
    text:      null,
    theme:     null,
    to:        null,
    uppercase: false,
};

Component.renderAffectingProps = [
    'disabled',
    'hover',
    'target',
    'text',
    'theme',
    'text',
];

Component.renderAffectingStates = [];

export default Component;
