//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { connect }     from 'react-redux';
import { withRouter }  from 'react-router';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import ProfileSideMenu from '@/components/connected/ProfileSideMenu';
import CompanySideMenu from '@/components/connected/CompanySideMenu';
import ViewingContext  from '@/constants/ViewingContext';
import Navigation      from '@/helper/Navigation';

export class Component extends React.Component {
    render() {
        const sideMenuComponent = this.getSideMenuComponent();

        if (!sideMenuComponent) {
            return null;
        }

        const component = React.createElement(
            sideMenuComponent,
            this.props,
        );

        return component;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }

    getSideMenuComponent() {
        if (
            this.props.viewingContext === ViewingContext.OWN_PROFILE ||
            this.props.viewingContext === ViewingContext.OTHER_PROFILE
        ) {
            return ProfileSideMenu;
        }

        if (
            this.props.viewingContext === ViewingContext.OWN_COMPANY ||
            this.props.viewingContext === ViewingContext.OTHER_COMPANY
        ) {
            return CompanySideMenu;
        }

        return null;
    }
}

Component.propTypes = {
    viewingContext: PropTypes.oneOfObjectValues(ViewingContext),
    route:          PropTypes.string,
};

Component.defaultProps = {
    viewingContext: ViewingContext.UNDEFINED,
    route:          null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state, ownProps) => {
    const viewingContext = Navigation.getViewingContext(ownProps, state);

    return {
        viewingContext,
    };
};

export const SideMenu = Component;

export default withRouter(connect(
    mapStateToProps,
    null,
)(Component));
