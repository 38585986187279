//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const UserTypes = {
    FETCH_JOIN_REQUESTS:                'User/FETCH_JOIN_REQUESTS',
    JOIN_COMPANY:                       'User/JOIN_COMPANY',
    DELETE_PROFILE:                     'User/DELETE_PROFILE',
    LEAVE_COMPANY:                      'User/LEAVE_COMPANY',
    LEAVE_COMPANY_CONFIRM:              'User/LEAVE_COMPANY',
    LOGIN:                              'User/LOGIN',
    LOGIN_FAILED:                       'User/LOGIN_FAILED',
    LOGIN_SUCCEEDED:                    'User/LOGIN_SUCCEEDED',
    FETCH:                              'User/FETCH',
    FETCH_FAILED:                       'User/FETCH_FAILED',
    FETCH_SUCCEEDED:                    'User/FETCH_SUCCEEDED',
    LOGOUT:                             'User/LOGOUT',
    SET_JOIN_REQUESTS:                  'User/SET_JOIN_REQUESTS',
    SET_LOGIN_IDENTIFIER:               'User/SET_LOGIN_IDENTIFIER',
    SET_LOGIN_PASSWORD:                 'User/SET_LOGIN_PASSWORD',
    CHECK_ACT_PRIVATE:                  'User/CHECK_ACT_PRIVATE',
    SET_ACT_PRIVATE_CHECKED:            'User/SET_ACT_PRIVATE_CHECKED',
    SET_ID_CARD_IMAGE:                  'User/SET_ID_CARD_IMAGE',
    SET_BUSINESS_CARD_IMAGE:            'User/SET_BUSINESS_CARD_IMAGE',
    CREATE_USER_VERIFICATION:           'User/CREATE_USER_VERIFICATION',
    CREATE_USER_VERIFICATION_FAILED:    'User/CREATE_USER_VERIFICATION_FAILED',
    CREATE_USER_VERIFICATION_SUCCEEDED: 'User/CREATE_USER_VERIFICATION_SUCCEEDED',
    CHECK_ACTIVE_COMPANY:               'User/CHECK_ACTIVE_COMPANY',
};

const login       = makeActionCreator(UserTypes.LOGIN);
const loginFailed = makeActionCreator(UserTypes.LOGIN_FAILED);

const loginSucceeded = makeActionCreator(UserTypes.LOGIN_SUCCEEDED, {
    company:          null,
    email:            null,
    lastLogin:        null,
    name:             null,
    token:            null,
    userId:           null,
    username:         null,
    phone:            null,
    position:         null,
    country:          null,
    workingAtCompany: null,
});

const fetch       = makeActionCreator(UserTypes.FETCH);
const fetchFailed = makeActionCreator(UserTypes.FETCH_FAILED);

const fetchSucceeded = makeActionCreator(UserTypes.FETCH_SUCCEEDED, {
    company:   null,
    email:     null,
    lastLogin: null,
    name:      null,
    token:     null,
    userId:    null,
    username:  null,
});

const checkActiveCompany = makeActionCreator(
    UserTypes.CHECK_ACTIVE_COMPANY,
    {},
);

const logout = makeActionCreator(UserTypes.LOGOUT);

const setLoginIdentifier = makeActionCreator(
    UserTypes.SET_LOGIN_IDENTIFIER,
    {
        identifier: null,
    },
);

const setLoginPassword = makeActionCreator(
    UserTypes.SET_LOGIN_PASSWORD,
    {
        password: null,
    },
);

const joinCompany = makeActionCreator(
    UserTypes.JOIN_COMPANY,
    {
        companyId: null,
    },
);

const leaveCompany = makeActionCreator(
    UserTypes.LEAVE_COMPANY,
    {
        companyId: null,
    },
);

const fetchJoinRequests = makeActionCreator(UserTypes.FETCH_JOIN_REQUESTS);

const setJoinRequests = makeActionCreator(
    UserTypes.SET_JOIN_REQUESTS,
    {
        joinRequests: [],
    },
);

const checkActPrivate = makeActionCreator(
    UserTypes.CHECK_ACT_PRIVATE,
    {
        joinRequests: [],
    },
);

const setActPrivateChecked = makeActionCreator(
    UserTypes.SET_ACT_PRIVATE_CHECKED,
    {
        checked: false,
    },
);

const leaveCompanyConfirm = makeActionCreator(
    UserTypes.LEAVE_COMPANY_CONFIRM,
    {
        companyId: null,
    },
);

const setIDCardImage = makeActionCreator(
    UserTypes.SET_ID_CARD_IMAGE,
    {
        identityCard: null,
    },
);

const setBusinessCardImage = makeActionCreator(
    UserTypes.SET_BUSINESS_CARD_IMAGE,
    {
        businessCard: null,
    },
);

const createUserVerification = makeActionCreator(
    UserTypes.CREATE_USER_VERIFICATION,
    {},
);

const createUserVerificationFailed = makeActionCreator(
    UserTypes.CREATE_USER_VERIFICATION_FAILED,
    {},
);

const createUserVerificationSucceeded = makeActionCreator(
    UserTypes.CREATE_USER_VERIFICATION_SUCCEEDED,
    {},
);

const deleteProfile = makeActionCreator(UserTypes.DELETE_PROFILE);

export const UserActions = {
    checkActPrivate,
    checkActiveCompany,
    fetch,
    fetchFailed,
    fetchJoinRequests,
    fetchSucceeded,
    joinCompany,
    leaveCompany,
    leaveCompanyConfirm,
    login,
    loginFailed,
    loginSucceeded,
    logout,
    deleteProfile,
    setActPrivateChecked,
    setJoinRequests,
    setLoginIdentifier,
    setLoginPassword,
    setIDCardImage,
    setBusinessCardImage,
    createUserVerification,
    createUserVerificationFailed,
    createUserVerificationSucceeded,
};
