//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                 from 'react';
import _                     from 'lodash';
import { CalendarContainer } from 'react-datepicker';
import DatePicker            from 'react-datepicker';
import classNames            from 'classnames';

import ComponentHelper from '@/helper/ComponentHelper';
import Icon            from '@/components/stateless/atomic/Icon';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import PropTypes       from '@/components/PropTypes';

import styles from './styles.module.scss';

export class DateInput extends React.Component {
    static propTypes = {
        value:    PropTypes.object,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        value:    null,
        onChange: _.noop,
        disabled: false,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    Container = ({ className, children }) => {
        return (
            <CalendarContainer className={className}>
                <div
                    style={{
                        position: 'relative',
                    }}
                >
                    {children}
                </div>
            </CalendarContainer>
        );
    };

    render() {
        return (
            <div
                className={classNames(
                    styles.dateInputWrapper,
                    {
                        [styles.disabled]: this.props.disabled,
                    },
                )}
            >
                <DatePicker
                    wrapperClassName={styles.datePicker}
                    popperClassName={styles.datePickerPopper}
                    className={styles.dateInput}
                    selected={this.props.value}
                    onChange={this.props.onChange}
                    calendarContainer={this.Container}
                />
                <div className={styles.iconWrapper}>
                    <Icon iconType={IconType.calendar} />
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default DateInput;
