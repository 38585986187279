//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const LoadingTypes = {
    OVERLAY_CLICKED: 'Loading/OVERLAY_CLICKED',
    RESET_OVERLAY:   'Loading/RESET_OVERLAY',
};

const overlayClicked = makeActionCreator(
    LoadingTypes.OVERLAY_CLICKED,
    {},
);

const resetOverlay   = makeActionCreator(
    LoadingTypes.RESET_OVERLAY,
    {},
);

export const LoadingActions = {
    overlayClicked,
    resetOverlay,
};
