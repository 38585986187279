//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const TestIds = {
    addNewProductButton:   'add-new-product-button',
    burger:                'burger',
    exampleHeaderButton:   'example-header-button',
    homeHeaderButton:      'home-header-button',
    imprintHeaderButton:   'imprint-header-button',
    inventoryHeaderButton: 'inventory-header-button',
    loginHeaderButton:     'login-header-button',
    overlay:               'overlay',
    overlayCancelButton:   'overlay-cancel-button',
    overlayOkButton:       'overlay-ok-button',
    search:                'search',
};

const TestIdPrefixes = {
    inventoryItem: 'inventory-item-',
};

export {
    TestIds,
    TestIdPrefixes,
};
