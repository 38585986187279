//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                   from 'react';
import I18n                    from 'i18next';
import { Helmet }              from 'react-helmet';
import _                       from 'lodash';
import PropTypes               from '@/components/PropTypes';
import appStyles               from '@/styles.module.scss';
import PageTitleHelper         from '@/helper/PageTitle';
import { StartPageBackground } from '@/components/stateless/atomic/StartPageBackground';
import { Link }                from '@/components/stateless/atomic/Link';
import Routes                  from '@/constants/Routes';
import { Logo }                from '@/components/stateless/atomic/Logo';
import { StartPageBox }        from '@/components/stateless/composed/StartPageBox';
import { HeadlineMedium }      from '@/components/stateless/atomic/HeadlineMedium';
import Icon                    from '@/components/stateless/atomic/Icon';
import styles                  from './styles.module.scss';

export class Component extends React.Component {
    renderIcon = () => {
        const iconType = this.props.icon;

        if (!iconType) {
            return null;
        }

        return (
            <div className={styles.startPageWithBoxIconHeader}>
                <Icon
                    iconType={iconType}
                />
            </div>
        );
    };

    renderSubHeadline = () => {
        const { headlineSubtext } = this.props;

        if (!headlineSubtext) {
            return null;
        }

        return (
            <div className={styles.startPageWithBoxHeadlineSubtext}>
                {headlineSubtext}
            </div>
        );
    };

    render() {
        const { pageTitle, children, headlineAlign, headline } = this.props;
        const headlineWithFallback                             = _.defaultTo(headline, pageTitle);

        return (
            <div className={appStyles.fullScreen}>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(pageTitle)}
                    </title>
                </Helmet>
                <StartPageBackground />
                <div className={styles.startPageWithBoxContent}>
                    <h1>
                        <span>
                            {I18n.t('welcomeTo')}
                        </span>
                        <Link to={Routes.home}>
                            <Logo />
                        </Link>
                    </h1>
                    <StartPageBox>
                        {this.renderIcon()}
                        <HeadlineMedium
                            text={headlineWithFallback}
                            align={headlineAlign}
                        />
                        {this.renderSubHeadline()}
                        {children}
                    </StartPageBox>
                </div>
            </div>
        );
    }
}

export const StartPageWithBox = Component;

Component.propTypes = {
    children:        PropTypes.children,
    headline:        PropTypes.string,
    headlineAlign:   PropTypes.align,
    headlineSubtext: PropTypes.string,
    icon:            PropTypes.icon,
    pageTitle:       PropTypes.string,
};

Component.defaultProps = {
    children:        null,
    headline:        null,
    headlineAlign:   null,
    headlineSubtext: null,
    icon:            null,
    pageTitle:       null,
};

export default Component;
