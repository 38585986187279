//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update          from 'immutability-helper';
import _               from 'lodash';
import { SignupTypes } from '@/store/actions/signup';

const initialState = {
    identifier:       '',
    name:             null,
    password:         '',
    repeatedPassword: '',
    passwordScore:    0,
    company:          0,
};

const resetSignup = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const updateSignupField = (action, state) => {
    const { field, value } = action;
    const containsField    = _.has(initialState, field);

    if (!containsField) {
        return state;
    }

    return update(state, {
        [field]: {
            $set: value,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case SignupTypes.UPDATE_SIGNUP_FIELD: return updateSignupField(action, state);
        case SignupTypes.RESET_SIGNUP:        return resetSignup(action, state);
        default:                              return state;
        // @formatter:on
    }
}
