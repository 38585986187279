//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import _                from 'lodash';
import classNames       from 'classnames';
import I18n             from 'i18next';
import { Modal }        from 'react-overlays';
import ColorButton      from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import ComponentHelper  from '@/helper/ComponentHelper';
import HeadlineMedium   from '@/components/stateless/atomic/HeadlineMedium';
import Ids              from '@/constants/Ids';
import OverlayType      from '@/components/connected/Overlay/OverlayType';
import PropTypes        from '@/components/PropTypes';
import SelectionHelper  from '@/helper/SelectionHelper';
import { TestIds }      from '@/constants/TestIds';
import styles           from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.unblock = null;
        this.state   = {
            hasUnsavedChanges: false,
        };
    }

    componentDidMount() {
        if (this.props.history) {
            this.unblock = this.props.history.block(() => {
                if (this.state.hasUnsavedChanges) {
                    const message = 'Are you sure you want to leave? Any unsaved changes will be lost.';

                    return !!window.confirm(message); // eslint-disable-line no-alert
                }

                return true;
            });
        }

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentDidUpdate() {
        if (this.props.dataChanged !== this.state.hasUnsavedChanges) {
            this.setState({
                hasUnsavedChanges: this.props.dataChanged,
            });
        }
    }

    componentWillUnmount() {
        if (this.unblock) {
            this.unblock();
        }

        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {
        if (this.state.hasUnsavedChanges) {
            const message     = 'Are you sure you want to leave? Any unsaved changes will be lost.';
            event.returnValue = message; // eslint-disable-line no-param-reassign

            return message;
        }

        return null;
    };

    getTopMargin = () => {
        const contentContainer = document.querySelector(`#${Ids.contentContainer}`);

        if (contentContainer) {
            const top = contentContainer.getBoundingClientRect().top;

            return (
                top < 0 ? 150 : top
            );
        }

        return null;
    };

    getTypeClass = () => {
        return SelectionHelper.get(
            this.props.overlayType,
            {
                [OverlayType.full]:       styles.modalContainerTypeFull,
                [OverlayType.prompt]:     styles.modalContainerTypePrompt,
                [OverlayType.widePrompt]: styles.modalContainerTypeWidePrompt,
            },
        );
    };

    render() {
        return (
            <Modal
                onHide={this.props.onHide}
                show={true}
                renderDialog={this.renderDialog}
            />
        );
    }

    renderDialog = (dialogProps) => {
        return (
            <div
                data-test-id={TestIds.overlay}
                {...dialogProps}
                role={null}
                tabIndex={null}
            >
                <div
                    id={Ids.modalContent}
                    className={classNames(
                        styles.modalWrapper,
                        {
                            [styles.modalWrapperUnclosable]: !this.props.closable || this.props.disableCloseOnOutsideClick,
                        },
                    )}
                    onMouseDown={this.props.handleContainerMouseDown}
                    onMouseUp={this.props.handleContainerMouseUp}
                >
                    <div
                        className={classNames(
                            styles.modalContainer,
                            this.getTypeClass(),
                        )}
                        style={{
                            marginTop: this.getTopMargin(),
                        }}
                        onClick={this.props.modalContainerClicked}
                        onMouseDown={this.props.handleInnerMouseUpDown}
                        onMouseUp={this.props.handleInnerMouseUpDown}
                    >
                        {this.renderHeader()}
                        {this.renderContent()}
                        {this.renderFooter()}
                    </div>
                </div>
            </div>
        );
    };

    renderFooter = () => {
        return (
            <div className={styles.modalFooter}>
                {this.renderOkButton()}
                {this.renderCancelButton()}
                {this.props.renderAdditionalFooterButtons()}
            </div>
        );
    };

    renderOkButton = () => {
        return (
            <ColorButton
                test-id={TestIds.overlayOkButton}
                onClick={this.props.okButtonPressed}
                text={this.props.okButtonText}
                theme={ColorButtonTheme.orange}
            />
        );
    };

    renderCancelButton = () => {
        if (this.props.closable) {
            return (
                <ColorButton
                    test-id={TestIds.overlayCancelButton}
                    onClick={this.props.cancelButtonPressed}
                    text={this.props.cancelButtonText}
                    theme={ColorButtonTheme.white}
                />
            );
        }

        return null;
    };

    renderContent = () => {
        return (
            <div className={styles.modalContent}>
                {this.props.children}
            </div>
        );
    };

    renderHeader = () => {
        if (this.props.title) {
            return (
                <div className={styles.modalHeader}>
                    <HeadlineMedium text={this.props.title} />
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Overlay = Component;

Component.propTypes = {
    cancelButtonPressed:           PropTypes.func,
    cancelButtonText:              PropTypes.string,
    children:                      PropTypes.children,
    closable:                      PropTypes.bool,
    closeButtonPressed:            PropTypes.func,
    dataChanged:                   PropTypes.bool,
    disableCloseOnOutsideClick:    PropTypes.bool,
    handleContainerMouseDown:      PropTypes.func,
    handleContainerMouseUp:        PropTypes.func,
    handleInnerMouseUpDown:        PropTypes.func,
    history:                       PropTypes.object,
    modalContainerClicked:         PropTypes.func,
    okButtonPressed:               PropTypes.func,
    okButtonText:                  PropTypes.string,
    onHide:                        PropTypes.func,
    overlayType:                   PropTypes.oneOfObjectValues(OverlayType),
    renderAdditionalFooterButtons: PropTypes.func,
    title:                         PropTypes.string,
};

Component.defaultProps = {
    cancelButtonPressed:           _.noop,
    cancelButtonText:              I18n.t('cancel'),
    children:                      [],
    closable:                      true,
    closeButtonPressed:            _.noop,
    dataChanged:                   false,
    disableCloseOnOutsideClick:    false,
    handleContainerMouseDown:      _.noop,
    handleContainerMouseUp:        _.noop,
    handleInnerMouseUpDown:        _.noop,
    history:                       null,
    modalContainerClicked:         _.noop,
    okButtonPressed:               _.noop,
    okButtonText:                  I18n.t('ok'),
    onHide:                        _.noop,
    overlayType:                   OverlayType.prompt,
    renderAdditionalFooterButtons: _.noop,
    title:                         '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'hasUnsavedChanges',
];

export default Component;
