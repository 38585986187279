//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import _                from 'lodash';
import I18n             from 'i18next';
import { connect }      from 'react-redux';
import { goBack }       from 'connected-react-router';
import Overlays         from '@/constants/Overlays';
import PropTypes        from '@/components/PropTypes';
import StatelessOverlay from '@/components/stateless/atomic/Overlay';

import OverlayType from './OverlayType';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mouseIsDown: false,
        };
    }

    componentDidMount() {
        if (this.props.getOkButtonPressedCallback) {
            this.props.getOkButtonPressedCallback(this.okButtonPressed);
        }
    }

    isClickOnScrollBar = (event) => {
        const target = event.target;

        return (
            event.clientX > target.clientWidth ||
            event.clientY > target.clientHeight
        );
    };

    closeButtonPressed = () => {
        if (this.props.closable) {
            this.goBack();
            this.props.closeButtonPressed();
        }
    };

    goBack = () => {
        this.props.goBack();
    };

    handleContainerMouseDown = (event) => {
        if (!this.isClickOnScrollBar(event)) {
            this.setState({
                mouseIsDown: true,
            });
        }
    };

    handleContainerMouseUp = () => {
        if (this.state.mouseIsDown && !this.props.disableCloseOnOutsideClick) {
            this.closeButtonPressed();
        }

        this.setState({
            mouseIsDown: false,
        });
    };

    handleInnerMouseUpDown = (event) => {
        // Prevent the mouse down event to get passed to the overlay
        // to avoid it to get accidentally closed when the user clicks (mouse down) in the input field
        // and releases the mouse key (mouse up) outside of this field.
        // @see handleContainerMouseDown
        // @see handleContainerMouseUp
        event.stopPropagation();

        return false;
    };

    modalContainerClicked = (event) => {
        // Prevent the onClick from modalWrapper to get fired when a child is clicked
        event.stopPropagation();
    };

    okButtonPressed = () => {
        if (this.props.okButtonValidator === _.noop || this.props.okButtonValidator()) {
            this.props.okButtonPressed();

            if (this.props.nextRoute) {
                this.props.history.push(this.props.nextRoute);
            } else if (!this.props.disableCloseOnButtonPress) {
                this.goBack();
            }
        }
    };

    cancelButtonPressed = () => {
        const { closeOnCancel, cancelButtonPressed } = this.props;

        if (closeOnCancel) {
            this.closeButtonPressed();
        }

        cancelButtonPressed();
    };

    render() {
        const props = this.props;

        return (
            <StatelessOverlay
                onHide={this.onHide}
                show={true}
                className={props.className}
                cancelButtonText={props.cancelButtonText}
                closable={props.closable}
                okButtonText={props.okButtonText}
                overlayType={props.overlayType}
                title={props.title}
                disableCloseOnOutsideClick={props.disableCloseOnOutsideClick}
                closeButtonPressed={this.closeButtonPressed}
                okButtonPressed={this.okButtonPressed}
                cancelButtonPressed={this.cancelButtonPressed}
                handleContainerMouseDown={this.handleContainerMouseDown}
                handleContainerMouseUp={this.handleContainerMouseUp}
                modalContainerClicked={this.modalContainerClicked}
                handleInnerMouseUpDown={this.handleInnerMouseUpDown}
                renderAdditionalFooterButtons={props.renderAdditionalFooterButtons}
                history={this.props.history}
                dataChanged={this.props.dataChanged}
            >
                {props.children}
            </StatelessOverlay>
        );
    }
}

Component.propTypes = {
    cancelButtonPressed:           PropTypes.func,
    cancelButtonText:              PropTypes.string,
    children:                      PropTypes.children,
    className:                     PropTypes.stringOrArrayOrObject,
    closable:                      PropTypes.bool,
    closeButtonPressed:            PropTypes.func,
    closeOnCancel:                 PropTypes.bool,
    dataChanged:                   PropTypes.bool,
    disableCloseOnButtonPress:     PropTypes.bool,
    disableCloseOnOutsideClick:    PropTypes.bool,
    getOkButtonPressedCallback:    PropTypes.func,
    goBack:                        PropTypes.func,
    history:                       PropTypes.object,
    id:                            PropTypes.oneOfObjectValues(Overlays),
    nextRoute:                     PropTypes.string,
    okButtonPressed:               PropTypes.func,
    okButtonText:                  PropTypes.string,
    okButtonValidator:             PropTypes.func,
    overlayType:                   PropTypes.oneOfObjectValues(OverlayType),
    renderAdditionalFooterButtons: PropTypes.func,
    title:                         PropTypes.string,
};

Component.defaultProps = {
    cancelButtonPressed:           _.noop,
    cancelButtonText:              I18n.t('cancel'),
    children:                      [],
    className:                     null,
    closable:                      true,
    closeButtonPressed:            _.noop,
    closeOnCancel:                 true,
    dataChanged:                   false,
    disableCloseOnButtonPress:     false,
    disableCloseOnOutsideClick:    false,
    getOkButtonPressedCallback:    _.noop,
    goBack:                        _.noop,
    history:                       null,
    id:                            '',
    nextRoute:                     '',
    okButtonPressed:               _.noop,
    okButtonText:                  I18n.t('ok'),
    okButtonValidator:             _.noop,
    overlayType:                   OverlayType.prompt,
    renderAdditionalFooterButtons: _.noop,
    title:                         '',
};

export const Overlay = Component;

export default connect(
    null,
    {
        goBack,
    },
)(Component);
