//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import { DropDownInput }         from '@/components/stateless/atomic/DropDownInput';
import PropTypes                 from '@/components/PropTypes';
import _                         from 'lodash';
import MachineCapacity           from '@/constants/MachineCapacity';
import { getCapacityOptions }    from '@/constants/MachineCapacity';
import { MachineCapacityStatus } from '@/components/stateless/composed/MachineCapacityStatus';
import ComponentHelper           from '@/helper/ComponentHelper';

export class Component extends React.Component {
    renderOption = (name, option) => {
        return (
            <MachineCapacityStatus
                capacity={option.value}
                disabled={this.props.disabled}
            />
        );
    };

    render() {
        const { onChange, machineCapacity, disabled } = this.props;
        const capacityOptions                         = getCapacityOptions();

        return (
            <DropDownInput
                multiple={false}
                options={capacityOptions}
                defaultValue={_.find(capacityOptions, {
                    value: machineCapacity,
                })}
                renderValue={this.renderOption}
                onChange={onChange}
                disabled={disabled}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const MachineCapacityDropdown = Component;

Component.propTypes = {
    disabled:        PropTypes.bool,
    machineCapacity: PropTypes.string,
    onChange:        PropTypes.func,
};

Component.defaultProps = {
    disabled:        false,
    machineCapacity: MachineCapacity.free,
    onChange:        _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
