//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                   from 'react';
import { Component }           from 'react';
import I18n                    from 'i18next';
import { connect }             from 'react-redux';
import _                       from 'lodash';
import { bindActionCreators }  from 'redux';
import appStyles               from '@/styles.module.scss';
import ComponentHelper         from '@/helper/ComponentHelper';
import StartPageWithBox        from '@/components/stateless/composed/StartPageWithBox';
import IconType                from '@/components/stateless/atomic/Icon/IconType';
import Align                   from '@/constants/Align';
import { TextInput }           from '@/components/stateless/atomic/TextInput';
import styles                  from '@/screens/LostPassword/styles.module.scss';
import ColorButtonTheme        from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import ColorButton             from '@/components/stateless/atomic/ColorButton';
import { StartPageBoxFooter }  from '@/components/stateless/composed/StartPageBoxFooter';
import Routes                  from '@/constants/Routes';
import PropTypes               from '@/components/PropTypes';
import { LostPasswordActions } from '@/store/actions/lostPassword';
import PasswordForm            from '@/components/stateless/composed/PasswordForm';

class Screen extends Component {
    identifierChanged = (event) => {
        const identifier = event.target.value;

        this.props.setIdentifier({
            identifier,
        });
    };

    requestPasswordButtonPressed = () => {
        this.props.requestPassword();
    };

    setNewPasswordButtonPressed = () => {
        this.props.setNewPassword();
    };

    passwordChanged = (event) => {
        const password = event.target.value;

        this.props.setPassword({
            password,
        });
    };

    repeatedPasswordChanged = (event) => {
        const repeatedPassword = event.target.value;

        this.props.setRepeatedPassword({
            repeatedPassword,
        });
    };

    passwordScoreUpdated = (passwordScore) => {
        this.props.setPasswordScore({
            passwordScore,
        });
    };

    renderRequestPassword = () => {
        if (this.props.token) {
            return null;
        }

        return (
            <StartPageWithBox
                icon={IconType.key}
                pageTitle={I18n.t('lostPasswordTitle')}
                headlineAlign={Align.center}
                headline={I18n.t('lostPasswordHeadline')}
                headlineSubtext={I18n.t('lostPasswordSubtext')}
            >
                <form onSubmit={this.onFormSubmit}>
                    <TextInput
                        placeholder={I18n.t('emailAddress')}
                        onChange={this.identifierChanged}
                        value={this.props.identifier}
                        autoFocus={true}
                    />
                </form>
                <div className={styles.requestButtonWrapper}>
                    <ColorButton
                        grow={true}
                        theme={ColorButtonTheme.orange}
                        text={I18n.t('lostPasswordRequestButton')}
                        onClick={this.requestPasswordButtonPressed}
                    />
                </div>
                <StartPageBoxFooter
                    text={I18n.t('alreadyRegistered')}
                    to={Routes.login}
                    align={Align.left}
                />
            </StartPageWithBox>
        );
    };

    renderSetNewPassword = () => {
        if (!this.props.token) {
            return null;
        }

        return (
            <StartPageWithBox
                icon={IconType.key}
                pageTitle={I18n.t('setNewPasswordTitle')}
                headlineAlign={Align.center}
                headlineSubtext={I18n.t('setNewPasswordSubtext')}
            >
                <form onSubmit={this.onFormSubmit}>
                    <PasswordForm
                        password={this.props.password}
                        repeatedPassword={this.props.repeatedPassword}
                        updatePassword={this.passwordChanged}
                        updateRepeatedPassword={this.repeatedPasswordChanged}
                        updatePasswordScore={this.passwordScoreUpdated}
                    />
                </form>
                <div className={styles.requestButtonWrapper}>
                    <ColorButton
                        grow={true}
                        theme={ColorButtonTheme.orange}
                        text={I18n.t('setNewPasswordTitle')}
                        onClick={this.setNewPasswordButtonPressed}
                    />
                </div>
            </StartPageWithBox>
        );
    };

    render() {
        return (
            <div className={appStyles.fullScreen}>
                {this.renderRequestPassword()}
                {this.renderSetNewPassword()}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    identifier:          PropTypes.string,
    password:            PropTypes.string,
    repeatedPassword:    PropTypes.string,
    requestPassword:     PropTypes.func,
    setIdentifier:       PropTypes.func,
    setNewPassword:      PropTypes.func,
    setPassword:         PropTypes.func,
    setPasswordScore:    PropTypes.func,
    setRepeatedPassword: PropTypes.func,
    token:               PropTypes.string,
};

Screen.defaultProps = {
    identifier:          null,
    password:            '',
    repeatedPassword:    '',
    requestPassword:     _.noop,
    setIdentifier:       _.noop,
    setNewPassword:      _.noop,
    setPassword:         _.noop,
    setPasswordScore:    _.noop,
    setRepeatedPassword: _.noop,
    token:               null,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        identifier:       _.get(state, 'lostPassword.identifier'),
        password:         _.get(state, 'lostPassword.password'),
        repeatedPassword: _.get(state, 'lostPassword.repeatedPassword'),
        token:            _.get(state, 'lostPassword.token'),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...LostPasswordActions,
    },
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
