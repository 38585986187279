//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { call }               from 'redux-saga/effects';
import { put }                from 'redux-saga/effects';
import { takeLatest }         from 'redux-saga/effects';
import * as Api               from '@/api';
import { CompanyTypeActions } from '@/store/actions/companyType';
import { CompanyTypesTypes }  from '@/store/actions/companyType';

function* fetchCompanyTypes() {
    const response = yield call(Api.fetchCompanyTypes);

    if (response.ok) {
        yield put(CompanyTypeActions.fetchCompanyTypesSucceeded({
            companyTypes: response.data,
        }));
    } else {
        yield put(CompanyTypeActions.fetchCompanyTypesFailed());
    }
}

export const callCompanyTypeSagas = () => {
    return [
        // @formatter:off
        takeLatest([CompanyTypesTypes.FETCH_COMPANY_TYPES], fetchCompanyTypes),
        // @formatter:on
    ];
};
