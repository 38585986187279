//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import ColorBoxColor     from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import { ColorBox }      from '@/components/stateless/atomic/ColorBox';
import I18n              from 'i18next';
import { HeadlineSmall } from '@/components/stateless/atomic/HeadlineSmall';
import PropTypes         from '@/components/PropTypes';
import { Link }          from '@/components/stateless/atomic/Link';

import { Icon }     from '@/components/stateless/atomic/Icon';
import IconType     from '@/components/stateless/atomic/Icon/IconType';
import UrlHelper    from '@/helper/Url';
import _            from 'lodash';
import { SvgColor } from '@/components/stateless/atomic/SvgColor';
import colors       from '@/styles/colors.module.scss';
import NumberFormat from '@/helper/NumberFormat';
import moment       from 'moment';
import styles       from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { presentation } = this.props;

        if (!presentation) {
            return null;
        }

        const absoluteUrl       = UrlHelper.getAbsoluteUrl(presentation.path);
        const presentationTitle = _.get(presentation, 'title', I18n.t('presentation'));
        const size              = _.get(presentation, 'size');
        const createdAt         = _.get(presentation, 'createdAt');
        const sizeHumanReadable = NumberFormat.toHumanReadableFileSize(size);
        const formattedDate     = moment(createdAt).format(I18n.t('dateFormat'));
        const fileInfo          = `${formattedDate} | ${sizeHumanReadable}`;

        return (
            <Link
                to={absoluteUrl}
                target={'_blank'}
                className={styles.presentationCTA}
            >
                <ColorBox color={ColorBoxColor.grayLight}>
                    <div className={styles.presentationCTAIcon}>
                        <Icon iconType={IconType.pdf} />
                    </div>
                    <div className={styles.presentationCTAText}>
                        <HeadlineSmall text={presentationTitle} />
                        <p>
                            {fileInfo}
                        </p>
                    </div>
                    <SvgColor color={colors.colorGrayHeather}>
                        <Icon iconType={IconType.download} />
                    </SvgColor>
                </ColorBox>
            </Link>
        );
    }
}

export const CallToActionPresentationBlock = Component;

Component.propTypes = {
    presentation: PropTypes.object,
};

Component.defaultProps = {
    presentation: null,
};

export default Component;
