//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import I18n                   from 'i18next';
import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import { TestIds }            from '@/constants/TestIds';
import ColorButtonTheme       from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import CallToActionBox        from '@/components/stateless/composed/CallToActionBox';
import ColorButton            from '@/components/stateless/atomic/ColorButton';
import Spacer                 from '@/components/stateless/atomic/Spacer';
import { ProductActions }     from '@/store/actions/product';

export class Component extends React.Component {
    onClickColorButton = () => {
        this.props.openEditProductOverlay();
    };

    render() {
        return (
            <>
                <Spacer height={30} />
                <CallToActionBox
                    title={I18n.t('addNewProductTitle')}
                    text={I18n.t('addNewProductText')}
                >
                    <ColorButton
                        test-id={TestIds.addNewProductButton}
                        onClick={this.onClickColorButton}
                        text={I18n.t('addNewProduct')}
                        theme={ColorButtonTheme.orange}
                    />
                </CallToActionBox>
                <Spacer height={30} />
            </>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AddNewProductBox = Component;

Component.propTypes = {
    openEditProductOverlay: PropTypes.func,
};

Component.defaultProps = {
    openEditProductOverlay: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...ProductActions,
    },
    dispatch,
);

export default compose(connect(
    null,
    mapDispatchToProps,
))(Component);
