//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import I18n             from 'i18next';
import ComponentHelper  from '@/helper/ComponentHelper';
import { Spacer }       from '@/components/stateless/atomic/Spacer';
import ProfileMenu      from '@/renderer/ProfileMenu';
import SideMenuHeadline from '@/components/stateless/composed/SideMenuHeadline';
import SideMenu         from '@/components/stateless/composed/SideMenu';
import PropTypes        from '@/components/PropTypes';
import _                from 'lodash';

export class Component extends React.Component {
    getHeadline = () => {
        if (this.props.isOnOwnProfile) {
            return I18n.t('myProfile');
        }

        return I18n.t('subMenu');
    };

    redirectToProjects = () => {
        this.props.openMyProfileProjects();
    };

    redirectToMessages = () => {
        this.props.openMyProfileMessages();
    };

    redirectToFavorites = () => {
        this.props.openFavorites();
    };

    renderOwnProfileMenu = () => {
        if (!this.props.isOnOwnProfile) {
            return null;
        }

        const { props } = this;
        const { route } = props;

        return (
            <>
                <Spacer height={20} />
                {ProfileMenu.renderMyProfileButton(props, route, true)}
                {ProfileMenu.renderMessagesButton(props, route, true, this.redirectToMessages)}
                {/* TODO: https://lulububu.atlassian.net/browse/SUPPLYDU-763 */}
                {/* {ProfileMenu.renderProjectsButton(props, route, true, this.redirectToProjects)} */}
                {ProfileMenu.renderFavoritesButton(props, route, true, this.redirectToFavorites)}
                {ProfileMenu.renderMyCompanyButton(props, route, true)}
                {ProfileMenu.renderCurrentRoleButton(props, route, true)}
                {ProfileMenu.renderUserVerification(props, route, true)}
            </>
        );
    };

    render() {
        return (
            <SideMenu>
                <SideMenuHeadline
                    text={this.getHeadline()}
                />
                {this.renderOwnProfileMenu()}
            </SideMenu>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ProfileSideMenu = Component;

Component.propTypes = {
    isOnOwnProfile:        PropTypes.bool,
    openFavorites:         PropTypes.func,
    openMyProfileMessages: PropTypes.func,
    openMyProfileProjects: PropTypes.func,
    route:                 PropTypes.string,
};

Component.defaultProps = {
    isOnOwnProfile:        false,
    openFavorites:         _.noop,
    openMyProfileMessages: _.noop,
    openMyProfileProjects: _.noop,
    route:                 null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
