//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import _                    from 'lodash';
import I18n                 from 'i18next';
import moment               from 'moment';
import ColorBox             from '@/components/stateless/atomic/ColorBox';
import Company              from '@/helper/Company';
import ComponentHelper      from '@/helper/ComponentHelper';
import CountryFlag          from '@/components/stateless/atomic/CountryFlag';
import DropDownInput        from '@/components/stateless/atomic/DropDownInput';
import FormRow              from '@/components/stateless/composed/FormRow';
import HeadlineMedium       from '@/components/stateless/atomic/HeadlineMedium';
import HeadlineSmall        from '@/components/stateless/atomic/HeadlineSmall';
import Icon                 from '@/components/stateless/atomic/Icon';
import IconType             from '@/components/stateless/atomic/Icon/IconType';
import ImageUpload          from '@/components/stateless/atomic/ImageUpload';
import Overlay              from '@/components/connected/Overlay';
import Overlays             from '@/constants/Overlays';
import OverlayType          from '@/components/connected/Overlay/OverlayType';
import PropTypes            from '@/components/PropTypes';
import TextInput            from '@/components/stateless/atomic/TextInput';
import TextInputSize        from '@/components/stateless/atomic/TextInput/TextInputSize';
import { LoadingIndicator } from '@/components/stateless/atomic/LoadingIndicator';
import TagDropDown          from '@/components/connected/TagDropDown';
import TagTypeDropDown      from '@/constants/TagTypeDropDown';
import CompanyTypes         from '@/constants/CompanyTypes';
import CountryListHelper    from '@/helper/CountryListHelper';
import ColorButton          from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme     from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import { Collapsable }      from '@/components/stateless/composed/Collapsable';

import TagSearchEditor     from '@/components/connected/TagSearchEditor';
import TagEditorContext    from '@/constants/TagEditorContext';
import AttachmentUpload    from '@/components/stateless/composed/AttachmentUpload';
import MemberStatusWarning from '@/components/connected/MemberStatusWarning';
import AllowedFileType     from '@/constants/AllowedFileType';
import styles              from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addTagsTo: null,
        };
    }

    resetTagSearch = () => {
        this.switchTagToAdd(null);
    };

    switchTagToAdd = (addTagsTo) => {
        this.props.resetTagQuery();
        this.setState({
            addTagsTo,
        });
    };

    getCompanyTypeOptions = () => {
        const companyTypeOptions          = [];
        const companyTypes                = this.props.companyTypes;
        const currentTypeOptions          = this.getCurrentCompanyTypeOption();
        const containsMachineManufacturer = currentTypeOptions.some((option) => {
            return option.value === CompanyTypes.machineManufacturer;
        });
        const containsSupplier            = currentTypeOptions.some((option) => {
            return option.value === CompanyTypes.supplier;
        });

        companyTypes.forEach((companyType) => {
            if (
                (
                    companyType === CompanyTypes.supplier &&
                    containsMachineManufacturer
                ) ||
                (
                    companyType === CompanyTypes.machineManufacturer &&
                    containsSupplier
                )
            ) {
                return;
            }
            companyTypeOptions.push({
                value: companyType,
                label: Company.getCompanyTypeLabel(companyType),
            });
        });

        return companyTypeOptions;
    };

    getCurrentCompanyTypeOption = () => {
        const selectedOptions = [];

        this.props.companyCompanyTypes.forEach((companyCompanyType) => {
            selectedOptions.push({
                value: companyCompanyType,
                label: Company.getCompanyTypeLabel(companyCompanyType),
            });
        });

        return selectedOptions;
    };

    onCompanyTagsAdd = (onTagSearchResultClicked, tagResult) => () => {
        onTagSearchResultClicked(tagResult);
        this.setState({
            addTagsTo: null,
        });
    };

    render() {
        return (
            <Overlay
                id={Overlays.editCompany}
                okButtonPressed={this.props.okButtonPressed}
                okButtonValidator={this.props.okButtonValidator}
                okButtonText={I18n.t('save')}
                overlayType={OverlayType.full}
                disableCloseOnOutsideClick={true}
                history={this.props.history}
                dataChanged={this.props.dataChanged}
            >
                {this.renderHeader()}
                {this.renderBody()}
            </Overlay>
        );
    }

    renderBody = () => {
        return (
            <div className={styles.editCompanyBodyContainer}>
                {this.renderDescription()}
                {this.renderVideoAndPresentation()}
                {this.renderIndustries()}
                {this.renderTags()}
                {this.renderAddress()}
                {this.renderDangerZone()}
            </div>
        );
    };

    renderAddress = () => {
        return (
            <>
                <HeadlineSmall text={I18n.t('address')} />
                <ColorBox>
                    <div className={styles.editCompanyBodyAddressFormRow}>
                        <FormRow label={I18n.t('street')}>
                            <TextInput
                                onChange={this.props.onCompanyAddressStreetChanged}
                                value={_.get(this.props, 'companyAddress.street')}
                            />
                        </FormRow>
                        <FormRow label={I18n.t('houseNumber')}>
                            <TextInput
                                onChange={this.props.onCompanyAddressHouseNumberChanged}
                                value={_.get(this.props, 'companyAddress.houseNumber')}
                            />
                        </FormRow>
                    </div>
                    <div className={styles.editCompanyBodyAddressFormRow}>
                        <FormRow label={I18n.t('country')}>
                            <DropDownInput
                                options={CountryListHelper.getOptions()}
                                onChange={this.props.onCompanyAddressCountryChanged}
                                defaultValue={CountryListHelper.getCurrentCompanyCountryOption(this.props.companyAddress)}
                                renderValue={this.renderCompanyCountryOption}
                            />
                        </FormRow>
                        <FormRow label={I18n.t('city')}>
                            <TextInput
                                onChange={this.props.onCompanyAddressCityChanged}
                                value={_.get(this.props, 'companyAddress.city')}
                            />
                        </FormRow>
                    </div>
                    <div className={styles.editCompanyBodyAddressFormRow}>
                        <FormRow label={I18n.t('state')}>
                            <TextInput
                                onChange={this.props.onCompanyAddressStateChanged}
                                value={_.get(this.props, 'companyAddress.state')}
                            />
                        </FormRow>
                        <FormRow label={I18n.t('postalCode')}>
                            <TextInput
                                onChange={this.props.onCompanyAddressPostalCodeChanged}
                                value={_.get(this.props, 'companyAddress.postalCode')}
                            />
                        </FormRow>
                    </div>
                    <div className={styles.editCompanyBodyAddressFormRow}>
                        <FormRow label={I18n.t('phoneNumber')}>
                            <TextInput
                                onChange={this.props.onCompanyContactPhoneChanged}
                                value={_.get(this.props, 'companyContact.phone')}
                            />
                        </FormRow>
                    </div>
                </ColorBox>
            </>
        );
    };

    renderDescription = () => {
        return (
            <>
                <HeadlineSmall text={I18n.t('description')} />
                <TextInput
                    size={TextInputSize.multilineExtraLarge}
                    placeholder={I18n.t('companyDescriptionPlaceholder')}
                    value={this.props.companyDescription}
                    onChange={this.props.onCompanyDescriptionChanged}
                />
            </>
        );
    };

    updateVideo = (video) => {
        this.props.onCompanyVideoChanged(video);
    };

    onVideoFileChanged = (files) => {
        const videoFile = _.last(files);

        if (videoFile) {
            this.updateVideo(videoFile);
        }
    };

    onVideoFileDeleted = () => {
        this.updateVideo(null);
    };

    onVideoTitleChanged = (video) => (title) => {
        this.updateVideo({
            ...video,
            title,
        });
    };

    updatePresentation = (video) => {
        this.props.onCompanyPresentationChanged(video);
    };

    onPresentationFileChanged = (files) => {
        const presentationFile = _.last(files);

        if (presentationFile) {
            this.updatePresentation(presentationFile);
        }
    };

    onPresentationFileDeleted = () => {
        this.updatePresentation(null);
    };

    onPresentationTitleChanged = (presentation) => (title) => {
        this.updatePresentation({
            ...presentation,
            title,
        });
    };

    renderVideoAndPresentation = () => {
        const { companyVideo, companyPresentation } = this.props;

        return (
            <>
                <MemberStatusWarning
                    text={I18n.t('companyUploadVideoAndPresentationWarning')}
                />
                <HeadlineSmall text={I18n.t('companyUploadPresentation')} />
                <AttachmentUpload
                    files={_.compact([companyPresentation])}
                    title={_.get(companyPresentation, 'title')}
                    renderTitleInput={true}
                    onFilesChanged={this.onPresentationFileChanged}
                    onFileDeleted={this.onPresentationFileDeleted}
                    onTitleChanged={this.onPresentationTitleChanged(companyPresentation)}
                    iconType={IconType.upload}
                    accept={AllowedFileType.presentation}
                />
                <HeadlineSmall text={I18n.t('companyUploadVideo')} />
                <AttachmentUpload
                    files={_.compact([companyVideo])}
                    title={_.get(companyVideo, 'title')}
                    renderTitleInput={true}
                    onFilesChanged={this.onVideoFileChanged}
                    onFileDeleted={this.onVideoFileDeleted}
                    onTitleChanged={this.onVideoTitleChanged(companyVideo)}
                    iconType={IconType.upload}
                    accept={AllowedFileType.video}
                />
            </>
        );
    };

    renderIndustries = () => {
        return (
            <>
                <HeadlineSmall text={I18n.t('industries')} />
                <TagDropDown
                    tagType={TagTypeDropDown.industry}
                    value={this.props.companyIndustries}
                    valueChanged={this.props.onCompanyIndustryChanged}
                    renderBelow={true}
                    multiple={true}
                    placeholder={I18n.t('industryPlaceholder')}
                />
            </>
        );
    };

    renderHeader = () => {
        return (
            <div className={styles.editCompanyHeaderContainer}>
                <ImageUpload
                    uploadFileCallback={this.props.onImageChanged}
                    deleteFileCallback={this.props.onImageChanged}
                    images={[this.props.companyLogo]}
                />
                <div className={styles.editCompanyHeaderWrapper}>
                    <div className={styles.editCompanyHeaderCompanyTypeWrapper}>
                        <HeadlineMedium text={this.props.companyName} />
                        <DropDownInput
                            options={this.getCompanyTypeOptions()}
                            onChange={this.props.onCompanyTypeChanged}
                            defaultValue={this.getCurrentCompanyTypeOption()}
                            multiple={true}
                            renderBelow={true}
                            renderBelowTitle={I18n.t('types')}
                            placeholder={I18n.t('companyTypePlaceholder')}
                        />
                    </div>
                    <div className={styles.editCompanyHeaderCountryWrapper}>
                        <HeadlineSmall text={I18n.t('companyName')} />
                        <TextInput
                            onChange={this.props.onCompanyNameChanged}
                            value={this.props.companyName}
                        />
                    </div>
                    <div className={styles.editCompanyHeaderInfoText}>
                        {
                            I18n.t(
                                'lastUpdate',
                                {
                                    updateString: moment(this.props.companyLastUpdate).fromNow(),
                                },
                            )
                        }
                    </div>
                </div>
            </div>
        );
    };

    renderCompanyCountryOption = (name, option) => {
        return (
            <div className={styles.editCompanyLanguageOption}>
                <CountryFlag
                    width={16}
                    iso31661Alpha2CountryCode={option.value}
                />
                {name}
            </div>
        );
    };

    renderCompetenceTags = () => {
        return (
            <TagSearchEditor
                title={I18n.t('competences')}
                tagSelectorPlaceholder={I18n.t('competencesSelectorPlaceholder')}
                tagContext={TagEditorContext.editCompanyCompetences}
            />
        );
    };

    renderTags = () => {
        return (
            <>
                {this.renderCompetenceTags()}
            </>
        );
    };

    renderSearchIcon = () => {
        if (this.props.isLoading) {
            return (
                <LoadingIndicator />
            );
        }

        return (
            <Icon iconType={IconType.search} />
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }

    onCompanyDeleteClicked = () => {
        this.props.onCompanyDelete();
    };

    renderDangerZone() {
        return (
            <div className={styles.editCompanyDangerZone}>
                <Collapsable
                    title={I18n.t('dangerZone')}
                    closed={true}
                >
                    <div className={styles.editCompanyDangerZoneContent}>
                        <ColorButton
                            theme={ColorButtonTheme.orange}
                            onClick={this.onCompanyDeleteClicked}
                            text={I18n.t('deleteCompany')}
                        />
                    </div>
                </Collapsable>
            </div>
        );
    }
}

export const EditCompanyOverlay = Component;

Component.propTypes = {
    companyAddress:                     PropTypes.object,
    companyCompanyTypes:                PropTypes.array,
    companyCompetenceTags:              PropTypes.array,
    companyContact:                     PropTypes.object,
    companyDescription:                 PropTypes.string,
    companyIndustries:                  PropTypes.array,
    companyLastUpdate:                  PropTypes.string,
    companyLogo:                        PropTypes.object,
    companyName:                        PropTypes.string,
    companyPresentation:                PropTypes.object,
    companyTypes:                       PropTypes.array,
    companyVideo:                       PropTypes.object,
    competencesResultList:              PropTypes.array,
    dataChanged:                        PropTypes.bool,
    history:                            PropTypes.object,
    isLoading:                          PropTypes.bool,
    okButtonPressed:                    PropTypes.func,
    okButtonValidator:                  PropTypes.func,
    onCompanyAddressCityChanged:        PropTypes.func,
    onCompanyAddressCountryChanged:     PropTypes.func,
    onCompanyAddressHouseNumberChanged: PropTypes.func,
    onCompanyAddressPostalCodeChanged:  PropTypes.func,
    onCompanyAddressStateChanged:       PropTypes.func,
    onCompanyAddressStreetChanged:      PropTypes.func,
    onCompanyCompetenceTagsAdd:         PropTypes.func,
    onCompanyCompetenceTagsDelete:      PropTypes.func,
    onCompanyContactPhoneChanged:       PropTypes.func,
    onCompanyDelete:                    PropTypes.func,
    onCompanyDescriptionChanged:        PropTypes.func,
    onCompanyIndustryChanged:           PropTypes.func,
    onCompanyNameChanged:               PropTypes.func,
    onCompanyPresentationChanged:       PropTypes.func,
    onCompanyTypeChanged:               PropTypes.func,
    onCompanyVideoChanged:              PropTypes.func,
    onImageChanged:                     PropTypes.func,
    onTagSearch:                        PropTypes.func,
    resetTagQuery:                      PropTypes.func,
    tagSearchQuery:                     PropTypes.string,
};

Component.defaultProps = {
    companyAddress:                     null,
    companyCompanyTypes:                [],
    companyCompetenceTags:              [],
    companyContact:                     null,
    companyDescription:                 null,
    companyIndustries:                  [],
    companyLastUpdate:                  null,
    companyLogo:                        null,
    companyName:                        null,
    companyPresentation:                null,
    companyTypes:                       [],
    companyVideo:                       null,
    competencesResultList:              [],
    dataChanged:                        false,
    history:                            null,
    isLoading:                          false,
    okButtonPressed:                    null,
    okButtonValidator:                  null,
    onCompanyAddressCityChanged:        null,
    onCompanyAddressCountryChanged:     null,
    onCompanyAddressHouseNumberChanged: null,
    onCompanyAddressPostalCodeChanged:  null,
    onCompanyAddressStateChanged:       null,
    onCompanyAddressStreetChanged:      null,
    onCompanyCompetenceTagsAdd:         null,
    onCompanyCompetenceTagsDelete:      null,
    onCompanyContactPhoneChanged:       null,
    onCompanyDelete:                    null,
    onCompanyDescriptionChanged:        null,
    onCompanyIndustryChanged:           _.noop,
    onCompanyNameChanged:               null,
    onCompanyPresentationChanged:       null,
    onCompanyTypeChanged:               null,
    onCompanyVideoChanged:              null,
    onImageChanged:                     null,
    onTagSearch:                        null,
    resetTagQuery:                      null,
    tagSearchQuery:                     null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'addTagsTo',
];

export default Component;
