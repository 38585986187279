//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import { Component }          from 'react';
import I18n                   from 'i18next';
import { connect }            from 'react-redux';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';
import ComponentHelper        from '@/helper/ComponentHelper';
import SignupForm             from '@/components/stateless/composed/SignupForm';
import StartPageWithBox       from '@/components/stateless/composed/StartPageWithBox';
import PropTypes              from '@/components/PropTypes';
import { SignupActions }      from '@/store/actions/signup';

class Screen extends Component {
    render() {
        const props = this.props;

        return (
            <StartPageWithBox
                pageTitle={I18n.t('signUp')}
                headline={I18n.t('signUp')}
            >
                <SignupForm
                    updateSignupField={props.updateSignupField}
                    signupData={props.signupData}
                    signup={props.signup}
                />
            </StartPageWithBox>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    signup:            PropTypes.func,
    signupData:        PropTypes.object,
    updateSignupField: PropTypes.func,
};

Screen.defaultProps = {
    signup:            _.noop,
    signupData:        null,
    updateSignupField: _.noop,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        signupData: _.get(state, 'signup'),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...SignupActions,
    },
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
