//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ToastType                         from '@/components/stateless/atomic/ToastWrapper/ToastType';
import SelectionHelper                   from '@/helper/SelectionHelper';
import { ToastTypes as ToastTypesRedux } from '@/store/actions/alertBox';
import { delay }                         from 'redux-saga/effects';
import { Store }                         from 'react-notifications-component';
import { takeLatest }                    from 'redux-saga/effects';
import { ToastTypes }                    from '@/store/actions/alertBox';

function* showAlert(action) {
    if (!Store.add) {
        yield delay(500);
    }

    const lifeTime = Math.max(action.lifeCounter || 0, 1);
    const type     = SelectionHelper.get(
        action.type,
        {
            [ToastTypesRedux.SHOW_ERROR]:   ToastType.danger,
            [ToastTypesRedux.SHOW_SUCCESS]: ToastType.success,
        },
    );

    Store.addNotification({
        type,
        title:     action.title,
        message:   action.text,
        container: 'top-right',
        dismiss:   {
            duration: 5000 * lifeTime,
            onScreen: true,
        },
    });
}

function* clearAlerts() {
    if (!Store.removeAllNotifications) {
        yield delay(500);
    }

    Store.removeAllNotifications();
}

export const callAlertSagas = () => {
    return [
        // @formatter:off
        takeLatest([ToastTypes.SHOW_SUCCESS], showAlert),
        takeLatest([ToastTypes.SHOW_ERROR],   showAlert),
        takeLatest([ToastTypes.CLEAR],        clearAlerts),
        // @formatter:on
    ];
};
