//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import _                             from 'lodash';
import PropTypes                     from '@/components/PropTypes';
import { getImagePathByImageObject } from '@/api';
import RequestMessageService         from '@/components/stateless/composed/RequestMessageService';
import TagList                       from '@/components/stateless/composed/TagList';
import ColorBoxColor                 from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import Spacer                        from '@/components/stateless/atomic/Spacer';
import ColorBox                      from '@/components/stateless/atomic/ColorBox';
import Collapsable                   from '@/components/stateless/composed/Collapsable';

export class Component extends React.Component {
    renderTagList = (service) => {
        const tagStrings = _.get(service, 'tagStrings');

        return (
            <TagList tags={tagStrings} />
        );
    };

    renderBody = (product) => {
        const props = this.props;
        const image = _.get(product, 'image');
        const title = _.get(
            product,
            'title',
            _.get(product, 'name'),
        );

        return (
            <RequestMessageService
                image={getImagePathByImageObject(image)}
                title={title}
                sectionTitle={props.sectionTitle}
                description={this.renderTagList(product)}
                showOnlyBody={props.collapsable}
            />
        );
    };

    renderCollapsedBody = (product) => {
        const props = this.props;

        if (!props.collapsable) {
            return this.renderBody(product);
        }

        return (
            <>
                <Spacer height={10} />
                <ColorBox color={ColorBoxColor.grayLight}>
                    <Collapsable
                        title={props.sectionTitle}
                        closed={props.collapsed}
                    >
                        {this.renderBody(product)}
                    </Collapsable>
                </ColorBox>
            </>
        );
    };

    render() {
        const { messageRequest, field } = this.props;
        const product                   = _.get(messageRequest, field);

        if (product) {
            return this.renderCollapsedBody(product);
        }

        return null;
    }
}

export const MessageRequestService = Component;

Component.propTypes = {
    collapsed:      PropTypes.bool,
    collapsable:    PropTypes.bool,
    field:          PropTypes.string,
    messageRequest: PropTypes.object,
    sectionTitle:   PropTypes.string,
};

Component.defaultProps = {
    collapsed:      false,
    collapsable:    false,
    field:          null,
    messageRequest: null,
    sectionTitle:   null,
};

export default Component;
