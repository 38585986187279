//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import classNames from 'classnames';
import Icon       from '@/components/stateless/atomic/Icon';
import IconType   from '@/components/stateless/atomic/Icon/IconType';
import PropTypes  from '@/components/PropTypes';
import styles     from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <span
                className={classNames(
                    styles.roundDropDownCaretDownWrapper,
                    {
                        [styles.roundDropDownCaretDownWrapperReduced]: this.props.isReduced,
                    },
                )}
            >
                {this.renderIcon()}
            </span>
        );
    }

    renderIcon = () => {
        let icon = IconType.plus;

        if (this.props.isReduced) {
            icon = IconType.pencil;
        }

        return (
            <Icon iconType={icon} />
        );
    };
}

export const RoundDropDownCaretDown = Component;

Component.propTypes = {
    isReduced: PropTypes.bool,
};

Component.defaultProps = {
    isReduced: false,
};

export default Component;
