//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import ProjectStatus   from '@/constants/ProjectStatus';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import { BadgeCount }  from '@/components/stateless/atomic/BadgeCount';
import { Flex }        from '@/components/stateless/atomic/Flex';

import 'react-datepicker/dist/react-datepicker.css';
import styles          from './styles.module.scss';

export class ListWithHeader extends React.Component {
    static propTypes = {
        headline: PropTypes.string,
        children: PropTypes.children,
        image:    PropTypes.string,
        name:     PropTypes.string,
        subtitle: PropTypes.string,
        count:    PropTypes.number,
    };

    static defaultProps = {
        headline: null,
        children: ProjectStatus.OPEN,
        image:    null,
        name:     null,
        subtitle: null,
        count:    null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    render() {
        return (
            <div className={styles.wrapper}>
                <h3 className={styles.headline}>
                    <Flex>
                        {this.props.headline}
                        <div className={styles.badgeCountContainer}>
                            <BadgeCount count={this.props.count} />
                        </div>
                    </Flex>
                </h3>
                <div className={styles.list}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default ListWithHeader;
