//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update           from 'immutability-helper';
import _                from 'lodash';
import { ProductTypes } from '@/store/actions/product';

const initialProduct = {
    title:    null,
    image:    null,
    category: null,
    tags:     [],
    tag:      null,
};

const initialState = {
    products:       [],
    ownProductEdit: initialProduct,
};

const editProductField = (action, state) => {
    const { field, value } = action;

    return update(state, {
        ownProductEdit: {
            [field]: {
                $set: value,
            },
        },
    });
};

const fetchProductsSucceeded = (action, state) => {
    const products = _.get(action, 'products');

    return update(state, {
        products: {
            $set: products,
        },
    });
};

const loadOwnProduct = (action, state) => {
    const ownProduct = _.get(action, 'ownProduct', null) ?? initialProduct;

    return update(state, {
        ownProductEdit: {
            $set: ownProduct,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case ProductTypes.EDIT_PRODUCT_FIELD:       return editProductField(action, state);
        case ProductTypes.FETCH_PRODUCTS_SUCCEEDED: return fetchProductsSucceeded(action, state);
        case ProductTypes.LOAD_OWN_PRODUCT:         return loadOwnProduct(action, state);
        default:                                    return state;
        // @formatter:on
    }
}
