//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                       from 'react';
import _                           from 'lodash';
import I18n                        from 'i18next';
import { bindActionCreators }      from 'redux';
import { compose }                 from 'redux';
import { connect }                 from 'react-redux';
import appStyles                   from '@/styles.module.scss';
import ComponentHelper             from '@/helper/ComponentHelper';
import IconType                    from '@/components/stateless/atomic/Icon/IconType';
import Ids                         from '@/constants/Ids';
import Navigation                  from '@/helper/Navigation';
import PropTypes                   from '@/components/PropTypes';
import Routes                      from '@/constants/Routes';
import State                       from '@/helper/State';
import { CompanyActions }          from '@/store/actions/company';
import ContentHeader               from '@/components/connected/ContentHeader';
import { MachineActions }          from '@/store/actions/machine';
import { IconTextButton }          from '@/components/stateless/atomic/IconTextButton';
import { Spacer }                  from '@/components/stateless/atomic/Spacer';
import IconButtonTheme             from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import IconTextButtonPosition      from '@/components/stateless/atomic/IconTextButton/IconTextButtonPosition';
import AddressLabel                from '@/components/stateless/composed/AddressLabel';
import { FactSheetBox }            from '@/components/stateless/composed/FactSheetBox';
import FactSheetFacts              from '@/components/stateless/composed/FactSheetFacts';
import FactSheetPieChart           from '@/components/stateless/composed/FactSheetPieChart';
import FactSheetBarChart           from '@/components/stateless/composed/FactSheetBarChart';
import FactSheetProducts           from '@/components/stateless/composed/FactSheetProducts';
import { CompanyFactSheetActions } from '@/store/actions/companyFactSheet';
import FactSheetEditType           from '@/constants/FactSheetEditType';
import { OverlayActions }          from '@/store/actions/ovleray';
import CompanySideMenu             from '@/components/connected/CompanySideMenu';
import { AlertBoxActions }         from '@/store/actions/alertBox';
import { BoldTranslation }         from '@/components/stateless/composed/BoldTranslation';
import withFetchCompany            from '@/components/hoc/FetchCompany';
import Route                       from '@/helper/Route';
import { NavigationActions }       from '@/store/actions/navigation';
import Revenue                     from '@/helper/Revenue';

import styles from './styles.module.scss';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        this.fetchCompany();
    }

    onBeforePrint = (event) => {
        event.preventDefault();
        this.printFactSheet();
    };

    printFactSheet = () => {
        this.props.openUrl({
            url: Route.buildPathByRoute(Route.buildRoute(Routes.companyFactSheetPrint, this.props.currentCompanyUrl)),
        });
    };

    componentDidMount() {
        window.addEventListener('beforeprint', this.onBeforePrint);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeprint', this.onBeforePrint);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCompanyId !== this.props.currentCompanyId) {
            this.fetchCompany();
        }
    }

    fetchCompany = () => {
        const id = this.props.currentCompanyId;

        this.props.fetchMachineByCompany({
            companyId: id,
        });
    };

    openEditOverlay = (editType) => {
        this.props.openEditOverlay({
            editType,
        });
    };

    editIndustryRatio = (editType) => () => {
        this.openEditOverlay(editType);
    };

    editDevelopmentRevenue = () => {
        this.openEditOverlay(FactSheetEditType.developmentRevenue);
    };

    editFacts = () => {
        this.openEditOverlay(FactSheetEditType.facts);
    };

    onEditProduct = (index) => {
        this.props.openEditOverlay({
            editType:     FactSheetEditType.products,
            productIndex: index,
        });
    };

    onAddProduct = () => {
        const { products, maximumFactSheetProductCount } = this.props;

        if (_.size(products) < maximumFactSheetProductCount) {
            this.props.openEditOverlay({
                editType:     FactSheetEditType.products,
                productIndex: -1,
            });
        } else {
            this.props.showErrorAlert({
                text: I18n.t(
                    'factSheetProductLimitExceeded',
                    {
                        amount: maximumFactSheetProductCount,
                    },
                ),
            });
        }
    };

    onDeleteProduct = (product, index) => {
        const editType = FactSheetEditType.products;
        const message  = (
            <BoldTranslation
                translationKey={'factSheetDeleteProductText'}
                values={{
                    productTitle: _.get(product, 'title'),
                }}
            />
        );

        this.props.openConfirmDialog({
            confirmAction:     [
                CompanyFactSheetActions.removeEntry({
                    index,
                    editType,
                }),
                CompanyFactSheetActions.updateFactSheet({
                    editType,
                }),
            ],
            title:             I18n.t('factSheetDeleteProductTitle'),
            message,
            confirmButtonText: I18n.t('delete'),
            cancelButtonText:  I18n.t('cancel'),
        });
    };

    render() {
        const props                     = this.props;
        const products                  = props.products;
        const companyAddress            = props.companyAddress;
        const facts                     = props.facts;
        const mappedRevenueDistribution = Revenue.mapIndustryRatios(props.revenueDistribution);
        const mappedExportRatio         = Revenue.mapIndustryRatios(props.exportRatio);
        const developmentRevenue        = props.developmentRevenue;
        const {
            label: developmentRevenueLabel,
            data:  developmentRevenueData,
        }                         = Revenue.getLabelAndData(developmentRevenue);

        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <CompanySideMenu
                        route={Routes.companyFactSheet}
                        {...props}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        <ContentHeader
                            currentCompanyUrl={props.currentCompanyUrl}
                            currentCompanyId={props.currentCompanyId}
                            rightContent={this.renderShareAndPrintButton()}
                        />
                        <div className={styles.companyFactSheetContainer}>
                            <div className={styles.companyFactSheetContent}>
                                <AddressLabel
                                    city={companyAddress?.city}
                                    country={companyAddress?.country}
                                    houseNumber={companyAddress?.houseNumber}
                                    postalCode={companyAddress?.postalCode}
                                    street={companyAddress?.street}
                                    iso31661Alpha2CountryCode={companyAddress?.countryCode}
                                />
                                <Spacer height={20} />
                                <FactSheetProducts
                                    products={products}
                                    currentCompanyId={props.currentCompanyId}
                                    onEditProduct={this.onEditProduct}
                                    onAddProduct={this.onAddProduct}
                                    onDeleteProduct={this.onDeleteProduct}
                                    isAdmin={props.isAdmin}
                                    maxProductAmount={props.maximumFactSheetProductCount}
                                />
                                <div className={styles.companyFactSheetRow}>
                                    <FactSheetBox
                                        headline={I18n.t('revenueDistribution')}
                                        editButtonPressed={this.editIndustryRatio(FactSheetEditType.revenueDistribution)}
                                        currentCompanyId={props.currentCompanyId}
                                    >
                                        <FactSheetPieChart
                                            data={mappedRevenueDistribution}
                                        />
                                    </FactSheetBox>
                                    <FactSheetBox
                                        headline={I18n.t('exportRatio')}
                                        editButtonPressed={this.editIndustryRatio(FactSheetEditType.exportRatio)}
                                        currentCompanyId={props.currentCompanyId}
                                    >
                                        <FactSheetPieChart
                                            data={mappedExportRatio}
                                        />
                                    </FactSheetBox>
                                </div>
                                <FactSheetBox
                                    headline={I18n.t('developmentRevenue')}
                                    editButtonPressed={this.editDevelopmentRevenue}
                                    currentCompanyId={props.currentCompanyId}
                                >
                                    <FactSheetBarChart
                                        data={developmentRevenueData}
                                        labels={developmentRevenueLabel}
                                    />
                                </FactSheetBox>
                                <FactSheetBox
                                    headline={I18n.t('facts')}
                                    editButtonPressed={this.editFacts}
                                    currentCompanyId={props.currentCompanyId}
                                >
                                    <FactSheetFacts
                                        facts={facts}
                                    />
                                </FactSheetBox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderShareAndPrintButton = () => {
        /* TODO: https://lulububu.atlassian.net/browse/SUPPLYDUAPP-869
            <IconButton
                to={'#'}
                iconType={IconType.share}
                theme={IconButtonTheme.grayBorder}
                disabled={true}
            />
                <FavoriseCompanyButton company={this.props.currentCompanyId} />
        */
        return (
            <IconTextButton
                onClick={this.printFactSheet}
                iconType={IconType.print}
                theme={IconButtonTheme.grayBorder}
                text={I18n.t('factSheet')}
                iconPosition={IconTextButtonPosition.left}
                hideMobile={true}
            />
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    companyAddress:               PropTypes.object,
    companyName:                  PropTypes.string,
    companyTypes:                 PropTypes.array,
    currentCompanyId:             PropTypes.string,
    currentCompanyUrl:            PropTypes.string,
    developmentRevenue:           PropTypes.array,
    exportRatio:                  PropTypes.array,
    facts:                        PropTypes.object,
    fetchCompany:                 PropTypes.func,
    fetchMachineByCompany:        PropTypes.func,
    isAdmin:                      PropTypes.bool,
    maximumFactSheetProductCount: PropTypes.number,
    openConfirmDialog:            PropTypes.func,
    openEditOverlay:              PropTypes.func,
    openUrl:                      PropTypes.func,
    products:                     PropTypes.array,
    revenueDistribution:          PropTypes.array,
    setCurrentCompany:            PropTypes.func,
    showErrorAlert:               PropTypes.func,
};

Screen.defaultProps = {
    companyAddress:               {},
    companyName:                  null,
    companyTypes:                 [],
    currentCompanyId:             null,
    currentCompanyUrl:            null,
    developmentRevenue:           [],
    exportRatio:                  [],
    facts:                        null,
    fetchCompany:                 _.noop,
    fetchMachineByCompany:        _.noop,
    isAdmin:                      _.noop,
    maximumFactSheetProductCount: 4,
    openConfirmDialog:            _.noop,
    openEditOverlay:              _.noop,
    openUrl:                      _.noop,
    products:                     [],
    revenueDistribution:          [],
    setCurrentCompany:            _.noop,
    showErrorAlert:               _.noop,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyActions,
        ...MachineActions,
        ...CompanyFactSheetActions,
        ...OverlayActions,
        ...AlertBoxActions,
        ...NavigationActions,
    },
    dispatch,
);

const mapStateToProps = (state, ownProps) => {
    const companyUrl  = Navigation.getParameterFromUrl(ownProps, 'companyId');
    const companyId   = Navigation.getCompanyIdFromUrl(ownProps);
    const isAdmin     = State.getUserCompanyId(state) === companyId;
    const userCompany = State.getUserCompany(state);

    return {
        companyAddress:               State.getCompanyValue(state, companyId, 'address'),
        companyName:                  State.getCompanyValue(state, companyId, 'name'),
        companyTypes:                 State.getCompanyValue(state, companyId, 'types', []),
        revenueDistribution:          State.getCompanyValue(state, companyId, 'factSheet.revenueDistribution', []),
        exportRatio:                  State.getCompanyValue(state, companyId, 'factSheet.exportRatio', []),
        facts:                        State.getCompanyValue(state, companyId, 'factSheet.facts'),
        products:                     State.getCompanyValue(state, companyId, 'factSheet.products'),
        developmentRevenue:           State.getCompanyValue(state, companyId, 'factSheet.developmentRevenue', []),
        maximumFactSheetProductCount: _.get(userCompany, 'maximumFactSheetProductCount', 5),
        currentCompanyId:             companyId,
        currentCompanyUrl:            companyUrl,
        isAdmin,
    };
};

const Composed = compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);

export default withFetchCompany(Composed);
