//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                       from 'react';
import ColorBox                    from '@/components/stateless/atomic/ColorBox';
import MachineParameterBoxHeadline from '@/components/stateless/composed/MachineParameterBoxHeadline';
import PropTypes                   from '@/components/PropTypes';
import ColorBoxColor               from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import styles                      from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.machineParameterBoxWrapper}>
                <ColorBox color={ColorBoxColor.grayLight}>
                    <MachineParameterBoxHeadline
                        text={'Parameters'}
                    />
                    <dl className={styles.machineParameterBoxList}>
                        {this.props.children}
                    </dl>
                </ColorBox>
            </div>
        );
    }
}

export const MachineParameterBox = Component;

Component.propTypes = {
    children: PropTypes.machineParameter,
};

Component.defaultProps = {
    children: [],
};

export default Component;
