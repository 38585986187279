//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { REHYDRATE }   from 'redux-persist';
import { Api }         from '@/api';
import { UserActions } from '@/store/actions/user';

function apiMiddleware() {
    return ({ dispatch }) => (next) => (action) => {
        if (
            action.key === 'root' &&
            action.type === REHYDRATE
        ) {
            const checkForInvalidTokenMonitor = (response) => {
                if (response.status === 401) {
                    dispatch(UserActions.logout());
                }
            };

            Api.addMonitor(checkForInvalidTokenMonitor);
        }

        return next(action);
    };
}

export default apiMiddleware;
