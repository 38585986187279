//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React               from 'react';
import I18n                from 'i18next';
import ComponentHelper     from '@/helper/ComponentHelper';
import { Spacer }          from '@/components/stateless/atomic/Spacer';
import CompanyMenu         from '@/renderer/CompanyMenu';
import SideMenuHeadline    from '@/components/stateless/composed/SideMenuHeadline';
import SideMenu            from '@/components/stateless/composed/SideMenu';
import PropTypes           from '@/components/PropTypes';
import Routes              from '@/constants/Routes';
import SideMenuButtonSmall from '@/components/stateless/atomic/SideMenuButtonSmall';
import IconType            from '@/components/stateless/atomic/Icon/IconType';
import Url                 from '@/helper/Url';
import _                   from 'lodash';
import MailAdresses        from '@/constants/MailAdresses';

export class Component extends React.Component {
    isOwnCompany = () => {
        return this.props.isOnOwnCompany;
    };

    getHeadline = () => {
        if (this.isOwnCompany()) {
            return I18n.t('myCompany');
        }

        return I18n.t('subMenu');
    };

    onCertificateDownloadClicked = () => {
        Url.openInNewTab(Url.getBackendUrl(this.props.verifiedCertificateURL));
    };

    onReportProfileClicked = () => {
        const { companyName, companyId } = this.props;
        const frontendUrl                = window.location.origin;

        Url.openMailTo(
            MailAdresses.reportProfile,
            I18n.t('reportProfileSubject', {
                companyName,
            }),
            encodeURIComponent(I18n.t('reportProfileBody', {
                companyName,
                frontendUrl,
                companyId,
            })),
        );
    };

    redirectToProjects = () => {
        this.props.openProjects({
            companyId: this.props.companyId,
        });
    };

    renderOwnCompanyMenu = () => {
        if (!this.isOwnCompany()) {
            return null;
        }

        return (
            <>
                <Spacer height={20} />
                {CompanyMenu.renderUserManagementButton(this.props, this.props.route)}
                {/* TODO: https://lulububu.atlassian.net/browse/SUPPLYDU-763 */}
                {/* {CompanyMenu.renderProjectsButton(this.props, this.props.route, true, this.redirectToProjects)} */}
                {CompanyMenu.renderMessagesButton(this.props, this.props.route, true)}
                <Spacer height={30} />
                <SideMenuHeadline
                    text={I18n.t('profile')}
                />
            </>
        );
    };

    renderSideMenu = () => {
        const { route }      = this.props;
        const myCompanyRoute = Routes.myCompany;

        if (route === myCompanyRoute) {
            return CompanyMenu.renderJoinCompanyButton(myCompanyRoute);
        }

        return CompanyMenu.renderSideMenuButtons(this.props, this.props.route);
    };

    renderCertificateDownload = () => {
        if (this.props.verifiedCertificateURL) {
            return (
                <SideMenuButtonSmall
                    title={I18n.t('downloadCertificate')}
                    onClick={this.onCertificateDownloadClicked}
                    icon={IconType.verified}
                />
            );
        }

        return null;
    };

    renderReportProfile = () => {
        if (!this.isOwnCompany()) {
            return (
                <>
                    <SideMenuButtonSmall
                        title={I18n.t('reportProfile')}
                        onClick={this.onReportProfileClicked}
                    />
                </>
            );
        }

        return null;
    };

    renderBottomSideMenu = () => {
        return (
            <>
                <Spacer height={20} />
                {this.renderCertificateDownload()}
                {this.renderReportProfile()}
            </>
        );
    };

    render() {
        return (
            <SideMenu>
                <SideMenuHeadline
                    text={this.getHeadline()}
                />
                {this.renderOwnCompanyMenu()}
                <Spacer height={20} />
                {this.renderSideMenu()}
                {this.renderBottomSideMenu()}
            </SideMenu>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const CompanySideMenu = Component;

Component.propTypes = {
    companyId:              PropTypes.string,
    companyName:            PropTypes.string,
    isOnOwnCompany:         PropTypes.bool,
    openProjects:           PropTypes.func,
    route:                  PropTypes.string,
    verifiedCertificateURL: PropTypes.string,
};

Component.defaultProps = {
    companyId:              null,
    companyName:            null,
    isOnOwnCompany:         false,
    openProjects:           _.noop,
    route:                  null,
    verifiedCertificateURL: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
