//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import CountryFlag     from '@/components/stateless/atomic/CountryFlag';
import DropDownInput   from '@/components/stateless/atomic/DropDownInput';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <DropDownInput
                options={this.props.options}
                renderValue={this.renderValue}
            />
        );
    }

    renderValue = (label, option) => {
        return (
            <>
                <div className={styles.countryFlagWrapper}>
                    <CountryFlag iso31661Alpha2CountryCode={option.iso31661Alpha2CountryCode} />
                </div>
                {label}
            </>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const CountryDropDownInput = Component;

Component.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })),
};

Component.defaultProps = {
    options: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
