//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import { connect }            from 'react-redux';
import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import StatelessBreadCrumbBar from '@/components/stateless/atomic/BreadCrumbBar';
import Route                  from '@/helper/Route';

export class Component extends React.Component {
    render() {
        if (!this.props.isFactSheetPrintRoute) {
            return (
                <StatelessBreadCrumbBar
                    hide={this.props.hide}
                    pathname={this.props.pathname}
                    currentCompany={this.props.currentCompany}
                    searchResultCount={this.props.searchResultCount}
                />
            );
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const BreadCrumbBar = Component;

Component.propTypes = {
    currentCompany:        PropTypes.object,
    hide:                  PropTypes.bool,
    isFactSheetPrintRoute: PropTypes.bool,
    pathname:              PropTypes.string,
    searchResultCount:     PropTypes.number,
};

Component.defaultProps = {
    currentCompany:        null,
    hide:                  false,
    isFactSheetPrintRoute: false,
    pathname:              null,
    searchResultCount:     0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => {
    const currentCompanyId  = _.get(state, 'company.currentCompanyId');
    const pathname          = _.get(state, 'router.location.pathname');
    const searchItems       = _.get(state, 'search.items');
    const searchResultCount = _.flatten(Object.values(searchItems)).length;

    return {
        currentCompany:        _.get(state, ['company', 'companies', currentCompanyId]),
        isFactSheetPrintRoute: Route.isFactsheetPrintRoute(pathname),
        pathname,
        searchResultCount,
    };
};

export default connect(
    mapStateToProps,
    null,
)(Component);
