//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update           from 'immutability-helper';
import { TagTypes }     from '@/store/actions/tag';
import TagEditorContext from '@/constants/TagEditorContext';
import _                from 'lodash';
import Tag              from '@/helper/Tag';
import ReducerHelper    from '@/helper/ReducerHelper';
import { REHYDRATE }    from 'redux-persist';

const initialState = {
    isLoading: null,
    tagQuery:  null,
    tags:      [],
    rootTags:  [],
    dropDown:  [],
    editor:    {
        [TagEditorContext.newProduct]:             {
            tagHierarchy: [],
        },
        [TagEditorContext.newEditMachine]:         {
            tagHierarchy: [],
        },
        [TagEditorContext.myProfileInterests]:     {
            tagHierarchy: [],
        },
        [TagEditorContext.editCompanyInterests]:   {
            tagHierarchy: [],
        },
        [TagEditorContext.editCompanyCompetences]: {
            tagHierarchy: [],
        },
    },
};

const fetchTags = (action, state) => {
    return update(state, {
        isLoading: {
            $set: true,
        },
        tagQuery:  {
            $set: action.title,
        },
    });
};

const fetchTagsFailed = (action, state) => {
    return update(state, {
        isLoading: {
            $set: false,
        },
    });
};

const fetchTagsSucceeded = (action, state) => {
    return update(state, {
        isLoading: {
            $set: false,
        },
        tags:      {
            $set: action.tags,
        },
    });
};

const fetchTagsForDropdownSucceeded = (action, state) => {
    const newDropDown = {
        ...state.dropDown,
        [action.tagType]: action.tags,
    };

    return update(state, {
        dropDown: {
            $set: newDropDown,
        },
    });
};

const fetchRootTags = (action, state) => {
    return update(state, {
        isLoading: {
            $set: true,
        },
    });
};

const fetchRootTagsSucceeded = (action, state) => {
    return update(state, {
        isLoading: {
            $set: false,
        },
        rootTags:  {
            $set: action.tags,
        },
    });
};

const fetchRootTagsFailed = (action, state) => {
    return update(state, {
        isLoading: {
            $set: false,
        },
    });
};

const reset = (action, state) => {
    const dropDown = _.get(state, 'dropDown');
    const rootTags = _.get(state, 'rootTags');
    const newState = {
        ...initialState,
        dropDown,
        rootTags,
    };

    return newState;
};

const resetTagQuery = (action, state) => {
    return update(state, {
        tagQuery: {
            $set: null,
        },
        tags:     {
            $set: [],
        },
    });
};

const setTagHierarchy = (action, state) => {
    const { context, tagHierarchy } = action;
    let stateToUse                  = state;

    if (!state.editor[context]) {
        stateToUse = update(stateToUse, {
            editor: {
                [context]: {
                    $set: initialState.editor[context],
                },
            },
        });
    }

    return update(stateToUse, {
        editor: {
            [context]: {
                tagHierarchy: {
                    $set: tagHierarchy,
                },
            },
        },
    });
};

const addChildrenToTagQuery = (action, state) => {
    const tag                = _.get(action, 'tag');
    const children           = _.get(action, 'children');
    const context            = _.get(action, 'context');
    const editorState        = _.cloneDeep(state.editor[context]);
    editorState.tagHierarchy = Tag.addPossibleChildToTagSelectorTree(editorState.tagHierarchy, tag, children);

    return update(
        state,
        {
            editor: {
                [context]: {
                    $set: editorState,
                },
            },
        },
    );
};

const addTag = (action, state) => {
    const context   = _.get(action, 'context');
    let editorState = _.cloneDeep(state.editor[context]);

    if (!editorState) {
        editorState = _.cloneDeep(initialState.editor[context]);
    }

    editorState.tagHierarchy = Tag.addTagToTagSelectorTree(editorState.tagHierarchy, action.tag);

    return update(state, {
        editor: {
            [context]: {
                $set: editorState,
            },
        },
    });
};

const deleteTag = (action, state) => {
    const context            = _.get(action, 'context');
    const editorState        = _.cloneDeep(state.editor[context]);
    const hierarchyPath      = _.get(action.tag, 'data.hierarchyPath');
    editorState.tagHierarchy = Tag.removeTagFromTagSelectorTree(editorState.tagHierarchy, hierarchyPath);

    return update(state, {
        editor: {
            [context]: {
                $set: editorState,
            },
        },
    });
};

const rehydrate = (action, state) => {
    return update(state, {
        isLoading: {
            $set: false,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case TagTypes.DELETE_TAG:                        return deleteTag(action, state);
        case TagTypes.ADD_TAG:                           return addTag(action, state);
        case TagTypes.ADD_CHILDREN_TO_TAG_QUERY:         return addChildrenToTagQuery(action, state);
        case TagTypes.SET_TAG_HIERARCHY:                 return setTagHierarchy(action, state);
        case TagTypes.FETCH_TAGS:                        return fetchTags(action, state);
        case TagTypes.FETCH_TAGS_FAILED:                 return fetchTagsFailed(action, state);
        case TagTypes.FETCH_TAGS_SUCCEEDED:              return fetchTagsSucceeded(action, state);
        case TagTypes.FETCH_TAGS_FOR_DROPDOWN_SUCCEEDED: return fetchTagsForDropdownSucceeded(action, state);
        case TagTypes.FETCH_ROOT_TAGS:                   return fetchRootTags(action, state);
        case TagTypes.FETCH_ROOT_TAGS_FAILED:            return fetchRootTagsFailed(action, state);
        case TagTypes.FETCH_ROOT_TAGS_SUCCEEDED:         return fetchRootTagsSucceeded(action, state);
        case TagTypes.RESET:                             return reset(action, state);
        case TagTypes.RESET_TAG_QUERY:                   return resetTagQuery(action, state);

        case REHYDRATE:                                  return rehydrate(action, state);
        default:                                         return ReducerHelper.updateStateByInitialState(state, initialState);
        // @formatter:on
    }
}
