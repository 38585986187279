//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import classNames      from 'classnames';
import I18n            from 'i18next';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import Icon            from '@/components/stateless/atomic/Icon';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import { FullImage }   from '@/components/stateless/composed/FullImage';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    getParametersString = () => {
        const parameters = [];

        this.props.parameters.forEach((parameter) => {
            parameters.push(`${parameter.name}: ${parameter.value}`);
        });

        return parameters.join(', ');
    };

    render() {
        const machineName = this.props.name;
        const machineId   = this.props.machineId;
        const isSelected  = this.props.selectedMachineId === machineId;

        return (
            <div
                className={classNames(
                    styles.machinePreview,
                    {
                        [styles.machinePreviewSelected]:      isSelected,
                        [styles.machinePreviewFallbackImage]: !this.props.image,
                    },
                )}
                onClick={this.props.onClick(machineId)}
                tabIndex={'-1'}
            >
                <FullImage
                    image={this.props.image}
                    alternativText={`${I18n.t('imageOf')} ${machineName}`}
                    width={80}
                />
                <div className={styles.machinePreviewText}>
                    <h4>
                        {machineName}
                    </h4>
                    {this.renderManufacturer()}
                    {this.renderParameters()}
                </div>
                {this.renderSelectedIcon(isSelected)}
            </div>
        );
    }

    renderSelectedIcon = (isSelected) => {
        if (isSelected) {
            return (
                <div className={styles.machinePreviewSelectedIcon}>
                    <Icon iconType={IconType.check} />
                </div>
            );
        }

        return null;
    };

    renderManufacturer = () => {
        const manufacturer = this.props.manufacturer;

        if (manufacturer) {
            return (
                <p>
                    {`${I18n.t('manufacturer')}: `}
                    {manufacturer}
                </p>
            );
        }

        return null;
    };

    renderParameters = () => {
        if (this.props.parameters.length) {
            return (
                <p>
                    {this.getParametersString()}
                </p>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const MachinePreview = Component;

Component.propTypes = {
    machineId:         PropTypes.string,
    image:             PropTypes.image,
    manufacturer:      PropTypes.string,
    name:              PropTypes.string,
    onClick:           PropTypes.func,
    parameters:        PropTypes.array,
    selectedMachineId: PropTypes.string,
};

Component.defaultProps = {
    machineId:         null,
    image:             null,
    manufacturer:      null,
    name:              null,
    onClick:           _.noop,
    parameters:        [],
    selectedMachineId: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
