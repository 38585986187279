//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import _                    from 'lodash';
import I18n                 from 'i18next';
import ComponentHelper      from '@/helper/ComponentHelper';
import PropTypes            from '@/components/PropTypes';
import FactSheetFactTagList from '@/constants/FactSheetFactTagList';

import styles from './styles.module.scss';

export class Component extends React.Component {
    static leftPaneFields = [
        'employees',
        'mainCustomers',
        FactSheetFactTagList.commodities,
        FactSheetFactTagList.processes,
    ];

    static rightPaneFields = [
        FactSheetFactTagList.productionType,
        FactSheetFactTagList.certificates,
        FactSheetFactTagList.language,
    ];

    renderRow(key, label, value) {
        let renderValue = value;

        if (_.isArray(value)) {
            renderValue = _.map(
                value,
                (item, index) => {
                    return (
                        <React.Fragment
                            key={`${index}-${key}-${value}`}
                        >
                            {item}
                            {' '}
                        </React.Fragment>
                    );
                },
            );
        }

        return (
            <div
                key={key}
                className={styles.factsRow}
            >
                <div className={styles.label}>
                    {label}
                </div>
                <div className={styles.factsRowDottedLineWrapper}>
                    <div
                        className={styles.factsRowDottedLine}
                    />
                </div>
                <div className={styles.factsRowValue}>
                    {renderValue}
                </div>
            </div>
        );
    }

    renderRowByField = (field) => {
        let value                 = _.get(this.props.facts, field, I18n.t('notDefined'));
        const labelTranslationKey = _.camelCase(`factSheetFact ${field}`);
        const label               = I18n.t(labelTranslationKey);

        if (_.isArray(value)) {
            const targetField = 'title';

            value = _.map(value, (object) => _.get(object, targetField));
        }

        if (
            value &&
            _.isString(value)
        ) {
            value = value.split('\n');
        }

        return this.renderRow(field, label, value);
    };

    render() {
        return (
            <div className={styles.factSheetFactsWrapper}>
                <div className={styles.factSheetFactsLeft}>
                    {_.map(Component.leftPaneFields, this.renderRowByField)}
                </div>
                <div className={styles.factSheetFactsRight}>
                    {_.map(Component.rightPaneFields, this.renderRowByField)}
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const FactSheetFacts = Component;

Component.propTypes = {
    facts: PropTypes.object,
};

Component.defaultProps = {
    facts: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
