//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import PropTypes                  from '@/components/PropTypes';
import { connect }                from 'react-redux';
import State                      from '@/helper/State';
import MemberStatus               from '@/constants/MemberStatus';
import ShowIfUserIsAdminOfCompany from '@/components/connected/ShowIfUserIsAdminOfCompany';
import I18n                       from 'i18next';
import MemberStatusWarning        from '@/components/connected/MemberStatusWarning';
import { Spacer }                 from '@/components/stateless/atomic/Spacer';

export class Component extends React.Component {
    render() {
        const { memberStatus, isAdmin, companyId } = this.props;

        if (
            memberStatus === MemberStatus.basic &&
            !isAdmin
        ) {
            return null;
        }

        return (
            <>
                <ShowIfUserIsAdminOfCompany
                    currentCompanyId={companyId}
                >
                    <MemberStatusWarning
                        text={I18n.t('companyUploadVideoAndPresentationWarning')}
                    />
                    <Spacer height={10} />
                </ShowIfUserIsAdminOfCompany>
                {this.props.children}
            </>
        );
    }
}

export const ShowIfCompanyIsMember = Component;

Component.propTypes = {
    children:     PropTypes.node,
    companyId:    PropTypes.string,
    isAdmin:      PropTypes.bool,
    memberStatus: PropTypes.string,
};

Component.defaultProps = {
    children:     null,
    companyId:    null,
    isAdmin:      false,
    memberStatus: null,
};

const mapStateToProps = (state, ownProps) => {
    const companyId     = ownProps.companyId;
    const memberStatus  = State.getCompanyValue(state, companyId, 'memberStatus');
    const userCompanyId = State.getUserCompanyId(state);

    return {
        memberStatus,
        isAdmin: companyId === userCompanyId,
    };
};

export default connect(
    mapStateToProps,
    null,
)(Component);
