//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update from 'immutability-helper';
import _      from 'lodash';
import moment from 'moment';

import { ProjectsTypes } from '@/store/actions/projects';
import Tag               from '@/helper/Tag';
import CompanyTagFields  from '@/constants/CompanyTagFields';

const initialOverlayState = Object.freeze({
    data:       {
        technologyTags:  [],
        branches:        [],
        mandatoryTags:   [],
        partFamilyTags:  [],
        materialTags:    [],
        attachments:     [],
        visibleNow:      true,
        visibleDate:     false,
        startDate:       new Date(),
        endDate:         moment().add(1, 'days').toDate(),
        visibleFromDate: new Date(),
        visibleToDate:   moment().add(1, 'days').toDate(),
        totalVolume:     1,
        peakYearVolume:  1,
    },
    validation: {},
});

const initialSelectedProjectState = Object.freeze({});

const initialState = {
    createProjectOverlayForm: {
        ...initialOverlayState,
    },
    suggestedProjects:        [],
    ownProjects:              [],
    selectedProject:          {
        ...initialSelectedProjectState,
    },
    interestedMessage:        '',
};

const resetOverlay = (action, state) => {
    return update(state, {
        createProjectOverlayForm: {
            $set: initialOverlayState,
        },
    });
};

const addChildrenToTagQuery = (action, state) => {
    const tag      = _.get(action, 'tag');
    const children = _.get(action, 'children');
    const tagType  = _.get(action, 'tagType');
    let tags       = _.cloneDeep(_.get(state, ['createProjectOverlayForm', 'data', tagType], []));
    tags           = Tag.addPossibleChildToTagSelectorTree(tags, tag, children);

    return update(
        state,
        {
            createProjectOverlayForm: {
                data: {
                    [tagType]: {
                        $set: tags,
                    },
                },
            },
        },
    );
};

const addTag = (tagType, action, state) => {
    const { tag } = action;
    let tags      = _.cloneDeep(_.get(state, ['createProjectOverlayForm', 'data', tagType], []));
    tags          = Tag.addTagToTagSelectorTree(tags, tag);

    const updatedState = update(state, {
        createProjectOverlayForm: {
            data: {
                [tagType]: {
                    $set: tags,
                },
            },
        },
    });

    return updatedState;
};

const removeTag = (tagType, action, state) => {
    const { tag } = action;
    let tags      = _.cloneDeep(_.get(state, ['createProjectOverlayForm', 'data', tagType], []));
    tags          = Tag.removeTagFromTagSelectorTree(tags, tag.hierarchyPath);

    const updatedState = update(state, {
        createProjectOverlayForm: {
            data: {
                [tagType]: {
                    $set: tags,
                },
            },
        },
    });

    return updatedState;
};

const resetSelectedProject = (action, state) => {
    return update(state, {
        selectedProject:   {
            $set: {
                ...initialSelectedProjectState,
            },
        },
        interestedMessage: {
            $set: '',
        },
    });
};

const filesChanged = (action, state) => {
    const { files } = action;

    const uniqueFiles = _.uniqBy(files, (file) => {
        return file.name + file.size;
    });

    return update(state, {
        createProjectOverlayForm: {
            data: {
                attachments: {
                    $set: uniqueFiles,
                },
            },
        },
    });
};

const fileDeleted = (action, state) => {
    const { file }    = action;
    const files       = _.get(state, ['createProjectOverlayForm', 'data', 'attachments'], []);
    const indexOfFile = _.findIndex(files, (entry) => {
        return entry.name === file.name;
    });

    return update(state, {
        createProjectOverlayForm: {
            data: {
                attachments: {
                    $removeArrayItemAtIndex: indexOfFile,
                },
            },
        },
    });
};

const ensureValidRange = (state, field, fromKey, toKey, minimumValue) => {
    const from  = _.get(state, ['createProjectOverlayForm', 'data', fromKey]);
    const to    = _.get(state, ['createProjectOverlayForm', 'data', toKey]);
    let updated = _.cloneDeep(state);

    if (field === fromKey) {
        if (from < minimumValue) {
            updated = update(updated, {
                createProjectOverlayForm: {
                    data: {
                        [fromKey]: {
                            $set: minimumValue,
                        },
                    },
                },
            });
        } else if (from > to) {
            updated = update(updated, {
                createProjectOverlayForm: {
                    data: {
                        [fromKey]: {
                            $set: from,
                        },
                        [toKey]:   {
                            $set: from,
                        },
                    },
                },
            });
        }
    } else if (field === toKey) {
        if (to < from) {
            updated = update(updated, {
                createProjectOverlayForm: {
                    data: {
                        [toKey]: {
                            $set: from,
                        },
                    },
                },
            });
        } else if (to < minimumValue) {
            updated = update(updated, {
                createProjectOverlayForm: {
                    data: {
                        [toKey]: {
                            $set: minimumValue,
                        },
                    },
                },
            });
        }
    }

    return updated;
};

const ensureValidVolume = (state, field) => {
    const totalVolume    = _.get(state, ['createProjectOverlayForm', 'data', 'totalVolume']);
    const peakYearVolume = _.get(state, ['createProjectOverlayForm', 'data', 'peakYearVolume']);
    let updated          = _.cloneDeep(state);

    if (totalVolume < 1) {
        updated = update(updated, {
            createProjectOverlayForm: {
                data: {
                    totalVolume: {
                        $set: 1,
                    },
                },
            },
        });
    }

    if (totalVolume < peakYearVolume) {
        if (field === 'totalVolume') {
            updated = update(updated, {
                createProjectOverlayForm: {
                    data: {
                        peakYearVolume: {
                            $set: totalVolume,
                        },
                    },
                },
            });
        } else if (field === 'peakYearVolume') {
            updated = update(updated, {
                createProjectOverlayForm: {
                    data: {
                        totalVolume: {
                            $set: peakYearVolume,
                        },
                    },
                },
            });
        }
    }

    return updated;
};

const handleVisibilityChange = (state, field) => {
    let updated = _.cloneDeep(state);

    if (
        field === 'visibleNow' ||
        field === 'visibleDate'
    ) {
        const oldValue = _.get(state, ['createProjectOverlayForm', 'data', field], false);
        const newValue = !oldValue;

        if (field === 'visibleNow') {
            updated = update(updated, {
                createProjectOverlayForm: {
                    data: {
                        visibleDate: {
                            $set: !newValue,
                        },
                        visibleNow:  {
                            $set: newValue,
                        },
                    },
                },
            });
        } else if (field === 'visibleDate') {
            updated = update(updated, {
                createProjectOverlayForm: {
                    data: {
                        visibleDate: {
                            $set: newValue,
                        },
                        visibleNow:  {
                            $set: !newValue,
                        },
                    },
                },
            });
        }
    }

    if (
        field === 'visibleFromDate' ||
        field === 'visibleToDate'
    ) {
        updated = update(updated, {
            createProjectOverlayForm: {
                data: {
                    visibleDate: {
                        $set: true,
                    },
                    visibleNow:  {
                        $set: false,
                    },
                },
            },
        });
    }

    return updated;
};

const createFormDataChanged = (action, state) => {
    const { field, value } = action;
    let updated            = update(state, {
        createProjectOverlayForm: {
            data: {
                [field]: {
                    $set: value,
                },
            },
        },
    });
    updated                = ensureValidRange(updated, field, 'visibleFromDate', 'visibleToDate', new Date());
    updated                = ensureValidRange(updated, field, 'startDate', 'endDate', new Date());
    updated                = ensureValidVolume(updated, field);
    updated                = handleVisibilityChange(updated, field, value);

    return updated;
};

const createFormDataValidationChanged = (action, state) => {
    const { validation } = action;

    return update(state, {
        createProjectOverlayForm: {
            validation: {
                $set: validation,
            },
        },
    });
};

const fetchProjectsSuggestionsSuccess = (action, state) => {
    const { projects } = action;

    return update(state, {
        suggestedProjects: {
            $set: projects,
        },
    });
};

const fetchProjectsOwnProjectsSuccess = (action, state) => {
    const { projects } = action;

    return update(state, {
        ownProjects: {
            $set: projects,
        },
    });
};

const fetchProjectSuccess = (action, state) => {
    const { project } = action;

    return update(state, {
        selectedProject: {
            $set: project,
        },
    });
};

const changeInterestedMessage = (action, state) => {
    const { message } = action;

    return update(state, {
        interestedMessage: {
            $set: message,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case ProjectsTypes.RESET_OVERLAY:                               return resetOverlay(action, state);
        case ProjectsTypes.ADD_CHILDREN_TO_TAG_QUERY:                   return addChildrenToTagQuery(action, state);
        case ProjectsTypes.ADD_TECHNOLOGY_TAG:                          return addTag(CompanyTagFields.TECHNOLOGY, action, state);
        case ProjectsTypes.ADD_INDUSTRY_TAG:                            return addTag(CompanyTagFields.BRANCHES, action, state);
        case ProjectsTypes.ADD_MATERIAL_TAG:                            return addTag(CompanyTagFields.MATERIAL, action, state);
        case ProjectsTypes.ADD_PART_FAMILY_TAG:                         return addTag(CompanyTagFields.PART_FAMILY, action, state);
        case ProjectsTypes.ADD_MANDATORY_TAG:                           return addTag(CompanyTagFields.MANDATORY, action, state);
        case ProjectsTypes.ADD_INSPECTION_TAG:                          return addTag(CompanyTagFields.INSPECTION, action, state);
        case ProjectsTypes.REMOVE_TECHNOLOGY_TAG:                       return removeTag(CompanyTagFields.TECHNOLOGY, action, state);
        case ProjectsTypes.REMOVE_INDUSTRY_TAG:                         return removeTag(CompanyTagFields.BRANCHES, action, state);
        case ProjectsTypes.REMOVE_MATERIAL_TAG:                         return removeTag(CompanyTagFields.MATERIAL, action, state);
        case ProjectsTypes.REMOVE_PART_FAMILY_TAG:                      return removeTag(CompanyTagFields.PART_FAMILY, action, state);
        case ProjectsTypes.REMOVE_MANDATORY_TAG:                        return removeTag(CompanyTagFields.MANDATORY, action, state);
        case ProjectsTypes.REMOVE_INSPECTION_TAG:                       return removeTag(CompanyTagFields.INSPECTION, action, state);
        case ProjectsTypes.CHANGE_INTERESTED_MESSAGE:                   return changeInterestedMessage(action, state);
        case ProjectsTypes.RESET_SELECTED_PROJECT:                      return resetSelectedProject(action, state);
        case ProjectsTypes.FILES_CHANGED:                               return filesChanged(action, state);
        case ProjectsTypes.FILE_DELETED:                                return fileDeleted(action, state);
        case ProjectsTypes.CREATE_PROJECT_FORM_DATA_CHANGED:            return createFormDataChanged(action, state);
        case ProjectsTypes.CREATE_PROJECT_FORM_DATA_VALIDATION_CHANGED: return createFormDataValidationChanged(action, state);
        case ProjectsTypes.FETCH_PROJECTS_SUGGESTIONS_SUCCESS:          return fetchProjectsSuggestionsSuccess(action, state);
        case ProjectsTypes.FETCH_PROJECTS_OWN_PROJECTS_SUCCESS:         return fetchProjectsOwnProjectsSuccess(action, state);
        case ProjectsTypes.FETCH_PROJECT_SUCCESS:                       return fetchProjectSuccess(action, state);
        default:                                                        return state;
        // @formatter:on
    }
}
