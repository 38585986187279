//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import classNames             from 'classnames';
import ComponentHelper        from '@/helper/ComponentHelper';
import Icon                   from '@/components/stateless/atomic/Icon';
import IconButtonTheme        from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import IconTextButtonPosition from '@/components/stateless/atomic/IconTextButton/IconTextButtonPosition';
import Link                   from '@/components/stateless/atomic/Link';
import PropTypes              from '@/components/PropTypes';
import SelectionHelper        from '@/helper/SelectionHelper';
import styles                 from './styles.module.scss';

export class Component extends React.Component {
    getClassName = () => {
        const className = classNames(
            styles.iconTextButton,
            {
                [styles.active]:     this.props.active,
                [styles.disabled]:   this.props.disabled,
                [styles.hideMobile]: this.props.hideMobile,
            },
            SelectionHelper.get(
                this.props.theme,
                {
                    [IconButtonTheme.grayBorder]:          styles.iconTextButtonGrayBorder,
                    [IconButtonTheme.grayBorderGrayHover]: styles.iconTextButtonGrayBorderGrayHover,
                    [IconButtonTheme.grayRound]:           styles.iconTextButtonGrayRound,
                    [IconButtonTheme.plain]:               styles.iconTextButtonPlain,
                },
            ),
            SelectionHelper.get(
                this.props.iconPosition,
                {
                    [IconTextButtonPosition.right]: styles.iconTextButtonRight,
                    [IconTextButtonPosition.left]:  styles.iconTextButtonLeft,
                },
            ),
        );

        return className;
    };

    render() {
        const finalClassName = this.getClassName();

        return (
            <Link
                className={classNames(
                    finalClassName,
                    styles.iconTextButtonWrapper,
                    {
                        [styles.noText]: !this.props.text,
                    },
                    this.props.className,
                )}
                onClick={this.props.onClick}
                to={this.props.to}
            >
                {this.renderText()}
                <Icon
                    iconType={this.props.iconType}
                />
            </Link>
        );
    }

    renderText = () => {
        if (this.props.text) {
            return (
                <span>
                    {this.props.text}
                </span>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const IconTextButton = Component;

Component.propTypes = {
    active:       PropTypes.bool,
    className:    PropTypes.string,
    disabled:     PropTypes.bool,
    hideMobile:   PropTypes.bool,
    iconPosition: PropTypes.oneOfObjectValues(IconTextButtonPosition),
    iconType:     PropTypes.icon,
    onClick:      PropTypes.func,
    text:         PropTypes.string,
    theme:        PropTypes.oneOfObjectValues(IconButtonTheme),
    to:           PropTypes.to,
};

Component.defaultProps = {
    active:       false,
    className:    null,
    disabled:     false,
    hideMobile:   false,
    iconPosition: IconTextButtonPosition.left,
    iconType:     null,
    onClick:      null,
    text:         '',
    theme:        IconButtonTheme.grayBorder,
    to:           null,
};

Component.renderAffectingProps = [
    'iconPosition',
    'iconType',
    'text',
    'theme',
    'to',
    'disabled',
];

Component.renderAffectingStates = [];

export default Component;
