//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator }                from '@/helper/Store';
import { makeActionCreatorIncreaseLoading } from '@/helper/Store';
import { makeActionCreatorDecreaseLoading } from '@/helper/Store';

export const CompanyTypes = {
    DELETE_COMPANY:                                    'Company/DELETE_COMPANY',
    DELETE_COMPANY_FAILED:                             'Company/DELETE_COMPANY_FAILED',
    DELETE_COMPANY_SUCCEEDED:                          'Company/DELETE_COMPANY_SUCCEEDED',
    ACT_PRIVATE:                                       'Company/ACT_PRIVATE',
    ACT_PRIVATE_FAILED:                                'Company/ACT_PRIVATE_FAILED',
    ACT_PRIVATE_SUCCEEDED:                             'Company/ACT_PRIVATE_SUCCEEDED',
    ADD_OWN_COMPANY_CHILDREN_TO_TAG_QUERY_COMPETENCES: 'Company/ADD_OWN_COMPANY_CHILDREN_TO_TAG_QUERY_COMPETENCES',
    ADD_OWN_COMPANY_CHILDREN_TO_TAG_QUERY_INTERESTS:   'Company/ADD_OWN_COMPANY_CHILDREN_TO_TAG_QUERY_INTERESTS',
    ADD_OWN_COMPANY_COMPETENCE_TAG:                    'Company/ADD_OWN_COMPANY_COMPETENCE_TAG',
    ADD_OWN_COMPANY_COMPETENCE_TAG_QUERY:              'Company/ADD_OWN_COMPANY_COMPETENCE_TAG_QUERY',
    ADD_OWN_COMPANY_MACHINE:                           'Company/ADD_OWN_COMPANY_MACHINE',
    ADD_OWN_COMPANY_INTEREST_TAG:                      'Company/ADD_OWN_COMPANY_INTEREST_TAG',
    ADD_OWN_COMPANY_INTEREST_TAG_QUERY:                'Company/ADD_OWN_COMPANY_INTEREST_TAG_QUERY',
    ADD_SIBLING_OWN_COMPANY_COMPETENCE_TAG:            'Company/ADD_SIBLING_OWN_COMPANY_COMPETENCE_TAG',
    ADD_SIBLING_OWN_COMPANY_INTEREST_TAG:              'Company/ADD_SIBLING_OWN_COMPANY_INTEREST_TAG',
    CANCEL_EDIT_OWN_COMPANY:                           'Company/CANCEL_EDIT_OWN_COMPANY',
    CHANGE_OWN_COMPANY_COMPETENCE_TAG:                 'Company/CHANGE_OWN_COMPANY_COMPETENCE_TAG',
    CHANGE_OWN_COMPANY_INTEREST_TAG:                   'Company/CHANGE_OWN_COMPANY_INTEREST_TAG',
    CREATE_COMPANY:                                    'Company/CREATE_COMPANY',
    CREATE_COMPANY_SUCCEEDED:                          'Company/CREATE_COMPANY_SUCCEEDED',
    CREATE_COMPANY_FAILED:                             'Company/CREATE_COMPANY_FAILED',
    CONFIRM_COMPANY_DELETE:                            'Company/CONFIRM_COMPANY_DELETE',
    DELETE_OWN_COMPANY_COMPETENCE_TAG:                 'Company/DELETE_OWN_COMPANY_COMPETENCE_TAG',
    DELETE_OWN_COMPANY_INTEREST_TAG:                   'Company/DELETE_OWN_COMPANY_INTEREST_TAG',
    OPEN_DELETE_OWN_COMPANY_MACHINE_DIALOG:            'Company/OPEN_DELETE_OWN_COMPANY_MACHINE_DIALOG',
    OPEN_REMOVE_OWN_COMPANY_MACHINE_DIALOG:            'Company/OPEN_REMOVE_OWN_COMPANY_MACHINE_DIALOG',
    DELETE_OWN_COMPANY_MACHINE:                        'Company/DELETE_OWN_COMPANY_MACHINE',
    DELETE_OWN_COMPANY_MACHINE_SUCCEEDED:              'Company/DELETE_OWN_COMPANY_MACHINE_SUCCEEDED',
    DELETE_OWN_COMPANY_MACHINE_FAILED:                 'Company/DELETE_OWN_COMPANY_MACHINE_FAILED',
    REMOVE_OWN_COMPANY_MACHINE:                        'Company/REMOVE_OWN_COMPANY_MACHINE',
    REMOVE_OWN_COMPANY_MACHINE_SUCCEEDED:              'Company/REMOVE_OWN_COMPANY_MACHINE_SUCCEEDED',
    EDIT_OWN_COMPANY_MACHINE:                          'Company/EDIT_OWN_COMPANY_MACHINE',
    EDIT_OWN_COMPANY:                                  'Company/EDIT_OWN_COMPANY',
    EDIT_OWN_COMPANY_ADDRESS_CITY:                     'Company/EDIT_OWN_COMPANY_ADDRESS_CITY',
    EDIT_OWN_COMPANY_ADDRESS_COUNTRY:                  'Company/EDIT_OWN_COMPANY_ADDRESS_COUNTRY',
    EDIT_OWN_COMPANY_ADDRESS_HOUSE_NUMBER:             'Company/EDIT_OWN_COMPANY_ADDRESS_HOUSE_NUMBER',
    EDIT_OWN_COMPANY_ADDRESS_POSTAL_CODE:              'Company/EDIT_OWN_COMPANY_ADDRESS_POSTAL_CODE',
    EDIT_OWN_COMPANY_ADDRESS_STATE:                    'Company/EDIT_OWN_COMPANY_ADDRESS_STATE',
    EDIT_OWN_COMPANY_ADDRESS_STREET:                   'Company/EDIT_OWN_COMPANY_ADDRESS_STREET',
    EDIT_OWN_COMPANY_AND_OPEN_OVERLAY:                 'Company/EDIT_OWN_COMPANY_AND_OPEN_OVERLAY',
    EDIT_OWN_COMPANY_COMPANY_INDUSTRIES:               'Company/EDIT_OWN_COMPANY_COMPANY_INDUSTRIES',
    EDIT_OWN_COMPANY_COMPANY_TYPES:                    'Company/EDIT_OWN_COMPANY_COMPANY_TYPES',
    EDIT_OWN_COMPANY_CONTACT_PHONE:                    'Company/EDIT_OWN_COMPANY_CONTACT_PHONE',
    EDIT_OWN_COMPANY_DESCRIPTION:                      'Company/EDIT_OWN_COMPANY_DESCRIPTION',
    EDIT_OWN_COMPANY_LOGO:                             'Company/EDIT_OWN_COMPANY_LOGO',
    EDIT_OWN_COMPANY_NAME:                             'Company/EDIT_OWN_COMPANY_NAME',
    EDIT_OWN_COMPANY_VIDEO:                            'Company/EDIT_OWN_COMPANY_VIDEO',
    EDIT_OWN_COMPANY_PRESENTATION:                     'Company/EDIT_OWN_COMPANY_PRESENTATION',
    FETCH_COMPANY:                                     'Company/FETCH_COMPANY',
    FETCH_COMPANY_FAILED:                              'Company/FETCH_COMPANY_FAILED',
    FETCH_COMPANY_SUCCEEDED:                           'Company/FETCH_COMPANY_SUCCEEDED',
    GET_OWN_COMPANY_CHILD_TAGS:                        'Company/GET_OWN_COMPANY_CHILD_TAGS',
    JOIN_REQUEST_ACCEPT:                               'Company/JOIN_REQUEST_ACCEPT',
    JOIN_REQUEST_DECLINE:                              'Company/JOIN_REQUEST_DECLINE',
    OPEN_PROJECTS:                                     'Company/OPEN_PROJECTS',
    SET_CREATE_COMPANY:                                'Company/SET_CREATE_COMPANY',
    SET_CURRENT_COMPANY:                               'Company/SET_CURRENT_COMPANY',
    SET_CURRENT_COMPANY_JOIN_REQUESTS:                 'Company/SET_CURRENT_COMPANY_JOIN_REQUESTS',
    SET_MY_COMPANY_QUERY:                              'Company/SET_MY_COMPANY_QUERY',
    SET_MY_COMPANY_RESULTS:                            'Company/SET_MY_COMPANY_RESULTS',
    SET_COMPANY_AS_ACTIVE:                             'Company/SET_COMPANY_AS_ACTIVE',
    UPDATE_COMPANY:                                    'Company/UPDATE_COMPANY',
    UPDATE_COMPANY_FAILED:                             'Company/UPDATE_COMPANY_FAILED',
    UPDATE_COMPANY_SUCCEEDED:                          'Company/UPDATE_COMPANY_SUCCEEDED',
    UPDATE_COMPANY_UPLOAD_PROGRESS:                    'Company/UPDATE_COMPANY_UPLOAD_PROGRESS',
};

const addCompanyCompetenceTag = makeActionCreator(
    CompanyTypes.ADD_OWN_COMPANY_COMPETENCE_TAG,
    {
        tag: null,
    },
);

const addOwnCompanyMachine = makeActionCreator(
    CompanyTypes.ADD_OWN_COMPANY_MACHINE,
    {
        machineIri: null,
    },
);

const addCompanyInterestTag = makeActionCreator(
    CompanyTypes.ADD_OWN_COMPANY_INTEREST_TAG,
    {
        tag: null,
    },
);

const addOwnCompanyChildrenToTagQueryCompetences = makeActionCreator(
    CompanyTypes.ADD_OWN_COMPANY_CHILDREN_TO_TAG_QUERY_COMPETENCES,
    {
        tag:      null,
        children: null,
    },
);

const addOwnCompanyChildrenToTagQueryInterests = makeActionCreator(
    CompanyTypes.ADD_OWN_COMPANY_CHILDREN_TO_TAG_QUERY_INTERESTS,
    {
        tag:      null,
        children: null,
    },
);

const addCompanyCompetenceTagQuery = makeActionCreator(
    CompanyTypes.ADD_OWN_COMPANY_COMPETENCE_TAG_QUERY,
    {
        tag: null,
    },
);

const addCompanyInterestTagQuery = makeActionCreator(
    CompanyTypes.ADD_OWN_COMPANY_INTEREST_TAG_QUERY,
    {
        tag: null,
    },
);

const addSiblingCompanyCompetenceTag = makeActionCreator(
    CompanyTypes.ADD_SIBLING_OWN_COMPANY_COMPETENCE_TAG,
    {
        tag: null,
    },
);

const addSiblingCompanyInterestTag = makeActionCreator(
    CompanyTypes.ADD_SIBLING_OWN_COMPANY_INTEREST_TAG,
    {
        tag: null,
    },
);

const cancelEditOwnCompany = makeActionCreator(CompanyTypes.CANCEL_EDIT_OWN_COMPANY);

const changeCompanyCompetenceTag = makeActionCreator(
    CompanyTypes.CHANGE_OWN_COMPANY_COMPETENCE_TAG,
    {
        tag: null,
    },
);

const changeCompanyInterestTag = makeActionCreator(
    CompanyTypes.CHANGE_OWN_COMPANY_INTEREST_TAG,
    {
        tag: null,
    },
);

const deleteCompanyCompetenceTag = makeActionCreator(
    CompanyTypes.DELETE_OWN_COMPANY_COMPETENCE_TAG,
    {
        tag: null,
    },
);

const deleteCompanyInterestTag = makeActionCreator(
    CompanyTypes.DELETE_OWN_COMPANY_INTEREST_TAG,
    {
        company: null,
    },
);

const deleteCompanyMachine = makeActionCreator(
    CompanyTypes.DELETE_OWN_COMPANY_MACHINE,
    {
        machine: null,
    },
);

const deleteCompanyMachineSucceeded = makeActionCreator(
    CompanyTypes.DELETE_OWN_COMPANY_MACHINE_SUCCEEDED,
    {},
);

const deleteCompanyMachineFailed = makeActionCreator(
    CompanyTypes.DELETE_OWN_COMPANY_MACHINE_FAILED,
    {},
);

const removeCompanyMachine = makeActionCreator(
    CompanyTypes.REMOVE_OWN_COMPANY_MACHINE,
    {
        machine: null,
    },
);

const removeCompanyMachineSucceeded = makeActionCreator(
    CompanyTypes.REMOVE_OWN_COMPANY_MACHINE_SUCCEEDED,
    {
        machine: null,
    },
);

const openDeleteCompanyMachineDialog = makeActionCreator(
    CompanyTypes.OPEN_DELETE_OWN_COMPANY_MACHINE_DIALOG,
    {
        machine: null,
    },
);

const openRemoveCompanyMachineDialog = makeActionCreator(
    CompanyTypes.OPEN_REMOVE_OWN_COMPANY_MACHINE_DIALOG,
    {
        machine: null,
    },
);

const editCompanyMachine = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_MACHINE,
    {
        machineId: null,
    },
);

const editOwnCompany = makeActionCreator(CompanyTypes.EDIT_OWN_COMPANY);

const editOwnCompanyAddressCity = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_CITY,
    {
        city: null,
    },
);

const editOwnCompanyAddressCountry = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_COUNTRY,
    {
        country:     null,
        countryCode: null,
    },
);

const editOwnCompanyAddressHouseNumber = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_HOUSE_NUMBER,
    {
        houseNumber: null,
    },
);

const editOwnCompanyAddressPostalCode = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_POSTAL_CODE,
    {
        postalCode: null,
    },
);

const editOwnCompanyAddressState = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_STATE,
    {
        state: null,
    },
);

const editOwnCompanyAddressStreet = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_STREET,
    {
        street: null,
    },
);

const editOwnCompanyAndOpenOverlay = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_AND_OPEN_OVERLAY,
    {},
);

const editOwnCompanyCompanyTypes = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_COMPANY_TYPES,
    {
        companyTypes: null,
    },
);

const editOwnCompanyCompanyIndustries = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_COMPANY_INDUSTRIES,
    {
        companyIndustries: null,
    },
);

const editOwnCompanyContactPhone = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_CONTACT_PHONE,
    {
        phoneNumber: null,
    },
);

const editOwnCompanyDescription = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_DESCRIPTION,
    {
        description: null,
    },
);

const editOwnCompanyLogo = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_LOGO,
    {
        logo: null,
    },
);

const editOwnCompanyName = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_NAME,
    {
        name: null,
    },
);

const editOwnCompanyVideo = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_VIDEO,
    {
        name: null,
    },
);

const editOwnCompanyPresentation = makeActionCreator(
    CompanyTypes.EDIT_OWN_COMPANY_PRESENTATION,
    {
        name: null,
    },
);

const fetchCompany = makeActionCreatorIncreaseLoading(
    CompanyTypes.FETCH_COMPANY,
    {
        id: null,
    },
);

const fetchCompanyFailed = makeActionCreatorDecreaseLoading(CompanyTypes.FETCH_COMPANY_FAILED);

const fetchCompanySucceeded = makeActionCreatorDecreaseLoading(
    CompanyTypes.FETCH_COMPANY_SUCCEEDED,
    {
        company: null,
    },
);

const getOwnCompanyChildTags = makeActionCreator(CompanyTypes.GET_OWN_COMPANY_CHILD_TAGS);

const setCurrentCompany = makeActionCreator(
    CompanyTypes.SET_CURRENT_COMPANY,
    {
        id: null,
    },
);

const setCurrentCompanyJoinRequests = makeActionCreator(
    CompanyTypes.SET_CURRENT_COMPANY_JOIN_REQUESTS,
    {
        usersRequestedToJoin: [],
    },
);

const setMyCompanyQuery = makeActionCreator(
    CompanyTypes.SET_MY_COMPANY_QUERY,
    {
        query: null,
    },
);

const setCreateCompany = makeActionCreator(
    CompanyTypes.SET_CREATE_COMPANY,
    {
        companyName: null,
        position:    null,
    },
);

const createCompany = makeActionCreator(CompanyTypes.CREATE_COMPANY);

const createCompanySucceeded = makeActionCreator(
    CompanyTypes.CREATE_COMPANY_SUCCEEDED,
    {
        company: null,
    },
);

const createCompanyFailed = makeActionCreator(CompanyTypes.CREATE_COMPANY_FAILED);
const actPrivate          = makeActionCreator(CompanyTypes.ACT_PRIVATE);
const actPrivateSucceeded = makeActionCreator(CompanyTypes.ACT_PRIVATE_SUCCEEDED);
const actPrivateFailed    = makeActionCreator(CompanyTypes.ACT_PRIVATE_FAILED);

const setMyCompanySearchResults = makeActionCreator(
    CompanyTypes.SET_MY_COMPANY_RESULTS,
    {
        companies: [],
    },
);

const setCompanyAsActive = makeActionCreator(
    CompanyTypes.SET_COMPANY_AS_ACTIVE,
    {
        id: null,
    },
);

const updateCompany       = makeActionCreator(CompanyTypes.UPDATE_COMPANY);
const updateCompanyFailed = makeActionCreator(CompanyTypes.UPDATE_COMPANY_FAILED);

const updateCompanySucceeded = makeActionCreator(
    CompanyTypes.UPDATE_COMPANY_SUCCEEDED,
    {
        company: null,
    },
);

const acceptJoinRequest = makeActionCreator(
    CompanyTypes.JOIN_REQUEST_ACCEPT,
    {
        companyIri: null,
        userId:     null,
    },
);

const declineJoinRequest = makeActionCreator(
    CompanyTypes.JOIN_REQUEST_DECLINE,
    {
        companyIri: null,
        userId:     null,
    },
);

const openProjects = makeActionCreator(
    CompanyTypes.OPEN_PROJECTS,
    {
        companyIri: null,
    },
);

const deleteCompany = makeActionCreatorIncreaseLoading(
    CompanyTypes.DELETE_COMPANY,
    {
        companyId: null,
    },
);

const deleteCompanyFailed = makeActionCreatorDecreaseLoading(
    CompanyTypes.DELETE_COMPANY_FAILED,
    {},
);

const deleteCompanySucceeded = makeActionCreatorDecreaseLoading(
    CompanyTypes.DELETE_COMPANY_SUCCEEDED,
    {},
);

const confirmCompanyDelete = makeActionCreator(
    CompanyTypes.CONFIRM_COMPANY_DELETE,
    {},
);

export const CompanyActions = {
    acceptJoinRequest,
    actPrivate,
    actPrivateFailed,
    actPrivateSucceeded,
    addCompanyCompetenceTag,
    addCompanyCompetenceTagQuery,
    addCompanyInterestTag,
    addCompanyInterestTagQuery,
    addOwnCompanyChildrenToTagQueryCompetences,
    addOwnCompanyChildrenToTagQueryInterests,
    addOwnCompanyMachine,
    addSiblingCompanyCompetenceTag,
    addSiblingCompanyInterestTag,
    cancelEditOwnCompany,
    changeCompanyCompetenceTag,
    changeCompanyInterestTag,
    createCompany,
    createCompanyFailed,
    createCompanySucceeded,
    confirmCompanyDelete,
    declineJoinRequest,
    deleteCompany,
    deleteCompanyCompetenceTag,
    deleteCompanyFailed,
    deleteCompanyInterestTag,
    deleteCompanyMachine,
    deleteCompanyMachineFailed,
    deleteCompanyMachineSucceeded,
    deleteCompanySucceeded,
    editCompanyMachine,
    editOwnCompany,
    editOwnCompanyAddressCity,
    editOwnCompanyAddressCountry,
    editOwnCompanyAddressHouseNumber,
    editOwnCompanyAddressPostalCode,
    editOwnCompanyAddressState,
    editOwnCompanyAddressStreet,
    editOwnCompanyAndOpenOverlay,
    editOwnCompanyCompanyIndustries,
    editOwnCompanyCompanyTypes,
    editOwnCompanyContactPhone,
    editOwnCompanyDescription,
    editOwnCompanyLogo,
    editOwnCompanyName,
    editOwnCompanyVideo,
    editOwnCompanyPresentation,
    fetchCompany,
    fetchCompanyFailed,
    fetchCompanySucceeded,
    getOwnCompanyChildTags,
    openDeleteCompanyMachineDialog,
    openProjects,
    openRemoveCompanyMachineDialog,
    removeCompanyMachine,
    removeCompanyMachineSucceeded,
    setCompanyAsActive,
    setCreateCompany,
    setCurrentCompany,
    setCurrentCompanyJoinRequests,
    setMyCompanyQuery,
    setMyCompanySearchResults,
    updateCompany,
    updateCompanyFailed,
    updateCompanySucceeded,
};
