//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import _                          from 'lodash';
import I18n                       from 'i18next';
import PropTypes                  from '@/components/PropTypes';
import ComponentHelper            from '@/helper/ComponentHelper';
import IconButton                 from '@/components/stateless/atomic/IconButton';
import IconType                   from '@/components/stateless/atomic/Icon/IconType';
import IconButtonTheme            from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import ShowIfUserIsAdminOfCompany from '@/components/connected/ShowIfUserIsAdminOfCompany';
import { FullImage }              from '@/components/stateless/composed/FullImage';
import styles                     from './styles.module.scss';

export class Component extends React.Component {
    static maxProductsPerRow = 5;

    constructor(props) {
        super(props);

        this.state = {
            loadedImages: 0,
        };
    }

    componentDidMount() {
        if (
            !this.props.products ||
            !this.props.products.length
        ) {
            this.props.onImagesLoaded();
        }
    }

    onImageLoaded = () => {
        this.setState(
            {
                loadedImages: this.state.loadedImages + 1,
            },
            () => {
                if (this.state.loadedImages >= this.props.products.length) {
                    this.props.onImagesLoaded();
                }
            },
        );
    };

    addProductButtonPressed = () => {
        this.props.onAddProduct();
    };

    deleteButtonPressed = (product, index) => () => {
        this.props.onDeleteProduct(product, index);
    };

    editButtonPressed = (product, index) => () => {
        this.props.onEditProduct(index);
    };

    renderHeadline = () => {
        const { products, maxProductAmount } = this.props;
        const productAmount                  = _.get(products, 'length', 0);

        return (
            <h2 className={styles.factSheetProductsHeadline}>
                {I18n.t(
                    'factSheetProductsTitle',
                    {
                        productAmount,
                    },
                )}
                <ShowIfUserIsAdminOfCompany
                    currentCompanyId={this.props.currentCompanyId}
                >
                    <IconButton
                        iconType={IconType.addCircle}
                        onClick={this.addProductButtonPressed}
                    />
                    <span className={styles.factSheetProductsHeadlineSubtext}>
                        {I18n.t(
                            'factSheetProductsSubtitle',
                            {
                                productAmount: maxProductAmount,
                            },
                        )}
                    </span>
                </ShowIfUserIsAdminOfCompany>
            </h2>
        );
    };

    renderRowBreak = (index) => {
        const isEven = (
            (
                index + 1
            ) % Component.maxProductsPerRow === 0
        );

        if (!isEven) {
            return null;
        }

        return (
            <div className={styles.break} />
        );
    };

    renderProduct = (product, index) => {
        return (
            <React.Fragment key={`product-${index}`}>
                <div className={styles.factSheetProductsPreviewWrapper}>
                    <div className={styles.factSheetProductsPreviewContent}>
                        <div className={styles.factSheetProductsPreviewButtonWrapper}>
                            <div className={styles.factSheetProductsPreviewButtonRow}>
                                <ShowIfUserIsAdminOfCompany
                                    currentCompanyId={this.props.currentCompanyId}
                                >
                                    <IconButton
                                        iconType={IconType.cross}
                                        onClick={this.deleteButtonPressed(product, index)}
                                        theme={IconButtonTheme.blackRectangular}
                                    />
                                    <IconButton
                                        iconType={IconType.pencil}
                                        onClick={this.editButtonPressed(product, index)}
                                        theme={IconButtonTheme.blackRectangular}
                                    />
                                </ShowIfUserIsAdminOfCompany>
                            </div>
                        </div>
                        <FullImage
                            className={styles.factSheetProductsPreviewImage}
                            image={product.image}
                            alternativeText={product.title}
                            onLoad={this.onImageLoaded}
                            onError={this.onImageLoaded}
                            width={200}
                        />
                    </div>
                    <div className={styles.factSheetProductsPreviewTitle}>
                        {product.title}
                    </div>
                </div>
                {this.renderRowBreak(index)}
            </React.Fragment>
        );
    };

    render() {
        const { products, isAdmin } = this.props;

        if (
            (
                products &&
                products.length
            ) ||
            isAdmin
        ) {
            return (
                <div className={styles.factSheetProducts}>
                    {this.renderHeadline()}
                    <div className={styles.factSheetProductsPreview}>
                        {_.map(products, this.renderProduct)}
                    </div>
                </div>
            );
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const FactSheetProducts = Component;

Component.propTypes = {
    currentCompanyId: PropTypes.string,
    isAdmin:          PropTypes.bool,
    maxProductAmount: PropTypes.number,
    onAddProduct:     PropTypes.func,
    onDeleteProduct:  PropTypes.func,
    onEditProduct:    PropTypes.func,
    onImagesLoaded:   PropTypes.func,
    products:         PropTypes.array,
};

Component.defaultProps = {
    currentCompanyId: null,
    isAdmin:          false,
    maxProductAmount: 0,
    onAddProduct:     _.noop,
    onDeleteProduct:  _.noop,
    onEditProduct:    _.noop,
    onImagesLoaded:   _.noop,
    products:         [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
