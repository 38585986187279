//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    company:                        '/company/:companyId',
    companyFactSheet:               '/company/:companyId/fact-sheet',
    companyFactSheetPrint:          '/company/:companyId/fact-sheet/print',
    companyMachine:                 '/company/:companyId/machine',
    companyMachinePart:             '/machine',
    companyMessage:                 '/company/:companyId/message',
    companyMessageDetail:           '/company/:companyId/message/:messageRequestId',
    companyPart:                    '/company/',
    companyProduction:              '/company/:companyId/production',
    companyProjectDetail:           '/company/:companyId/projects/:projectId',
    companyProjects:                '/company/:companyId/projects',
    favorites:                      '/favorites',
    home:                           '/',
    login:                          '/login',
    lostPassword:                   '/lost-password',
    machine:                        '/machine/:machineId',
    membership:                     '/membership',
    membershipRequest:              '/membership/request',
    myCompany:                      '/my-company',
    myProfile:                      '/my-profile',
    myProfileCurrentRole:           '/my-profile/current-role',
    myProfileVerification:          '/my-profile/verification',
    myProfileFavorites:             '/my-profile/favorites',
    myProfileJoinCompany:           '/my-profile/join-company',
    myProfileMessages:              '/my-profile/messages',
    myProfileMessagesDetail:        '/my-profile/messages/:messageRequestId',
    myProfileProjects:              '/my-profile/projects',
    myProfileProjectsProjectDetail: '/my-profile/projects/:projectId',
    screenDesign:                   '/screendesign',
    searchResults:                  '/search',
    signUp:                         '/signup',
    userManagement:                 '/company/:companyId/user-management',
};
