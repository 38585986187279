//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const ProductTypes = {
    DELETE_PRODUCT:            'Product/DELETE_PRODUCT',
    DELETE_PRODUCT_FAILED:     'Product/DELETE_PRODUCT_FAILED',
    DELETE_PRODUCT_SUCCEEDED:  'Product/DELETE_PRODUCT_SUCCEEDED',
    EDIT_PRODUCT:              'Product/EDIT_PRODUCT',
    EDIT_PRODUCT_FIELD:        'Product/EDIT_PRODUCT_FIELD',
    FETCH_PRODUCTS:            'Product/FETCH_PRODUCTS',
    FETCH_PRODUCTS_FAILED:     'Product/FETCH_PRODUCTS_FAILED',
    FETCH_PRODUCTS_SUCCEEDED:  'Product/FETCH_PRODUCTS_SUCCEEDED',
    LOAD_OWN_PRODUCT:          'Product/LOAD_OWN_PRODUCT',
    OPEN_EDIT_PRODUCT_OVERLAY: 'Product/OPEN_EDIT_PRODUCT_OVERLAY',
    REQUEST_PRODUCT:           'Product/REQUEST_PRODUCT',
    SAVE_PRODUCT:              'Product/SAVE_PRODUCT',
    SAVE_PRODUCT_FAILED:       'Product/SAVE_PRODUCT_FAILED',
    SAVE_PRODUCT_SUCCEEDED:    'Product/SAVE_PRODUCT_SUCCEEDED',
};

const deleteProduct = makeActionCreator(
    ProductTypes.DELETE_PRODUCT,
    {
        index: null,
    },
);

const deleteProductFailed = makeActionCreator(
    ProductTypes.DELETE_PRODUCT_FAILED,
    {},
);

const deleteProductSucceeded = makeActionCreator(
    ProductTypes.DELETE_PRODUCT_SUCCEEDED,
    {},
);

const editProduct = makeActionCreator(
    ProductTypes.EDIT_PRODUCT,
    {
        product: null,
    },
);

const editProductField = makeActionCreator(
    ProductTypes.EDIT_PRODUCT_FIELD,
    {},
);

const fetchProducts = makeActionCreator(
    ProductTypes.FETCH_PRODUCTS,
    {},
);

const fetchProductsFailed = makeActionCreator(
    ProductTypes.FETCH_PRODUCTS_FAILED,
    {},
);

const fetchProductsSucceeded = makeActionCreator(
    ProductTypes.FETCH_PRODUCTS_SUCCEEDED,
    {
        products: null,
    },
);

const loadOwnProduct = makeActionCreator(
    ProductTypes.LOAD_OWN_PRODUCT,
    {
        ownProduct: null,
    },
);

const openEditProductOverlay = makeActionCreator(
    ProductTypes.OPEN_EDIT_PRODUCT_OVERLAY,
    {},
);

const requestProduct = makeActionCreator(
    ProductTypes.REQUEST_PRODUCT,
    {
        product: null,
    },
);

const saveProduct = makeActionCreator(
    ProductTypes.SAVE_PRODUCT,
    {},
);

const saveProductFailed = makeActionCreator(
    ProductTypes.SAVE_PRODUCT_FAILED,
    {},
);

const saveProductSucceeded = makeActionCreator(
    ProductTypes.SAVE_PRODUCT_SUCCEEDED,
    {},
);

export const ProductActions = {
    deleteProduct,
    deleteProductFailed,
    deleteProductSucceeded,
    editProduct,
    editProductField,
    fetchProducts,
    fetchProductsFailed,
    fetchProductsSucceeded,
    loadOwnProduct,
    openEditProductOverlay,
    requestProduct,
    saveProduct,
    saveProductFailed,
    saveProductSucceeded,
};
