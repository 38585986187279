//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import I18n                   from 'i18next';
import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import TextInput              from '@/components/stateless/atomic/TextInput';
import ColorButtonTheme       from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import Routes                 from '@/constants/Routes';
import ColorButton            from '@/components/stateless/atomic/ColorButton';
import { StartPageBoxFooter } from '@/components/stateless/composed/StartPageBoxFooter';
import Align                  from '@/constants/Align';
import { PasswordForm }       from '@/components/stateless/composed/PasswordForm';
import { Spacer }             from '@/components/stateless/atomic/Spacer';
import { Trans }              from 'react-i18next';
import styles                 from './styles.module.scss';

export class Component extends React.Component {
    onFormSubmit = (event) => {
        event.preventDefault();
    };

    updateSignupData = (field) => (event) => {
        const value = event.target.value;

        this.props.updateSignupField({
            field,
            value,
        });
    };

    renderTextInput = (placeHolder, field, type = 'text') => {
        const value = _.get(this.props.signupData, field);

        return (
            <TextInput
                placeholder={I18n.t(placeHolder)}
                type={type}
                onChange={this.updateSignupData(field)}
                value={value}
                autoFocus={true}
            />
        );
    };

    signupButtonPressed = () => {
        this.props.signup();
    };

    passwordScoreUpdated = (passwordScore) => {
        this.props.updateSignupField({
            field: 'passwordScore',
            value: passwordScore,
        });
    };

    render() {
        const { password, repeatedPassword } = this.props.signupData;

        return (
            <>
                <form onSubmit={this.onFormSubmit}>
                    {this.renderTextInput('name', 'name')}
                    <Spacer height={20} />
                    {this.renderTextInput('emailAddress', 'identifier', 'email')}
                    <Spacer height={20} />
                    <PasswordForm
                        password={password}
                        repeatedPassword={repeatedPassword}
                        updatePassword={this.updateSignupData('password')}
                        updateRepeatedPassword={this.updateSignupData('repeatedPassword')}
                        updatePasswordScore={this.passwordScoreUpdated}
                    />
                    <p className={styles.legalText}>
                        <Trans
                            i18nKey={'singUpTerms'}
                            components={{
                                a: <a />,
                            }}
                        />
                    </p>
                    <div className={styles.signupButtonWrapper}>
                        <ColorButton
                            grow={true}
                            theme={ColorButtonTheme.orange}
                            text={I18n.t('signUp')}
                            onClick={this.signupButtonPressed}
                        />
                    </div>
                </form>
                <StartPageBoxFooter
                    text={I18n.t('alreadyRegistered')}
                    to={Routes.login}
                    align={Align.left}
                />
            </>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const SignupForm = Component;

Component.propTypes = {
    signup:            PropTypes.func,
    signupData:        PropTypes.object,
    updateSignupField: PropTypes.func,
};

Component.defaultProps = {
    signup:            _.noop,
    signupData:        null,
    updateSignupField: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
