//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

class Filter {
    static replaceUmlauts = (stringOrArray, simpleUmlauts = false) => {
        const simpleUmlautsMap = {
            'ä': 'a',
            'ö': 'o',
            'ü': 'u',
            'Ä': 'A',
            'Ö': 'O',
            'Ü': 'U',
        };
        const trueMap          = {
            'ä': 'ae',
            'ö': 'oe',
            'ü': 'ue',
            'Ä': 'Ae',
            'Ö': 'Oe',
            'Ü': 'Ue',
        };
        const map              = (
            simpleUmlauts ?
                simpleUmlautsMap :
                trueMap
        );

        if (_.isArray(stringOrArray)) {
            return stringOrArray.map((string) => this.replaceUmlauts(string, simpleUmlauts));
        }

        return stringOrArray.replace(/[äöüÄÖÜ]/g, (matched) => map[matched]);
    };

    static filterResults = (items, filters) => {
        const allItems = _.cloneDeep(items);

        Object.entries(filters).forEach(([key, filterValue]) => {
            const value = _.toLower(_.trim(filterValue.value));

            if (value) {
                _.remove(allItems, (item) => {
                    let itemValue;

                    if (
                        filterValue.query &&
                        filterValue.query.length
                    ) {
                        itemValue = _.map(_.get(item, filterValue.query), key);
                    } else {
                        itemValue = _.get(item, key, '');
                    }

                    if (_.isObject(itemValue)) {
                        itemValue = JSON.stringify(Object.values(itemValue));
                    }

                    const itemValueLowercase     = _.toLower(itemValue);
                    itemValue                    = this.replaceUmlauts(itemValueLowercase);
                    const itemValueSimpleUmlauts = this.replaceUmlauts(itemValueLowercase, true);

                    return (
                        !itemValue.includes(this.replaceUmlauts(value)) &&
                        !itemValueSimpleUmlauts.includes(this.replaceUmlauts(value, true))
                    );
                });
            }
        });

        return allItems || [];
    };
}

export default Filter;
