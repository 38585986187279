//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import countryList     from 'country-list';
import CCPTranslations from '@/helper/CCPTranslations';
import _               from 'lodash';

class CountryListHelper {
    static getOptions() {
        return countryList.getData().map((country) => {
            const { code, name } = CCPTranslations.countryObject(country);

            return {
                value: code,
                label: name,
            };
        });
    }

    static getCurrentCompanyCountryOption(companyAddress) {
        const { code, name } = CCPTranslations.countryObject({
            code: _.get(companyAddress, 'countryCode'),
            name: _.get(companyAddress, 'country'),
        });

        return {
            value: code,
            label: name,
        };
    }
}

export default CountryListHelper;
