//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React              from 'react';
import _                  from 'lodash';
import className          from 'classnames';
import I18n               from 'i18next';
import appStyles          from '@/styles.module.scss';
import CompanyTypes       from '@/constants/CompanyTypes';
import ComponentHelper    from '@/helper/ComponentHelper';
import Link               from '@/components/stateless/atomic/Link';
import PropTypes          from '@/components/PropTypes';
import Route              from '@/helper/Route';
import Routes             from '@/constants/Routes';
import { IconTextButton } from '@/components/stateless/atomic/IconTextButton';
import IconType           from '@/components/stateless/atomic/Icon/IconType';
import IconButtonTheme    from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import styles             from './styles.module.scss';

export class Component extends React.Component {
    partRoutes = [
        Routes.company,
        Routes.companyMachine,
        Routes.companyFactSheet,
        Routes.companyMessage,
        Routes.companyMessageDetail,
        Routes.companyProduction,
        Routes.companyProjects,
        Routes.companyProjectDetail,
        Routes.machine,
        Routes.myProfile,
        Routes.myProfileProjects,
        Routes.myProfileCurrentRole,
        Routes.myProfileVerification,
        Routes.myProfileMessages,
        Routes.myProfileJoinCompany,
        Routes.myProfileFavorites,
        Routes.searchResults,
        Routes.userManagement,
    ];

    getBreadCrumbRouteStack = () => {
        const matchingPartRoute    = this.getMatchingPartRoute();
        const breadCrumbRouteStack = [
            Routes.home,
        ];

        if (matchingPartRoute === Routes.company) {
            breadCrumbRouteStack.push(Routes.searchResults);
        }

        switch (this.props.pathname) {
            case Routes.login:
                breadCrumbRouteStack.push(Routes.login);

                break;

            case Routes.exampleAlertBox:
            case Routes.exampleBreadCrumbBar:
            case Routes.exampleCheckBox:
            case Routes.exampleCountDown:
            case Routes.exampleDebounceInput:
            case Routes.exampleImage:
            case Routes.exampleKeyDown:
            case Routes.exampleLeftRightSplitter:
            case Routes.exampleNumberFormat:
                breadCrumbRouteStack.push(Routes.examples);

                break;

            case Routes.inventoryDetails:
                breadCrumbRouteStack.push(Routes.inventory);

                break;

            case Routes.screenDesign:
                breadCrumbRouteStack.push(Routes.screenDesign);

                break;
        }

        switch (matchingPartRoute) {
            case Routes.companyMachine:
            case Routes.companyFactSheet:
            case Routes.companyMessage:
            case Routes.companyMessageDetail:
            case Routes.companyProduction:
            case Routes.companyProjects:
            case Routes.userManagement:
            case Routes.companyProjectDetail:
                breadCrumbRouteStack.push(Routes.company);

                break;
        }

        switch (matchingPartRoute) {
            case Routes.myProfileMessages:
            case Routes.myProfileProjects:
            case Routes.myProfileJoinCompany:
            case Routes.myProfileCurrentRole:
            case Routes.myProfileVerification:
            case Routes.myProfileFavorites:
                breadCrumbRouteStack.push(Routes.myProfile);

                break;
        }

        if (matchingPartRoute) {
            breadCrumbRouteStack.push(matchingPartRoute);
        } else {
            breadCrumbRouteStack.push(this.props.pathname);
        }

        return breadCrumbRouteStack;
    };

    getLabelForRoute = (route) => {
        switch (route) {
            // @formatter:off
            case Routes.about:                    return I18n.t('about');
            case Routes.adChoices:                return I18n.t('adChoices');
            case Routes.businessServices:         return I18n.t('businessServices');
            case Routes.company:                  return I18n.t('company');
            case Routes.companyMachine:           return this.getCompanyMachineLabel();
            case Routes.companyFactSheet:         return I18n.t('factSheet');
            case Routes.companyMessage:           return I18n.t('message');
            case Routes.companyProjects:          return I18n.t('projects');
            case Routes.companyProjectDetail:     return I18n.t('projects');
            case Routes.companyMessageDetail:     return I18n.t('message');
            case Routes.userManagement:           return I18n.t('userManagement');
            case Routes.companyProduction:        return I18n.t('companyProduction');
            case Routes.dataProtection:           return I18n.t('dataProtection');
            case Routes.membership:               return I18n.t('membership');
            case Routes.favorites:                return I18n.t('favorites');
            case Routes.home:                     return I18n.t('home');
            case Routes.imprint:                  return I18n.t('imprint');
            case Routes.login:                    return I18n.t('login');
            case Routes.machine:                  return I18n.t('machine');
            case Routes.screenDesign:             return I18n.t('screenDesign');
            case Routes.searchResults:            return I18n.t('searchResults');
            case Routes.signUp:                   return I18n.t('singUp');
            case Routes.myProfile:                return I18n.t('myProfile');
            case Routes.myProfileCurrentRole:     return I18n.t('currentRole');
            case Routes.myProfileVerification:    return I18n.t('userVerification');
            case Routes.myProfileJoinCompany:     return I18n.t('joinCompany');
            case Routes.myProfileProjects:        return I18n.t('projects');
            case Routes.myProfileMessages:        return I18n.t('message');
            case Routes.myProfileFavorites:       return I18n.t('favorites');
            // @formatter:on
        }

        console.error('Missing label for route:', route);

        return null;
    };

    getCompanyMachineLabel = () => {
        const companyType = _.get(this.props, 'currentCompany.types.0');

        switch (companyType) {
            case CompanyTypes.machineManufacturer:
                return I18n.t('productsAndMachines');
            case CompanyTypes.supplier:
                return I18n.t('productionMachine');
            default:
        }

        return I18n.t('companyMachine');
    };

    getMatchingPartRoute = () => {
        return _.find(this.partRoutes, (partRoute) => {
            return Route.matchesPartRoute(partRoute, this.props.pathname);
        });
    };

    render() {
        return (
            <div className={className(appStyles.defaultContainer, styles.breadCrumbBarContainer)}>
                <div className={className(appStyles.defaultInnerContainer, styles.breadCrumbBar)}>
                    {this.renderBreadCrumb()}
                </div>
            </div>
        );
    }

    renderBreadCrumb = () => {
        if (!this.props.hide) {
            const breadCrumbRouteStack = this.getBreadCrumbRouteStack();

            return (
                <ul>
                    {breadCrumbRouteStack.map(this.renderBreadCrumbRoute)}
                </ul>
            );
        }

        return null;
    };

    renderBreadCrumbRoute = (breadCrumbRoute, index) => {
        const label      = this.getLabelForRoute(breadCrumbRoute);
        const breadCrumb = (
            breadCrumbRoute === Routes.searchResults ?
                this.renderButtonLink(breadCrumbRoute, label) :
                this.renderLink(breadCrumbRoute, label)
        );

        return (
            <li key={index}>
                {breadCrumb}
            </li>
        );
    };

    renderButtonLink = (breadCrumbRoute, label) => {
        const matchingPartRoute = this.getMatchingPartRoute();

        if (
            this.props.searchResultCount.length &&
            (
                !this.partRoutes.includes(breadCrumbRoute) ||
                matchingPartRoute === Routes.company
            )
        ) {
            return (
                <span className={styles.breadCrumbButton}>
                    <IconTextButton
                        to={breadCrumbRoute}
                        text={I18n.t(label.toLowerCase())}
                        iconType={IconType.search}
                        theme={IconButtonTheme.grayBorderGrayHover}
                    />
                </span>
            );
        }

        return (
            <span>
                {label}
            </span>
        );
    };

    renderLink = (breadCrumbRoute, label) => {
        if (!this.partRoutes.includes(breadCrumbRoute)) {
            return (
                <Link to={breadCrumbRoute}>
                    {label}
                </Link>
            );
        }

        return (
            <span>
                {label}
            </span>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const BreadCrumbBar = Component;

Component.propTypes = {
    currentCompany:    PropTypes.object,
    hide:              PropTypes.bool,
    pathname:          PropTypes.string,
    searchResultCount: PropTypes.number,
};

Component.defaultProps = {
    currentCompany:    null,
    hide:              false,
    pathname:          null,
    searchResultCount: 0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
