//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }       from 'connected-react-router';
import { call }       from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { delay }      from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import I18n           from 'i18next';

import { LostPasswordActions } from '@/store/actions/lostPassword';
import { LostPasswordTypes }   from '@/store/actions/lostPassword';
import RouteHelper             from '@/helper/RouteHelper';
import { AlertBoxActions }     from '@/store/actions/alertBox';
import * as Api                from '@/api';
import Routes                  from '@/constants/Routes';
import PasswordStrength        from '@/constants/PasswordStrength';

const passwordTokenField = 'password-token';

function* requestPassword() {
    yield put(AlertBoxActions.clearAlerts());

    const lostPassword            = yield select((state) => state.lostPassword);
    const requestPasswordResponse = yield call(
        Api.requestPassword,
        lostPassword.identifier,
    );

    if (requestPasswordResponse.ok) {
        yield put(LostPasswordActions.requestPasswordSucceeded());
    } else {
        yield put(LostPasswordActions.requestPasswordFailed());
    }
}

function* requestPasswordSucceeded() {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('requestPasswordSucceeded'),
    }));
}

function* requestPasswordFailed() {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('requestPasswordFailed'),
    }));
}

function* setNewPassword() {
    yield put(AlertBoxActions.clearAlerts());

    const lostPassword                                         = yield select((state) => state.lostPassword);
    const { token, password, repeatedPassword, passwordScore } = lostPassword;
    const isPasswordSecureEnough                               = yield PasswordStrength.isPasswordSecureEnough(
        passwordScore,
        password,
        repeatedPassword,
    );

    if (!isPasswordSecureEnough) {
        return;
    }

    const requestPasswordResponse = yield call(
        Api.setNewPassword,
        token,
        password,
    );

    if (requestPasswordResponse.ok) {
        yield put(LostPasswordActions.setNewPasswordSucceeded());
    } else {
        yield put(LostPasswordActions.setNewPasswordFailed());
    }
}

function* setNewPasswordSucceeded() {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('setNewPasswordSucceeded'),
    }));
    yield delay(1500);
    yield put(push(Routes.login));
    yield put(LostPasswordActions.resetToken());
}

function* setNewPasswordFailed() {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('setNewPasswordFailed'),
    }));
}

function* restorePasswordToken() {
    const location = window.location;
    const search   = location.search;
    const query    = new URLSearchParams(search);
    const token    = query.get(passwordTokenField);

    if (!token) {
        return;
    }

    yield* RouteHelper.removeUnsecureQueryParameters();
    yield put(LostPasswordActions.setPasswordToken({
        token,
    }));
}

export const callLostPasswordSagas = () => {
    return [
        // @formatter:off
        takeLatest([LostPasswordTypes.REQUEST_PASSWORD],           requestPassword),
        takeLatest([LostPasswordTypes.REQUEST_PASSWORD_FAILED],    requestPasswordFailed),
        takeLatest([LostPasswordTypes.REQUEST_PASSWORD_SUCCEEDED], requestPasswordSucceeded),
        takeLatest([LostPasswordTypes.SET_NEW_PASSWORD],           setNewPassword),
        takeLatest([LostPasswordTypes.SET_NEW_PASSWORD_FAILED],    setNewPasswordFailed),
        takeLatest([LostPasswordTypes.SET_NEW_PASSWORD_SUCCEEDED], setNewPasswordSucceeded),
        // @formatter:on
    ];
};

export default {
    restorePasswordToken
}
