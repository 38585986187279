//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import ColorBox        from '@/components/stateless/atomic/ColorBox';
import ColorBoxColor   from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import HeadlineSmall   from '@/components/stateless/atomic/HeadlineSmall';
import ImageUpload     from '@/components/stateless/atomic/ImageUpload';
import TextInput       from '@/components/stateless/atomic/TextInput';
import File            from '@/helper/File';

import styles from './styles.module.scss';

export class Component extends React.Component {
    titleChanged = (event) => {
        const { index } = this.props;
        const value     = event.target.value;

        this.props.updateEntry(index, 'title', value);
    };

    imageChanged = (uploadedImage) => {
        const { index } = this.props;

        if (uploadedImage) {
            File
                .getFileFromBlob(uploadedImage)
                .then((image) => {
                    this.props.updateEntry(index, 'image', image);
                });
        } else {
            this.props.updateEntry(index, 'image', null);
        }
    };

    render() {
        const { product }      = this.props;
        const { title, image } = product;

        return (
            <div className={styles.editFactSheetProductWrapper}>
                <ColorBox color={ColorBoxColor.grayLight}>
                    <div className={styles.editFactSheetProductFlex}>
                        <div className={styles.editFactSheetProductInputWrapper}>
                            <HeadlineSmall text={'image'} />
                            <ImageUpload
                                images={[image]}
                                uploadFileCallback={this.imageChanged}
                            />
                        </div>
                        <div className={styles.editFactSheetProductInputWrapper}>
                            <HeadlineSmall text={'title'} />
                            <TextInput
                                value={title}
                                onChange={this.titleChanged}
                            />
                        </div>
                    </div>
                </ColorBox>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const EditFactSheetProduct = Component;

Component.propTypes = {
    index:       PropTypes.number,
    updateEntry: PropTypes.func,
    product:     PropTypes.object,
};

Component.defaultProps = {
    index:       -1,
    updateEntry: _.noop,
    product:     null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
