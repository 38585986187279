//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import TagTypeDropDown from '@/constants/TagTypeDropDown';

const buildFilterParameter = (type, ...additionalParameters) => {
    let filter = {
        type,
    };

    _.forEach(additionalParameters, (additionalParameter) => {
        filter = _.merge(filter, additionalParameter);
    });

    return filter;
};

const buildExistsFilter = (fieldName, exists) => {
    return {
        [`exists[${fieldName}]`]: exists,
    };
};

const buildTypeDropDownFilters = (existingFilters) => {
    const tagTypeDropDown = Object.values(TagTypeDropDown);
    const filtersToAdd    = tagTypeDropDown.filter((filter) => !_.has(existingFilters, filter));
    let filterObject      = {
        ...existingFilters,
    };

    _.forEach(filtersToAdd, (filter) => {
        filterObject = {
            ...filterObject,
            [filter]: buildFilterParameter(
                filter,
                buildExistsFilter('parentTag', true),
            ),
        };
    });

    return filterObject;
};

const hasParentTag       = () => buildExistsFilter('parentTag', true);
const hasNoParentTag     = () => buildExistsFilter('parentTag', false);
const externalIdentifier = (identifier) => (
    {
        'parentTag.externalIdentifier': identifier,
    }
);
const order              = (field, direction = 'asc') => (
    {
        [`order[${field}]`]: direction,
    }
);

const tagTypeDropDownFilter = buildTypeDropDownFilters({
    [TagTypeDropDown.commodities]:      buildFilterParameter(
        TagTypeDropDown.process,
        hasNoParentTag(),
    ),
    [TagTypeDropDown.processMachining]: buildFilterParameter(
        TagTypeDropDown.process,
        hasParentTag(),
        externalIdentifier('Mach1'),
    ),
    [TagTypeDropDown.processCasting]:   buildFilterParameter(
        TagTypeDropDown.process,
        hasParentTag(),
        externalIdentifier('Cast1'),
    ),
    [TagTypeDropDown.industry]:         buildFilterParameter(
        TagTypeDropDown.industry,
        hasParentTag(),
        order('title'),
    ),
    [TagTypeDropDown.process]:          buildFilterParameter(
        TagTypeDropDown.process,
        hasParentTag(),
    ),
    [TagTypeDropDown.processRoot]:      buildFilterParameter(
        TagTypeDropDown.process,
        hasNoParentTag(),
    ),
});

export default tagTypeDropDownFilter;
