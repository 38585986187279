//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import _               from 'lodash';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import classNames      from 'classnames';
import { Icon }        from '@/components/stateless/atomic/Icon';
import FileDownloader  from '@/helper/FileDownloader';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    handleDownloadCertificateClicked = () => {
        const tagCertificate = _.get(this.props, 'tagCertificate');
        const title          = _.get(tagCertificate, 'title');
        const { path, name } = _.get(tagCertificate, 'file');

        FileDownloader.downloadFile(path, name, title);
    };

    render() {
        return (
            <div
                onClick={this.handleDownloadCertificateClicked}
                className={classNames(
                    styles.tagCertificate,
                    {
                        [styles.noDownload]: this.props.tagCertificate === null,
                    },
                )}
            >
                <Icon
                    iconType={IconType.download}
                />
                <span className={styles.tagTitle}>
                    {this.props.tag.title}
                </span>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const TagCertificate = Component;

Component.propTypes = {
    tag:            PropTypes.object,
    tagCertificate: PropTypes.object,
};

Component.defaultProps = {
    tag:            null,
    tagCertificate: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
