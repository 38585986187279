//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React          from 'react';
import classNames     from 'classnames';
import IconType       from '@/components/stateless/atomic/Icon/IconType';
import Link           from '@/components/stateless/atomic/Link';
import PropTypes      from '@/components/PropTypes';
import { IconButton } from '@/components/stateless/atomic/IconButton';
import styles         from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <ul className={styles.paginationWrapper}>
                <li className={styles.paginationNextPrev}>
                    {this.renderPrevArrow()}
                </li>
                {this.renderNumbers()}
                <li className={styles.paginationNextPrev}>
                    {this.renderNextArrow()}
                </li>
            </ul>
        );
    }

    changeLocation = (number) => () => {
        this.props.history.push({
            pathname: this.props.route,
            search:   `?page=${number}`,
        });
    };

    renderNumbers = () => {
        const numbers = [];

        for (let i = 0; i < this.props.pagesCount; i++) {
            numbers.push(this.renderPageNumber(i + 1, i));
        }

        return numbers;
    };

    renderPageNumber = (number, index) => {
        return (
            <li
                className={classNames(
                    styles.paginationNumber,
                    {
                        [styles.paginationNumberActive]: number === this.props.currentPage,
                    },
                )}
                key={`pagination-${index}`}
            >
                <Link
                    className={styles.paginationLink}
                    onClick={this.changeLocation(number)}
                >
                    {number}
                </Link>
            </li>
        );
    };

    renderNextArrow = () => {
        const pageNumber = this.props.currentPage + 1;

        if (pageNumber <= this.props.pagesCount) {
            return (
                <IconButton
                    iconType={IconType.caretRight}
                    onClick={this.changeLocation(pageNumber)}
                />
            );
        }

        return null;
    };

    renderPrevArrow = () => {
        const pageNumber = this.props.currentPage - 1;

        if (pageNumber > 0) {
            return (
                <IconButton
                    iconType={IconType.caretLeft}
                    onClick={this.changeLocation(pageNumber)}
                />
            );
        }

        return null;
    };
}

export const Pagination = Component;

Component.propTypes = {
    currentPage: PropTypes.number.isRequired,
    history:     PropTypes.object,
    pagesCount:  PropTypes.number.isRequired,
    route:       PropTypes.string.isRequired,
};

Component.defaultProps = {
    currentPage: 1,
    history:     null,
    pagesCount:  1,
    route:       null,
};

export default Component;
