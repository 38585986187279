//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import _                   from 'lodash';
import ColorButton         from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme    from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import PropTypes           from '@/components/PropTypes';
import { BoldTranslation } from '@/components/stateless/composed/BoldTranslation';
import Pages               from '@/constants/Pages';
import styles              from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { cookiesAccepted } = this.props;

        if (cookiesAccepted === null) {
            const { cookiesAcceptedClicked, cookiesRejectedClicked } = this.props;

            return (
                <div className={styles.magicBanner}>
                    <div className={styles.magicBannerText}>
                        <h3>
                            {I18n.t('cookieBanner.title')}
                        </h3>
                        <p>
                            <BoldTranslation
                                translationKey={'cookieBanner.text'}
                                components={{
                                    privacyLink:  <a href={Pages.privacy} />,
                                    settingsLink: <a href={'/cookies=true'} />,
                                }}
                            />
                        </p>
                    </div>
                    <div className={styles.magicBannerButtons}>
                        <ColorButton
                            text={I18n.t('cookieBanner.buttonAccept')}
                            theme={ColorButtonTheme.orange}
                            onClick={cookiesAcceptedClicked}
                        />
                        <ColorButton
                            text={I18n.t('cookieBanner.buttonReject')}
                            theme={ColorButtonTheme.white}
                            onClick={cookiesRejectedClicked}
                        />
                    </div>
                </div>
            );
        }

        return null;
    }
}

export const CookieBanner = Component;

Component.propTypes = {
    cookiesAccepted:        PropTypes.bool,
    cookiesAcceptedClicked: PropTypes.func,
    cookiesRejectedClicked: PropTypes.func,
};

Component.defaultProps = {
    cookiesAccepted:        null,
    cookiesAcceptedClicked: _.noop,
    cookiesRejectedClicked: _.noop,
};

export default Component;
