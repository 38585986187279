//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update              from 'immutability-helper';
import _                   from 'lodash';
import { NavigationTypes } from '@/store/actions/navigation';
import { UserTypes }       from '@/store/actions/user';
import { CompanyTypes }    from '@/store/actions/company';

const initialState = {
    actsPrivate:             false,
    workingAtCompany:        null,
    position:                null,
    company:                 null,
    email:                   '',
    lastLogin:               null,
    loginIdentifier:         '',
    loginPassword:           '',
    name:                    '',
    token:                   null,
    userId:                  '',
    username:                '',
    favoriteCompanies:       [],
    joinRequests:            [],
    interests:               [],
    userVerificationRequest: {
        identityCard: null,
        businessCard: null,
    },
};

const loginSucceeded = (action, state) => {
    return update(state, {
        actsPrivate:       {
            $set: action.actsPrivate,
        },
        workingAtCompany:  {
            $set: action.workingAtCompany,
        },
        position:          {
            $set: action.position,
        },
        phone:             {
            $set: action.phone,
        },
        country:           {
            $set: action.country,
        },
        company:           {
            $set: action.company,
        },
        email:             {
            $set: action.email,
        },
        lastLogin:         {
            $set: action.lastLogin ? action.lastLogin.date : null,
        },
        loginIdentifier:   {
            $set: '',
        },
        loginPassword:     {
            $set: '',
        },
        name:              {
            $set: action.name,
        },
        token:             {
            $set: action.token,
        },
        userId:            {
            $set: action.userId,
        },
        username:          {
            $set: action.username,
        },
        favoriteCompanies: {
            $set: action.favoriteCompanies,
        },
        joinRequests:      {
            $set: action.joinRequests,
        },
    });
};

const logout = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const setLoginPassword = (action, state) => {
    return update(state, {
        loginPassword: {
            $set: action.password,
        },
    });
};

const setLoginIdentifier = (action, state) => {
    return update(state, {
        loginIdentifier: {
            $set: action.identifier,
        },
    });
};

const setJoinRequests = (action, state) => {
    return update(state, {
        joinRequests: {
            $set: action.joinRequests,
        },
    });
};

const setCompanyOnCreate = (action, state) => {
    return update(state, {
        company: {
            $set: _.get(action, 'company.id', null),
        },
    });
};

const actPrivate = (action, state) => {
    return update(state, {
        actsPrivate:      {
            $set: true,
        },
        workingAtCompany: {
            $set: action.companyName,
        },
        position:         {
            $set: action.position,
        },
    });
};

const setActPrivateChecked = (action, state) => {
    const checked = _.get(action, 'checked', false);

    return update(state, {
        actPrivateChecked: {
            $set: checked,
        },
    });
};

const fetchSucceeded = (action, state) => {
    return update(state, {
        actsPrivate:             {
            $set: action.actsPrivate,
        },
        workingAtCompany:        {
            $set: action.workingAtCompany,
        },
        position:                {
            $set: action.position,
        },
        company:                 {
            $set: action.company,
        },
        userCompany:             {
            $set: action.userCompany,
        },
        email:                   {
            $set: action.email,
        },
        lastLogin:               {
            $set: action.lastLogin ? action.lastLogin.date : null,
        },
        loginIdentifier:         {
            $set: '',
        },
        loginPassword:           {
            $set: '',
        },
        name:                    {
            $set: action.name,
        },
        timezone:                {
            $set: action.timezone,
        },
        imageString:             {
            $set: action.imageString,
        },
        userId:                  {
            $set: action.userId,
        },
        username:                {
            $set: action.username,
        },
        favoriteCompanies:       {
            $set: action.favoriteCompanies,
        },
        joinRequests:            {
            $set: action.joinRequests,
        },
        country:                 {
            $set: action.country,
        },
        interests:               {
            $set: action.interests,
        },
        preferredLanguage:       {
            $set: action.preferredLanguage,
        },
        phone:                   {
            $set: action.phone,
        },
        image:                   {
            $set: action.image,
        },
        userVerificationRequest: {
            $set: _.get(action, 'userVerificationRequest') ?? initialState.userVerificationRequest,
        },
    });
};

const updateUserVerificationImage = (field) => {
    return (action, state) => {
        const userVerificationRequest    = _.get(state, 'userVerificationRequest', initialState.userVerificationRequest);
        const newUserVerificationRequest = {
            ...userVerificationRequest,
            [field]: _.get(action, field, initialState.userVerificationRequest[field]),
        };

        return update(state, {
            userVerificationRequest: {
                $set: newUserVerificationRequest,
            },
        });
    };
};

const setBusinessCardImage = (action, state) => {
    return updateUserVerificationImage('businessCard')(action, state);
};

const setIDCardImage = (action, state) => {
    return updateUserVerificationImage('identityCard')(action, state);
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case UserTypes.LOGIN_SUCCEEDED:                   return loginSucceeded(action, state);
        case NavigationTypes.RESET_STATE:
        case UserTypes.LOGOUT:                            return logout(action, state);
        case UserTypes.SET_LOGIN_IDENTIFIER:              return setLoginIdentifier(action, state);
        case UserTypes.SET_LOGIN_PASSWORD:                return setLoginPassword(action, state);
        case UserTypes.SET_JOIN_REQUESTS:                 return setJoinRequests(action, state);
        case UserTypes.FETCH_SUCCEEDED:                   return fetchSucceeded(action, state);
        case CompanyTypes.CREATE_COMPANY_SUCCEEDED:       return setCompanyOnCreate(action, state);
        case CompanyTypes.ACT_PRIVATE_SUCCEEDED:          return actPrivate(action, state);
        case UserTypes.SET_ACT_PRIVATE_CHECKED:           return setActPrivateChecked(action, state);
        case UserTypes.SET_BUSINESS_CARD_IMAGE:           return setBusinessCardImage(action, state);
        case UserTypes.SET_ID_CARD_IMAGE:                 return setIDCardImage(action, state);
        default:                                          return state;
        // @formatter:on
    }
}
