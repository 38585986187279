//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import _                         from 'lodash';
import { connect }               from 'react-redux';
import { bindActionCreators }    from 'redux';
import { compose }               from 'redux';
import { goBack }                from 'connected-react-router';
import I18n                      from 'i18next';
import ComponentHelper           from '@/helper/ComponentHelper';
import PropTypes                 from '@/components/PropTypes';
import { MessageRequestActions } from '@/store/actions/messageRequest';
import { RequestMessageTags }    from '@/components/stateless/composed/RequestMessageTags';
import RequestMessageForm        from '@/components/stateless/composed/RequestMessageForm';
import Spacer                    from '@/components/stateless/atomic/Spacer';
import { MessageRequestService } from '@/components/stateless/composed/MessageRequestService';
import StateHelper               from '@/helper/State';
import Navigation                from '@/helper/Navigation';
import ViewingContext            from '@/constants/ViewingContext';

export class Component extends React.Component {
    updateField = (field) => (event, index = null) => {
        const value = _.get(event, 'target.value', event);

        this.props.editMessageRequestField({
            field,
            value,
            index,
        });
    };

    onSend = () => {
        const { viewingContext } = this.props;

        this.props.sendMessageRequest({
            viewingContext,
        });
    };

    onCancel = () => {
        const props = this.props;

        props.reset();
        props.goBack();
    };

    addEntryTag = () => {
        this.props.addMessageRequestTag();
    };

    updateTag = (index, tag) => {
        this.props.updateMessageRequestTag({
            index,
            tag,
        });
    };

    removeTag = (index) => {
        this.props.removeMessageRequestTag({
            index,
        });
    };

    render() {
        const { targetCompany, messageRequest }         = this.props;
        const { message, subject, images, attachments } = messageRequest;

        return (
            <>
                <RequestMessageTags
                    tags={_.get(targetCompany, 'allRelatedTags', [])}
                    values={_.get(messageRequest, 'tags', [])}
                    addEntry={this.addEntryTag}
                    updateEntry={this.updateTag}
                    removeEntry={this.removeTag}
                    collapsed={!!messageRequest.machine}
                />
                <MessageRequestService
                    messageRequest={messageRequest}
                    collapsable={true}
                    field={'portfolioProduct'}
                    sectionTitle={I18n.t('product')}
                />
                <MessageRequestService
                    messageRequest={messageRequest}
                    collapsable={true}
                    collapsed={!messageRequest.machine}
                    field={'machine'}
                    sectionTitle={I18n.t('machine')}
                />
                <Spacer height={15} />
                <RequestMessageForm
                    onSend={this.onSend}
                    onCancel={this.onCancel}
                    message={message}
                    subject={subject}
                    onMessageChanged={this.updateField('message')}
                    onSubjectChanged={this.updateField('subject')}
                    images={images}
                    attachments={attachments}
                    onImageUpdate={this.updateField('images')}
                    onAttachmentUpdate={this.updateField('attachments')}
                />
            </>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const MessageRequest = Component;

Component.propTypes = {
    addMessageRequestTag:    PropTypes.func,
    addTag:                  PropTypes.func,
    deleteTag:               PropTypes.func,
    editMessageRequestField: PropTypes.func,
    goBack:                  PropTypes.func,
    messageRequest:          PropTypes.object,
    removeMessageRequestTag: PropTypes.func,
    reset:                   PropTypes.func,
    sendMessageRequest:      PropTypes.func,
    targetCompany:           PropTypes.object,
    updateMessageRequestTag: PropTypes.func,
    viewingContext:          PropTypes.oneOfObjectValues(ViewingContext),
};

Component.defaultProps = {
    addMessageRequestTag:    _.noop,
    addTag:                  _.noop,
    deleteTag:               _.noop,
    editMessageRequestField: _.noop,
    goBack:                  _.noop,
    messageRequest:          null,
    removeMessageRequestTag: _.noop,
    reset:                   _.noop,
    sendMessageRequest:      _.noop,
    targetCompany:           null,
    updateMessageRequestTag: _.noop,
    viewingContext:          ViewingContext.UNDEFINED,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...MessageRequestActions,
        goBack,
    },
    dispatch,
);

const mapStateToProps = (state, ownProps) => {
    const targetCompany  = StateHelper.getCompanyObject(state, StateHelper.getCompanyId(state));
    const viewingContext = Navigation.getViewingContext(ownProps);

    return {
        viewingContext,
        messageRequest: _.get(state, 'messageRequest'),
        targetCompany,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
