//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import PropTypes              from '@/components/PropTypes';
import { getColorByCapacity } from '@/constants/MachineCapacity';
import { getTextByCapacity }  from '@/constants/MachineCapacity';
import MachineCapacity        from '@/constants/MachineCapacity';
import classNames             from 'classnames';
import ComponentHelper        from '@/helper/ComponentHelper';
import styles                 from './styles.module.scss';

export class Component extends React.Component {
    getDotColorByCapacity = () => {
        return {
            backgroundColor: getColorByCapacity(this.props.capacity),
        };
    };

    render() {
        const textByCapacity = getTextByCapacity(this.props.capacity);

        if (!textByCapacity) {
            return null;
        }

        return (
            <div
                className={classNames(
                    styles.machineCapacityStatus,
                    {
                        [styles.disabled]: this.props.disabled,
                    },
                )}
            >
                <div
                    className={styles.machineCapacityStatusDot}
                    style={this.getDotColorByCapacity()}
                />
                <span className={styles.machineCapacityStatusText}>
                    {textByCapacity}
                </span>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const MachineCapacityStatus = Component;

Component.propTypes = {
    capacity: PropTypes.machineCapacity,
    disabled: PropTypes.bool,
};

Component.defaultProps = {
    capacity: MachineCapacity.free,
    disabled: false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
