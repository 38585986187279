//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import _                from 'lodash';
import I18n             from 'i18next';
import * as Api         from '@/api';
import AddNewMachineCTA from '@/components/connected/AddNewMachineCTA';
import ApiMode          from '@/constants/ApiMode';
import ColorBox         from '@/components/stateless/atomic/ColorBox';
import ColorButton      from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import ComponentHelper  from '@/helper/ComponentHelper';
import MachinePreview   from '@/components/stateless/atomic/MachinePreview';
import FormRow          from '@/components/stateless/composed/FormRow';
import HeadlineMedium   from '@/components/stateless/atomic/HeadlineMedium';
import Icon             from '@/components/stateless/atomic/Icon';
import IconType         from '@/components/stateless/atomic/Icon/IconType';
import Overlay          from '@/components/connected/Overlay';
import Overlays         from '@/constants/Overlays';
import OverlayType      from '@/components/connected/Overlay/OverlayType';
import PropTypes        from '@/components/PropTypes';
import TextInput        from '@/components/stateless/atomic/TextInput';
import styles           from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searched:    false,
            searchQuery: null,
        };
    }

    onSearchQueryChange = (event) => {
        this.setState({
            searched:    false,
            searchQuery: event.target.value,
        });
    };

    onSearchClicked = () => {
        this.props.onMachineSearch(this.state.searchQuery);
        this.setState({
            searched: true,
        });
    };

    render() {
        return (
            <Overlay
                id={Overlays.addMachine}
                okButtonPressed={this.props.okButtonPressed}
                okButtonText={I18n.t('save')}
                overlayType={OverlayType.full}
                disableCloseOnOutsideClick={true}
            >
                <ColorBox>
                    {this.renderHeader()}
                    {this.renderBody()}
                </ColorBox>
            </Overlay>
        );
    }

    renderHeader = () => {
        return (
            <div className={styles.addNewMachineHeaderContainer}>
                <HeadlineMedium text={I18n.t('searchAndSelectYourMachineTitle')} />
                <p>
                    {I18n.t('searchAndSelectYourMachineText')}
                </p>
            </div>
        );
    };

    renderBody = () => {
        return (
            <div className={styles.addNewMachineBodyContainer}>
                <div className={styles.addNewMachineSearchContainer}>
                    <TextInput
                        value={this.state.searchQuery}
                        placeholder={I18n.t('searchExistingMachinePlaceholder')}
                        onChange={this.onSearchQueryChange}
                    />
                    {this.renderButton()}
                </div>
                {this.renderResults()}
            </div>
        );
    };

    renderButton = () => {
        let text = I18n.t('search');

        if (this.props.isLoading) {
            text = (
                <div className={styles.loadingIndicator}>
                    <Icon iconType={IconType.spinner} />
                </div>
            );
        }

        return (
            <ColorButton
                text={text}
                theme={ColorButtonTheme.orange}
                onClick={this.onSearchClicked}
                disabled={!this.state.searchQuery}
            />
        );
    };

    renderResults = () => {
        let machines;

        if (this.props.machines.length) {
            machines = (
                <>
                    {this.renderMachines()}
                    <div className={styles.addNewMachineResultEmptyContainer}>
                        <AddNewMachineCTA
                            name={this.state.searchQuery}
                            apiMode={ApiMode.create}
                        />
                    </div>
                </>
            );
        } else if (this.state.searched && !this.props.isLoading) {
            machines = (
                <div className={styles.addNewMachineResultEmptyContainer}>
                    <AddNewMachineCTA
                        name={this.state.searchQuery}
                        apiMode={ApiMode.create}
                    />
                </div>
            );
        } else {
            return null;
        }

        return (
            <FormRow label={I18n.t('results')}>
                <div className={styles.addNewMachineResultContainer}>
                    {machines}
                </div>
            </FormRow>
        );
    };

    renderMachine = (machine) => {
        const machineId  = _.get(machine, 'id');
        const machineIri = _.get(machine, 'iri');

        return (
            <MachinePreview
                key={`image-upload-${machineId}`}
                name={_.get(machine, 'name')}
                machineId={machineIri}
                image={Api.getImagePath(_.get(machine, 'image.path'))}
                parameters={_.get(machine, 'parameters')}
                manufacturer={_.get(machine, 'manufacturer')}
                onClick={this.props.onMachineClicked}
                selectedMachineId={this.props.selectedMachineId}
            />
        );
    };

    renderMachines = () => {
        return this.props.machines.map(this.renderMachine);
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AddNewMachineOverlay = Component;

Component.propTypes = {
    closeButtonPressed: PropTypes.func,
    isLoading:          PropTypes.bool,
    machines:           PropTypes.array,
    okButtonPressed:    PropTypes.func,
    onMachineClicked:   PropTypes.func,
    onMachineSearch:    PropTypes.func,
    selectedMachineId:  PropTypes.string,
};

Component.defaultProps = {
    closeButtonPressed: _.noop,
    isLoading:          false,
    machines:           [],
    okButtonPressed:    _.noop,
    onMachineClicked:   _.noop,
    onMachineSearch:    _.noop,
    selectedMachineId:  null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'searched',
    'searchQuery',
];

export default Component;
