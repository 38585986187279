//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';

export class Component extends React.Component {
    render() {
        const props = this.props;
        let percent = props.percent;

        if (!_.isFinite(percent)) {
            percent = 0;
        }

        return (
            <span>
                {percent.toFixed(props.percentFractionDigits)}
                {'%'}
            </span>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const PercentageLabel = Component;

Component.propTypes = {
    percent:               PropTypes.number,
    percentFractionDigits: PropTypes.number,
};

Component.defaultProps = {
    percent:               0,
    percentFractionDigits: 0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
