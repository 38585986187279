//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Focus        from '@/helper/Focus';
import React        from 'react';
import _            from 'lodash';
import Select       from 'react-select';
import { Trans }    from 'react-i18next';
import WindowHelper from '@/helper/Window';

import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import RoundDropDownCaretDown from '@/components/stateless/atomic/RoundDropDownCaretDown';

import colors from '@/styles/colors.module.scss';
import Ids    from '@/constants/Ids';
import Device from '@/helper/Device';
import styles from './styles.module.scss';

const DropdownIndicator = () => {
    return (
        <RoundDropDownCaretDown />
    );
};

const ReducedDropdownIndicator = () => {
    return (
        <RoundDropDownCaretDown isReduced={true} />
    );
};

export class Component extends React.Component {
    customStyles = {
        container:           (provided, state) => {
            const { menuIsOpen }    = this.state;
            const menuListReference = this.selectReference?.current?.select?.menuListRef;
            const value             = state.getValue();
            const labelLength       = _.get(value, '0.label.length', 0);
            let width               = 'auto';
            let minWidth            = 0;

            if (labelLength > 0) {
                minWidth = labelLength * 10;
            }

            if (minWidth <= 250) {
                minWidth = 250;
            }

            if (menuIsOpen) {
                const menuClientWidth = menuListReference?.clientWidth;
                width                 = menuClientWidth + 2;
            }

            return {
                ...provided,
                height: 38,
                width,
                minWidth,
            };
        },
        control:             (provided, state) => {
            const borderRadius     = 15;
            const borderColor      = (
                state.isFocused
                    ? colors.colorOrange
                    : colors.colorOrangeLighter
            );
            let borderRadiusBottom = (
                state.menuIsOpen
                    ? 0
                    : borderRadius
            );
            let borderBottomWidth  = (
                state.menuIsOpen
                    ? 0
                    : 1
            );
            const backgroundColor  = (
                state.isFocused
                    ? colors.colorOrangeLighter1
                    : colors.colorOrangeLighter
            );

            if (this.props.textBelow) {
                borderRadiusBottom = 0;
                borderBottomWidth  = 0;
            }

            return {
                ...provided,
                height:                  30,
                borderRadius:            0,
                borderTopLeftRadius:     borderRadius,
                borderTopRightRadius:    borderRadius,
                borderBottomLeftRadius:  borderRadiusBottom,
                borderBottomRightRadius: borderRadiusBottom,
                backgroundColor,
                transition:              'border-color 0.1s linear',
                borderWidth:             1,
                borderColor:             `${borderColor} !important`,
                alignItems:              'center',
                boxShadow:               'none',
                cursor:                  'pointer',
                borderBottomWidth,
            };
        },
        indicatorsContainer: () => (
            {
                marginRight: 20,
            }
        ),
        indicatorSeparator:  () => (
            {
                display: 'none',
            }
        ),
        menu:                (provided) => {
            const dropdownBounds = this.dropdownReference.current ? this.dropdownReference.current.getBoundingClientRect() : {
                top:    0,
                height: 0,
            };
            const topPosition    = dropdownBounds.top + dropdownBounds.height;
            const containerWidth = Math.ceil(dropdownBounds.width);

            return {
                ...provided,
                position:             'fixed',
                top:                  topPosition,
                width:                'auto',
                minWidth:             containerWidth,
                borderTopLeftRadius:  0,
                borderTopRightRadius: 0,
                borderWidth:          1,
                borderRadius:         15,
                borderTopWidth:       0,
                borderColor:          colors.colorOrange,
                borderStyle:          'solid',
                overflow:             'hidden',
                boxShadow:            'none',
                margin:               0,
            };
        },
        menuList:            (provided) => (
            {
                ...provided,
                paddingBottom: 0,
                paddingTop:    0,
            }
        ),
        option:              (provided, state) => {
            const backgroundColor = (
                state.isFocused ?
                    `${colors.colorOrangeLighter} !important` :
                    'transparent'
            );

            return {
                ...provided,
                borderWidth:    0,
                borderTopWidth: 1,
                borderStyle:    'solid',
                borderColor:    colors.colorGray,
                cursor:         'pointer',
                fontSize:       14,
                display:        state.isDisabled ? 'none' : 'block',
                color:          colors.colorBlack,
                fontWeight:     state.isSelected ? 800 : 400,
                boxSizing:      'border-box',
                height:         28,
                padding:        '4px 12px',
                whiteSpace:     'no-wrap',
                overflow:       'hidden',
                textOverflow:   'ellipsis',
                background:     backgroundColor,
            };
        },
        placeholder:         (provided) => (
            {
                ...provided,
                color:      colors.colorBlack,
                fontSize:   14,
                fontWeight: 800,
            }
        ),
        singleValue:         (provided) => (
            {
                ...provided,
                position:   'relative',
                top:        'inherit',
                lineHeight: '30px',
                transform:  'none',
                color:      colors.colorBlack,
                fontSize:   14,
                fontWeight: 800,
            }
        ),
        valueContainer:      (provided) => (
            {
                ...provided,
                paddingLeft: 12,
                fontSize:    14,
                width:       '100%',
                flexWrap:    'nowrap',
            }
        ),
    };

    constructor(props) {
        super(props);

        this.skipAutoScroll    = false;
        this.dropdownReference = React.createRef();
        this.selectReference   = React.createRef();
        this.state             = {
            menuIsOpen: props.initialOpened,
        };
    }

    renderBelowText = () => {
        const { textBelow } = this.props;

        if (!textBelow) {
            return null;
        }

        return (
            <Trans>
                <div className={styles.belowText}>
                    {textBelow}
                </div>
            </Trans>
        );
    };

    onScroll = (event) => {
        if (
            event.target === window.document &&
            !this.skipAutoScroll
        ) {
            this.closeMenu();
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, true);
    }

    componentDidUpdate(prevProps, prevState) {
        const { menuIsOpen } = this.state;

        if (menuIsOpen !== prevState.menuIsOpen) {
            this.props.selectIsFocused(menuIsOpen);
        }
    }

    getCustomComponents = () => {
        if (this.props.selectionNotChangeable) {
            return {
                DropdownIndicator: null,
            };
        }

        if (
            this.props.isReduced &&
            this.state.menuIsOpen
        ) {
            return {
                DropdownIndicator: ReducedDropdownIndicator,
            };
        }

        return {
            DropdownIndicator,
        };
    };

    isOptionDisabled = (option) => {
        return (
            option.disabled ||
            (
                this.props.optionIsLocked &&
                option === this.props.selectedOption
            )
        );
    };

    onClickOutside = (event) => {
        const dropdownReference = this.dropdownReference;

        if (
            dropdownReference &&
            dropdownReference.current &&
            !dropdownReference.current.contains(event.target)
        ) {
            this.closeMenu();
        }
    };

    closeMenu = () => {
        document.removeEventListener('mousedown', this.onClickOutside);
        Focus.blurActiveElement();
        this.setState({
            menuIsOpen: false,
        });
    };

    // eslint-disable-next-line no-unused-vars
    openMenu = (event = null, skipScrollCheck = false) => {
        if (!skipScrollCheck) {
            this.setState(
                {
                    menuIsOpen: true,
                },
                this.menuOpenedWithScrollFix,
            );
        } else {
            this.setState(
                {
                    menuIsOpen: true,
                },
                this.menuOpened,
            );
        }
    };

    getFirstParentMatch = (element, selector) => {
        let newElement       = element;
        const isMatch        = element.matches(selector);
        const hasParentMatch = element.nodeName.toLowerCase() !== 'html';

        if (!isMatch && hasParentMatch) {
            newElement = this.getFirstParentMatch(element.parentNode, selector);
        }

        return newElement;
    };

    scrollFixCallback = (isNotScrollable = false) => {
        this.openMenu(null, true);

        if (isNotScrollable) {
            const menuListReference           = this.selectReference.current.select.menuListRef;
            menuListReference.style.maxHeight = WindowHelper.getMaxHeightNoOverflow(menuListReference);
        }

        this.skipAutoScroll = false;
    };

    menuOpened = () => {
        document.addEventListener('mousedown', this.onClickOutside);
    };

    menuOpenedWithScrollFix = () => {
        this.menuOpened();

        const menuListReference = this.selectReference.current.select.menuListRef;

        if (menuListReference) {
            const windowOverflow = WindowHelper.getWindowOverflow(menuListReference);

            if (windowOverflow > 0) {
                const scrollParent  = this.getFirstParentMatch(menuListReference, `#${Ids.modalContent}`);
                this.skipAutoScroll = true;

                this.closeMenu();
                WindowHelper.scrollAdd(
                    windowOverflow + 50, // +50 because we want a little padding at the bottom.
                    scrollParent,
                    this.scrollFixCallback,
                );
            }
        }
    };

    onChange = (event) => {
        this.props.onChange(event);
        this.closeMenu();
    };

    render() {
        return (
            <div
                className={styles.roundDropDownWrapper}
                ref={this.dropdownReference}
            >
                <Select
                    components={{
                        ...this.getCustomComponents(),
                    }}
                    options={this.props.options}
                    styles={this.customStyles}
                    value={this.props.selectedOption}
                    onChange={this.onChange}
                    isOptionDisabled={this.isOptionDisabled}
                    ref={this.selectReference}
                    onFocus={this.openMenu}
                    menuIsOpen={this.state.menuIsOpen}
                    isSearchable={!Device.isIOS}
                    isDisabled={this.props.disabled}
                />
                {this.renderBelowText()}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const RoundDropDown = Component;

Component.propTypes = {
    disabled:                PropTypes.bool,
    isReduced:               PropTypes.bool,
    onChange:                PropTypes.func,
    optionIsLocked:          PropTypes.bool,
    options:                 PropTypes.array,
    overwriteSelectedOption: PropTypes.object,
    selectedOption:          PropTypes.object,
    selectionNotChangeable:  PropTypes.bool,
    selectIsFocused:         PropTypes.func,
    textBelow:               PropTypes.string,
    initialOpened:           PropTypes.bool,
};

Component.defaultProps = {
    disabled:                false,
    isReduced:               false,
    onChange:                _.noop,
    optionIsLocked:          false,
    options:                 [],
    overwriteSelectedOption: null,
    selectedOption:          null,
    selectionNotChangeable:  false,
    selectIsFocused:         _.noop,
    textBelow:               '',
    initialOpened:           false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'menuIsOpen',
];

export default Component;
