//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import _                          from 'lodash';
import { bindActionCreators }     from 'redux';
import { compose }                from 'redux';
import { connect }                from 'react-redux';
import AddMachineOverlayStateless from '@/components/stateless/composed/AddMachineOverlay';
import ComponentHelper            from '@/helper/ComponentHelper';
import PropTypes                  from '@/components/PropTypes';
import { CompanyActions }         from '@/store/actions/company';
import { SearchActions }          from '@/store/actions/search';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedMachineId: null,
        };
    }

    onCloseButtonPressed = () => {
        this.props.cancelEditOwnCompany();
    };

    okButtonPressed = () => {
        this.props.addOwnCompanyMachine({
            machineIri: this.state.selectedMachineId,
        });
    };

    onMachineClicked = (machineId) => () => {
        this.setState({
            selectedMachineId: machineId,
        });
    };

    onMachineSearch = (searchQuery) => {
        this.props.clearSearchResults();
        this.props.search({
            query:       searchQuery,
            indices:     ['machines'],
            otherSearch: true,
        });
    };

    render() {
        return (
            <AddMachineOverlayStateless
                closeButtonPressed={this.onCloseButtonPressed}
                onMachineSearch={this.onMachineSearch}
                machines={this.props.machines}
                onMachineClicked={this.onMachineClicked}
                selectedMachineId={this.state.selectedMachineId}
                okButtonPressed={this.okButtonPressed}
                isLoading={this.props.isLoading}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AddNewMachineOverlay = Component;

Component.propTypes = {
    addOwnCompanyMachine: PropTypes.func,
    cancelEditOwnCompany: PropTypes.func,
    clearSearchResults:   PropTypes.func,
    editOwnCompany:       PropTypes.func,
    isLoading:            PropTypes.bool,
    machines:             PropTypes.array,
    search:               PropTypes.func,
};

Component.defaultProps = {
    addOwnCompanyMachine: _.noop,
    cancelEditOwnCompany: _.noop,
    clearSearchResults:   _.noop,
    editOwnCompany:       _.noop,
    isLoading:            false,
    machines:             [],
    search:               _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'selectedMachineId',
];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...SearchActions,
        ...CompanyActions,
    },
    dispatch,
);

const mapStateToProps = (state) => (
    {
        isLoading: !!_.get(state, 'search.isLoading', false),
        machines:  _.get(state, 'search.items.machines'),
    }
);

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
