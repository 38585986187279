//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const PartnerTypes = {
    FETCH_PARTNER:           'Partner/FETCH_PARTNER',
    FETCH_PARTNER_FAILED:    'Partner/FETCH_PARTNER_FAILED',
    FETCH_PARTNER_SUCCEEDED: 'Partner/FETCH_PARTNER_SUCCEEDED',
};

const fetchPartners = makeActionCreator(
    PartnerTypes.FETCH_PARTNER,
    {},
);

const fetchPartnersFailed = makeActionCreator(
    PartnerTypes.FETCH_PARTNER_FAILED,
    {},
);

const fetchPartnersSucceeded = makeActionCreator(
    PartnerTypes.FETCH_PARTNER_SUCCEEDED,
    {
        partner: null,
    },
);

export const PartnerActions = {
    fetchPartners,
    fetchPartnersFailed,
    fetchPartnersSucceeded,
};
