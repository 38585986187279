//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                    from 'react';
import _                        from 'lodash';
import { connect }              from 'react-redux';
import { withRouter }           from 'react-router';
import ComponentHelper          from '@/helper/ComponentHelper';
import PropTypes                from '@/components/PropTypes';
import StatelessCompanySideMenu from '@/components/stateless/composed/CompanySideMenu';
import Navigation               from '@/helper/Navigation';
import { bindActionCreators }   from 'redux';
import { CompanyActions }       from '@/store/actions/company';

export class Component extends React.Component {
    render() {
        // TODO https://lulububu.atlassian.net/browse/SUPPLYDU-573
        return (
            <StatelessCompanySideMenu
                isOnOwnCompany={this.props.isOnOwnCompany}
                verifiedCertificateURL={this.props.verifiedCertificateURL}
                {...this.props}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    companyName:            PropTypes.string,
    isOnOwnCompany:         PropTypes.bool,
    route:                  PropTypes.string,
    verifiedCertificateURL: PropTypes.string,
};

Component.defaultProps = {
    companyName:            null,
    isOnOwnCompany:         false,
    route:                  null,
    verifiedCertificateURL: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state, ownProps) => {
    // TODO https://lulububu.atlassian.net/browse/SUPPLYDU-573
    const currentCompanyId       = Navigation.getCompanyIdFromUrl(ownProps);
    const ownCompanyId           = _.get(state, 'user.company');
    const isOnOwnCompany         = ownCompanyId === currentCompanyId;
    const company                = _.get(state, `company.companies.${currentCompanyId}`);
    const companyName            = _.get(company, 'name');
    const verifiedCertificateURL = _.get(company, 'verifiedCertificate.path');

    return {
        companyId: currentCompanyId,
        companyName,
        isOnOwnCompany,
        verifiedCertificateURL,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyActions,
    },
    dispatch,
);

export const CompanySideMenu = Component;

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component));
