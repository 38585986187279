//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                    from 'react';
import _                        from 'lodash';
import { connect }              from 'react-redux';
import { bindActionCreators }   from 'redux';
import { compose }              from 'redux';
import ComponentHelper          from '@/helper/ComponentHelper';
import PropTypes                from '@/components/PropTypes';
import { TagActions }           from '@/store/actions/tag';
import Tag                      from '@/helper/Tag';
import TagSearchEditorStateless from '@/components/stateless/composed/TagSearchEditor';
import I18n                     from 'i18next';

export class Component extends React.Component {
    disabledProxy = (callback) => (input) => {
        if (this.props.disabled) {
            return;
        }

        callback(input);
    };

    render() {
        return (
            <TagSearchEditorStateless
                {...this.props}
                addTag={this.disabledProxy(this.props.addTag)}
                deleteTag={this.disabledProxy(this.props.deleteTag)}
                tagAdded={this.disabledProxy(this.props.tagAdded)}
                tagDeleted={this.disabledProxy(this.props.tagDeleted)}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const TagSearchEditor = Component;

Component.propTypes = {
    addTag:                 PropTypes.func,
    deleteTag:              PropTypes.func,
    disabled:               PropTypes.bool,
    fetchTags:              PropTypes.func,
    isLoading:              PropTypes.bool,
    resetTagQuery:          PropTypes.func,
    tagAdded:               PropTypes.func,
    tagContext:             PropTypes.string,
    tagDeleted:             PropTypes.func,
    tagHierarchy:           PropTypes.array,
    tagObjectPath:          PropTypes.string,
    tagResultList:          PropTypes.array,
    tagSearchQuery:         PropTypes.string,
    rootTags:               PropTypes.array,
    tagSelectorPlaceholder: PropTypes.string,
    title:                  PropTypes.string,
};

Component.defaultProps = {
    addTag:                 _.noop,
    deleteTag:              _.noop,
    disabled:               false,
    fetchTags:              _.noop,
    isLoading:              false,
    resetTagQuery:          _.noop,
    tagAdded:               _.noop,
    tagContext:             null,
    tagDeleted:             _.noop,
    tagHierarchy:           [],
    tagObjectPath:          null,
    tagResultList:          [],
    tagSearchQuery:         null,
    rootTags:               [],
    tagSelectorPlaceholder: I18n.t('tagSelectorPlaceholder'),
    title:                  I18n.t('tags'),
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...TagActions,
    },
    dispatch,
);

const mapStateToProps = (state, ownProps) => {
    const { tagContext } = ownProps;
    const tag            = _.get(state, 'tag');
    const tagObject      = _.get(tag, ['editor', tagContext], {});
    const allTagResults  = _.get(state, 'tag.tags', []);
    const tagHierarchy   = _.get(tagObject, 'tagHierarchy', []);
    const tagResultList  = Tag.filterAllTagsFromHierarchy(allTagResults, tagHierarchy);
    const rootTags       = _.get(state, ['tag', 'rootTags'], []);

    return {
        isLoading:      _.get(tag, 'isLoading'),
        tagHierarchy,
        tagResultList,
        tagSearchQuery: _.get(tag, 'tagQuery'),
        rootTags,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
