//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                       from 'react';
import _                           from 'lodash';
import { bindActionCreators }      from 'redux';
import { connect }                 from 'react-redux';
import ComponentHelper             from '@/helper/ComponentHelper';
import PropTypes                   from '@/components/PropTypes';
import StatelessMyCompanySearchBox from '@/components/stateless/composed/CompanySearchBox';
import { CompanyActions }          from '@/store/actions/company';
import { UserActions }             from '@/store/actions/user';

export class Component extends React.Component {
    onTextChange = (event) => {
        const query = _.get(event, 'target.value');

        this.props.setMyCompanyQuery({
            query,
        });
    };

    onCompanyJoinClick = (company) => () => {
        const companyId = _.get(company, 'id');

        this.props.joinCompany({
            companyId,
        });
    };

    render() {
        const { companies, joinRequests, query } = this.props;
        const companiesToShow                    = (
            !_.isEmpty(joinRequests) ?
                joinRequests :
                companies
        );
        const mappedJoinRequests                 = _.map(
            joinRequests,
            (joinRequest) => {
                return _.get(joinRequest, 'iri', joinRequest);
            },
        );

        return (
            <StatelessMyCompanySearchBox
                companies={companiesToShow}
                joinRequests={mappedJoinRequests}
                onTextChange={this.onTextChange}
                onCompanyJoinClick={this.onCompanyJoinClick}
                query={query}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const MyCompanySearchBox = Component;

Component.propTypes = {
    companies:         PropTypes.array,
    joinCompany:       PropTypes.func,
    joinRequests:      PropTypes.array,
    query:             PropTypes.string,
    setMyCompanyQuery: PropTypes.func,
};

Component.defaultProps = {
    companies:         [],
    joinCompany:       _.noop,
    joinRequests:      [],
    query:             null,
    setMyCompanyQuery: _.noop,
};

Component.renderAffectingProps = _.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        companies: _.get(state, 'company.myCompanySearchResults', []),
        query:     _.get(state, 'company.myCompanyQuery'),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...UserActions,
        ...CompanyActions,
    },
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
