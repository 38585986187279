//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { redirectWithDelay } from '@/helper/Route';
import _                     from 'lodash';
import I18n                  from 'i18next';
import { call }              from 'redux-saga/effects';
import { put }               from 'redux-saga/effects';
import { all }               from 'redux-saga/effects';
import { select }            from 'redux-saga/effects';
import { takeLatest }        from 'redux-saga/effects';
import { delay }             from 'redux-saga/effects';

import { push }              from 'connected-react-router';
import Routes                from '@/constants/Routes';
import RedirectCheck         from '@/helper/RedirectCheck';
import User                  from '@/helper/User';
import { AlertBoxActions }   from '@/store/actions/alertBox';
import { CompanyActions }    from '@/store/actions/company';
import * as Api              from '@/api';
import { UserActions }       from '@/store/actions/user';
import { UserTypes }         from '@/store/actions/user';
import Hydra                 from '@/helper/Hydra';
import moment                from 'moment-timezone';
import { OverlayActions }    from '@/store/actions/ovleray';
import { NavigationActions } from '@/store/actions/navigation';
import OverlayType           from '@/components/connected/Overlay/OverlayType';
import { LoadingActions }    from '@/store/actions/loading';
import UserVerificationState from '@/constants/UserVerificationState';

const afterLoginRoute = Routes.home;

function* login() {
    yield put(AlertBoxActions.clearAlerts());

    const user          = yield select((state) => state.user);
    const query         = yield select((state) => state.router.location.query);
    const loginResponse = yield call(
        Api.login,
        user.loginIdentifier,
        user.loginPassword,
    );

    if (loginResponse.ok) {
        const data              = _.get(loginResponse, 'data');
        const company           = _.get(data, 'company', null);
        const email             = _.get(data, 'email', '');
        const lastLogin         = _.get(data, 'lastLogin');
        const token             = _.get(data, 'token', null);
        const userId            = _.get(data, 'userId', null);
        const username          = _.get(data, 'username', '');
        const name              = _.get(data, 'name', '');
        const actsPrivate       = _.get(data, 'actsPrivate', false);
        const favoriteCompanies = _.get(data, 'favoriteCompanies', []);
        const joinRequests      = _.get(data, 'joinRequests', []);
        const phone             = _.get(data, 'phone', null);
        const workingAtCompany  = _.get(data, 'workingAtCompany', null);
        const country           = _.get(data, 'country', null);
        const position          = _.get(data, 'position', null);

        if (userId) {
            Api.setToken(token);

            yield put(UserActions.loginSucceeded({
                company,
                email,
                lastLogin,
                name,
                token,
                userId,
                username,
                favoriteCompanies,
                actsPrivate,
                joinRequests,
                phone,
                workingAtCompany,
                country,
                position,
                redirect: query?.redirect,
            }));
        } else {
            yield put(UserActions.loginFailed());
        }
    } else {
        yield put(UserActions.loginFailed());
    }
}

function* loginFailed() {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('loginError'),
    }));
}

function* loginSucceeded(action) {
    const {
        company,
        actsPrivate,
        joinRequests,
        redirect,
    }            = action;
    const redirectPath = redirect ?? afterLoginRoute;

    if (company) {
        yield put(CompanyActions.fetchCompany({
            id: company,
        }));
    }

    // Todo: Redirect to the route the user should see after the successful login
    yield put(push(decodeURIComponent(redirectPath)));
    yield put(UserActions.checkActPrivate({
        company,
        actsPrivate,
        joinRequests,
    }));
}

function* logout() {
    Api.setToken(null);

    localStorage.removeItem('persist:root');
    yield put(push(Routes.login));
}

function* restoreToken() {
    const pathname        = yield select((state) => state.router.location.pathname);
    const browserPathname = window.location.pathname;
    const user            = yield select((state) => state.user);

    if (user.token) {
        if (!User.isTokenValid(user.token)) {
            yield put(UserActions.logout());
        } else {
            Api.setToken(user.token);

            if (pathname === Routes.login || browserPathname === Routes.login) {
                yield put(push(afterLoginRoute));
            }

            // TODO: You may want to fetch some data here
        }
    } else if (RedirectCheck.checkSessionRedirect(user, pathname)) {
        yield* redirectWithDelay(RedirectCheck.getSessionRedirect(pathname));
    }
}

function* joinCompany(action) {
    const response = yield call(
        Api.joinCompany,
        action.companyId,
    );

    if (response.ok) {
        const joinRequests = _.get(response, 'data.joinRequests', []);

        yield put(UserActions.setJoinRequests({
            joinRequests,
        }));
        yield put(AlertBoxActions.showSuccessAlert({
            text: I18n.t('requestedJoinSuccess'),
        }));
        yield put(UserActions.fetch());
    } else {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('requestedJoinFailed'),
        }));
    }
}

function* leaveCompany(action) {
    const { companyId, confirmCompanyDelete } = action;
    const response                            = yield call(
        Api.leaveCompany,
        companyId,
        confirmCompanyDelete,
    );

    if (response.ok) {
        yield all([
            put(AlertBoxActions.showSuccessAlert({
                text: I18n.t('changedRoleSuccess'),
            })),
            put(UserActions.fetch()),
            put(CompanyActions.confirmCompanyDelete({
                confirmCompanyDelete: false,
            })),
        ]);

        return;
    }

    if (
        response.status === 400 &&
        response.data?.error === 'missingDeletionConfirmation'
    ) {
        yield put(CompanyActions.confirmCompanyDelete({
            confirmCompanyDelete: true,
        }));

        return;
    }

    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('changedRoleFailed'),
    }));
}

function* fetchJoinRequests() {
    const user     = yield select((state) => state.user);
    const userId   = _.get(user, 'userId', null);
    const response = yield call(
        Api.fetchJoinRequests,
        userId,
    );

    if (response.ok) {
        const joinRequests = Hydra.cleanupObject(_.get(response, 'data.joinRequests', []));

        yield put(UserActions.setJoinRequests({
            joinRequests,
        }));
    }
}

function* fetch() {
    const user   = yield select((state) => state.user);
    const userId = _.get(user, 'userId', null);

    if (!userId) {
        yield put(UserActions.fetchFailed({
            error: 'No userId found. The user might not be logged in.',
        }));

        return;
    }

    try {
        const response = yield call(
            Api.fetchUser,
            userId,
        );

        if (response.ok) {
            const data                    = _.get(response, 'data');
            const workingAtCompany        = _.get(data, 'workingAtCompany', '');
            const company                 = _.get(data, 'company.id', null);
            const userCompany             = _.get(data, 'company', null);
            const email                   = _.get(data, 'email', '');
            const username                = _.get(data, 'username', '');
            const name                    = _.get(data, 'name', '');
            const favoriteCompanies       = _.get(data, 'favoriteCompanies', []);
            const country                 = _.get(data, 'country', null);
            const preferredLanguage       = _.get(data, 'preferredLanguage', null);
            const interests               = _.get(data, 'interests', []);
            const phone                   = _.get(data, 'phone', null);
            const position                = _.get(data, 'position', null);
            const image                   = _.get(data, 'image', null);
            const timezone                = _.get(data, 'timezone', null);
            const actsPrivate             = _.get(data, 'actsPrivate', false);
            const joinRequests            = _.get(data, 'joinRequests', []);
            const userVerificationRequest = _.get(data, 'userVerificationRequest', null);

            if (timezone) {
                moment.tz.setDefault(timezone);
                console.info('User: Timezone set to', timezone);
            }

            if (userId) {
                yield put(UserActions.fetchSucceeded({
                    company,
                    userCompany,
                    workingAtCompany,
                    email,
                    name,
                    userId,
                    username,
                    favoriteCompanies,
                    country,
                    preferredLanguage,
                    interests,
                    phone,
                    position,
                    image,
                    timezone,
                    actsPrivate,
                    joinRequests,
                    userVerificationRequest,
                }));
            } else {
                yield put(UserActions.fetchFailed());
            }
        }
    } catch (exception) {
        yield put(UserActions.fetchFailed({
            error: exception,
        }));
    }
}

function* checkActPrivate(action) {
    const company           = _.get(action, 'company', null);
    const actsPrivate       = _.get(action, 'actsPrivate', false);
    const user              = yield select((state) => state.user);
    const actPrivateChecked = _.get(user, 'actPrivateChecked', false);
    const joinRequests      = _.get(action, 'joinRequests', []);

    console.debug('User: Checking act private', company, actsPrivate, joinRequests);

    if (
        _.isNil(company) &&
        !actsPrivate &&
        _.isEmpty(joinRequests) &&
        !actPrivateChecked
    ) {
        yield delay(1000);
        yield put(OverlayActions.openConfirmDialog({
            confirmAction:     [
                NavigationActions.openUrl({
                    url: Routes.myProfileJoinCompany,
                }),
                UserActions.setActPrivateChecked({
                    checked: true,
                }),
            ],
            cancelAction:      CompanyActions.actPrivate(),
            title:             I18n.t('checkActPrivateTitle'),
            message:           I18n.t('checkActPrivateText'),
            confirmButtonText: I18n.t('checkActPrivateCompanyButton'),
            cancelButtonText:  I18n.t('checkActPrivateCustomerButton'),
            overlayType:       OverlayType.widePrompt,
        }));
    }
}

function* fetchSucceeded(action) {
    const { company, actsPrivate, joinRequests } = action;

    yield put(UserActions.checkActPrivate({
        company,
        actsPrivate,
        joinRequests,
    }));
    yield put(UserActions.checkActiveCompany());
}

export function* deleteProfile() {
    const user   = yield select((state) => state.user);
    const userId = _.get(user, 'userId', null);

    const response = yield call(
        Api.deleteProfile,
        userId,
    );

    if (response.ok) {
        yield all([
            put(UserActions.logout()),
            put(AlertBoxActions.showSuccessAlert({
                text: I18n.t('profileDeletedSuccess'),
            })),
        ]);

        return;
    }

    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('profileDeletedFailed'),
    }));
}

export function* createUserVerification() {
    const user                    = yield select((state) => state.user);
    const userVerificationRequest = _.get(user, 'userVerificationRequest', null);
    const identityCard            = _.get(userVerificationRequest, 'identityCard');
    const businessCard            = _.get(userVerificationRequest, 'businessCard');
    const state                   = _.get(userVerificationRequest, 'state');

    if (
        !identityCard ||
        !businessCard
    ) {
        yield put(LoadingActions.resetOverlay());
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('userVerificationMissingImages'),
        }));

        return;
    }

    if (
        state === UserVerificationState.declined &&
        (
            _.get(identityCard, 'iri') ||
            _.get(businessCard, 'iri')
        )
    ) {
        yield put(LoadingActions.resetOverlay());
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('userVerificationDeclinedImages'),
        }));

        return;
    }

    const response = yield call(
        Api.createUserVerification,
        {
            identityCard,
            businessCard,
        },
    );

    if (response.ok) {
        yield put(UserActions.createUserVerificationSucceeded());
        yield put(UserActions.fetch());
        yield put(AlertBoxActions.showSuccessAlert({
            text: I18n.t('userVerificationSucceeded'),
        }));

        return;
    }

    yield put(UserActions.createUserVerificationFailed());
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('userVerificationFailed'),
    }));
}

function* checkActiveCompany() {
    const daysSinceLastUpdate      = yield select((state) => state.company.daysSinceLastUpdate);
    const isLoggedIn               = yield select((state) => !!state.user.token);
    const hasUserCompany           = yield select((state) => !!state.user.company);
    const shouldShowUpdateRequired = (
        daysSinceLastUpdate >= 90 &&
        isLoggedIn &&
        hasUserCompany
    );

    if (shouldShowUpdateRequired) {
        const usersCompanyId = yield select((state) => state.user.company);

        yield put(OverlayActions.openConfirmDialog({
            confirmAction:     [
                CompanyActions.setCompanyAsActive({
                    id: usersCompanyId,
                }),
            ],
            title:             I18n.t('companyUpdateRequiredTitle'),
            message:           I18n.t('companyUpdateRequiredMessage'),
            confirmButtonText: I18n.t('confirm'),
            cancelButtonText:  I18n.t('ignore'),
        }));
    }
}

export const callUserSagas = () => {
    return [
        // @formatter:off
        takeLatest([UserTypes.LOGIN],                    login),
        takeLatest([UserTypes.LOGIN_FAILED],             loginFailed),
        takeLatest([UserTypes.LOGIN_SUCCEEDED],          loginSucceeded),
        takeLatest([UserTypes.LOGOUT],                   logout),
        takeLatest([UserTypes.DELETE_PROFILE],           deleteProfile),
        takeLatest([UserTypes.CHECK_ACT_PRIVATE],        checkActPrivate),
        takeLatest([UserTypes.JOIN_COMPANY],             joinCompany),
        takeLatest([UserTypes.LEAVE_COMPANY],            leaveCompany),
        takeLatest([UserTypes.FETCH_JOIN_REQUESTS],      fetchJoinRequests),
        takeLatest([UserTypes.FETCH],                    fetch),
        takeLatest([UserTypes.FETCH_SUCCEEDED],          fetchSucceeded),
        takeLatest([UserTypes.CREATE_USER_VERIFICATION], createUserVerification),
        takeLatest([UserTypes.CHECK_ACTIVE_COMPANY],     checkActiveCompany),
        // @formatter:on
    ];
};

export default {
    restoreToken,
};
