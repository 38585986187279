//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }       from 'connected-react-router';
import { put }        from 'redux-saga/effects';
import { all }        from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import I18n           from 'i18next';
import { call }       from 'redux-saga/effects';
import _              from 'lodash';

import * as Api                from '@/api';
import Overlays                from '@/constants/Overlays';
import { OverlayManager }      from '@/components/connected/OverlayManager';
import StateHelper             from '@/helper/State';
import { ProjectsTypes }       from '@/store/actions/projects';
import HydraHelper             from '@/helper/Hydra';
import { ProjectsActions }     from '@/store/actions/projects';
import { CreateProjectSchema } from '@/validations/createProjects';
import Hydra                   from '@/helper/Hydra';
import { TagActions }          from '@/store/actions/tag';
import { AlertBoxActions }     from '@/store/actions/alertBox';
import SagaStateHelper         from '@/helper/SagaStateHelper';
import { CompanyActions }      from '@/store/actions/company';
import MachineSagas            from '@/store/sagas/machine';
import Routes                  from '@/constants/Routes';
import Route                   from '@/helper/Route';
import CompanyTagFields        from '@/constants/CompanyTagFields';
import ViewingContext          from '@/constants/ViewingContext';

function* openCreateProjectOverlay() {
    yield put(ProjectsActions.resetOverlay());
    yield put(TagActions.resetTagQuery());
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.createProject)));
}

function* createProject() {
    const project = yield select((state) => {
        return _.get(state, 'projects.createProjectOverlayForm.data', {});
    });

    const clonedProject = _.cloneDeep(project);

    Object.values(CompanyTagFields).forEach((key) => {
        if (clonedProject[key]) {
            clonedProject[key] = clonedProject[key].map((tag) => {
                return Hydra.getIriFromId('tags', tag.id);
            });
        }
    });

    const validationResult = CreateProjectSchema.safeParse(clonedProject);

    if (!validationResult.success) {
        yield put(ProjectsActions.createProjectFailed({
            error: validationResult,
        }));

        return;
    }

    if (clonedProject.visibleNow) {
        clonedProject.visibleFromDate = new Date();
        clonedProject.visibleToDate   = null;
    }

    const response = yield call(Api.context.projects.create, clonedProject);

    if (response.ok) {
        const result = response.data;

        yield put(ProjectsActions.createProjectSuccess({
            project: result,
        }));
        yield put(ProjectsActions.fetchProjectsOwnProjects());
    } else {
        yield put(ProjectsActions.createProjectFailed());
    }
}

function* closeProject(action) {
    const response  = yield call(
        Api.context.projects.closeProject,
        action.projectIri,
    );
    const projectId = Hydra.getIdFromIri(action.projectIri);

    if (response.ok) {
        yield all([
            put(ProjectsActions.closeProjectSuccess()),
            put(ProjectsActions.fetchProject({
                projectId,
            })),
        ]);
    } else {
        yield put(ProjectsActions.closeProjectFailed());
    }
}

function* fetchProjectsSuggestions() {
    const response = yield call(Api.context.projects.fetchSuggestions);

    if (response.ok) {
        const result = response.data;

        yield put(ProjectsActions.fetchProjectsSuggestionsSuccess({
            projects: result,
        }));
    } else {
        yield put(ProjectsActions.fetchProjectsSuggestionsFailed());
    }
}

function* fetchProjectsOwnProjects() {
    const response = yield call(Api.context.projects.fetchOwnProjects);

    if (response.ok) {
        const result = HydraHelper.cleanupObject(response.data);

        yield put(ProjectsActions.fetchProjectsOwnProjectsSuccess({
            projects: result,
        }));
    } else {
        yield put(ProjectsActions.fetchProjectsOwnProjectsFailed());
    }
}

function* fetchProject(action) {
    yield put(ProjectsActions.resetSelectedProject());

    const response = yield call(Api.context.projects.fetchProject, action.projectId);

    if (response.ok) {
        const result = HydraHelper.cleanupObject(response.data);

        yield put(ProjectsActions.fetchProjectSuccess({
            project: result,
        }));
    } else {
        yield put(ProjectsActions.fetchProjectFailed());
    }
}

function* acceptSuggestedProject(action) {
    const { project }          = action;
    const state                = yield SagaStateHelper.getState();
    const projectIri           = Hydra.getIriFromId('projects', project.id);
    const requestingCompanyIri = StateHelper.getUserCompanyIri(state);
    const response             = yield call(Api.context.projects.acceptProject, requestingCompanyIri, projectIri);
    const interestedMessage    = _.get(state, 'projects.interestedMessage', '');

    if (response.ok) {
        if (interestedMessage) {
            const matchResult = Hydra.cleanupObject(response.data);

            yield put(ProjectsActions.sendMessageRequest({
                message: interestedMessage,
                project,
                match:   matchResult,
            }));
        }
        yield all([
            put(ProjectsActions.acceptSuggestedProjectSuccess()),
            put(ProjectsActions.fetchProjectsSuggestions()),
        ]);
    } else {
        yield put(ProjectsActions.acceptSuggestedProjectFailed(response.data));
    }
}

function* openProject(action) {
    const { viewingContext } = action;
    const projectId          = _.get(action, 'project.id', null);
    let url                  = null;

    if (viewingContext === ViewingContext.OWN_PROFILE) {
        url = Route.replaceParametersInUrl(Routes.myProfileProjectsProjectDetail, {
            projectId,
        });
    } else if (viewingContext === ViewingContext.OWN_COMPANY) {
        const companyId = _.get(action, 'project.company.id', null);
        url             = Route.replaceParametersInUrl(Routes.projectDetail, {
            companyId,
            projectId,
        });
    }

    yield put(ProjectsActions.fetchProjectAndDependencies({
        projectId,
        redirectToUrl: url,
    }));
}

function* fetchProjectAndDependencies(action) {
    const { projectId, redirectToUrl } = action;

    yield call(fetchProject, {
        projectId,
    });

    const project = yield select((state) => _.get(state, 'projects.selectedProject', null));

    if (!project) {
        return;
    }

    const { company } = project;
    const companyId   = company?.id;

    if (companyId) {
        yield all([
            put(CompanyActions.setCurrentCompany({
                id: companyId,
            })),
            call(MachineSagas.fetchMachinesByCompany, {
                companyId,
            }),
        ]);
    }

    yield put(ProjectsActions.fetchProjectAndDependenciesSuccess({
        redirectToUrl,
    }));
}

function* fetchProjectAndDependenciesSuccess(action) {
    const { redirectToUrl } = action;

    if (redirectToUrl) {
        yield put(push(redirectToUrl));
    }
}

function* sendMessageRequest(action) {
    const { project, message, match } = action;
    const { company, projectCreator } = project;
    const projectIri                  = Hydra.getIriFromId('projects', project.id);
    const companyIri                  = Hydra.getIriFromId('companies', company?.id);
    const recipientIri                = Hydra.getIriFromId('users', projectCreator?.id);
    const state                       = yield SagaStateHelper.getState();
    const requestingCompanyIri        = StateHelper.getUserCompanyIri(state);
    const mappedMessageRequest        = {
        company:           companyIri,
        recipient:         recipientIri,
        message,
        project:           projectIri,
        requestingCompany: requestingCompanyIri,
        subject:           I18n.t('interestedInProjectSubject'),
    };

    const response = yield call(
        Api.saveMessageRequest,
        mappedMessageRequest,
    );

    if (response.ok) {
        const messageRequestResult = HydraHelper.cleanupObject(response.data);

        yield call(
            Api.context.projects.updateProjectMatch,
            match.iri,
            {
                messageRequest: messageRequestResult.iri,
            },
        );
    }
}

function* declineSuggestedProject(action) {
    const companyIri = yield select((state) => StateHelper.getUserCompanyIri(state));
    const projectIri = Hydra.getIriFromId('projects', action.projectId);
    const response   = yield call(Api.context.projects.declineProject, companyIri, projectIri);
    const projectId  = Hydra.getIdFromIri(projectIri);

    if (response.ok) {
        const result = HydraHelper.cleanupObject(response.data);

        yield put(ProjectsActions.declineSuggestedProjectSuccess({
            project: result,
        }));
        yield put(ProjectsActions.fetchProjectsSuggestions());
        yield put(ProjectsActions.fetchProject({
            projectId,
        }));
    } else {
        yield put(ProjectsActions.declineSuggestedProjectFailed(response.data));
    }
}

function* acceptProject(action) {
    const { project }       = action;
    const companyIri        = yield select((state) => StateHelper.getUserCompanyIri(state));
    const projectIri        = Hydra.getIriFromId('projects', project.id);
    const response          = yield call(Api.context.projects.acceptProject, companyIri, projectIri);
    const projectId         = Hydra.getIdFromIri(projectIri);
    const interestedMessage = yield select((state) => _.get(state, 'projects.interestedMessage', ''));

    if (response.ok) {
        const result = HydraHelper.cleanupObject(response.data);

        if (interestedMessage) {
            const matchResult = Hydra.cleanupObject(response.data);

            yield put(ProjectsActions.sendMessageRequest({
                message: interestedMessage,
                project,
                match:   matchResult,
            }));
        }

        yield put(ProjectsActions.acceptProjectSuccess({
            project: result,
        }));
        yield put(ProjectsActions.fetchProject({
            projectId,
        }));
    } else {
        yield put(ProjectsActions.acceptProjectFailed(response.data));
    }
}

function* rejectProjectMatch(action) {
    const response   = yield call(Api.context.projects.rejectProjectMatch, action.matchIri);
    const projectIri = Hydra.getIriFromId('projects', action.projectId);
    const projectId  = Hydra.getIdFromIri(projectIri);

    if (response.ok) {
        const result = HydraHelper.cleanupObject(response.data);

        yield put(ProjectsActions.rejectProjectMatchSuccess({
            project: result,
        }));
        yield put(ProjectsActions.fetchProject({
            projectId,
        }));
    } else {
        yield put(ProjectsActions.rejectProjectMatchFailed(response.data));
    }
}

function* declineProject(action) {
    const companyIri = yield select((state) => StateHelper.getUserCompanyIri(state));
    const projectIri = Hydra.getIriFromId('projects', action.projectId);
    const response   = yield call(Api.context.projects.declineProject, companyIri, projectIri);
    const projectId  = Hydra.getIdFromIri(projectIri);

    if (response.ok) {
        const result = HydraHelper.cleanupObject(response.data);

        yield put(ProjectsActions.declineProjectSuccess({
            project: result,
        }));
        yield put(ProjectsActions.fetchProject({
            projectId,
        }));
    } else {
        yield put(ProjectsActions.declineProjectFailed(response.data));
    }
}

function* showErrorMessage(action) {
    yield put(AlertBoxActions.clearAlerts());
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(action.error),
    }));
}

function fetchAndAddChildTags(tagType) {
    return function* fetchAndAddChildTagsGenerator(action) {
        const tag       = _.get(action, 'tag', null);
        const hierarchy = _.get(tag, 'hierarchy', null);

        for (const tagElement of hierarchy) {
            const tagId = _.get(tagElement, 'id', null);

            if (tagId) {
                const response = yield call(
                    Api.fetchTag,
                    tagId,
                );

                if (response.ok) {
                    const result   = HydraHelper.cleanupObject(response.data);
                    const children = _.get(result, 'childTags', []);

                    yield put(ProjectsActions.addChildrenToTagQuery({
                        tagType,
                        tag: result,
                        children,
                    }));
                }
            }
        }
    };
}

export const callProjectsSagas = () => {
    return [
        // @formatter:off
        takeLatest([ProjectsTypes.FETCH_PROJECT_AND_DEPENDENCIES],         fetchProjectAndDependencies),
        takeLatest([ProjectsTypes.FETCH_PROJECT_AND_DEPENDENCIES_SUCCESS], fetchProjectAndDependenciesSuccess),
        takeLatest([ProjectsTypes.OPEN_PROJECT],                           openProject),
        takeLatest([ProjectsTypes.CREATE_PROJECT],                         createProject),
        takeLatest([ProjectsTypes.OPEN_CREATE_PROJECT_OVERLAY],            openCreateProjectOverlay),
        takeLatest([ProjectsTypes.FETCH_PROJECT],                          fetchProject),
        takeLatest([ProjectsTypes.FETCH_PROJECTS_SUGGESTIONS],             fetchProjectsSuggestions),
        takeLatest([ProjectsTypes.FETCH_PROJECTS_OWN_PROJECTS],            fetchProjectsOwnProjects),
        takeLatest([ProjectsTypes.ADD_TECHNOLOGY_TAG],                     fetchAndAddChildTags(CompanyTagFields.TECHNOLOGY)),
        takeLatest([ProjectsTypes.ADD_INDUSTRY_TAG],                       fetchAndAddChildTags(CompanyTagFields.BRANCHES)),
        takeLatest([ProjectsTypes.ADD_MATERIAL_TAG],                       fetchAndAddChildTags(CompanyTagFields.MATERIAL)),
        takeLatest([ProjectsTypes.ADD_PART_FAMILY_TAG],                    fetchAndAddChildTags(CompanyTagFields.PART_FAMILY)),
        takeLatest([ProjectsTypes.ADD_MANDATORY_TAG],                      fetchAndAddChildTags(CompanyTagFields.MANDATORY)),
        takeLatest([ProjectsTypes.ADD_INSPECTION_TAG],                     fetchAndAddChildTags(CompanyTagFields.INSPECTION)),
        takeLatest([ProjectsTypes.REJECT_PROJECT_MATCH],                   rejectProjectMatch),
        takeLatest([ProjectsTypes.SEND_MESSAGE_REQUEST],                   sendMessageRequest),
        takeLatest([ProjectsTypes.ACCEPT_SUGGESTED_PROJECT],               acceptSuggestedProject),
        takeLatest([ProjectsTypes.ACCEPT_PROJECT],                         acceptProject),
        takeLatest([ProjectsTypes.CLOSE_PROJECT],                          closeProject),
        takeLatest([ProjectsTypes.DECLINE_SUGGESTED_PROJECT],              declineSuggestedProject),
        takeLatest([ProjectsTypes.DECLINE_PROJECT],                        declineProject),
        takeLatest([ProjectsTypes.ACCEPT_PROJECT_FAILED],                  showErrorMessage),
        takeLatest([ProjectsTypes.DECLINE_PROJECT_FAILED],                 showErrorMessage),
        takeLatest([ProjectsTypes.ACCEPT_SUGGESTED_PROJECT_FAILED],        showErrorMessage),
        takeLatest([ProjectsTypes.DECLINE_SUGGESTED_PROJECT_FAILED],       showErrorMessage),
        // @formatter:on
    ];
};
