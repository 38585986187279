//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import PropTypes from '@/components/PropTypes';
import React     from 'react';

import ComponentHelper from '@/helper/ComponentHelper';
import { FullImage }   from '@/components/stateless/composed/FullImage';

import styles from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { partner } = this.props;

        if (!partner) {
            return null;
        }

        const { name, logo, website } = partner;

        return (
            <div className={styles.partner}>
                <a
                    href={website}
                    target={'_blank'}
                    rel={'noreferrer'}
                >
                    <FullImage
                        image={logo}
                        alternativeText={name}
                        height={20}
                    />
                </a>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Partner = Component;

Component.propTypes = {
    partner: PropTypes.partner,
};

Component.defaultProps = {
    partner: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
