//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                    from 'lodash';
import classNames           from 'classnames';
import I18n                 from 'i18next';
import React                from 'react';
import { DebounceInput }    from 'react-debounce-input';
import ColorButton          from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme     from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import ComponentHelper      from '@/helper/ComponentHelper';
import Icon                 from '@/components/stateless/atomic/Icon';
import IconType             from '@/components/stateless/atomic/Icon/IconType';
import PropTypes            from '@/components/PropTypes';
import Routes               from '@/constants/Routes';
import TagSelector          from '@/components/stateless/composed/TagSelector';
import Window               from '@/helper/Window';
import { LoadingIndicator } from '@/components/stateless/atomic/LoadingIndicator';
import Device               from '@/helper/Device';
import StartPageSearchMode  from './StartPageSearchMode';
import styles               from './styles.module.scss';

export class Component extends React.Component {
    // todo: click auf control = input setzen?
    // todo: was passiert beim fokus?
    // TODO: welche randfarbe bei focus?

    constructor(props) {
        super(props);

        this.componentReference       = React.createRef();
        this.searchInputReference     = React.createRef();
        this.resultContainerReference = React.createRef();
        this.tagsContainerReference   = React.createRef();
        this.state                    = {
            isInputFocused:    null,
            isResultFocused:   null,
            isDropdownFocused: null,
            tagSearchActive:   false,
        };
    }

    updateSearchHeight = () => {
        if (
            this.resultContainerReference.current ||
            this.tagsContainerReference.current
        ) {
            const windowHeight    = window.innerHeight;
            const resultPosition  = Window.getCoordinates(this.resultContainerReference.current).top;
            const maxHeight       = windowHeight - resultPosition - 50;
            const cappedMaxHeight = maxHeight <= 300 ? maxHeight : 300;

            if (this.resultContainerReference.current) {
                this.resultContainerReference.current.style.maxHeight = cappedMaxHeight;
            }

            if (this.tagsContainerReference.current) {
                this.tagsContainerReference.current.style.maxHeight = cappedMaxHeight;
            }
        }
    };

    componentDidMount() {
        this.updateSearchHeight();

        window.addEventListener('resize', this.updateSearchHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSearchHeight);
    }

    componentDidUpdate(prevProps, prevState) {
        this.updateSearchHeight();

        if (prevProps.query !== this.props.query) {
            this.focusSearchInput();
        }

        if (
            !prevState.isResultFocused &&
            !prevState.isInputFocused &&
            !prevState.isDropdownFocused &&
            (
                this.state.isResultFocused ||
                this.state.isInputFocused ||
                this.state.isDropdownFocused
            )
        ) {
            this.focusSearchInput();
        }
    }

    focusSearchInput = () => {
        if (
            this.searchInputReference.current &&
            !Device.isIOS
        ) {
            this.searchInputReference.current.focus();
        }
    };

    isFocusedWithin = () => {
        return (
            this.componentReference.current &&
            this.componentReference.current.contains(document.activeElement)
        ) || (
            this.componentReference.current === document.activeElement
        ) || (
            this.state.isResultFocused ||
            this.state.isInputFocused ||
            this.state.isDropdownFocused ||
            this.props.headerInputFocused
        );
    };

    onInputBlur = () => {
        requestAnimationFrame(() => {
            this.setState({
                isInputFocused: false,
            });
        });
    };

    onInputFocus = () => {
        this.setState({
            isInputFocused:  true,
            isResultFocused: false,
        });
    };

    onResultBlur = () => {
        requestAnimationFrame(() => {
            this.setState({
                isResultFocused: false,
            });
        });
    };

    onResultFocus = () => {
        this.setState({
            isResultFocused: true,
            isInputFocused:  false,
        });
    };

    onTagSearchButtonClicked = () => {
        this.setState({
            tagSearchActive: true,
        });
    };

    renderTagSearchButton = () => {
        if (!this.props.allowInitialTagSearch) {
            return null;
        }

        return (
            <div className={styles.tagSearchButtonContainer}>
                <span>
                    {I18n.t('or')}
                </span>
                <ColorButton
                    onClick={this.onTagSearchButtonClicked}
                    theme={ColorButtonTheme.orange}
                    text={I18n.t('tagSearchButtonText')}
                />
            </div>
        );
    };

    render() {
        const details = this.renderDetails();

        if (!this.props.hasHeaderParent || details) {
            const value = _.defaultTo(_.get(this.props, 'query'), '');

            return (
                <>
                    <div
                        className={classNames(
                            styles.startPageSearchWrapper,
                            {
                                [styles.startPageSearchWrapperHidden]:      this.props.hasHeaderParent && !this.isFocusedWithin(),
                                [styles.startPageSearchWrapperWithContent]: !this.props.hasHeaderParent && details,
                                [styles.startPageSearchWrapperCompany]:     this.props.mode === StartPageSearchMode.company,
                            },
                        )}
                        ref={this.componentReference}
                    >
                        <div className={styles.inputWrapper}>
                            <div
                                className={styles.searchIconWrapper}
                            >
                                {this.renderSearchIconOrLoadingIndicator()}
                            </div>
                            <DebounceInput
                                autoFocus
                                debounceTimeout={300}
                                onChange={this.props.onTextChange}
                                placeholder={I18n.t('searchFieldPlaceholder')}
                                type={'text'}
                                value={value}
                                onFocus={this.onInputFocus}
                                onBlur={this.onInputBlur}
                                inputRef={this.searchInputReference}
                            />
                            {this.renderButtonGroup()}
                        </div>
                        {details}
                    </div>
                    {this.renderTagSearchButton()}
                </>
            );
        }

        return null;
    }

    renderButtonGroup = () => {
        return (
            <div className={styles.buttonGroupWrapper}>
                {this.renderClearButton()}
                {this.renderSearchButton()}
                {this.renderShowMoreButton()}
            </div>
        );
    };

    renderSearchIconOrLoadingIndicator = () => {
        if (this.props.isLoading) {
            return (
                <LoadingIndicator />
            );
        }

        return (
            <Icon iconType={IconType.search} />
        );
    };

    renderClearButton = () => {
        if (this.props.tagQuery.length > 0) {
            return (
                <div className={styles.buttonWrapper}>
                    <ColorButton
                        theme={ColorButtonTheme.white}
                        text={I18n.t('clearButtonAction')}
                        onClick={this.clearButtonClicked}
                    />
                </div>
            );
        }

        return null;
    };

    clearButtonClicked = () => {
        this.props.clearSearchResultsAction();
        this.setState({
            tagSearchActive: false,
        });
    };

    renderSearchButton = () => {
        if (!this.props.showSearchButton) {
            return null;
        }

        const tagQuery = this.props.tagQuery;

        if (tagQuery && tagQuery.length) {
            const resultCount = this.props.possibleResultsCount;
            const isDisabled  = !!(
                !resultCount || this.props.isLoading
            );

            return (
                <div className={styles.buttonWrapper}>
                    <ColorButton
                        theme={ColorButtonTheme.orange}
                        onClick={this.onSearchButtonClicked}
                        text={I18n.t(
                            'searchButtonAction',
                            {
                                resultCount,
                            },
                        )}
                        disabled={isDisabled}
                    />
                </div>
            );
        }

        return null;
    };

    onSearchButtonClicked = () => {
        this.setState({
            isResultFocused:    false,
            isInputFocused:     false,
            isDropdownFocused:  false,
            headerInputFocused: false,
        });

        this.props.openUrl({
            url: Routes.searchResults,
        });
    };

    renderShowMoreButton = () => {
        const isHidden = !(
            this.props.hasHeaderParent &&
            !this.isFocusedWithin()
        );

        return (
            <div
                className={classNames(
                    styles.buttonWrapper,
                    {
                        [styles.buttonWrapperHidden]: isHidden,
                    },
                )}
            >
                <ColorButton
                    theme={ColorButtonTheme.orange}
                    text={I18n.t('showMore')}
                    onClick={this.onResultFocus}
                />
            </div>
        );
    };

    renderDetails = () => {
        if (
            this.props.mode !== StartPageSearchMode.none &&
            this.props.mode !== StartPageSearchMode.company &&
            (
                this.props.resultList.length ||
                this.props.tagQuery.length ||
                this.state.tagSearchActive
            )
        ) {
            return (
                <>
                    <div
                        className={classNames({
                            [styles.startPageWrapper]: !this.props.hasHeaderParent,
                        })}
                    >
                        <div
                            ref={this.resultContainerReference}
                            className={classNames(
                                styles.detailsWrapper,
                                {
                                    [styles.detailsWrapperReduced]: this.props.hasHeaderParent && !this.isFocusedWithin(),
                                    [styles.detailsWrapperTagList]: this.props.mode === StartPageSearchMode.tagQueryTree,
                                },
                            )}
                            onFocus={this.onResultFocus}
                            onBlur={this.onResultBlur}
                            tabIndex={'-1'} // This enables the onFocus & onBlur events
                        >
                            {this.renderTagQueryTree()}
                        </div>
                        <div
                            ref={this.tagsContainerReference}
                            className={classNames(
                                styles.detailsWrapper,
                                styles.detailsWrapperOverlay,
                                {
                                    [styles.detailsWrapperHide]:               !this.props.query.length,
                                    [styles.detailsWrapperReduced]:            this.props.hasHeaderParent && !this.isFocusedWithin(),
                                    [styles.detailsWrapperTagList]:            this.props.mode === StartPageSearchMode.tagQueryTree,
                                    [styles.detailsWrapperOverlayWithResults]: this.props.resultList.length && this.props.mode === StartPageSearchMode.resultList,
                                },
                            )}
                            onFocus={this.onResultFocus}
                            onBlur={this.onResultBlur}
                            tabIndex={'-1'} // This enables the onFocus & onBlur events
                        >
                            {this.renderDetailsContent()}
                        </div>
                    </div>
                </>
            );
        }

        return null;
    };

    renderDetailsContent = () => {
        if (this.props.mode === StartPageSearchMode.resultList) {
            return this.renderDetailsResultList();
        }

        return null;
    };

    renderDetailsResultList = () => {
        return (
            this.props.resultList
        );
    };

    selectIsFocused = (isFocused) => {
        this.setState({
            isDropdownFocused: isFocused,
        });
    };

    focusResults = () => {
        if (!Device.isIOS) {
            this.resultContainerReference.current.focus();
        }
    };

    renderTagQueryTree = () => {
        return (
            <TagSelector
                tags={this.props.tagQuery}
                initialOpened={this.state.tagSearchActive}
                onAddNextChild={this.props.onAddNextChild}
                addTagQuery={this.props.addTagQuery}
                onAddSibling={this.props.onAddSibling}
                onTagChange={this.props.onTagChange}
                onTagDelete={this.props.onTagDelete}
                showReducedTags={this.props.hasHeaderParent && !this.isFocusedWithin()}
                selectIsFocused={this.selectIsFocused}
                focusResults={this.focusResults}
                rootTags={this.props.rootTags}
                searchTipTranslationKey={'selectTagsTip'}
            />
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const StartPageSearch = Component;

Component.propTypes = {
    addTagQuery:              PropTypes.func,
    allowOverflowScrolling:   PropTypes.bool,
    clearSearchResultsAction: PropTypes.func,
    hasHeaderParent:          PropTypes.bool,
    headerInputFocused:       PropTypes.bool,
    isLoading:                PropTypes.bool,
    mode:                     PropTypes.oneOf(Object.keys(StartPageSearchMode)),
    onAddNextChild:           PropTypes.func,
    onAddSibling:             PropTypes.func,
    onTagChange:              PropTypes.func,
    onTagDelete:              PropTypes.func,
    onTextChange:             PropTypes.func,
    openUrl:                  PropTypes.func,
    possibleResultsCount:     PropTypes.number,
    query:                    PropTypes.string,
    resultList:               PropTypes.oneOfType([
        PropTypes.children,
        PropTypes.array,
    ]),
    rootTags:                 PropTypes.array,
    selectIsFocused:          PropTypes.func,
    tagQuery:                 PropTypes.array,
    allowInitialTagSearch:    PropTypes.bool,
    showSearchButton:         PropTypes.bool,
};

Component.defaultProps = {
    addTagQuery:              _.noop,
    allowOverflowScrolling:   true,
    clearSearchResultsAction: _.noop,
    hasHeaderParent:          false,
    headerInputFocused:       false,
    isLoading:                false,
    mode:                     StartPageSearchMode.none,
    onAddNextChild:           _.noop,
    onAddSibling:             _.noop,
    onTagChange:              _.noop,
    onTagDelete:              _.noop,
    onTextChange:             _.noop,
    openUrl:                  _.noop,
    possibleResultsCount:     0,
    query:                    null,
    resultList:               [],
    rootTags:                 [],
    selectIsFocused:          _.noop,
    tagQuery:                 [],
    allowInitialTagSearch:    false,
    showSearchButton:         true,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'isInputFocused',
    'isResultFocused',
    'isDropdownFocused',
    'tagSearchActive',
];

export default Component;
