//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import PropTypes       from '@/components/PropTypes';
import ComponentHelper from '@/helper/ComponentHelper';
import { ImageUpload } from '@/components/stateless/atomic/ImageUpload';
import ImageUploadSize from '@/components/stateless/atomic/ImageUpload/ImageUploadSize';
import File            from '@/helper/File';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    onImageDeleted = (index) => () => {
        this.props.onImageDeleted(null, index);
    };

    onImageUploaded = (index) => (uploadedLogo) => {
        File
            .getFileFromBlob(uploadedLogo)
            .then((image) => {
                this.props.onImageUploaded(image, index);
            });
    };

    renderPhoto = (image, index) => {
        return (
            <div
                className={styles.photosPreviewImageWrapper}
                key={index}
            >
                <ImageUpload
                    uploadFileCallback={this.onImageUploaded(index)}
                    deleteFileCallback={this.onImageDeleted(index)}
                    images={[image]}
                    size={ImageUploadSize.default}
                />
            </div>
        );
    };

    render() {
        const images = [
            ...this.props.images,
            null,
        ];

        return (
            <div className={styles.photosPreviewRow}>
                {_.map(images, this.renderPhoto)}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const PhotosPreview = Component;

Component.propTypes = {
    images:          PropTypes.array,
    onImageDeleted:  PropTypes.func,
    onImageUploaded: PropTypes.func,

};

Component.defaultProps = {
    images:          [],
    onImageDeleted:  _.noop,
    onImageUploaded: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
