//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    BING_MAPS_API_KEY: 'AmpM2vSRWHnC4gHxlfLndzRrTlrbPHXdJTu6KD0sBrkVIbNLVArd8aHyMHkqe0dQ', // Original supplydu key: AqI6H1FwmrOVOiVvG03f3JeqIh7Lhz8dZoLfQ5N5QhjNL6yje_RXkllv66oD_mwC
};
