//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import _                         from 'lodash';
import { bindActionCreators }    from 'redux';
import { connect }               from 'react-redux';
import ComponentHelper           from '@/helper/ComponentHelper';
import PropTypes                 from '@/components/PropTypes';
import StatelessAddNewMachineCTA from '@/components/stateless/composed/AddNewMachineCTA';
import { MachineActions }        from '@/store/actions/machine';

export class Component extends React.Component {
    onOpenAddNewMachineClicked = () => {
        const { apiMode, name } = this.props;

        this.props.openAddNewMachineOverlay({
            apiMode,
            name,
        });
    };

    render() {
        return (
            <StatelessAddNewMachineCTA
                onOpenAddNewMachineClicked={this.onOpenAddNewMachineClicked}
                productName={this.props.name}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AddNewMachineCTA = Component;

Component.propTypes = {
    apiMode:                  PropTypes.apiMode,
    name:                     PropTypes.string,
    openAddNewMachineOverlay: PropTypes.func,
};

Component.defaultProps = {
    apiMode:                  null,
    name:                     null,
    openAddNewMachineOverlay: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    MachineActions,
    dispatch,
);

export default connect(
    null,
    mapDispatchToProps,
)(Component);
