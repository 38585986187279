//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import PropTypes       from '@/components/PropTypes';
import ColorBoxColor   from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import ColorBox        from '@/components/stateless/atomic/ColorBox';
import ComponentHelper from '@/helper/ComponentHelper';
import Avatar          from '@/components/stateless/atomic/Avatar';
import EntityLabelSize from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    renderServiceBody = () => {
        return (
            <div className={styles.requestMessageServiceInfoWrapper}>
                <div className={styles.requestMessageServiceImage}>
                    <Avatar
                        image={this.props.image}
                        fallback={this.props.title}
                        withoutLink={true}
                        size={EntityLabelSize.small}
                    />
                </div>
                <div className={styles.requestMessageServiceInfos}>
                    <div className={styles.requestMessageServiceTitle}>
                        {this.props.title}
                    </div>
                    <div className={styles.requestMessageServiceDescription}>
                        {this.props.description}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const props = this.props;

        if (props.showOnlyBody) {
            return this.renderServiceBody();
        }

        return (
            <div className={styles.requestMessageServiceWrapper}>
                <h4>
                    {props.sectionTitle}
                </h4>
                <ColorBox color={ColorBoxColor.grayLight}>
                    {this.renderServiceBody()}
                </ColorBox>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    description:  PropTypes.string,
    image:        PropTypes.image,
    sectionTitle: PropTypes.string,
    showOnlyBody: PropTypes.bool,
    title:        PropTypes.string,
};

Component.defaultProps = {
    description:  null,
    image:        null,
    sectionTitle: null,
    showOnlyBody: false,
    title:        null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export const RequestMessageService = Component;

export default Component;
