//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import colors from '@/styles/colors.module.scss';
import _      from 'lodash';
import I18n   from 'i18next';

const capacityStatus = {
    busy:  'BUSY',
    inUse: 'IN_USE',
    free:  'FREE',
};

const emptyCapacityStatus = 'EMPTY_STATUS';
const baseTranslationPath = 'machineCapacityStatus';

const capacityColors = {
    [capacityStatus.busy]:  colors.colorRedCinnabar,
    [capacityStatus.inUse]: colors.colorOrange,
    [capacityStatus.free]:  colors.colorGreenEmerald,
};

const capacityTranslationKey = {
    [capacityStatus.busy]:  `${baseTranslationPath}Busy`,
    [capacityStatus.inUse]: `${baseTranslationPath}InUse`,
    [capacityStatus.free]:  `${baseTranslationPath}Free`,
    [capacityStatus.free]:  `${baseTranslationPath}Free`,
};

export const yearsToShow = 4;

export const getColorByCapacity = (capacity) => {
    return _.get(capacityColors, capacity, colors.colorGrayHeather);
};

export const getTextByCapacity = (capacity) => {
    const translationKey = _.get(capacityTranslationKey, capacity);

    return I18n.t(translationKey);
};

export const getCapacityOptions = () => {
    return _.map(capacityStatus, (status) => {
        return {
            value: status,
            label: getTextByCapacity(status),
        };
    });
};

export const noMemberCapacityStatus = emptyCapacityStatus;

export default capacityStatus;
