//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import I18n       from 'i18next';
import classNames from 'classnames';
import _          from 'lodash';

import { IconTextButton } from '@/components/stateless/atomic/IconTextButton';
import IconType           from '@/components/stateless/atomic/Icon/IconType';
import ProjectStatus      from '@/constants/ProjectStatus';
import ComponentHelper    from '@/helper/ComponentHelper';
import PropTypes          from '@/components/PropTypes';
import BadgeCount         from '@/components/stateless/atomic/BadgeCount';

import styles from './styles.module.scss';

import 'react-datepicker/dist/react-datepicker.css';

export class OwnProjectListElement extends React.Component {
    static propTypes = {
        companyId:       PropTypes.string,
        headline:        PropTypes.string,
        name:            PropTypes.string,
        numberOfMatches: PropTypes.number,
        onDetailClick:   PropTypes.func,
        projectId:       PropTypes.string,
        projectName:     PropTypes.string,
        projectRuntime:  PropTypes.string,
        projectVolume:   PropTypes.number,
        status:          PropTypes.oneOfObjectValues(ProjectStatus),
    };

    static defaultProps = {
        companyId:       null,
        headline:        null,
        name:            null,
        numberOfMatches: 0,
        onDetailClick:   _.noop,
        projectId:       null,
        projectName:     null,
        projectRuntime:  null,
        projectVolume:   0,
        status:          ProjectStatus.OPEN,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    renderStatus = () => {
        const { status }           = this.props;
        const statusValueClassname = classNames(
            styles.statusValue,
            {
                [styles.colorBlack]:  status === ProjectStatus.OPEN,
                [styles.colorOrange]: status === ProjectStatus.INTERESTED,
                [styles.colorRed]:    status === ProjectStatus.REJECTED,
            },
        );

        return (
            <div className={styles.status}>
                <span className={styles.statusLabel}>
                    {I18n.t('status')}
                </span>
                <span
                    className={statusValueClassname}
                >
                    {I18n.t(status)}
                </span>
            </div>
        );
    };

    renderBadgeCount = () => {
        const { numberOfMatches, status } = this.props;

        if (status === ProjectStatus.CLOSED) {
            return null;
        }

        return (
            <BadgeCount
                count={numberOfMatches}
            />
        );
    };

    renderMatch = () => {
        return (
            <div className={styles.match}>
                {this.renderBadgeCount()}
                <div className={styles.matchText}>
                    {I18n.t('matches')}
                </div>
            </div>
        );
    };

    renderActions = () => {
        return (
            <div className={styles.actions}>
                <div className={styles.innerActions}>
                    <IconTextButton
                        onClick={this.props.onDetailClick}
                        iconType={IconType.info}
                        text={I18n.t('details')}
                    />
                </div>
            </div>
        );
    };

    renderProject = () => {
        return (
            <div className={styles.projectWrapper}>
                <h2 className={styles.projectName}>
                    {this.props.projectName}
                </h2>
                <div className={styles.projectRuntimeVolumeWrapper}>
                    <div className={styles.projectRuntime}>
                        {I18n.t('runtime')} {this.props.projectRuntime}
                    </div>
                    <div className={styles.projectVolume}>
                        {I18n.t('volume')} {this.props.projectVolume}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    {this.renderProject()}
                </div>
                <div className={styles.middle}>
                    {this.renderMatch()}
                </div>
                <div className={styles.right}>
                    {this.renderActions()}
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default OwnProjectListElement;
