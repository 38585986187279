//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class Text {
    static replaceUmlauts(text) {
        return text
            .replace('ß', 'ss')
            .replace('ä', 'ae')
            .replace('ö', 'oe')
            .replace('ü', 'ue')
            .replace('Ä', 'Ae')
            .replace('Ö', 'Oe')
            .replace('Ü', 'Ue');
    }

    static countUmlauts(text) {
        return (
            text.match(/[öäüÖÄÜß]/g) || []
        ).length;
    }
}

export default Text;
