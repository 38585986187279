//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

/**
 * @param key
 * @param objectToClean
 */
const removeLeadingHydraFromKey = (key, objectToClean) => {
    let newKey             = key;
    const newObjectToClean = _.clone(objectToClean);

    if (newKey.indexOf('hydra:') === 0) {
        newObjectToClean[newKey.substr(6)] = newObjectToClean[newKey];

        delete newObjectToClean[newKey];

        newKey = newKey.substr(6);
    }

    if (typeof newObjectToClean[newKey] === 'string' && newObjectToClean[newKey].indexOf('hydra:') === 0) {
        newObjectToClean[newKey] = newObjectToClean[newKey].substr(6);
    }

    return [newKey, newObjectToClean];
};

const isHydraResponse = (responseData) => {
    return _.keysIn(responseData).includes('@context');
};

/**
 *
 * @param key
 * @param objectToClean
 */
const removeHydraSearch = (key, objectToClean) => {
    const newObjectToClean = objectToClean;

    if (key === 'search' || key === 'context') {
        delete newObjectToClean[key];
    }

    return newObjectToClean;
};

/**
 * @param key
 * @param objectToClean
 */
const cleanupIds = (key, objectToClean) => {
    const newObjectToClean = _.clone(objectToClean);

    if (key === '@id') {
        const iriId          = newObjectToClean['@id'];
        newObjectToClean.iri = iriId;
        newObjectToClean.id  = _.split(iriId, '/').pop();
    }

    return newObjectToClean;
};

const cleanupObject = (objectToClean) => {
    let newObjectToClean = _.clone(objectToClean);
    const keys           = Object.keys(objectToClean || {});

    for (let key of keys) {
        if (newObjectToClean[key]) {
            [key, newObjectToClean] = removeLeadingHydraFromKey(key, newObjectToClean);

            newObjectToClean = removeHydraSearch(key, newObjectToClean);

            if (_.isArray(newObjectToClean[key])) {
                const arrayOfChild    = _.clone(newObjectToClean[key]);
                newObjectToClean[key] = [];

                for (const childObjectToClean of arrayOfChild) {
                    const cleanedChild = cleanupObject(childObjectToClean);

                    newObjectToClean[key].push(cleanedChild);
                }
            } else if (_.isObject(newObjectToClean[key])) {
                newObjectToClean[key] = cleanupObject(newObjectToClean[key]);
            }

            newObjectToClean = cleanupIds(key, newObjectToClean);
        }
    }

    return newObjectToClean;
};

const getMembersFromResponse = (response) => {
    const data            = (
        response.data
            ? response.data
            : response
    );
    const cleanedResponse = cleanupObject(data);

    if (_.has(
        cleanedResponse,
        [
            'member',
        ],
    )) {
        return _.get(
            cleanedResponse,
            [
                'member',
            ],
        );
    }

    return [];
};

const getFirstMemberFromResponse = (response) => {
    const data    = (
        response.data
            ? response.data
            : response
    );
    const members = getMembersFromResponse(data);

    if (members.length > 0) {
        return members[0];
    }

    return {};
};

/**
 * @param iri
 */
const getIdFromIri = (iri) => {
    if (iri) {
        const splittedIri = iri.split('/');

        return splittedIri[3];
    }

    return iri;
};

const getIriFromId = (entity, id) => {
    if (
        !entity ||
        !id
    ) {
        return null;
    }

    return `/api/${entity}/${id}`;
};

export default {
    cleanupIds,
    cleanupObject,
    getIdFromIri,
    getIriFromId,
    getMembersFromResponse,
    getFirstMemberFromResponse,
    isHydraResponse,
    removeHydraSearch,
    removeLeadingHydraFromKey,
};
