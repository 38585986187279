//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import I18n                   from 'i18next';
import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import { HeadlineMedium }     from '@/components/stateless/atomic/HeadlineMedium';
import { getColorByCapacity } from '@/constants/MachineCapacity';
import { FullImage }          from '@/components/stateless/composed/FullImage';

import styles from './styles.module.scss';

export class Component extends React.Component {
    getDotColorByCapacity = (capacity) => {
        return {
            backgroundColor: getColorByCapacity(capacity),
        };
    };

    getButtonProps = (index) => {
        return {
            key: `machine-header-${index}`,
        };
    };

    render() {
        return (
            <div className={styles.machineHeaderWrapper}>
                <div className={styles.imageAndCapacitiesWrapper}>
                    <FullImage
                        image={this.props.image}
                        alternativText={`${this.props.title} ${I18n.t('image')}`}
                        width={120}
                    />
                    {this.renderCapacities()}
                </div>
                <div className={styles.machineHeaderTextWrapper}>
                    <div className={styles.machineHeaderTopWrapper}>
                        <div className={styles.machineHeaderTitleWrapper}>
                            <HeadlineMedium text={this.props.title} />
                            {this.renderManufacturer()}
                        </div>
                        <div className={styles.machineHeaderButtonWrapper}>
                            {this.renderButtons()}
                        </div>
                    </div>
                    <div className={styles.machineHeaderText}>
                        {this.props.text}
                    </div>
                </div>
            </div>
        );
    }

    renderCapacity = (capacity) => {
        return (
            <div
                className={styles.capacity}
                key={capacity.year}
            >
                <div
                    className={styles.capacityDot}
                    style={this.getDotColorByCapacity(capacity.capacity)}
                />
                <div className={styles.year}>
                    {capacity.year}
                </div>
            </div>
        );
    };

    renderCapacities = () => {
        const capacities = this.props.capacities;

        if (
            !capacities ||
            capacities.length === 0
        ) {
            return null;
        }

        return (
            <div className={styles.capacities}>
                <div className={styles.title}>
                    {I18n.t('capacity')}
                </div>
                <div className={styles.subtitle}>
                    {I18n.t('forecast')}
                </div>
                {this.props.capacities.map(this.renderCapacity)}
            </div>
        );
    };

    renderButton = (button) => {
        return button;
    };

    renderButtons = () => {
        return ComponentHelper.mapChildren(
            this.props.children,
            this.getButtonProps,
            this.renderButton,
        );
    };

    renderManufacturer = () => {
        if (this.props.manufacturer) {
            return (
                <span>
                    {`${I18n.t('manufacturer')}: `}
                    {this.props.manufacturer}
                </span>
            );
        }

        return null;
    };
}

export const MachineHeader = Component;

Component.propTypes = {
    children:         PropTypes.children,
    image:            PropTypes.image,
    manufacturer:     PropTypes.string,
    text:             PropTypes.string,
    title:            PropTypes.string,
    capacities:       PropTypes.array,
    currentCompanyId: PropTypes.string,
};

Component.defaultProps = {
    children:         null,
    image:            null,
    manufacturer:     null,
    text:             null,
    title:            null,
    capacities:       [],
    currentCompanyId: null,
};

export default Component;
