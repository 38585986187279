//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import PropTypes       from '@/components/PropTypes';
import ComponentHelper from '@/helper/ComponentHelper';
import DropDownInput   from '@/components/stateless/atomic/DropDownInput';
import Employee        from '@/constants/Employee';

export class Component extends React.Component {
    mapEmployee = () => {
        return _.map(Employee, (value, key) => {
            return {
                value: key,
                label: value,
            };
        });
    };

    render() {
        const options      = this.mapEmployee();
        const defaultValue = _.find(options, {
            label: this.props.employeeValue,
        });

        return (
            <DropDownInput
                options={options}
                defaultValue={defaultValue}
                onChange={this.props.employeeValueChanged}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const EmployeeDropDown = Component;

Component.propTypes = {
    employeeValueChanged: PropTypes.func,
    employeeValue:        PropTypes.string,
};

Component.defaultProps = {
    employeeValueChanged: _.noop,
    employeeValue:        null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
