//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiUrls from '@/constants/ApiUrls';

export default (api) => {
    return {
        fetchMembership: () => {
            return api.get(ApiUrls.API_MEMBERSHIPS_URL);
        },
        requestMembership: (data) => {
            return api.post(
                ApiUrls.API_MEMBERSHIP_REQUEST_URL,
                data,
            );
        },
    };
};
