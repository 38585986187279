//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as Api                   from '@/api';
import { AlertBoxActions }        from '@/store/actions/alertBox';
import { MembershipRequestTypes } from '@/store/actions/membershipRequest';
import I18n                       from 'i18next';
import { call }                   from 'redux-saga/effects';
import { put }                    from 'redux-saga/effects';
import { takeLatest }             from 'redux-saga/effects';

function* requestMembership(action) {
    const {
        companyIri,
        address,
        country,
        city,
        state,
        region,
        taxId,
        membership,
        preferredOnsiteVerificationDate,
        promotionCode,
    }        = action;
    const response = yield call(
        Api.context.membership.requestMembership,
        {
            company:    companyIri,
            address,
            city,
            state,
            region,
            taxId,
            promotionCode,
            preferredOnsiteVerificationDate,
            country:    country?.label,
            membership: membership?.value,
        },
    );

    if (response.ok) {
        yield put(AlertBoxActions.clearAlerts());
        yield put(AlertBoxActions.showSuccessAlert({
            title: I18n.t('sentMembershipRequestTitle'),
            text:  I18n.t('sentMembershipRequestText'),
        }));
    } else {
        yield put(AlertBoxActions.showErrorAlert({
            title: I18n.t('sentMembershipRequestTitle'),
            text:  I18n.t('sentMembershipRequestText'),
        }));
    }
}

export const callMembershipRequestSaga = () => {
    return [
        // @formatter:off
        takeLatest([MembershipRequestTypes.REQUEST_MEMBERSHIP], requestMembership),
        // @formatter:on
    ];
};
