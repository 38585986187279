//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ColorButtonTheme from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import { Icon }         from '@/components/stateless/atomic/Icon';
import IconType         from '@/components/stateless/atomic/Icon/IconType';
import { Tooltip }      from '@/components/stateless/atomic/Tooltip';
import VerifiedProps    from '@/constants/VerifiedProps';
import Download         from '@/helper/Download';
import Url              from '@/helper/Url';
import _                from 'lodash';
import React            from 'react';
import classNames       from 'classnames';
import I18n             from 'i18next';

import Avatar           from '@/components/stateless/atomic/Avatar';
import BestMatchLabel   from '@/components/stateless/atomic/BestMatchLabel';
import CountryFlag      from '@/components/stateless/atomic/CountryFlag';
import EntityLabelSize  from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import HeadlineMedium   from '@/components/stateless/atomic/HeadlineMedium';
import ColorButton      from '@/components/stateless/atomic/ColorButton';
import PropTypes        from '@/components/PropTypes';
import SelectionHelper  from '@/helper/SelectionHelper';
import EntityLabelTheme from '@/components/stateless/composed/EntityLabel/EntityLabelTheme';
import { Link }         from '@/components/stateless/atomic/Link';

import styles from './styles.module.scss';

export class Component extends React.Component {
    certificateDownloadClicked = () => {
        const { verified, title } = this.props;
        const { certificate }     = verified;
        const { path }            = certificate;

        if (path) {
            const fileExtension = path.split('.').pop();

            Download.downloadFile(
                Url.getBackendUrl(path),
                I18n.t(
                    'certificateName',
                    {
                        companyName: title,
                        fileExtension,
                    },
                ),
            );
        }
    };

    getTitle() {
        if (!this.props.title) {
            return '';
        }

        const splittedTitle = this.props.title.split(' ');

        return splittedTitle.map((titlePart) => {
            const partLength = titlePart.length;

            if (partLength > 15) {
                const partLengthHalf = partLength / 2;

                return `${titlePart.substring(0, partLengthHalf)}​${titlePart.substring(partLengthHalf)} `; /* eslint-disable-line no-irregular-whitespace */
            }

            return `${titlePart} `;
        }).join(' ');
    }

    renderLink = (title) => {
        const headline = (
            <HeadlineMedium
                text={title}
                className={styles.headline}
            />
        );

        if (this.props.to) {
            return (
                <Link to={this.props.to}>
                    {headline}
                </Link>
            );
        }

        return headline;
    };

    renderVerifiedInLabelContainer = () => {
        if (this.props.inlineVerified) {
            return null;
        }

        return this.renderVerified();
    };

    renderVerifiedInline = () => {
        if (!this.props.inlineVerified) {
            return null;
        }

        return (
            <div>
                {this.renderVerified()}
            </div>
        );
    };

    render() {
        const title = this.getTitle();

        return (
            <div
                className={classNames(
                    styles.entityLabelWrapper,
                    SelectionHelper.get(
                        this.props.size,
                        {
                            [EntityLabelSize.small]:   styles.entityLabelWrapperSmall,
                            [EntityLabelSize.default]: styles.entityLabelWrapperDefault,
                        },
                    ),
                    SelectionHelper.get(
                        this.props.theme,
                        {
                            [EntityLabelTheme.white]: styles.entityLabelWrapperWhite,
                        },
                    ),
                    this.props.className,
                )}
            >
                <div className={styles.entityLabelAvatarContainer}>
                    <Avatar
                        image={this.props.avatar}
                        fallback={this.props.title}
                        size={this.props.size}
                        to={this.props.to}
                        withoutLink={this.props.withoutLink}
                        onImageLoaded={this.props.onImageLoaded}
                    />
                </div>
                <div className={styles.entityLabelTextContainer}>
                    <div className={styles.entityLabelTextTitle}>
                        <div className={styles.entityLinkVerifiedContainer}>
                            {this.renderLink(title)}
                            {this.renderVerifiedInline()}
                        </div>
                        <div className={styles.labelContainer}>
                            {this.renderVerifiedInLabelContainer()}
                            {this.renderBestMatchLabel()}
                            {this.renderFlag()}
                        </div>
                    </div>
                    <div className={styles.entityLabelTextSubtitle}>
                        {this.props.subtitle}
                    </div>
                </div>
            </div>
        );
    }

    renderBestMatchLabel = () => {
        if (this.props.isBestMatch) {
            return (
                <BestMatchLabel />
            );
        }

        return null;
    };

    renderFlag = () => {
        if (this.props.iso31661Alpha2CountryCode) {
            return (
                <div className={styles.entityLabelTextFlag}>
                    <CountryFlag
                        iso31661Alpha2CountryCode={this.props.iso31661Alpha2CountryCode}
                    />
                </div>
            );
        }

        return null;
    };

    renderCertificate = (certificate) => {
        if (!certificate) {
            return null;
        }

        return (
            <Icon iconType={IconType.eye} />
        );
    };

    renderTooltip = () => {
        return (
            <div className={styles.tooltip}>
                <strong>
                    {I18n.t('verifiedCertificate')}
                </strong>
                <ColorButton
                    text={I18n.t('showCertificates')}
                    icon={IconType.eye}
                    theme={ColorButtonTheme.transparentBorder}
                    onClick={this.certificateDownloadClicked}
                />
            </div>
        );
    };

    renderVerified = () => {
        const { verified } = this.props;

        if (verified.verified) {
            return (
                <Tooltip content={this.renderTooltip()}>
                    <div className={styles.entityLabelTextVerified}>
                        {I18n.t('verified')}
                        {this.renderCertificate(verified.certificate)}
                    </div>
                </Tooltip>
            );
        }

        if (verified.underVerification) {
            return (
                <div className={styles.entityLabelTextUnderVerification}>
                    {I18n.t('underVerification')}
                </div>
            );
        }

        return null;
    };
}

export const EntityLabel = Component;

Component.propTypes = {
    avatar:                    PropTypes.image,
    className:                 PropTypes.string,
    isBestMatch:               PropTypes.bool,
    iso31661Alpha2CountryCode: PropTypes.string,
    onImageLoaded:             PropTypes.func,
    size:                      PropTypes.oneOfObjectValues(EntityLabelSize),
    subtitle:                  PropTypes.string,
    theme:                     PropTypes.oneOfObjectValues(EntityLabelTheme),
    title:                     PropTypes.string,
    to:                        PropTypes.object,
    verified:                  PropTypes.object,
    inlineVerified:            PropTypes.bool,
    withoutLink:               PropTypes.bool,
};

Component.defaultProps = {
    avatar:                    null,
    className:                 null,
    isBestMatch:               false,
    iso31661Alpha2CountryCode: null,
    onImageLoaded:             _.noop,
    size:                      EntityLabelSize.small,
    subtitle:                  '',
    theme:                     EntityLabelTheme.default,
    title:                     '',
    to:                        null,
    verified:                  VerifiedProps,
    inlineVerified:            false,
    withoutLink:               false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
