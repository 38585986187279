//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import ColorBoxColor     from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import { ColorBox }      from '@/components/stateless/atomic/ColorBox';
import I18n              from 'i18next';
import { HeadlineSmall } from '@/components/stateless/atomic/HeadlineSmall';
import PropTypes         from '@/components/PropTypes';
import { Link }          from '@/components/stateless/atomic/Link';
import Routes            from '@/constants/Routes';
import Route             from '@/helper/Route';

import { Icon } from '@/components/stateless/atomic/Icon';
import IconType from '@/components/stateless/atomic/Icon/IconType';
import styles   from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { company, companyId } = this.props;

        return (
            <Link
                to={Route.buildRoute(Routes.companyMessage, companyId)}
                className={styles.messageCTA}
            >
                <ColorBox color={ColorBoxColor.grayLight}>
                    <div className={styles.messageCTAIcon}>
                        <Icon iconType={IconType.envelope} />
                    </div>
                    <div className={styles.messageCTAText}>
                        <HeadlineSmall text={I18n.t('contact')} />
                        <p>
                            {I18n.t('sendRequestTo', {
                                company,
                            })}
                        </p>
                    </div>
                    <Icon iconType={IconType.caretRight} />
                </ColorBox>
            </Link>
        );
    }
}

export const CallToActionMessageBlock = Component;

Component.propTypes = {
    company:   PropTypes.string,
    companyId: PropTypes.string,
};

Component.defaultProps = {
    company:   null,
    companyId: null,
};

export default Component;
