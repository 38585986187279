//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import _          from 'lodash';
import classNames from 'classnames';

import ComponentHelper  from '@/helper/ComponentHelper';
import PropTypes        from '@/components/PropTypes';
import { ColorButton }  from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import IconType         from '@/components/stateless/atomic/Icon/IconType';
import ColorButtonSize  from '@/components/stateless/atomic/ColorButton/ColorButtonSize';

import InformationBoxDirection from '@/components/stateless/composed/InformationBox/InformationBoxDirection';
import styles                  from './styles.module.scss';

export class Component extends React.Component {
    buttonClicked = (event) => {
        this.props.onClick(event);
    };

    renderCancelButton = () => {
        const { cancelButtonText, onCancelClick } = this.props;

        if (
            !cancelButtonText ||
            _.eq(onCancelClick, _.noop)
        ) {
            return null;
        }

        return (
            <ColorButton
                onClick={onCancelClick}
                text={cancelButtonText}
                theme={ColorButtonTheme.white}
                size={ColorButtonSize.small}
            />
        );
    };

    renderButton = () => {
        const { buttonText, buttonIcon } = this.props;

        if (!buttonText) {
            return null;
        }

        return (
            <ColorButton
                onClick={this.buttonClicked}
                text={buttonText}
                theme={ColorButtonTheme.orange}
                icon={buttonIcon}
                size={ColorButtonSize.small}
            />
        );
    };

    render() {
        const { headline, subline, direction } = this.props;

        return (
            <div
                className={classNames(
                    styles.informationBox,
                    {
                        [styles.column]: direction === InformationBoxDirection.column,
                    },
                )}
            >
                <div className={styles.informationBoxTextWrapper}>
                    <div className={styles.informationBoxHeadline}>
                        {headline}
                    </div>
                    <div className={styles.informationBoxContent}>
                        {subline}
                    </div>
                </div>
                <div className={styles.informationBoxButton}>
                    {this.renderButton()}
                    {this.renderCancelButton()}
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const InformationBox = Component;

Component.propTypes = {
    buttonText:       PropTypes.string,
    buttonIcon:       PropTypes.oneOf(Object.values(IconType)),
    headline:         PropTypes.string,
    onClick:          PropTypes.func,
    subline:          PropTypes.string,
    cancelButtonText: PropTypes.string,
    onCancelClick:    PropTypes.func,
    direction:        PropTypes.oneOf(Object.values(InformationBoxDirection)),
};

Component.defaultProps = {
    buttonText:       null,
    buttonIcon:       IconType.search,
    headline:         null,
    onClick:          _.noop,
    subline:          null,
    cancelButtonText: null,
    onCancelClick:    _.noop,
    direction:        InformationBoxDirection.row,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
