//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    addMachine:                'addMachine',
    addNewMachine:             'addNewMachine',
    addNewMachineManufacturer: 'addNewMachineManufacturer',
    addNewProduct:             'addNewProduct',
    closeProjectOverlay:       'closeProjectOverlay',
    confirmOverlay:            'confirmOverlay',
    confirmBeingActive:        'confirmBeingActive',
    createProject:             'createProject',
    deleteMachine:             'deleteMachine',
    removeMachine:             'removeMachine',
    editCompany:               'editCompany',
    editFactSheetOverlay:      'editFactSheetOverlay',
};
