//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { connect }     from 'react-redux';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import Environment     from '@/helper/Environment';
import ReactGA         from 'react-ga4';

export class Component extends React.Component {
    render() {
        const { cookiesAccepted } = this.props;

        if (cookiesAccepted) {
            if (Environment.isStaging()) {
                ReactGA.initialize('G-MX7FGVSRM4');
            } else if (Environment.isProduction()) {
                ReactGA.initialize('G-FP4Y18TGN2');
            }
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const GoogleAnalytics = Component;

Component.propTypes = {
    cookiesAccepted: PropTypes.bool,
};

Component.defaultProps = {
    cookiesAccepted: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        cookiesAccepted: state.cookieBanner.accepted,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
