//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }             from 'redux-saga/effects';
import { call }            from 'redux-saga/effects';
import { takeLatest }      from 'redux-saga/effects';
import * as Api            from '@/api';
import { UserActions }     from '@/store/actions/user';
import HydraHelper         from '@/helper/Hydra';
import _                   from 'lodash';
import { FavoriteTypes }   from '@/store/actions/favorite';
import { FavoriteActions } from '@/store/actions/favorite';
import { CompanyActions }  from '@/store/actions/company';
import SagaStateHelper     from '@/helper/SagaStateHelper';
import State               from '@/helper/State';

function* patchUserOrCompany(patchedFavoriteCompanies) {
    const state          = yield SagaStateHelper.getState();
    const userCompany    = State.getUserCompany(state);
    const userCompanyIri = State.getUserCompanyIri(state);
    const userIri        = State.getUserIri(state);
    const targetIri      = (
        userCompany ?
            userCompanyIri :
            userIri
    );
    const targetApiCall  = (
        userCompany ?
            Api.patchCompany :
            Api.patchUser
    );
    const patchResponse  = yield call(
        targetApiCall,
        targetIri,
        patchedFavoriteCompanies,
    );

    return patchResponse;
}

function* patchFavoriteCompany(
    companyIri,
    add,
    succeededAction,
    failedAction,
) {
    const state           = yield SagaStateHelper.getState();
    const userCompanyIri  = State.getUserCompanyIri(state);
    let favoriteCompanies = State.getFavoriteCompanies(state);

    if (add) {
        favoriteCompanies.push(companyIri);
    } else {
        favoriteCompanies = _.remove(
            favoriteCompanies,
            (favoriteCompany) => favoriteCompany !== companyIri,
        );
    }

    const patchedFavoriteCompanies = {
        favoriteCompanies,
    };
    const patchResponse            = yield patchUserOrCompany(patchedFavoriteCompanies);

    if (patchResponse.ok) {
        yield put(succeededAction({
            companyIri: userCompanyIri,
        }));
    } else {
        yield put(failedAction());
    }
}

function* addFavoriteCompany(action) {
    yield patchFavoriteCompany(
        _.get(action, 'company', null),
        true,
        FavoriteActions.addFavoriteCompanySucceeded,
        FavoriteActions.addFavoriteCompanyFailed,
    );
}

function* removeFavoriteCompany(action) {
    yield patchFavoriteCompany(
        _.get(action, 'company', null),
        false,
        FavoriteActions.removeFavoriteCompanySucceeded,
        FavoriteActions.removeFavoriteCompanyFailed,
    );
}

function* patchFavoriteCompanySucceeded(action) {
    const state       = yield SagaStateHelper.getState();
    const userCompany = State.getUserCompany(state);

    if (userCompany) {
        const { companyIri } = action;

        yield put(CompanyActions.fetchCompany({
            id: HydraHelper.getIdFromIri(companyIri),
        }));
    } else {
        yield put(UserActions.fetch());
    }
}

export const callFavoriteSagas = () => {
    return [
        // @formatter:off
        takeLatest([FavoriteTypes.ADD_FAVORITE_COMPANY],              addFavoriteCompany),
        takeLatest([FavoriteTypes.REMOVE_FAVORITE_COMPANY],           removeFavoriteCompany),
        takeLatest([
            FavoriteTypes.ADD_FAVORITE_COMPANY_SUCCEEDED,
            FavoriteTypes.REMOVE_FAVORITE_COMPANY_SUCCEEDED,
        ],                                                            patchFavoriteCompanySucceeded),
        // @formatter:on
    ];
};
