//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Tooltip }          from '@/components/stateless/atomic/Tooltip';
import React                from 'react';
import _                    from 'lodash';
import classNames           from 'classnames';
import I18n                 from 'i18next';
import ComponentHelper      from '@/helper/ComponentHelper';
import PropTypes            from '@/components/PropTypes';
import { LoadingIndicator } from '@/components/stateless/atomic/LoadingIndicator';
import IconType             from '@/components/stateless/atomic/Icon/IconType';
import ColorBoxColor        from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import HeadlineSmall        from '@/components/stateless/atomic/HeadlineSmall';
import ColorBox             from '@/components/stateless/atomic/ColorBox';
import TagSelector          from '@/components/stateless/composed/TagSelector';
import TextInput            from '@/components/stateless/atomic/TextInput';
import IconButton           from '@/components/stateless/atomic/IconButton';
import TagSearchResult      from '@/components/stateless/composed/TagSearchResult';
import Icon                 from '@/components/stateless/atomic/Icon';
import styles               from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addTag: null,
        };
    }

    addTagClicked = () => {
        this.props.resetTagQuery();
        this.setState({
            addTag: true,
        });
    };

    tagAdded = (tag) => {
        const newTag = tag.tag ? tag.tag : tag;

        this.props.addTag({
            tag:     newTag,
            context: this.props.tagContext,
        });
    };

    onTagAdded = (onTagSearchResultClicked, tag) => () => {
        onTagSearchResultClicked(tag);
        this.setState({
            addTag: null,
        });
    };

    onTagDeleted = (tag) => {
        this.props.deleteTag({
            tag,
            context: this.props.tagContext,
        });
    };

    onTagSearch = (event) => {
        const value = event.target.value;

        this.props.fetchTags({
            title: value,
        });
    };

    getTagSelectorPlaceholder = () => {
        if (!this.state.addTag) {
            return this.props.tagSelectorPlaceholder;
        }

        return null;
    };

    renderTooltip = () => {
        return (
            <p>
                <p>{I18n.t('cacheInvalidation')}</p>
                <span>{I18n.t('cacheInvalidationText')}</span>
            </p>
        );
    };

    render() {
        return (
            <div className={styles.tagSearchEditorBodyContainer}>
                <HeadlineSmall
                    text={this.props.title}
                    iconType={IconType.addCircle}
                    onClick={this.addTagClicked}
                    disabled={this.isDisabled()}
                >
                    <Tooltip content={this.renderTooltip()}>
                        <span className={styles.tagInfoIcon} />
                    </Tooltip>
                </HeadlineSmall>
                <div className={styles.tagSearchEditorInputContainer}>
                    <ColorBox color={ColorBoxColor.whiteGrayBorder}>
                        {this.renderTagsSearchBox()}
                        <TagSelector
                            tags={this.props.tagHierarchy}
                            onTagDelete={this.onTagDeleted}
                            addTagQuery={this.tagAdded}
                            placeholder={this.getTagSelectorPlaceholder()}
                            rootTags={this.props.rootTags}
                            disabled={this.isDisabled()}
                        />
                    </ColorBox>
                </div>
            </div>
        );
    }

    isDisabled = () => {
        return this.props.disabled;
    };

    renderTagsSearchBox = () => {
        if (this.state.addTag) {
            return (
                <>
                    <div
                        className={classNames(
                            styles.tagSearchEditorInputWrapper,
                            {
                                [styles.tagSearchEditorInputWrapperWithResults]: this.props.tagResultList.length,
                            },
                        )}
                    >
                        <TextInput
                            onChange={this.onTagSearch}
                            value={this.props.tagSearchQuery}
                            disabled={this.isDisabled()}
                        />
                        <div className={styles.tagSearchEditorInputIcon}>
                            {this.renderSearchIcon()}
                        </div>
                        <div className={styles.tagSearchEditorAbortSearch}>
                            <IconButton
                                iconType={IconType.cross}
                                onClick={this.resetTagSearch}
                            />
                        </div>
                    </div>
                    {this.renderTagResults(this.tagAdded, this.props.tagResultList)}
                </>
            );
        }

        return null;
    };

    renderSearchIcon = () => {
        if (this.props.isLoading) {
            return (
                <LoadingIndicator />
            );
        }

        return (
            <Icon iconType={IconType.search} />
        );
    };

    renderTagResult = (tagResult, index, onTagSearchResultClicked) => {
        return (
            <TagSearchResult
                key={`tag-search-result-${index}`}
                tagChain={tagResult.parentTagsAsString}
                highlightText={this.props.tagSearchQuery}
                onClick={this.onTagAdded(onTagSearchResultClicked, tagResult)}
            />
        );
    };

    renderTagResults = (onTagSearchResultClicked, tagResultList) => {
        return (
            <div className={styles.tagSearchEditorResultWrapper}>
                {tagResultList.map((tagResult, index) => {
                    return this.renderTagResult(tagResult, index, onTagSearchResultClicked);
                })}
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const TagSearchEditor = Component;

Component.propTypes = {
    addTag:                 PropTypes.func,
    deleteTag:              PropTypes.func,
    disabled:               PropTypes.bool,
    fetchTags:              PropTypes.func,
    isLoading:              PropTypes.bool,
    resetTagQuery:          PropTypes.func,
    tagContext:             PropTypes.string,
    tagHierarchy:           PropTypes.array,
    tagObjectPath:          PropTypes.string,
    tagResultList:          PropTypes.array,
    tagSearchQuery:         PropTypes.string,
    title:                  PropTypes.string,
    rootTags:               PropTypes.array,
    tagSelectorPlaceholder: PropTypes.string,
};

Component.defaultProps = {
    addTag:                 _.noop,
    deleteTag:              _.noop,
    disabled:               false,
    fetchTags:              _.noop,
    isLoading:              false,
    resetTagQuery:          _.noop,
    tagContext:             null,
    tagHierarchy:           [],
    tagObjectPath:          null,
    tagResultList:          [],
    tagSearchQuery:         null,
    title:                  I18n.t('tags'),
    rootTags:               [],
    tagSelectorPlaceholder: I18n.t('tagSelectorPlaceholder'),
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
