//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import _                          from 'lodash';
import I18n                       from 'i18next';
import ComponentHelper            from '@/helper/ComponentHelper';
import PropTypes                  from '@/components/PropTypes';
import { FullImage }              from '@/components/stateless/composed/FullImage';
import IconType                   from '@/components/stateless/atomic/Icon/IconType';
import IconTextButton             from '@/components/stateless/atomic/IconTextButton';
import { CollapsableTagList }     from '@/components/stateless/composed/CollapsableTagList';
import TagList                    from '@/components/stateless/composed/TagList';
import ShowIfUserIsAdminOfCompany from '@/components/connected/ShowIfUserIsAdminOfCompany';
import styles                     from './styles.module.scss';

export class Component extends React.Component {
    renderRequestButton = (product) => {
        return (
            <IconTextButton
                iconType={IconType.envelope}
                onClick={this.props.onRequestClicked(product)}
            />
        );
    };

    renderProduct = (product) => {
        const id            = _.get(product, 'id');
        const title         = _.get(product, 'title');
        const image         = _.get(product, 'image');
        const categoryTitle = _.get(product, 'category.title');
        const tagStrings    = _.get(product, 'tagStrings');

        return (
            <div
                key={id}
                className={styles.productionPortfolioItem}
            >
                <div className={styles.productionPortfolioHeader}>
                    <div className={styles.productionPortfolioHeaderTitle}>
                        <span className={styles.productionPortfolioHeaderTitleText}>
                            {title}
                        </span>
                        <span className={styles.productionPortfolioHeaderTitleCategory}>
                            {categoryTitle}
                        </span>
                    </div>
                    <div className={styles.productionPortfolioHeaderRequest}>
                        <ShowIfUserIsAdminOfCompany
                            currentCompanyId={this.props.currentCompanyId}
                            fallbackContent={this.renderRequestButton(product)}
                        >
                            <IconTextButton
                                onClick={this.props.onEditClicked(product)}
                                iconType={IconType.pencil}
                                text={I18n.t('edit')}
                            />
                            <IconTextButton
                                onClick={this.props.onDeleteClicked(product)}
                                iconType={IconType.remove}
                                text={I18n.t('delete')}
                            />
                        </ShowIfUserIsAdminOfCompany>
                    </div>
                </div>
                <div className={styles.productionPortfolioImageWrapper}>
                    <FullImage
                        className={styles.productionPortfolioImage}
                        image={image}
                        width={500}
                    />
                </div>
                <div className={styles.productionPortfolioFooter}>
                    <CollapsableTagList>
                        <TagList tags={tagStrings} />
                    </CollapsableTagList>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className={styles.productionPortfolio}>
                {_.map(this.props.productionPortfolios, this.renderProduct)}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ProductionPortfolio = Component;

Component.propTypes = {
    currentCompanyId:     PropTypes.string,
    onDeleteClicked:      PropTypes.func,
    onEditClicked:        PropTypes.func,
    onRequestClicked:     PropTypes.func,
    productionPortfolios: PropTypes.array,
};

Component.defaultProps = {
    currentCompanyId:     null,
    onDeleteClicked:      _.noop,
    onEditClicked:        _.noop,
    onRequestClicked:     _.noop,
    productionPortfolios: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
