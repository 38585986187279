//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { z } from 'zod';
import _     from 'lodash';
import I18n  from 'i18next';

export const SaveProfileSchema = z.object({
    name:             z.string().min(1),
    workingAtCompany: z.string().min(1),
    position:         z.any().transform((value, context) => {
        if (!value) {
            context.addIssue({
                code:    z.ZodIssueCode.custom,
                message: I18n.t('saveProfileDefinePosition'),
            });
        }

        return value ?? '';
    }),
    country:          z.string().min(1),
    email:            z.string().email(),
    plainPassword:    z.string().optional(),
    currentPassword:  z.string().optional(),
}).refine((data) => {
    const { plainPassword, currentPassword } = data;

    if (
        !_.isEmpty(plainPassword) &&
        _.isEmpty(currentPassword)
    ) {
        return false;
    }

    return true;
}, {
    message: 'Please enter your current password to change your password.',
    path:    ['currentPassword'],
});

export default {
    SaveProfileSchema,
};
