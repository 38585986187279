//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import VerifiedProps          from '@/constants/VerifiedProps';
import React                  from 'react';
import _                      from 'lodash';
import PropTypes              from '@/components/PropTypes';
import ColorBox               from '@/components/stateless/atomic/ColorBox';
import ColorBoxColor          from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import EntityLabelSize        from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import { EntityLabel }        from '@/components/stateless/composed/EntityLabel';
import Route                  from '@/helper/Route';
import Routes                 from '@/constants/Routes';
import { CollapsableTagList } from '@/components/stateless/composed/CollapsableTagList';
import TagList                from '@/components/stateless/composed/TagList';
import FavoriseCompanyButton  from '@/components/connected/FavoriseCompanyButton';
import * as Api               from '@/api';
import Company                from '@/helper/Company';
import styles                 from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const company         = _.get(this.props, 'company');
        const companyVerified = _.get(this.props, 'companyVerified');
        const companyId       = company.id;

        return (
            <ColorBox
                className={styles.favoriteCompanyBox}
                color={ColorBoxColor.grayLight}
            >
                <div className={styles.favoriteCompanyBoxHeader}>
                    <EntityLabel
                        title={company.name}
                        subtitle={Company.getCompanyTypesString(company.types)}
                        verified={companyVerified}
                        avatar={Api.getImagePath(_.get(company, 'logo.path'))}
                        iso31661Alpha2CountryCode={_.get(company, 'address.countryCode', null)}
                        to={Route.buildRoute(Routes.company, Route.getCompanyUrl(companyId, company.slug))}
                        size={EntityLabelSize.small}
                    />
                    <FavoriseCompanyButton
                        company={companyId}
                    />
                </div>
                <span className={styles.spacer}></span>
                <CollapsableTagList>
                    <TagList tags={_.get(company, 'competencestrings', [])} />
                </CollapsableTagList>
            </ColorBox>
        );
    }
}

export const CompanyFavorite = Component;

Component.propTypes = {
    company:         PropTypes.object,
    companyVerified: PropTypes.object,
};

Component.defaultProps = {
    company:         null,
    companyVerified: VerifiedProps,
};

export default Component;
