//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                       from 'react';
import I18n                        from 'i18next';
import { bindActionCreators }      from 'redux';
import { compose }                 from 'redux';
import { connect }                 from 'react-redux';
import { Trans }                   from 'react-i18next';
import _                           from 'lodash';
import appStyles                   from '@/styles.module.scss';
import ComponentHelper             from '@/helper/ComponentHelper';
import Ids                         from '@/constants/Ids';
import PropTypes                   from '@/components/PropTypes';
import { CompanyActions }          from '@/store/actions/company';
import { MachineActions }          from '@/store/actions/machine';
import Spacer                      from '@/components/stateless/atomic/Spacer';
import Routes                      from '@/constants/Routes';
import Navigation                  from '@/helper/Navigation';
import { UserActions }             from '@/store/actions/user';
import CompanySideMenu             from '@/components/connected/CompanySideMenu';
import State                       from '@/helper/State';
import { CompanyJoinRequestTable } from '@/components/stateless/composed/CompanyJoinRequestTable';
import Hydra                       from '@/helper/Hydra';
import styles                      from './styles.module.scss';

class Screen extends React.Component {
    getActionObject = (user) => {
        return {
            companyIri: Hydra.getIriFromId('companies', this.props.currentCompanyId),
            userId:     _.get(user, 'id'),
        };
    };

    onJoinRequestAccept = (user) => () => {
        this.props.acceptJoinRequest(this.getActionObject(user));
    };

    onJoinRequestDecline = (user) => () => {
        this.props.declineJoinRequest(this.getActionObject(user));
    };

    render() {
        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <CompanySideMenu
                        route={Routes.userManagement}
                        {...this.props}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        <div className={styles.userManagementContainer}>
                            <div className={styles.userManagementContent}>
                                <h3>
                                    {I18n.t('joinRequests')}
                                </h3>
                                <Spacer height={10} />
                                <Trans
                                    i18nKey={'joinRequestInfoText'}
                                    components={{
                                        p: (
                                            <p />
                                        ),
                                    }}
                                />
                                <Spacer height={25} />
                                <CompanyJoinRequestTable
                                    joinRequests={this.props.joinRequests}
                                    onJoinRequestAccept={this.onJoinRequestAccept}
                                    onJoinRequestDecline={this.onJoinRequestDecline}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    acceptJoinRequest:  PropTypes.func,
    currentCompanyId:   PropTypes.string,
    declineJoinRequest: PropTypes.func,
    joinRequests:       PropTypes.array,
    ownCompanyUrl:      PropTypes.string,
};

Screen.defaultProps = {
    acceptJoinRequest:  _.noop,
    currentCompanyId:   null,
    declineJoinRequest: _.noop,
    joinRequests:       [],
    ownCompanyUrl:      null,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...UserActions,
        ...CompanyActions,
        ...MachineActions,
    },
    dispatch,
);

const mapStateToProps = (state, ownProps) => {
    const companyUrl = Navigation.getParameterFromUrl(ownProps, 'companyId');
    const companyId  = Navigation.getCompanyIdFromUrl(ownProps);

    return {
        companyAddress:    State.getCompanyValue(state, companyId, 'address'),
        companyName:       State.getCompanyValue(state, companyId, 'name'),
        companyTypes:      State.getCompanyValue(state, companyId, 'types', []),
        joinRequests:      State.getCompanyValue(state, companyId, 'usersRequestedToJoin'),
        currentCompanyId:  companyId,
        currentCompanyUrl: companyUrl,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
