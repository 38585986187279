//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                   from 'react';
import _                       from 'lodash';
import { bindActionCreators }  from 'redux';
import { connect }             from 'react-redux';
import ComponentHelper         from '@/helper/ComponentHelper';
import PropTypes               from '@/components/PropTypes';
import StatelessLoadingOverlay from '@/components/stateless/atomic/LoadingOverlay';
import { LoadingActions }      from '@/store/actions/loading';

export class Component extends React.Component {
    loadingOverlayClicked = () => {
        this.props.overlayClicked();
    };

    render() {
        return (
            <StatelessLoadingOverlay
                isLoading={this.props.isLoading}
                loadingOverlayClicked={this.loadingOverlayClicked}
                text={this.props.text}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const LoadingOverlay = Component;

Component.propTypes = {
    isLoading:      PropTypes.bool,
    overlayClicked: PropTypes.func,
    text:           PropTypes.string,
};

Component.defaultProps = {
    isLoading:      false,
    overlayClicked: _.noop,
    text:           '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        isLoading: _.get(state, 'loading.isLoading'),
        text:      _.get(state, 'loading.text'),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(LoadingActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
