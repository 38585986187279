//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import classNames      from 'classnames';
import React           from 'react';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div
                className={classNames(
                    styles.loadingOverlay,
                    {
                        [styles.loadingOverlayVisible]:       this.props.isLoading,
                        [styles.loadingOverlayComponentOnly]: this.props.componentOnly,
                    },
                )}
            >
                <div
                    className={styles.loadingOverlayPulse}
                >
                    <span onClick={this.props.loadingOverlayClicked}></span>
                    <span onClick={this.props.loadingOverlayClicked}></span>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const LoadingOverlay = Component;

Component.propTypes = {
    componentOnly:         PropTypes.bool,
    isLoading:             PropTypes.bool,
    loadingOverlayClicked: PropTypes.func,
    text:                  PropTypes.string,
};

Component.defaultProps = {
    componentOnly:         false,
    isLoading:             false,
    loadingOverlayClicked: _.noop,
    text:                  '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
