//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';
import I18n  from 'i18next';
import _     from 'lodash';

import { IconTextButton }    from '@/components/stateless/atomic/IconTextButton';
import Routes                from '@/constants/Routes';
import IconType              from '@/components/stateless/atomic/Icon/IconType';
import EntityLabelSize       from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import { EntityLabel }       from '@/components/stateless/composed/EntityLabel';
import ComponentHelper       from '@/helper/ComponentHelper';
import PropTypes             from '@/components/PropTypes';
import ProjectStatus         from '@/components/stateless/atomic/ProjectStatus';
import ProjectStatusConstant from '@/constants/ProjectStatus';
import Route                 from '@/helper/Route';
import * as Api              from '@/api';

import styles from './styles.module.scss';

import 'react-datepicker/dist/react-datepicker.css';

export class ProjectListElement extends React.Component {
    static propTypes = {
        company:        PropTypes.object,
        headline:       PropTypes.string,
        name:           PropTypes.string,
        onAcceptClick:  PropTypes.func,
        onDeclineClick: PropTypes.func,
        onDetailClick:  PropTypes.func,
        projectCreator: PropTypes.object,
        projectId:      PropTypes.string,
        projectName:    PropTypes.string,
        projectRuntime: PropTypes.string,
        projectVolume:  PropTypes.number,
        status:         PropTypes.oneOfObjectValues(ProjectStatusConstant),
        subtitle:       PropTypes.string,
    };

    static defaultProps = {
        company:        null,
        headline:       null,
        name:           null,
        onAcceptClick:  null,
        onDeclineClick: null,
        onDetailClick:  _.noop,
        projectCreator: null,
        projectId:      null,
        projectName:    null,
        projectRuntime: null,
        projectVolume:  0,
        status:         ProjectStatusConstant.OPEN,
        subtitle:       null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    renderMatch = () => {
        const {
            subtitle,
            company,
            projectCreator,
        } = this.props;

        return (
            <div className={styles.match}>
                <EntityLabel
                    title={company?.name || projectCreator?.name}
                    subtitle={subtitle || projectCreator?.position}
                    avatar={Api.getImagePath(company?.logo || projectCreator?.image)}
                    size={EntityLabelSize.small}
                    iso31661Alpha2CountryCode={'de'}
                    to={Route.buildRoute(Routes.company, Route.getCompanyUrl(company?.id, company?.slug))}
                />
            </div>
        );
    };

    renderOpenActions = () => {
        const { onAcceptClick, onDeclineClick } = this.props;
        let acceptButton                        = (
            <IconTextButton
                onClick={onAcceptClick}
                iconType={IconType.check}
                text={I18n.t('accept')}
            />
        );
        let declineButton                       = (
            <IconTextButton
                onClick={onDeclineClick}
                iconType={IconType.cross}
                text={I18n.t('decline')}
            />
        );

        if (!onAcceptClick) {
            acceptButton = null;
        }

        if (!onDeclineClick) {
            declineButton = null;
        }

        return (
            <>
                {acceptButton}
                {declineButton}
            </>
        );
    };

    renderInterestedActions = () => {
        return (
            <>
                <IconTextButton
                    to={Routes.screenDesign}
                    iconType={IconType.cross}
                    text={I18n.t('chat')}
                />
            </>
        );
    };

    renderRejectedActions = () => {
        return (
            <IconTextButton
                to={Routes.screenDesign}
                iconType={IconType.cross}
                disabled={true}
                text={I18n.t('chat')}
            />
        );
    };

    renderActions = () => {
        const { status } = this.props;
        let actions      = null;

        switch (status) {
            case ProjectStatusConstant.OPEN:
                actions = this.renderOpenActions();

                break;
            case ProjectStatusConstant.INTERESTED:
                actions = this.renderInterestedActions();

                break;
            case ProjectStatusConstant.REJECTED:
                actions = this.renderRejectedActions();

                break;
        }

        return (
            <div className={styles.actions}>
                <div className={styles.innerActions}>
                    {actions}
                    <IconTextButton
                        onClick={this.props.onDetailClick}
                        iconType={IconType.info}
                        text={I18n.t('details')}
                    />
                </div>
            </div>
        );
    };

    renderProject = () => {
        return (
            <div className={styles.projectWrapper}>
                <div className={styles.projectName}>
                    {this.props.projectName}
                </div>
                <div className={styles.projectRuntimeVolumeWrapper}>
                    <div className={styles.projectRuntime}>
                        {I18n.t('runtime')} {this.props.projectRuntime}
                    </div>
                    <div className={styles.projectVolume}>
                        {I18n.t('volume')} {this.props.projectVolume}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.top}>
                    <div className={styles.left}>
                        {this.renderProject()}
                    </div>
                    <div className={styles.right}>
                        {this.renderMatch()}
                    </div>
                </div>
                <div className={styles.bottom}>
                    <div className={styles.left}>
                        <ProjectStatus
                            status={this.props.status}
                        />
                    </div>
                    <div className={styles.right}>
                        {this.renderActions()}
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default ProjectListElement;
