//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import Link            from '@/components/stateless/atomic/Link';
import PropTypes       from '@/components/PropTypes';
import { BadgeCount }  from '@/components/stateless/atomic/BadgeCount';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div
                className={classNames(
                    styles.headerButton,
                    {
                        [styles.headerButtonActive]: this.props.active,
                    },
                )}
            >
                <Link
                    to={this.props.to}
                    className={styles.headerButtonText}
                    onClick={this.props.onClick}
                >
                    {this.props.label}
                </Link>
                {this.renderBadgeCount()}
            </div>
        );
    }

    renderBadgeCount = () => {
        if (this.props.badgeCount) {
            return (
                <div className={styles.headerButtonBadge}>
                    <BadgeCount count={this.props.badgeCount} />
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const HeaderButton = Component;

Component.propTypes = {
    active:     PropTypes.bool,
    badgeCount: PropTypes.number,
    label:      PropTypes.string,
    onClick:    PropTypes.func,
    to:         PropTypes.to,
};

Component.defaultProps = {
    active:     false,
    badgeCount: null,
    label:      null,
    onClick:    null,
    to:         null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
