//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class String {
    static truncate(stringToTruncate, totalLength, truncatedStringEllipsis = '...', keepExtension = false) {
        if (!stringToTruncate) {
            return '';
        }

        if (keepExtension) {
            const extension = stringToTruncate.split('.').pop();

            if (stringToTruncate.length + keepExtension.length <= totalLength) {
                return stringToTruncate;
            }

            const substring = stringToTruncate.substring(
                0,
                totalLength - truncatedStringEllipsis.length - extension.length,
            );

            return `${substring + truncatedStringEllipsis}.${extension}`;
        }

        if (stringToTruncate.length <= totalLength) {
            return stringToTruncate;
        }

        return stringToTruncate.substring(0, totalLength - truncatedStringEllipsis.length) + truncatedStringEllipsis;
    }

    static decodeHtml(html) {
        const text     = document.createElement('textarea');
        text.innerHTML = html;

        return text.value;
    }

    static removeHTML(html) {
        return html.replace(/<[^>]*>/g, '');
    }

    static cleanup(text) {
        const decodedText = String.decodeHtml(text);
        const clearedText = String.removeHTML(decodedText);

        return clearedText;
    }

    static mask(value, pattern) {
        let index = 0;

        return pattern.replace(/[#-]/g, () => value[index++]);
    }

    static slugify(text) {
        return text.replace(/ /g, '-').toLowerCase();
    }
}

export default String;
