//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                        from 'lodash';
import createSagaMiddleware     from 'redux-saga';
import storage                  from 'redux-persist/lib/storage';
import withReady                from 'redux-ready';
import { applyMiddleware }      from 'redux';
import { compose }              from 'redux';
import { createStore }          from 'redux';
import { createBrowserHistory } from 'history';
import { persistReducer }       from 'redux-persist';
import { persistStore }         from 'redux-persist';
import { routerMiddleware }     from 'connected-react-router';
import Environment              from '@/helper/Environment';
import sagas                    from './sagas/index';
import rootReducer              from './reducer';
import apiMiddleware            from './utils/api';

const reduxImmutableStateInvariant = require('redux-immutable-state-invariant').default();

export const history        = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();
const apiMiddlewareService  = apiMiddleware();
const persistConfig         = {
    key:       'root',
    storage,
    blacklist: [
        'projects',
        'loading',
        'router',
        'profile',
        'smallScreen',
    ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default (initialState) => {
    const isDevelopment    = Environment.isDevelopment();
    const allMiddlewares   = [
        apiMiddlewareService,
        (
            isDevelopment ?
                reduxImmutableStateInvariant :
                null
        ),
        sagaMiddleware,
        routerMiddleware(history),
    ];
    const middlewares      = _.reject(allMiddlewares, _.isNull);
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line
    const store            = createStore(
        persistedReducer,
        initialState,
        composeEnhancers(
            withReady,
            applyMiddleware(...middlewares),
        ),
    );

    store.ready().then(() => {
        sagaMiddleware.run(sagas.root);
    });

    const persistor = persistStore(store);

    return {
        store,
        persistor,
    };
};
