//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import LoadingLevel from '@/constants/LoadingLevel';

/**
 *
 * @param type
 * @param loadingLevel
 * @param props
 * @returns {function(...[*]): {type: *}}
 */
export function makeActionCreator(type, props = {}, loadingLevel = undefined) {
    const action = {
        type,
        loadingLevel,
        ...props,
    };

    const closure = (args, ...rest) => {
        if (args) {
            if (
                typeof args !== 'object' ||
                rest?.length > 0
            ) {
                const parameterString = [args, ...rest];
                const error           = `Action ${type} is not called with a single object. Instead you passed these parameters [${parameterString}].`;
                const explanation     = 'Dont call actions like this \'action(1,2)\' instead do this \'action({ a:1, b:2 })\'';
                const message         = `${error} ${explanation}`;

                throw new Error(message);
            }

            return {
                ...action,
                ...args,
            };
        }

        return action;
    };

    return closure;
}

/**
 *
 * @param type
 * @param props
 * @returns {function(...[*]): {type: *}}
 */
export function makeActionCreatorIncreaseLoading(type, props = {}) {
    return makeActionCreator(type, props, LoadingLevel.increase);
}

/**
 *
 * @param type
 * @param props
 * @returns {function(...[*]): {type: *}}
 */
export function makeActionCreatorDecreaseLoading(type, props = {}) {
    return makeActionCreator(type, props, LoadingLevel.decrease);
}
