//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import I18n             from 'i18next';
import _                from 'lodash';
import PropTypes        from '@/components/PropTypes';
import ColorBox         from '@/components/stateless/atomic/ColorBox';
import ColorBoxColor    from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import HeadlineSmall    from '@/components/stateless/atomic/HeadlineSmall';
import { FormHeadline } from '@/components/stateless/atomic/FormHeadline';
import { TextInput }    from '@/components/stateless/atomic/TextInput';
import IconType         from '@/components/stateless/atomic/Icon/IconType';
import { IconButton }   from '@/components/stateless/atomic/IconButton';
import ComponentHelper  from '@/helper/ComponentHelper';
import PercentageLabel  from '@/components/stateless/composed/PercentageLabel';
import TagDropDown      from '@/components/connected/TagDropDown';
import TagTypeDropDown  from '@/constants/TagTypeDropDown';
import styles           from './styles.module.scss';

export class Component extends React.Component {
    entryTitleMap = {
        [TagTypeDropDown.industry]:          'industry',
        [TagTypeDropDown.exportRegion]:      'region',
        [TagTypeDropDown.regionExportRatio]: 'region',
    };

    static maximalPercentage = 100;

    industryChanged = (index) => (industry) => {
        this.props.industryRatioChanged(
            index,
            'industry',
            industry,
        );
    };

    valueChanged = (index) => (event) => {
        const value = _.toNumber(event.target.value);

        this.props.industryRatioChanged(
            index,
            'percentage',
            this.calculateMaximalPercentage(value, index),
        );
    };

    removeButtonClicked = (index) => () => {
        this.props.removeButtonClicked(index);
    };

    calculateMaximalPercentage = (value, index) => {
        const { industryRatios } = this.props;
        const sum                = _.sumBy(
            _.filter(
                industryRatios,
                (industryValue, industryIndex) => index !== industryIndex,
            ),
            'percentage',
        );
        const other              = Component.maximalPercentage - (
            sum + value
        );

        return (
            other < 0 ?
                value + other :
                value
        );
    };

    calculateOtherPercentage = () => {
        const { industryRatios } = this.props;
        const sum                = _.sumBy(industryRatios, 'percentage');
        const other              = Component.maximalPercentage - sum;

        return (
            other < 0 ?
                0 :
                other
        );
    };

    render() {
        const { title } = this.props;

        return (
            <div className={styles.industryRatioInputWrapper}>
                <div className={styles.industryRatioInputHeader}>
                    <FormHeadline
                        text={title}
                        onClick={this.props.addButtonClicked}
                    />
                </div>
                <ColorBox color={ColorBoxColor.grayLight}>
                    <div className={styles.industryRatioInputList}>
                        {this.renderIndustryRatios()}
                        <div className={styles.industryRatioInputRow}>
                            <div className={styles.industryRatioInputColumn}>
                                <HeadlineSmall text={I18n.t('others')} />
                            </div>
                            <div className={styles.industryRatioInputColumn}>
                                <PercentageLabel
                                    percent={this.calculateOtherPercentage()}
                                    percentFractionDigits={0}
                                />
                            </div>
                        </div>
                    </div>
                </ColorBox>
            </div>
        );
    }

    renderIndustryRatio = (industryRatio, industryRatioIndex) => {
        const { tagType }   = this.props;
        const entryTitleKey = this.entryTitleMap[tagType] || 'industry';
        const selectedIds   = _.compact(_.map(this.props.industryRatios, 'industry.id'));

        return (
            <div
                key={industryRatioIndex}
                className={styles.industryRatioRow}
            >
                <div className={styles.industryRatioInputRow}>
                    <div className={styles.industryRatioInputColumn}>
                        <HeadlineSmall text={I18n.t(entryTitleKey)} />
                    </div>
                    <div className={styles.industryRatioInputColumnPercent}>
                        <HeadlineSmall text={I18n.t('percentageShort')} />
                    </div>
                    <div className={styles.industryRatioInputColumn} />
                </div>
                <div className={styles.industryRatioInputRow}>
                    <div className={styles.industryRatioInputColumn}>
                        <TagDropDown
                            tagType={tagType}
                            value={industryRatio.industry}
                            valueChanged={this.industryChanged(industryRatioIndex)}
                            selectedValueIds={selectedIds}
                        />
                    </div>
                    <div className={styles.industryRatioInputColumnInput}>
                        <TextInput
                            onChange={this.valueChanged(industryRatioIndex)}
                            value={industryRatio.percentage}
                            type={'number'}
                        />
                    </div>
                    <div className={styles.industryRatioInputColumn}>
                        <IconButton
                            iconType={IconType.deleteCircle}
                            onClick={this.removeButtonClicked(industryRatioIndex)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderIndustryRatios = () => {
        return this.props.industryRatios.map(this.renderIndustryRatio);
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const IndustryRatio = Component;

Component.propTypes = {
    addButtonClicked:     PropTypes.func,
    industryRatioChanged: PropTypes.func,
    industryRatios:       PropTypes.arrayOf(PropTypes.shape({
        id:    PropTypes.string,
        name:  PropTypes.string,
        value: PropTypes.string,
    })),
    removeButtonClicked:  PropTypes.func,
    tagType:              PropTypes.oneOfObjectValues(TagTypeDropDown),
    title:                PropTypes.string,
};

Component.defaultProps = {
    addButtonClicked:     _.noop,
    industryRatioChanged: _.noop,
    industryRatios:       [],
    removeButtonClicked:  _.noop,
    tagType:              null,
    title:                null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
