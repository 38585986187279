//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div
                className={styles.colorBox}
            >
                {this.getValidationErrorText()}
            </div>
        );
    }

    getValidationErrorText = () => {
        const issues = this.props?.validation?.error?.issues;

        if (issues?.length > 0) {
            const foundIssuesWithThisField = _.find(
                issues,
                (issue) => {
                    return issue.path.join('.') === this.props.field;
                },
            );

            if (foundIssuesWithThisField) {
                return (
                    <div
                        className={styles.validationError}
                    >
                        {foundIssuesWithThisField.message}
                    </div>
                );
            }
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ValidationError = Component;

Component.propTypes = {
    validation: PropTypes.object,
    field:      PropTypes.string,
};

Component.defaultProps = {
    validation: null,
    field:      null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
