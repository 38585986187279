//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }        from 'redux-saga/effects';
import { delay }      from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { push }       from 'connected-react-router';
import _              from 'lodash';

import { OverlayManager } from '@/components/connected/OverlayManager';
import Overlays           from '@/constants/Overlays';
import SagaStateHelper    from '@/helper/SagaStateHelper';
import PostActionHelper   from '@/helper/PostActionHelper';
import { OverlayTypes }   from '@/store/actions/ovleray';

function* openConfirmDialog() {
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.confirmOverlay)));
}

function* triggerAction(action, actionFieldName) {
    const { actionToTrigger } = action;
    const actionField         = _.defaultTo(actionToTrigger, actionFieldName);
    const confirmAction       = yield SagaStateHelper.selectFromState('overlay', 'confirmDialog', actionField);

    yield delay(500);
    yield PostActionHelper.runAction(confirmAction);
}

function* triggerConfirmAction(action) {
    yield triggerAction(action, 'confirmAction');
}

function* triggerCancelAction(action) {
    yield triggerAction(action, 'cancelAction');
}

export const callOverlaySagas = () => {
    return [
        // @formatter:off
        takeLatest([OverlayTypes.OPEN_CONFIRM_DIALOG],    openConfirmDialog),
        takeLatest([OverlayTypes.TRIGGER_CONFIRM_ACTION], triggerConfirmAction),
        takeLatest([OverlayTypes.TRIGGER_CANCEL_ACTION],  triggerCancelAction),
        // @formatter:on
    ];
};
