//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const StagingAuthenticationTypes = {
    AUTHENTICATE:                    'StagingAuthentication/AUTHENTICATE',
    AUTHENTICATION_PASSWORD_CHANGED: 'StagingAuthentication/AUTHENTICATION_PASSWORD_CHANGED',
    AUTHENTICATION_SUCCEEDED:        'StagingAuthentication/AUTHENTICATION_SUCCEEDED',
};

const authenticate = makeActionCreator(StagingAuthenticationTypes.AUTHENTICATE);

const authenticationPasswordChanged = makeActionCreator(
    StagingAuthenticationTypes.AUTHENTICATION_PASSWORD_CHANGED,
    {
        password: null,
    },
);

const authenticationSucceeded = makeActionCreator(StagingAuthenticationTypes.AUTHENTICATION_SUCCEEDED);

export const StagingAuthenticationActions = {
    authenticate,
    authenticationPasswordChanged,
    authenticationSucceeded,
};
