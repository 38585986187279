//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { Collapse } from 'react-collapse';
import classNames   from 'classnames';
import _            from 'lodash';

import PropTypes            from '@/components/PropTypes';
import { HeadlineSmall }    from '@/components/stateless/atomic/HeadlineSmall';
import { Spacer }           from '@/components/stateless/atomic/Spacer';
import ComponentHelper      from '@/helper/ComponentHelper';
import CollapsableArrowMode from '@/components/stateless/composed/Collapsable/CollapsableArrowMode';

import styles from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: props.closed,
        };
    }

    componentDidUpdate(prevProps) {
        const { closed }    = this.props;
        const { collapsed } = this.state;

        if (
            prevProps.closed !== closed &&
            closed !== collapsed
        ) {
            this.setState({
                collapsed: closed,
            });
        }
    }

    collapse = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    getTitle = () => {
        const { title } = this.props;

        if (title) {
            if (_.isFunction(title)) {
                return title(this.state.collapsed);
            }

            return title;
        }

        console.error('The Collapsable-Component is missing a title.');

        return null;
    };

    renderArrow = (targetArrowMode) => {
        const { arrowMode } = this.props;

        if (arrowMode !== targetArrowMode) {
            return null;
        }

        return (
            <span
                className={classNames(
                    styles.collapsableHeadlineCaret,
                    {
                        [styles.collapsableHeadlineCaretClosed]: !this.state.collapsed,
                    },
                )}
            />
        );
    };

    render() {
        return (
            <div>
                <div className={styles.collapsableHeadlineWrapper}>
                    {this.renderArrow(CollapsableArrowMode.left)}
                    <HeadlineSmall
                        text={this.getTitle()}
                        onClick={this.collapse}
                    />
                    {this.renderArrow(CollapsableArrowMode.right)}
                </div>
                <Collapse isOpened={!this.state.collapsed}>
                    <Spacer height={15} />
                    {this.props.children}
                </Collapse>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Collapsable = Component;

Component.propTypes = {
    arrowMode: PropTypes.string,
    children:  PropTypes.children,
    closed:    PropTypes.bool,
    title:     PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
    ]),
};

Component.defaultProps = {
    arrowMode: CollapsableArrowMode.right,
    children:  null,
    closed:    false,
    title:     null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'collapsed',
];

export default Component;
