//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import PropTypes from '@/components/PropTypes';
import colors    from '@/styles/colors.module.scss';
import styles    from './styles.module.scss';

export class Component extends React.Component {
    getStyle = () => {
        return {
            '--svg-color': this.props.color || colors.colorGrayHeather,
        };
    };

    render() {
        return (
            <div
                className={styles.color}
                style={this.getStyle()}
            >
                {this.props.children}
            </div>
        );
    }
}

export const SvgColor = Component;

Component.propTypes = {
    children: PropTypes.children,
    color:    PropTypes.string,
};

Component.defaultProps = {
    children: null,
    color:    null,
};

export default Component;
