//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _           from 'lodash';
import HydraHelper from '@/helper/Hydra';

const Errors = Object.freeze({
    messageRequestDeclined:        {
        status:     400,
        messageKey: 'messageRequestDeclined',
    },
    messageRequestPending:         {
        status:     400,
        messageKey: 'messageRequestPending',
    },
    portfolioProductLimitExceeded: {
        status:     400,
        messageKey: 'portfolioProductLimitExceeded',
    },
    factSheetProductLimitExceeded: {
        status:     400,
        messageKey: 'factSheetProductLimitExceeded',
    },
});

export default Errors;

export const getErrorByStatusCode = (response) => {
    const { status }     = response;
    const possibleErrors = _.filter(
        Errors,
        (error) => error.status === status,
    );
    const foundError     = _.first(possibleErrors);

    return foundError;
};

export const getErrorByKeyWord = (error) => {
    const description    = _.get(error, 'description');
    const possibleErrors = _.filter(
        Errors,
        (errorDefinition) => {
            const errorKeyWord = _.get(errorDefinition, 'errorKeyWord');

            return _.includes(description, errorKeyWord);
        },
    );
    const foundError     = _.first(possibleErrors);

    return foundError;
};

export const getError = (response) => {
    const { data, status } = response;
    const error            = HydraHelper.cleanupObject(data);
    const errorKey         = _.get(error, 'description') ?? _.get(error, 'error');
    let foundError         = _.get(
        Errors,
        errorKey,
        getErrorByStatusCode(response),
    );

    if (!foundError) {
        foundError = getErrorByKeyWord(error);
    }

    if (
        foundError &&
        foundError.status === status
    ) {
        return foundError;
    }

    return null;
};

export const isKnownError = (response) => {
    const foundError = getError(response);

    return !_.isNil(foundError);
};
