//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React             from 'react';
import PropTypes         from '@/components/PropTypes';
import { HeadlineSmall } from '@/components/stateless/atomic/HeadlineSmall';
import styles            from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.sideMenuHeadline}>
                <HeadlineSmall text={this.props.text} />
            </div>
        );
    }
}

export const SideMenuHeadline = Component;

Component.propTypes = {
    text: PropTypes.string,
};

Component.defaultProps = {
    text: '',
};

export default Component;
