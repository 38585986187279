//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _              from 'lodash';
import { call }       from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import HydraHelper            from '@/helper/Hydra';
import * as Api               from '@/api';
import { MinimumQueryLength } from '@/constants/Search';
import { SearchActions }      from '@/store/actions/search';
import { SearchTypes }        from '@/store/actions/search';
import { AlertBoxActions }    from '@/store/actions/alertBox';
import { FilterTypes }        from '@/store/actions/filter';
import SearchTabs             from '@/constants/SearchTabs';

function* search(action) {
    const query = yield select((state) => state.search.query);

    if (query.length >= MinimumQueryLength) {
        const parameters = {};
        const flags      = [];
        let indices      = action.indices;

        if (!indices) {
            indices = yield select((state) => state.search.indices);
        }

        if (indices.length === 1 && indices.includes('machines')) {
            flags['machine-parameters'] = true;
        }

        const indicesString = indices.join(',');

        if (
            !_.isEmpty(query) &&
            !_.isEmpty(indicesString)
        ) {
            const response = yield call(
                Api.search,
                query,
                indicesString,
                parameters,
                flags,
            );

            if (response.ok) {
                const result = HydraHelper.cleanupObject(response.data);

                yield put(SearchActions.searchSucceeded({
                    items: result,
                }));
            } else {
                yield put(SearchActions.searchFailed());
            }
        }
    }
}

function getPagingObject(context, page, paging) {
    return (
        (
            !context &&
            !page
        ) ?
            {
                machines:             {
                    page: 1,
                },
                supplier:             {
                    page: 1,
                },
                machine_manufacturer: {
                    page: 1,
                },
            } :
            paging
    );
}

function getFilterObject(activeTab, filter) {
    switch (activeTab) {
        case SearchTabs.machines:
            return {
                machines: {
                    address:   _.get(filter, ['searchResultMachines', 'address', 'value'], ''),
                    companies: _.get(filter, ['searchResultMachines', 'companies', 'value'], ''),
                    name:      _.get(filter, ['searchResultMachines', 'name', 'value'], ''),
                },
            };
        case SearchTabs.machine_manufacturer:
            return {
                machine_manufacturer: {
                    address: _.get(filter, ['searchResultCompanies', 'address', 'value'], ''),
                    name:    _.get(filter, ['searchResultCompanies', 'name', 'value'], ''),
                },
            };
        case SearchTabs.supplier:
        default:
            return {
                supplier: {
                    address: _.get(filter, ['searchResultCompanies', 'address', 'value'], ''),
                    name:    _.get(filter, ['searchResultCompanies', 'name', 'value'], ''),
                },
            };
    }
}

function* searchByTags(action) {
    const { context, page } = action;
    const paging            = yield select((state) => state.search.paging);
    const tagQueryAsList    = yield select((state) => state.search.tagQueryAsList);
    const tagQuery          = yield select((state) => state.search.tagQuery);
    const activeTab         = yield select((state) => state.search.activeTab);
    const filter            = yield select((state) => state.filter.filters);

    _.filter(tagQuery, (query) => {
        const standaloneTagSearch = _.get(query, 'tag.standaloneTagSearch', false);
        const hasNoChildren       = _.get(query, 'children.length', 0) === 0;

        return (
            !standaloneTagSearch &&
            hasNoChildren
        );
    });

    if (tagQuery.length) {
        yield put(SearchActions.searchByTags());
    }

    yield put(AlertBoxActions.clearAlerts());

    if (tagQueryAsList.length > 0) {
        const response = yield call(
            Api.searchByTags,
            tagQueryAsList,
            getPagingObject(context, page, paging),
            getFilterObject(activeTab, filter),
        );

        if (response.ok) {
            const result = HydraHelper.cleanupObject(response.data);

            yield put(SearchActions.searchByTagsSucceeded({
                resultCount:               result.resultCount,
                results:                   result.results,
                paging:                    result.paging,
                totalItemsWithoutMachines: result.totalItemsWithoutMachines,
            }));
        } else {
            yield put(SearchActions.searchByTagsFailed());
        }
    }
}

function* addNextChild(action) {
    const tag                 = _.get(action, 'tag', null);
    const existingChildren    = _.get(action, 'data.children', []);
    const existingChildrenIds = _.map(existingChildren, (child) => child.id);
    const tagId               = _.get(tag, 'value', null);

    if (tagId) {
        const response = yield call(
            Api.fetchTag,
            tagId,
        );

        if (response.ok) {
            const result   = HydraHelper.cleanupObject(response.data);
            const children = _.get(result, 'childTags', []);
            const child    = _.find(
                children,
                (entry) => _.indexOf(existingChildrenIds, entry.id) === -1,
            );

            if (child) {
                yield put(SearchActions.addTagQuery({
                    tag: child,
                }));
            }
        }
    }
}

function* getChildTags(action) {
    const tag       = _.get(action, 'tag', null);
    const hierarchy = _.get(tag, 'hierarchy', null);

    for (const tagElement of hierarchy) {
        const tagId = _.get(tagElement, 'id', null);

        if (tagId) {
            const response = yield call(
                Api.fetchTag,
                tagId,
            );

            if (response.ok) {
                const result   = HydraHelper.cleanupObject(response.data);
                const children = _.get(result, 'childTags', []);

                yield put(SearchActions.addChildrenToTagQuery({
                    tag: result,
                    children,
                }));
            }
        }
    }
}

function* getChildTagsForNextSibling() {
    const tagId = yield select((state) => state.search.lastAddedSiblingTagId);

    if (tagId) {
        const response = yield call(
            Api.fetchTag,
            tagId,
        );

        if (response.ok) {
            const result   = HydraHelper.cleanupObject(response.data);
            const children = _.get(result, 'childTags', []);

            yield put(SearchActions.addChildrenToTagQuery({
                tag: result,
                children,
            }));
        }
    }
}

export const callSearchSagas = () => {
    return [
        // @formatter:off
        takeLatest([SearchTypes.ADD_NEXT_CHILD],   addNextChild),
        takeLatest([SearchTypes.ADD_NEXT_SIBLING], searchByTags),
        takeLatest([SearchTypes.ADD_NEXT_SIBLING], getChildTagsForNextSibling),
        takeLatest([SearchTypes.ADD_TAG_QUERY],    searchByTags),
        takeLatest([SearchTypes.ADD_TAG_QUERY],    getChildTags),
        takeLatest([SearchTypes.CHANGE_TAG],       searchByTags),
        takeLatest([SearchTypes.DELETE_TAG],       searchByTags),
        takeLatest([SearchTypes.SEARCH],           search),
        takeLatest([SearchTypes.UPDATE_PAGING],    searchByTags),
        takeLatest([FilterTypes.APPLY_FILTER],     searchByTags),
        takeLatest([FilterTypes.RESET_FILTER],     searchByTags),
        // @formatter:on
    ];
};
