//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _            from 'lodash';
import React        from 'react';
import I18n         from 'i18next';
import PropTypes    from '@/components/PropTypes';
import Tag          from '@/components/stateless/atomic/Tag';
import StringHelper from '@/helper/String';

import styles from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.tagListWrapper}>
                {this.renderTags()}
            </div>
        );
    }

    renderTags = () => {
        if (this.props.tags.length) {
            return this.props.tags.map(this.renderTag);
        }

        return (
            <p className={styles.tagListWrapperNoTags}>
                {I18n.t('noTagsSelected')}
            </p>
        );
    };

    renderTag = (tagTree, index) => {
        const tag     = _.last(tagTree);
        const tagSlug = tag ? StringHelper.slugify(tag) : null;

        return (
            <Tag
                tag={tag}
                key={`tag-${tagSlug}`}
                index={index}
                tree={_.initial(tagTree)}
            />
        );
    };
}

export const TagList = Component;

Component.propTypes = {
    tags: PropTypes.array,
};

Component.defaultProps = {
    tags: [],
};

export default Component;
