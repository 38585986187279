//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const CompanyFactSheetTypes = {
    ADD_NEW_ENTRY:               'CompanyFactSheet/ADD_NEW_ENTRY',
    EDIT_REVENUE_DISTRIBUTION:   'CompanyFactSheet/EDIT_REVENUE_DISTRIBUTION',
    LOAD_EDIT_CONTEXT:           'CompanyFactSheet/LOAD_EDIT_CONTEXT',
    OPEN_EDIT_OVERLAY:           'CompanyFactSheet/OPEN_EDIT_OVERLAY',
    REMOVE_ENTRY:                'CompanyFactSheet/REMOVE_ENTRY',
    SAVE_EDIT_TO_COMPANY:        'CompanyFactSheet/SAVE_EDIT_TO_COMPANY',
    UPDATE_ENTRY:                'CompanyFactSheet/UPDATE_ENTRY',
    UPDATE_FACT_SHEET:           'CompanyFactSheet/UPDATE_FACT_SHEET',
    UPDATE_FACT_SHEET_FAILED:    'CompanyFactSheet/UPDATE_FACT_SHEET_FAILED',
    UPDATE_FACT_SHEET_SUCCEEDED: 'CompanyFactSheet/UPDATE_FACT_SHEET_SUCCEEDED',
    UPDATE_VALUE:                'CompanyFactSheet/UPDATE_VALUE',
    CREATE_CERTIFICATE_FILE:     'CompanyFactSheet/CREATE_CERTIFICATE_FILE',
    UPDATE_CERTIFICATE_FILE:     'CompanyFactSheet/UPDATE_CERTIFICATE_FILE',
};

const addNewEntry = makeActionCreator(
    CompanyFactSheetTypes.ADD_NEW_ENTRY,
    {
        editType: null,
    },
);

const editRevenueDistribution = makeActionCreator(
    CompanyFactSheetTypes.EDIT_REVENUE_DISTRIBUTION,
    {},
);

const loadEditContext = makeActionCreator(
    CompanyFactSheetTypes.LOAD_EDIT_CONTEXT,
    {
        editType:  null,
        factSheet: null,
    },
);
const openEditOverlay = makeActionCreator(
    CompanyFactSheetTypes.OPEN_EDIT_OVERLAY,
    {
        editType: null,
        index:    null,
    },
);

const removeEntry = makeActionCreator(
    CompanyFactSheetTypes.REMOVE_ENTRY,
    {
        editType: null,
        index:    null,
    },
);

const saveEditToCompany = makeActionCreator(
    CompanyFactSheetTypes.SAVE_EDIT_TO_COMPANY,
    {
        editType: null,
    },
);

const updateEntry = makeActionCreator(
    CompanyFactSheetTypes.UPDATE_ENTRY,
    {
        editType: null,
        index:    null,
        key:      null,
        value:    null,
    },
);

const updateFactSheet = makeActionCreator(
    CompanyFactSheetTypes.UPDATE_FACT_SHEET,
    {},
);

const updateFactSheetFailed = makeActionCreator(
    CompanyFactSheetTypes.UPDATE_FACT_SHEET_FAILED,
    {},
);

const updateFactSheetSucceeded = makeActionCreator(
    CompanyFactSheetTypes.UPDATE_FACT_SHEET_SUCCEEDED,
    {},
);

const updateValue = makeActionCreator(
    CompanyFactSheetTypes.UPDATE_VALUE,
    {},
);

const updateCertificateFile = makeActionCreator(
    CompanyFactSheetTypes.UPDATE_CERTIFICATE_FILE,
    {
        certificateFileIndex: null,
        data:                 null,
    },
);

const createCertificateFile          = makeActionCreator(
    CompanyFactSheetTypes.CREATE_CERTIFICATE_FILE,
    {
        data: null,
    },
);
export const CompanyFactSheetActions = {
    addNewEntry,
    editRevenueDistribution,
    loadEditContext,
    openEditOverlay,
    removeEntry,
    saveEditToCompany,
    updateEntry,
    updateFactSheet,
    updateFactSheetFailed,
    updateFactSheetSucceeded,
    updateValue,
    updateCertificateFile,
    createCertificateFile,
};
