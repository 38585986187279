//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import CompanyLogo      from '@/components/stateless/atomic/CompanyLogo';
import CompanyTypeLabel from '@/components/stateless/atomic/CompanyTypeLabel';
import ComponentHelper  from '@/helper/ComponentHelper';
import HighlightedText  from '@/components/stateless/atomic/HighlightedText';
import PropTypes        from '@/components/PropTypes';
import styles           from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.companySearchResultWrapper}>
                <div className={styles.companyLogoWrapper}>
                    <CompanyLogo
                        alternativeText={this.props.companyName}
                        image={this.props.companyImage}
                        size={30}
                        withoutLink={true}
                    />
                </div>
                <HighlightedText
                    highlightText={this.props.highlightText}
                    text={this.props.companyName}
                />
                <div className={styles.companyTypeWrapper}>
                    <CompanyTypeLabel companyTypes={this.props.companyTypes} />
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const CompanySearchResult = Component;

Component.propTypes = {
    companyImage:  PropTypes.image,
    companyName:   PropTypes.string,
    companyTypes:  PropTypes.array,
    highlightText: PropTypes.string,
};

Component.defaultProps = {
    companyImage:  '',
    companyName:   '',
    companyTypes:  [],
    highlightText: '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
