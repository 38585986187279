//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import I18n             from 'i18next';
import _                from 'lodash';
import PropTypes        from '@/components/PropTypes';
import ColorBox         from '@/components/stateless/atomic/ColorBox';
import ColorBoxColor    from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import HeadlineSmall    from '@/components/stateless/atomic/HeadlineSmall';
import { FormHeadline } from '@/components/stateless/atomic/FormHeadline';
import { TextInput }    from '@/components/stateless/atomic/TextInput';
import IconType         from '@/components/stateless/atomic/Icon/IconType';
import { IconButton }   from '@/components/stateless/atomic/IconButton';
import ComponentHelper  from '@/helper/ComponentHelper';
import styles           from './styles.module.scss';

export class Component extends React.Component {
    nameChanged = (parameter, index) => (event) => {
        this.props.nameChanged(index, event.target.value);
    };

    removeButtonClicked = (parameter, index) => () => {
        this.props.removeButtonClicked(parameter, index);
    };

    render() {
        return (
            <div className={styles.parameterInputWrapper}>
                <div className={styles.parameterInputHeader}>
                    <FormHeadline
                        text={I18n.t('parameters')}
                        onClick={this.props.addButtonClicked}
                    />
                </div>
                <ColorBox color={ColorBoxColor.grayLight}>
                    <div className={styles.parameterInputParameterList}>
                        <div className={styles.parameterInputRow}>
                            <div className={styles.parameterInputColumn}>
                                <HeadlineSmall
                                    text={I18n.t('parameterName')}
                                />
                            </div>
                            <div className={styles.parameterInputColumn}>
                                <HeadlineSmall
                                    text={I18n.t('parameterValue')}
                                />
                            </div>
                            <div className={styles.parameterInputColumn}>

                            </div>
                        </div>
                        {this.renderParameters()}
                    </div>
                </ColorBox>
            </div>
        );
    }

    renderParameter = (parameter, parameterIndex) => {
        return (
            <div
                key={parameter.id}
                className={styles.parameterInputRow}
            >
                <div className={styles.parameterInputColumn}>
                    <TextInput
                        onChange={this.valueChanged(
                            parameter,
                            parameterIndex,
                        )}
                        value={parameter.value}
                    />
                </div>
                <div className={styles.parameterInputColumn}>
                    <TextInput
                        onChange={this.valueChanged(
                            parameter,
                            parameterIndex,
                        )}
                        value={parameter.value}
                    />
                </div>
                <div className={styles.parameterInputColumn}>
                    <IconButton
                        iconType={IconType.deleteCircle}
                        onClick={this.removeButtonClicked(parameter, parameterIndex)}
                    />
                </div>
            </div>
        );
    };

    renderParameters = () => {
        return this.props.parameters.map(this.renderParameter);
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }

    valueChanged = (parameter, index) => (event) => {
        this.props.valueChanged(index, event.target.value);
    };
}

export const ParameterInput = Component;

Component.propTypes = {
    addButtonClicked:    PropTypes.func,
    nameChanged:         PropTypes.func,
    parameters:          PropTypes.arrayOf(PropTypes.shape({
        id:    PropTypes.string,
        name:  PropTypes.string,
        value: PropTypes.string,
    })),
    removeButtonClicked: PropTypes.func,
    valueChanged:        PropTypes.func,
};

Component.defaultProps = {
    addButtonClicked:    _.noop,
    nameChanged:         _.noop,
    parameters:          [],
    removeButtonClicked: _.noop,
    valueChanged:        _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
