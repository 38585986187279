//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _       from 'lodash';
import { put } from 'redux-saga/effects';

function* runAction(postAction, parameters = {}) {
    if (!postAction) {
        return;
    }

    if (_.isArray(postAction)) {
        for (const actionToTrigger of postAction) {
            yield put(actionToTrigger);
        }

        return;
    }

    if (_.isFunction(postAction)) {
        yield put(postAction(parameters));
    } else {
        yield put(postAction);
    }
}

export default {
    runAction,
};
