//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                    from 'react';
import _                        from 'lodash';
import { bindActionCreators }   from 'redux';
import { connect }              from 'react-redux';
import PropTypes                from '@/components/PropTypes';
import { FilterActions }        from '@/store/actions/filter';
import TableWithFilterStateless from '@/components/stateless/composed/TableWithFilter';
import { SearchActions }        from '@/store/actions/search';

export class Component extends React.Component {
    onFilterToggle = () => {
        this.props.toggleFilter({
            filter: this.props.context.name,
        });
    };

    onResetFilterClicked = () => {
        this.props.resetFilter();
    };

    onUpdatePaging = (pageObject) => {
        const { pagingContext } = this.props;

        if (!pagingContext) {
            return;
        }

        this.props.updatePaging({
            context: this.props.pagingContext,
            page:    pageObject.selected + 1,
        });
    };

    onFilterChanged = (field) => (event) => {
        this.props.applyFilter({
            filter:     this.props.context.name,
            field:      this.props.context.fields[field].value,
            fieldQuery: this.props.context.fields[field].query,
            value:      event.target.value,
        });
    };

    getPaging = () => {
        const { paging, pagingContext } = this.props;

        if (!pagingContext) {
            return null;
        }

        return paging;
    };

    render() {
        return (
            <TableWithFilterStateless
                onFilterToggle={this.onFilterToggle}
                onResetFilterClicked={this.onResetFilterClicked}
                onFilterChanged={this.onFilterChanged}
                context={this.props.context}
                onUpdatePaging={this.onUpdatePaging}
                paging={this.getPaging()}
                filters={this.props.filters}
                columnCount={this.props.columnCount}
                renderTableHeaderRows={this.props.renderTableHeaderRows}
                isFilterOpen={this.props.isFilterOpen}
                renderTableBodyRows={this.props.renderTableBodyRows}
                isLoggedIn={this.props.isLoggedIn}
            />
        );
    }
}

export const TableWithFilter = Component;

Component.propTypes = {
    applyFilter:           PropTypes.func,
    columnCount:           PropTypes.number,
    context:               PropTypes.object,
    filters:               PropTypes.object,
    isFilterOpen:          PropTypes.bool,
    isLoggedIn:            PropTypes.bool,
    paging:                PropTypes.object,
    pagingContext:         PropTypes.string,
    renderTableBodyRows:   PropTypes.children,
    renderTableHeaderRows: PropTypes.children,
    resetFilter:           PropTypes.func,
    toggleFilter:          PropTypes.func,
    updatePaging:          PropTypes.func,
};

Component.defaultProps = {
    applyFilter:           _.noop,
    columnCount:           0,
    context:               {},
    filters:               {},
    isFilterOpen:          false,
    isLoggedIn:            false,
    paging:                null,
    pagingContext:         null,
    renderTableBodyRows:   null,
    renderTableHeaderRows: null,
    resetFilter:           _.noop,
    toggleFilter:          _.noop,
    updatePaging:          _.noop,
};

const mapStateToProps = (state, ownProps) => {
    return {
        paging:       _.get(
            state.search.paging,
            [ownProps.pagingContext],
            {
                page: 1,
            },
        ),
        filters:      _.omit(_.get(state.filter.filters, [ownProps.context.name], {}), 'isOpen'),
        isFilterOpen: _.get(state.filter.filters, [ownProps.context.name, 'isOpen'], false),
        isLoggedIn:   !_.isEmpty(_.get(state, 'user.token', null)),
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...FilterActions,
    ...SearchActions,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
