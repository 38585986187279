//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React              from 'react';
import I18n               from 'i18next';
import ComponentHelper    from '@/helper/ComponentHelper';
import HighlightedText    from '@/components/stateless/atomic/HighlightedText';
import IconType           from '@/components/stateless/atomic/Icon/IconType';
import { IconTextButton } from '@/components/stateless/atomic/IconTextButton';
import PropTypes          from '@/components/PropTypes';
import _                  from 'lodash';
import IconButtonTheme    from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import styles             from './styles.module.scss';

export class Component extends React.Component {
    onRequestToAddClicked = () => {
        const { name, keyword } = this.props;
        const subject           = encodeURIComponent(I18n.t('requestToAddEmailSubject'));
        const body              = encodeURIComponent(I18n.t('requestToAddEmailText', {
            name: _.defaultTo(name, ''),
            keyword,
        }));
        const emailTarget       = I18n.t('requestToAddEmailTarget');

        window.open(`mailto:${emailTarget}?subject=${subject}&body=${body}`);
    };

    render() {
        return (
            <div className={styles.noSearchResultWrapper}>
                <HighlightedText
                    text={I18n.t('noSearchResults')}
                />
                <div className={styles.keywordNotFoundWrapper}>
                    <span
                        className={styles.keywordNotFoundText}
                    >
                        {I18n.t('keywordNotFound')}
                    </span>
                    <IconTextButton
                        onClick={this.onRequestToAddClicked}
                        iconType={IconType.envelope}
                        text={I18n.t('requestToAdd')}
                        theme={IconButtonTheme.grayBorderGrayHover}
                    />
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const NoSearchResults = Component;

Component.propTypes = {
    keyword: PropTypes.string,
    name:    PropTypes.string,
};

Component.defaultProps = {
    keyword: null,
    name:    null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
