//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                from 'immutability-helper';
import { NavigationTypes }   from '@/store/actions/navigation';
import { LostPasswordTypes } from '@/store/actions/lostPassword';

const initialState = {
    identifier:       '',
    password:         '',
    repeatedPassword: '',
    token:            null,
    passwordScore:    0,
};

const reset = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const setIdentifier = (action, state) => {
    return update(state, {
        identifier: {
            $set: action.identifier,
        },
    });
};

const setPassword = (action, state) => {
    return update(state, {
        password: {
            $set: action.password,
        },
    });
};

const setPasswordScore = (action, state) => {
    return update(state, {
        passwordScore: {
            $set: action.passwordScore,
        },
    });
};

const setPasswordToken = (action, state) => {
    return update(state, {
        token: {
            $set: action.token,
        },
    });
};

const setRepeatedPassword = (action, state) => {
    return update(state, {
        repeatedPassword: {
            $set: action.repeatedPassword,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case NavigationTypes.RESET_STATE:
        case LostPasswordTypes.RESET_TOKEN:
        case LostPasswordTypes.REQUEST_PASSWORD_FAILED:
        case LostPasswordTypes.SET_NEW_PASSWORD_FAILED:
        case LostPasswordTypes.REQUEST_PASSWORD_SUCCEEDED: return reset(action, state);
        case LostPasswordTypes.SET_IDENTIFIER:             return setIdentifier(action, state);
        case LostPasswordTypes.SET_PASSWORD:               return setPassword(action, state);
        case LostPasswordTypes.SET_PASSWORD_SCORE:         return setPasswordScore(action, state);
        case LostPasswordTypes.SET_PASSWORD_TOKEN:         return setPasswordToken(action, state);
        case LostPasswordTypes.SET_REPEATED_PASSWORD:      return setRepeatedPassword(action, state);
        default:                                           return state;
        // @formatter:on
    }
}
