//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import _                             from 'lodash';
import I18n                          from 'i18next';
import { bindActionCreators }        from 'redux';
import { compose }                   from 'redux';
import { connect }                   from 'react-redux';
import AddNewMachineOverlayStateless from '@/components/stateless/composed/AddNewMachineOverlay';
import ComponentHelper               from '@/helper/ComponentHelper';
import File                          from '@/helper/File';
import Crypto                        from '@/helper/Crypto';
import PropTypes                     from '@/components/PropTypes';
import { AlertBoxActions }           from '@/store/actions/alertBox';
import { MachineActions }            from '@/store/actions/machine';
import { TagActions }                from '@/store/actions/tag';
import ApiMode                       from '@/constants/ApiMode';
import StateHelper                   from '@/helper/State';
import MachineCapacity               from '@/constants/MachineCapacity';
import { yearsToShow }               from '@/constants/MachineCapacity';

const contentFields = [
    'machineName',
    'machineCategory',
    'machineDescription',
    'machineCapacities',
];

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.initialDataHash = Crypto.hashContent(contentFields, this.props);
        this.state           = {
            dataChanged: false,
        };
    }

    componentDidUpdate() {
        const hash = Crypto.hashContent(contentFields, this.props);

        if (hash !== this.initialDataHash) {
            this.setState({
                dataChanged: true,
            });
        }
    }

    onCloseButtonPressed = () => {
        this.props.abortMachineEdit();
    };

    isEditingDisallowed = () => {
        const { apiMode, isCreator } = this.props;

        return (
            apiMode === ApiMode.edit &&
            !isCreator
        );
    };

    okButtonPressed = () => {
        if (this.isEditingDisallowed()) {
            this.props.updateMachineCapacity({
                machine:           this.props.machine,
                machineCapacities: this.props.machineCapacities,
            });
        } else {
            this.props.openAddNewMachineManufacturerOverlay();
        }
    };

    okButtonValidator = () => {
        this.props.clearAlerts();

        const props = this.props;

        if (!props.machineName) {
            this.props.showErrorAlert({
                text: I18n.t('machineOverlayNameMissingError'),
            });

            return false;
        }

        if (!props.machineDescription) {
            this.props.showErrorAlert({
                text: I18n.t('machineOverlayDescriptionMissingError'),
            });

            return false;
        }

        if (!props.machineImage) {
            this.props.showErrorAlert({
                text: I18n.t('machineOverlayImageMissingError'),
            });

            return false;
        }

        return true;
    };

    onImageChanged = (uploadedImage) => {
        if (uploadedImage) {
            File
                .getFileFromBlob(uploadedImage)
                .then((image) => {
                    this.props.editMachineImage({
                        image,
                    });
                });
        } else {
            this.props.editMachineImage();
        }
    };

    onMachineNameChanged = (event) => {
        this.props.machineNameChanged({
            name: event.target.value,
        });
    };

    onMachineCategoryChange = (event) => {
        this.props.machineCategoryChanged({
            category: event.target.value.replace(/[^a-zA-Z\d\s:]/g, ''),
        });
    };

    onMachineDescriptionChanged = (event) => {
        this.props.machineDescriptionChanged({
            description: event.target.value,
        });
    };

    onMachineCapacityChanged = (year, capacity) => {
        const { userCompanyIri, userCompanyId, machine } = this.props;
        const newCapacity                                = {
            company: {
                iri: userCompanyIri,
                id:  userCompanyId,
            },
            machine,
            year,
            capacity,
        };

        this.props.machineCapacityChanged({
            machineCapacity: newCapacity,
        });
    };

    getOkButtonText = () => {
        return (
            this.isEditingDisallowed() ?
                I18n.t('updateMachineCapacity') :
                I18n.t('continue')
        );
    };

    render() {
        const props = this.props;

        return (
            <AddNewMachineOverlayStateless
                apiMode={props.apiMode}
                closeButtonPressed={this.onCloseButtonPressed}
                okButtonPressed={this.okButtonPressed}
                okButtonText={this.getOkButtonText()}
                onMachineNameChange={this.onMachineNameChanged}
                onMachineCategoryChange={this.onMachineCategoryChange}
                onMachineDescriptionChange={this.onMachineDescriptionChanged}
                okButtonValidator={this.okButtonValidator}
                onImageChanged={this.onImageChanged}
                machineImage={props.machineImage}
                machineName={props.machineName}
                machineCategory={props.machineCategory}
                machineDescription={props.machineDescription}
                disabled={this.isEditingDisallowed()}
                memberVerified={props.memberVerified}
                machineCapacities={props.machineCapacities}
                onMachineCapacityChange={this.onMachineCapacityChanged}
                dataChanged={this.state.dataChanged}
                history={this.props.history}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AddNewMachineOverlay = Component;

Component.propTypes = {
    abortMachineEdit:                     PropTypes.func,
    apiMode:                              PropTypes.apiMode,
    clearAlerts:                          PropTypes.func,
    editMachineImage:                     PropTypes.func,
    history:                              PropTypes.object,
    isCreator:                            PropTypes.bool,
    isLoading:                            PropTypes.bool,
    machine:                              PropTypes.object,
    machineCapacities:                    PropTypes.string,
    machineCapacityChanged:               PropTypes.func,
    machineCategory:                      PropTypes.string,
    machineCategoryChanged:               PropTypes.func,
    machineDescription:                   PropTypes.string,
    machineDescriptionChanged:            PropTypes.func,
    machineImage:                         PropTypes.string,
    machineName:                          PropTypes.string,
    machineNameChanged:                   PropTypes.func,
    memberVerified:                       PropTypes.bool,
    openAddNewMachineManufacturerOverlay: PropTypes.func,
    showErrorAlert:                       PropTypes.func,
    updateMachineCapacity:                PropTypes.func,
    userCompanyId:                        PropTypes.string,
    userCompanyIri:                       PropTypes.string,
};

Component.defaultProps = {
    abortMachineEdit:                     _.noop,
    apiMode:                              null,
    clearAlerts:                          _.noop,
    editMachineImage:                     _.noop,
    history:                              null,
    isCreator:                            false,
    isLoading:                            false,
    machine:                              null,
    machineCapacities:                    null,
    machineCapacityChanged:               _.noop,
    machineCategory:                      null,
    machineCategoryChanged:               _.noop,
    machineDescription:                   null,
    machineDescriptionChanged:            _.noop,
    machineImage:                         null,
    machineName:                          null,
    machineNameChanged:                   _.noop,
    memberVerified:                       false,
    openAddNewMachineManufacturerOverlay: _.noop,
    showErrorAlert:                       _.noop,
    updateMachineCapacity:                _.noop,
    userCompanyId:                        null,
    userCompanyIri:                       null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'dataChanged',
];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...AlertBoxActions,
        ...MachineActions,
        ...TagActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    const machine            = _.get(state, 'machine.ownMachineEdit');
    const userCompanyId      = StateHelper.getUserCompanyId(state);
    const userCompanyIri     = StateHelper.getUserCompanyIri(state);
    const memberVerified     = StateHelper.getCompanyVerified(state, userCompanyId).verified;
    const createdByCompanyId = _.get(machine, 'createdByCompany.id');
    const machineCapacities  = _.get(machine, 'capacities');
    const machineCategories  = _.get(machine, 'categories');
    const machineCategory    = _.get(_.find(machineCategories, ['company.id', userCompanyId]), 'name');
    const currentYear        = new Date().getFullYear();
    const capacities         = _.range(0, yearsToShow).map((offset) => {
        const year             = currentYear + offset;
        const existingCapacity = _.find(machineCapacities, (capacity) => {
            return (
                _.get(capacity, 'year') === year &&
                _.get(capacity, 'company.id') === userCompanyId
            );
        });
        const result           = {
            year,
            capacity: MachineCapacity.free,
            ...existingCapacity,
        };

        return result;
    });

    return {
        apiMode:            _.get(state, 'machine.apiMode'),
        isCreator:          userCompanyId === createdByCompanyId,
        machine,
        machineCategory,
        machineDescription: _.get(machine, 'description'),
        machineImage:       _.get(machine, 'imageUploaded'),
        machineName:        _.get(machine, 'name'),
        machineCapacities:  capacities,
        memberVerified,
        userCompanyId,
        userCompanyIri,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
