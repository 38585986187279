//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }        from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { push }       from 'connected-react-router';
import _              from 'lodash';
import I18n           from 'i18next';

import Overlays                  from '@/constants/Overlays';
import { OverlayManager }        from '@/components/connected/OverlayManager';
import { ProductActions }        from '@/store/actions/product';
import { ProductTypes }          from '@/store/actions/product';
import HydraHelper               from '@/helper/Hydra';
import * as Api                  from '@/api';
import SagaStateHelper           from '@/helper/SagaStateHelper';
import StateHelper               from '@/helper/State';
import { AlertBoxActions }       from '@/store/actions/alertBox';
import { MessageRequestActions } from '@/store/actions/messageRequest';
import Route                     from '@/helper/Route';
import Routes                    from '@/constants/Routes';
import TagEditor                 from '@/helper/TagEditor';
import TagEditorContext          from '@/constants/TagEditorContext';
import { TagActions }            from '@/store/actions/tag';
import { getError }              from '@/constants/Errors';

function* fetchProducts() {
    const state      = yield SagaStateHelper.getState();
    const companyIri = StateHelper.getCurrentCompanyIri(state);
    const response   = yield call(
        Api.fetchProducts,
        {
            'company.id': companyIri,
        },
    );

    if (response.ok) {
        const products = HydraHelper.cleanupObject(response.data.member);

        yield put(ProductActions.fetchProductsSucceeded({
            products,
        }));
    } else {
        yield put(ProductActions.fetchProductsFailed());
    }
}

function* deleteProduct(action) {
    const product      = _.get(action, 'product');
    const response     = yield call(
        Api.deleteProduct,
        _.get(product, 'iri'),
    );
    const responseOkay = (
        response.ok ?
            ProductActions.deleteProductSucceeded :
            ProductActions.deleteProductFailed
    );

    yield put(responseOkay());
}

function* deleteProductFailed() {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('deleteProductFailed'),
    }));
}

function* deleteProductSucceeded() {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('deleteProductSucceeded'),
    }));
    yield put(ProductActions.fetchProducts());
}

function* openEditProductOverlay(action) {
    const ownProduct = action.product;
    yield put(ProductActions.loadOwnProduct({
        ownProduct,
    }));
    yield put(TagActions.buildHierarchy({
        context: TagEditorContext.newProduct,
        tags:    _.get(ownProduct, 'tags', []),
    }));
    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.addNewProduct)));
}

function* requestProduct(action) {
    const { product }  = action;
    const company      = _.get(product, 'company');
    const companyId    = _.get(company, 'id');
    const companySlug  = _.get(company, 'slug');
    const companyUrl   = Route.getCompanyUrl(companyId, companySlug);
    const companyRoute = Route.buildRoute(Routes.companyMessage, companyUrl);
    const companyPath  = Route.buildPathByRoute(companyRoute);

    yield put(MessageRequestActions.editMessageRequestField({
        field: 'machine',
        value: null,
    }));
    yield put(MessageRequestActions.editMessageRequestField({
        field: 'portfolioProduct',
        value: product,
    }));
    yield put(push(companyPath));
}

function* saveProduct() {
    const product       = yield SagaStateHelper.selectFromState('product', 'ownProductEdit');
    const image         = _.get(product, 'image', null);
    const state         = yield SagaStateHelper.getState();
    const companyIri    = StateHelper.getCurrentCompanyIri(state);
    const tagIris       = yield TagEditor.getTagsFromEditor(TagEditorContext.newProduct);
    const mappedProduct = {
        ...product,
        tag:     _.get(product, 'tag.iri', null),
        company: companyIri,
        tags:    tagIris,
    };

    if (_.isObject(image)) {
        delete mappedProduct.image;
    }

    const response = yield call(
        Api.saveProduct,
        mappedProduct,
    );

    if (response.ok) {
        yield put(ProductActions.saveProductSucceeded());
    } else {
        const error = getError(response);

        yield put(ProductActions.saveProductFailed({
            error,
        }));
    }
}

function* saveProductFailed(action) {
    const error       = _.get(action, 'error', null);
    const messageKey  = _.get(error, 'messageKey', 'saveProductFailed');
    const state       = yield SagaStateHelper.getState();
    const userCompany = yield StateHelper.getUserCompany(state);

    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(
            messageKey,
            {
                amount: _.get(userCompany, 'maximumPortfolioProductCount', 0),
            },
        ),
    }));
}

function* saveProductSucceeded() {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('saveProductSucceeded'),
    }));
    yield put(ProductActions.fetchProducts());
}

export const callProductSagas = () => {
    return [
        // @formatter:off
        takeLatest([ProductTypes.OPEN_EDIT_PRODUCT_OVERLAY],  openEditProductOverlay),
        takeLatest([ProductTypes.EDIT_PRODUCT],               openEditProductOverlay),
        takeLatest([ProductTypes.REQUEST_PRODUCT],            requestProduct),
        takeLatest([ProductTypes.SAVE_PRODUCT],               saveProduct),
        takeLatest([ProductTypes.SAVE_PRODUCT_FAILED],        saveProductFailed),
        takeLatest([ProductTypes.SAVE_PRODUCT_SUCCEEDED],     saveProductSucceeded),
        takeLatest([ProductTypes.FETCH_PRODUCTS],             fetchProducts),
        takeLatest([ProductTypes.DELETE_PRODUCT],             deleteProduct),
        takeLatest([ProductTypes.DELETE_PRODUCT_FAILED],      deleteProductFailed),
        takeLatest([ProductTypes.DELETE_PRODUCT_SUCCEEDED],   deleteProductSucceeded),
        // @formatter:on
    ];
};
