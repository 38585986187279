//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                from 'immutability-helper';
import { CompanyTypesTypes } from '@/store/actions/companyType';

const initialState = {
    companyTypes: [],
};

const fetchCompanyTypeSucceeded = (action, state) => {
    return update(state, {
        companyTypes: {
            $set: action.companyTypes,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case CompanyTypesTypes.FETCH_COMPANY_TYPES_SUCCEEDED: return fetchCompanyTypeSucceeded(action, state);
        default:                                              return state;
        // @formatter:on
    }
}
