//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import { connect }     from 'react-redux';
import ComponentHelper from '@/helper/ComponentHelper';
import StatelessMap    from '@/components/stateless/atomic/Map';
import PropTypes       from '@/components/PropTypes';

export class Component extends React.Component {
    render() {
        const { cookiesAccepted, geoLocation, pinName } = this.props;

        return (
            <StatelessMap
                cookiesAccepted={cookiesAccepted}
                geoLocation={geoLocation}
                pinName={pinName}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Map = Component;

Component.propTypes = {
    cookiesAccepted: PropTypes.bool,
    geoLocation:     PropTypes.geoLocation,
    pinName:         PropTypes.string,
};

Component.defaultProps = {
    cookiesAccepted: null,
    geoLocation:     {
        latitude:  0,
        longitude: 0,
    },
    pinName:         null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        cookiesAccepted: state.cookieBanner.accepted,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
