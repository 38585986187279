//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }        from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import { ProfileTypes }      from '@/store/actions/profile';
import { ProfileActions }    from '@/store/actions/profile';
import * as Api              from '@/api';
import { UserActions }       from '@/store/actions/user';
import { UserTypes }         from '@/store/actions/user';
import StateHelper           from '@/helper/State';
import { SaveProfileSchema } from '@/validations/saveProfile';
import { ProjectsActions }   from '@/store/actions/projects';
import { push }              from 'connected-react-router';
import Routes                from '@/constants/Routes';
import Route                 from '@/helper/Route';
import _                     from 'lodash';
import { TagActions }        from '@/store/actions/tag';
import TagEditorContext      from '@/constants/TagEditorContext';
import TagEditor             from '@/helper/TagEditor';
import { AlertBoxActions }   from '@/store/actions/alertBox';
import I18n                  from 'i18next';

function* loadInitialProfileData() {
    const userState = yield select((state) => state.user);
    const {
        email,
        name,
        workingAtCompany,
        position,
        phone,
        country,
        preferredLanguage,
        timezone,
        interests,
    }         = userState;

    yield put(ProfileActions.setInitialProfileData({
        data: {
            email,
            name,
            workingAtCompany,
            position,
            phone,
            country,
            preferredLanguage,
            timezone,
            interests,
        },
    }));
}

function* setInitialProfileData(action) {
    const { data }  = action;
    const interests = _.get(data, 'interests', []);

    yield put(TagActions.buildHierarchy({
        tags:    interests,
        context: TagEditorContext.myProfileInterests,
    }));
}

function* saveProfile() {
    const state           = yield select((reduxState) => reduxState);
    const profileState    = yield select((reduxState) => reduxState?.profile?.data);
    const userIri         = StateHelper.getCurrentUserIri(state);
    const newProfileState = {
        ...profileState,
        imageString: profileState.image,
        country:     profileState.country?.value,
        position:    profileState.position?.value,
        interests:   yield TagEditor.getTagsFromEditor(TagEditorContext.myProfileInterests),
    };

    delete newProfileState.image;

    const validationResult = SaveProfileSchema.safeParse(newProfileState);

    if (!validationResult.success) {
        yield put(ProfileActions.saveProfileFailed({
            error: validationResult,
        }));
        yield put(ProfileActions.validationChanged({
            validation: validationResult,
        }));
    } else {
        const response = yield call(
            Api.context.profile.save,
            userIri,
            newProfileState,
        );

        if (response.ok) {
            yield put(ProfileActions.saveProfileSucceeded());
        } else {
            yield put(ProfileActions.saveProfileFailed());
        }
    }
}

function* saveProfileSucceeded() {
    yield put(UserActions.fetch());
    yield takeLatest([UserTypes.FETCH_SUCCEEDED], loadInitialProfileData);
    yield put(AlertBoxActions.clearAlerts());
    yield put(AlertBoxActions.showSuccessAlert({
        text:        I18n.t('saveProfileSuccess'),
        scrollToTop: true,
    }));
}

function* saveProfileFailure() {
    console.log('Profile: saveProfileFailure called');
    yield put(AlertBoxActions.clearAlerts());
    yield put(AlertBoxActions.showErrorAlert({
        text:        I18n.t('saveProfileFailure'),
        scrollToTop: true,
    }));
}

function* buildPathForRoute(routeName) {
    const companyId = yield select((state) => _.get(state, 'user.company'));

    if (_.isNil(companyId)) {
        return null;
    }

    const route = Route.buildRoute(routeName, companyId);
    const path  = Route.buildPathByRoute(route);

    return path;
}

function* redirectToCompanyRoute(routeName) {
    const companyPath = yield buildPathForRoute(routeName);

    if (companyPath) {
        yield put(push(companyPath));

        return true;
    }

    return false;
}

function* openMyProfileProjects() {
    const redirectedToCompany = yield redirectToCompanyRoute(Routes.companyProjects);

    if (!redirectedToCompany) {
        yield put(ProjectsActions.fetchProjectsOwnProjects());
        yield put(push(Routes.myProfileProjects));
    }
}

function* openMyProfileMessages() {
    const redirectedToCompany = yield redirectToCompanyRoute(Routes.companyMessage);

    if (!redirectedToCompany) {
        yield put(push(Routes.myProfileMessages));
    }
}

function* openFavorites() {
    yield put(push(Routes.favorites));
}

export const callProfileSagas = () => {
    return [
        // @formatter:off
        takeLatest([ProfileTypes.LOAD_INITIAL_PROFILE_DATA], loadInitialProfileData),
        takeLatest([ProfileTypes.SET_INITIAL_PROFILE_DATA],  setInitialProfileData),
        takeLatest([ProfileTypes.SAVE_PROFILE],              saveProfile),
        takeLatest([ProfileTypes.SAVE_PROFILE_SUCCEEDED],    saveProfileSucceeded),
        takeLatest([ProfileTypes.SAVE_PROFILE_FAILED],       saveProfileFailure),
        takeLatest([ProfileTypes.OPEN_MY_PROFILE_PROJECTS],  openMyProfileProjects),
        takeLatest([ProfileTypes.OPEN_MY_PROFILE_MESSAGES],  openMyProfileMessages),
        takeLatest([ProfileTypes.OPEN_FAVORITES],            openFavorites),
        // @formatter:on
    ];
};
