//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import classNames      from 'classnames';
import I18n            from 'i18next';
import ComponentHelper from '@/helper/ComponentHelper';
import IconButton      from '@/components/stateless/atomic/IconButton';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import PropTypes       from '@/components/PropTypes';
import SelectionHelper from '@/helper/SelectionHelper';
import ToastType       from '@/components/stateless/atomic/ToastWrapper/ToastType';

import styles from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div
                className={classNames(
                    styles.alertBox,
                    SelectionHelper.get(
                        this.props.type,
                        {
                            [ToastType.error]:   styles.alertBoxError,
                            [ToastType.success]: styles.alertBoxSuccess,
                            [ToastType.warning]: styles.alertBoxWarning,
                        },
                    ),
                )}
            >
                <h3>
                    {this.renderTitle()}
                </h3>
                <p>
                    {this.props.text}
                </p>
                {this.renderCloseButton()}
            </div>
        );
    }

    renderCloseButton = () => {
        if (this.props.onCloseButtonClick) {
            return (
                <span className={styles.alertBoxCloseButtonWrapper}>
                    <IconButton
                        iconType={IconType.cross}
                        onClick={this.props.onCloseButtonClick}
                    />
                </span>
            );
        }

        return null;
    };

    renderTitle = () => {
        switch (this.props.type) {
            // @formatter:off
            case ToastType.error:   return I18n.t('error');
            case ToastType.success: return I18n.t('success');
            case ToastType.warning: return I18n.t('warning');
            // @formatter:on
        }

        console.warn('AlertBox type is unknown, you may forgot to add it to the stateless AlertBox component');

        return I18n.t('unknown');
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AlertBox = Component;

Component.propTypes = {
    onCloseButtonClick: PropTypes.func,
    text:               PropTypes.string,
    type:               PropTypes.oneOf(Object.values(ToastType)),
};

Component.defaultProps = {
    onCloseButtonClick: null,
    text:               null,
    type:               null,
};

Component.renderAffectingProps = [
    'text',
    'type',
];

Component.renderAffectingStates = [];

export default Component;
