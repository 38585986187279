//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import I18n            from 'i18next';
import * as Api        from '@/api';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import { FullImage }   from '@/components/stateless/composed/FullImage';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.machineSearchResultWrapper}>
                <FullImage
                    image={Api.getImagePath(this.props.machineImage)}
                    alternativText={`${this.props.machineName} ${I18n.t('image')}`}
                    height={50}
                />
                {this.props.machineName}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const MachineSearchResult = Component;

Component.propTypes = {
    machineImage: PropTypes.image,
    machineName:  PropTypes.string,
};

Component.defaultProps = {
    machineImage: null,
    machineName:  null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
