//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }       from 'connected-react-router';
import { put }        from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';

import Routes                           from '@/constants/Routes';
import { StagingAuthenticationActions } from '@/store/actions/stagingAuthentication';
import { StagingAuthenticationTypes }   from '@/store/actions/stagingAuthentication';

function* authenticate() {
    const isAuthenticated = yield select((state) => state.stagingAuthentication.isAuthenticated);

    if (isAuthenticated) {
        yield put(StagingAuthenticationActions.authenticationSucceeded({}));
    }
}

function* authenticationSucceeded() {
    yield put(push(Routes.login));
}

export const callStagingAuthenticationSagas = () => {
    return [
        // @formatter:off
        takeLatest([StagingAuthenticationTypes.AUTHENTICATE],             authenticate),
        takeLatest([StagingAuthenticationTypes.AUTHENTICATION_SUCCEEDED], authenticationSucceeded),
        // @formatter:on
    ];
};
