//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                               from 'react';
import ComponentHelper                     from '@/helper/ComponentHelper';
import I18n                                from 'i18next';
import _                                   from 'lodash';
import IconType                            from '@/components/stateless/atomic/Icon/IconType';
import { Icon }                            from '@/components/stateless/atomic/Icon';
import classNames                          from 'classnames';
import colors                              from '@/styles/colors.module.scss';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import propTypes                           from '@/components/PropTypes';
import styles                              from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showBatch: true,
        };
    }

    hideBatch = () => {
        this.setState({
            showBatch: false,
        });
    };

    isComplete() {
        return _.every(this.props.completionSteps, 'fullFilled');
    }

    renderCompletionsSteps() {
        return _.map(this.props.completionSteps, ({ title, fullFilled }) => {
            const iconType = fullFilled ?
                IconType.completeProfileCheck :
                IconType.completeProfileUncheck;

            return (
                <div
                    className={classNames(
                        styles.completionStep,
                        {
                            [styles.fullFilled]: fullFilled,
                        },
                    )}
                >
                    <Icon iconType={iconType} />
                    {title}
                </div>
            );
        });
    }

    getPercentageCompletionProgress() {
        const fullFilledSteps = _.filter(this.props.completionSteps, 'fullFilled');

        return (
            fullFilledSteps.length / this.props.completionSteps.length
        ) * 100;
    }

    getCompletionProgressText() {
        const fullFilledSteps = _.filter(this.props.completionSteps, 'fullFilled');

        return `${fullFilledSteps.length}/${this.props.completionSteps.length}`;
    }

    render() {
        if (
            !this.state.showBatch ||
            this.isComplete()
        ) {
            return null;
        }

        return (
            <div className={styles.profileCompletionBatch}>
                <button
                    className={styles.hideButton}
                    onClick={this.hideBatch}
                >
                    <Icon iconType={IconType.cross} />
                </button>
                <div className={styles.completionTextAndRing}>
                    <div className={styles.completionRing}>
                        <CircularProgressbarWithChildren
                            value={this.getPercentageCompletionProgress()}
                            styles={{
                                path:  {
                                    stroke: colors.colorOrange,
                                },
                                trail: {
                                    stroke: colors.colorGrayBlue,
                                },
                            }}
                        >
                            <span className={styles.completionRingInner}>
                                {this.getCompletionProgressText()}
                            </span>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className={styles.completionText}>
                        <h4>
                            {I18n.t('completionHeadline')}
                        </h4>
                        <p>
                            {I18n.t('completionText')}
                        </p>
                    </div>

                </div>
                <div className={styles.completionSteps}>
                    {this.renderCompletionsSteps()}
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ProfileCompletionBatch = Component;

Component.propTypes = {
    completionSteps: propTypes.arrayOf(propTypes.shape({
        title:      propTypes.string,
        fullFilled: propTypes.bool,
    })),
};

Component.defaultProps = {
    completionSteps: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = ['showBatch'];

export default Component;
