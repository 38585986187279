//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';

import _ from 'lodash';

import * as Api  from '@/api';
import PropTypes from '@/components/PropTypes';
import ApiUrls   from '@/constants/ApiUrls';

const propTypes = {
    originalText:   PropTypes.string,
    style:          PropTypes.object,
    targetLanguage: PropTypes.string,
};

const AutomaticTextTranslator = ({
    originalText,
    targetLanguage,
}) => {
    const [translatedText, setTranslatedText] = useState(null);

    useEffect(
        () => {
            const fetchTranslatedText = async () => {
                const encodedText           = encodeURIComponent(originalText);
                const response              = await fetch(
                    Api.getUrl(`${ApiUrls.API_TRANSLATOR}?text=${encodedText}&target_lang=${targetLanguage}`),
                    {
                        headers: {
                            'Authorization': Api.getAuth(),
                        },
                    },
                );
                const content               = await response.json();
                const translatedTextFromApi = content.text;

                setTranslatedText(translatedTextFromApi);
            };

            fetchTranslatedText();
        },
        [
            originalText,
            targetLanguage,
        ],
    );

    return _.defaultTo(translatedText, originalText);
};

AutomaticTextTranslator.propTypes = propTypes;

export default React.memo(AutomaticTextTranslator);
