//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import { Component }          from 'react';
import _                      from 'lodash';
import I18n                   from 'i18next';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import ColorButton            from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme       from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import Routes                 from '@/constants/Routes';
import { AlertBoxActions }    from '@/store/actions/alertBox';
import { TextInput }          from '@/components/stateless/atomic/TextInput';
import { UserActions }        from '@/store/actions/user';
import StartPageWithBox       from '@/components/stateless/composed/StartPageWithBox';
import { StartPageBoxFooter } from '@/components/stateless/composed/StartPageBoxFooter';
import Align                  from '@/constants/Align';

import styles from './styles.module.scss';

class Screen extends Component {
    loginButtonPressed = () => {
        console.log('LoginScreen: loginButtonPressed');

        this.props.clearAlerts();
        this.props.login();
    };

    onFormSubmit = (event) => {
        event.preventDefault();
    };

    render() {
        const title = I18n.t('login');

        return (
            <StartPageWithBox pageTitle={title}>
                <form onSubmit={this.onFormSubmit}>
                    <div className={styles.loginInputWrapper}>
                        <TextInput
                            placeholder={I18n.t('emailAddress')}
                            onChange={this.identifierChanged}
                            value={this.props.identifier}
                            autoFocus={true}
                        />
                        <TextInput
                            placeholder={I18n.t('password')}
                            type={'password'}
                            onChange={this.passwordChanged}
                            value={this.props.password}
                        />
                    </div>
                    <div className={styles.loginButtonWrapper}>
                        <ColorButton
                            grow={true}
                            theme={ColorButtonTheme.orange}
                            text={title}
                            onClick={this.loginButtonPressed}
                        />
                        <ColorButton
                            grow={true}
                            theme={ColorButtonTheme.white}
                            text={I18n.t('signUp')}
                            to={Routes.signUp}
                        />
                    </div>
                </form>
                <StartPageBoxFooter
                    text={I18n.t('lostPassword')}
                    to={Routes.lostPassword}
                    align={Align.left}
                />
            </StartPageWithBox>
        );
    }

    identifierChanged = (event) => {
        this.props.clearAlerts();
        this.props.setLoginIdentifier({
            identifier: event.target.value,
        });
    };

    passwordChanged = (event) => {
        this.props.clearAlerts();
        this.props.setLoginPassword({
            password: event.target.value,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    clearAlerts:        PropTypes.func,
    identifier:         PropTypes.string,
    login:              PropTypes.func,
    password:           PropTypes.string,
    setLoginIdentifier: PropTypes.func,
    setLoginPassword:   PropTypes.func,
};

Screen.defaultProps = {
    clearAlerts:        _.noop,
    identifier:         null,
    login:              _.noop,
    password:           null,
    setLoginIdentifier: _.noop,
    setLoginPassword:   _.noop,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        password:   _.get(state, 'user.loginPassword'),
        identifier: _.get(state, 'user.loginIdentifier'),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    AlertBoxActions,
    UserActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
