//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const FavoriteTypes = {
    ADD_FAVORITE_COMPANY:              'Favorite/ADD_FAVORITE_COMPANY',
    ADD_FAVORITE_COMPANY_FAILED:       'Favorite/ADD_FAVORITE_COMPANY_FAILED',
    ADD_FAVORITE_COMPANY_SUCCEEDED:    'Favorite/ADD_FAVORITE_COMPANY_SUCCEEDED',
    REMOVE_FAVORITE_COMPANY:           'Favorite/REMOVE_FAVORITE_COMPANY',
    REMOVE_FAVORITE_COMPANY_FAILED:    'Favorite/REMOVE_FAVORITE_COMPANY_FAILED',
    REMOVE_FAVORITE_COMPANY_SUCCEEDED: 'Favorite/REMOVE_FAVORITE_COMPANY_SUCCEEDED',
};

const addFavoriteCompany = makeActionCreator(
    FavoriteTypes.ADD_FAVORITE_COMPANY,
    {
        company: null,
    },
);

const addFavoriteCompanyFailed = makeActionCreator(
    FavoriteTypes.ADD_FAVORITE_COMPANY_FAILED,
    {},
);

const addFavoriteCompanySucceeded = makeActionCreator(
    FavoriteTypes.ADD_FAVORITE_COMPANY_SUCCEEDED,
    {},
);

const removeFavoriteCompany = makeActionCreator(
    FavoriteTypes.REMOVE_FAVORITE_COMPANY,
    {
        company: null,
    },
);

const removeFavoriteCompanyFailed = makeActionCreator(
    FavoriteTypes.REMOVE_FAVORITE_COMPANY_FAILED,
    {},
);

const removeFavoriteCompanySucceeded = makeActionCreator(
    FavoriteTypes.REMOVE_FAVORITE_COMPANY_SUCCEEDED,
    {},
);

export const FavoriteActions = {
    addFavoriteCompany,
    addFavoriteCompanyFailed,
    addFavoriteCompanySucceeded,
    removeFavoriteCompany,
    removeFavoriteCompanyFailed,
    removeFavoriteCompanySucceeded,
};
