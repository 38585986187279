//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                       from 'react';
import _                           from 'lodash';
import PropTypes                   from '@/components/PropTypes';
import ComponentHelper             from '@/helper/ComponentHelper';
import { MachineCapacityDropdown } from '@/components/stateless/composed/MachineCapacityDropdown';
import styles                      from './styles.module.scss';

export class Component extends React.Component {
    machineCapacityChanged = (year) => (selectedOption) => {
        const value = _.get(selectedOption, 'value', null);

        this.props.onChange(year, value);
    };

    getOptionValue = (option) => option.value;

    getOptionLabel = (option) => option.label;

    render() {
        return (
            <div className={styles.capacityInputWrapper}>
                <div className={styles.capacityInputList}>
                    {this.renderList()}
                </div>
            </div>
        );
    }

    renderMachineCapacity = ({ year, capacity }) => {
        return (
            <div
                key={year}
                className={styles.capacityInputRow}
            >
                <div className={styles.capacityInputColumn}>
                    {year}
                </div>
                <div className={styles.capacityInputColumn}>
                    <MachineCapacityDropdown
                        onChange={this.machineCapacityChanged(year)}
                        machineCapacity={capacity}
                        disabled={false}
                    />
                </div>
            </div>
        );
    };

    renderList = () => {
        return _.map(
            this.props.machineCapacities,
            (entry) => this.renderMachineCapacity(entry),
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const DevelopmentRevenue = Component;

Component.propTypes = {
    disabled:          PropTypes.bool,
    machineCapacities: PropTypes.array,
    onChange:          PropTypes.func,
};

Component.defaultProps = {
    disabled:          false,
    machineCapacities: [],
    onChange:          _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
