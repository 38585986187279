//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import PropTypes from '@/components/PropTypes';
import styles    from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.contentHeaderTitleWrapper}>
                <div className={styles.contentHeaderTitleTextContainer}>
                    <span className={styles.contentHeaderTitleTextTitle}>
                        {this.props.title}
                    </span>
                    <span className={styles.contentHeaderTitleTextSubtitle}>
                        {this.props.subtitle}
                    </span>
                </div>
            </div>
        );
    }
}

export const ContentHeaderTitle = Component;

Component.propTypes = {
    subtitle: PropTypes.string,
    title:    PropTypes.string,
};

Component.defaultProps = {
    subtitle: '',
    title:    '',
};

export default Component;
