//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                   from 'react';
import _                       from 'lodash';
import I18n                    from 'i18next';
import { bindActionCreators }  from 'redux';
import { compose }             from 'redux';
import { connect }             from 'react-redux';
import * as Api                from '@/api';
import appStyles               from '@/styles.module.scss';
import ComponentHelper         from '@/helper/ComponentHelper';
import Filter                  from '@/constants/Filter';
import FilterHelper            from '@/helper/Filter';
import Ids                     from '@/constants/Ids';
import MachineParameter        from '@/components/stateless/atomic/MachineParameter';
import PropTypes               from '@/components/PropTypes';
import TagList                 from '@/components/stateless/composed/TagList';
import { CollapsableTagList }  from '@/components/stateless/composed/CollapsableTagList';
import { CompanyListTable }    from '@/components/stateless/composed/CompanyListTable';
import { MachineActions }      from '@/store/actions/machine';
import { MachineBox }          from '@/components/stateless/composed/MachineBox';
import { HeadlineMedium }      from '@/components/stateless/atomic/HeadlineMedium';
import { MachineHeader }       from '@/components/stateless/composed/MachineHeader';
import { MachineParameterBox } from '@/components/stateless/composed/MachineParameterBox';
import { Spacer }              from '@/components/stateless/atomic/Spacer';
import Facts                   from '@/components/stateless/atomic/Facts';
import { ColorBox }            from '@/components/stateless/atomic/ColorBox';
import MemberStatus            from '@/constants/MemberStatus';
import MachineCapacity         from '@/constants/MachineCapacity';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        const machineId = props.machineId;

        this.props.fetchMachine({
            machineId,
        });
    }

    getMachine = () => {
        return _.get(this.props, ['machine']);
    };

    renderMachineParameter = (machineParameter) => {
        const title = _.get(machineParameter, 'name');
        const value = _.get(machineParameter, 'value');

        return (
            <MachineParameter
                title={title}
                value={value}
                key={`machine-parameter-${title}-${value}`}
            />
        );
    };

    renderParameterBox = () => {
        const machine    = this.getMachine();
        const parameters = _.get(machine, 'parameters');

        return (
            <MachineParameterBox>
                {_.map(parameters, this.renderMachineParameter)}
            </MachineParameterBox>
        );
    };

    renderTagList = () => {
        const machine = this.getMachine();
        const tagList = _.get(machine, 'tagStrings');

        return (
            <CollapsableTagList>
                <TagList tags={tagList} />
            </CollapsableTagList>
        );
    };

    renderMachineParameters = (parameters) => {
        return _.map(
            parameters,
            (parameter) => (
                <MachineParameter
                    title={parameter.name}
                    value={parameter.value}
                />
            ),
        );
    };

    renderMachineBoxParameters = (parameters) => {
        if (parameters.length) {
            return (
                <ColorBox>
                    <Facts
                        headline={I18n.t('parameters')}
                        facts={_.map(parameters, (parameter) => {
                            return {
                                label: parameter.name,
                                value: `${parameter.value} ${parameter.unit}`,
                            };
                        })}
                        responsive={true}
                    />
                </ColorBox>
            );
        }

        return null;
    };

    renderMachineBox = () => {
        const machine      = this.getMachine();
        const name         = _.get(machine, 'name');
        const parameters   = _.get(machine, 'parameters', []);
        const description  = _.get(machine, 'description');
        const imagePath    = _.get(machine, 'image.path');
        const manufacturer = _.get(machine, 'manufacturer.name');

        return (
            <MachineBox
                topLeftContent={
                    <MachineHeader
                        image={Api.getImagePath(imagePath)}
                        title={name}
                        manufacturer={manufacturer}
                        text={description}
                    >
                    </MachineHeader>
                }
                rightContent={this.renderTagList()}
                bottomLeftContent={this.renderMachineBoxParameters(parameters)}
            />
        );
    };

    filterCompanies = () => {
        return FilterHelper.filterResults(_.get(this.props, 'companies'), this.props.filters);
    };

    renderCompaniesOwning = () => {
        const companies      = this.filterCompanies();
        const { capacities } = this.props;

        return (
            <>
                <HeadlineMedium text={I18n.t('companiesOwningMachine')} />
                <Spacer height={30} />
                <CompanyListTable
                    companies={companies}
                    capacities={capacities}
                    history={this.props.history}
                />
            </>
        );
    };

    render() {
        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultColumnInnerContainer}
                    id={Ids.contentContainer}
                >
                    {this.renderMachineBox()}
                    <Spacer height={70} />
                    {this.renderCompaniesOwning()}
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    capacities:   PropTypes.array,
    fetchMachine: PropTypes.func,
    filters:      PropTypes.object,
    history:      PropTypes.object,
    machineId:    PropTypes.string,
};

Screen.defaultProps = {
    capacities:   [],
    fetchMachine: _.noop,
    filters:      {},
    history:      null,
    machineId:    null,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...MachineActions,
    },
    dispatch,
);

const mapStateToProps = (state, ownProps) => {
    const filters          = _.omit(_.get(state, ['filter', 'filters', Filter.companyListTable.name], []), 'isOpen');
    const machineId        = _.get(ownProps, ['match', 'params', 'machineId']);
    const machines         = _.get(state, ['machine', 'machines'], []);
    const foundMachine     = _.find(
        machines,
        (machine) => _.endsWith(
            _.get(machine, 'iri'),
            machineId,
        ),
    );
    const capacities       = _.get(foundMachine, 'capacities', []);
    const machineCompanies = _.get(foundMachine, 'companies', []);
    const companies        = _.map(
        machineCompanies,
        (machineCompany) => {
            const companyId = _.get(machineCompany, 'id');
            const company   = _.get(state, ['company', 'companies', companyId]);

            return company;
        },
    );
    const currentYear      = new Date().getFullYear();
    const sortedCompanies  = _.sortBy(
        companies,
        (company) => {
            let bits       = 0;
            const masks    = {
                free:            0,
                inUse:           1,
                busy:            2,
                noCapacity:      1,
                noTrustedMember: 8,
                notVerified:     16,
            };
            const capacity = _.find(
                capacities,
                (entry) => {
                    return (
                        _.get(entry, 'company.id') === _.get(company, 'id') &&
                        _.get(entry, 'year') === currentYear
                    );
                },
            );

            if (!capacity) {
                bits |= masks.noCapacity;
            }

            const memberStatus = _.get(company, 'memberStatus', null);

            if (
                !memberStatus ||
                memberStatus !== MemberStatus.trustedMember
            ) {
                bits |= masks.noTrustedMember;
            }

            if (!_.get(company, 'verified', false)) {
                bits |= masks.notVerified;
            }

            if (!capacity) {
                bits |= masks.noCapacity;
            } else {
                switch (capacity.capacity) {
                    case MachineCapacity.free:
                        bits |= masks.free;

                        break;
                    case MachineCapacity.inUse:
                        bits |= masks.inUse;

                        break;
                    case MachineCapacity.busy:
                        bits |= masks.busy;

                        break;
                }
            }

            return bits;
        },
    );

    return {
        companies: sortedCompanies,
        filters,
        machineId,
        machine:   foundMachine,
        capacities,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
