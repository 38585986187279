//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                    from 'react';
import _                        from 'lodash';
import IconType                 from '@/components/stateless/atomic/Icon/IconType';
import { IconTextButton }       from '@/components/stateless/atomic/IconTextButton';
import { TableHeader }          from '@/components/stateless/atomic/TableHeader';
import { TableHeaderFilterRow } from '@/components/stateless/atomic/TableHeaderFilterRow';
import { TableBody }            from '@/components/stateless/atomic/TableBody';
import PropTypes                from '@/components/PropTypes';
import TableHeaderFilterColumn  from '@/components/stateless/atomic/TableHeaderFilterColumn';
import PaginateWrapper          from '@/components/stateless/composed/PaginateWrapper';
import styles                   from './styles.module.scss';

export class Component extends React.Component {
    renderTableHeaderFilterRow = (field) => {
        const fieldValue   = _.get(this.props.context.fields, [field, 'value']);
        const fieldPadding = _.get(this.props.context.fields, [field, 'padding']);
        const filterValue  = _.get(this.props.filters, [fieldValue, 'value'], '');

        return (
            <TableHeaderFilterColumn
                key={`table-header-filter-${field}`}
                field={field}
                onChange={this.props.onFilterChanged(field)}
                value={filterValue}
                fieldPadding={fieldPadding}
            />
        );
    };

    renderTableHeaderFilterRows = () => {
        const fields    = Object.keys(this.props.context.fields);
        const fillCount = this.props.columnCount - fields.length;

        [...Array(fillCount)].forEach(() => {
            fields.push(null);
        });

        return fields.map(this.renderTableHeaderFilterRow);
    };

    renderPaging = () => {
        const { paging, isLoggedIn } = this.props;

        if (
            !isLoggedIn ||
            !paging
        ) {
            return null;
        }

        return (
            <PaginateWrapper
                pageCount={_.get(paging, 'maxPage', 0)}
                initialPage={0}
                onPageChange={this.props.onUpdatePaging}
            />
        );
    };

    render() {
        return (
            <div className={styles.tableContainer}>
                <div className={styles.tableFilter}>
                    <IconTextButton
                        onClick={this.props.onFilterToggle}
                        iconType={IconType.filter}
                    />
                </div>
                <table className={styles.tableWithFilter}>
                    <TableHeader>
                        {this.props.renderTableHeaderRows}
                        <TableHeaderFilterRow show={this.props.isFilterOpen}>
                            {this.renderTableHeaderFilterRows()}
                        </TableHeaderFilterRow>
                    </TableHeader>
                    <TableBody>
                        {this.props.renderTableBodyRows}
                    </TableBody>
                </table>
                {this.renderPaging()}
            </div>
        );
    }
}

export const TableWithFilter = Component;

Component.propTypes = {
    columnCount:           PropTypes.number,
    context:               PropTypes.object,
    filters:               PropTypes.object,
    isFilterOpen:          PropTypes.bool,
    isLoggedIn:            PropTypes.bool,
    onFilterChanged:       PropTypes.func,
    onFilterToggle:        PropTypes.func,
    onResetFilterClicked:  PropTypes.func,
    onUpdatePaging:        PropTypes.func,
    paging:                PropTypes.object,
    renderTableBodyRows:   PropTypes.children,
    renderTableHeaderRows: PropTypes.children,
};

Component.defaultProps = {
    columnCount:           0,
    context:               {},
    filters:               {},
    isFilterOpen:          false,
    isLoggedIn:            false,
    onFilterChanged:       _.noop,
    onFilterToggle:        _.noop,
    onResetFilterClicked:  _.noop,
    onUpdatePaging:        _.noop,
    paging:                null,
    renderTableBodyRows:   null,
    renderTableHeaderRows: null,
};

export default Component;
