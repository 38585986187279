//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    companyListTable:      {
        name:   'companyListTable',
        fields: {
            company: {
                value: 'name',
            },
            region:  {
                value: 'address',
            },
        },
    },
    searchResultCompanies: {
        name:   'searchResultCompanies',
        fields: {
            company: {
                value:   'name',
                padding: 75,
            },
            region:  {
                value:   'address',
                padding: 76,
            },
        },
    },
    searchResultMachines:  {
        name:   'searchResultMachines',
        fields: {
            machine: {
                value:   'name',
                padding: 75,
            },
            company: {
                value: 'companies',
                padding: 76,
            },
            region:  {
                value: 'address',
                query: 'companies',
                padding: 75,
            },
        },
    },
};
