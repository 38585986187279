//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import ComponentHelper from '@/helper/ComponentHelper';
import CountryFlag     from '@/components/stateless/atomic/CountryFlag';
import PropTypes       from '@/components/PropTypes';
import CCPTranslations from '@/helper/CCPTranslations';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    getAddressString = () => {
        const streetHouseNumber = _.compact([
            this.props.street,
            this.props.houseNumber,
        ]).join(' ');

        const cityPostalCode = _.compact([
            this.props.postalCode,
            this.props.city,
        ]).join(' ');

        return _.compact([
            streetHouseNumber,
            cityPostalCode,
        ]).join(', ');
    };

    render() {
        return (
            <div className={styles.addressLabelWrapper}>
                <div className={styles.countryFlagWrapper}>
                    <CountryFlag
                        iso31661Alpha2CountryCode={this.props.iso31661Alpha2CountryCode}
                    />
                </div>
                <div className={styles.labelWrapper}>
                    <strong>
                        {this.getAddressString()}
                    </strong>
                    <span>
                        {CCPTranslations.countryString(this.props.country)}
                    </span>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AddressLabel = Component;

Component.propTypes = {
    city:                      PropTypes.string,
    country:                   PropTypes.string,
    houseNumber:               PropTypes.houseNumber,
    iso31661Alpha2CountryCode: PropTypes.string,
    postalCode:                PropTypes.postalCode,
    street:                    PropTypes.string,
};

Component.defaultProps = {
    city:                      null,
    country:                   null,
    houseNumber:               null,
    iso31661Alpha2CountryCode: null,
    postalCode:                null,
    street:                    null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
