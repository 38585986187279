//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import { Trans } from 'react-i18next';
import _         from 'lodash';

import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import { ColorBox }    from '@/components/stateless/atomic/ColorBox';
import { EntityLabel } from '@/components/stateless/composed/EntityLabel';
import EntityLabelSize from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import { TextInput }   from '@/components/stateless/atomic/TextInput';
import TextInputSize   from '@/components/stateless/atomic/TextInput/TextInputSize';
import * as Api        from '@/api';

import styles from './styles.module.scss';

export class Component extends React.Component {
    onMessageChange = (event) => {
        this.props.onMessageChanged(event.target.value);
    };

    render() {
        const { company, projectCreator, project } = this.props;

        if (!project) {
            return null;
        }

        const name = company?.name || projectCreator?.name;

        return (
            <div>
                <Trans
                    i18nKey={'projectsAcceptProjectText'}
                    values={{
                        projectTitle: project.name,
                        receiver:     name,
                    }}
                />
                <br />
                <br />
                <ColorBox className={styles.colorBoxWrapper}>
                    <div className={styles.companyWrapper}>
                        <EntityLabel
                            className={styles.companyLabel}
                            title={name}
                            subtitle={new Date().toUTCString()}
                            avatar={Api.getImagePath(company?.logo || projectCreator?.image)}
                            size={EntityLabelSize.small}
                            iso31661Alpha2CountryCode={'de'}
                        />
                    </div>
                    <TextInput
                        className={styles.messageInput}
                        size={TextInputSize.multiline}
                        onChange={this.onMessageChange}
                    />
                </ColorBox>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const InterestedInProjectMessageContent = Component;

Component.propTypes = {
    company:          PropTypes.object,
    projectCreator:   PropTypes.object,
    project:          PropTypes.object,
    onMessageChanged: PropTypes.func,
};

Component.defaultProps = {
    company:          null,
    projectCreator:   null,
    project:          null,
    onMessageChanged: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
