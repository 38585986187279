//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class Download {
    static downloadDataURI = (dataUri, name) => {
        this.downloadFile(
            dataUri,
            name,
        );
    };

    static downloadBlob = (blob, name) => {
        const blobObjectURL = URL.createObjectURL(blob);

        this.downloadFile(
            blobObjectURL,
            name,
        );
    };

    static downloadFile = (destination, name) => {
        console.log('Download: downloadFile', destination);

        const element            = document.createElement('a');
        element.href             = destination;
        element.download         = name;
        element.style.visibility = 'hidden';
        element.target           = '_blank';

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };
}

export default Download;
