//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const factSheetEditType = {
    developmentRevenue:  'developmentRevenue',
    exportRatio:         'exportRatio',
    facts:               'facts',
    products:            'products',
    revenueDistribution: 'revenueDistribution',
};

module.exports = factSheetEditType;
