//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import _                      from 'lodash';

import ComponentHelper         from '@/helper/ComponentHelper';
import PropTypes               from '@/components/PropTypes';
import { OverlayActions }      from '@/store/actions/ovleray';
import StatelessConfirmOverlay from '@/components/stateless/composed/ConfirmOverlay';

export class Component extends React.Component {
    okButtonPressed = () => {
        const { triggerConfirmAction } = this.props;

        triggerConfirmAction();
    };

    render() {
        const { confirmDialog, children } = this.props;

        return (
            <StatelessConfirmOverlay
                confirmDialog={confirmDialog}
            >
                {children}
            </StatelessConfirmOverlay>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ConfirmOverlay = Component;

Component.propTypes = {
    children:             PropTypes.children,
    confirmDialog:        PropTypes.object,
    triggerConfirmAction: PropTypes.func,
    triggerCancelAction:  PropTypes.func,
};

Component.defaultProps = {
    children:             null,
    confirmDialog:        null,
    triggerConfirmAction: _.noop,
    triggerCancelAction:  _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...OverlayActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    return {
        confirmDialog: _.get(state, 'overlay.confirmDialog'),
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
