//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import PropTypes from '@/components/PropTypes';
import styles    from './styles.module.scss';

export class Component extends React.Component {
    renderLeftContent = () => {
        const { bottomLeftContent } = this.props;

        if (bottomLeftContent) {
            return (
                <div className={styles.machineBoxBottomLeftContent}>
                    {bottomLeftContent}
                </div>
            );
        }

        return null;
    };

    render() {
        return (
            <div className={styles.machineBoxWrapper}>
                <div className={styles.machineBoxLeftContent}>
                    <div className={styles.machineBoxTopLeftContent}>
                        {this.props.topLeftContent}
                    </div>
                    {this.renderLeftContent()}
                </div>
                <div className={styles.machineBoxRightContent}>
                    {this.props.rightContent}
                </div>
            </div>
        );
    }
}

export const MachineBox = Component;

Component.propTypes = {
    bottomLeftContent: PropTypes.children,
    rightContent:      PropTypes.children,
    topLeftContent:    PropTypes.children,
};

Component.defaultProps = {
    bottomLeftContent: null,
    rightContent:      null,
    topLeftContent:    null,
};

export default Component;
