//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import _                    from 'lodash';
import I18n                 from 'i18next';
import PropTypes            from '@/components/PropTypes';
import ColorBoxColor        from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import ColorBox             from '@/components/stateless/atomic/ColorBox';
import Collapsable          from '@/components/stateless/composed/Collapsable';
import ComponentHelper      from '@/helper/ComponentHelper';
import CollapsableArrowMode from '@/components/stateless/composed/Collapsable/CollapsableArrowMode';
import { IconButton }       from '@/components/stateless/atomic/IconButton';
import IconType             from '@/components/stateless/atomic/Icon/IconType';
import TagDropDown          from '@/components/connected/TagDropDown';
import { FormHeadline }     from '@/components/stateless/atomic/FormHeadline';
import styles               from './styles.module.scss';

export class Component extends React.Component {
    getTitle = (collapsed) => {
        return (
            collapsed ?
                I18n.t('showTags') :
                I18n.t('showLessTags')
        );
    };

    entryChanged = (index) => (tag) => {
        this.props.updateEntry(index, tag);
    };

    addEntry = () => {
        this.props.addEntry();
    };

    removeEntry = (index) => () => {
        this.props.removeEntry(index);
    };

    renderTagDropDowns = () => {
        const { tags, values } = this.props;

        return _.map(values, (tag, index) => {
            return (
                <div
                    className={styles.requestMessageTagRow}
                    key={index}
                >
                    <TagDropDown
                        value={tag}
                        valueChanged={this.entryChanged(index)}
                        tags={tags}
                        placeholder={I18n.t('messageTagsPlaceholder')}
                    />
                    <IconButton
                        iconType={IconType.deleteCircle}
                        onClick={this.removeEntry(index)}
                    />
                </div>
            );
        });
    };

    render() {
        const { collapsed } = this.props;

        return (
            <ColorBox color={ColorBoxColor.grayLight}>
                <Collapsable
                    title={this.getTitle}
                    arrowMode={CollapsableArrowMode.left}
                    closed={collapsed}
                >
                    <FormHeadline
                        text={I18n.t('tags')}
                        onClick={this.addEntry}
                    />
                    <div className={styles.requestMessageTagWrapper}>
                        {this.renderTagDropDowns()}
                    </div>
                </Collapsable>
            </ColorBox>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const RequestMessageTags = Component;

Component.propTypes = {
    addEntry:    PropTypes.func,
    collapsed:   PropTypes.bool,
    removeEntry: PropTypes.func,
    tags:        PropTypes.array,
    updateEntry: PropTypes.func,
    values:      PropTypes.array,
};

Component.defaultProps = {
    addEntry:    _.noop,
    collapsed:   false,
    removeEntry: _.noop,
    tags:        [],
    updateEntry: _.noop,
    values:      [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
