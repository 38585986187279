//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React       from 'react';
import Highlighter from 'react-highlight-words';
import PropTypes   from '@/components/PropTypes';
import Text        from '@/helper/Text';
import styles      from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <Highlighter
                highlightClassName={styles.highlightedText}
                searchWords={[this.props.highlightText]}
                autoEscape={true}
                textToHighlight={this.props.text}
                findChunks={this.findChunks}
            />
        );
    }

    findChunks(options) {
        const textToHighlight     = options.textToHighlight;
        const searchWords         = options.searchWords.join(' ');
        const chunks              = [];
        const umlautCount         = Text.countUmlauts(textToHighlight);
        const textLow             = Text.replaceUmlauts(textToHighlight.toLowerCase());
        const searchWordsSplitted = searchWords.split(/[^\wäöüÄÖÜß]+/).filter(String);

        searchWordsSplitted.forEach((searchWord) => {
            const lowerSearchWord = Text.replaceUmlauts(searchWord.toLowerCase());
            const pattern         = new RegExp(lowerSearchWord, 'g');
            const matches         = [
                ...textLow.matchAll(pattern),
            ];

            matches.forEach((match) => {
                const startIndexShift = umlautCount && match.index !== 0 ? umlautCount : 0;

                chunks.push({
                    start: match.index - startIndexShift,
                    end:   match.index + lowerSearchWord.length - umlautCount,
                });
            });
        });

        return chunks;
    }
}

export const HighlightedText = Component;

Component.propTypes = {
    highlightText: PropTypes.string,
    text:          PropTypes.string,
};

Component.defaultProps = {
    highlightText: '',
    text:          '',
};

export default Component;
