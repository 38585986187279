//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React               from 'react';
import _                   from 'lodash';
import I18n                from 'i18next';
import PasswordStrengthBar from 'react-password-strength-bar';
import ComponentHelper     from '@/helper/ComponentHelper';
import PropTypes           from '@/components/PropTypes';
import TextInput           from '@/components/stateless/atomic/TextInput';
import PasswordStrength    from '@/constants/PasswordStrength';

import styles from './styles.module.scss';

export class Component extends React.Component {
    onChangeScore = (score) => {
        this.props.updatePasswordScore(score);
    };

    renderPasswordStrengthBar = (password) => {
        let bar = null;

        if (password.length) {
            bar = (
                <PasswordStrengthBar
                    password={password}
                    minLength={PasswordStrength.minimalPasswordLength}
                    onChangeScore={this.onChangeScore}
                />
            );
        }

        return (
            <div className={styles.strengthBar}>
                {bar}
            </div>
        );
    };

    renderPasswordNotMatched = (repeatedPasswordIsValid, password) => {
        if (repeatedPasswordIsValid) {
            return this.renderPasswordStrengthBar(password);
        }

        let bar = null;

        if (password.length) {
            bar = (
                <PasswordStrengthBar
                    password={''}
                    barColors={['#ef4836']}
                    minLength={0}
                    onChangeScore={this.onChangeScore}
                    scoreWords={[I18n.t('passwordDoesNotMatch')]}
                />
            );
        }

        return (
            <div className={styles.strengthBar}>
                {bar}
            </div>
        );
    };

    render() {
        const props                          = this.props;
        const { password, repeatedPassword } = props;
        const repeatedPasswordIsValid        = _.eq(password, repeatedPassword);

        return (
            <>
                <TextInput
                    placeholder={I18n.t('password')}
                    type={'password'}
                    onChange={props.updatePassword}
                    value={password}
                    autoFocus={true}
                />
                {this.renderPasswordStrengthBar(password)}
                <TextInput
                    placeholder={I18n.t('repeatedPassword')}
                    type={'password'}
                    onChange={props.updateRepeatedPassword}
                    value={repeatedPassword}
                    autoFocus={true}
                />
                {this.renderPasswordNotMatched(repeatedPasswordIsValid, repeatedPassword)}
            </>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const PasswordForm = Component;

Component.propTypes = {
    password:               PropTypes.string,
    repeatedPassword:       PropTypes.string,
    updatePassword:         PropTypes.func,
    updateRepeatedPassword: PropTypes.func,
    updatePasswordScore:    PropTypes.func,
};

Component.defaultProps = {
    password:               '',
    repeatedPassword:       '',
    updatePassword:         _.noop,
    updateRepeatedPassword: _.noop,
    updatePasswordScore:    _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
