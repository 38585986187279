//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const CookieBannerTypes = {
    COOKIES_ACCEPTED: 'CookieBanner/COOKIES_ACCEPTED',
    COOKIES_REJECTED: 'CookieBanner/COOKIES_REJECTED',
    COOKIES_RESET:    'CookieBanner/COOKIES_RESET',
};

const acceptCookies = makeActionCreator(
    CookieBannerTypes.COOKIES_ACCEPTED,
    {},
);

const rejectedCookies = makeActionCreator(
    CookieBannerTypes.COOKIES_REJECTED,
    {},
);

const resetCookies = makeActionCreator(
    CookieBannerTypes.COOKIES_RESET,
    {},
);

export const CookieBannerActions = {
    acceptCookies,
    rejectedCookies,
    resetCookies,
};
