//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const MembershipRequestTypes = {
    REQUEST_MEMBERSHIP: 'MembershipRequest/REQUEST_MEMBERSHIP',
};

const requestMembership = makeActionCreator(
    MembershipRequestTypes.REQUEST_MEMBERSHIP,
    {
        companyName:                     null,
        address:                         null,
        country:                         null,
        city:                            null,
        state:                           null,
        region:                          null,
        taxId:                           null,
        membership:                      null,
        preferredOnsiteVerificationDate: null,
        promotionCode:                   null,
    },
);

export const MembershipRequestActions = {
    requestMembership,
};
