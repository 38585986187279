//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';
import styled        from 'styled-components';
import ReactPaginate from 'react-paginate';
import colors        from '@/styles/colors.module.scss';

const StyledPaginate = styled(ReactPaginate).attrs({
    activeClassName: 'active',
})`
    margin-top:      2rem;
    margin-bottom:   2rem;
    display:         flex;
    flex-direction:  row;
    justify-content: center;
    gap:             30px;
    list-style-type: none;
    padding:         0 5rem;

    li a
    {
        border-radius: 7px;
        padding:       0.1rem 0.5rem;
        border:        gray 1px solid;
        cursor:        pointer;
    }

    li.previous a, li.next a, li.break a
    {
        border-color: transparent;
    }

    li.active a
    {
        background-color: ${colors.colorOrange};
        border-color:     transparent;
        color:            ${colors.colorWhite};
        min-width:        32px;
    }

    li.disabled a
    {
        color: grey;
    }

    li.disable, li.disabled a
    {
        cursor: default;
    }

    @media (max-width: 768px)
    {
        gap:     10px;
        padding: 10px 0;
    }
`;

const propTypes    = {};
const defaultProps = {};

const PaginateWrapper = (props) => {
    function getPaginationSettings() {
        if (window.innerWidth <= 768) {
            return {
                marginPagesDisplayed: 1,
                pageRangeDisplayed:   1,
            };
        }
        return {
            marginPagesDisplayed: 2,
            pageRangeDisplayed:   3,
        };
    }

    const [paginationSettings, setPaginationSettings] = useState(getPaginationSettings());

    useEffect(() => {
        const handleResize = () => {
            setPaginationSettings(getPaginationSettings());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            return window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <StyledPaginate
            {...props}
            marginPagesDisplayed={paginationSettings.marginPagesDisplayed}
            pageRangeDisplayed={paginationSettings.pageRangeDisplayed}
        />
    );
};

PaginateWrapper.propTypes    = propTypes;
PaginateWrapper.defaultProps = defaultProps;

export default PaginateWrapper;
