//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { ColorButton }  from '@/components/stateless/atomic/ColorButton';
import ColorButtonSize  from '@/components/stateless/atomic/ColorButton/ColorButtonSize';
import ColorButtonTheme from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import { Tooltip }      from '@/components/stateless/atomic/Tooltip';
import Routes           from '@/constants/Routes';
import classNames       from 'classnames';
import I18n             from 'i18next';
import _                from 'lodash';
import React            from 'react';
import PropTypes        from '@/components/PropTypes';
import ComponentHelper  from '@/helper/ComponentHelper';

import styles from './styles.module.scss';

export class Component extends React.Component {
    renderBenefit = (benefit) => {
        const { title, text } = benefit;

        return (
            <li>
                <Tooltip content={this.renderTooltip(title, text)}>
                    <div>
                        {title}
                    </div>
                </Tooltip>
            </li>
        );
    };

    renderTooltip = (title, text) => {
        return (
            <div className={styles.tooltip}>
                <strong>
                    {title}
                </strong>
                <p>
                    {text}
                </p>
            </div>
        );
    };

    renderBenefits = (option) => {
        const { benefits }    = option;
        const orderedBenefits = _.orderBy(benefits, ['priority'], ['desc']);

        return (
            <ul>
                {orderedBenefits.map(this.renderBenefit)}
            </ul>
        );
    };

    renderPrice = (price) => {
        if (price === 0) {
            return I18n.t('free');
        }

        return (
            <span>
                {price}
            </span>
        );
    };

    renderTitlePart = (titlePart) => {
        return (
            <span>
                {titlePart}
            </span>
        );
    };

    renderTitle = (title) => {
        const titleParts = title.split(' ');

        return titleParts.map(this.renderTitlePart);
    };

    onOptionClicked = () => {
        const { option, isLoggedIn } = this.props;
        let route                    = {
            pathname: Routes.membershipRequest,
            search:   `?option=${option.iri}`,
        };

        if (
            !option.priceInEuro &&
            !isLoggedIn
        ) {
            route = {
                pathname: Routes.signUp,
            };
        }

        this.props.history.push(route);
    };

    render() {
        const { option, buttonText, buttonDisabled } = this.props;

        return (
            <div
                className={classNames(
                    styles.membershipOption,
                    {
                        [styles.membershipOptionHighlighted]: option.highlighted,
                    },
                )}
            >
                <h2>
                    {this.renderTitle(option.title)}
                </h2>
                <p className={styles.membershipOptionPrice}>
                    {this.renderPrice(option.priceInEuro)}
                </p>
                <ColorButton
                    text={I18n.t(buttonText)}
                    size={ColorButtonSize.large}
                    theme={ColorButtonTheme.orange}
                    onClick={this.onOptionClicked}
                    disabled={buttonDisabled}
                />
                {this.renderBenefits(option)}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const MembershipOption = Component;

Component.propTypes = {
    buttonDisabled: PropTypes.bool,
    buttonText:     PropTypes.string,
    history:        PropTypes.object,
    isLoggedIn:     PropTypes.bool,
    option:         PropTypes.object,
};

Component.defaultProps = {
    buttonDisabled: false,
    buttonText:     'signup',
    history:        null,
    isLoggedIn:     false,
    option:         null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
