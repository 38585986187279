//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import classNames      from 'classnames';
import I18n            from 'i18next';
import * as Api        from '@/api';
import ImageUploadSize from '@/components/stateless/atomic/ImageUpload/ImageUploadSize';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        let images = _.compact(_.cloneDeep(props.images));

        if (!props.multiple) {
            images = images.slice(0, 1);
        }

        this.state = {
            images,
        };
    }

    createPreviewImage = (file) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            let images = _.cloneDeep(this.state.images);

            if (this.props.multiple) {
                images.push({
                    preview: reader.result,
                });
            } else {
                images = [
                    {
                        preview: reader.result,
                    },
                ];
            }

            this.setState({
                images,
            });
        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            console.error('Failed to create a image preview.');
        }
    };

    deleteImage = (index) => () => {
        const images = _.cloneDeep(this.state.images);

        images.splice(index, 1);

        this.props.deleteFileCallback(index);
        this.setState({
            images,
        });
    };

    onFileChanged = (event) => {
        const files = _.get(event, 'target.files', []);

        for (const file of files) {
            this.createPreviewImage(file);
            this.props.uploadFileCallback(file);
        }

        event.preventDefault();
    };

    render() {
        return (
            <div className={styles.imageUploadContainer}>
                {this.state.images.map(this.renderImage)}
                {this.renderImageUploadField()}
            </div>
        );
    }

    renderImage = (image, index) => {
        const style = {};

        if (image) {
            if (image.path || image.preview) {
                const imagePath       = image.path ? Api.getImagePath(image.path) : image.preview;
                style.backgroundImage = `url(${imagePath})`;
            } else {
                style.backgroundImage = `url(${image})`;
            }
        }

        return (
            <div
                className={classNames(
                    styles.imageUploadImage,
                    {
                        [styles.imageUploadFieldLarge]: this.props.size === ImageUploadSize.large,
                    },
                )}
                style={style}
                key={`image-upload-${index}`}
            >
                <button
                    className={styles.imageUploadDeleteButton}
                    onClick={this.deleteImage(index)}
                    disabled={this.props.disabled}
                >
                    {I18n.t('deleteImage')}
                </button>
            </div>
        );
    };

    renderImageUploadField = () => {
        if (this.props.multiple || !this.state.images.length) {
            return (
                <div
                    className={classNames(
                        styles.imageUploadField,
                        {
                            [styles.imageUploadFieldLarge]: this.props.size === ImageUploadSize.large,
                        },
                    )}
                >
                    <label>
                        <input
                            type={'file'}
                            accept={'image/*'}
                            multiple={this.props.multiple}
                            onChange={this.onFileChanged}
                            disabled={this.props.disabled}
                        />
                        <span>
                            {I18n.t('addPhoto')}
                        </span>
                    </label>
                </div>
            );
        }

        return null;
    };
}

export const ImageUpload = Component;

Component.propTypes = {
    deleteFileCallback: PropTypes.func,
    disabled:           PropTypes.bool,
    images:             PropTypes.array,
    multiple:           PropTypes.bool,
    size:               PropTypes.oneOfObjectValues(ImageUploadSize),
    uploadFileCallback: PropTypes.func,
};

Component.defaultProps = {
    deleteFileCallback: _.noop,
    disabled:           false,
    images:             [],
    multiple:           false,
    size:               ImageUploadSize.default,
    uploadFileCallback: _.noop,
};

export default Component;
