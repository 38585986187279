//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class CCPTranslations {
    static countryObject(country) {
        switch (country.code) {
            case 'TW':
                return {
                    code: 'TW',
                    name: 'Taiwan (China)',
                };
            case 'HK':
                return {
                    code: 'HK',
                    name: 'Hong Kong (China)',
                };
            case 'MO':
                return {
                    code: 'MO',
                    name: 'Macao (China)',
                };
            default:
                return country;
        }
    }

    static countryString(country) {
        switch (country) {
            case 'Taiwan':
                return 'Taiwan (China)';
            case 'Hong Kong':
                return 'Hong Kong (China)';
            case 'Macao':
                return 'Macao (China)';
            default:
                return country;
        }
    }
}

export default CCPTranslations;
