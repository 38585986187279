//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React              from 'react';
import I18n               from 'i18next';
import CallToActionBox    from '@/components/stateless/composed/CallToActionBox';
import ColorButton        from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme   from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import Overlays           from '@/constants/Overlays';
import { OverlayManager } from '@/components/connected/OverlayManager';
import { TestIds }        from '@/constants/TestIds';

export class Component extends React.Component {
    render() {
        return (
            <CallToActionBox
                title={I18n.t('addMachineTitle')}
                text={I18n.t('addMachineText')}
            >
                <ColorButton
                    test-id={TestIds.addNewProductButton}
                    to={OverlayManager.getPathForOverlayKey(Overlays.addMachine)}
                    text={I18n.t('addMachine')}
                    theme={ColorButtonTheme.orange}
                />
            </CallToActionBox>
        );
    }
}

export const AddNewMachineCTA = Component;

Component.propTypes = {};

Component.defaultProps = {};

export default Component;
