//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                 from 'react';
import { connect }           from 'react-redux';
import _                     from 'lodash';
import ComponentHelper       from '@/helper/ComponentHelper';
import PropTypes             from '@/components/PropTypes';
import StatelessHeaderButton from '@/components/stateless/atomic/HeaderButton';

export class Component extends React.Component {
    render() {
        const { isLoggedIn, showOnlyWhenLoggedIn } = this.props;

        if (
            showOnlyWhenLoggedIn &&
            !isLoggedIn
        ) {
            return null;
        }

        const { active, to, label, pathname, badgeCount } = this.props;
        const showActive                                  = active || pathname === to;

        return (
            <StatelessHeaderButton
                active={showActive}
                to={to}
                badgeCount={badgeCount}
                label={label}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const HeaderButton = Component;

Component.propTypes = {
    active:               PropTypes.bool,
    badgeCount:           PropTypes.number,
    isLoggedIn:           PropTypes.bool,
    label:                PropTypes.string,
    pathname:             PropTypes.string,
    showOnlyWhenLoggedIn: PropTypes.bool,
    to:                   PropTypes.to.isRequired,
};

Component.defaultProps = {
    active:               false,
    badgeCount:           null,
    isLoggedIn:           false,
    label:                null,
    pathname:             null,
    showOnlyWhenLoggedIn: false,
    to:                   null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => {
    return {
        isLoggedIn: !_.isEmpty(_.get(state, 'user.token', null)),
        pathname:   state.router.location.pathname,
    };
};

export default connect(mapStateToProps)(Component);
