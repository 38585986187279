//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import _         from 'lodash';
import PropTypes from '@/components/PropTypes';
import Url       from '@/helper/Url';

export class Component extends React.Component {
    getAltText = () => {
        const { image, alternativeText } = this.props;
        const imageAltText               = _.get(image, 'alt', null);

        return alternativeText || imageAltText || '';
    };

    getImagePath        = () => {
        const { image } = this.props;
        let path        = null;

        if (_.isString(image)) {
            path = image;
        }

        if (_.isObject(image)) {
            path = _.get(image, 'path', null);
        }

        if (!path) {
            return null;
        }

        return this.getResizedImagePath(path);
    };

    getResizedImagePath = (path) => {
        const { width, height } = this.props;

        if (
            !width &&
            !height
        ) {
            return path;
        }

        return Url.resizedImage(path, width, height);
    };

    render() {
        const { className, onLoad, onError } = this.props;
        const fullPath                       = this.getImagePath();
        const altText                        = this.getAltText();

        return (
            <img
                className={className}
                alt={altText}
                onLoad={onLoad}
                onError={onError}
                src={fullPath}
            />
        );
    }
}

export const FullImage = Component;

Component.propTypes = {
    className:       PropTypes.string,
    image:           PropTypes.object,
    width:           PropTypes.number,
    height:          PropTypes.number,
    alternativeText: PropTypes.string,
    onLoad:          PropTypes.func,
    onError:         PropTypes.func,
};

Component.defaultProps = {
    className:       null,
    image:           null,
    width:           null,
    height:          null,
    alternativeText: '',
    onLoad:          _.noop,
    onError:         _.noop,
};

export default Component;
