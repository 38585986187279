//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import _         from 'lodash';
import I18n      from 'i18next';
import PropTypes from '@/components/PropTypes';
import TextInput from '@/components/stateless/atomic/TextInput';
import IconType  from '@/components/stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { field, onChange, value, fieldPadding } = this.props;
        const style                                    = {};

        if (fieldPadding) {
            style.marginLeft = fieldPadding;
        }

        if (field) {
            return (
                <th className={styles.tableHeaderFilterColumn}>
                    <div
                        className={styles.tableHeaderFilterColumnInner}
                        style={style}
                    >
                        <TextInput
                            placeholder={`${I18n.t('search')} ${I18n.t(field)}`}
                            icon={IconType.search}
                            onChange={onChange}
                            value={value}
                        />
                    </div>
                </th>
            );
        }

        return (
            <th />
        );
    }
}

export const TableHeaderFilterColumn = Component;

Component.propTypes = {
    field:        PropTypes.string,
    fieldPadding: PropTypes.number,
    onChange:     PropTypes.func,
    value:        PropTypes.string,
};

Component.defaultProps = {
    field:        null,
    fieldPadding: null,
    onChange:     _.noop,
    value:        null,
};

export default Component;
