//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                       from 'react';
import I18n                        from 'i18next';
import _                           from 'lodash';
import ComponentHelper             from '@/helper/ComponentHelper';
import PropTypes                   from '@/components/PropTypes';
import HeadlineSmall               from '@/components/stateless/atomic/HeadlineSmall';
import TextInput                   from '@/components/stateless/atomic/TextInput';
import TextInputSize               from '@/components/stateless/atomic/TextInput/TextInputSize';
import { IconButton }              from '@/components/stateless/atomic/IconButton';
import IconType                    from '@/components/stateless/atomic/Icon/IconType';
import { FormHeadline }            from '@/components/stateless/atomic/FormHeadline';
import ColorBoxColor               from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import ColorBox                    from '@/components/stateless/atomic/ColorBox';
import FactSheetFactTagList        from '@/constants/FactSheetFactTagList';
import { EmployeeDropDown }        from '@/components/stateless/composed/EmployeeDropDown';
import TagDropDown                 from '@/components/connected/TagDropDown';
import TagTypeDropDown             from '@/constants/TagTypeDropDown';
import DateInput                   from '@/components/stateless/atomic/DateInput';
import { TagCertificateFileInput } from '@/components/stateless/atomic/TagCertificateFileInput';
import FileDownloader              from '@/helper/FileDownloader';
import { AlertBox }                from '@/components/stateless/atomic/AlertBox';
import ToastType                   from '@/components/stateless/atomic/ToastWrapper/ToastType';
import styles                      from './styles.module.scss';

export class Component extends React.Component {
    getTagFieldToTagTypeMapping = () => {
        return {
            [FactSheetFactTagList.processes]:      TagTypeDropDown.processRoot,
            [FactSheetFactTagList.commodities]:    TagTypeDropDown.commodities,
            [FactSheetFactTagList.productionType]: TagTypeDropDown.productionType,
            [FactSheetFactTagList.language]:       TagTypeDropDown.language,
            [FactSheetFactTagList.certificates]:   TagTypeDropDown.certificates,
        };
    };

    factChanged = (fieldName) => (option) => {
        this.props.updateFact(fieldName, option.label);
    };

    factMainCustomerChanged = (fieldName) => (event) => {
        const { value } = event.target;
        this.props.updateFact(fieldName, value);
    };

    entryChanged = (index, fieldName) => (tag) => {
        this.props.updateEntry(index, fieldName, tag, true);
    };

    certificateFileChanged = (certificate, certificateFile) => (file) => {
        const data = {
            ...certificateFile,
            file,
        };

        if (!file) {
            data.title = null;
        }

        this.props.updateOrCreateCertificateFile(certificate, data);
    };

    certificateExpirationDateChanged = (certificate, certificateFile) => (date) => {
        const data = {
            ...certificateFile,
            expirationDate: date,
        };

        this.props.updateOrCreateCertificateFile(certificate, data);
    };

    addEntry = (fieldName) => () => {
        this.props.addEntry(fieldName, true);
    };

    removeEntry = (index, fieldName) => () => {
        this.props.removeEntry(index, fieldName, true);
    };

    getTagsFilter = (tags) => {
        return (entry) => {
            return !_.find(
                tags,
                {
                    externalIdentifier: entry.externalIdentifier,
                },
            );
        };
    };

    handleCertificateFileDownload = (certificateFile) => () => {
        if (certificateFile === null) {
            return;
        }

        const title          = _.get(certificateFile, 'title');
        const { path, name } = _.get(certificateFile, 'file');

        FileDownloader.downloadFile(path, name, title);
    };

    renderTagDropDown = (fieldName, tagType, tags, facts) => {
        const commodities = _.get(facts, FactSheetFactTagList.commodities);
        const filter      = this.getTagsFilter(tags);

        return _.map(tags, (tag, index) => {
            let newTagType       = tagType;
            let multipleTagTypes = false;

            if (tagType === TagTypeDropDown.processRoot) {
                const hasMachiningCommodity = !!_.find(commodities, {
                    externalIdentifier: 'Mach1',
                });
                const hasCastingCommodity   = !!_.find(commodities, {
                    externalIdentifier: 'Cast1',
                });
                multipleTagTypes            = true;
                newTagType                  = [];

                if (hasMachiningCommodity) {
                    newTagType.push(TagTypeDropDown.processMachining);
                }

                if (hasCastingCommodity) {
                    newTagType.push(TagTypeDropDown.processCasting);
                }
            }

            return (
                <div
                    className={styles.editFactSheetFactsTagRow}
                    key={index}
                >
                    <TagDropDown
                        value={tag}
                        valueChanged={this.entryChanged(index, fieldName)}
                        tagType={newTagType}
                        multipleTagTypes={multipleTagTypes}
                        filter={filter}
                    />
                    <IconButton
                        iconType={IconType.deleteCircle}
                        onClick={this.removeEntry(index, fieldName)}
                    />
                </div>
            );
        });
    };

    renderEmptyTagListLabel = (fieldName) => {
        return (
            <div className={styles.editFactSheetFactsTagEmptyLabel}>
                {I18n.t(
                    'noTagsSelectedHint',
                    {
                        fieldName: I18n.t(fieldName).toLowerCase(),
                    },
                )}
            </div>
        );
    };

    renderTagDropDownWithExpirableCertificate = (fieldName, tagType, tags, facts) => {
        const certificateFiles = _.get(facts, 'certificateFiles');
        const filter           = this.getTagsFilter(tags);

        return _.map(tags, (tag, index) => {
            const certificateFile = _.find(certificateFiles, {
                tag,
            });
            const expirationDate  = certificateFile?.expirationDate ?
                new Date(certificateFile.expirationDate) :
                null;

            return (
                <div className={styles.editFactSheetFactsExpirableTagCertificate}>
                    <div
                        className={styles.editFactSheetFactsExpirableTagCertificateRow}
                        key={index}
                    >
                        <TagDropDown
                            value={tag}
                            valueChanged={this.entryChanged(index, fieldName)}
                            tagType={tagType}
                            multipleTagTypes={false}
                            filter={filter}
                        />
                        <DateInput
                            value={expirationDate}
                            onChange={this.certificateExpirationDateChanged(tag, certificateFile)}
                            disabled={!tag.iri}
                        />
                        <IconButton
                            iconType={IconType.deleteCircle}
                            onClick={this.removeEntry(index, fieldName)}
                        />
                    </div>
                    <TagCertificateFileInput
                        file={certificateFile?.file}
                        onFileClicked={this.handleCertificateFileDownload(certificateFile)}
                        disabled={!tag.iri}
                        onFileChanged={this.certificateFileChanged(tag, certificateFile)}
                        title={certificateFile?.title}
                    />
                </div>
            );
        });
    };

    renderList = (fieldName) => {
        const { facts } = this.props;
        const tags      = _.get(facts, fieldName);

        if (_.isEmpty(tags)) {
            return this.renderEmptyTagListLabel(fieldName);
        }

        const tagTypeMapping                     = this.getTagFieldToTagTypeMapping();
        const tagTypeMappingElement              = tagTypeMapping[fieldName];
        const renderExpirableWithCertificateFile = (
            fieldName === FactSheetFactTagList.certificates &&
            this.props.isMember
        );

        if (tagTypeMappingElement) {
            if (renderExpirableWithCertificateFile) {
                return this.renderTagDropDownWithExpirableCertificate(fieldName, tagTypeMappingElement, tags, facts);
            }

            return this.renderTagDropDown(fieldName, tagTypeMappingElement, tags, facts);
        }

        return null;
    };

    renderCertificateFileMemberHint = (fieldName) => {
        const shouldRenderCertificateFileMemberHint = (
            fieldName === FactSheetFactTagList.certificates &&
            !this.props.isMember
        );

        if (!shouldRenderCertificateFileMemberHint) {
            return null;
        }

        return (
            <div className={styles.certificateFileMemberHint}>
                <AlertBox
                    type={ToastType.warning}
                    text={I18n.t('certificateFileMemberHint')}
                />
            </div>
        );
    };

    renderFieldForTagList = (fieldName) => {
        return (
            <div
                className={styles.editFactSheetFactsTagWrapper}
                key={fieldName}
            >
                <FormHeadline
                    text={I18n.t(fieldName)}
                    onClick={this.addEntry(fieldName)}
                />
                {this.renderCertificateFileMemberHint(fieldName)}
                <ColorBox color={ColorBoxColor.grayLight}>
                    {this.renderList(fieldName)}
                </ColorBox>
            </div>
        );
    };

    render() {
        const { facts }     = this.props;
        const employees     = _.get(facts, 'employees');
        const mainCustomers = _.get(facts, 'mainCustomers');

        return (
            <div className={styles.editFactSheetFactsWrapper}>
                <ColorBox color={ColorBoxColor.grayLight}>
                    <div className={styles.editFactSheetFactsRow}>
                        <div className={styles.editFactSheetFactsInputWrapper}>
                            <HeadlineSmall
                                text={I18n.t('numberOfEmployees')}
                            />
                            <EmployeeDropDown
                                employeeValueChanged={this.factChanged('employees')}
                                employeeValue={employees}
                            />
                        </div>
                    </div>
                    <div className={styles.editFactSheetFactsRow}>
                        <div className={styles.editFactSheetFactsInputWrapper}>
                            <HeadlineSmall
                                text={I18n.t('mainCustomers')}
                            />
                            <TextInput
                                onChange={this.factMainCustomerChanged('mainCustomers')}
                                value={mainCustomers}
                                size={TextInputSize.multilineLarge}
                            />
                        </div>
                    </div>
                </ColorBox>
                {_.map(FactSheetFactTagList, this.renderFieldForTagList)}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const EditFactSheetFacts = Component;

Component.propTypes = {
    addEntry:                      PropTypes.func,
    facts:                         PropTypes.object,
    removeEntry:                   PropTypes.func,
    updateEntry:                   PropTypes.func,
    updateFact:                    PropTypes.func,
    updateOrCreateCertificateFile: PropTypes.func,
    isMember:                      PropTypes.bool,
};

Component.defaultProps = {
    addEntry:                      _.noop,
    facts:                         null,
    removeEntry:                   _.noop,
    updateEntry:                   _.noop,
    updateFact:                    _.noop,
    updateOrCreateCertificateFile: _.noop,
    isMember:                      false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
