//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import crypto from 'crypto';

class Crypto {
    static hashContent(contentFields, props) {
        const data = JSON.stringify(contentFields.map((field) => props[field]));

        return crypto.createHash('sha256').update(data).digest('hex');
    }
}

export default Crypto;
