//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import jwtDecode from 'jwt-decode';
import DateTime  from '@/helper/DateTime';

export default class User {
    static isTokenValid = (token) => {
        if (token) {
            const currentTimeStamp     = DateTime.getCurrentTimeInSeconds();
            const tokenExpireTimeStamp = jwtDecode(token).exp;

            return currentTimeStamp <= tokenExpireTimeStamp;
        }

        return false;
    };
}
