//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

class ReducerHelper {
    static updateStateByInitialState(state, initialState) {
        if (_.eq(state, initialState)) {
            return state;
        }

        return {
            ...initialState,
            ...state,
        };
    }
}

export default ReducerHelper;
