//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                            from 'react';
import { connect }                      from 'react-redux';
import { Route }                        from 'react-router';
import { Switch }                       from 'react-router';
import AddNewMachineOverlay             from '@/components/connected/AddNewMachineOverlay';
import AddNewProductOverlay             from '@/components/connected/AddNewProductOverlay';
import CompanyMachine                   from '@/screens/CompanyMachine';
import CompanyFactSheet                 from '@/screens/CompanyFactSheet';
import CompanyFactSheetPrint            from '@/screens/CompanyFactSheetPrint';
import MessageDetail                    from '@/screens/MessageDetail';
import CompanyProduction                from '@/screens/CompanyProduction';
import CompanyProfile                   from '@/screens/CompanyProfile';
import ProjectDetail                    from '@/screens/ProjectDetail';
import Membership                       from '@/screens/Membership';
import MembershipRequest                from '@/screens/MembershipRequest';
import CompanyUserManagement            from '@/screens/CompanyUserManagement';
import ComponentHelper                  from '@/helper/ComponentHelper';
import Messages                         from '@/screens/Messages';
import Projects                         from '@/screens/Projects';
import ConfirmOverlay                   from '@/components/connected/ConfirmOverlay';
import CreateProjectOverlay             from '@/components/connected/CreateProjectOverlay';
import DeleteMachineOverlay             from '@/components/connected/DeleteMachineOverlay';
import RemoveMachineOverlay             from '@/components/connected/RemoveMachineOverlay';
import EditCompanyOverlay               from '@/components/connected/EditCompanyOverlay';
import EditFactSheetOverlay             from '@/components/connected/EditFactSheetOverlay';
import AddMachineOverlay                from '@/components/connected/AddMachineOverlay';
import AddNewMachineManufacturerOverlay from '@/components/connected/AddNewMachineManufacturerOverlay';
import Environment                      from '@/helper/Environment';
import Favorites                        from '@/screens/Favorites';
import HeaderWrapper                    from '@/components/connected/HeaderWrapper';
import Home                             from '@/screens/Home';
import Login                            from '@/screens/Login';
import LostPassword                     from '@/screens/LostPassword';
import MachineDetails                   from '@/screens/MachineDetails';
import MyCompany                        from '@/screens/MyCompany';
import MyProfile                        from '@/screens/MyProfile';
import MyProfileJoinCompany             from '@/screens/MyProfileJoinCompany';
import NotFound                         from '@/screens/NotFound';
import OverlayManager                   from '@/components/connected/OverlayManager';
import Overlays                         from '@/constants/Overlays';
import PropTypes                        from '@/components/PropTypes';
import Routes                           from '@/constants/Routes';
import ScreenDesign                     from '@/screens/ScreenDesign';
import SearchResults                    from '@/screens/SearchResults';
import Signup                           from '@/screens/Signup';
import MyProfileCurrentRole             from '@/screens/MyProfileCurrentRole';
import MyProfileVerification            from '@/screens/MyProfileVerification';

export class Component extends React.Component {
    shouldHideApp = () => {
        return (
            Environment.isStaging() &&
            !Environment.isDevelopment() &&
            !this.props.isAuthenticated
        );
    };

    render() {
        if (!this.shouldHideApp()) {
            return (
                <>
                    <HeaderWrapper />
                    <OverlayManager
                        overlays={{
                            [Overlays.addNewProduct]:             AddNewProductOverlay,
                            [Overlays.createProject]:             CreateProjectOverlay,
                            [Overlays.addMachine]:                AddMachineOverlay,
                            [Overlays.addNewMachine]:             AddNewMachineOverlay,
                            [Overlays.addNewMachineManufacturer]: AddNewMachineManufacturerOverlay,
                            [Overlays.deleteMachine]:             DeleteMachineOverlay,
                            [Overlays.removeMachine]:             RemoveMachineOverlay,
                            [Overlays.editCompany]:               EditCompanyOverlay,
                            [Overlays.editFactSheetOverlay]:      EditFactSheetOverlay,
                            [Overlays.confirmOverlay]:            ConfirmOverlay,
                        }}
                        history={this.props.history}
                    />
                    <Switch>
                        <Route
                            exact
                            path={Routes.home}
                            component={Home}
                        />
                        <Route
                            exact
                            path={Routes.login}
                            component={Login}
                        />
                        <Route
                            exact
                            path={Routes.lostPassword}
                            component={LostPassword}
                        />
                        <Route
                            exact
                            path={Routes.signUp}
                            component={Signup}
                        />
                        <Route
                            exact
                            path={Routes.companyMachine}
                            component={CompanyMachine}
                        />
                        <Route
                            exact
                            path={Routes.company}
                            component={CompanyProfile}
                        />
                        <Route
                            exact
                            path={Routes.companyFactSheet}
                            component={CompanyFactSheet}
                        />
                        <Route
                            exact
                            path={Routes.companyFactSheetPrint}
                            component={CompanyFactSheetPrint}
                        />
                        <Route
                            exact
                            path={Routes.companyMessage}
                            component={Messages}
                        />
                        <Route
                            exact
                            path={Routes.companyProjectDetail}
                            component={ProjectDetail}
                        />
                        <Route
                            exact
                            path={Routes.myProfileProjects}
                            component={Projects}
                        />
                        <Route
                            exact
                            path={Routes.companyProjects}
                            component={Projects}
                        />
                        <Route
                            exact
                            path={Routes.myProfileMessages}
                            component={Messages}
                        />
                        <Route
                            exact
                            path={Routes.myProfileMessagesDetail}
                            component={MessageDetail}
                        />
                        <Route
                            exact
                            path={Routes.myProfileProjectsProjectDetail}
                            component={ProjectDetail}
                        />
                        <Route
                            exact
                            path={Routes.companyMessageDetail}
                            component={MessageDetail}
                        />
                        <Route
                            exact
                            path={Routes.companyProduction}
                            component={CompanyProduction}
                        />
                        <Route
                            exact
                            path={Routes.machine}
                            component={MachineDetails}
                        />
                        <Route
                            exact
                            path={Routes.searchResults}
                            component={SearchResults}
                        />
                        <Route
                            exact
                            path={Routes.membership}
                            component={Membership}
                        />
                        <Route
                            exact
                            path={Routes.membershipRequest}
                            component={MembershipRequest}
                        />
                        <Route
                            path={Routes.favorites}
                            component={Favorites}
                        />
                        <Route
                            path={Routes.myProfileJoinCompany}
                            component={MyProfileJoinCompany}
                        />
                        <Route
                            path={Routes.myProfileCurrentRole}
                            component={MyProfileCurrentRole}
                        />
                        <Route
                            path={Routes.myProfileVerification}
                            component={MyProfileVerification}
                        />
                        <Route
                            path={Routes.myProfile}
                            component={MyProfile}
                        />
                        <Route
                            path={Routes.myCompany}
                            component={MyCompany}
                        />
                        <Route
                            path={Routes.userManagement}
                            component={CompanyUserManagement}
                        />
                        <Route
                            path={Routes.screenDesign}
                            component={ScreenDesign}
                        />
                        <Route component={NotFound} />
                    </Switch>
                </>
            );
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const MainRouter = Component;

Component.propTypes = {
    history:         PropTypes.object,
    isAuthenticated: PropTypes.bool,
};

Component.defaultProps = {
    history:         null,
    isAuthenticated: false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        isAuthenticated: state.stagingAuthentication.isAuthenticated,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);
