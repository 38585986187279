//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import PropTypes from '@/components/PropTypes';
import styles    from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.contentHeaderWrapper}>
                <div className={styles.contentHeaderLeft}>
                    {this.props.leftContent}
                </div>
                <div className={styles.contentHeaderRight}>
                    <div className={styles.contentHeaderRightContent}>
                        {this.props.rightContent}
                    </div>
                    <div className={styles.contentHeaderInfoText}>
                        {this.props.infoText}
                    </div>
                </div>
            </div>
        );
    }
}

export const ContentHeaderStateless = Component;

Component.propTypes = {
    infoText:     PropTypes.string,
    leftContent:  PropTypes.children,
    rightContent: PropTypes.children,
};

Component.defaultProps = {
    infoText:     '',
    leftContent:  null,
    rightContent: null,
};

export default Component;
