//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator }                from '@/helper/Store';
import { makeActionCreatorIncreaseLoading } from '@/helper/Store';
import { makeActionCreatorDecreaseLoading } from '@/helper/Store';

export const ProjectsTypes = {
    ACCEPT_PROJECT:                              'Projects/ACCEPT_PROJECT',
    ACCEPT_PROJECT_FAILED:                       'Projects/ACCEPT_PROJECT_FAILED',
    ACCEPT_PROJECT_SUCCESS:                      'Projects/ACCEPT_PROJECT_SUCCESS',
    ACCEPT_SUGGESTED_PROJECT:                    'Projects/ACCEPT_SUGGESTED_PROJECT',
    ACCEPT_SUGGESTED_PROJECT_FAILED:             'Projects/ACCEPT_SUGGESTED_PROJECT_FAILED',
    ACCEPT_SUGGESTED_PROJECT_SUCCESS:            'Projects/ACCEPT_SUGGESTED_PROJECT_SUCCESS',
    ADD_CHILDREN_TO_TAG_QUERY:                   'Projects/ADD_CHILDREN_TO_TAG_QUERY',
    ADD_INDUSTRY_TAG:                            'Projects/ADD_INDUSTRY_TAG',
    ADD_INSPECTION_TAG:                          'Projects/ADD_INSPECTION_TAG',
    ADD_MANDATORY_TAG:                           'Projects/ADD_MANDATORY_TAG',
    ADD_MATERIAL_TAG:                            'Projects/ADD_MATERIAL_TAG',
    ADD_PART_FAMILY_TAG:                         'Projects/ADD_PART_FAMILY_TAG',
    ADD_TECHNOLOGY_TAG:                          'Projects/ADD_TECHNOLOGY_TAG',
    CHANGE_INTERESTED_MESSAGE:                   'Projects/CHANGE_INTERESTED_MESSAGE',
    CLOSE_PROJECT:                               'Projects/CLOSE_PROJECT',
    CLOSE_PROJECT_FAILED:                        'Projects/CLOSE_PROJECT_FAILED',
    CLOSE_PROJECT_SUCCESS:                       'Projects/CLOSE_PROJECT_SUCCESS',
    CREATE_PROJECT:                              'Projects/CREATE_PROJECT',
    CREATE_PROJECT_FAILED:                       'Projects/CREATE_PROJECT_FAILED',
    CREATE_PROJECT_FORM_DATA_CHANGED:            'Projects/CREATE_PROJECT_FORM_DATA_CHANGED',
    CREATE_PROJECT_FORM_DATA_VALIDATION_CHANGED: 'Projects/CREATE_PROJECT_FORM_DATA_VALIDATION_CHANGED',
    CREATE_PROJECT_SUCCESS:                      'Projects/CREATE_PROJECT_SUCCESS',
    DECLINE_PROJECT:                             'Projects/DECLINE_PROJECT',
    DECLINE_PROJECT_FAILED:                      'Projects/DECLINE_PROJECT_FAILED',
    DECLINE_PROJECT_SUCCESS:                     'Projects/DECLINE_PROJECT_SUCCESS',
    DECLINE_SUGGESTED_PROJECT:                   'Projects/DECLINE_SUGGESTED_PROJECT',
    DECLINE_SUGGESTED_PROJECT_FAILED:            'Projects/DECLINE_SUGGESTED_PROJECT_FAILED',
    DECLINE_SUGGESTED_PROJECT_SUCCESS:           'Projects/DECLINE_SUGGESTED_PROJECT_SUCCESS',
    FETCH_PROJECT:                               'Projects/FETCH_PROJECT',
    FETCH_PROJECTS_OWN_PROJECTS:                 'Projects/FETCH_PROJECTS_OWN_PROJECTS',
    FETCH_PROJECTS_OWN_PROJECTS_FAILED:          'Projects/FETCH_PROJECTS_OWN_PROJECTS_FAILED',
    FETCH_PROJECTS_OWN_PROJECTS_SUCCESS:         'Projects/FETCH_PROJECTS_OWN_PROJECTS_SUCCESS',
    FETCH_PROJECTS_SUGGESTIONS:                  'Projects/FETCH_PROJECTS_SUGGESTIONS',
    FETCH_PROJECTS_SUGGESTIONS_FAILED:           'Projects/FETCH_PROJECTS_SUGGESTIONS_FAILED',
    FETCH_PROJECTS_SUGGESTIONS_SUCCESS:          'Projects/FETCH_PROJECTS_SUGGESTIONS_SUCCESS',
    FETCH_PROJECT_AND_DEPENDENCIES:              'Projects/FETCH_PROJECT_AND_DEPENDENCIES',
    FETCH_PROJECT_AND_DEPENDENCIES_SUCCESS:      'Projects/FETCH_PROJECT_AND_DEPENDENCIES_SUCCESS',
    FETCH_PROJECT_FAILED:                        'Projects/FETCH_PROJECT_FAILED',
    FETCH_PROJECT_SUCCESS:                       'Projects/FETCH_PROJECT_SUCCESS',
    FETCH_PROJECT_MATCH_STATUS:                  'Projects/FETCH_PROJECT_MATCH_STATUS',
    FETCH_PROJECT_MATCH_STATUS_FAILED:           'Projects/FETCH_PROJECT_MATCH_STATUS_FAILED',
    FETCH_PROJECT_MATCH_STATUS_SUCCESS:          'Projects/FETCH_PROJECT_MATCH_STATUS_SUCCESS',
    FILES_CHANGED:                               'Projects/FILES_CHANGED',
    FILE_DELETED:                                'Projects/FILE_DELETED',
    OPEN_PROJECT:                                'Projects/OPEN_PROJECT',
    OPEN_CREATE_PROJECT_OVERLAY:                 'Projects/OPEN_CREATE_PROJECT_OVERLAY',
    REJECT_PROJECT_MATCH:                        'Projects/REJECT_PROJECT_MATCH',
    REJECT_PROJECT_MATCH_FAILED:                 'Projects/REJECT_PROJECT_MATCH_FAILED',
    REJECT_PROJECT_MATCH_SUCCESS:                'Projects/REJECT_PROJECT_MATCH_SUCCESS',
    REMOVE_INDUSTRY_TAG:                         'Projects/REMOVE_INDUSTRY_TAG',
    REMOVE_INSPECTION_TAG:                       'Projects/REMOVE_INSPECTION_TAG',
    REMOVE_MANDATORY_TAG:                        'Projects/REMOVE_MANDATORY_TAG',
    REMOVE_MATERIAL_TAG:                         'Projects/REMOVE_MATERIAL_TAG',
    REMOVE_PART_FAMILY_TAG:                      'Projects/REMOVE_PART_FAMILY_TAG',
    REMOVE_TECHNOLOGY_TAG:                       'Projects/REMOVE_TECHNOLOGY_TAG',
    RESET_OVERLAY:                               'Projects/RESET_OVERLAY',
    RESET_SELECTED_PROJECT:                      'Projects/RESET_SELECTED_PROJECT',
    SEND_MESSAGE_REQUEST:                        'Projects/SEND_MESSAGE_REQUEST',
};

const sendMessageRequest = makeActionCreator(
    ProjectsTypes.SEND_MESSAGE_REQUEST,
    {
        project: null,
        message: null,
    },
);

const changeInterestedMessage = makeActionCreator(
    ProjectsTypes.CHANGE_INTERESTED_MESSAGE,
    {
        message: null,
    },
);

const fileDeleted = makeActionCreator(
    ProjectsTypes.FILE_DELETED,
    {
        file: null,
    },
);

const filesChanged = makeActionCreator(
    ProjectsTypes.FILES_CHANGED,
    {
        files: null,
    },
);

const addChildrenToTagQuery = makeActionCreator(
    ProjectsTypes.ADD_CHILDREN_TO_TAG_QUERY,
    {
        tagType:  null,
        tag:      null,
        children: null,
    },
);

const resetOverlay = makeActionCreator(
    ProjectsTypes.RESET_OVERLAY,
    {},
);

const addIndustryTag = makeActionCreator(
    ProjectsTypes.ADD_INDUSTRY_TAG,
    {
        tag: null,
    },
);

const addTechnologyTag = makeActionCreator(
    ProjectsTypes.ADD_TECHNOLOGY_TAG,
    {
        tag: null,
    },
);

const addMaterialTag = makeActionCreator(
    ProjectsTypes.ADD_MATERIAL_TAG,
    {
        tag: null,
    },
);

const addPartFamilyTag = makeActionCreator(
    ProjectsTypes.ADD_PART_FAMILY_TAG,
    {
        tag: null,
    },
);

const addMandatoryTag = makeActionCreator(
    ProjectsTypes.ADD_MANDATORY_TAG,
    {
        tag: null,
    },
);

const addInspectionTag = makeActionCreator(
    ProjectsTypes.ADD_INSPECTION_TAG,
    {
        tag: null,
    },
);

const removeIndustryTag = makeActionCreator(
    ProjectsTypes.REMOVE_INDUSTRY_TAG,
    {
        tag: null,
    },
);

const removeTechnologyTag = makeActionCreator(
    ProjectsTypes.REMOVE_TECHNOLOGY_TAG,
    {
        tag: null,
    },
);

const removeMaterialTag = makeActionCreator(
    ProjectsTypes.REMOVE_MATERIAL_TAG,
    {
        tag: null,
    },
);

const removePartFamilyTag = makeActionCreator(
    ProjectsTypes.REMOVE_PART_FAMILY_TAG,
    {
        tag: null,
    },
);

const removeMandatoryTag = makeActionCreator(
    ProjectsTypes.REMOVE_MANDATORY_TAG,
    {
        tag: null,
    },
);

const removeInspectionTag = makeActionCreator(
    ProjectsTypes.REMOVE_INSPECTION_TAG,
    {
        tag: null,
    },
);

const acceptSuggestedProject = makeActionCreatorIncreaseLoading(
    ProjectsTypes.ACCEPT_SUGGESTED_PROJECT,
    {
        project: null,
    },
);

const acceptSuggestedProjectSuccess = makeActionCreatorDecreaseLoading(
    ProjectsTypes.ACCEPT_SUGGESTED_PROJECT_SUCCESS,
    {
        project: null,
    },
);

const acceptSuggestedProjectFailed = makeActionCreatorDecreaseLoading(
    ProjectsTypes.ACCEPT_SUGGESTED_PROJECT_FAILED,
    {},
);

const resetSelectedProject = makeActionCreator(
    ProjectsTypes.RESET_SELECTED_PROJECT,
    {},
);

const openProject   = makeActionCreator(
    ProjectsTypes.OPEN_PROJECT,
    {
        project: null,
    },
);
const acceptProject = makeActionCreatorIncreaseLoading(
    ProjectsTypes.ACCEPT_PROJECT,
    {
        project: null,
    },
);

const acceptProjectSuccess = makeActionCreatorDecreaseLoading(
    ProjectsTypes.ACCEPT_PROJECT_SUCCESS,
    {},
);

const acceptProjectFailed = makeActionCreatorDecreaseLoading(
    ProjectsTypes.ACCEPT_PROJECT_FAILED,
    {},
);

const closeProject = makeActionCreatorIncreaseLoading(
    ProjectsTypes.CLOSE_PROJECT,
    {
        projectIri: null,
    },
);

const closeProjectSuccess = makeActionCreatorDecreaseLoading(
    ProjectsTypes.CLOSE_PROJECT_SUCCESS,
    {},
);

const closeProjectFailed = makeActionCreatorDecreaseLoading(
    ProjectsTypes.CLOSE_PROJECT_FAILED,
    {},
);

const declineSuggestedProject = makeActionCreatorIncreaseLoading(
    ProjectsTypes.DECLINE_SUGGESTED_PROJECT,
    {
        projectId: null,
    },
);

const declineSuggestedProjectSuccess = makeActionCreatorDecreaseLoading(
    ProjectsTypes.DECLINE_SUGGESTED_PROJECT_SUCCESS,
    {
        projects: null,
    },
);

const declineSuggestedProjectFailed = makeActionCreatorDecreaseLoading(
    ProjectsTypes.DECLINE_SUGGESTED_PROJECT_FAILED,
    {},
);

const declineProject = makeActionCreatorIncreaseLoading(
    ProjectsTypes.DECLINE_PROJECT,
    {
        projectId: null,
    },
);

const declineProjectSuccess = makeActionCreatorDecreaseLoading(
    ProjectsTypes.DECLINE_PROJECT_SUCCESS,
    {},
);

const declineProjectFailed = makeActionCreatorDecreaseLoading(
    ProjectsTypes.DECLINE_PROJECT_FAILED,
    {},
);

const rejectProjectMatch = makeActionCreatorIncreaseLoading(
    ProjectsTypes.REJECT_PROJECT_MATCH,
    {
        matchId: null,
    },
);

const rejectProjectMatchSuccess = makeActionCreatorDecreaseLoading(
    ProjectsTypes.REJECT_PROJECT_MATCH_SUCCESS,
    {},
);

const rejectProjectMatchFailed = makeActionCreatorDecreaseLoading(
    ProjectsTypes.REJECT_PROJECT_MATCH_FAILED,
    {},
);

const fetchProjectsSuggestions = makeActionCreatorIncreaseLoading(
    ProjectsTypes.FETCH_PROJECTS_SUGGESTIONS,
    {},
);

const fetchProjectsSuggestionsSuccess = makeActionCreatorDecreaseLoading(
    ProjectsTypes.FETCH_PROJECTS_SUGGESTIONS_SUCCESS,
    {
        projects: null,
    },
);

const fetchProjectsSuggestionsFailed = makeActionCreatorDecreaseLoading(
    ProjectsTypes.FETCH_PROJECTS_SUGGESTIONS_FAILED,
    {},
);

const createProject = makeActionCreatorIncreaseLoading(
    ProjectsTypes.CREATE_PROJECT,
    {},
);

const createProjectSuccess = makeActionCreatorDecreaseLoading(
    ProjectsTypes.CREATE_PROJECT_SUCCESS,
    {
        project: null,
    },
);

const createProjectFailed = makeActionCreatorDecreaseLoading(
    ProjectsTypes.CREATE_PROJECT_FAILED,
    {
        error: null,
    },
);

const fetchProjectsOwnProjects = makeActionCreatorIncreaseLoading(
    ProjectsTypes.FETCH_PROJECTS_OWN_PROJECTS,
    {},
);

const fetchProjectsOwnProjectsSuccess = makeActionCreatorDecreaseLoading(
    ProjectsTypes.FETCH_PROJECTS_OWN_PROJECTS_SUCCESS,
    {
        projects: null,
    },
);

const fetchProjectsOwnProjectsFailed = makeActionCreatorDecreaseLoading(
    ProjectsTypes.FETCH_PROJECTS_OWN_PROJECTS_FAILED,
    {},
);

const fetchProjectAndDependencies = makeActionCreatorIncreaseLoading(
    ProjectsTypes.FETCH_PROJECT_AND_DEPENDENCIES,
    {
        project: null,
        company: null,
    },
);

const fetchProjectAndDependenciesSuccess = makeActionCreatorDecreaseLoading(
    ProjectsTypes.FETCH_PROJECT_AND_DEPENDENCIES_SUCCESS,
    {},
);

const fetchProject = makeActionCreatorIncreaseLoading(
    ProjectsTypes.FETCH_PROJECT,
    {
        projectId: null,
    },
);

const fetchProjectSuccess = makeActionCreatorDecreaseLoading(
    ProjectsTypes.FETCH_PROJECT_SUCCESS,
    {
        project: null,
    },
);

const fetchProjectFailed = makeActionCreatorDecreaseLoading(
    ProjectsTypes.FETCH_PROJECT_FAILED,
    {},
);

const openCreateProjectOverlay = makeActionCreator(
    ProjectsTypes.OPEN_CREATE_PROJECT_OVERLAY,
    {},
);

const createProjectOverlayFormDataChanged = makeActionCreator(
    ProjectsTypes.CREATE_PROJECT_FORM_DATA_CHANGED,
    {
        field: '',
        value: null,
    },
);

const createProjectOverlayValidationChanged = makeActionCreator(
    ProjectsTypes.CREATE_PROJECT_FORM_DATA_VALIDATION_CHANGED,
    {
        validation: null,
    },
);

export const ProjectsActions = {
    acceptProject,
    acceptProjectFailed,
    acceptProjectSuccess,
    acceptSuggestedProject,
    acceptSuggestedProjectFailed,
    acceptSuggestedProjectSuccess,
    addChildrenToTagQuery,
    addIndustryTag,
    addInspectionTag,
    addMandatoryTag,
    addMaterialTag,
    addPartFamilyTag,
    addTechnologyTag,
    changeInterestedMessage,
    closeProject,
    closeProjectFailed,
    closeProjectSuccess,
    createProject,
    createProjectFailed,
    createProjectOverlayFormDataChanged,
    createProjectOverlayValidationChanged,
    createProjectSuccess,
    declineProject,
    declineProjectFailed,
    declineProjectSuccess,
    declineSuggestedProject,
    declineSuggestedProjectFailed,
    declineSuggestedProjectSuccess,
    fetchProject,
    fetchProjectAndDependencies,
    fetchProjectAndDependenciesSuccess,
    fetchProjectFailed,
    fetchProjectSuccess,
    fetchProjectsOwnProjects,
    fetchProjectsOwnProjectsFailed,
    fetchProjectsOwnProjectsSuccess,
    fetchProjectsSuggestions,
    fetchProjectsSuggestionsFailed,
    fetchProjectsSuggestionsSuccess,
    fileDeleted,
    filesChanged,
    openProject,
    openCreateProjectOverlay,
    rejectProjectMatch,
    rejectProjectMatchFailed,
    rejectProjectMatchSuccess,
    removeIndustryTag,
    removeInspectionTag,
    removeMandatoryTag,
    removeMaterialTag,
    removePartFamilyTag,
    removeTechnologyTag,
    resetOverlay,
    resetSelectedProject,
    sendMessageRequest,
};
