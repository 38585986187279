//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React               from 'react';
import { PercentageLabel } from '@/components/stateless/composed/PercentageLabel';
import PropTypes           from '@/components/PropTypes';

import styles from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { name, backgroundColor, value } = this.props;
        const background                       = {
            backgroundColor,
        };

        return (
            <div className={styles.pieChartLegendRow}>
                <div className={styles.pieChartLegendRowName}>
                    {name}
                </div>
                <div className={styles.pieChartLegendRowValueWrapper}>
                    <span
                        style={background}
                        className={styles.pieChartLegendRowValuePoint}
                    />
                    <div className={styles.pieChartLegendRowValue}>
                        <PercentageLabel percent={value} />
                    </div>
                </div>
            </div>
        );
    }
}

export const LegendRow = Component;

Component.propTypes = {
    backgroundColor: PropTypes.string,
    hasOther:        PropTypes.bool,
    name:            PropTypes.string,
    value:           PropTypes.number,
};

Component.defaultProps = {
    backgroundColor: '#ff00ff',
    hasOther:        false,
    name:            '',
    value:           0,
};

export default Component;
