//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import classNames from 'classnames';
import PropTypes  from '@/components/PropTypes';
import Link       from '@/components/stateless/atomic/Link';
import styles     from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const content      = this.renderContent();
        const wrapperClass = classNames(
            styles.sideMenuButtonWrapper,
            {
                [styles.sideMenuButtonWrapperActive]: this.props.active,
            },
        );

        if (this.props.to || this.props.onClick) {
            return (
                <Link
                    className={wrapperClass}
                    to={this.props.to}
                    onClick={this.props.onClick}
                >
                    {content}
                </Link>
            );
        }

        console.warn('Please pass "to" or "onClick" to render SideMenuButton');

        return null;
    }

    renderContent = () => {
        const { title } = this.props;

        return (
            <>
                <strong className={styles.sideMenuButtonTitle}>
                    {title}
                </strong>
                {this.renderSubtitle()}
            </>
        );
    };

    renderSubtitle = () => {
        const { subTitle } = this.props;

        if (subTitle) {
            return (
                <p className={styles.sideMenuButtonSubTitle}>
                    {subTitle}
                </p>
            );
        }

        return null;
    };
}

export const SideMenuButton = Component;

Component.propTypes = {
    active:   PropTypes.bool,
    onClick:  PropTypes.func,
    subTitle: PropTypes.string,
    title:    PropTypes.string,
    to:       PropTypes.to,
};

Component.defaultProps = {
    active:   false,
    onClick:  null,
    subTitle: null,
    title:    null,
    to:       null,
};

export default Component;
