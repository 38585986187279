//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import Avatar    from '@/components/stateless/atomic/Avatar';
import PropTypes from '@/components/PropTypes';
import styles    from './styles.module.scss';

export class Component extends React.Component {
    getStyle = () => {
        const style = {
            borderRadius: this.props.size,
            height:       this.props.size,
            width:        this.props.size,
        };

        return style;
    };

    render() {
        const style = this.getStyle();

        return (
            <div
                className={styles.companyLogoWrapper}
                style={style}
            >
                <Avatar
                    fallback={this.props.alternativeText}
                    image={this.props.image}
                    withoutLink={this.props.withoutLink}
                />
            </div>
        );
    }
}

export const CompanyLogo = Component;

Component.propTypes = {
    alternativeText: PropTypes.string,
    image:           PropTypes.image,
    size:            PropTypes.number,
    withoutLink:     PropTypes.bool,
};

Component.defaultProps = {
    alternativeText: '',
    image:           null,
    size:            30,
    withoutLink:     false,
};

export default Component;
