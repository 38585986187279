//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import { Redirect }           from 'react-router';
import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import Route                  from '@/helper/Route';
import { CompanyActions }     from '@/store/actions/company';
import { MachineActions }     from '@/store/actions/machine';
import Routes                 from '@/constants/Routes';
import Navigation             from '@/helper/Navigation';
import { UserActions }        from '@/store/actions/user';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        props.fetchJoinRequests();
    }

    render() {
        const { ownCompanyUrl } = this.props;

        if (ownCompanyUrl) {
            return (
                <Redirect to={Navigation.getToFromRouteObject(ownCompanyUrl)} />
            );
        }

        return <Redirect to={Routes.myProfile} />;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    fetchJoinRequests: PropTypes.func,
    ownCompanyUrl:     PropTypes.string,
};

Screen.defaultProps = {
    fetchJoinRequests: _.noop,
    ownCompanyUrl:     null,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...UserActions,
        ...CompanyActions,
        ...MachineActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    const ownCompanyId = _.get(state, 'user.company');
    const ownCompany   = _.get(state, ['company', 'companies', ownCompanyId]);

    return {
        ownCompanyUrl: Route.buildRouteForCompany(ownCompany),
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
