//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _      from 'lodash';
import update from 'immutability-helper';

export default class Tag {
    /**
     * Adds a tag to an existing tag tree.
     * Traverses the tree, searches for existing industries and appends the provided tag to the last node in the hierarchy.
     * @param originalTagQuery
     * @param tag
     * @returns {*}
     */
    static addTagToTagSelectorTree = (originalTagQuery, tag) => {
        const hierarchy    = tag.hierarchy;
        let tagQuery       = _.cloneDeep(originalTagQuery || []);
        const dynamicChain = [];

        /**
         * Each tag has a flat hierarachy list of its parents. Here we traverse this list element by element and create a
         * dynamic chain which will be used by lodash to append the tag to the tree.
         *
         * The tree could look like this
         * Casting
         * -- Iron
         * ---- A
         * -- Plastic
         * ---- A
         * ---- B
         *
         * The dynamic chain could have this values [0, 'children', 1, 'children'].
         * In this case we would get the children of Casting > Plastic = [A,B] and append C
         *
         */
        for (let hierarchyIndex = 0; hierarchyIndex < hierarchy.length; hierarchyIndex++) {
            const currentNodeInHierarchy   = hierarchy[hierarchyIndex];
            const hierarchyPath            = hierarchy.slice(0, hierarchyIndex + 1).map((subTag) => subTag.title);
            const title                    = currentNodeInHierarchy.title;
            const id                       = currentNodeInHierarchy.id;
            const iri                      = currentNodeInHierarchy.iri;
            const externalIdentifier       = currentNodeInHierarchy.externalIdentifier;
            const siblings                 = currentNodeInHierarchy.siblings;
            const standaloneTagSearch      = currentNodeInHierarchy.standaloneTagSearch;
            const numberOfPossibleChildren = currentNodeInHierarchy.numberOfPossibleChildren;
            const tagsInThisLevel          = [
                currentNodeInHierarchy,
                ...siblings,
            ];
            let childIndex                 = -1;
            const newTag                   = {
                selectedTagIndex: 0,
                tag:              currentNodeInHierarchy,
                tags:             tagsInThisLevel,
                id,
                iri,
                standaloneTagSearch,
                externalIdentifier,
                hierarchyPath,
                children:         [],
                possibleChildren: [],
                numberOfPossibleChildren,
            };

            if (dynamicChain.length === 0) {
                let rootIndex = -1;

                for (let i = 0; i < tagQuery.length; i++) {
                    const element         = tagQuery[i];
                    const elementTagTitle = _.get(
                        element,
                        [
                            'tag',
                            'title',
                        ],
                        null,
                    );

                    if (elementTagTitle === title) {
                        rootIndex = i;

                        break;
                    }
                }

                if (rootIndex === -1) {
                    tagQuery = [newTag, ...tagQuery];

                    dynamicChain.push(0);
                } else {
                    dynamicChain.push(rootIndex);
                }
            } else {
                const children = _.get(
                    tagQuery,
                    dynamicChain,
                    [],
                );

                for (let i = 0; i < children.length; i++) {
                    const element          = children[i];
                    const selectedTagTitle = _.get(
                        element,
                        [
                            'tag',
                            'title',
                        ],
                        null,
                    );

                    if (selectedTagTitle === title) {
                        childIndex = i;

                        break;
                    }
                }

                if (childIndex === -1) {
                    const changeSet = _.set(
                        [],
                        dynamicChain,
                        {
                            $push: [newTag],
                        },
                    );
                    tagQuery        = update(tagQuery, changeSet);
                    childIndex      = _.get(
                        tagQuery,
                        [
                            ...dynamicChain,
                            'length',
                        ],
                        0,
                    ) - 1;
                }

                dynamicChain.push(childIndex);
            }

            dynamicChain.push('children');
        }

        return Tag.updatePossibleTagStatesAtRootLevel(tagQuery);
    };

    /**
     *
     * @param originalTagQuery
     * @param tag
     * @param children
     * @returns {*}
     */
    static addPossibleChildToTagSelectorTree = (originalTagQuery, tag, children) => {
        const hierarchy    = tag.hierarchy;
        let tagQuery       = _.cloneDeep(originalTagQuery || []);
        const dynamicChain = [];

        /**
         * Each tag has a flat hierarachy list of its parents. Here we traverse this list element by element and create a
         * dynamic chain which will be used by lodash to append the tag to the tree.
         *
         * The tree could look like this
         * Casting
         * -- Iron
         * ---- A
         * -- Plastic
         * ---- A
         * ---- B
         *
         * The dynamic chain could have this values [0, 'children', 1, 'children'].
         * In this case we would get the children of Casting > Plastic = [A,B] and append C
         *
         */
        for (let hierarchyIndex = 0; hierarchyIndex < hierarchy.length; hierarchyIndex++) {
            const currentNodeInHierarchy = hierarchy[hierarchyIndex];
            const title                  = currentNodeInHierarchy.title;
            let childIndex               = -1;

            if (dynamicChain.length === 0) {
                let rootIndex = -1;

                for (let i = 0; i < tagQuery.length; i++) {
                    const element         = tagQuery[i];
                    const elementTagTitle = _.get(
                        element,
                        [
                            'tag',
                            'title',
                        ],
                        null,
                    );

                    if (elementTagTitle === title) {
                        rootIndex = i;

                        break;
                    }
                }

                if (rootIndex === -1) {
                    dynamicChain.push(0);
                } else {
                    dynamicChain.push(rootIndex);
                }
            } else {
                const foundChildren = _.get(
                    tagQuery,
                    dynamicChain,
                    [],
                );

                for (let i = 0; i < foundChildren.length; i++) {
                    const element          = foundChildren[i];
                    const selectedTagTitle = _.get(
                        element,
                        [
                            'tag',
                            'title',
                        ],
                        null,
                    );

                    if (selectedTagTitle === title) {
                        childIndex = i;

                        break;
                    }
                }

                if (childIndex === -1) {
                    childIndex = _.get(
                        tagQuery,
                        [
                            ...dynamicChain,
                            'length',
                        ],
                        0,
                    ) - 1;
                }

                dynamicChain.push(childIndex);
            }

            if (_.get(tagQuery, dynamicChain).id === tag.id) {
                dynamicChain.push('possibleChildren');

                let newPossibleChildren = _.get(tagQuery, dynamicChain);
                newPossibleChildren     = _.uniqBy(
                    [
                        ...newPossibleChildren,
                        ...children,
                    ],
                    'id',
                );
                newPossibleChildren     = _.sortBy(
                    newPossibleChildren,
                    'title',
                );
                const changeSet         = _.set(
                    [],
                    dynamicChain,
                    {
                        $set: newPossibleChildren,
                    },
                );
                tagQuery                = update(tagQuery, changeSet);
            }

            dynamicChain.push('children');
        }

        return Tag.updatePossibleTagStatesAtRootLevel(tagQuery);
    };

    /**
     * Traverses the tree and removes the last node in the hierarchy of the tag you provided.
     * @param originalTagQuery
     * @param hierarchyPath
     * @returns {*}
     */
    static removeTagFromTagSelectorTree = (originalTagQuery, hierarchyPath) => {
        const dynamicChain   = [];
        let tagQuery         = _.cloneDeep(originalTagQuery);
        const isRoot         = hierarchyPath.length === 1;
        let tagIndexToRemove = -1;

        /**
         * The tree could look like this
         * Casting
         * -- Iron
         * ---- A
         * -- Plastic
         * ---- A
         * ------ X
         * ------ Y
         * ------ Z
         * ---- B
         * ---- C
         *
         * The dynamic chain could have this values [0, 'children', 1, 'children', 0].
         * In this case we would remove A from Casting > Plastic and all of its children
         *
         */
        for (let hierarchyIndex = 0; hierarchyIndex < hierarchyPath.length; hierarchyIndex++) {
            const isLast                        = hierarchyIndex === hierarchyPath.length - 1;
            const result                        = Tag.findIndexAndSiblings(hierarchyPath, hierarchyIndex, tagQuery, dynamicChain);
            const foundIndex                    = result.foundIndex;
            const currentHierarchyLevelSiblings = result.currentHierarchyLevelSiblings;

            if (isRoot) {
                tagIndexToRemove = foundIndex;
            } else if (!isLast) {
                dynamicChain.push(foundIndex, 'children');
            } else if (foundIndex !== -1) {
                const foundTagTitle     = currentHierarchyLevelSiblings[foundIndex];
                const currentListOfTags = _.get(
                    tagQuery,
                    dynamicChain,
                    [],
                );
                tagIndexToRemove        = _.findIndex(
                    currentListOfTags,
                    (entry) => {
                        return entry.tag.title === foundTagTitle;
                    },
                );
            }
        }

        if (isRoot) {
            _.unset(
                tagQuery,
                [
                    tagIndexToRemove,
                ],
            );

            tagQuery = _.compact(tagQuery);
        } else {
            _.unset(
                tagQuery,
                [
                    ...dynamicChain,
                    tagIndexToRemove,
                ],
            );
            _.set(
                tagQuery,
                dynamicChain,
                _.compact(_.get(
                    _.cloneDeep(tagQuery),
                    dynamicChain,
                )),
            );
        }

        return Tag.updatePossibleTagStatesAtRootLevel(tagQuery);
    };

    /**
     * Finds the provided tag in the hierarchy, changes its selected tag index and other values like the id, iri and title.
     * Removes all children below the tag because they probably dont match the new tag.
     * @param originalTagQuery
     * @param hierarchyPath
     * @param changedValue
     * @returns {*}
     */
    static changeTagInTagSelectorTree = (originalTagQuery, hierarchyPath, changedValue) => {
        const tagQuery     = _.cloneDeep(originalTagQuery);
        const dynamicChain = [];

        for (let hierarchyIndex = 0; hierarchyIndex < hierarchyPath.length; hierarchyIndex++) {
            const result     = Tag.findIndexAndSiblings(hierarchyPath, hierarchyIndex, tagQuery, dynamicChain);
            const foundIndex = result.foundIndex;

            if (hierarchyIndex !== hierarchyPath.length - 1) {
                dynamicChain.push(foundIndex, 'children');
            } else {
                dynamicChain.push(foundIndex);
            }
        }

        /**
         * The tree could look like this
         * Casting
         * -- Iron
         * ---- A
         * -- Plastic = [A,B,C,D,E...Z]
         * ---- A = 0
         * ---- B = 1
         * ---- C = 2
         *
         * The dynamic chain could have this values [0, 'children', 1, 'children', 0].
         * In this case we would change the selected index value of A in Casting > Plastic from 0 = A to for example 4 = E.
         * 1 and 2 are not possible because B and C are already selected siblings of A. They are invisible in the dropdown of A.
         */
        if (dynamicChain.length > 0) {
            for (let index = 0; index < dynamicChain.length; index++) {
                const chainPart = dynamicChain.slice(0, index + 1);

                if (index === dynamicChain.length - 1) {
                    const existingTag                             = _.cloneDeep(_.get(tagQuery, chainPart, {}));
                    const siblings                                = _.get(existingTag, 'tags', []);
                    const siblingIds                              = _.map(siblings, (sibling) => sibling.id);
                    const newSelectedTagIndex                     = _.indexOf(siblingIds, changedValue);
                    const newTag                                  = siblings[newSelectedTagIndex];
                    const newHierarchyPath                        = existingTag.hierarchyPath;
                    newHierarchyPath[newHierarchyPath.length - 1] = newTag.title;

                    _.update(tagQuery, chainPart, () => {
                        return {
                            ...existingTag,
                            id:                       newTag.id,
                            iri:                      newTag.iri,
                            externalIdentifier:       newTag.externalIdentifier,
                            selectedTagIndex:         newSelectedTagIndex,
                            children:                 [],
                            hierarchyPath:            newHierarchyPath,
                            numberOfPossibleChildren: newTag.numberOfPossibleChildren,
                            tag:                      {
                                ...newTag,
                                sibling: _.cloneDeep(siblings),
                            },
                        };
                    });
                }
            }
        }

        return Tag.updatePossibleTagStatesAtRootLevel(tagQuery);
    };

    /**
     * Adds a new sibling to the provided tag.
     * The new sibling will be the next from the list of possible siblings.
     * When the list of free siblings is exausted nothing happends and no new sibling is added.
     * @param originalTagQuery
     * @param hierarchyPath
     * @param possibleChildren
     * @returns {*}
     */
    static addNextSiblingToTagSelectorTree = (originalTagQuery, hierarchyPath, possibleChildren) => {
        const tagQuery     = _.cloneDeep(originalTagQuery);
        const dynamicChain = [];
        let changeSet      = {};
        const isRoot       = hierarchyPath.length === 1;

        /**
         * The tree could look like this
         * Casting
         * -- Iron
         * ---- A
         * -- Plastic = [A,B,C,D,E...Z]
         * ---- A = 0
         * ---- B = 1
         * ---- C = 2
         *
         * The dynamic chain could have this values [0, 'children', 1, 'children', 0].
         * In this case we would append D = 3 to Casting > Plastic.
         * 0 through 2 are already children of Plastic so the next possible child is D = 3
         */
        for (let hierarchyIndex = 0; hierarchyIndex < hierarchyPath.length; hierarchyIndex++) {
            const isLast     = hierarchyIndex === hierarchyPath.length - 1;
            const result     = Tag.findIndexAndSiblings(
                hierarchyPath,
                hierarchyIndex,
                tagQuery,
                dynamicChain,
            );
            const foundIndex = result.foundIndex;

            if (isLast) {
                dynamicChain.push(foundIndex);
            } else {
                dynamicChain.push(foundIndex, 'children');
            }
        }

        let siblingIds       = [];
        let selectedTag      = null;
        let newHierarchyPath = [];

        if (isRoot) {
            siblingIds  = _.map(
                tagQuery,
                (node) => node.id,
            );
            selectedTag = _.cloneDeep(_.get(
                tagQuery,
                [0],
                {},
            ));
        } else {
            const parentTag  = _.cloneDeep(_.get(
                tagQuery,
                dynamicChain.slice(0, -2),
                {},
            ));
            newHierarchyPath = parentTag.hierarchyPath;

            selectedTag = _.cloneDeep(_.get(
                tagQuery,
                dynamicChain,
                {},
            ));
            siblingIds  = _.map(
                parentTag.children,
                (child) => child.id,
            );
        }

        const newTagIndex = _.findIndex(
            possibleChildren,
            (tag) => _.indexOf(siblingIds, tag.id) === -1,
        );

        if (newTagIndex === -1) {
            return Tag.updatePossibleTagStatesAtRootLevel(tagQuery);
        }

        const newTag = possibleChildren[newTagIndex];

        newHierarchyPath.push(newTag.title);

        const mergedTag = {
            ...selectedTag,
            id:                 newTag.id,
            iri:                newTag.iri,
            externalIdentifier: newTag.externalIdentifier,
            selectedTagIndex:   newTagIndex - 1,
            possibleChildren:   [],
            children:           [],
            hierarchyPath:      newHierarchyPath,
            tag:                {
                ...newTag,
                sibling: possibleChildren,
            },
        };

        if (isRoot) {
            changeSet = {
                $push: [_.cloneDeep(mergedTag)],
            };
        } else {
            _.set(
                changeSet,
                dynamicChain.slice(0, -1),
                {
                    $push: [_.cloneDeep(mergedTag)],
                },
            );
        }

        const updated = update(
            tagQuery,
            changeSet,
        );

        return {
            tagQuery:              Tag.updatePossibleTagStatesAtRootLevel(updated),
            lastAddedSiblingTagId: newTag.id,
        };
    };

    /**
     * Traverses the tree and fills a list of already selected siblings in each node so the render code hides the already selected values in the dropdown.
     * @param tags
     * @param selectedTagIds
     * @returns {*}
     */
    static updatePossibleTagStates = (tags) => {
        for (let tagIndex = 0; tagIndex < tags.length; tagIndex++) {
            const tagEntry                     = tags[tagIndex];
            const children                     = tagEntry.children;
            tagEntry.alreadySelectedSiblingIds = _.map(children, (child) => child.id);
            tagEntry.children                  = Tag.updatePossibleTagStates(children);
        }

        return tags;
    };

    /**
     * Same as updatePossibleTagStates but the traversal is started at the root level.
     * @param tagQuery
     * @returns {*}
     */
    static updatePossibleTagStatesAtRootLevel = (tagQuery) => {
        const newTagQuery = _.cloneDeep(tagQuery);

        return Tag.updatePossibleTagStates(newTagQuery);
    };

    /**
     * Traverses the tree and gathers the identifier of all leaf nodes. Basically you get all ids of the last nodes in the tree.
     * The tree could look like this
     * Casting
     * -- Iron
     * ---- A
     * -- Plastic
     * ---- A
     * ---- B
     * ---- C
     * In this case we get a list of the ids of Casting > Iron > A and Casting > Plastic > A,B,C
     * @param tagQuery
     * @returns {*[]}
     */
    static gatherLeafIdentifier = (tagQuery) => {
        let leafIdentifiers = [];

        tagQuery?.forEach((node) => {
            leafIdentifiers = [
                ...Tag.traverseTreeAndGatherLeafIdentifier(node),
                ...leafIdentifiers,
            ];
        });

        return _.uniq(leafIdentifiers);
    };

    static gatherIdentifier = (tagQuery) => {
        let identifiers = [];

        for (const node of tagQuery) {
            identifiers = [
                ...Tag.traverseTreeAndGatherIdentifier(node),
                ...identifiers,
            ];
        }

        return _.uniq(identifiers);
    };

    /**
     * Same as gatherLeafIdentifier but with iri.
     * @param tagQuery
     * @returns {*[]}
     */
    static gatherLeafIri = (tagQuery = []) => {
        let leafIdentifiers = [];

        tagQuery.forEach((node) => {
            leafIdentifiers = [
                ...Tag.traverseTreeAndGatherLeafIri(node),
                ...leafIdentifiers,
            ];
        });

        return _.uniq(leafIdentifiers);
    };

    /**
     * Same as gatherLeafIdentifier but with iri.
     * @param tagQuery
     * @returns {*[]}
     */
    static gatherTagsFromHierarchy = (tagQuery) => {
        let leafs = [];

        tagQuery.forEach((node) => {
            leafs = [
                ...Tag.traverseTreeAndGatherTagsFromHierarchy(node),
                ...leafs,
            ];
        });

        return _.uniq(leafs);
    };

    /**
     * Recursive helper method for gatherLeafIri
     * @param tag
     * @returns {*[]}
     */
    static traverseTreeAndGatherTagsFromHierarchy = (tag) => {
        const identifier = [];
        const children   = tag.children;

        if (children.length > 0) {
            for (const child of children) {
                identifier.push(...Tag.traverseTreeAndGatherTagsFromHierarchy(child));
            }
        }

        identifier.push(tag);

        return identifier;
    };

    /**
     * Recursive helper method for gatherLeafIdentifier
     * @param tag
     * @returns {*[]}
     */
    static traverseTreeAndGatherLeafIdentifier = (tag) => {
        const identifier = [];
        const children   = tag.children;

        if (children.length > 0) {
            for (const child of children) {
                identifier.push(...Tag.traverseTreeAndGatherLeafIdentifier(child));
            }
        } else {
            identifier.push(tag.id);
        }

        return identifier;
    };

    /**
     * @param tag
     * @returns {*[]}
     */
    static traverseTreeAndGatherIdentifier = (tag) => {
        const identifier = [];
        const children   = tag.children || [];

        identifier.push(tag.id);

        if (children.length > 0) {
            for (const child of children) {
                identifier.push(...Tag.traverseTreeAndGatherIdentifier(child));
            }
        }

        return identifier;
    };

    /**
     * Recursive helper method for gatherLeafIri
     * @param tag
     * @returns {*[]}
     */
    static traverseTreeAndGatherLeafIri = (tag) => {
        const identifier = [];
        const children   = tag.children || [];

        if (children.length > 0) {
            for (const child of children) {
                identifier.push(...Tag.traverseTreeAndGatherLeafIri(child));
            }
        } else {
            identifier.push(tag.iri);
        }

        return identifier;
    };

    /**
     * Finds the index of the provided node inside the list of siblings on the same level and returns the found index and its siblings.
     * @param hierarchyPath
     * @param hierarchyIndex
     * @param tagQuery
     * @param dynamicChain
     * @returns {{foundIndex: number, currentHierarchyLevelSiblings: *}}
     */
    static findIndexAndSiblings(hierarchyPath, hierarchyIndex, tagQuery, dynamicChain) {
        const currentHierarchyLevelNode   = hierarchyPath[hierarchyIndex];
        let currentHierarchyLevelSiblings = _.cloneDeep(tagQuery);

        if (hierarchyIndex !== 0) {
            currentHierarchyLevelSiblings = _.get(tagQuery, dynamicChain, []);
        }

        currentHierarchyLevelSiblings = currentHierarchyLevelSiblings.map((sibling) => sibling.tag.title);
        const foundIndex              = _.indexOf(currentHierarchyLevelSiblings, currentHierarchyLevelNode);

        return {
            currentHierarchyLevelSiblings,
            foundIndex,
        };
    }

    static filterAllTagsFromHierarchy(allTags, tagHierarchy) {
        const foundTagIds = Tag.gatherIdentifier(tagHierarchy);

        return _.filter(allTags, (tagResult) => !foundTagIds.includes(tagResult.id));
    }
}
