//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    addCircle:              'addCircle',
    arrowRight:             'arrowRight',
    burger:                 'burger',
    calendar:               'calendar',
    camera:                 'camera',
    caretDown:              'caretDown',
    caretLeft:              'caretLeft',
    caretRight:             'caretRight',
    chat:                   'chat',
    check:                  'check',
    cross:                  'cross',
    completeProfileCheck:   'completeProfileCheck',
    completeProfileUncheck: 'completeProfileUncheck',
    deleteCircle:           'deleteCircle',
    download:               'download',
    envelope:               'envelope',
    eye:                    'eye',
    facebook:               'facebook',
    file:                   'file',
    filter:                 'filter',
    info:                   'info',
    instagram:              'instagram',
    key:                    'key',
    letter:                 'letter',
    linkedin:               'linkedin',
    owner:                  'owner',
    pdf:                    'pdf',
    pencil:                 'pencil',
    plus:                   'plus',
    print:                  'print',
    remove:                 'remove',
    search:                 'search',
    share:                  'share',
    spinner:                'spinner',
    star:                   'star',
    twitter:                'twitter',
    unfollow:               'unfollow',
    upload:                 'upload',
    user:                   'user',
    verified:               'verified',
    x:                      'x',
    youTube:                'youTube',
};
