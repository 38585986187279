//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

class MembershipListHelper {
    static getOptions(membershipOptions) {
        return membershipOptions.map((membership) => {
            const { title, iri } = membership;

            return {
                value: iri,
                label: title,
            };
        });
    }

    static getCurrentMembershipCountryOption(membershipOptions, option) {
        if (!membershipOptions.length) {
            return {
                value: null,
                label: 'none',
            };
        }

        const membership     = option ?
            _.find(membershipOptions, {
                iri: option,
            }) :
            _.first(membershipOptions);
        const { title, iri } = membership;

        return {
            value: iri,
            label: title,
        };
    }
}

export default MembershipListHelper;
