//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                            from 'react';
import _                                from 'lodash';
import { bindActionCreators }           from 'redux';
import { connect }                      from 'react-redux';
import ComponentHelper                  from '@/helper/ComponentHelper';
import Environment                      from '@/helper/Environment';
import PropTypes                        from '@/components/PropTypes';
import StatelessStagingLoginOverlay     from '@/components/stateless/atomic/StagingLoginOverlay';
import { StagingAuthenticationActions } from '@/store/actions/stagingAuthentication';

export class Component extends React.Component {
    shouldShowStagingLoginOverlay = () => {
        return (
            Environment.isStaging() &&
            !Environment.isDevelopment() &&
            !this.props.isAuthenticated
        );
    };

    render() {
        if (this.shouldShowStagingLoginOverlay()) {
            return (
                <StatelessStagingLoginOverlay
                    authenticate={this.props.authenticate}
                    passwordChanged={this.props.authenticationPasswordChanged}
                />
            );
        }

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const StagingLoginOverlay = Component;

Component.propTypes = {
    authenticate:                  PropTypes.func,
    authenticationPasswordChanged: PropTypes.func,
    isAuthenticated:               PropTypes.bool,
};

Component.defaultProps = {
    authenticate:                  _.noop,
    authenticationPasswordChanged: _.noop,
    isAuthenticated:               true,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        isAuthenticated: state.stagingAuthentication.isAuthenticated,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
    StagingAuthenticationActions,
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
