//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';
import I18n  from 'i18next';

import PropTypes         from '@/components/PropTypes';
import { Collapsable }   from '@/components/stateless/composed/Collapsable';
import { HeadlineSmall } from '@/components/stateless/atomic/HeadlineSmall';
import { Spacer }        from '@/components/stateless/atomic/Spacer';

import styles from './styles.module.scss';

export class Component extends React.Component {
    getTitle = () => {
        if (this.props.title) {
            return this.props.title;
        }

        if (this.props.collapsable) {
            return I18n.t('tags');
        }

        return null;
    };

    render() {
        const title = this.getTitle();

        if (this.props.collapsable) {
            return (
                <Collapsable
                    title={title}
                    closed={this.props.closed}
                >
                    {this.renderCollapsableTagList()}
                </Collapsable>
            );
        }

        return (
            <div>
                {this.renderTitle(title)}
                {this.renderCollapsableTagList()}
            </div>
        );
    }

    renderCollapsableTagList = () => {
        return (
            <div className={styles.collapsableTagListWrapper}>
                {this.props.children}
            </div>
        );
    };

    renderTitle = (title) => {
        if (title) {
            return (
                <>
                    <HeadlineSmall text={title} />
                    <Spacer height={15} />
                </>
            );
        }

        return null;
    };
}

export const CollapsableTagList = Component;

Component.propTypes = {
    children:    PropTypes.children,
    closed:      PropTypes.bool,
    collapsable: PropTypes.bool,
    title:       PropTypes.string,
};

Component.defaultProps = {
    children:    null,
    closed:      false,
    collapsable: true,
    title:       null,
};

export default Component;
