//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _              from 'lodash';
import queryString    from 'query-string';
import String         from '@/helper/String';
import RouteHelper    from '@/helper/Route';
import Routes         from '@/constants/Routes';
import ViewingContext from '@/constants/ViewingContext';

class Navigation {
    static goBack() {
        window.history.back();
    }

    static getToFromRouteObject(routeObject, searchObject = [], hash = null) {
        let searchParameters = {};
        let pathname         = routeObject;

        if (typeof routeObject === 'object') {
            pathname         = _.get(routeObject, 'route', '');
            const parameters = _.get(routeObject, 'parameters', {});
            searchParameters = queryString.parse(_.get(routeObject, 'search', {}));
            pathname         = RouteHelper.replaceParametersInUrl(pathname, parameters);
        }

        const search = Object.entries({
            ...searchObject,
            ...searchParameters,
        }).map(([key, value]) => `${key}=${value}`).join('&');

        const toParameters = {
            pathname,
            search: search ? `?${search}` : '',
            hash:   hash ? `#${hash}` : '',
        };

        return toParameters;
    }

    static getCompanyIdFromUrl(ownProps) {
        const companyId = this.getParameterFromUrl(ownProps, 'companyId');

        if (!companyId) {
            return null;
        }

        return this.getUuidFromString(companyId);
    }

    static getProfileIdFromUrl(ownProps) {
        const profileId = this.getParameterFromUrl(ownProps, 'profileId');

        if (!profileId) {
            return null;
        }

        return this.getUuidFromString(profileId);
    }

    static getProjectIdFromUrl(ownProps) {
        const projectId = this.getParameterFromUrl(ownProps, 'projectId');

        if (!projectId) {
            return null;
        }

        return String.mask(projectId, '########-####-####-####-############');
    }

    static getMessageRequestIdFromUrl(ownProps) {
        const messageRequestId = this.getParameterFromUrl(ownProps, 'messageRequestId');

        if (!messageRequestId) {
            return null;
        }

        return this.getUuidFromString(messageRequestId);
    }

    static getParameterFromUrl(ownProps, parameter) {
        return _.get(ownProps, ['match', 'params', parameter]);
    }

    static getUuidFromString(string) {
        return String.mask(string, '########-####-####-####-############');
    }

    static isOwnProfileVisible(ownProps, ownProfileId) {
        const pathname = _.get(ownProps, 'location.pathname');

        if (_.startsWith(pathname, Routes.myProfile)) {
            return true;
        }

        const profileId = this.getProfileIdFromUrl(ownProps);

        if (
            !profileId ||
            !ownProfileId
        ) {
            return false;
        }

        return _.startsWith(profileId, ownProfileId);
    }

    static isOwnCompanyVisible(ownProps, ownCompanyId) {
        const companyIdFromUrl = Navigation.getParameterFromUrl(ownProps, 'companyId');

        if (
            !companyIdFromUrl ||
            !ownCompanyId
        ) {
            return false;
        }

        return _.startsWith(companyIdFromUrl, ownCompanyId);
    }

    static getViewingContext(ownProps, state) {
        const isACompanyPage = _.startsWith(_.get(ownProps, 'location.pathname'), Routes.companyPart);
        const isAProfilePage = _.startsWith(_.get(ownProps, 'location.pathname'), Routes.myProfile);

        if (isACompanyPage) {
            const ownCompanyId   = _.get(state, 'user.company');
            const isOnOwnCompany = Navigation.isOwnCompanyVisible(ownProps, ownCompanyId);

            if (isOnOwnCompany) {
                return ViewingContext.OWN_COMPANY;
            }

            return ViewingContext.OTHER_COMPANY;
        }

        if (isAProfilePage) {
            const ownProfileId   = _.get(state, 'user.profile');
            const isOnOwnProfile = Navigation.isOwnProfileVisible(ownProps, ownProfileId);

            if (isOnOwnProfile) {
                return ViewingContext.OWN_PROFILE;
            }

            return ViewingContext.OTHER_PROFILE;
        }

        return ViewingContext.UNDEFINED;
    }
}

export default Navigation;
