//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }   from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import { push }  from 'connected-react-router';
import _         from 'lodash';
import Routes    from '@/constants/Routes';
import Overlays  from '@/constants/Overlays';

export function* redirectWithDelay(route, delayInMilliseconds = 50) {
    // This delay is important otherwise the redirect will not work properly
    yield delay(delayInMilliseconds);
    yield put(push(route));
}

class Route {
    static buildPathByRoute(route) {
        let pathname     = _.get(route, 'route', '');
        const parameters = _.get(route, 'parameters', {});
        pathname         = this.replaceParametersInUrl(pathname, parameters);

        return {
            pathname,
        };
    }

    static getRouteAndOverlay(location) {
        let overlayMatch = null;
        let routeMatch   = Object.values(Routes).find((route) => {
            return route === location.pathname;
        });

        if (!routeMatch) {
            routeMatch = Object.values(Routes).find((route) => {
                return Route.matchesPartRoute(route, location.pathname);
            });
        }

        if (!routeMatch) {
            console.error('Given location could not be found.');

            return false;
        }

        if (location.search.includes('overlay')) {
            const parameters  = new URLSearchParams(location.search);
            const overlayName = parameters.get('overlay');
            overlayMatch      = Object.values(Overlays).find((overlay) => {
                return overlay === overlayName;
            });

            if (!overlayMatch) {
                console.error('Given overlay could not be found.');

                return false;
            }
        }

        return {
            route:   routeMatch,
            overlay: overlayMatch,
        };
    }

    static buildRoute(route, ...parameters) {
        const routeParameters = this.getParametersFromRoute(route);

        parameters.forEach((parameter, index) => {
            const routeParameterKey            = Object.keys(routeParameters)[index];
            routeParameters[routeParameterKey] = parameter;
        });

        return {
            route,
            parameters: routeParameters,
        };
    }

    static buildRouteForCompany(company) {
        if (company) {
            return Route.buildRoute(Routes.company, Route.getCompanyUrl(company.id, company.slug));
        }

        return null;
    }

    static buildRouteForProfile() {
        return Route.buildRoute(Routes.myProfile, []);
    }

    static getCompanyUrl(id, slug) {
        return `${id}-${slug}`;
    }

    static buildPathForCompanyMessageRequest(company, messageRequest) {
        const route        = this.buildRoute(
            Routes.companyMessageDetail,
            Route.getCompanyUrl(company.id, company.slug),
            messageRequest.id,
        );
        const { pathname } = this.buildPathByRoute(route);

        return pathname;
    }

    static buildPathForProfileMessageRequestDetail(messageRequest) {
        const route        = this.buildRoute(
            Routes.myProfileMessagesDetail,
            messageRequest.id,
        );
        const { pathname } = this.buildPathByRoute(route);

        return pathname;
    }

    static getParametersFromRoute(route, matchingRoute = route) {
        const results = {};
        const matches = matchingRoute.match(/:([\w-]+)/g);

        if (!matches) {
            return results;
        }

        matches.forEach((match) => {
            const index                 = matchingRoute.indexOf(match);
            const preParameter          = matchingRoute.slice(0, index);
            const postParameter         = matchingRoute.slice(index + match.length);
            results[match.substring(1)] = route.replace(preParameter, '').replace(postParameter, '');
        });

        return results;
    }

    static replaceParametersInUrl(url, parameters) {
        const keys      = _.keys(parameters).join('|');
        let replacedUrl = url;

        // Replace optional path parameter values
        _.forEach(parameters, (value, key) => {
            if (_.isNil(value)) {
                replacedUrl = url.replace(`:${key}?`, '');
            } else {
                replacedUrl = url.replace(`:${key}?`, parameters[key]);
            }
        });

        const regExp = new RegExp(`:(${keys})`, 'g');
        replacedUrl  = replacedUrl.replace(regExp, (matched) => parameters[matched.replace(':', '')] || matched);

        return replacedUrl;
    }

    static matchesPartRoute(route, currentRoute) {
        const splittedRoute        = route.split('/');
        const splittedCurrentRoute = currentRoute.split('?')[0].split('/');
        const differences          = _.differenceWith(splittedRoute, splittedCurrentRoute, _.isEqual);
        const isDifferencePart     = differences.some((difference) => !difference.startsWith(':'));

        return !isDifferencePart && splittedRoute.length === splittedCurrentRoute.length;
    }

    static hasParameter(route, parameter, value = null) {
        const searchParameters = new URL(`https://some.url${route}`).searchParams;

        if (value) {
            return searchParameters.get(parameter) === value;
        }

        return searchParameters.has(parameter);
    }

    static isFactsheetPrintRoute(route) {
        return route ? route.endsWith('/fact-sheet/print') : false;
    }
}

export default Route;
