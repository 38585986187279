//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update           from 'immutability-helper';
import { PartnerTypes } from '@/store/actions/partner';

const initialState = {
    partner: [],
};

const partnerFetchSucceeded = (action, state) => {
    return update(state, {
        partner: {
            $set: action?.partner ?? [],
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case PartnerTypes.FETCH_PARTNER_SUCCEEDED: return partnerFetchSucceeded(action, state);
        default:                                   return state;
        // @formatter:on
    }
}
