//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import _                             from 'lodash';
import { bindActionCreators }        from 'redux';
import { connect }                   from 'react-redux';
import ComponentHelper               from '@/helper/ComponentHelper';
import StatelessCookieBannerRequired from '@/components/stateless/atomic/CookieBannerRequired';
import { CookieBannerActions }       from '@/store/actions/cookieBanner';
import PropTypes                     from '@/components/PropTypes';

export class Component extends React.Component {
    acceptCookies = () => {
        this.props.acceptCookies();
    };

    render() {
        return (
            <StatelessCookieBannerRequired acceptCookies={this.acceptCookies} />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const CookieBanner = Component;

Component.propTypes = {
    acceptCookies: PropTypes.func,
};

Component.defaultProps = {
    acceptCookies: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    CookieBannerActions,
    dispatch,
);

export default connect(
    null,
    mapDispatchToProps,
)(Component);
