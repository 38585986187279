//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import I18n            from 'i18next';
import _               from 'lodash';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import { IconButton }  from '@/components/stateless/atomic/IconButton';
import IconButtonTheme from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import FileDownloader  from '@/helper/FileDownloader';
import styles          from './styles.module.scss';

export class AttachmentList extends React.Component {
    static propTypes = {
        files: PropTypes.array,
    };

    static defaultProps = {
        files: [],
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    onDownloadFile = (file) => () => {
        const { path, name } = file;

        FileDownloader.downloadFile(path, name);
    };

    renderFile = (file) => {
        const { displayName, name } = file;
        const fileName              = displayName || name;

        return (
            <div
                className={styles.fileEntry}
                key={_.uniqueId(fileName)}
            >
                {fileName}
                <IconButton
                    iconType={IconType.download}
                    onClick={this.onDownloadFile(file)}
                    theme={IconButtonTheme.grayRound}
                />
            </div>
        );
    };

    renderFiles = () => {
        const { files }          = this.props;
        const renderedComponents = [];

        for (const file of files) {
            renderedComponents.push(this.renderFile(file));
        }

        return renderedComponents;
    };

    render() {
        const { files } = this.props;

        if (files.length === 0) {
            return null;
        }

        return (
            <div className={styles.wrapper}>
                <h3>
                    {I18n.t('attachedFiles')}
                </h3>
                {this.renderFiles()}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default AttachmentList;
