//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';
import _     from 'lodash';
import I18n  from 'i18next';

import * as Api           from '@/api';
import EntityLabelSize    from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import PropTypes          from '@/components/PropTypes';
import { EntityLabel }    from '@/components/stateless/composed/EntityLabel';

import moment          from 'moment';
import ReactHtmlParser from 'react-html-parser';
import Company         from '@/helper/Company';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    renderParticipant = (request, isFromMessage) => {
        // @formatter:off
        const {
            company,
            requestingCompany,
            recipient,
            sender,
        }            = request;
        let title    = '';
        let subtitle = '';
        let avatar   = null;
        // @formatter:on

        if (isFromMessage) {
            title    = requestingCompany?.name || sender?.name;
            subtitle = Company.getCompanyTypesString(requestingCompany?.types) || sender?.position;
            avatar   = requestingCompany?.logo?.path || sender?.image?.path;
        } else {
            title    = company?.name || recipient?.name;
            subtitle = Company.getCompanyTypesString(company?.types) || recipient?.position;
            avatar   = company?.logo?.path || recipient?.image?.path;
        }

        let lastMessage = _.last(_.get(request, 'messages'));

        if (!lastMessage) {
            lastMessage = request;
        }

        return (
            <div className={styles.messageRequestWrapper}>
                <EntityLabel
                    title={title}
                    subtitle={subtitle}
                    avatar={Api.getImagePath(avatar)}
                    size={EntityLabelSize.small}
                    withoutLink={true}
                />
                <p>
                    {ReactHtmlParser(_.get(lastMessage, 'message'))}
                </p>
                <span>
                    {moment(_.get(lastMessage, 'createdAt')).format(I18n.t('dateFormat'))}
                </span>
            </div>
        );
    };

    render() {
        const { messageRequest, incoming } = this.props;
        const content                      = (
            <>
                <div className={styles.messageRequestPreview}>
                    {this.renderParticipant(messageRequest, incoming)}
                </div>
            </>
        );

        return React.createElement(
            'button',
            {
                onClick:   this.props.onOpenClicked,
                className: styles.message,
            },
            content,
        );
    }
}

export const MessageRequestPreview = Component;

Component.propTypes = {
    incoming:         PropTypes.bool,
    messageRequest:   PropTypes.object,
    onAcceptClicked:  PropTypes.func,
    onDeclineClicked: PropTypes.func,
    onOpenClicked:    PropTypes.func,
};

Component.defaultProps = {
    incoming:         false,
    messageRequest:   null,
    onAcceptClicked:  _.noop,
    onDeclineClicked: _.noop,
    onOpenClicked:    _.noop,
};

export default Component;
