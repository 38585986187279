//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import HydraHelper        from '@/helper/Hydra';
import { PartnerActions } from '@/store/actions/partner';
import { call }           from 'redux-saga/effects';
import { put }            from 'redux-saga/effects';
import { takeLatest }     from 'redux-saga/effects';
import * as Api           from '@/api';
import { PartnerTypes }   from '@/store/actions/partner';

function* fetchPartner() {
    const response = yield call(Api.context.partner.fetchPartner);

    if (response.ok) {
        yield put(PartnerActions.fetchPartnersSucceeded({
            partner: HydraHelper.getMembersFromResponse(response.data),
        }));
    } else {
        yield put(PartnerActions.fetchPartnersFailed());
    }
}

export const callPartnerSaga = () => {
    return [
        // @formatter:off
        takeLatest([PartnerTypes.FETCH_PARTNER], fetchPartner),
        // @formatter:on
    ];
};
