//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                    from 'react';
import { bindActionCreators }   from 'redux';
import { connect }              from 'react-redux';
import _                        from 'lodash';
import PropTypes                from '@/components/PropTypes';
import StatelessStartPageHeader from '@/components/stateless/composed/StartPageHeader';
import ComponentHelper          from '@/helper/ComponentHelper';
import { UserActions }          from '@/store/actions/user';
import State                    from '@/helper/State';

export class Component extends React.Component {
    logout = () => {
        this.props.logout();
    };

    render() {
        const { isUserWithoutCompany, isLoggedIn } = this.props;

        return (
            <StatelessStartPageHeader
                isLoggedIn={isLoggedIn}
                isUserWithoutCompany={isUserWithoutCompany}
                onLogoutClicked={this.logout}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const StartPageHeader = Component;

Component.propTypes = {
    isLoggedIn:           PropTypes.bool,
    isUserWithoutCompany: PropTypes.bool,
    logout:               PropTypes.func,
    scrollToTop:          PropTypes.func,
};

Component.defaultProps = {
    isLoggedIn:           false,
    isUserWithoutCompany: false,
    logout:               _.noop,
    scrollToTop:          _.noop,
};

Component.renderAffectingProps = _.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        isLoggedIn:           !!_.get(state, 'user.token', false),
        isUserWithoutCompany: _.isNil(State.getUserCompany(state)),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
    UserActions,
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
