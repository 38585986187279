//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React              from 'react';
import _                  from 'lodash';
import I18n               from 'i18next';
import PropTypes          from '@/components/PropTypes';
import IconType           from '@/components/stateless/atomic/Icon/IconType';
import { IconTextButton } from '@/components/stateless/atomic/IconTextButton';
import { Logo }           from '@/components/stateless/atomic/Logo';
import styles             from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { onClick } = this.props;

        return (
            <div
                className={styles.claimCompanyHeader}
            >
                <div className={styles.claimNowText}>
                    <span>
                        {I18n.t('claim')}
                    </span>
                    <span>
                        {I18n.t('now')}
                    </span>
                </div>
                <Logo className={styles.logo} />
                <div
                    className={styles.claimButton}
                >
                    <IconTextButton
                        active={true}
                        onClick={onClick}
                        iconType={IconType.plus}
                        text={I18n.t('claim')}
                    />
                </div>
            </div>
        );
    }
}

Component.propTypes = {
    company: PropTypes.object,
    onClick: PropTypes.func,
};

Component.defaultProps = {
    company: null,
    onClick: _.noop,
};

export const ClaimCompanyHeader = Component;

export default Component;
