//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                         from 'react';
import _                             from 'lodash';
import { bindActionCreators }        from 'redux';
import { compose }                   from 'redux';
import { connect }                   from 'react-redux';
import ComponentHelper               from '@/helper/ComponentHelper';
import PropTypes                     from '@/components/PropTypes';
import StatelessRemoveMachineOverlay from '@/components/stateless/composed/RemoveMachineOverlay';
import { CompanyActions }            from '@/store/actions/company';

export class Component extends React.Component {
    render() {
        return (
            <StatelessRemoveMachineOverlay
                machineName={this.props.machineName}
                closeButtonPressed={this.props.cancelEditOwnCompany}
                okButtonPressed={this.props.removeCompanyMachine}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const RemoveMachineOverlay = Component;

Component.propTypes = {
    cancelEditOwnCompany: PropTypes.func,
    machineName:          PropTypes.string,
    removeCompanyMachine: PropTypes.func,
};

Component.defaultProps = {
    cancelEditOwnCompany: _.noop,
    machineName:          null,
    removeCompanyMachine: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    const machineToRemove = _.get(state, 'company.machineToRemove');

    return {
        machineName: _.get(machineToRemove, 'name'),
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Component);
