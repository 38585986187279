//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import React           from 'react';
import classNames      from 'classnames';
import nameInitials    from 'name-initials';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import Link            from '@/components/stateless/atomic/Link';
import EntityLabelSize from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import SelectionHelper from '@/helper/SelectionHelper';
import { FullImage }   from '@/components/stateless/composed/FullImage';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const avatar = this.renderAvatarOrFallback();

        return this.renderAvatarLink(avatar);
    }

    renderAvatarLink = (avatar) => {
        if (
            !this.props.withoutLink &&
            this.props.to
        ) {
            return (
                <Link
                    to={this.props.to}
                    className={styles.link}
                >
                    {avatar}
                </Link>
            );
        }

        return (
            <div className={styles.link}>
                {avatar}
            </div>
        );
    };

    renderAvatarOrFallback = () => {
        if (this.props.image) {
            return this.renderAvatar();
        }

        return this.renderFallbackAvatar();
    };

    renderAvatar = () => {
        return (
            <FullImage
                alternativeText={this.props.fallback}
                image={this.props.image}
                className={styles.avatar}
                onLoad={this.props.onImageLoaded}
                width={80}
            />
        );
    };

    renderFallbackAvatar = () => {
        if (this.props.fallback) {
            return (
                <div
                    className={classNames(
                        styles.avatar,
                        styles.avatarFallback,
                        SelectionHelper.get(
                            this.props.size,
                            {
                                [EntityLabelSize.small]:   styles.avatarFallbackSmall,
                                [EntityLabelSize.default]: styles.avatarFallbackDefault,
                            },
                        ),
                    )}
                >
                    {nameInitials(this.props.fallback)}
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Avatar = Component;

Component.propTypes = {
    fallback:      PropTypes.string,
    image:         PropTypes.image,
    onImageLoaded: PropTypes.func,
    size:          PropTypes.oneOfObjectValues(EntityLabelSize),
    to:            PropTypes.object,
    withoutLink:   PropTypes.bool,
};

Component.defaultProps = {
    fallback:      null,
    image:         null,
    onImageLoaded: _.noop,
    size:          EntityLabelSize.small,
    to:            null,
    withoutLink:   false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
