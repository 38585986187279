//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                             from 'lodash';
import update                        from 'immutability-helper';
import { MachineTypes }              from '@/store/actions/machine';
import Tag                           from '@/helper/Tag';
import ApiMode                       from '@/constants/ApiMode';
import { getImagePathByImageObject } from '@/api';
import { CompanyTypes }              from '@/store/actions/company';

const initialMachineParameter = {
    name:  '',
    value: '',
    unit:  '',
};

const initialState = {
    apiMode:        null,
    machines:       [],
    ownMachineEdit: null,
};

const abortMachineEdit = (action, state) => {
    return update(state, {
        ownMachineEdit: {
            $set: null,
        },
    });
};

const addChildrenToTagQuery = (action, state) => {
    const tag                   = _.get(action, 'tag');
    const children              = _.get(action, 'children');
    const ownMachineEdit        = _.cloneDeep(state.ownMachineEdit);
    ownMachineEdit.tagHierarchy = Tag.addPossibleChildToTagSelectorTree(ownMachineEdit.tagHierarchy, tag, children);

    return update(
        state,
        {
            ownMachineEdit: {
                $set: ownMachineEdit,
            },
        },
    );
};

const addMachineParameter = (action, state) => {
    return update(state, {
        ownMachineEdit: {
            parameters: {
                $push: [initialMachineParameter],
            },
        },
    });
};

const addMachineTag = (action, state) => {
    const clonedEditMachine        = _.cloneDeep(state.ownMachineEdit);
    clonedEditMachine.tagHierarchy = Tag.addTagToTagSelectorTree(clonedEditMachine.tagHierarchy, action.tag);

    return update(state, {
        ownMachineEdit: {
            $set: clonedEditMachine,
        },
    });
};

const changeMachineTag = (action, state) => {
    const clonedEditMachine        = _.cloneDeep(state.ownMachineEdit);
    const hierarchyPath            = _.get(action.tag, 'data.hierarchyPath');
    const newTag                   = action.tag.changedValue;
    clonedEditMachine.tagHierarchy = Tag.changeTagInTagSelectorTree(clonedEditMachine.tagHierarchy, hierarchyPath, newTag.value);

    return update(state, {
        ownMachineEdit: {
            $set: clonedEditMachine,
        },
    });
};

const deleteMachineTag = (action, state) => {
    const clonedEditMachine        = _.cloneDeep(state.ownMachineEdit);
    const hierarchyPath            = _.get(action.tag, 'data.hierarchyPath');
    clonedEditMachine.tagHierarchy = Tag.removeTagFromTagSelectorTree(clonedEditMachine.tagHierarchy, hierarchyPath);

    return update(state, {
        ownMachineEdit: {
            $set: clonedEditMachine,
        },
    });
};

const editMachineImage = (action, state) => {
    return update(state, {
        ownMachineEdit: {
            imageUploaded: {
                $set: action.image,
            },
        },
    });
};

const fetchMachineSucceeded = (action, state) => {
    let machine = action.machine;

    if (!_.isArray(machine)) {
        machine = [machine];
    }

    const machines = _.uniqBy(
        [
            ...machine,
            ...state.machines,
        ],
        'iri',
    );

    return update(state, {
        machines: {
            $set: machines,
        },
    });
};

const fetchMachinesByCompanySucceeded = (action, state) => {
    const companyId = action.companyId;
    const machines  = _.uniqBy(
        [
            ...action.machines,
            ..._.cloneDeep(state.machines),
        ],
        'iri',
    );

    for (const machine of machines) {
        // remove company entry from machine.companies if action.machines does not contain the machine
        const foundMachine = _.find(action.machines, {
            id: machine.id,
        });

        if (!foundMachine) {
            machine.companies = _.filter(machine.companies, (company) => company.id !== companyId);
        }
    }

    return update(state, {
        machines: {
            $set: machines,
        },
    });
};

const isManufacturerChanged = (action, state) => {
    return update(state, {
        ownMachineEdit: {
            isManufacturer: {
                $set: !state.ownMachineEdit.isManufacturer,
            },
        },
    });
};

const machineCategoryChanged = (action, state) => {
    return update(state, {
        ownMachineEdit: {
            category: {
                $set: action.category,
            },
        },
    });
};

const machineDescriptionChanged = (action, state) => {
    return update(state, {
        ownMachineEdit: {
            description: {
                $set: action.description,
            },
        },
    });
};

const machineNameChanged = (action, state) => {
    return update(state, {
        ownMachineEdit: {
            name: {
                $set: action.name,
            },
        },
    });
};

const machineCapacityChanged = (action, state) => {
    const machineCapacity = action.machineCapacity;
    const capacities      = _.cloneDeep(_.get(state, 'ownMachineEdit.capacities', []));
    const index           = _.findIndex(
        capacities,
        (capacity) => {
            return (
                _.get(capacity, 'company.id') === _.get(machineCapacity, 'company.id') &&
                _.get(capacity, 'year') === _.get(machineCapacity, 'year')
            );
        },
    );

    if (index === -1) {
        capacities.push(machineCapacity);
    } else {
        capacities[index] = {
            ...capacities[index],
            ...machineCapacity,
        };
    }

    return update(state, {
        ownMachineEdit: {
            capacities: {
                $set: capacities,
            },
        },
    });
};

const machineParameterNameChanged = (action, state) => {
    return update(state, {
        ownMachineEdit: {
            parameters: {
                [action.index]: {
                    name: {
                        $set: action.name,
                    },
                },
            },
        },
    });
};

const machineParameterValueChanged = (action, state) => {
    return update(state, {
        ownMachineEdit: {
            parameters: {
                [action.index]: {
                    value: {
                        $set: action.value,
                    },
                },
            },
        },
    });
};

const machineParameterUnitChanged = (action, state) => {
    return update(state, {
        ownMachineEdit: {
            parameters: {
                [action.index]: {
                    unit: {
                        $set: action.unit,
                    },
                },
            },
        },
    });
};

const openAddNewMachineOverlay = (action, state) => {
    const tagHierarchy                = [];
    const clonedOwnEditMachine        = {};
    clonedOwnEditMachine.name         = action.name;
    clonedOwnEditMachine.parameters   = [initialMachineParameter];
    clonedOwnEditMachine.tagHierarchy = tagHierarchy;

    return update(state, {
        ownMachineEdit: {
            $set: clonedOwnEditMachine,
        },
        apiMode:        {
            $set: action.apiMode,
        },
    });
};

const openEditMachineOverlay = (action, state) => {
    const existingMachine              = _.find(state.machines, {
        id: action.machineId,
    });
    const clonedOwnEditMachine         = _.cloneDeep(existingMachine);
    clonedOwnEditMachine.imageUploaded = getImagePathByImageObject(existingMachine?.image);

    return update(state, {
        ownMachineEdit: {
            $set: clonedOwnEditMachine,
        },
        apiMode:        {
            $set: ApiMode.edit,
        },
    });
};

const removeMachineParameter = (action, state) => {
    const clonedEditMachine = _.cloneDeep(state.ownMachineEdit);

    clonedEditMachine.parameters.splice(action.index, 1);

    return update(state, {
        ownMachineEdit: {
            $set: clonedEditMachine,
        },
    });
};

const deleteOwnCompanyMachine = (action, state) => {
    const clonedMachines            = _.cloneDeep(state.machines);
    const machine                   = action.machine;
    const machineListWithoutMachine = clonedMachines.filter((existingMachine) => existingMachine.id !== machine.id);

    const updatedState = update(state, {
        machines: {
            $set: machineListWithoutMachine,
        },
    });

    return updatedState;
};

const removeOwnCompanyMachine = (action, state) => {
    const clonedMachines   = _.cloneDeep(state.machines);
    const removedMachine   = action.machine;
    const currentCompanyId = action.companyId;

    for (const existingMachine of clonedMachines) {
        if (existingMachine.id === removedMachine.id) {
            existingMachine.companies = existingMachine.companies.filter((company) => company.id !== currentCompanyId);

            break;
        }
    }

    const updatedState = update(state, {
        machines: {
            $set: clonedMachines,
        },
    });

    return updatedState;
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case MachineTypes.ABORT_MACHINE_EDIT:                        return abortMachineEdit(action, state);
        case MachineTypes.ADD_CHILDREN_TO_TAG_QUERY:                 return addChildrenToTagQuery(action, state);
        case MachineTypes.ADD_MACHINE_PARAMETER:                     return addMachineParameter(action, state);
        case MachineTypes.ADD_MACHINE_TAG:                           return addMachineTag(action, state);
        case MachineTypes.CHANGE_MACHINE_TAG:                        return changeMachineTag(action, state);
        case MachineTypes.DELETE_MACHINE_TAG:                        return deleteMachineTag(action, state);
        case MachineTypes.EDIT_MACHINE_IMAGE:                        return editMachineImage(action, state);
        case CompanyTypes.DELETE_OWN_COMPANY_MACHINE_SUCCEEDED:      return deleteOwnCompanyMachine(action, state);
        case CompanyTypes.REMOVE_OWN_COMPANY_MACHINE_SUCCEEDED:      return removeOwnCompanyMachine(action, state);
        case MachineTypes.FETCH_MACHINES_SUCCEEDED:                  return fetchMachineSucceeded(action, state);
        case MachineTypes.FETCH_MACHINES_BY_COMPANY_SUCCEEDED:       return fetchMachinesByCompanySucceeded(action, state);
        case MachineTypes.IS_MANUFACTURER_CHANGED:                   return isManufacturerChanged(action, state);
        case MachineTypes.MACHINE_CATEGORY_CHANGED:                  return machineCategoryChanged(action, state);
        case MachineTypes.MACHINE_DESCRIPTION_CHANGED:               return machineDescriptionChanged(action, state);
        case MachineTypes.MACHINE_NAME_CHANGED:                      return machineNameChanged(action, state);
        case MachineTypes.MACHINE_CAPACITY_CHANGED:                  return machineCapacityChanged(action, state);
        case MachineTypes.MACHINE_PARAMETER_NAME_CHANGED:            return machineParameterNameChanged(action, state);
        case MachineTypes.MACHINE_PARAMETER_VALUE_CHANGED:           return machineParameterValueChanged(action, state);
        case MachineTypes.MACHINE_PARAMETER_UNIT_CHANGED:            return machineParameterUnitChanged(action, state);
        case MachineTypes.OPEN_ADD_NEW_MACHINE_OVERLAY:              return openAddNewMachineOverlay(action, state);
        case MachineTypes.OPEN_EDIT_MACHINE_OVERLAY:                 return openEditMachineOverlay(action, state);
        case MachineTypes.REMOVE_MACHINE_PARAMETER:                  return removeMachineParameter(action, state);
        default:                                                     return state;
        // @formatter:on
    }
}
