//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiConstants from '@/constants/Api';
import _            from 'lodash';

class Url {
    static openInNewTab(url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    static openMailTo(url, subject = null, body = null) {
        const mailToUrl       = `mailto:${url}`;
        const parameters      = {
            subject,
            body,
        };
        const parameterString = `${Object.entries(parameters).map(([key, value]) => `${key}=${value}`).join('&')}`;
        window.location.href  = `${mailToUrl}?${parameterString}`;
    }

    static getBackendUrl(url) {
        return ApiConstants.getBackendUrl() + url;
    }

    static getAbsoluteUrl(url) {
        if (_.startsWith(url, 'http')) {
            return url;
        }

        return this.getBackendUrl(url);
    }

    static resizedImage(path, width = null, height = null, minWidth = null, minHeight = null, extension = null) {
        const backendUrl              = ApiConstants.getBackendUrl();
        let completeFilePath          = path;
        let filePathWithoutBackendUrl = path;

        if (
            !_.startsWith(completeFilePath, backendUrl) &&
            !_.startsWith(completeFilePath, 'http')
        ) {
            completeFilePath = ApiConstants.getBackendUrl() + path;
        } else {
            const parsedUrl                  = new URL(path);
            const { pathname, search, hash } = parsedUrl;
            filePathWithoutBackendUrl        = `${pathname}${search}${hash}`;
        }

        const additionalParams = {
            width,
            height,
            minWidth,
            minHeight,
            extension,
            origin: completeFilePath,
            seed:   filePathWithoutBackendUrl,
        };
        const url              = new URL('/minify-image', backendUrl);
        const params           = new URLSearchParams();

        Object.keys(additionalParams).forEach((key) => {
            if (additionalParams[key]) {
                params.append(key, additionalParams[key]);
            }
        });

        return `${url}?${params.toString()}`;
    }
}

export default Url;
