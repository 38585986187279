//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import _         from 'lodash';
import PropTypes from '@/components/PropTypes';
import Tab       from '@/components/stateless/atomic/Tab';
import styles    from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state      = {
            indicatorWidth:    0,
            indicatorPosition: 0,
        };
        this.tabRef     = React.createRef();
        this.references = this.props.tabs.map(() => React.createRef());
    }

    componentDidMount() {
        this.calculateIndicator(0);
    }

    onTabClicked = (index) => (event) => {
        this.calculateIndicator(index);
        this.props.changeActiveTabIndex(index)(event);
    };

    calculateIndicator = (index) => {
        const { tabRef, references } = this;

        if (
            tabRef &&
            tabRef.current &&
            references &&
            references[index]
        ) {
            const { current }     = tabRef;
            const parentRectangle = current.getBoundingClientRect();
            const rectangle       = references[index].current.getBoundingClientRect();

            this.setState({
                indicatorWidth:    rectangle.width,
                indicatorPosition: rectangle.x - parentRectangle.x,
            });
        }
    };

    render() {
        return (
            <div
                ref={this.tabRef}
                className={styles.tabBarWrapper}
            >
                <div
                    className={styles.tabBarIndicator}
                    style={{
                        left:  this.state.indicatorPosition,
                        width: this.state.indicatorWidth,
                    }}
                />
                {this.props.tabs.map(this.renderTab)}
            </div>
        );
    }

    renderTab = (tab, index) => {
        return (
            <Tab
                ref={this.references[index]}
                tab={tab}
                key={`tab-${index}`}
                active={this.props.activeTabIndex === index}
                onClick={this.onTabClicked(index)}
            />
        );
    };
}

export const TabBar = Component;

Component.propTypes = {
    activeTabIndex:       PropTypes.number,
    changeActiveTabIndex: PropTypes.func,
    contentRight:         PropTypes.children,
    tabs:                 PropTypes.array,
};

Component.defaultProps = {
    activeTabIndex:       0,
    changeActiveTabIndex: _.noop,
    contentRight:         null,
    tabs:                 [],
};

export default Component;
