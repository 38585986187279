//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import PropTypes from '@/components/PropTypes';
import Link      from '@/components/stateless/atomic/Link';
import styles    from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const wrapperClass = styles.sideMenuFooterButtonWrapper;
        const content      = this.renderContent();

        if (this.props.onClick) {
            return (
                <div
                    className={wrapperClass}
                    onClick={this.props.onClick}
                >
                    {content}
                </div>
            );
        }

        if (this.props.to) {
            return (
                <Link
                    className={wrapperClass}
                    to={this.props.to}
                >
                    {content}
                </Link>
            );
        }

        console.warn('Please pass "to" or "onClick" to render SideMenuFooterButton');

        return null;
    }

    renderContent = () => {
        return (
            <span className={styles.sideMenuFooterButtonTitle}>
                {this.props.title}
            </span>
        );
    };
}

export const SideMenuFooterButton = Component;

Component.propTypes = {
    active:  PropTypes.bool,
    onClick: PropTypes.func,
    title:   PropTypes.string,
    to:      PropTypes.to,
};

Component.defaultProps = {
    active:  false,
    onClick: null,
    title:   null,
    to:      null,
};

export default Component;
