//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }       from 'connected-react-router';
import { put }        from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import _              from 'lodash';

import * as Api                    from '@/api';
import Overlays                    from '@/constants/Overlays';
import { OverlayManager }          from '@/components/connected/OverlayManager';
import StateHelper                 from '@/helper/State';
import { CompanyFactSheetActions } from '@/store/actions/companyFactSheet';
import { CompanyFactSheetTypes }   from '@/store/actions/companyFactSheet';
import FactSheetEditType           from '@/constants/FactSheetEditType';
import { CompanyActions }          from '@/store/actions/company';
import FactSheetFactTagList        from '@/constants/FactSheetFactTagList';
import { getError }                from '@/constants/Errors';
import SagaStateHelper             from '@/helper/SagaStateHelper';
import { AlertBoxActions }         from '@/store/actions/alertBox';
import I18n                        from 'i18next';

function* openEditOverlay(action) {
    const { editType, productIndex } = action;
    const state                      = yield select((reduxState) => reduxState);
    const companyId                  = StateHelper.getCompanyId(state);
    const factSheet                  = StateHelper.getCompanyValue(state, companyId, 'factSheet', {});

    yield put(CompanyFactSheetActions.loadEditContext({
        factSheet,
        editType,
        productIndex,
    }));

    yield put(push(OverlayManager.getPathForOverlayKey(Overlays.editFactSheetOverlay)));
}

function* updateFactSheetSucceeded() {
    const state = yield select((reduxState) => reduxState);
    const id    = StateHelper.getCompanyId(state);

    yield put(CompanyActions.fetchCompany({
        id,
    }));
}

function* updateFactSheetFailed(action) {
    const error       = _.get(action, 'error', null);
    const messageKey  = _.get(error, 'messageKey', 'updateFactSheetFailed');
    const state       = yield SagaStateHelper.getState();
    const userCompany = yield StateHelper.getUserCompany(state);

    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(
            messageKey,
            {
                amount: _.get(userCompany, 'maximumFactSheetProductCount', 0),
            },
        ),
    }));
}

function mapIndustryRatio(factSheet) {
    const mappedFactSheet        = _.cloneDeep(factSheet);
    const industryRatioEditTypes = [
        FactSheetEditType.exportRatio,
        FactSheetEditType.revenueDistribution,
    ];

    _.forEach(
        industryRatioEditTypes,
        (editType) => {
            const mappedIndustryRatio = _.map(
                mappedFactSheet[editType],
                (industryRatio) => {
                    const industry = _.get(industryRatio, 'industry.iri', null);

                    if (!industry) {
                        return null;
                    }

                    return {
                        ...industryRatio,
                        industry,
                        id: _.get(industryRatio, 'iri', null),
                    };
                },
            );

            mappedFactSheet[editType] = {
                ..._.compact(mappedIndustryRatio),
            };
        },
    );

    return mappedFactSheet;
}

function mapCertificateFiles(certificateFiles) {
    return _.map(
        certificateFiles,
        (certificateFile) => {
            return {
                ...certificateFile,
                tag: _.get(certificateFile, 'tag.iri', null),
                id:  _.get(certificateFile, 'iri', null),
            };
        },
    );
}

function mapFacts(factSheet) {
    const mappedFactSheet        = _.cloneDeep(factSheet);
    const tagList                = FactSheetFactTagList;
    const mappedTags             = {};
    const mappedCertificateFiles = mapCertificateFiles(mappedFactSheet[FactSheetEditType.facts].certificateFiles);

    _.forEach(tagList, (fieldName) => {
        mappedTags[fieldName] = _.map(
            mappedFactSheet[FactSheetEditType.facts][fieldName],
            (tag) => {
                return _.get(tag, 'iri', tag);
            },
        );
    });

    mappedFactSheet[FactSheetEditType.facts] = {
        ...mappedFactSheet[FactSheetEditType.facts],
        ...mappedTags,
        certificateFiles: mappedCertificateFiles,
        id:               _.get(factSheet, [FactSheetEditType.facts, 'iri'], null),
    };

    return mappedFactSheet;
}

function mapProducts(factSheet) {
    const mappedFactSheet = _.cloneDeep(factSheet);

    mappedFactSheet[FactSheetEditType.products] = _.map(
        mappedFactSheet[FactSheetEditType.products],
        (product) => {
            const id            = _.get(product, 'iri', null);
            const mappedProduct = {
                ...product,
                id,
            };

            const image = mappedProduct.image;

            if (!_.isString(image) && id) {
                delete mappedProduct.image;
            }

            return mappedProduct;
        },
    );

    return mappedFactSheet;
}

function mapDevelopmentRevenue(factSheet) {
    const mappedFactSheet                                 = _.cloneDeep(factSheet);
    const mappedDevelopmentRevenues                       = _.map(
        mappedFactSheet[FactSheetEditType.developmentRevenue],
        (developmentRevenue) => {
            const id                             = _.get(developmentRevenue, 'iri', null);
            const label                          = _.get(developmentRevenue, 'label', null);
            const value                          = _.get(developmentRevenue, 'value', 0.0);
            const mappedDevelopmentRevenueObject = {
                ...developmentRevenue,
                value: parseFloat(value),
                id,
            };

            if (!label) {
                return null;
            }

            return mappedDevelopmentRevenueObject;
        },
    );
    mappedFactSheet[FactSheetEditType.developmentRevenue] = _.compact(mappedDevelopmentRevenues);

    return mappedFactSheet;
}

function* updateFactSheet() {
    const state             = yield select((reduxState) => reduxState);
    const factSheet         = _.get(state, 'companyFactSheet');
    const companyIri        = StateHelper.getCurrentCompanyIri(state);
    let clonedFactSheet     = _.cloneDeep(factSheet);
    clonedFactSheet         = mapIndustryRatio(clonedFactSheet);
    clonedFactSheet         = mapFacts(clonedFactSheet);
    clonedFactSheet         = mapProducts(clonedFactSheet);
    clonedFactSheet         = mapDevelopmentRevenue(clonedFactSheet);
    clonedFactSheet.company = companyIri;
    const response          = yield call(
        Api.updateFactSheet,
        factSheet.iri,
        clonedFactSheet,
    );

    if (response.ok) {
        yield put(CompanyFactSheetActions.updateFactSheetSucceeded());
    } else {
        const error = getError(response);

        yield put(CompanyFactSheetActions.updateFactSheetFailed({
            error,
        }));
    }
}

export const callCompanyFactSheetSagas = () => {
    return [
        // @formatter:off
        takeLatest([CompanyFactSheetTypes.OPEN_EDIT_OVERLAY],           openEditOverlay),
        takeLatest([CompanyFactSheetTypes.UPDATE_FACT_SHEET],           updateFactSheet),
        takeLatest([CompanyFactSheetTypes.UPDATE_FACT_SHEET_SUCCEEDED], updateFactSheetSucceeded),
        takeLatest([CompanyFactSheetTypes.UPDATE_FACT_SHEET_FAILED],    updateFactSheetFailed),
        // @formatter:on
    ];
};
