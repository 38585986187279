//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';

import ComponentHelper    from '@/helper/ComponentHelper';
import { CompanyActions } from '@/store/actions/company';
import Navigation         from '@/helper/Navigation';
import PropTypes          from '@/components/PropTypes';
import _                  from 'lodash';

export default function withFetchCompany(WrappedComponent) {
    class Component extends React.Component {
        componentDidMount() {
            this.fetchCompany();
        }

        fetchCompany = () => {
            const id = this.props.currentCompanyId;

            if (!id) {
                return;
            }

            if (id === this.props.currentCompanyIdInState) {
                return;
            }

            this.props.setCurrentCompany({
                id,
            });
        };

        componentWillUnmount() {
        }

        shouldComponentUpdate(nextProps, nextState) {
            return ComponentHelper.shouldComponentUpdate(
                this,
                nextProps,
                nextState,
            );
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                />
            );
        }
    }

    Component.propTypes = {
        setCurrentCompany:       PropTypes.func,
        currentCompanyId:        PropTypes.string,
        currentCompanyIdInState: PropTypes.string,
    };

    Component.defaultProps = {
        setCurrentCompany:       _.noop,
        currentCompanyId:        null,
        currentCompanyIdInState: null,
    };

    Component.renderAffectingProps = Object.keys(Component.defaultProps);

    Component.renderAffectingStates = [];

    const mapDispatchToProps = (dispatch) => bindActionCreators(
        {
            ...CompanyActions,
        },
        dispatch,
    );

    const mapStateToProps = (state, ownProps) => {
        const currentCompanyUrl = Navigation.getParameterFromUrl(ownProps, 'companyId');
        const currentCompanyId  = Navigation.getCompanyIdFromUrl(ownProps);

        return {
            currentCompanyIdInState: state.company.currentCompanyId,
            currentCompanyId,
            currentCompanyUrl,
        };
    };

    return compose(connect(
        mapStateToProps,
        mapDispatchToProps,
    ))(Component);
}
