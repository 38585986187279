//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import I18n       from 'i18next';
import classNames from 'classnames';

import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import ProjectStatus   from '@/constants/ProjectStatus';

import styles from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { status }           = this.props;
        const statusValueClassname = classNames(
            styles.statusValue,
            {
                [styles.colorBlack]:  status === ProjectStatus.OPEN,
                [styles.colorOrange]: status === ProjectStatus.INTERESTED,
                [styles.colorRed]:    status === ProjectStatus.DECLINED,
            },
        );

        return (
            <div className={styles.status}>
                <span className={styles.statusLabel}>
                    {I18n.t('status')}
                </span>
                <span
                    className={statusValueClassname}
                >
                    {I18n.t(status)}
                </span>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Status = Component;

Component.propTypes = {
    status: PropTypes.string,
};

Component.defaultProps = {
    status: '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
