//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React             from 'react';
import { PhotoProvider } from 'react-photo-view';
import { PhotoView }     from 'react-photo-view';
import _                 from 'lodash';
import ComponentHelper   from '@/helper/ComponentHelper';
import PropTypes         from '@/components/PropTypes';

import 'react-photo-view/dist/react-photo-view.css';
import styles            from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { images } = this.props;

        return (
            <PhotoProvider
                maskOpacity={0.5}
                className={styles.imagePreviewPhotoProvider}
            >
                <div className={styles.imagePreview}>
                    {images.map((image) => {
                        const src     = _.get(image, 'src', image);
                        const altText = _.get(image, 'alt', '');

                        return (
                            <PhotoView
                                key={src}
                                src={src}
                            >
                                <div className={styles.imagePreviewImageWrapper}>
                                    <img
                                        className={styles.imagePreviewImage}
                                        src={src}
                                        alt={altText}
                                    />
                                </div>
                            </PhotoView>
                        );
                    })}
                </div>
            </PhotoProvider>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ImagePreview = Component;

Component.propTypes = {
    images: PropTypes.array,
};

Component.defaultProps = {
    images: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
