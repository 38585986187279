//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import { connect }     from 'react-redux';
import { withRouter }  from 'react-router';
import PropTypes       from '@/components/PropTypes';
import StatelessFooter from '@/components/stateless/composed/Footer';
import Route           from '@/helper/Route';

export class Component extends React.Component {
    render() {
        if (!this.props.isFactSheetPrintRoute) {
            return (
                <StatelessFooter />
            );
        }

        return null;
    }
}

export const Footer = Component;

Component.propTypes = {
    isFactSheetPrintRoute: PropTypes.bool,
};

Component.defaultProps = {
    isFactSheetPrintRoute: false,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => {
    const pathname = _.get(state, 'router.location.pathname');

    return {
        isFactSheetPrintRoute: Route.isFactsheetPrintRoute(pathname),
    };
};

export default withRouter(connect(
    mapStateToProps,
    null,
)(Component));
