//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import I18n            from 'i18next';
import { Trans }       from 'react-i18next';
import ComponentHelper from '@/helper/ComponentHelper';
import HeadlineMedium  from '@/components/stateless/atomic/HeadlineMedium';
import Overlay         from '@/components/connected/Overlay';
import Overlays        from '@/constants/Overlays';
import OverlayType     from '@/components/connected/Overlay/OverlayType';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <Overlay
                id={Overlays.removeMachine}
                okButtonPressed={this.props.okButtonPressed}
                okButtonText={I18n.t('remove')}
                closeButtonPressed={this.props.closeButtonPressed}
                overlayType={OverlayType.prompt}
            >
                {this.renderHeader()}
                {this.renderBody()}
            </Overlay>
        );
    }

    renderHeader = () => {
        return (
            <div className={styles.removeMachineHeaderContainer}>
                <HeadlineMedium text={I18n.t('removeMachineTitle')} />
            </div>
        );
    };

    renderBody = () => {
        return (
            <div className={styles.removeMachineBodyContainer}>
                <Trans
                    i18nKey={'removeMachineText'}
                    values={{
                        machineName: this.props.machineName,
                    }}
                    components={{
                        bold: <strong />,
                    }}
                />
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const RemoveMachineOverlay = Component;

Component.propTypes = {
    closeButtonPressed: PropTypes.func,
    machineName:        PropTypes.string,
    okButtonPressed:    PropTypes.func,
};

Component.defaultProps = {
    closeButtonPressed: _.noop,
    machineName:        null,
    okButtonPressed:    _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
