//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import HydraHelper           from '@/helper/Hydra';
import { MembershipActions } from '@/store/actions/membership';
import { call }              from 'redux-saga/effects';
import { put }               from 'redux-saga/effects';
import { takeLatest }        from 'redux-saga/effects';
import * as Api              from '@/api';
import { MembershipTypes }   from '@/store/actions/membership';

function* fetchMembership() {
    const response = yield call(Api.context.membership.fetchMembership);

    if (response.ok) {
        yield put(MembershipActions.fetchMembershipsSucceeded({
            options: HydraHelper.getMembersFromResponse(response.data),
        }));
    } else {
        yield put(MembershipActions.fetchMembershipsFailed());
    }
}

export const callMembershipSaga = () => {
    return [
        // @formatter:off
        takeLatest([MembershipTypes.FETCH_MEMBERSHIP], fetchMembership),
        // @formatter:on
    ];
};
