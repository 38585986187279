//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import Company      from '@/helper/Company';
import PropTypes    from '@/components/PropTypes';
import CompanyTypes from '@/constants/CompanyTypes';
import styles       from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const text = Company.getCompanyTypesString(this.props.companyTypes);

        return (
            <span className={styles.companyTypeLabel}>
                {text}
            </span>
        );
    }
}

export const CompanyTypeLabel = Component;

Component.propTypes = {
    companyTypes: PropTypes.arrayOf(PropTypes.oneOfObjectValues(CompanyTypes)),
};

Component.defaultProps = {
    companyTypes: null,
};

export default Component;
