//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { MembershipActions as MembershipAction } from '@/store/actions/membership';
import { PartnerActions }                        from '@/store/actions/partner';
import { callPartnerSaga }                       from '@/store/sagas/partner';
import { all }                                   from 'redux-saga/effects';
import { takeLatest }                            from 'redux-saga/effects';
import { put }                                   from 'redux-saga/effects';
import { REHYDRATE }                             from 'redux-persist';
import { LOCATION_CHANGE }                       from 'connected-react-router';

// Types
import { LoadingTypes }    from '@/store/actions/loading';
import { NavigationTypes } from '@/store/actions/navigation';

// Actions
import { TagActions } from '@/store/actions/tag';

// Types
import TagTypeDropDown from '@/constants/TagTypeDropDown';

// Sagas
import { UserActions }                    from '@/store/actions/user';
import { callFavoriteSagas }              from '@/store/sagas/favorite';
import NavigationSagas                    from './navigation';
import LoadingSagas                       from './loading';
import UserSagas                          from './user';
import { callUserSagas }                  from './user';
import LostPasswordSagas                  from './lostPassword';
import { callLostPasswordSagas }          from './lostPassword';
import { callMachineSagas }               from './machine';
import { callMembershipSaga }             from './membership';
import { callOverlaySagas }               from './overlay';
import { callProductSagas }               from './product';
import { callSearchSagas }                from './search';
import { callSignupSagas }                from './signup';
import { callStagingAuthenticationSagas } from './stagingAuthentication';
import { callTagSagas }                   from './tag';
import { callMessageRequestSagas }        from './messageRequest';
import { callMembershipRequestSaga }      from './membershipRequest';
import { callProjectsSagas }              from './projects';
import { callProfileSagas }               from './profile';
import { callCompanySagas }               from './company';
import { callCompanyTypeSagas }           from './companyType';
import { callCompanyFactSheetSagas }      from './companyFactSheet';
import { callAlertSagas }                 from './alert';

function* preLoading() {
    console.log('Preload');
    yield put(UserActions.setActPrivateChecked({
        checked: false,
    }));
    yield put(TagActions.fetchRootTags());
    yield put(UserActions.fetch());
    yield put(MembershipAction.fetchMemberships());
    yield put(PartnerActions.fetchPartners());

    for (const type of Object.values(TagTypeDropDown)) {
        yield put(TagActions.fetchTagsForDropdown({
            target: type,
        }));
    }
}

function* rehydrationDone() {
    yield preLoading();
}

const callLoadingSagas = () => {
    return [
        // @formatter:off
        takeLatest([LoadingTypes.OVERLAY_CLICKED], LoadingSagas.overlayClicked),
        // @formatter:on
    ];
};

const callNavigationSagas = () => {
    return [
        // @formatter:off
        takeLatest([NavigationTypes.OPEN_URL], NavigationSagas.openUrl),
        // @formatter:on
    ];
};

const callLocationChangeSagas = () => {
    return [
        // @formatter:off
        takeLatest([LOCATION_CHANGE], NavigationSagas.locationChange),
        takeLatest([LOCATION_CHANGE], UserSagas.restoreToken),
        // @formatter:on
    ];
};

const callRehydrateSagas = () => {
    return [
        // @formatter:off
        takeLatest([REHYDRATE], LostPasswordSagas.restorePasswordToken),
        takeLatest([REHYDRATE], UserSagas.restoreToken),
        takeLatest([REHYDRATE], rehydrationDone),
        // @formatter:on
    ];
};

function* root() {
    yield all([
        ...callAlertSagas(),
        ...callProfileSagas(),
        ...callProjectsSagas(),
        ...callCompanySagas(),
        ...callCompanyTypeSagas(),
        ...callCompanyFactSheetSagas(),
        ...callMachineSagas(),
        ...callMembershipSaga(),
        ...callPartnerSaga(),
        ...callProductSagas(),
        ...callMessageRequestSagas(),
        ...callMembershipRequestSaga(),
        ...callUserSagas(),
        ...callLostPasswordSagas(),
        ...callSignupSagas(),
        ...callOverlaySagas(),
        ...callSearchSagas(),
        ...callFavoriteSagas(),
        ...callStagingAuthenticationSagas(),
        ...callTagSagas(),
        ...callLoadingSagas(),
        ...callNavigationSagas(),
        ...callLocationChangeSagas(),
        ...callRehydrateSagas(),
    ]);
}

export default {
    root,
};
