//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React              from 'react';
import ComponentHelper    from '@/helper/ComponentHelper';
import { IconTextButton } from '@/components/stateless/atomic/IconTextButton';
import IconType           from '@/components/stateless/atomic/Icon/IconType';

import PropTypes      from '@/components/PropTypes';
import _              from 'lodash';
import moment         from 'moment';
import { IconButton } from '@/components/stateless/atomic/IconButton';
import File           from '@/helper/File';
import styles         from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    uploadButtonClicked = () => {
        this.inputRef.current.click();
    };

    onFileUploaded = async (event) => {
        const { onFileChanged } = this.props;
        const file              = event.target.files[0];
        const fileAsUrl         = await File.getFileFromBlob(file);
        const newFile           = {
            name:             file.name,
            lastModifiedDate: file.lastModifiedDate,
            size:             file.size,
            file:             fileAsUrl,
        };

        onFileChanged(newFile);
    };

    onFileRemoved = () => {
        const { onFileChanged } = this.props;

        this.inputRef.current.value = '';

        onFileChanged(null);
    };

    renderUploadButton = () => {
        if (this.props.file) {
            return null;
        }

        return (
            <IconTextButton
                disabled={this.props.disabled}
                onClick={this.uploadButtonClicked}
                text={'Upload'}
                iconType={IconType.upload}
            />
        );
    };

    renderUploadedFile = () => {
        const { file, title } = this.props;

        if (!file) {
            return null;
        }

        const fileSizeInMb = (
            file
                ? (
                    file.size / 1000 / 1000
                ).toFixed(1)
                : 0
        );
        const fileDate     = moment(file.createdAt).format('MMMM Do YYYY');
        const detailString = `${fileDate}${file.size ? `, ${fileSizeInMb} MB` : null}`;
        const filename     = title ?? file.name;

        return (
            <div className={styles.tagCertificateFile}>
                <div
                    className={styles.tagCertificateFileInformation}
                    onClick={this.props.onFileClicked}
                >
                    <div>
                        {filename}
                    </div>
                    <div>
                        {detailString}
                    </div>
                </div>
                <IconButton
                    iconType={IconType.deleteCircle}
                    onClick={this.onFileRemoved}
                />
            </div>
        );
    };

    render() {
        return (
            <div className={styles.tagCertificateFileInput}>
                <input
                    ref={this.inputRef}
                    type="file"
                    onChange={this.onFileUploaded}
                />
                {this.renderUploadButton()}
                {this.renderUploadedFile()}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const TagCertificateFileInput = Component;

Component.propTypes = {
    onFileChanged: PropTypes.func,
    disabled:      PropTypes.bool,
    file:          PropTypes.object,
    title:         PropTypes.string,
    onFileClicked: PropTypes.func,
};

Component.defaultProps = {
    onFileChanged: _.noop,
    disabled:      false,
    file:          null,
    title:         '',
    onFileClicked: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
