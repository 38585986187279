//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import I18n                   from 'i18next';
import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import { Table }              from '@/components/stateless/atomic/Table';
import { TableBodyColumn }    from '@/components/stateless/atomic/TableBodyColumn';
import { TableBodyRow }       from '@/components/stateless/atomic/TableBodyRow';
import { TableHeader }        from '@/components/stateless/atomic/TableHeader';
import { TableHeaderColumn }  from '@/components/stateless/atomic/TableHeaderColumn';
import { TableHeaderRow }     from '@/components/stateless/atomic/TableHeaderRow';
import IconTextButton         from '@/components/stateless/atomic/IconTextButton';
import Spacer                 from '@/components/stateless/atomic/Spacer';
import IconType               from '@/components/stateless/atomic/Icon/IconType';
import IconTextButtonPosition from '@/components/stateless/atomic/IconTextButton/IconTextButtonPosition';
import styles                 from './styles.module.scss';

export class Component extends React.Component {
    renderJoinRequestBodyRow = (joinRequestUser) => {
        if (!joinRequestUser) {
            return null;
        }

        const id    = _.get(joinRequestUser, 'id');
        const name  = _.get(joinRequestUser, 'name');
        const email = _.get(joinRequestUser, 'email');

        return (
            <TableBodyRow
                key={id}
            >
                <TableBodyColumn>
                    {name}
                </TableBodyColumn>
                <TableBodyColumn>
                    {email}
                </TableBodyColumn>
                <TableBodyColumn className={styles.tableButtonColumn}>
                    <div className={styles.tableButtonColumnChildren}>
                        <IconTextButton
                            active={true}
                            iconPosition={IconTextButtonPosition.left}
                            iconType={IconType.check}
                            onClick={this.props.onJoinRequestAccept(joinRequestUser)}
                            text={I18n.t('accept')}
                        />
                        <Spacer width={10} />
                        <IconTextButton
                            iconPosition={IconTextButtonPosition.left}
                            iconType={IconType.cross}
                            onClick={this.props.onJoinRequestDecline(joinRequestUser)}
                            text={I18n.t('decline')}
                        />
                    </div>
                </TableBodyColumn>
            </TableBodyRow>
        );
    };

    renderJoinRequestBodyRows = () => {
        const joinRequests = _.get(this.props, 'joinRequests', []);

        return _.map(
            joinRequests,
            this.renderJoinRequestBodyRow,
        );
    };

    renderNoJoinRequestsRow = () => {
        const joinRequests = _.get(this.props, 'joinRequests', []);

        if (_.size(joinRequests) > 0) {
            return null;
        }

        return (
            <div className={styles.noJoinRequestsRow}>
                {I18n.t('noJoinRequests')}
            </div>
        );
    };

    render() {
        return (
            <>
                <Table>
                    <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderColumn>
                                {I18n.t('name')}
                            </TableHeaderColumn>
                            <TableHeaderColumn>
                                {I18n.t('emailAddress')}
                            </TableHeaderColumn>
                            <TableHeaderColumn>
                                {I18n.t('actions')}
                            </TableHeaderColumn>
                        </TableHeaderRow>
                    </TableHeader>
                    {this.renderJoinRequestBodyRows()}
                </Table>
                {this.renderNoJoinRequestsRow()}
            </>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const CompanyJoinRequestTable = Component;

Component.propTypes = {
    joinRequests:         PropTypes.array,
    onJoinRequestAccept:  PropTypes.func,
    onJoinRequestDecline: PropTypes.func,
};

Component.defaultProps = {
    joinRequests:         [],
    onJoinRequestAccept:  _.noop,
    onJoinRequestDecline: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
