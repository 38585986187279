//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import _                from 'lodash';
import I18n             from 'i18next';
import CallToActionBox  from '@/components/stateless/composed/CallToActionBox';
import ColorButton      from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import PropTypes        from '@/components/PropTypes';
import { TestIds }      from '@/constants/TestIds';

export class Component extends React.Component {
    render() {
        return (
            <CallToActionBox
                title={I18n.t(
                    'noMachineFoundTitle',
                    {
                        machineName: this.props.productName,
                    },
                )}
                text={I18n.t('noMachineFoundText')}
            >
                <ColorButton
                    test-id={TestIds.addNewProductButton}
                    onClick={this.props.onOpenAddNewMachineClicked}
                    text={I18n.t('addNewMachine')}
                    theme={ColorButtonTheme.orange}
                />
            </CallToActionBox>
        );
    }
}

export const AddNewMachineCTA = Component;

Component.propTypes = {
    onOpenAddNewMachineClicked: PropTypes.func,
    productName:                PropTypes.string,
};

Component.defaultProps = {
    onOpenAddNewMachineClicked: _.noop,
    productName:                null,
};

export default Component;
