//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import classNames      from 'classnames';
import CurrencyInput   from 'react-currency-input-field';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import TextInputSize   from '@/components/stateless/atomic/TextInput/TextInputSize';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    onValueChange = (event) => {
        this.props.onValueChange(event);
    };

    render() {
        const className = classNames(
            styles.textCurrencyInput,
            {
                [styles.textCurrencyInputMultiline]:           this.props.size !== TextInputSize.default,
                [styles.textCurrencyInputMultilineLarge]:      this.props.size === TextInputSize.multilineLarge,
                [styles.textCurrencyInputMultilineExtraLarge]: this.props.size === TextInputSize.multilineExtraLarge,
            },
        );
        const props     = this.props;
        const value     = props.value ?? 0.0;

        return (
            <CurrencyInput
                className={className}
                decimalScale={props.decimalScale}
                intlConfig={props.intlConfig}
                onValueChange={this.onValueChange}
                prefix={props.prefix}
                value={value}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const TextCurrencyInput = Component;

Component.propTypes = {
    decimalScale:  PropTypes.number,
    intlConfig:    PropTypes.object,
    onValueChange: PropTypes.func,
    size:          PropTypes.oneOfObjectValues(TextInputSize),
    prefix:        PropTypes.string,
    value:         PropTypes.number,
};

Component.defaultProps = {
    decimalScale:  0,
    intlConfig:    {
        'locale':   'en-US',
        'currency': 'USD',
    },
    onValueChange: _.noop,
    size:          TextInputSize.default,
    prefix:        '$',
    value:         0.0,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
