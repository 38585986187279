//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import _                from 'lodash';
import classNames       from 'classnames';
import I18n             from 'i18next';
import * as Api         from '@/api';
import Avatar           from '@/components/stateless/atomic/Avatar';
import ComponentHelper  from '@/helper/ComponentHelper';
import EntityLabelSize  from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import IconType         from '@/components/stateless/atomic/Icon/IconType';
import Link             from '@/components/stateless/atomic/Link';
import PropTypes        from '@/components/PropTypes';
import Route            from '@/helper/Route';
import Routes           from '@/constants/Routes';
import { BadgeCount }   from '@/components/stateless/atomic/BadgeCount';
import { Icon }         from '@/components/stateless/atomic/Icon';
import { ColorBox }     from '@/components/stateless/atomic/ColorBox';
import ColorButtonTheme from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import ColorButtonSize  from '@/components/stateless/atomic/ColorButton/ColorButtonSize';
import { ColorButton }  from '@/components/stateless/atomic/ColorButton';
import ColorBoxColor    from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import { Spacer }       from '@/components/stateless/atomic/Spacer';
import styles           from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const company = _.get(this.props, 'user.company');

        return (
            <div
                className={classNames(
                    styles.headerUserButton,
                    {
                        [styles.noCompany]: !company,
                    },
                )}
            >
                {this.renderUserButton(company)}
                {this.renderBadgeCount()}
                {this.renderDropDown()}
            </div>
        );
    }

    renderDropDown() {
        if (!this.props.isLoggedIn) {
            return null;
        }

        return (
            <div className={styles.headerUserButtonDropDown}>
                <ColorBox color={ColorBoxColor.grayLight}>
                    <ColorButton
                        text={I18n.t('myProfile')}
                        theme={ColorButtonTheme.orange}
                        size={ColorButtonSize.medium}
                        uppercase={true}
                        onClick={this.props.onMyProfileClicked}
                    />
                    <Spacer height={10} />
                    <ColorButton
                        text={I18n.t('logout')}
                        theme={ColorButtonTheme.orange}
                        size={ColorButtonSize.medium}
                        uppercase={true}
                        onClick={this.props.onLogoutClicked}
                    />
                </ColorBox>
            </div>
        );
    }

    renderBadgeCount = () => {
        if (this.props.badgeCount && this.props.isLoggedIn) {
            return (
                <div className={styles.headerUserButtonBadge}>
                    <BadgeCount count={this.props.badgeCount} />
                </div>
            );
        }

        return null;
    };

    renderIcon = () => {
        return (
            <div className={styles.headerUserButtonIcon}>
                <Icon iconType={IconType.user} />
            </div>
        );
    };

    renderUserButton = (company) => {
        if (this.props.isLoggedIn) {
            if (company) {
                return (
                    <div className={styles.headerUserButtonAvatar}>
                        <Avatar
                            to={Route.buildRouteForCompany(_.get(this.props, 'company'))}
                            image={Api.getImagePath(_.get(this.props, 'company.logo.path'))}
                            size={EntityLabelSize.small}
                            fallback={_.get(this.props, 'user.name')}
                        />
                    </div>
                );
            }

            return (
                <div className={styles.headerUserButtonAvatar}>
                    <Avatar
                        to={Routes.myProfile}
                        image={Api.getImagePath(_.get(this.props, 'user.image.path'))}
                        size={EntityLabelSize.small}
                        fallback={_.get(this.props, 'user.name')}
                    />
                </div>
            );
        }

        return (
            <Link
                to={Routes.login}
                className={styles.headerUserButtonText}
            >
                {I18n.t('signIn')}
                {this.renderIcon()}
            </Link>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const HeaderUserButton = Component;

Component.propTypes = {
    badgeCount:         PropTypes.number,
    company:            PropTypes.object,
    isLoggedIn:         PropTypes.bool,
    onLogoutClicked:    PropTypes.func,
    onMyProfileClicked: PropTypes.func,
    user:               PropTypes.object,
};

Component.defaultProps = {
    badgeCount:         null,
    company:            null,
    isLoggedIn:         false,
    onLogoutClicked:    _.noop,
    onMyProfileClicked: _.noop,
    user:               null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
