//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                          from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';
import classNames                     from 'classnames';
import ComponentHelper                from '@/helper/ComponentHelper';
import Navigation                     from '@/helper/Navigation';
import PropTypes                      from '@/components/PropTypes';
import styles                         from './styles.module.scss';

export class Component extends React.Component {
    render() {
        if (this.props.to) {
            const toParameter = Navigation.getToFromRouteObject(this.props.to, this.props.search, this.props.hash);

            return (
                <ReactRouterDomLink
                    className={classNames(
                        this.props.className,
                        {
                            [styles.disabledLink]: this.props.disabled,
                        },
                    )}
                    to={toParameter}
                    target={this.props.target}
                >
                    {this.props.children}
                </ReactRouterDomLink>
            );
        }

        if (this.props.onClick) {
            return (
                <button
                    className={this.props.className}
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}
                >
                    {this.props.children}
                </button>
            );
        }

        console.warn('Please pass "to" or "onClick" to render Link');

        return null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Link = Component;

Component.propTypes = {
    children:  PropTypes.children,
    className: PropTypes.string,
    disabled:  PropTypes.bool,
    hash:      PropTypes.string,
    onClick:   PropTypes.func,
    search:    PropTypes.object,
    target:    PropTypes.string,
    to:        PropTypes.to,
};

Component.defaultProps = {
    children:  [],
    className: '',
    disabled:  false,
    hash:      '',
    onClick:   null,
    search:    {},
    target:    null,
    to:        null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
