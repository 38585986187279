//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import I18n       from 'i18next';
import classNames from 'classnames';
import _          from 'lodash';

import { IconTextButton } from '@/components/stateless/atomic/IconTextButton';
import Routes             from '@/constants/Routes';
import IconType           from '@/components/stateless/atomic/Icon/IconType';
import EntityLabelSize    from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import { EntityLabel }    from '@/components/stateless/composed/EntityLabel';
import ProjectStatus      from '@/constants/ProjectStatus';
import ComponentHelper    from '@/helper/ComponentHelper';
import PropTypes          from '@/components/PropTypes';

import styles from './styles.module.scss';

import 'react-datepicker/dist/react-datepicker.css';

export class Match extends React.Component {
    static propTypes = {
        facts:                   PropTypes.arrayOf(PropTypes.object),
        headline:                PropTypes.string,
        image:                   PropTypes.string,
        name:                    PropTypes.string,
        onAcceptProjectClicked:  PropTypes.func,
        onChatClicked:           PropTypes.func,
        onDeclineProjectClicked: PropTypes.func,
        status:                  PropTypes.oneOfObjectValues(ProjectStatus),
        subtitle:                PropTypes.string,
    };

    static defaultProps = {
        facts:                   [],
        headline:                null,
        image:                   null,
        name:                    null,
        onAcceptProjectClicked:  _.noop,
        onChatClicked:           _.noop,
        onDeclineProjectClicked: _.noop,
        status:                  ProjectStatus.OPEN,
        subtitle:                null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    renderStatus = () => {
        const { status }           = this.props;
        const statusValueClassname = classNames(
            styles.statusValue,
            {
                [styles.colorBlack]:  status === ProjectStatus.OPEN,
                [styles.colorOrange]: status === ProjectStatus.INTERESTED,
                [styles.colorRed]:    status === ProjectStatus.REJECTED,
            },
        );

        return (
            <div className={styles.status}>
                <div className={styles.statusLabel}>
                    {I18n.t('status')}
                </div>
                <div
                    className={statusValueClassname}
                >
                    {I18n.t(status)}
                </div>
            </div>
        );
    };

    renderMatch = () => {
        const {
            image,
            name,
            subtitle,
        } = this.props;

        return (
            <div className={styles.match}>
                <EntityLabel
                    title={name}
                    subtitle={subtitle}
                    avatar={image}
                    size={EntityLabelSize.small}
                    iso31661Alpha2CountryCode={'de'}
                />
            </div>
        );
    };

    renderOpenActions = () => {
        return (
            <>
                <IconTextButton
                    iconType={IconType.check}
                    text={I18n.t('accept')}
                    onClick={this.props.onAcceptProjectClicked}
                />
                <IconTextButton
                    iconType={IconType.cross}
                    text={I18n.t('decline')}
                    onClick={this.props.onDeclineProjectClicked}
                />
            </>
        );
    };

    renderInterestedActions = () => {
        return (
            <>
                <IconTextButton
                    iconType={IconType.chat}
                    text={I18n.t('chat')}
                    onClick={this.props.onChatClicked}
                />
                <IconTextButton
                    iconType={IconType.cross}
                    text={I18n.t('decline')}
                    onClick={this.props.onDeclineProjectClicked}
                />
            </>
        );
    };

    renderRejectedActions = () => {
        return (
            <IconTextButton
                to={Routes.screenDesign}
                iconType={IconType.chat}
                disabled={true}
                text={I18n.t('chat')}
            />
        );
    };

    renderActions = () => {
        const { status } = this.props;
        let actions      = null;

        switch (status) {
            case ProjectStatus.OPEN:
                actions = this.renderOpenActions();

                break;
            case ProjectStatus.INTERESTED:
                actions = this.renderInterestedActions();

                break;
            case ProjectStatus.REJECTED:
                actions = this.renderRejectedActions();

                break;
        }

        return (
            <div className={styles.actions}>
                <div className={styles.innerActions}>
                    {actions}
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    {this.renderStatus()}
                </div>
                <div className={styles.middle}>
                    {this.renderMatch()}
                </div>
                <div className={styles.right}>
                    {this.renderActions()}
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default Match;
