//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React              from 'react';
import I18n               from 'i18next';
import ComponentHelper    from '@/helper/ComponentHelper';
import Overlay            from '@/components/connected/Overlay';
import Overlays           from '@/constants/Overlays';
import OverlayType        from '@/components/connected/Overlay/OverlayType';
import _                  from 'lodash';
import PropTypes          from '@/components/PropTypes';
import IconType           from '@/components/stateless/atomic/Icon/IconType';
import ColorBoxColor      from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import IconButtonTheme    from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import { CheckBox }       from '@/components/stateless/atomic/CheckBox';
import { IconButton }     from '@/components/stateless/atomic/IconButton';
import { FormRow }        from '@/components/stateless/composed/FormRow';
import { TextInput }      from '@/components/stateless/atomic/TextInput';
import { HeadlineSmall }  from '@/components/stateless/atomic/HeadlineSmall';
import { ColorBox }       from '@/components/stateless/atomic/ColorBox';
import { HeadlineMedium } from '@/components/stateless/atomic/HeadlineMedium';
import styles             from './styles.module.scss';

export class Component extends React.Component {
    renderParameter = (parameter, index) => {
        const {
            onMachineParameterNameChanged,
            onMachineParameterValueChanged,
            onMachineParameterUnitChanged,
            deleteMachineParameterClicked,
        } = this.props;

        return (
            <div className={styles.addNewMachineInputInnerContainer}>
                <FormRow label={I18n.t('name')}>
                    <TextInput
                        value={parameter.name}
                        onChange={onMachineParameterNameChanged(index)}
                    />
                </FormRow>
                <FormRow label={I18n.t('parameterValue')}>
                    <TextInput
                        value={parameter.value}
                        onChange={onMachineParameterValueChanged(index)}
                    />
                </FormRow>
                <FormRow label={I18n.t('parameterUnit')}>
                    <TextInput
                        value={parameter.unit}
                        onChange={onMachineParameterUnitChanged(index)}
                    />
                </FormRow>
                <IconButton
                    theme={IconButtonTheme.grayRound}
                    iconType={IconType.cross}
                    onClick={deleteMachineParameterClicked(index)}
                />
            </div>
        );
    };

    renderParameters = () => {
        const { isManufacturer } = this.props;

        if (isManufacturer) {
            const {
                addMachineParameterClicked,
                machineParameters,
            } = this.props;

            return (
                <div className={styles.addNewMachineBodyContainer}>
                    <HeadlineSmall
                        text={I18n.t('parameters')}
                        iconType={IconType.addCircle}
                        onClick={addMachineParameterClicked}
                    />
                    <div className={styles.addNewMachineInputContainer}>
                        <ColorBox color={ColorBoxColor.grayLight}>
                            {machineParameters.map(this.renderParameter)}
                        </ColorBox>
                    </div>
                </div>
            );
        }

        return null;
    };

    renderHeader = () => {
        return (
            <div className={styles.addNewMachineHeaderContainer}>
                <HeadlineMedium text={I18n.t('areYouTheManufacturer')} />
            </div>
        );
    };

    render() {
        const {
            okButtonPressed,
            closeButtonPressed,
            isManufacturer,
            isManufacturerChanged,
        } = this.props;

        return (
            <Overlay
                id={Overlays.addNewMachineManufacturer}
                okButtonPressed={okButtonPressed}
                closeButtonPressed={closeButtonPressed}
                okButtonValidator={() => true}
                okButtonText={I18n.t('save')}
                overlayType={OverlayType.full}
                disableCloseOnOutsideClick={true}
            >
                {this.renderHeader()}
                <div className={styles.addNewMachineBodyContainer}>
                    <CheckBox
                        text={I18n.t('iAmTheManufacturerCheckBoxLabel')}
                        checked={isManufacturer}
                        valueChanged={isManufacturerChanged}
                    />
                </div>
                {this.renderParameters()}
            </Overlay>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AddNewMachineManufacturerOverlay = Component;

Component.propTypes = {
    addMachineParameterClicked:     PropTypes.func,
    closeButtonPressed:             PropTypes.func,
    deleteMachineParameterClicked:  PropTypes.func,
    isManufacturer:                 PropTypes.bool,
    isManufacturerChanged:          PropTypes.func,
    machineParameters:              PropTypes.array,
    okButtonPressed:                PropTypes.func,
    onMachineParameterNameChanged:  PropTypes.func,
    onMachineParameterValueChanged: PropTypes.func,
    onMachineParameterUnitChanged:  PropTypes.func,
    updateOrCreateMachine:          PropTypes.func,
};

Component.defaultProps = {
    addMachineParameterClicked:     _.noop,
    closeButtonPressed:             _.noop,
    deleteMachineParameterClicked:  _.noop,
    isManufacturer:                 false,
    isManufacturerChanged:          _.noop,
    machineParameters:              [],
    okButtonPressed:                _.noop,
    onMachineParameterNameChanged:  _.noop,
    onMachineParameterValueChanged: _.noop,
    onMachineParameterUnitChanged:  _.noop,
    updateOrCreateMachine:          _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
