//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { ToastTypes as ToastTypesRedux } from '@/store/actions/alertBox';
import { UserTypes }                           from '@/store/actions/user';

const clear = (action, state) => {
    return state;
};

const showResult = (action, state) => {
    return state;
};

export default function reducer(state = {}, action) {
    switch (action.type) {
        // @formatter:off
        case UserTypes.LOGOUT:
        case ToastTypesRedux.CLEAR:        return clear(action, state);
        case ToastTypesRedux.SHOW_ERROR:
        case ToastTypesRedux.SHOW_SUCCESS: return showResult(action, state);
        default:                              return state;
        // @formatter:on
    }
}
