//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const NavigationTypes = {
    RESET_STATE: 'Navigation/RESET_STATE',
    OPEN_URL:    'Navigation/OPEN_URL',
};

const openUrl = makeActionCreator(
    NavigationTypes.OPEN_URL,
    {
        url: null,
    },
);

const resetState = makeActionCreator(NavigationTypes.RESET_STATE);

export const NavigationActions = {
    openUrl,
    resetState,
};
