//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router';
import Cast                   from '@/helper/Cast';
import ComponentHelper        from '@/helper/ComponentHelper';
import PropTypes              from '@/components/PropTypes';
import StartPageSearchMode    from '@/components/stateless/composed/StartPageSearch/StartPageSearchMode';
import StatelessHeader        from '@/components/stateless/composed/Header';
import { SearchActions }      from '@/store/actions/search';
import Navigation             from '@/helper/Navigation';
import Routes                 from '@/constants/Routes';
import Route                  from '@/helper/Route';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: props.searchQuery,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchQuery !== this.props.searchQuery) {
            this.setState({
                searchQuery: this.props.searchQuery,
            });
        }
    }

    getSearchQuery = () => {
        if (!this.props.otherSearch) {
            return this.state.searchQuery;
        }

        return null;
    };

    render() {
        if (!this.props.isFactSheetPrintRoute) {
            return (
                <StatelessHeader
                    isMyCompanyVisible={this.props.isMyCompanyVisible}
                    isMyProfileVisible={this.props.isMyProfileVisible}
                    isMyCompanyActive={this.props.isMyCompanyActive}
                    isMyProfileActive={this.props.isMyProfileActive}
                    onSearch={this.search}
                    searchQuery={this.getSearchQuery()}
                    hideSearch={this.shouldHideSearch()}
                    route={this.props.route}
                    searchResults={this.props.searchResults}
                />
            );
        }

        return null;
    }

    shouldHideSearch = () => {
        return (
            !this.props.otherSearch &&
            Cast.bool(this.props.searchMode !== StartPageSearchMode.resultList &&
                (
                    (
                        this.props.hasSearchResults
                    ) ||
                    (
                        this.props.searchQuery.length &&
                        !this.props.isLoading
                    )
                ))
        );
    };

    search = (event) => {
        this.props.search({
            query: event.target.value,
        });
        this.props.changeSearchMode({
            mode: StartPageSearchMode.resultList,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Header = Component;

Component.propTypes = {
    changeSearchMode:      PropTypes.func,
    children:              PropTypes.children,
    hasSearchResults:      PropTypes.bool,
    isFactSheetPrintRoute: PropTypes.bool,
    isLoading:             PropTypes.bool,
    isMyCompanyActive:     PropTypes.bool,
    isMyCompanyVisible:    PropTypes.bool,
    isMyProfileActive:     PropTypes.bool,
    isMyProfileVisible:    PropTypes.bool,
    otherSearch:           PropTypes.bool,
    route:                 PropTypes.string,
    search:                PropTypes.func,
    searchMode:            PropTypes.string,
    searchQuery:           PropTypes.string,
    searchResults:         PropTypes.object,
};

Component.defaultProps = {
    changeSearchMode:      _.noop,
    children:              null,
    hasSearchResults:      false,
    isFactSheetPrintRoute: false,
    isLoading:             false,
    isMyCompanyActive:     false,
    isMyCompanyVisible:    false,
    isMyProfileActive:     false,
    isMyProfileVisible:    false,
    otherSearch:           false,
    route:                 null,
    search:                _.noop,
    searchMode:            null,
    searchQuery:           null,
    searchResults:         null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [
    'searchQuery',
];

const mapStateToProps = (state, ownProps) => {
    const currentCompanyId   = Navigation.getCompanyIdFromUrl(ownProps);
    const ownCompanyId       = _.get(state, 'user.company');
    const isOnOwnCompany     = ownCompanyId === currentCompanyId;
    const pathname           = _.get(state, 'router.location.pathname');
    const actsPrivate        = !_.get(state, 'user.company', false);
    const isMyProfileVisible = actsPrivate;
    const isMyCompanyVisible = !isMyProfileVisible;
    const isMyCompanyActive  = (
        (
            _.startsWith(pathname, Routes.myCompany) ||
            _.startsWith(pathname, Routes.companyPart)
        )
    );
    const isMyProfileActive  = (
        _.startsWith(pathname, Routes.myProfile) &&
        !isMyCompanyActive
    );

    return {
        hasSearchResults:      Cast.bool(_.sum(_.map(_.mapValues(state.search.items, 'length')))),
        isLoading:             !!_.get(state, 'search.isLoading', false),
        isOnOwnCompany,
        isMyCompanyActive,
        isMyProfileActive,
        isMyCompanyVisible,
        isMyProfileVisible,
        otherSearch:           _.get(state, 'search.otherSearch', false),
        searchMode:            _.get(state, 'search.searchMode', null),
        searchQuery:           _.get(state, 'search.query', null),
        searchResults:         _.get(state, 'search.items', {}),
        isFactSheetPrintRoute: Route.isFactsheetPrintRoute(pathname),
        route:                 _.get(state, 'router.location.pathname'),
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    SearchActions,
    dispatch,
);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component));
