//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { ColorButton }      from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme     from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import { HeaderUserButton } from '@/components/stateless/atomic/HeaderUserButton';
import { HeadlineSmall }    from '@/components/stateless/atomic/HeadlineSmall';
import Routes               from '@/constants/Routes';
import I18n                 from 'i18next';
import React                from 'react';
import styles               from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.signUpSearchResult}>
                <div className={styles.signUpSearchResultContent}>
                    <HeadlineSmall text={I18n.t('signupToContinue')} />
                    <div className={styles.signUpSearchResultText}>
                        {I18n.t('signupToContinueText')}
                        <HeaderUserButton />
                    </div>
                </div>
                <ColorButton
                    to={Routes.login}
                    text={I18n.t('loginToContinue')}
                    theme={ColorButtonTheme.orange}
                />
            </div>
        );
    }
}

export const SignUpSearchResultCTA = Component;

Component.propTypes = {};

Component.defaultProps = {};

export default Component;
