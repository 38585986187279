//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const FilterTypes = {
    APPLY_FILTER:  'Filter/APPLY_FILTER',
    RESET_FILTER:  'Filter/RESET_FILTER',
    TOGGLE_FILTER: 'Filter/TOGGLE_FILTER',
};

const applyFilter = makeActionCreator(
    FilterTypes.APPLY_FILTER,
    {
        filter:     null,
        field:      null,
        fieldQuery: null,
        value:      null,
    },
);

const resetFilter = makeActionCreator(FilterTypes.RESET_FILTER);

const toggleFilter = makeActionCreator(
    FilterTypes.TOGGLE_FILTER,
    {
        filter: null,
    },
);

export const FilterActions = {
    applyFilter,
    resetFilter,
    toggleFilter,
};
