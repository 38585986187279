//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import classNames      from 'classnames';
import ColorBoxColor   from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div
                className={classNames(
                    this.props.className,
                    styles.colorBox,
                    {
                        [styles.grayLight]:       this.props.color === ColorBoxColor.grayLight,
                        [styles.white]:           this.props.color === ColorBoxColor.white,
                        [styles.whiteGrayBorder]: this.props.color === ColorBoxColor.whiteGrayBorder,
                    },
                )}
            >
                {this.props.children}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ColorBox = Component;

Component.propTypes = {
    children:  PropTypes.children,
    className: PropTypes.string,
    color:     PropTypes.oneOfObjectKeys(ColorBoxColor),
};

Component.defaultProps = {
    children:  null,
    className: null,
    color:     ColorBoxColor.grayLight,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
