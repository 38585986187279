//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import FlagIconFactory from 'react-flag-icon-css';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

const FlagIcon = FlagIconFactory(
    React,
    {
        useCssModules: false,
    },
);

export class Component extends React.Component {
    getStyle = () => {
        const width  = this.props.width;
        const style  = {
            width,
        };

        return style;
    };

    render() {
        const style = this.getStyle();

        return (
            <span
                className={styles.countryFlagWrapper}
                style={style}
            >
                {this.renderFlag()}
            </span>
        );
    }

    renderFlag = () => {
        if (this.props.iso31661Alpha2CountryCode) {
            return (
                <FlagIcon
                    code={this.props.iso31661Alpha2CountryCode.toLowerCase()}
                    squared={this.props.squared}
                />
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const CountryFlag = Component;

Component.propTypes = {
    iso31661Alpha2CountryCode: PropTypes.string,
    squared:                   PropTypes.bool,
    width:                     PropTypes.number,
};

Component.defaultProps = {
    iso31661Alpha2CountryCode: null,
    squared:                   false,
    width:                     100,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
