//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import I18n                   from 'i18next';
import moment                 from 'moment';

import ComponentHelper                       from '@/helper/ComponentHelper';
import Ids                                   from '@/constants/Ids';
import Navigation                            from '@/helper/Navigation';
import PropTypes                             from '@/components/PropTypes';
import Routes                                from '@/constants/Routes';
import State                                 from '@/helper/State';
import StateHelper                           from '@/helper/State';
import { CompanyActions }                    from '@/store/actions/company';
import ContentHeader                         from '@/components/connected/ContentHeader';
import { MachineActions }                    from '@/store/actions/machine';
import { OverlayActions }                    from '@/store/actions/ovleray';
import { MessageRequestActions }             from '@/store/actions/messageRequest';
import { NavigationActions }                 from '@/store/actions/navigation';
import CreateProjectOverlay                  from '@/components/connected/CreateProjectOverlay';
import ColorButtonTheme                      from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import { ColorBox }                          from '@/components/stateless/atomic/ColorBox';
import { Flex }                              from '@/components/stateless/atomic/Flex';
import FlexDirection                         from '@/components/stateless/atomic/Flex/FlexDirection';
import { HeadlineMedium }                    from '@/components/stateless/atomic/HeadlineMedium';
import { ColorButton }                       from '@/components/stateless/atomic/ColorButton';
import { Spacer }                            from '@/components/stateless/atomic/Spacer';
import InfoText                              from '@/components/stateless/atomic/InfoText';
import ProjectListElement                    from '@/components/stateless/composed/ProjectListElement';
import { BadgeCount }                        from '@/components/stateless/atomic/BadgeCount';
import OwnProjectListElement                 from '@/components/stateless/composed/OwnProjectListElement';
import IconType                              from '@/components/stateless/atomic/Icon/IconType';
import { IconButton }                        from '@/components/stateless/atomic/IconButton';
import { ProjectsActions }                   from '@/store/actions/projects';
import appStyles                             from '@/styles.module.scss';
import { BoldTranslation }                   from '@/components/stateless/composed/BoldTranslation';
import { InterestedInProjectMessageContent } from '@/components/stateless/composed/InterestedInProjectMessageContent';
import OverlayType                           from '@/components/connected/Overlay/OverlayType';
import { FavoriseCompanyButton }             from '@/components/connected/FavoriseCompanyButton';
import ProjectStatusConstant                 from '@/constants/ProjectStatus';
import { Collapsable }                       from '@/components/stateless/composed/Collapsable';
import Company                               from '@/helper/Company';
import SideMenu                              from '@/components/connected/SideMenu';
import ViewingContext                        from '@/constants/ViewingContext';
import { ProfileActions }                    from '@/store/actions/profile';

import styles from './styles.module.scss';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        const { viewingContext } = props;

        if (viewingContext === ViewingContext.OWN_COMPANY) {
            props.fetchProjectsSuggestions();
        }

        props.fetchProjectsOwnProjects();
    }

    renderCreateProjectModal = () => {
        return (
            <CreateProjectOverlay />
        );
    };

    renderContentHeader = () => {
        const { props } = this;

        if (props.viewingContext === ViewingContext.OWN_COMPANY) {
            return null;
        }

        return (
            <ContentHeader
                rightContent={this.renderStarButton()}
                currentCompanyUrl={props.currentCompanyUrl}
                currentCompanyId={props.currentCompanyId}
            />
        );
    };

    renderStarButton = () => {
        return (
            <FavoriseCompanyButton
                company={this.props.currentCompanyId}
            />
        );
    };

    getSideMenuRoute = () => {
        switch (this.props.viewingContext) {
            case ViewingContext.OWN_PROFILE:
                return Routes.myProfileProjects;
            case ViewingContext.OWN_COMPANY:
                return Routes.projects;
        }

        return null;
    };

    render() {
        const sideMenuRoute = this.getSideMenuRoute();

        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <SideMenu
                        route={sideMenuRoute}
                        {...this.props}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        {this.renderContentHeader()}
                        <div className={styles.projectsContainer}>
                            <div className={styles.projectsContent}>
                                <Flex
                                    flexDirection={FlexDirection.column}
                                    gap={30}
                                >
                                    {this.renderOwnProjects()}
                                    {this.renderSuggestedProjects()}
                                </Flex>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderOwnProjects = () => {
        const { ownProjects } = this.props;

        if (ownProjects.length === 0) {
            return (
                <ColorBox color={ColorButtonTheme.white}>
                    <Flex
                        flexDirection={FlexDirection.column}
                    >
                        <HeadlineMedium>
                            {I18n.t('addNewProject')}
                        </HeadlineMedium>
                        <InfoText>
                            {I18n.t('addNewProjectInfoText')}
                        </InfoText>
                        <Spacer height={30} />
                        <ColorButton
                            theme={ColorButtonTheme.orange}
                            text={I18n.t('addNewProject')}
                            onClick={this.onAddProjectClick}
                        />
                        {this.renderCollapsableProjectList(this.props.closedOwnProjects, 'closedProjects', this.renderProject)}
                    </Flex>
                </ColorBox>
            );
        }

        return (
            <ColorBox color={ColorButtonTheme.white}>
                <Flex
                    flexDirection={FlexDirection.column}
                >
                    <HeadlineMedium className={styles.ownProjectsHeadline}>
                        {I18n.t('ownProjects')}
                        <IconButton
                            iconType={IconType.addCircle}
                            onClick={this.onAddProjectClick}
                        />
                    </HeadlineMedium>
                    <Flex
                        flexDirection={FlexDirection.column}
                        gap={20}
                    >
                        {ownProjects.map(this.renderProject)}
                        {this.renderCollapsableProjectList(this.props.closedOwnProjects, 'closedProjects', this.renderProject)}
                    </Flex>
                </Flex>
            </ColorBox>
        );
    };

    onAddProjectClick = () => {
        this.props.openCreateProjectOverlay({});
    };

    renderSuggestedProjects = () => {
        const { viewingContext, suggestedProjects } = this.props;

        if (viewingContext === ViewingContext.OWN_PROFILE) {
            return null;
        }

        if (!suggestedProjects || suggestedProjects.length === 0) {
            return (
                <ColorBox color={ColorButtonTheme.white}>
                    <HeadlineMedium>
                        {I18n.t('suggestedProjectsTitle')}
                    </HeadlineMedium>
                    <InfoText>
                        {I18n.t('suggestedProjectsText')}
                    </InfoText>
                </ColorBox>
            );
        }

        return (
            <ColorBox color={ColorButtonTheme.white}>
                <HeadlineMedium>
                    <Flex flexDirection={FlexDirection.row}>
                        {I18n.t('suggestedProjectsTitle')}
                        <div className={styles.badgeCountContainer}>
                            <BadgeCount count={suggestedProjects.length} />
                        </div>
                    </Flex>
                </HeadlineMedium>
                {this.renderSuggestions()}
            </ColorBox>
        );
    };

    renderCollapsableProjectList = (projects, titleKey, renderCallback) => {
        if (!projects || projects.length === 0) {
            return null;
        }

        return (
            <Collapsable
                title={I18n.t(titleKey)}
                closed={true}
            >
                <Flex
                    flexDirection={FlexDirection.column}
                    gap={20}
                >
                    {projects.map(renderCallback)}
                </Flex>
            </Collapsable>
        );
    };

    renderSuggestions = () => {
        return (
            <Flex
                flexDirection={FlexDirection.column}
                gap={20}
            >
                {this.props.suggestedProjects.map(this.renderSuggestion)}
                {this.renderCollapsableProjectList(this.props.acceptedProjects, 'acceptedProjects', this.renderAcceptedProject)}
            </Flex>
        );
    };

    getRuntimeString = (project) => {
        const startDate = moment(project.startDate).format(I18n.t('dateFormat'));
        const endDate   = moment(project.endDate).format(I18n.t('dateFormat'));
        const runtime   = `${startDate} - ${endDate}`;

        return runtime;
    };

    onProjectDetailClick = (project) => {
        return () => {
            const { isOnOwnProfile, viewingContext } = this.props;

            this.props.openProject({
                project,
                isOnOwnProfile,
                viewingContext,
            });
        };
    };

    renderProject = (project) => {
        const runtime = this.getRuntimeString(project);

        return (
            <OwnProjectListElement
                key={project.id}
                companyId={project?.company?.id}
                project={project}
                projectId={project.id}
                projectName={project.name}
                projectRuntime={runtime}
                projectVolume={project.totalVolume}
                status={project.status}
                numberOfMatches={project.interestedMatches?.length}
                onDetailClick={this.onProjectDetailClick(project)}
            />
        );
    };

    renderSuggestion = (project) => {
        const runtime  = this.getRuntimeString(project);
        const subtitle = Company.getCompanyTypesString(project.company?.types);

        return (
            <ProjectListElement
                key={project.id}
                company={project.company}
                projectCreator={project.projectCreator}
                projectId={project.id}
                projectName={project.name}
                projectRuntime={runtime}
                projectVolume={project.peakYearVolume}
                status={project.status}
                subtitle={subtitle}
                onDetailClick={this.onProjectDetailClick(project)}
                onAcceptClick={this.onAcceptSuggestedProjectClick(project)}
                onDeclineClick={this.onDeclineSuggestedProjectClick(project)}
            />
        );
    };

    renderAcceptedProject = (project) => {
        if (!project.company) {
            return null;
        }

        const runtime  = this.getRuntimeString(project);
        const subtitle = Company.getCompanyTypesString(project.company.types);

        return (
            <ProjectListElement
                key={project.id}
                company={project.company}
                projectId={project.id}
                projectName={project.name}
                projectRuntime={runtime}
                projectVolume={project.peakYearVolume}
                status={project.status}
                subtitle={subtitle}
                onDetailClick={this.onProjectDetailClick(project)}
            />
        );
    };

    onAcceptSuggestedProjectClick = (project) => () => {
        const message = (
            <InterestedInProjectMessageContent
                company={project.company}
                projectCreator={project.projectCreator}
                project={project}
                onMessageChanged={this.onMessageChanged}
                message={project.interestedMessage}
            />
        );

        this.props.openConfirmDialog({
            confirmAction:     [
                ProjectsActions.acceptSuggestedProject({
                    project,
                }),
            ],
            title:             I18n.t('projectsAcceptProjectTitle'),
            message,
            overlayType:       OverlayType.widePrompt,
            confirmButtonText: I18n.t('send'),
            cancelButtonText:  I18n.t('cancel'),
        });
    };

    onMessageChanged = (message) => {
        this.props.changeInterestedMessage({
            message,
        });
    };

    onDeclineSuggestedProjectClick = (project) => () => {
        const message = (
            <BoldTranslation
                translationKey={'projectsDeclineProjectText'}
                values={{
                    projectTitle: project.name,
                }}
            />
        );

        this.props.openConfirmDialog({
            confirmAction:     [
                ProjectsActions.declineSuggestedProject({
                    projectId: project.id,
                }),
            ],
            title:             I18n.t('projectsDeclineProjectTitle'),
            message,
            confirmButtonText: I18n.t('notInterested'),
            cancelButtonText:  I18n.t('cancel'),
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    acceptSuggestedProject:   PropTypes.func,
    changeInterestedMessage:  PropTypes.func,
    company:                  PropTypes.object,
    companyAddress:           PropTypes.object,
    companyIri:               PropTypes.string,
    companyName:              PropTypes.string,
    companyTypes:             PropTypes.array,
    currentCompanyId:         PropTypes.string,
    currentCompanyUrl:        PropTypes.string,
    declineSuggestedProject:  PropTypes.func,
    fetchCompany:             PropTypes.func,
    fetchProjectsOwnProjects: PropTypes.func,
    fetchProjectsSuggestions: PropTypes.func,
    fetchMessageRequests:     PropTypes.func,
    isOnOwnCompany:           PropTypes.bool,
    isOnOwnProfile:           PropTypes.bool,
    messageRequests:          PropTypes.array,
    openProject:              PropTypes.func,
    openConfirmDialog:        PropTypes.func,
    openCreateProjectOverlay: PropTypes.func,
    openUrl:                  PropTypes.func,
    ownProjects:              PropTypes.array,
    requestingCompanyIri:     PropTypes.string,
    setCurrentCompany:        PropTypes.func,
    setMessageRequestState:   PropTypes.func,
    suggestedProjects:        PropTypes.array,
    competences:              PropTypes.array,
    acceptedProjects:         PropTypes.array,
    closedOwnProjects:        PropTypes.array,
    viewingContext:           PropTypes.oneOfObjectValues(ViewingContext),
};

Screen.defaultProps = {
    acceptSuggestedProject:   null,
    changeInterestedMessage:  _.noop,
    company:                  null,
    companyAddress:           {},
    companyIri:               null,
    companyName:              null,
    companyTypes:             [],
    currentCompanyId:         null,
    currentCompanyUrl:        null,
    declineSuggestedProject:  null,
    fetchCompany:             _.noop,
    fetchProjectsOwnProjects: _.noop,
    fetchProjectsSuggestions: _.noop,
    fetchMessageRequests:     _.noop,
    isOnOwnCompany:           false,
    isOnOwnProfile:           false,
    messageRequests:          [],
    openProject:              _.noop,
    openConfirmDialog:        _.noop,
    openCreateProjectOverlay: _.noop,
    openUrl:                  _.noop,
    ownProjects:              [],
    requestingCompanyIri:     null,
    setCurrentCompany:        _.noop,
    setMessageRequestState:   _.noop,
    suggestedProjects:        [],
    competences:              [],
    acceptedProjects:         [],
    closedOwnProjects:        [],
    viewingContext:           ViewingContext.UNDEFINED,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...CompanyActions,
    ...ProjectsActions,
    ...MachineActions,
    ...ProfileActions,
    ...OverlayActions,
    ...MessageRequestActions,
    ...NavigationActions,
}, dispatch);

const mapStateToProps = (state, ownProps) => {
    const companyUrl                = Navigation.getParameterFromUrl(ownProps, 'companyId');
    const companyId                 = Navigation.getCompanyIdFromUrl(ownProps);
    const companyIri                = StateHelper.getCurrentCompanyIri(state);
    const requestingCompanyIri      = StateHelper.getUserCompanyIri(state);
    const company                   = StateHelper.getCompanyObject(state, companyId);
    const suggestedProjects         = _.get(state, 'projects.suggestedProjects', []);
    const ownProjects               = _.get(state, 'projects.ownProjects', []);
    const acceptedProjects          = _.filter(suggestedProjects, (project) => {
        return _.find(project.matches, (match) => {
            return _.eq(match.matchingCompany.id, companyId);
        });
    });
    const filteredSuggestedProjects = _.filter(suggestedProjects, (project) => {
        return !_.find(project.matches, (match) => {
            return _.eq(match.matchingCompany.id, companyId);
        });
    });
    const openOwnProjects           = _.filter(ownProjects, {
        status: ProjectStatusConstant.OPEN,
    });
    const closedOwnProjects         = _.filter(ownProjects, {
        status: ProjectStatusConstant.CLOSED,
    });
    const viewingContext            = Navigation.getViewingContext(ownProps, state);

    return {
        companyAddress:    State.getCompanyValue(state, companyId, 'address'),
        companyName:       State.getCompanyValue(state, companyId, 'name'),
        messageRequests:   _.get(state, 'messageRequest.messageRequests', []),
        companyTypes:      State.getCompanyValue(state, companyId, 'types', []),
        competences:       State.getCompanyValue(state, companyId, 'competencesHierarchy', []),
        currentCompanyId:  companyId,
        currentCompanyUrl: companyUrl,
        companyIri,
        requestingCompanyIri,
        company,
        viewingContext,
        suggestedProjects: filteredSuggestedProjects,
        ownProjects:       openOwnProjects,
        acceptedProjects,
        closedOwnProjects,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
