//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React            from 'react';
import _                from 'lodash';
import I18n             from 'i18next';
import ColorButtonSize  from '@/components/stateless/atomic/ColorButton/ColorButtonSize';
import ColorButtonTheme from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import PropTypes        from '@/components/PropTypes';
import Routes           from '@/constants/Routes';
import { ColorButton }  from '@/components/stateless/atomic/ColorButton';
import { Logo }         from '@/components/stateless/atomic/Logo';
import HeaderUserButton from '@/components/connected/HeaderUserButton';
import { TestIds }      from '@/constants/TestIds';
import IconType         from '@/components/stateless/atomic/Icon/IconType';
import classNames       from 'classnames';
import styles           from './styles.module.scss';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
        };
    }

    toggleMenu = () => {
        this.setState({
            menuOpen: !this.state.menuOpen,
        });
    };

    renderBurgerMenu() {
        return (
            <div className={styles.burgerMenu}>
                <ColorButton
                    test-id={TestIds.burger}
                    onClick={this.toggleMenu}
                    icon={IconType.burger}
                />
            </div>
        );
    }

    render() {
        return (
            <div className={styles.startPageHeaderWrapper}>
                <div className={styles.startPageHeaderLogoWrapper}>
                    <Logo to={Routes.home} />
                </div>
                {this.renderBurgerMenu()}
                <div
                    className={classNames(
                        styles.startPageHeaderButtonWrapper,
                        {
                            [styles.contentOpen]: this.state.menuOpen,
                        },
                    )}
                >
                    {this.renderButtons()}
                </div>
            </div>
        );
    }

    renderButtons = () => {
        if (this.props.isLoggedIn) {
            return this.renderLoggedInButtons();
        }

        return this.renderLoggedOutButtons();
    };

    renderLoggedInButtons = () => {
        return (
            <>
                {this.renderMemberButton()}
                {this.renderFavoritesButton()}
                {this.renderMyCompanyOrProfileButton()}
                <HeaderUserButton />
            </>
        );
    };

    renderLoggedOutButtons = () => {
        return (
            <>
                {this.renderMemberButton()}
                {this.renderLoginButton()}
                {this.renderSignUpButton()}
            </>
        );
    };

    renderMyCompanyOrProfileButton() {
        const translationKey = (
            this.props.isUserWithoutCompany ?
                'myProfile' :
                'myCompany'
        );

        return (
            <ColorButton
                text={I18n.t(translationKey)}
                theme={ColorButtonTheme.transparent}
                size={ColorButtonSize.medium}
                uppercase={true}
                to={Routes.myCompany}
            />
        );
    }

    renderFavoritesButton() {
        return (
            <ColorButton
                text={I18n.t('favorites')}
                theme={ColorButtonTheme.transparent}
                size={ColorButtonSize.medium}
                uppercase={true}
                to={Routes.favorites}
            />
        );
    }

    renderLogoutButton() {
        return (
            <ColorButton
                text={I18n.t('logout')}
                theme={ColorButtonTheme.transparent}
                size={ColorButtonSize.medium}
                uppercase={true}
                onClick={this.props.onLogoutClicked}
            />
        );
    }

    renderMemberButton() {
        return (
            <ColorButton
                to={Routes.membership}
                text={I18n.t('membership')}
                theme={ColorButtonTheme.transparent}
                size={ColorButtonSize.medium}
                uppercase={true}
            />
        );
    }

    renderLoginButton() {
        return (
            <ColorButton
                to={Routes.login}
                text={I18n.t('login')}
                theme={ColorButtonTheme.transparent}
                size={ColorButtonSize.medium}
                uppercase={true}
            />
        );
    }

    renderSignUpButton() {
        return (
            <ColorButton
                to={Routes.signUp}
                text={I18n.t('signUpForFree')}
                theme={ColorButtonTheme.whiteGhost}
                size={ColorButtonSize.medium}
            />
        );
    }
}

export const StartPageHeader = Component;

Component.propTypes = {
    isLoggedIn:           PropTypes.bool,
    isUserWithoutCompany: PropTypes.bool,
    onLogoutClicked:      PropTypes.func,
};

Component.defaultProps = {
    isLoggedIn:           false,
    isUserWithoutCompany: false,
    onLogoutClicked:      _.noop,
};

Component.renderAffectingProps = _.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
