//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                              from 'react';
import _                                  from 'lodash';
import classNames                         from 'classnames';
import I18n                               from 'i18next';
import Select                             from 'react-select';
import { components as SelectComponents } from 'react-select';
import AsyncSelect                        from 'react-select/async';
import colors                             from '@/styles/colors.module.scss';
import ComponentHelper                    from '@/helper/ComponentHelper';
import fonts                              from '@/styles/fonts.module.scss';
import HeadlineSmall                      from '@/components/stateless/atomic/HeadlineSmall';
import Icon                               from '@/components/stateless/atomic/Icon';
import IconType                           from '@/components/stateless/atomic/Icon/IconType';
import PropTypes                          from '@/components/PropTypes';
import Style                              from '@/helper/Style';
import ReactSelectCloseIcon               from '../../../../assets/icons/react-select-close.svg';
import styles                             from './styles.module.scss';

export class Component extends React.Component {
    getCustomComponents() {
        const DropdownIndicator = () => {
            return (
                <div className={styles.dropDownIndicatorWrapper}>
                    <Icon iconType={IconType.caretDown} />
                </div>
            );
        };

        const NoOptionsMessage = (props) => {
            return (
                <div
                    {...props.innerProps}
                    className={styles.noOptionsMessageWrapper}
                >
                    {I18n.t('dropDownEmptyText')}
                </div>
            );
        };

        const Option = (props) => {
            const option = _.find(props.options, {
                value: props.value,
            });

            return (
                <div
                    {...props.innerProps}
                    className={classNames(styles.dropDownOption)}
                >
                    {this.renderValue(props.label, option)}
                </div>
            );
        };

        const SingleValue = (props) => {
            const data        = _.get(props, 'data');
            const children    = _.get(props, 'children');
            const label       = _.get(data, 'label', null);
            const singleValue = (
                label ?
                    this.renderValue(label, data) :
                    children
            );

            return (
                <div className={styles.valueWrapper}>
                    {singleValue}
                </div>
            );
        };

        const MultiValue = (props) => {
            const data       = _.get(props, 'data');
            const children   = _.get(props, 'children');
            const label      = _.get(data, 'label', null);
            const multiValue = (
                label ?
                    this.renderMultiValue(label, data, props) :
                    children
            );

            return (
                <div className={styles.valueWrapper}>
                    {multiValue}
                </div>
            );
        };

        const customComponents = {
            DropdownIndicator,
            NoOptionsMessage,
            Option,
            SingleValue,
            MultiValue,
        };

        return customComponents;
    }

    getCustomStyles() {
        const customStyles = {
            control:             (provided, state) => {
                const borderColor        = (
                    state.menuIsOpen ?
                        colors.colorOrange :
                        colors.colorGray
                );
                const borderRadiusBottom = (
                    state.menuIsOpen ?
                        0 :
                        5
                );
                const zIndex             = (
                    state.menuIsOpen ?
                        1337 :
                        1
                );

                return {
                    backgroundColor:         colors.colorWhite,
                    borderColor,
                    borderRadius:            5,
                    borderBottomLeftRadius:  borderRadiusBottom,
                    borderBottomRightRadius: borderRadiusBottom,
                    borderBottomColor:       colors.colorGray,
                    borderStyle:             'solid',
                    borderWidth:             1,
                    cursor:                  'pointer',
                    display:                 'flex',
                    minHeight:               40,
                    minWidth:                10,
                    zIndex,
                };
            },
            indicatorsContainer: () => {
                return {
                    alignItems:     'center',
                    display:        'flex',
                    justifyContent: 'center',
                };
            },
            indicatorSeparator:  () => {
                return {
                    display: 'none',
                };
            },
            input:               () => {
                return {
                    color:         colors.colorGrayDarker,
                    fontFamily:    Style.important(fonts.fontSourceOpenSans),
                    fontSize:      14,
                    paddingTop:    0,
                    paddingRight:  0,
                    paddingBottom: 0,
                    paddingLeft:   4,
                };
            },
            menu:                () => {
                return {
                    boxSizing:            'border-box',
                    borderColor:          colors.colorOrange,
                    borderStyle:          'solid',
                    borderWidth:          1,
                    borderTopWidth:       0,
                    borderRadius:         5,
                    borderTopLeftRadius:  0,
                    borderTopRightRadius: 0,
                    left:                 0,
                    maxHeight:            288,
                    overflow:             'auto',
                    position:             'absolute',
                    width:                '100%',
                    zIndex:               1337,
                };
            },
            menuList:            () => {
                return {
                    padding: 0,
                };
            },
            option:              () => {
                return {};
            },
            placeholder:         () => {
                return {
                    alignItems:    'center',
                    color:         Style.important(colors.colorGrayDark),
                    display:       'flex',
                    height:        '100%',
                    fontFamily:    fonts.fontSourceOpenSans,
                    fontSize:      14,
                    paddingTop:    0,
                    paddingRight:  0,
                    paddingBottom: 4,
                    paddingLeft:   4,
                };
            },
            singleValue:         () => {
                return {
                    alignItems:    'center',
                    color:         Style.important(colors.colorGrayDarker),
                    display:       'flex',
                    fontFamily:    fonts.fontSourceOpenSans,
                    fontSize:      14,
                    paddingTop:    0,
                    paddingRight:  0,
                    paddingBottom: 4,
                    paddingLeft:   4,
                };
            },
            valueContainer:      () => {
                return {
                    boxSizing:     'border-box',
                    display:       'flex',
                    alignItems:    'center',
                    flexGrow:      1,
                    flexWrap:      'wrap',
                    paddingTop:    2,
                    paddingRight:  5,
                    paddingLeft:   15,
                    paddingBottom: 0,
                };
            },
        };

        return customStyles;
    }

    mapDisabled = (options) => {
        return options.map((option) => {
            return {
                ...option,
                isDisabled: this.props.disabled,
            };
        });
    };

    render() {
        const props            = this.props;
        const customComponents = this.getCustomComponents();
        const customStyles     = this.getCustomStyles();
        const defaultValue     = props.defaultValue;

        if (props.async) {
            return (
                <AsyncSelect
                    placeholder={props.placeholder}
                    components={customComponents}
                    options={props.options}
                    loadOptions={props.loadOptions}
                    getOptionLabel={props.getOptionLabel}
                    getOptionValue={props.getOptionValue}
                    styles={customStyles}
                    onChange={props.onChange}
                    value={defaultValue}
                    isMulti={props.multiple}
                    controlShouldRenderValue={!props.renderBelow}
                />
            );
        }

        return (
            <>
                <Select
                    placeholder={props.placeholder}
                    components={customComponents}
                    options={this.mapDisabled(props.options)}
                    styles={customStyles}
                    onChange={props.onChange}
                    getOptionLabel={props.getOptionLabel}
                    getOptionValue={props.getOptionValue}
                    value={defaultValue}
                    isMulti={props.multiple}
                    controlShouldRenderValue={!props.renderBelow}
                />
                {this.renderValuesBelow()}
            </>
        );
    }

    renderValue = (label, option) => {
        if (this.props.renderValue) {
            return this.props.renderValue(
                label,
                option,
            );
        }

        return label;
    };

    renderMultiValue = (label, option, props) => {
        if (this.props.renderMultiValue) {
            return this.props.renderMultiValue(
                label,
                option,
                props,
            );
        }

        return (
            <SelectComponents.MultiValue {...props} />
        );
    };

    handleRemoveValue = (event) => {
        const { getOptionValue }   = this.props;
        const defaultValue         = this.props.defaultValue;
        const { name: buttonName } = event.currentTarget;
        const removedValue         = defaultValue.find((option) => getOptionValue(option) === buttonName);

        if (removedValue) {
            this.props.onChange(defaultValue.filter((option) => getOptionValue(option) !== buttonName));
        }
    };

    renderValuesBelow = () => {
        const defaultValue = this.props.defaultValue;

        if (this.props.renderBelow && defaultValue.length) {
            const values = defaultValue.map(this.customRenderValue);

            return (
                <div className={styles.customValuesContainer}>
                    <HeadlineSmall text={this.props.renderBelowTitle} />
                    <div className={styles.customValuesInnerContainer}>
                        {values}
                    </div>
                </div>
            );
        }

        return null;
    };

    customRenderValue = (option) => {
        const { getOptionValue, getOptionLabel } = this.props;

        return (
            <div
                className={styles.customValue}
                key={getOptionValue(option)}
            >
                <span>
                    {getOptionLabel(option)}
                </span>
                <button
                    onClick={this.handleRemoveValue}
                    name={getOptionValue(option)}
                >
                    <ReactSelectCloseIcon />
                </button>
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const DropDownInput = Component;

Component.getOptionValue = (option) => option.value;

Component.getOptionLabel = (option) => option.label;

Component.propTypes = {
    async:            PropTypes.bool,
    defaultValue:     PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    disabled:         PropTypes.bool,
    getOptionLabel:   PropTypes.func,
    getOptionValue:   PropTypes.func,
    loadOptions:      PropTypes.func,
    multiple:         PropTypes.bool,
    onChange:         PropTypes.func,
    options:          PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })),
    placeholder:      PropTypes.string,
    renderBelow:      PropTypes.bool,
    renderBelowTitle: PropTypes.string,
    renderMultiValue: PropTypes.func,
    renderValue:      PropTypes.func,
};

Component.defaultProps = {
    async:            false,
    defaultValue:     null,
    disabled:         false,
    getOptionLabel:   Component.getOptionLabel,
    getOptionValue:   Component.getOptionValue,
    loadOptions:      _.noop,
    multiple:         false,
    onChange:         _.noop,
    options:          [],
    placeholder:      null,
    renderBelow:      false,
    renderBelowTitle: null,
    renderMultiValue: null,
    renderValue:      null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
