//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

export default class Revenue {
    static mapDevelopment = (developmentRevenues, field) => {
        return _.map(
            developmentRevenues,
            (developmentRevenue) => _.get(developmentRevenue, field),
        );
    }

    static getLabelAndData = (developmentRevenue) => {
        const label = Revenue.mapDevelopment(developmentRevenue, 'label');
        const data  = [
            {
                label: 'Turnover',
                data:  Revenue.mapDevelopment(developmentRevenue, 'value'),
            },
        ];

        return {
            label,
            data,
        };
    }

    static mapIndustryRatios = (industryRatios) => {
        return _.map(
            industryRatios,
            (industryRatio) => {
                return {
                    value: industryRatio.percentage,
                    name:  industryRatio.industry.title,
                };
            },
        );
    };
}
