//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React  from 'react';
import I18n   from 'i18next';
import moment from 'moment';

import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import Facts           from '@/components/stateless/atomic/Facts';
import { Spacer }      from '@/components/stateless/atomic/Spacer';

export class Component extends React.Component {
    renderComment = () => {
        const { project } = this.props;
        const { comment } = project;

        if (!comment) {
            return null;
        }

        return (
            <>
                <Spacer height={30} />
                {comment}
            </>
        );
    };

    render() {
        const { project }                                                                  = this.props;
        const { startDate, endDate, totalVolume, peakYearVolume, name, countryOfDelivery } = project;
        const facts                                                                        = [
            {
                label: 'start',
                value: moment(startDate).format(I18n.t('dateFormat')),
            },
            {
                label: 'end',
                value: moment(endDate).format(I18n.t('dateFormat')),
            },
            {
                label: 'volume',
                value: totalVolume,
            },
            {
                label: 'peakYearVolume',
                value: peakYearVolume,
            },
            {
                label: 'peakYearVolume',
                value: peakYearVolume,
            },
        ];

        if (countryOfDelivery) {
            facts.push({
                label: 'countryOfDelivery',
                value: countryOfDelivery,
            });
        }

        return (
            <div>
                <Facts
                    headline={name}
                    facts={facts}
                />
                {this.renderComment()}
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const ProjectSummary = Component;

Component.propTypes = {
    project: PropTypes.object,
};

Component.defaultProps = {
    project: null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
