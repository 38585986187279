//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator }                from '@/helper/Store';
import { makeActionCreatorDecreaseLoading } from '@/helper/Store';
import { makeActionCreatorIncreaseLoading } from '@/helper/Store';

export const MachineTypes = {
    ABORT_MACHINE_EDIT:                        'Machine/ABORT_MACHINE_EDIT',
    ADD_CHILDREN_TO_TAG_QUERY:                 'Machine/ADD_CHILDREN_TO_TAG_QUERY',
    ADD_MACHINE_PARAMETER:                     'Machine/ADD_MACHINE_PARAMETER',
    ADD_MACHINE_TAG:                           'Machine/ADD_MACHINE_TAG',
    CHANGE_MACHINE_TAG:                        'Machine/CHANGE_MACHINE_TAG',
    CREATE_MACHINE_FAILED:                     'Machine/CREATE_MACHINE_FAILED',
    CREATE_MACHINE_SUCCEEDED:                  'Machine/CREATE_MACHINE_SUCCEEDED',
    UPDATE_MACHINE_SUCCEEDED:                  'Machine/UPDATE_MACHINE_SUCCEEDED',
    UPDATE_MACHINE_FAILED:                     'Machine/UPDATE_MACHINE_FAILED',
    DELETE_MACHINE_TAG:                        'Machine/DELETE_MACHINE_TAG',
    EDIT_MACHINE_IMAGE:                        'Machine/EDIT_MACHINE_IMAGE',
    FETCH_MACHINES:                            'Machine/FETCH_MACHINES',
    FETCH_MACHINES_BY_COMPANY:                 'Machine/FETCH_MACHINES_BY_COMPANY',
    FETCH_MACHINES_BY_COMPANY_FAILED:          'Machine/FETCH_MACHINES_BY_COMPANY_FAILED',
    FETCH_MACHINES_BY_COMPANY_SUCCEEDED:       'Machine/FETCH_MACHINES_BY_COMPANY_SUCCEEDED',
    FETCH_MACHINES_FAILED:                     'Machine/FETCH_MACHINES_FAILED',
    FETCH_MACHINES_SUCCEEDED:                  'Machine/FETCH_MACHINES_SUCCEEDED',
    IS_MANUFACTURER_CHANGED:                   'Machine/IS_MANUFACTURER_CHANGED',
    MACHINE_CATEGORY_CHANGED:                  'Machine/MACHINE_CATEGORY_CHANGED',
    MACHINE_DESCRIPTION_CHANGED:               'Machine/MACHINE_DESCRIPTION_CHANGED',
    MACHINE_NAME_CHANGED:                      'Machine/MACHINE_NAME_CHANGED',
    MACHINE_CAPACITY_CHANGED:                  'Machine/MACHINE_CAPACITY_CHANGED',
    MACHINE_PARAMETER_NAME_CHANGED:            'Machine/MACHINE_PARAMETER_NAME_CHANGED',
    MACHINE_PARAMETER_VALUE_CHANGED:           'Machine/MACHINE_PARAMETER_VALUE_CHANGED',
    MACHINE_PARAMETER_UNIT_CHANGED:            'Machine/MACHINE_PARAMETER_UNIT_CHANGED',
    OPEN_ADD_NEW_MACHINE_MANUFACTURER_OVERLAY: 'Machine/OPEN_ADD_NEW_MACHINE_MANUFACTURER_OVERLAY',
    OPEN_ADD_NEW_MACHINE_OVERLAY:              'Machine/OPEN_ADD_NEW_MACHINE_OVERLAY',
    OPEN_EDIT_MACHINE_OVERLAY:                 'Machine/OPEN_EDIT_MACHINE_OVERLAY',
    POST_MACHINE_PARAMETERS:                   'Machine/POST_MACHINE_PARAMETERS',
    POST_MACHINE_PARAMETERS_FAILED:            'Machine/POST_MACHINE_PARAMETERS_FAILED',
    POST_MACHINE_PARAMETERS_SUCCEEDED:         'Machine/POST_MACHINE_PARAMETERS_SUCCEEDED',
    UPDATE_MACHINE_CAPACITY:                   'Machine/UPDATE_MACHINE_CAPACITY',
    UPDATE_MACHINE_CAPACITY_FAILED:            'Machine/UPDATE_MACHINE_CAPACITY_FAILED',
    UPDATE_MACHINE_CAPACITY_SUCCEEDED:         'Machine/UPDATE_MACHINE_CAPACITY_SUCCEEDED',
    UPDATE_MACHINE_CATEGORY:                   'Machine/UPDATE_MACHINE_CATEGORY',
    UPDATE_MACHINE_CATEGORY_FAILED:            'Machine/UPDATE_MACHINE_CATEGORY_FAILED',
    UPDATE_MACHINE_CATEGORY_SUCCEEDED:         'Machine/UPDATE_MACHINE_CATEGORY_SUCCEEDED',
    REMOVE_MACHINE_PARAMETER:                  'Machine/REMOVE_MACHINE_PARAMETER',
    REQUEST_MACHINE:                           'Machine/REQUEST_MACHINE',
    RESET_MACHINE:                             'Machine/RESET_MACHINE',
    UPDATE_OR_CREATE_MACHINE:                  'Machine/UPDATE_OR_CREATE_MACHINE',
};

const abortMachineEdit = makeActionCreator(MachineTypes.ABORT_MACHINE_EDIT);

const addChildrenToTagQuery = makeActionCreator(
    MachineTypes.ADD_CHILDREN_TO_TAG_QUERY,
    {
        tag:      null,
        children: null,
    },
);

const addMachineParameter = makeActionCreator(MachineTypes.ADD_MACHINE_PARAMETER);

const addMachineTag = makeActionCreator(
    MachineTypes.ADD_MACHINE_TAG,
    {
        tag: null,
    },
);

const createMachineFailed    = makeActionCreatorDecreaseLoading(MachineTypes.CREATE_MACHINE_FAILED);
const createMachineSucceeded = makeActionCreatorDecreaseLoading(MachineTypes.CREATE_MACHINE_SUCCEEDED);
const updateMachineSucceeded = makeActionCreatorDecreaseLoading(MachineTypes.UPDATE_MACHINE_SUCCEEDED);
const updateMachineFailed    = makeActionCreatorDecreaseLoading(MachineTypes.UPDATE_MACHINE_FAILED);

const changeMachineTag = makeActionCreator(
    MachineTypes.CHANGE_MACHINE_TAG,
    {
        tag: null,
    },
);

const deleteMachineTag = makeActionCreator(
    MachineTypes.DELETE_MACHINE_TAG,
    {
        tag: null,
    },
);

const editMachineImage = makeActionCreator(
    MachineTypes.EDIT_MACHINE_IMAGE,
    {
        image: null,
    },
);

const fetchMachine = makeActionCreator(
    MachineTypes.FETCH_MACHINES,
    {
        machineId: null,
    },
);

const fetchMachinesByCompany = makeActionCreator(
    MachineTypes.FETCH_MACHINES_BY_COMPANY,
    {
        companyId: null,
    },
);

const fetchMachinesByCompanyFailed = makeActionCreator(MachineTypes.FETCH_MACHINES_BY_COMPANY_FAILED);

const fetchMachinesByCompanySucceeded = makeActionCreator(
    MachineTypes.FETCH_MACHINES_BY_COMPANY_SUCCEEDED,
    {
        machines:  null,
        companyId: null,
    },
);

const fetchMachineFailed = makeActionCreator(MachineTypes.FETCH_MACHINES_FAILED);

const fetchMachineSucceeded = makeActionCreator(
    MachineTypes.FETCH_MACHINES_SUCCEEDED,
    {
        machine: null,
    },
);

const isManufacturerChanged = makeActionCreator(MachineTypes.IS_MANUFACTURER_CHANGED);

const machineCategoryChanged = makeActionCreator(
    MachineTypes.MACHINE_CATEGORY_CHANGED,
    {
        category: null,
    },
);

const machineDescriptionChanged = makeActionCreator(
    MachineTypes.MACHINE_DESCRIPTION_CHANGED,
    {
        description: null,
    },
);

const machineNameChanged = makeActionCreator(
    MachineTypes.MACHINE_NAME_CHANGED,
    {
        name: null,
    },
);

const machineCapacityChanged = makeActionCreator(
    MachineTypes.MACHINE_CAPACITY_CHANGED,
    {
        machineCapacity: null,
    },
);

const machineParameterNameChanged = makeActionCreator(
    MachineTypes.MACHINE_PARAMETER_NAME_CHANGED,
    {
        name:  null,
        index: null,
    },
);

const machineParameterValueChanged = makeActionCreator(
    MachineTypes.MACHINE_PARAMETER_VALUE_CHANGED,
    {
        value: null,
        index: null,
    },
);

const machineParameterUnitChanged = makeActionCreator(
    MachineTypes.MACHINE_PARAMETER_UNIT_CHANGED,
    {
        unit:  null,
        index: null,
    },
);

const openAddNewMachineManufacturerOverlay = makeActionCreator(MachineTypes.OPEN_ADD_NEW_MACHINE_MANUFACTURER_OVERLAY);

const openAddNewMachineOverlay = makeActionCreator(
    MachineTypes.OPEN_ADD_NEW_MACHINE_OVERLAY,
    {
        apiMode: null,
        name:    null,
    },
);

const openEditMachineOverlay = makeActionCreator(
    MachineTypes.OPEN_EDIT_MACHINE_OVERLAY,
    {
        machineId: null,
    },
);

const postMachineParameters = makeActionCreator(
    MachineTypes.POST_MACHINE_PARAMETERS,
    {
        machineIri: null,
        parameters: null,
    },
);

const postMachineParametersFailed    = makeActionCreator(MachineTypes.POST_MACHINE_PARAMETERS_FAILED);
const postMachineParametersSucceeded = makeActionCreator(MachineTypes.POST_MACHINE_PARAMETERS_SUCCEEDED);

const updateMachineCapacity = makeActionCreator(
    MachineTypes.UPDATE_MACHINE_CAPACITY,
    {
        machineCapacities: null,
        machine: null,
    },
);

const updateMachineCapacityFailed    = makeActionCreator(MachineTypes.UPDATE_MACHINE_CAPACITY_FAILED);
const updateMachineCapacitySucceeded = makeActionCreator(MachineTypes.UPDATE_MACHINE_CAPACITY_SUCCEEDED);

const updateMachineCategory = makeActionCreator(
    MachineTypes.UPDATE_MACHINE_CATEGORY,
    {
        machineCategory: null,
    },
);

const updateMachineCategoryFailed    = makeActionCreator(MachineTypes.UPDATE_MACHINE_CATEGORY_FAILED);
const updateMachineCategorySucceeded = makeActionCreator(MachineTypes.UPDATE_MACHINE_CATEGORY_SUCCEEDED);

const removeMachineParameter = makeActionCreator(
    MachineTypes.REMOVE_MACHINE_PARAMETER,
    {
        index: null,
    },
);

const requestMachine = makeActionCreator(
    MachineTypes.REQUEST_MACHINE,
    {
        machine:    null,
        companyUrl: null,
    },
);

const resetMachine = makeActionCreator(
    MachineTypes.RESET_MACHINE,
    {
        machine:    null,
        companyUrl: null,
    },
);

const updateOrCreateMachine = makeActionCreatorIncreaseLoading(MachineTypes.UPDATE_OR_CREATE_MACHINE);

export const MachineActions = {
    abortMachineEdit,
    addChildrenToTagQuery,
    addMachineParameter,
    addMachineTag,
    changeMachineTag,
    createMachineFailed,
    createMachineSucceeded,
    deleteMachineTag,
    editMachineImage,
    fetchMachine,
    fetchMachineFailed,
    fetchMachinesByCompany,
    fetchMachinesByCompanyFailed,
    fetchMachinesByCompanySucceeded,
    fetchMachineSucceeded,
    isManufacturerChanged,
    machineCapacityChanged,
    machineCategoryChanged,
    machineDescriptionChanged,
    machineNameChanged,
    machineParameterNameChanged,
    machineParameterValueChanged,
    machineParameterUnitChanged,
    openAddNewMachineManufacturerOverlay,
    openAddNewMachineOverlay,
    openEditMachineOverlay,
    postMachineParameters,
    postMachineParametersFailed,
    postMachineParametersSucceeded,
    removeMachineParameter,
    requestMachine,
    resetMachine,
    updateMachineCapacity,
    updateMachineCapacityFailed,
    updateMachineCapacitySucceeded,
    updateMachineCategory,
    updateMachineCategoryFailed,
    updateMachineCategorySucceeded,
    updateMachineFailed,
    updateMachineSucceeded,
    updateOrCreateMachine,
};
