//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n                from 'i18next';
import { put }             from 'redux-saga/effects';
import _                   from 'lodash';
import { LoadingActions }  from '@/store/actions/loading';
import { AlertBoxActions } from '@/store/actions/alertBox';

const requiredPasswordScore = 2;
const minimalPasswordLength = 8;

function* isPasswordSecureEnough(
    score,
    password,
    repeatedPassword,
) {
    if (!_.eq(password, repeatedPassword)) {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('passwordNotMatch'),
        }));
        yield put(LoadingActions.resetOverlay());

        return false;
    }

    if (score < requiredPasswordScore) {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t(
                'passwordIsNotSecureEnough',
                {
                    minimalLength: minimalPasswordLength,
                },
            ),
        }));
        yield put(LoadingActions.resetOverlay());

        return false;
    }

    return true;
}

export default {
    requiredPasswordScore,
    minimalPasswordLength,
    isPasswordSecureEnough,
};
