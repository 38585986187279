//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const LostPasswordTypes = {
    REQUEST_PASSWORD:           'LostPassword/REQUEST_PASSWORD',
    REQUEST_PASSWORD_FAILED:    'LostPassword/REQUEST_PASSWORD_FAILED',
    REQUEST_PASSWORD_SUCCEEDED: 'LostPassword/REQUEST_PASSWORD_SUCCEEDED',
    RESET_TOKEN:                'LostPassword/RESET_TOKEN',
    SET_IDENTIFIER:             'LostPassword/SET_IDENTIFIER',
    SET_NEW_PASSWORD:           'LostPassword/SET_NEW_PASSWORD',
    SET_NEW_PASSWORD_FAILED:    'LostPassword/SET_NEW_PASSWORD_FAILED',
    SET_NEW_PASSWORD_SUCCEEDED: 'LostPassword/SET_NEW_PASSWORD_SUCCEEDED',
    SET_PASSWORD:               'LostPassword/SET_PASSWORD',
    SET_PASSWORD_SCORE:         'LostPassword/SET_PASSWORD_SCORE',
    SET_PASSWORD_TOKEN:         'LostPassword/SET_PASSWORD_TOKEN',
    SET_REPEATED_PASSWORD:      'LostPassword/SET_REPEATED_PASSWORD',
};

const requestPassword = makeActionCreator(
    LostPasswordTypes.REQUEST_PASSWORD,
    {},
);

const requestPasswordFailed = makeActionCreator(
    LostPasswordTypes.REQUEST_PASSWORD_FAILED,
    {},
);

const requestPasswordSucceeded = makeActionCreator(
    LostPasswordTypes.REQUEST_PASSWORD_SUCCEEDED,
    {},
);

const resetToken = makeActionCreator(
    LostPasswordTypes.RESET_TOKEN,
    {},
);

const setIdentifier = makeActionCreator(
    LostPasswordTypes.SET_IDENTIFIER,
    {
        identifier: null,
    },
);

const setNewPassword = makeActionCreator(
    LostPasswordTypes.SET_NEW_PASSWORD,
    {},
);

const setNewPasswordFailed = makeActionCreator(
    LostPasswordTypes.SET_NEW_PASSWORD_FAILED,
    {},
);

const setNewPasswordSucceeded = makeActionCreator(
    LostPasswordTypes.SET_NEW_PASSWORD_SUCCEEDED,
    {},
);

const setPassword = makeActionCreator(
    LostPasswordTypes.SET_PASSWORD,
    {
        password: null,
    },
);

const setPasswordScore = makeActionCreator(
    LostPasswordTypes.SET_PASSWORD_SCORE,
    {
        passwordScore: null,
    },
);

const setPasswordToken = makeActionCreator(
    LostPasswordTypes.SET_PASSWORD_TOKEN,
    {
        token: null,
    },
);

const setRepeatedPassword = makeActionCreator(
    LostPasswordTypes.SET_REPEATED_PASSWORD,
    {
        repeatedPassword: null,
    },
);

export const LostPasswordActions = {
    requestPassword,
    requestPasswordFailed,
    requestPasswordSucceeded,
    resetToken,
    setIdentifier,
    setNewPassword,
    setNewPasswordFailed,
    setNewPasswordSucceeded,
    setPassword,
    setPasswordScore,
    setPasswordToken,
    setRepeatedPassword,
};
