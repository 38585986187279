//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const CompanyTypesTypes = {
    FETCH_COMPANY_TYPES:           'CompanyTypes/FETCH_COMPANY_TYPES',
    FETCH_COMPANY_TYPES_FAILED:    'CompanyTypes/FETCH_COMPANY_TYPES_FAILED',
    FETCH_COMPANY_TYPES_SUCCEEDED: 'CompanyTypes/FETCH_COMPANY_TYPES_SUCCEEDED',
};

const fetchCompanyTypes       = makeActionCreator(CompanyTypesTypes.FETCH_COMPANY_TYPES);
const fetchCompanyTypesFailed = makeActionCreator(CompanyTypesTypes.FETCH_COMPANY_TYPES_FAILED);

const fetchCompanyTypesSucceeded = makeActionCreator(
    CompanyTypesTypes.FETCH_COMPANY_TYPES_SUCCEEDED,
    {
        companyTypes: null,
    },
);

export const CompanyTypeActions = {
    fetchCompanyTypes,
    fetchCompanyTypesFailed,
    fetchCompanyTypesSucceeded,
};
