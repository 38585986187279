//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ApiConfiguration from '@/constants/Api';

class FileDownloader {
    static downloadFile = (path, fileName, displayName) => {
        if (path) {
            if (path.startsWith('http')) {
                window.open(path, '_blank');

                return;
            }

            const url = new URL(`/download/${fileName}`, ApiConfiguration.getBackendUrl());

            if (displayName) {
                url.searchParams.append('displayName', displayName);
            }

            window.open(url.href, '_blank');
        }
    };
}

export default FileDownloader;
