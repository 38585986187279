//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _            from 'lodash';
import I18n         from 'i18next';
import CompanyTypes from '@/constants/CompanyTypes';

class Company {
    static getCompanyTypeLabel = (type) => {
        switch (type) {
            // @formatter:off
            case CompanyTypes.machineManufacturer: return I18n.t('companyTypeMachineManufacturer');
            case CompanyTypes.serviceProvider:     return I18n.t('companyTypeServiceProvider');
            case CompanyTypes.supplier:            return I18n.t('companyTypeSupplier');
            // @formatter:on
        }

        return I18n.t('companyTypeUnknown');
    }

    static getCompanyTypesString = (types = []) => {
        const translatedTypes = _.map(
            types,
            (type) => Company.getCompanyTypeLabel(type),
        );

        return translatedTypes.join(', ');
    };

    static isSupplier = (company) => {
        return _.get(company, 'types', []).includes(CompanyTypes.supplier);
    };
}

export default Company;
