//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React             from 'react';
import I18n              from 'i18next';
import _                 from 'lodash';
import PropTypes         from '@/components/PropTypes';
import ColorBox          from '@/components/stateless/atomic/ColorBox';
import ColorBoxColor     from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import HeadlineSmall     from '@/components/stateless/atomic/HeadlineSmall';
import { FormHeadline }  from '@/components/stateless/atomic/FormHeadline';
import IconType          from '@/components/stateless/atomic/Icon/IconType';
import { IconButton }    from '@/components/stateless/atomic/IconButton';
import ComponentHelper   from '@/helper/ComponentHelper';
import DropDownInput     from '@/components/stateless/atomic/DropDownInput';
import TextCurrencyInput from '@/components/stateless/atomic/TextCurrencyInput';

import styles from './styles.module.scss';

export class Component extends React.Component {
    currencyInputChanged = (index, field) => (value) => {
        this.props.developmentRevenueChanged(
            index,
            field,
            value,
        );
    };

    yearLabelChanged = (index, field) => (event) => {
        const value = _.get(event, 'value', null);

        this.props.developmentRevenueChanged(
            index,
            field,
            value,
        );
    };

    removeButtonClicked = (index) => () => {
        this.props.removeButtonClicked(index);
    };

    getLastYears = (amountOfYears) => {
        const usedYears   = _.map(this.props.developmentRevenues, 'label');
        const currentYear = new Date().getFullYear();
        const years       = [];

        for (let i = 0; i < amountOfYears; i++) {
            years.push(currentYear - i);
        }

        const mappedYearsAsString = _.map(
            years,
            (year) => year.toString(),
        );
        const onlyAvailableYears  = _.difference(
            mappedYearsAsString,
            usedYears,
        );

        return _.map(
            onlyAvailableYears,
            (year) => {
                const yearAsString = year.toString();

                return {
                    label: yearAsString,
                    value: yearAsString,
                };
            },
        );
    };

    getOptionValue = (option) => option.value;

    getOptionLabel = (option) => option.label;

    render() {
        const { title } = this.props;

        return (
            <div className={styles.developmentRevenueInputWrapper}>
                <div className={styles.developmentRevenueInputHeader}>
                    <FormHeadline
                        text={title}
                        onClick={this.props.addButtonClicked}
                    />
                </div>
                <ColorBox color={ColorBoxColor.grayLight}>
                    <div className={styles.developmentRevenueInputList}>
                        {this.renderDevelopmentRevenues()}
                    </div>
                </ColorBox>
            </div>
        );
    }

    renderDevelopmentRevenue = (developmentRevenue, index) => {
        const label        = _.get(developmentRevenue, 'label');
        const defaultValue = (
            label ?
                {
                    label,
                    value: label,
                } :
                null
        );

        return (
            <div
                key={index}
                className={styles.developmentRevenueRow}
            >
                <div className={styles.developmentRevenueInputRow}>
                    <div className={styles.developmentRevenueInputColumn}>
                        <HeadlineSmall
                            text={I18n.t('year')}
                        />
                    </div>
                    <div className={styles.developmentRevenueInputColumn}>
                        <HeadlineSmall
                            text={I18n.t('value')}
                        />
                    </div>
                    <div className={styles.developmentRevenueInputColumn}>

                    </div>
                </div>
                <div
                    className={styles.developmentRevenueInputRow}
                >
                    <div className={styles.developmentRevenueInputColumn}>
                        <DropDownInput
                            onChange={this.yearLabelChanged(index, 'label')}
                            defaultValue={defaultValue}
                            getOptionValue={this.getOptionValue}
                            getOptionLabel={this.getOptionLabel}
                            options={this.getLastYears(10)}
                        />
                    </div>
                    <div className={styles.developmentRevenueInputColumn}>
                        <TextCurrencyInput
                            value={developmentRevenue.value}
                            onValueChange={this.currencyInputChanged(index, 'value')}
                            decimalScale={2}
                        />
                    </div>
                    <div className={styles.developmentRevenueInputColumn}>
                        <IconButton
                            iconType={IconType.deleteCircle}
                            onClick={this.removeButtonClicked(index)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderDevelopmentRevenues = () => {
        return this.props.developmentRevenues.map(this.renderDevelopmentRevenue);
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const DevelopmentRevenue = Component;

Component.propTypes = {
    addButtonClicked:          PropTypes.func,
    developmentRevenueChanged: PropTypes.func,
    developmentRevenues:       PropTypes.arrayOf(PropTypes.shape({
        id:    PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
    })),
    removeButtonClicked:       PropTypes.func,
    title:                     PropTypes.string,
};

Component.defaultProps = {
    addButtonClicked:          _.noop,
    developmentRevenueChanged: _.noop,
    developmentRevenues:       [],
    removeButtonClicked:       _.noop,
    title:                     null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
