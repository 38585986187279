//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                      from 'react';
import _                          from 'lodash';
import I18n                       from 'i18next';
import moment                     from 'moment';
import { bindActionCreators }     from 'redux';
import { compose }                from 'redux';
import { connect }                from 'react-redux';
import * as Api                   from '@/api';
import appStyles                  from '@/styles.module.scss';
import CompanySideMenu            from '@/components/connected/CompanySideMenu';
import ComponentHelper            from '@/helper/ComponentHelper';
import EntityLabelSize            from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import FavoriseCompanyButton      from '@/components/connected/FavoriseCompanyButton';
import AddMachineCTA              from '@/components/stateless/composed/AddMachineCTA';
import IconType                   from '@/components/stateless/atomic/Icon/IconType';
import Ids                        from '@/constants/Ids';
import MachineParameter           from '@/components/stateless/atomic/MachineParameter';
import Navigation                 from '@/helper/Navigation';
import PropTypes                  from '@/components/PropTypes';
import Route                      from '@/helper/Route';
import Routes                     from '@/constants/Routes';
import ShowIfUserIsAdminOfCompany from '@/components/connected/ShowIfUserIsAdminOfCompany';
import State                      from '@/helper/State';
import String                     from '@/helper/String';
import TagList                    from '@/components/stateless/composed/TagList';
import { CollapsableTagList }     from '@/components/stateless/composed/CollapsableTagList';
import { CompanyActions }         from '@/store/actions/company';
import { ContentHeaderStateless } from '@/components/stateless/composed/ContentHeaderStateless';
import { EntityLabel }            from '@/components/stateless/composed/EntityLabel';
import { MachineActions }         from '@/store/actions/machine';
import { MachineBox }             from '@/components/stateless/composed/MachineBox';
import { IconTextButton }         from '@/components/stateless/atomic/IconTextButton';
import { MachineHeader }          from '@/components/stateless/composed/MachineHeader';
import { MachineParameterBox }    from '@/components/stateless/composed/MachineParameterBox';
import { TabBar }                 from '@/components/stateless/composed/TabBar';
import withFetchCompany           from '@/components/hoc/FetchCompany';
import MachineCapacity            from '@/constants/MachineCapacity';
import Company                    from '@/helper/Company';
import { SvgColor }               from '@/components/stateless/atomic/SvgColor';
import { getColorByCapacity }     from '@/constants/MachineCapacity';
import { yearsToShow }            from '@/constants/MachineCapacity';

import styles from './styles.module.scss';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        this.fetchCompanyMachine();

        this.state = {
            tabIndex: 0,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCompanyId !== this.props.currentCompanyId) {
            this.fetchCompanyMachine();
        }
    }

    onTabIndexChanged = (index) => () => {
        this.setState({
            tabIndex: index,
        });
    };

    fetchCompanyMachine = () => {
        const id = this.props.currentCompanyId;

        this.props.fetchMachinesByCompany({
            companyId: id,
        });
    };

    onCompanyMailClicked = (machine) => () => {
        const props = this.props;

        props.requestMachine({
            machine,
            companyUrl: props.currentCompanyUrl,
        });
    };

    onMachineEditClicked = (machine) => () => {
        const props = this.props;

        props.openEditMachineOverlay({
            machineId: machine.id,
            machine,
        });
    };

    onDeleteMachineClicked = (machine) => () => {
        const userCompany      = this.props.userCompany;
        const userCompanyId    = _.get(userCompany, 'id');
        const createdByCompany = _.get(machine, 'createdByCompany');

        if (
            !createdByCompany ||
            createdByCompany.id !== userCompanyId
        ) {
            this.props.openRemoveCompanyMachineDialog({
                machine,
                company: userCompany,
            });
        } else {
            this.props.openDeleteCompanyMachineDialog({
                machine,
                company: userCompany,
            });
        }
    };

    getTabs = () => {
        const categories = Object.entries(this.props.categorizedMachines);

        return categories.map(([key, value]) => (
            {
                title:      key,
                badgeCount: value.length,
            }
        ));
    };

    render() {
        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <CompanySideMenu
                        route={Routes.companyMachine}
                        {...this.props}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        <ContentHeaderStateless
                            leftContent={
                                <EntityLabel
                                    title={this.props.companyName}
                                    subtitle={Company.getCompanyTypesString(this.props.companyTypes)}
                                    verified={this.props.companyVerified}
                                    avatar={Api.getImagePath(this.props.companyLogo)}
                                    size={EntityLabelSize.default}
                                    iso31661Alpha2CountryCode={_.get(this.props, 'companyAddress.countryCode', null)}
                                    to={Route.buildRoute(Routes.company, this.props.currentCompanyUrl)}
                                />
                            }
                            rightContent={
                                <FavoriseCompanyButton
                                    company={this.props.currentCompanyId}
                                />
                            }
                            infoText={I18n.t(
                                'lastUpdate',
                                {
                                    updateString: moment(this.props.companyLastUpdate).fromNow(),
                                },
                            )}
                        />
                        <TabBar
                            tabs={this.getTabs()}
                            changeActiveTabIndex={this.onTabIndexChanged}
                            activeTabIndex={this.state.tabIndex}
                        />
                        <ShowIfUserIsAdminOfCompany currentCompanyId={this.props.currentCompanyId}>
                            <div className={styles.machineContainer}>
                                <AddMachineCTA />
                            </div>
                        </ShowIfUserIsAdminOfCompany>
                        <div className={styles.machineContainer}>
                            {this.renderMachines()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderMessageRequestButton = (machine) => {
        return (
            <IconTextButton
                key={'machine-header-button-mail'}
                onClick={this.onCompanyMailClicked(machine)}
                iconType={IconType.envelope}
            />
        );
    };

    getCapacityColor = (machine) => {
        const capacities             = _.get(machine, 'capacities');
        const currentCompanyId       = this.props.currentCompanyId;
        const companyMachineCapacity = _.find(capacities, (capacity) => {
            return _.get(capacity, 'company.id') === currentCompanyId;
        });
        const capacity               = _.get(companyMachineCapacity, 'capacity');

        return getColorByCapacity(capacity);
    };

    renderMachine = (machine) => {
        const currentYear               = new Date().getFullYear();
        const existingMachineCapacities = _.get(machine, 'capacities');
        const currentCompanyId          = this.props.currentCompanyId;
        let capacities                  = null;

        if (
            this.props.companyIsSupplier &&
            this.props.companyVerified?.verified
        ) {
            capacities = _.range(0, yearsToShow).map((offset) => {
                const year             = currentYear + offset;
                const existingCapacity = _.find(existingMachineCapacities, (capacity) => {
                    return (
                        _.get(capacity, 'company.id') === currentCompanyId &&
                        _.get(capacity, 'year') === year
                    );
                });
                const result           = {
                    year,
                    capacity: _.get(existingCapacity, 'capacity', MachineCapacity.free),
                };

                return result;
            });
        }

        return (
            <div
                className={styles.machine}
                key={`machine-${machine.iri}`}
            >
                <MachineBox
                    topLeftContent={
                        <MachineHeader
                            image={Api.getImagePath(_.get(machine, 'image.path'))}
                            title={_.get(machine, 'name')}
                            manufacturer={_.get(machine, 'manufacturer.name')}
                            text={String.cleanup(_.get(machine, 'description'))}
                            capacities={capacities}
                            currentCompanyId={this.props.currentCompanyId}
                        >
                            <ShowIfUserIsAdminOfCompany
                                currentCompanyId={this.props.currentCompanyId}
                                fallbackContent={this.renderMessageRequestButton(machine)}
                            />
                            {this.renderEditButton(machine)}
                            {this.renderDeleteButton(machine)}

                            <SvgColor
                                color={this.getCapacityColor(machine)}
                            >
                                <IconTextButton
                                    to={Route.buildRoute(Routes.machine, machine.id)}
                                    key={'machine-header-button-owner'}
                                    iconType={IconType.owner}
                                    text={I18n.t('details')}
                                    iconColor={'red'}
                                />
                            </SvgColor>
                        </MachineHeader>
                    }
                    rightContent={
                        <CollapsableTagList>
                            <TagList tags={_.get(machine, 'tagStrings', [])} />
                        </CollapsableTagList>
                    }
                />
            </div>
        );
    };

    renderDeleteButton = (machine) => {
        return (
            <ShowIfUserIsAdminOfCompany currentCompanyId={this.props.currentCompanyId}>
                <IconTextButton
                    key={'machine-header-button-delete'}
                    onClick={this.onDeleteMachineClicked(machine)}
                    iconType={IconType.remove}
                />
            </ShowIfUserIsAdminOfCompany>
        );
    };

    renderEditButton = (machine) => {
        return (
            <ShowIfUserIsAdminOfCompany
                currentCompanyId={this.props.currentCompanyId}
            >
                <IconTextButton
                    onClick={this.onMachineEditClicked(machine)}
                    key={'machine-header-button-owner-edit'}
                    iconType={IconType.pencil}
                />
            </ShowIfUserIsAdminOfCompany>
        );
    };

    renderMachines = () => {
        const categorizedMachines = Object.values(this.props.categorizedMachines);
        const visibleMachines     = categorizedMachines[this.state.tabIndex] ?? [];

        return visibleMachines.map(this.renderMachine);
    };

    renderParameter = (parameter, index) => {
        return (
            <MachineParameter
                key={`machine-parameter-${index}`}
                title={_.get(parameter, 'name')}
                value={_.get(parameter, 'value')}
            />
        );
    };

    renderParameters = (parameters) => {
        return (
            <MachineParameterBox>
                {parameters.map(this.renderParameter)}
            </MachineParameterBox>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    categorizedMachines:            PropTypes.object,
    companyLastUpdate:              PropTypes.string,
    companyLogo:                    PropTypes.string,
    companyName:                    PropTypes.string,
    companyTypes:                   PropTypes.array,
    companyVerified:                PropTypes.bool,
    companyIsSupplier:              PropTypes.bool,
    currentCompanyId:               PropTypes.string,
    currentCompanyUrl:              PropTypes.string,
    fetchMachinesByCompany:         PropTypes.func,
    openDeleteCompanyMachineDialog: PropTypes.func,
    openEditMachineOverlay:         PropTypes.func,
    openRemoveCompanyMachineDialog: PropTypes.func,
    requestMachine:                 PropTypes.func,
    setCurrentCompany:              PropTypes.func,
    userCompany:                    PropTypes.string,
};

Screen.defaultProps = {
    categorizedMachines:            {},
    companyLastUpdate:              null,
    companyLogo:                    null,
    companyName:                    null,
    companyTypes:                   [],
    companyVerified:                false,
    companyIsSupplier:              false,
    currentCompanyId:               null,
    currentCompanyUrl:              null,
    fetchMachinesByCompany:         _.noop,
    openDeleteCompanyMachineDialog: _.noop,
    openEditMachineOverlay:         _.noop,
    openRemoveCompanyMachineDialog: _.noop,
    requestMachine:                 _.noop,
    setCurrentCompany:              _.noop,
    userCompany:                    null,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [
    'tabIndex',
];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyActions,
        ...MachineActions,
    },
    dispatch,
);

const mapStateToProps = (state, ownProps) => {
    const companyUrl             = Navigation.getParameterFromUrl(ownProps, 'companyId');
    const companyId              = Navigation.getCompanyIdFromUrl(ownProps);
    const companyMachines        = State.getMachinesByCompanyId(state, companyId);
    const categorizedMachines    = {};
    const currentCompanyVerified = State.getCompanyVerified(state, companyId);
    const currentCompanyMember   = State.isUserCompanyMember(state);
    const company                = State.getCompanyObject(state, companyId);
    const companyIsSupplier      = Company.isSupplier(company);

    if (currentCompanyMember) {
        companyMachines.forEach((machine) => {
            const category     = machine.categories?.find((machineCategory) => machineCategory.company.id === companyId);
            const categoryName = category?.name?.toLowerCase() ?? 'all';

            if (!categorizedMachines[categoryName]) {
                categorizedMachines[categoryName] = [];
            }

            categorizedMachines[categoryName].push(machine);
        });
    } else {
        categorizedMachines.all = companyMachines;
    }

    return {
        companyAddress:    State.getCompanyValue(state, companyId, 'address'),
        companyContact:    State.getCompanyValue(state, companyId, 'contactData'),
        companyLastUpdate: State.getCompanyValue(state, companyId, 'updatedAt'),
        companyLogo:       State.getCompanyValue(state, companyId, 'logo.path'),
        categorizedMachines,
        companyName:       State.getCompanyValue(state, companyId, 'name'),
        companyTypes:      State.getCompanyValue(state, companyId, 'types', []),
        companyVerified:   currentCompanyVerified,
        companyIsSupplier,
        currentCompanyId:  companyId,
        currentCompanyUrl: companyUrl,
        userCompany:       State.getUserCompany(state),
    };
};

const Composed = compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);

export default withFetchCompany(Composed);
