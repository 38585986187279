//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { z } from 'zod';
import I18n  from 'i18next';

export const CreateProjectSchema = z.object({
    name:              z.string().min(1),
    comment:           z.optional(z.string().min(1)),
    totalVolume:       z.number().positive(),
    peakYearVolume:    z.number().positive(),
    visibleFromDate:   z.date(),
    visibleToDate:     z.date(),
    countryOfDelivery: z.string().min(1, {
        message: I18n.t('validationErrorCountryOfDelivery'),
    }),
    startDate:         z.date(),
    endDate:           z.date(),
    visibleNow:        z.optional(z.boolean()),
});

export default {
    CreateProjectSchema,
};
