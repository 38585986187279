//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Partner }             from '@/components/stateless/atomic/Partner';
import React                   from 'react';
import { Component }           from 'react';
import I18n                    from 'i18next';
import { Trans }               from 'react-i18next';
import appStyles               from '@/styles.module.scss';
import ColorButtonSize         from '@/components/stateless/atomic/ColorButton/ColorButtonSize';
import ColorButtonTheme        from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import IconType                from '@/components/stateless/atomic/Icon/IconType';
import StartPageHeader         from '@/components/connected/StartPageHeader';
import StartPageSearch         from '@/components/connected/StartPageSearch';
import StartPageSearchMode     from '@/components/stateless/composed/StartPageSearch/StartPageSearchMode';
import { ColorButton }         from '@/components/stateless/atomic/ColorButton';
import { StartPageBackground } from '@/components/stateless/atomic/StartPageBackground';
import classNames              from 'classnames';
import Pages                   from '@/constants/Pages';
import { compose }             from 'redux';
import { bindActionCreators }  from 'redux';
import { connect }             from 'react-redux';
import { OverlayActions }      from '@/store/actions/ovleray';
import { CompanyActions }      from '@/store/actions/company';
import _                       from 'lodash';
import PropTypes               from 'prop-types';
import styles                  from './styles.module.scss';

const mapStateToProps = (state) => {
    return {
        partner: _.get(state, 'partner.partner', []),
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyActions,
        ...OverlayActions,
    },
    dispatch,
);

class Screen extends Component {
    renderPartner = (partner) => {
        return (
            <Partner partner={partner} />
        );
    };

    render() {
        const { partner } = this.props;

        return (
            <div className={classNames(appStyles.fullScreen, appStyles.smallFooter)}>
                <StartPageBackground fullHeight={true} />
                <StartPageHeader />
                <div className={styles.homeContent}>
                    <h1>
                        <Trans
                            i18nKey={'whatAreYouLookingFor'}
                            components={{
                                span: <span />,
                            }}
                        />
                    </h1>
                    <StartPageSearch
                        allowOverflowScrolling={true}
                        allowInitialTagSearch={true}
                        mode={StartPageSearchMode.none}
                    />
                    <div className={styles.homeContentCTA}>
                        <span>
                            {I18n.t('firstTimeCTAText')}
                        </span>
                        <ColorButton
                            to={Pages.about}
                            target={'_blank'}
                            text={I18n.t('firstTimeCTAButton')}
                            theme={ColorButtonTheme.transparent}
                            icon={IconType.arrowRight}
                            size={ColorButtonSize.large}
                        />
                    </div>
                    <div className={styles.homePartner}>
                        {partner.map(this.renderPartner)}
                    </div>
                </div>
            </div>
        );
    }
}

export const HomeScreen = Screen;

Screen.propTypes = {
    partner: PropTypes.array,
};

Screen.defaultProps = {
    partner: [],
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
