//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                 from 'react';
import { CountryRegionData } from 'react-country-region-selector';
import I18n                  from 'i18next';

import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import HeadlineSmall   from '@/components/stateless/atomic/HeadlineSmall';
import PropTypes       from '@/components/PropTypes';

import styles from './styles.module.scss';

import 'react-datepicker/dist/react-datepicker.css';

export class Facts extends React.Component {
    static propTypes = {
        facts:      PropTypes.arrayOf(PropTypes.object),
        headline:   PropTypes.string,
        responsive: PropTypes.bool,
    };

    static defaultProps = {
        facts:      [],
        headline:   null,
        responsive: true,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [];

    renderHeadline = () => {
        if (this.props.headline) {
            return (
                <HeadlineSmall text={this.props.headline} />
            );
        }

        return null;
    };

    render() {
        return (
            <div className={styles.boxWrapper}>
                {this.renderHeadline()}
                <dl className={styles.boxList}>
                    {this.props.facts.map(this.renderFact)}
                </dl>
            </div>
        );
    }

    convertCountryOfDeliveryCodeToName = (code) => {
        const country = CountryRegionData.find((entryArray) => entryArray[1] === code);

        if (country) {
            return country[0];
        }

        return code;
    };

    renderFact = ({ label, value }) => {
        let convertedValue = value;

        if (label === 'countryOfDelivery') {
            convertedValue = this.convertCountryOfDeliveryCodeToName(value);
        }

        return (
            <React.Fragment key={label}>
                <dt
                    className={classNames({
                        [styles.responsive]: this.props.responsive,
                    })}
                >
                    {I18n.t(label)}
                </dt>
                <dd
                    className={classNames({
                        [styles.responsive]: this.props.responsive,
                    })}
                >
                    {convertedValue}
                </dd>
            </React.Fragment>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default Facts;
