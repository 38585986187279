//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    smaller50:        '< 50',
    between50And149:  '50 - 149',
    between150And299: '150 - 299',
    between300And499: '300 - 499',
    between500And999: '500 - 999',
    bigger1000:       '> 1000',
};
