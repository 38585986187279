//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { Component } from 'react';
import classNames    from 'classnames';
import I18n          from 'i18next';
import appStyles     from '@/styles.module.scss';

class Screen extends Component {
    render() {
        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={classNames(
                        appStyles.defaultInnerTextContainer,
                        appStyles.defaultInnerTextCenter,
                    )}
                >
                    <h1>
                        {I18n.t('notFoundTitle')}
                    </h1>
                    <p>
                        {I18n.t('notFoundText')}
                    </p>
                </div>
            </div>
        );
    }
}

export default Screen;
