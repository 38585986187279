//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    API_COMPANY_FACT_SHEET_URL: 'api/company-fact-sheets',
    API_COMPANY_TYPE_URL:       'api/company-types',
    API_COMPANY_URL:            'api/companies',
    API_INDUSTRIES_URL:         'api/industries',
    API_MACHINE_CAPACITY_URL:   'api/machine-capacities',
    API_MACHINE_CATEGORY_URL:   'api/machine-categories',
    API_MACHINE_PARAMETERS_URL: 'api/machine-parameters',
    API_MACHINES_URL:           'api/machines',
    API_MEMBERSHIP_REQUEST_URL: 'api/membership-requests',
    API_MEMBERSHIPS_URL:        'api/membership-options',
    API_MESSAGE_REQUEST_URL:    'api/message-requests',
    API_MESSAGE_URL:            'api/messages',
    API_MESSAGES_READ_URL:      'api/message-requests-read',
    API_PARTNERS_URL:           'api/partners',
    API_PORTFOLIO_PRODUCTS_URL: 'api/portfolio-products',
    API_PROJECT:                'api/projects',
    API_PROJECT_MATCHES:        'api/project-matches',
    API_PROJECTS_CREATE:        'api/projects',
    API_PROJECTS_OWN_PROJECTS:  'api/projects/own',
    API_PROJECTS_SUGGESTIONS:   'api/projects/suggestions',
    API_QUERY_BY_TAGS_URL:      'api/searchByTags',
    API_QUERY_URL:              'api/query',
    API_RESET_PASSWORD:         'api/users/reset-password',
    API_ROOT_TAGS_URL:          'api/rootTags',
    API_SET_NEW_PASSWORD:       'api/users/set-new-password',
    API_TAG_COMPETENCES_URL:    'api/tag-competences',
    API_TAG_INTERESTS_URL:      'api/tag-interests',
    API_TAG_PREVIEW_URL:        'api/tags/preview',
    API_TAG_URL:                'api/tags',
    API_TOKENS:                 'api/tokens',
    API_TRANSLATOR:             'api/translator',
    API_USER_VERIFICATION:      'api/user-verification-requests',
    API_USERS:                  'api/users',
    API_USERS_CLAIM_COMPANY:    (company) => `api/users?company=${company}`,
};
