//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import classNames from 'classnames';
import PropTypes  from 'prop-types'; // TODO
import styles     from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.machineParameterWrapper}>
                <dt
                    className={classNames(
                        styles.machineParameter,
                        styles.machineParameterTitle,
                    )}
                >
                    {this.props.title}
                </dt>
                <dd
                    className={classNames(
                        styles.machineParameter,
                        styles.machineParameterValue,
                    )}
                >
                    {this.props.value}
                </dd>
            </div>
        );
    }
}

export const MachineParameter = Component;

Component.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
};

Component.defaultProps = {
    title: '',
    value: '',
};

export default Component;
