//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import classNames      from 'classnames';
import ComponentHelper from '@/helper/ComponentHelper';
import IconButton      from '@/components/stateless/atomic/IconButton';
import IconButtonTheme from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div
                className={classNames(
                    styles.starButtonWrapper,
                    {
                        [styles.starButtonWrapperGlow]:  this.props.glow,
                        [styles.starButtonWrapperGray]:  this.props.gray,
                        [styles.starButtonWrapperHover]: !this.props.noHover,
                    },
                )}
            >
                <IconButton
                    iconType={IconType.star}
                    onClick={this.props.onClick}
                    theme={IconButtonTheme.plain}
                />
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    glow:    PropTypes.bool,
    gray:    PropTypes.bool,
    noHover: PropTypes.bool,
    onClick: PropTypes.func,
};

Component.defaultProps = {
    glow:    false,
    gray:    false,
    noHover: false,
    onClick: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export const StarButton = Component;

export default Component;
