//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { call }       from 'redux-saga/effects';
import { put }        from 'redux-saga/effects';
import { select }     from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { takeEvery }  from 'redux-saga/effects';

import HydraHelper           from '@/helper/Hydra';
import * as Api              from '@/api';
import { TagActions }        from '@/store/actions/tag';
import { TagTypes }          from '@/store/actions/tag';
import tagTypeDropDownFilter from '@/constants/TagTypeDropDownFilter';
import _                     from 'lodash';
import Tag                   from '@/helper/Tag';

const deleteCompetenceTagsBatch = function* batchDelete(competenceTagIris, tagErrors) {
    const competenceTagIri = competenceTagIris.shift();
    const response         = yield call(
        Api.deleteCompetenceTag,
        competenceTagIri,
    );

    if (!response.ok) {
        tagErrors.push(competenceTagIri);
    }

    if (competenceTagIris.length > 0) {
        yield deleteCompetenceTagsBatch(competenceTagIris, tagErrors);
    } else if (!tagErrors.length) {
        yield put(TagActions.deleteCompetenceTagsSucceeded());
    } else {
        yield put(TagActions.deleteCompetenceTagsFailed());
    }
};

const deleteInterestTagsBatch = function* batchDelete(interestTagIris, tagErrors) {
    const interestTagIri = interestTagIris.shift();
    const response       = yield call(
        Api.deleteInterestTag,
        interestTagIri,
    );

    if (!response.ok) {
        tagErrors.push(interestTagIri);
    }

    if (interestTagIris.length > 0) {
        yield deleteInterestTagsBatch(interestTagIris, tagErrors);
    } else if (!tagErrors.length) {
        yield put(TagActions.deleteInterestTagsSucceeded());
    } else {
        yield put(TagActions.deleteInterestTagsFailed());
    }
};

const postCompetenceTagsBatch = function* batchPost(tagIris, company, tagErrors) {
    const tagIri   = tagIris.shift();
    const response = yield call(
        Api.postCompetenceTag,
        tagIri,
        company.iri,
    );

    if (!response.ok) {
        tagErrors.push(tagIri);
    }

    if (tagIris.length > 0) {
        yield postCompetenceTagsBatch(tagIris, company, tagErrors);
    } else if (!tagErrors.length) {
        yield put(TagActions.postCompetenceTagsSucceeded());
    } else {
        yield put(TagActions.postCompetenceTagsFailed());
    }
};

const postInterestTagsBatch = function* batchPost(tagIris, company, tagErrors) {
    const tagIri   = tagIris.shift();
    const response = yield call(
        Api.postInterestTag,
        tagIri,
        company.iri,
    );

    if (!response.ok) {
        tagErrors.push(tagIri);
    }

    if (tagIris.length > 0) {
        yield postInterestTagsBatch(tagIris, company, tagErrors);
    } else if (!tagErrors.length) {
        yield put(TagActions.postInterestTagsSucceeded());
    } else {
        yield put(TagActions.postInterestTagsFailed());
    }
};

function* deleteCompetenceTags(action) {
    const competenceTagIris = action.competenceTagIris;

    if (!competenceTagIris.length) {
        yield put(TagActions.deleteCompetenceTagsSucceeded());
    } else {
        yield deleteCompetenceTagsBatch(competenceTagIris, []);
    }
}

function* deleteInterestTags(action) {
    const interestTagIris = action.interestTagIris;

    if (!interestTagIris.length) {
        yield put(TagActions.deleteInterestTagsSucceeded());
    } else {
        yield deleteInterestTagsBatch(interestTagIris, []);
    }
}

function* fetchTags(action) {
    const title   = _.get(action, 'title', null);
    const tagType = _.get(action, 'tagType', null);

    if (title) {
        const payload = {
            title,
        };

        if (tagType) {
            payload.type = tagType;
        }

        const response = yield call(Api.fetchTags, payload);

        if (response.ok) {
            const tags = HydraHelper.getMembersFromResponse(response.data);

            if (tags) {
                yield put(TagActions.fetchTagsSucceeded({
                    tags,
                }));
            } else {
                yield put(TagActions.fetchTagsFailed());
            }
        } else {
            yield put(TagActions.fetchTagsFailed());
        }
    } else {
        yield put(TagActions.fetchTagsSucceeded({
            tags: [],
        }));
    }
}

function* fetchTagsForDropdown(action) {
    const { target }       = action;
    const requestParameter = tagTypeDropDownFilter[target];
    const response         = yield call(
        Api.fetchTagsPreview,
        requestParameter,
    );

    if (response.ok) {
        const tags = HydraHelper.getMembersFromResponse(response.data);

        if (tags) {
            yield put(TagActions.fetchTagsForDropdownSucceeded({
                tagType: target,
                tags,
            }));
        } else {
            yield put(TagActions.fetchTagsForDropdownFailed());
        }
    } else {
        yield put(TagActions.fetchTagsForDropdownFailed());
    }
}

function* fetchRootTags() {
    const response = yield call(Api.fetchRootTags);

    if (response.ok) {
        const tags = response.data;

        if (tags) {
            yield put(TagActions.fetchRootTagsSucceeded({
                tags,
            }));
        } else {
            yield put(TagActions.fetchRootTagsFailed());
        }
    } else {
        yield put(TagActions.fetchRootTagsFailed());
    }
}

function* postCompetenceTags(action) {
    const company = yield select((state) => state.company.ownCompanyEdit);
    const tagIris = action.tagIris;

    if (!tagIris.length) {
        yield put(TagActions.postCompetenceTagsSucceeded());
    } else {
        yield postCompetenceTagsBatch(tagIris, company, []);
    }
}

function* postInterestTags(action) {
    const company = yield select((state) => state.company.ownCompanyEdit);
    const tagIris = action.tagIris;

    if (!tagIris.length) {
        yield put(TagActions.postInterestTagsSucceeded());
    } else {
        yield postInterestTagsBatch(tagIris, company, []);
    }
}

function* getChildTags(context, hierarchy) {
    for (const tagElement of hierarchy) {
        const tagId = _.get(tagElement, 'id', null);

        if (tagId) {
            const response = yield call(
                Api.fetchTag,
                tagId,
            );

            if (response.ok) {
                const result   = HydraHelper.cleanupObject(response.data);
                const children = _.get(result, 'childTags', []);

                yield put(TagActions.addChildrenToTagQuery({
                    tag: result,
                    children,
                    context,
                }));
            }
        }
    }
}

function* buildHierarchy(action) {
    const sourceTags = _.get(action, 'tags', []);
    const context    = _.get(action, 'context', null);
    let tagHierarchy = [];

    sourceTags.forEach((tag) => {
        tagHierarchy = Tag.addTagToTagSelectorTree(tagHierarchy, tag);
    });

    yield put(TagActions.setTagHierarchy({
        context,
        tagHierarchy,
    }));

    for (const sourceTag of sourceTags) {
        const hierarchy = _.get(sourceTag, 'hierarchy', []);

        yield getChildTags(context, hierarchy);
    }
}

function* addTag(action) {
    const tag       = _.get(action, 'tag', null);
    const context   = _.get(action, 'context', null);
    const hierarchy = _.get(tag, 'hierarchy', null);

    yield getChildTags(context, hierarchy);
}

export const callTagSagas = () => {
    return [
        // @formatter:off
        takeEvery([TagTypes.FETCH_TAGS_FOR_DROPDOWN], fetchTagsForDropdown),
        takeLatest([TagTypes.DELETE_COMPETENCE_TAGS], deleteCompetenceTags),
        takeLatest([TagTypes.DELETE_INTEREST_TAGS],   deleteInterestTags),
        takeLatest([TagTypes.FETCH_TAGS],             fetchTags),
        takeLatest([TagTypes.POST_COMPETENCE_TAGS],   postCompetenceTags),
        takeLatest([TagTypes.POST_INTEREST_TAGS],     postInterestTags),
        takeLatest([TagTypes.FETCH_ROOT_TAGS],        fetchRootTags),
        takeLatest([TagTypes.BUILD_HIERARCHY],        buildHierarchy),
        takeLatest([TagTypes.ADD_TAG],                addTag),
        // @formatter:on
    ];
};
