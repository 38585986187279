//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React             from 'react';
import _                 from 'lodash';
import I18n              from 'i18next';
import PropTypes         from '@/components/PropTypes';
import ColorBoxColor     from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import ColorBox          from '@/components/stateless/atomic/ColorBox';
import ComponentHelper   from '@/helper/ComponentHelper';
import { TextInput }     from '@/components/stateless/atomic/TextInput';
import TextInputSize     from '@/components/stateless/atomic/TextInput/TextInputSize';
import ColorButtonTheme  from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import ColorButton       from '@/components/stateless/atomic/ColorButton';
import { PhotosPreview } from '@/components/stateless/composed/PhotosPreview';
import AttachmentUpload  from '@/components/stateless/composed/AttachmentUpload';
import styles            from './styles.module.scss';

export class Component extends React.Component {
    renderRow = (label, value) => {
        return (
            <div className={styles.requestMessageFormInputRow}>
                <div className={styles.requestMessageFormInputLabel}>
                    {label}
                </div>
                <div className={styles.requestMessageFormInputValue}>
                    {value}
                </div>
            </div>
        );
    };

    renderButtons = () => {
        const { attachments, onAttachmentUpdate } = this.props;

        return (
            <div className={styles.requestMessageFormButtons}>
                <ColorButton
                    theme={ColorButtonTheme.orange}
                    text={I18n.t('send')}
                    onClick={this.props.onSend}
                />
                <ColorButton
                    theme={ColorButtonTheme.white}
                    text={I18n.t('cancel')}
                    onClick={this.props.onCancel}
                />
                <AttachmentUpload
                    files={attachments}
                    onFilesChanged={onAttachmentUpdate}
                    renderOnlyUploadButton={true}
                />
            </div>
        );
    };

    renderTextInput = (onChange, value, size = TextInputSize.default) => {
        return (
            <TextInput
                onChange={onChange}
                value={value}
                size={size}
            />
        );
    };

    renderPhotosPreview = () => {
        const { images, onImageUpdate } = this.props;

        return (
            <PhotosPreview
                onImageUploaded={onImageUpdate}
                onImageDeleted={onImageUpdate}
                images={images}
            />
        );
    };

    onFileDeleted = (file) => {
        const { attachments, onAttachmentUpdate } = this.props;
        const index                               = attachments.indexOf(file);

        onAttachmentUpdate(null, index);
    };

    renderAttachmentPreview = () => {
        const { attachments, onAttachmentUpdate } = this.props;

        return (
            <AttachmentUpload
                files={attachments}
                onFilesChanged={onAttachmentUpdate}
                onFileDeleted={this.onFileDeleted}
                renderOnlyAttachmentList={true}
            />
        );
    };

    renderHeadline = () => {
        return (
            <div className={styles.requestMessageFormHeadline}>
                {I18n.t('message')}
            </div>
        );
    };

    render() {
        const { onSubjectChanged, subject, onMessageChanged, message } = this.props;

        return (
            <div className={styles.requestMessageFormWrapper}>
                <ColorBox color={ColorBoxColor.grayLight}>
                    <div className={styles.requestMessageFormInputWrapper}>
                        {this.renderRow(
                            null,
                            this.renderHeadline(),
                        )}
                        {this.renderRow(
                            I18n.t('subject'),
                            this.renderTextInput(onSubjectChanged, subject),
                        )}
                        {this.renderRow(
                            I18n.t('message'),
                            this.renderTextInput(onMessageChanged, message, TextInputSize.multilineExtraLarge),
                        )}
                        {this.renderRow(
                            I18n.t('photos'),
                            this.renderPhotosPreview(),
                        )}
                        {this.renderRow(
                            null,
                            this.renderAttachmentPreview(),
                        )}
                        {this.renderRow(
                            null,
                            this.renderButtons(),
                        )}
                    </div>
                </ColorBox>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const RequestMessageForm = Component;

Component.propTypes = {
    attachments:        PropTypes.array,
    images:             PropTypes.array,
    message:            PropTypes.string,
    onAttachmentUpdate: PropTypes.func,
    onCancel:           PropTypes.func,
    onImageUpdate:      PropTypes.func,
    onMessageChanged:   PropTypes.func,
    onSend:             PropTypes.func,
    onSubjectChanged:   PropTypes.func,
    subject:            PropTypes.string,
};

Component.defaultProps = {
    attachments:        [],
    images:             [],
    message:            null,
    onAttachmentUpdate: _.noop,
    onCancel:           _.noop,
    onImageUpdate:      _.noop,
    onMessageChanged:   _.noop,
    onSend:             _.noop,
    onSubjectChanged:   _.noop,
    subject:            null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
